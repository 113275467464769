define('bookalook/routes/jobs/functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.deleteJob = deleteJob;
  exports.cancelJob = cancelJob;
  exports.onOpenLoanForms = onOpenLoanForms;

  function deleteJob(route, job) {
    var option = arguments.length <= 2 || arguments[2] === undefined ? 'cancel' : arguments[2];

    var words = ['cancel', 'Cancelling', 'cancelled', 'Close'];

    if (option !== 'cancel') {
      words = ['delete', 'Deleting', 'deleted', 'Delete'];
    }

    utils['default'].openDefaultConfirmationModal(route, words[3] + ' <strong>Job</strong>', 'You are about to ' + words[0] + ' this job. All data will be lost', null, words[0] + ' job', 'Go back').then(function () {
      route.send('openMessageModal', words[1] + ' job. Please Wait.');
      job.destroyRecord().then(function () {
        route.transitionTo('jobs.index').then(function () {
          //route.send('openTopBar', `The job was ${words[2]} correctly`);
          route.send('openPositiveTopBar');
          route.send('closeMessageModal');
        });
      });
    });
  }

  function cancelJob(route, job) {
    utils['default'].openDefaultConfirmationModal(route, "Cancel <strong>Job</strong>", "You are about to cancel this job. All samples will be cancelled", null, "Cancel job", "Go back").then(function () {
      route.send('openMessageModal', "Cancelling job. Please Wait.");
      utils['default'].putAPIJSON('jobs/cancel/' + job.get('id')).then(function () {
        route.refresh().then(function () {
          route.send('closeMessageModal');
        });
      }, function (failed) {
        route.refresh().then(function () {
          route.send('openTopBar', "This job can't be canceled: job was just updated by a PR");
          route.send('closeMessageModal');
        });
      });
    });
  }

  function onOpenLoanForms(jobId, controller) {
    controller.set('isLoading', true);
    utils['default'].getAPIJSON('jobs/' + jobId + '/loanforms').then(function (result) {
      var loanForms = result.loanForm;
      controller.set('jobLoanForms', loanForms);
      controller.set('isLoading', false);
    });
  }

});