define('bookalook/templates/admin/pr-users', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 12
              },
              "end": {
                "line": 60,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/admin/pr-users.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"type","button");
            dom.setAttribute(el1,"data-toggle","modal");
            dom.setAttribute(el1,"class","btn_icon");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [5]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createAttrMorph(element6, 'data-target');
            morphs[3] = dom.createMorphAt(element6,1,1);
            morphs[4] = dom.createMorphAt(fragment,7,7,contextualElement);
            return morphs;
          },
          statements: [
            ["content","user.stripeCustomerId",["loc",[null,[55,14],[55,39]]]],
            ["content","user.registerPlan",["loc",[null,[55,43],[55,64]]]],
            ["attribute","data-target",["concat",["#stripe-customer-id-remove-",["get","user.id",["loc",[null,[56,98],[56,105]]]]]]],
            ["inline","fa-icon",["times-circle"],[],["loc",[null,[57,16],[57,42]]]],
            ["inline","partial",["admin/modals/remove-stripe-customer-id"],[],["loc",[null,[59,14],[59,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 14
                },
                "end": {
                  "line": 74,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/admin/pr-users.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-group validated-select col-xs-12");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2,"type","button");
              dom.setAttribute(el2,"id","insert-pruser-stripe-button");
              dom.setAttribute(el2,"class","btn_primary");
              var el3 = dom.createTextNode("Create");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element4,1,1);
              morphs[1] = dom.createElementMorph(element5);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["placeholder","Select Plan...","searchEnabled",true,"content",["subexpr","@mut",[["get","plansAvailable",["loc",[null,[66,38],[66,52]]]]],[],[]],"value",["subexpr","@mut",[["get","planSelected",["loc",[null,[67,38],[67,50]]]]],[],[]],"optionLabelPath","plan","optionValuePath","id"],["loc",[null,[63,18],[70,20]]]],
              ["element","action",["createNewPRUserInStripeModal",["get","planSelected",["loc",[null,[71,67],[71,79]]]],["get","user.id",["loc",[null,[71,80],[71,87]]]]],[],["loc",[null,[71,26],[71,89]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 14
                },
                "end": {
                  "line": 76,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/admin/pr-users.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                No manager\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 12
              },
              "end": {
                "line": 81,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/admin/pr-users.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n            <button {{ action \"openNewPRUserInStripeModal\" user}}\n            type=\"button\" id=\"insert-pruser-stripe-button\" class=\"btn_primary\">Create customer</button>\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.isManager",["loc",[null,[61,20],[61,34]]]]],[],0,1,["loc",[null,[61,14],[76,21]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 12
              },
              "end": {
                "line": 87,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/admin/pr-users.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn_edit");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn_edit");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(element2,0,0);
            morphs[2] = dom.createElementMorph(element3);
            return morphs;
          },
          statements: [
            ["element","action",["verify",["get","user",["loc",[null,[85,40],[85,44]]]]],[],["loc",[null,[85,22],[85,46]]]],
            ["inline","fa-icon",["check-circle"],[],["loc",[null,[85,64],[85,90]]]],
            ["element","action",["discard",["get","user",["loc",[null,[86,41],[86,45]]]]],[],["loc",[null,[86,22],[86,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 12
              },
              "end": {
                "line": 91,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/admin/pr-users.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createMorphAt(element1,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["btn_edit ",["subexpr","unless",[["get","user.searchable",["loc",[null,[90,86],[90,101]]]],"color_light_gray"],[],["loc",[null,[90,77],[90,122]]]]]]],
            ["element","action",["toggleUserSearchable",["get","user",["loc",[null,[90,54],[90,58]]]]],[],["loc",[null,[90,22],[90,60]]]],
            ["inline","fa-icon",[["subexpr","if",[["get","user.searchable",["loc",[null,[90,138],[90,153]]]],"eye","eye-slash"],[],["loc",[null,[90,134],[90,172]]]]],[],["loc",[null,[90,124],[90,174]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 12
              },
              "end": {
                "line": 95,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/admin/pr-users.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn_edit");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["makeNewAgain",["get","user",["loc",[null,[94,46],[94,50]]]]],[],["loc",[null,[94,22],[94,52]]]],
            ["inline","fa-icon",["undo"],[],["loc",[null,[94,70],[94,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 6
            },
            "end": {
              "line": 101,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/admin/pr-users.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-left");
          var el3 = dom.createElement("strong");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","icons_column");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","icons_column");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","btn_edit");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [15]);
          var element9 = dom.childAt(element7, [17, 1]);
          var morphs = new Array(13);
          morphs[0] = dom.createAttrMorph(element7, 'id');
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [1, 0]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [5]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element7, [7]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element7, [9]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element7, [11]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element7, [13]),1,1);
          morphs[8] = dom.createMorphAt(element8,1,1);
          morphs[9] = dom.createMorphAt(element8,3,3);
          morphs[10] = dom.createMorphAt(element8,5,5);
          morphs[11] = dom.createElementMorph(element9);
          morphs[12] = dom.createMorphAt(element9,0,0);
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",["pr-row-",["get","user.id",["loc",[null,[46,25],[46,32]]]]]]],
          ["content","user.fullName",["loc",[null,[47,40],[47,57]]]],
          ["inline","date-format",[["get","user.date",["loc",[null,[48,28],[48,37]]]]],[],["loc",[null,[48,14],[48,39]]]],
          ["content","user.email",["loc",[null,[49,14],[49,28]]]],
          ["content","user.showroom.city",["loc",[null,[50,14],[50,36]]]],
          ["content","user.company",["loc",[null,[51,14],[51,30]]]],
          ["content","user.areasInfluence.length",["loc",[null,[52,14],[52,44]]]],
          ["block","if",[["get","user.stripeCustomerId",["loc",[null,[54,18],[54,39]]]]],[],0,1,["loc",[null,[54,12],[81,19]]]],
          ["block","if",[["subexpr","if-equal",[["get","statusFilter",["loc",[null,[84,28],[84,40]]]],3],[],["loc",[null,[84,18],[84,43]]]]],[],2,null,["loc",[null,[84,12],[87,19]]]],
          ["block","if",[["subexpr","if-equal",[["get","statusFilter",["loc",[null,[89,28],[89,40]]]],4],[],["loc",[null,[89,18],[89,43]]]]],[],3,null,["loc",[null,[89,12],[91,19]]]],
          ["block","if",[["subexpr","if-equal",[["get","statusFilter",["loc",[null,[93,28],[93,40]]]],5],[],["loc",[null,[93,18],[93,43]]]]],[],4,null,["loc",[null,[93,12],[95,19]]]],
          ["element","action",["deletePRUser",["get","user",["loc",[null,[98,44],[98,48]]]]],[],["loc",[null,[98,20],[98,50]]]],
          ["inline","fa-icon",["trash"],[],["loc",[null,[98,68],[98,87]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 105,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/admin/pr-users.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","billmodeld");
        dom.setAttribute(el1,"class","loader-container");
        dom.setAttribute(el1,"style","height:50px;display:none");
        var el2 = dom.createTextNode("\n Loading Your Content...\n   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sk-folding-cube");
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube1 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube2 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube4 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube3 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","adminid");
        dom.setAttribute(el1,"class","loader-container");
        dom.setAttribute(el1,"style","height:50px;display:none");
        var el2 = dom.createTextNode("\n Loading Your Content...\n   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sk-folding-cube");
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube1 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube2 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube4 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sk-cube3 sk-cube");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-8 no-padding admin_sub_menu");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("ACTIVATED USERS");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("NEW USERS");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("DISCARTED USERS");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        dom.setAttribute(el2,"class","table table-hover");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("thead");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5,"class","text-left");
        var el6 = dom.createTextNode("Full name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Date");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Email ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Showroom");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Company");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Brands");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Stripe");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Searchable");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Remove?");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tbody");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [6, 1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element10, [5]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element11, 'class');
        morphs[2] = dom.createElementMorph(element11);
        morphs[3] = dom.createAttrMorph(element12, 'class');
        morphs[4] = dom.createElementMorph(element12);
        morphs[5] = dom.createAttrMorph(element13, 'class');
        morphs[6] = dom.createElementMorph(element13);
        morphs[7] = dom.createMorphAt(dom.childAt(fragment, [8, 1, 3]),1,1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["inline","partial",["admin/partials/admin-header"],[],["loc",[null,[1,0],[1,41]]]],
        ["attribute","class",["concat",["admin_sub_menu_option ",["subexpr","if",[["subexpr","if-equal",[["get","statusFilter",["loc",[null,[23,94],[23,106]]]],4],[],["loc",[null,[23,84],[23,109]]]],"color_primary"],[],["loc",[null,[23,79],[23,127]]]]]]],
        ["element","action",["changeUsersStatusFilter",4],[],["loc",[null,[23,10],[23,49]]]],
        ["attribute","class",["concat",["admin_sub_menu_option ",["subexpr","if",[["subexpr","if-equal",[["get","statusFilter",["loc",[null,[24,94],[24,106]]]],3],[],["loc",[null,[24,84],[24,109]]]],"color_primary"],[],["loc",[null,[24,79],[24,127]]]]]]],
        ["element","action",["changeUsersStatusFilter",3],[],["loc",[null,[24,10],[24,49]]]],
        ["attribute","class",["concat",["admin_sub_menu_option ",["subexpr","if",[["subexpr","if-equal",[["get","statusFilter",["loc",[null,[25,93],[25,105]]]],5],[],["loc",[null,[25,83],[25,108]]]],"color_primary"],[],["loc",[null,[25,78],[25,126]]]]]]],
        ["element","action",["changeUsersStatusFilter",5],[],["loc",[null,[25,10],[25,49]]]],
        ["block","each",[["get","filteredUsers",["loc",[null,[45,14],[45,27]]]]],[],0,null,["loc",[null,[45,6],[101,15]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});