define('bookalook/routes/search/functions', ['exports', 'bookalook/utils', 'ember'], function (exports, utils, Ember) {

  'use strict';

  exports.searchByCriteria = searchByCriteria;
  exports.setupSearchInput = setupSearchInput;
  exports._debounceSearch = _debounceSearch;
  exports.onGoToSearch = onGoToSearch;

  function searchByCriteria(category, criteria) {
    var page = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];
    var filters = arguments.length <= 3 || arguments[3] === undefined ? [] : arguments[3];

    var filterString = '';
    filters.forEach(function (filter) {
      var separator = '[]=';
      filterString += '&' + filter[0] + separator + encodeURIComponent(filter[1]);
    });

    var promise = utils['default'].getAPIJSON('search/' + category + '/?q=' + criteria + '&p=' + page + '&en=12' + filterString);

    // Check look privacy
    promise.then(function (searchResult) {
      if (searchResult.data.looks) {
        searchResult.data.looks.items.forEach(function (l) {
          l.isPrivate = l.album.privacy === 'All my contacts' && !l.album.mp.contains(parseInt(Ember['default'].user.get('id'), 10));
        });
      }
    });
    return promise;
  }

  function setupSearchInput(route, searchInputControllerName, searchOptionControllerName) {
    var _this = this;

    var controller = route.controller;
    route.onSearchOption('');

    controller.searchPlaceholder = Ember['default'].computed(searchOptionControllerName, function () {
      var options = {
        'looks': 'samples',
        'hashtags': 'hashtags',
        'companies': 'brands & agencies',
        'users': 'people'
      };
      return 'Search ' + (options[controller.get(searchOptionControllerName)] || 'everything');
    });

    utils['default'].addObserver(controller, [searchInputControllerName, searchOptionControllerName], function () {
      controller.set('isDebouncing', true);
      Ember['default'].run.debounce(_this, '_debounceSearch', { controller: controller, searchInputControllerName: searchInputControllerName, searchOptionControllerName: searchOptionControllerName }, 600);
    });
  }

  function _debounceSearch(options) {
    var controller = options.controller;
    var searchCriteria = controller.get(options.searchInputControllerName);
    var searchOption = controller.get(options.searchOptionControllerName) || 'all';

    controller.set('searchCriteria', searchCriteria);

    if (searchCriteria.length >= 3) {
      controller.set('isOverlayLoading', true);
      controller.set('minimumSearchLength', true);
      searchByCriteria(searchOption, encodeURIComponent(searchCriteria)).then(function (response) {
        response = response.data;
        setupSearchControllers(controller, response);
        controller.set('isOverlayLoading', false);
        controller.set('isDebouncing', false);
      });
    } else {
      controller.set('companiesFound', null);
      controller.set('hashtagsFound', null);
      controller.set('looksFound', null);
      controller.set('usersFound', null);
      controller.set('minimumSearchLength', false);
    }
  }

  function setupSearchControllers(controller, searchResult) {
    limitResultSize(controller, searchResult.companies, 'Companies', 8);
    limitResultSize(controller, searchResult.looks, 'Looks', 6);
    limitResultSize(controller, searchResult.users, 'Users', 8);

    controller.set('companiesFound', searchResult.companies);
    controller.set('hashtagsFound', searchResult.hashtags);

    controller.set('looksFound', searchResult.looks);
    controller.set('usersFound', searchResult.users);
  }

  function limitResultSize(controller, entityObject, entity, numResults) {
    var controllerName = "seeAll" + entity;
    if (entityObject && entityObject.items.length > numResults) {
      var numToRemove = entityObject.items.length - numResults;
      entityObject.items.splice(-1 * numToRemove, numToRemove);
      controller.set(controllerName, true);
    }
  }

  function onGoToSearch(route, searchCriteria, searchOption) {

    $('.filter_criteria span').each(function () {
      $(this).removeClass('color_primary');
    });

    if (Ember['default'].isEmpty(searchOption)) {
      searchOption = 'overview';
    }

    if (searchOption === 'hashtags' && searchCriteria.charAt(0) !== '#') {
      searchCriteria = '#' + searchCriteria;
      searchOption = 'looks';
    }

    if (searchOption === 'users' || searchOption === 'companies') {
      try {
        var indexController = route.controllerFor('search.index');
        if (indexController.get('searchOption') === "users" || indexController.get('searchOption') === "companies") {
          route.transitionTo('search.index', searchOption, encodeURIComponent(searchCriteria));

          indexController.set('searchOption', searchOption);
          indexController.set('searchCriteria', searchCriteria);
          indexController.route._searchTarget(encodeURIComponent(searchCriteria), searchOption);
        } else {
          route.transitionTo('search.index', searchOption, encodeURIComponent(searchCriteria));
        }
      } catch (e) {
        route.transitionTo('search.index', searchOption, encodeURIComponent(searchCriteria));
      }
    } else {
      try {
        var looksController = route.controllerFor('search.looks');
        if (looksController.get('searchCriteria')) {
          //Reset filters!!!!
          looksController.set('firstSearch', true);

          route.transitionTo('search.looks', encodeURIComponent(searchCriteria));
          looksController.set('searchFilters', []);
          looksController.set('activeFilters', []);
          looksController.route._searchLooks(encodeURIComponent(searchCriteria));
        } else {
          route.transitionTo('search.looks', encodeURIComponent(searchCriteria));
        }
      } catch (e) {
        route.transitionTo('search.looks', encodeURIComponent(searchCriteria));
      }
    }
    route.controller.set('headerSearchInput', '');
    route.controller.set('minimumSearchLength', true);
    Ember['default'].$('.search_suggestion_wrapper').removeClass('show');
  }

});