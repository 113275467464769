define('bookalook/serializers/user', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].RESTSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      areasInfluence: { serialize: 'ids', deserialize: 'records' },
      mpcompany: { serialize: 'ids', deserialize: 'records' },
      addresses: { serialize: 'ids', deserialize: 'records' }
    }
  });

});