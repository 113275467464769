define('bookalook/routes/bookings/loan-form', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils', 'bookalook/routes/bookings/functions', 'bookalook/filters'], function (exports, Ember, config, UserValidatorMixin, utils, functions, filters) {

  'use strict';

  exports.filterByCollection = filterByCollection;

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      download: function download() {
        var skipSave = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
        this.onDownload(skipSave);
      },
      openAddSamplesModal: function openAddSamplesModal() {
        this.onOpenAddSamplesModal();
      },
      removeSample: function removeSample(sample) {
        this.onRemoveSample(sample);
      },
      saveLoanForm: function saveLoanForm() {
        functions['default'].onSaveLoanForm(this, 'loan-form');
      },
      openContactAddressSelector: function openContactAddressSelector() {
        functions['default'].onOpenContactAddressSelector(this);
      },
      willTransition: function willTransition(transition) {
        functions['default'].onTransitionLoanForm(this, transition);
      },
      startEditing: function startEditing() {
        var sectionId = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        this.onStartEditing(sectionId);
      },
      cancelEditing: function cancelEditing(sectionId) {
        this.onCancelEditing(sectionId);
      },
      saveEditing: function saveEditing(sectionId) {
        this.onSaveEditing(sectionId);
      },
      swapReturnAddress: function swapReturnAddress(direction) {
        this.onSwapReturnAddress(direction, event);
      },
      swapCallInAddress: function swapCallInAddress(direction) {
        this.onSwapCallInAddress(direction, event);
      },
      newCallInAddress: function newCallInAddress() {
        this.onNewCallInAddress();
      },
      cancelNewCallIn: function cancelNewCallIn() {
        this.onCancelNewCallIn();
      },
      saveNewCallIn: function saveNewCallIn() {
        this.onSaveNewCallIn();
      },
      newReturnAddress: function newReturnAddress() {
        this.onNewReturnAddress();
      },
      cancelNewReturn: function cancelNewReturn() {
        this.onCancelNewReturn();
      },
      saveNewReturn: function saveNewReturn() {
        this.onSaveNewReturn();
      },
      toggleSetAsMainAddress: function toggleSetAsMainAddress(newCallInAddress) {
        this.onToggleSetAsMainAddress(newCallInAddress);
      },
      closeNewCallInAddressModal: function closeNewCallInAddressModal() {
        this.onCloseNewCallInAddressModal();
      }

    },

    model: function model(params) {
      var parcel;
      if (params.parcel && params.parcel !== "new") {
        parcel = this.store.findRecord('parcel', params.parcel).then(function (p) {
          return p.reload();
        });
      }

      return Ember['default'].RSVP.hash({
        collections: utils['default'].getCollections(this, true),
        booking: this.store.findRecord('booking', params.id),
        parcel: parcel,
        showroomInfo: utils['default'].getAPIJSON('bookings/' + params.id + '/showroomInfo')
      });
    },
    setupController: function setupController(controller, model) {
      //Ember.user.reload();
      var appRoute = this.controllerFor('application');
      var userLogged = appRoute.get('userLogged');
      controller.set('userLogged', userLogged);
      controller.setProperties(model);
      controller.set('isLoaded', false);
      controller.set('route', this);

      this.controller.set('loanFormPage', true);
      this.controller.set('ddtFormPage', false);

      if (!Ember['default'].user.get('isMP')) {
        utils['default'].getContactListsCache(this).then(function (lists) {
          var contactLists = lists.map(function (l) {
            return l.get('contacts');
          });
          var contacts = contactLists.reduce(function (prev, next) {
            prev.addObjects(next);
            return prev;
          }, Ember['default'].A());
          controller.set('allContacts', contacts.uniq());
        });
      }
      this.controller.set('disableShowroomFilter', true);
      //this.controller.set('disableBrandFilter', true);
      this.controller.set('disableStatusFilter', true);
      functions['default'].showroomSelectorObserver(controller, 'showroom', 'booking');

      if (Ember['default'].user.get('isPR')) {
        this.initializeFilters();
        utils['default'].addObserver(controller, ['brandFilter', 'collectionFilter', 'albumFilter', 'searchInput', 'itemFilter', 'colourFilter'], 'searchFilterFunction');
      }

      //filters.setFilterComputeds(this);

      var booking = controller.get('booking');
      if (!controller.get('parcel')) {
        controller.set('parcel', booking.createParcel());
      }
      if (!controller.get('parcel.requester')) {
        controller.set('parcel.requester', booking.get('requester.fullName'));
      }
      if (Ember['default'].user.get('isMP')) {
        this.controller.set('isLoading', true);

        var samplesToLoad = model.parcel.get('samples');
        var samples = [];
        samplesToLoad.forEach(function (sample) {
          if (sample.id) {
            samples.push(sample.id);
          }
        });

        // MP: MEDIANTE NUEVO ENTRYPOINT
        if (samples.length) {
          utils['default'].postAPIJSON('collections/samples', { 'samples': samples }).then(function (collection) {
            controller.set('looks', collection.collection[0].albums[0].looks);
            controller.set('collections', collection.collection);
            controller.set('isLoading', false);
            controller.set('brandLogo', collection.collection[0].brand.avatar);
          });
        }
      }

      if (Ember['default'].user.get('isPR')) {
        var sortedList = controller.get('collections').sortBy('brand.name', 'collectionType', 'year');
        controller.set('collections', sortedList);

        //Barcodes: Obtain for checked samples
        var samplesId = [];

        controller.get('booking.samples').forEach(function (sample) {
          if (sample.id) {
            samplesId.push(sample.id);
          }
        });

        utils['default'].postAPIJSON('barcode/base64/samples', { samples: samplesId }).then(function (data) {
          var barcodes64 = data;
          controller.set('barcodes64', barcodes64);
        });
      }

      controller.get('booking.samples').setEach('checked', false);
      controller.get('parcel.samples').setEach('checked', true);
      controller.set('today', new Date());

      controller.set('PDFURL', null);

      if (Ember['default'].user.get('isMP')) {
        var samplesId = [];
        controller.get('booking.samples').forEach(function (sample) {
          if (sample.id) {
            samplesId.push(parseInt(sample.id));
          }
        });

        utils['default'].postAPIJSON('barcode/base64/samples', { samples: samplesId }).then(function (data) {
          var barcodes64 = data;
          controller.set('barcodes64', barcodes64);
        });
      }

      controller.set('someSampleVisible', true);

      controller.visibleSamplesObserver = function () {
        var samples = controller.get('parcel.samples');
        if (!Ember['default'].isNone(samples)) {
          controller.set('someSampleVisible', controller.get('parcel.samples').some(function (s) {
            return s.get('visible');
          }));
        } else {
          controller.set('someSampleVisible', true);
        }
      };

      utils['default'].addObserver(controller, ['parcel.samples.@each.visible'], 'visibleSamplesObserver');

      if (Ember['default'].user.get('isPR')) {
        this._resetNewCallInAddress();
        this._resetNewReturnAddress();
        this._initializeAddresses();

        /*Ember.set(controller, 'moreThan1Addresses',Ember.computed('addressesRequester.length', function () {
         //controller.set('moreThan1Addresses', Ember.computed('addressesRequester.length', function () {
          let a = controller.get('addressesRequester');
          if (a != null) {
            if (a.get('length') != null && a.get('length') > 1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }));
         Ember.set(controller, 'moreThan1Showroom',Ember.computed('allShowrooms.length', function () {
        //      controller.set('moreThan1Showroom', Ember.computed('allShowrooms.length', function () {
          let a = controller.get('allShowrooms');
          if (a != null) {
            if (a.get('length') != null && a.get('length') > 1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }));*/

        controller.searchFilterFunction = function () {
          var lookFilter = controller.get('lookFilter');
          var brandFilter = controller.get('brandFilter');
          var collectionFilter = controller.get('collectionFilter');
          var albumFilter = controller.get('albumFilter');
          var searchInput = this.get('searchInput').toLowerCase();
          var colourFilter = (this.get('colourFilter') || '').toLowerCase();
          var showroomFilter = this.get('showroomFilter');
          var itemFilter = (this.get('itemFilter') || '').toLowerCase();
          var samples = controller.get('parcel').get('samples');
          var allSamplesUnvisible = true;
          var routeName = controller.route ? controller.route.routeName : '';
          samples.forEach(function (s) {
            var checked = !s.get('archived') && !s.get('look.archived') && s.get('look.album.published');

            if (showroomFilter && checked) {
              checked = s.get('showroom.id') === showroomFilter;
            }

            if (brandFilter && checked) {
              checked = s.get('look.album.collection.brand.id') === brandFilter;
            }

            if (collectionFilter && checked) {
              checked = s.get('look.album.collection.id') === collectionFilter;
            }

            if (albumFilter && albumFilter.get && checked) {
              checked = s.get('look.album.id') === albumFilter.get('id');
            }

            if (lookFilter && checked) {
              checked = s.get('look.id') === lookFilter;
            }

            if (searchInput !== '' && checked) {
              checked = s.get('searchString').toLowerCase().indexOf(searchInput) > -1;
            }

            if (colourFilter && colourFilter !== '' && checked) {
              checked = s.get('colourSearchString').toLowerCase().indexOf(colourFilter) > -1;
            }

            if (itemFilter && itemFilter !== '' && itemFilter !== 'all samples' && checked) {
              checked = s.get('itemObject')['name'].toLowerCase() === itemFilter;
            }

            s.set('visible', checked);
            allSamplesUnvisible = allSamplesUnvisible && !checked;
          });
          controller.set('allSamplesUnvisible', allSamplesUnvisible);

          //_hideControllerSamples(hideControllerSamples, controller);
        };
        controller.searchFilterFunction();

        controller.albumsFilteredByCollection = Ember['default'].computed('collectionFilter', function () {
          var collection = controller.get('allCollections')[0];
          controller.set('albumFilter', null);
          controller.set('lookFilter', null);
          controller.set('colourFilter', '');
          controller.set('statusFilter', '');

          if (!collection || !collection.get) {
            return [];
          }
          var albums = collection.get('albums');
          var albumsFiltered = albums;

          if (controller.get('collectionFilter') !== '0') {
            albumsFiltered = albums.filter(function (album) {
              return album.get('collection.id') === controller.get('collectionFilter') && !album.get('archived');
            });
          }

          albumsFiltered = albumsFiltered.sort(function (a, b) {
            if (a.get && b.get) {
              return a.get('albumTypeLabel').toLowerCase().localeCompare(b.get('albumTypeLabel').toLowerCase());
            } else {
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          return utils['default'].addDefaultOption(albumsFiltered, 'All Albums', 'albumTypeLabel', null);
        });

        controller.allCollections = Ember['default'].computed('collectionsFilteredByBrand', 'collectionFilter', function () {
          var collectionFilter = controller.get('collectionFilter');
          var collections = controller.get('collectionsFilteredByBrand');

          var collectionsFiltered = filterByCollection(collections, collectionFilter, controller);

          return collectionsFiltered;
        });

        controller.allBrands = Ember['default'].computed('collections.@each', function () {
          if (!controller.get('collections')) {
            return [];
          }

          var brands = controller.get('collections').reduce(function (brandList, nextCollection) {
            var content = nextCollection.get('brand.content');
            if (content) {
              brandList.addObject(content);
            }
            return brandList;
          }, Ember['default'].A([{ id: null, name: 'All brands' }]));
          return brands;
        });
      }
    },

    onOpenAddSamplesModal: function onOpenAddSamplesModal() {
      //filters.setFilterComputeds(this, '#add-samples-modal', '#add-samples-modal .modal-dialog', true);
      Ember['default'].$('#add-samples-modal').modal();
    },

    _initializeAddresses: function _initializeAddresses() {
      var controller = this.controller;
      var booking = controller.get('booking');
      var allAddresses = [];
      var parcel = controller.get('parcel');

      if (booking.get('isInternal')) {
        allAddresses = controller.get('booking.requester.allAddresses') || [];
        /*//Set addresses for requester
        var requester = controller.get('booking.requester');
         let allAddresses = [];
         var mainAddress = requester.get('mainAddress');
        if (mainAddress && !(mainAddress.get('street1')) && !(mainAddress.get('street2')) && !(mainAddress.get('street1'))) {
          allAddresses.addObjects(requester.get('privateAddresses'));
        }
         if (requester.get('privateAddresses')) {
          allAddresses.addObject(requester.get('mainAddress'));
          allAddresses.addObjects(requester.get('privateAddresses'));
        }*/
      } else {
          var jobAddresses = booking.get('job').get('addresses');
          jobAddresses.forEach(function (jA) {
            allAddresses.pushObject(jA);
          });
        }

      var params = [];
      params.push(parcel.get('street1In'));
      params.push(parcel.get('street2In'));
      params.push(parcel.get('street3In'));
      params.push(parcel.get('cityIn'));
      params.push(parcel.get('countryIn'));
      params.push(parcel.get('zipCodeIn'));
      params.push(parcel.get('telephone1In'));
      params.push(parcel.get('telephone2In'));
      var hasSomeField = params.some(function (p) {
        return !Ember['default'].isBlank(p);
      });
      if (hasSomeField) {
        parcel.set('callInId', 0);
        var current = null;
        if (booking.get('isInternal')) {
          current = Ember['default'].Object.create({
            'id': 0,
            'street1': parcel.get('street1In'),
            'street2': parcel.get('street2In'),
            'street3': parcel.get('street3In'),
            'country': parcel.get('countryIn'),
            'city': parcel.get('cityIn'),
            'zipCode': parcel.get('zipCodeIn'),
            'telephone1': parcel.get('telephone1In'),
            'telephone2': parcel.get('telephone2In')
          });
        } else {
          current = Ember['default'].Object.create({
            'id': 0,
            'street1': parcel.get('street1In'),
            'street2': parcel.get('street2In'),
            'street3': parcel.get('street3In'),
            'country': parcel.get('countryIn'),
            'city': parcel.get('cityIn'),
            'zipCode': parcel.get('zipCodeIn'),
            'telephone': parcel.get('telephone1In'),
            'telephone2': parcel.get('telephone2In')
          });
        }

        var found = false;
        var allAddressesCopy = allAddresses.slice();
        for (var i = 0; i < allAddresses.length && !found; i++) {
          found = functions['default'].compareAddresses(current, allAddressesCopy[i]);
          if (found) {
            current = allAddressesCopy[i];
            allAddressesCopy.splice(i, 1);
            parcel.set('callInId', current.get('id'));
            parcel.set('callInMain', current.get('main'));
          }
        }
        var mixed = [current].concat(allAddressesCopy);
        controller.set('addressesRequester', mixed);
      } else {
        if (!Ember['default'].isNone(allAddresses[0])) {
          if (booking.get('isInternal')) {
            parcel.setProperties({
              callInId: allAddresses[0].get('id'),
              street1In: allAddresses[0].get('street1'),
              street2In: allAddresses[0].get('street2'),
              street3In: allAddresses[0].get('street3'),
              countryIn: allAddresses[0].get('country'),
              cityIn: allAddresses[0].get('city'),
              zipCodeIn: allAddresses[0].get('zipCode'),
              telephone1In: allAddresses[0].get('telephone1'),
              telephone2In: allAddresses[0].get('telephone2')
            });
          } else {
            parcel.setProperties({
              callInId: allAddresses[0].get('id'),
              street1In: allAddresses[0].get('street1'),
              street2In: allAddresses[0].get('street2'),
              street3In: allAddresses[0].get('street3'),
              countryIn: allAddresses[0].get('country'),
              cityIn: allAddresses[0].get('city'),
              zipCodeIn: allAddresses[0].get('zipCode'),
              telephone1In: allAddresses[0].get('telephone'),
              callInMain: allAddresses[0].get('main')
            });
          }
        }
        controller.set('addressesRequester', allAddresses);
      }

      var addressesRequester = controller.get('addressesRequester');
      if (!Ember['default'].isNone(addressesRequester)) {
        if (addressesRequester.get('length') != null && addressesRequester.get('length') > 1) {
          controller.set('moreThan1Addresses', true);
        } else {
          controller.set('moreThan1Addresses', false);
        }
      } else {
        controller.set('moreThan1Addresses', false);
      }

      controller.set('allShowrooms', Ember['default'].computed('booking.showroom', 'parcel', function () {
        if (Ember['default'].agency.get('showrooms')) {
          var _parcel = controller.get('parcel');
          _parcel.set('returnId', 0);
          var current = Ember['default'].Object.create({
            'id': 0,
            'street': _parcel.get('street1'),
            'street2': _parcel.get('street2'),
            'street3': _parcel.get('street3'),
            'country': _parcel.get('country'),
            'city': _parcel.get('city'),
            'zipCode': _parcel.get('zipCode'),
            'telephone': _parcel.get('telephone1'),
            'telephone2': _parcel.get('telephone2')
          });
          var showrooms = Ember['default'].agency.get('showrooms').toArray();
          var showroomsCopy = showrooms.slice();
          var found = false;
          for (var i = 0; i < showroomsCopy.length && !found; i++) {
            found = functions['default'].compareAddresses(current, showroomsCopy[i]);
            if (found) {
              current = showroomsCopy[i];
              showroomsCopy.splice(i, 1);
              _parcel.set('returnId', current.get('id'));
            }
          }
          var mixed = [current].concat(showroomsCopy);
          return mixed;
          //return Ember.agency.get('showrooms').toArray().addObject(current);
        }
      }));

      var a = controller.get('allShowrooms');
      if (a != null) {
        if (a.get('length') != null && a.get('length') > 1) {
          controller.set('moreThan1Showroom', true);
        } else {
          controller.set('moreThan1Showroom', false);
        }
      } else {
        controller.set('moreThan1Showroom', false);
      }
    },

    onRemoveSample: function onRemoveSample(sample) {
      var _t = this;
      var parcel = this.controller.get('parcel');
      var parcelSamples = parcel.get('samples');
      parcelSamples.removeObject(sample);

      sample.set('checked', false);
      /*_t.send('openMessageModal', 'Removing sample...');
      parcel.save().then(() => {
        _t.send('closeMessageModal');    });*/
    },

    onDownload: function onDownload() {
      var _this = this;

      var skipSave = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      this.send('openMessageModal', 'Generating loan form...');
      if (skipSave) {
        this._generatePDF();
      } else {
        functions['default'].onSaveLoanForm(this, 'loan-form').then(function () {
          return _this._generatePDF();
        });
      }
    },

    onStartEditing: function onStartEditing() {
      var sectionId = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _t = this;
      /*if (this.controller.get('parcel.isNew')){
        functions.onSaveLoanForm(this, 'loan-form').then(function () {
          if (sectionId !== null){
            Ember.$('#'+sectionId+' .line_element').hide(500, function () {
              _t.controller.set(sectionId+"Editing", true);
              Ember.$('#'+sectionId+' .edit_element').show(250, function () {
                Ember.$('#'+sectionId).addClass('editing');
              });
            });
          } else {
            _t.controller.set('beforeEditCallInId', _t.controller.get('parcel.callInId'));
            Ember.$("#edit-loan-form").modal('show');
          }
        });
      } else {
        if (sectionId !== null){
          Ember.$('#'+sectionId+' .line_element').hide(500, function () {
            _t.controller.set(sectionId+"Editing", true);
            Ember.$('#'+sectionId+' .edit_element').show(250, function () {
              Ember.$('#'+sectionId).addClass('editing');
            });
          });
        } else {
          _t.controller.set('beforeEditCallInId', _t.controller.get('parcel.callInId'));
          Ember.$("#edit-loan-form").modal('show');
        }
      }*/

      //this._initializeAddresses();

      if (this.controller.get('parcel.isNew')) {
        //Backup parcel editable fields
        this.controller.set('parcelCloneCompany', this.controller.get('parcel').get('company'));
        this.controller.set('parcelCloneReturnDate', this.controller.get('parcel').get('dateReturn'));
        this.controller.set('parcelCloneShippingDate', this.controller.get('parcel').get('shippingDate'));
        this.controller.set('parcelCloneDeliveryMethod', this.controller.get('parcel').get('deliveryMethod'));
        this.controller.set('parcelCloneCourier', this.controller.get('parcel').get('courier'));
        this.controller.set('parcelCloneTrackNumber', this.controller.get('parcel').get('trackNumber'));
        this.controller.set('parcelCloneNotes', this.controller.get('parcel').get('notes'));

        //ADDRESSES
        var parcel = this.controller.get('parcel');
        this.controller.set('oldcallInId', parcel.get('callInId'));
        this.controller.set('oldstreet1In', parcel.get('street1In'));
        this.controller.set('oldstreet2In', parcel.get('street2In'));
        this.controller.set('oldstreet3In', parcel.get('street3In'));
        this.controller.set('oldcityIn', parcel.get('cityIn'));
        this.controller.set('oldcountryIn', parcel.get('countryIn'));
        this.controller.set('oldzipCodeIn', parcel.get('zipCodeIn'));
        this.controller.set('oldtelephone1In', parcel.get('telephone1In'));
        this.controller.set('oldtelephone2In', parcel.get('telephone2In'));

        this.controller.set('oldreturnId', parcel.get('returnId'));
        this.controller.set('oldstreet1', parcel.get('street1'));
        this.controller.set('oldstreet2', parcel.get('street2'));
        this.controller.set('oldstreet3', parcel.get('street3'));
        this.controller.set('oldcountry', parcel.get('country'));
        this.controller.set('oldcity', parcel.get('city'));
        this.controller.set('oldzipCode', parcel.get('zipCode'));
        this.controller.set('oldtelephone1', parcel.get('telephone1'));
        this.controller.set('oldtelephone2', parcel.get('telephone2'));

        Ember['default'].$("#edit-loan-form").modal('show');
      } else {
        Ember['default'].$("#edit-loan-form").modal('show');
      }
    },

    onCancelEditing: function onCancelEditing() {
      var sectionId = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _t = this;

      //Restore original object editable fields (creation)
      if (this.controller.get('parcel.isNew')) {
        this.controller.set('parcel.company', this.controller.get('parcelCloneCompany'));
        this.controller.set('parcel.dateReturn', this.controller.get('parcelCloneReturnDate'));
        this.controller.set('parcel.shippingDate', this.controller.get('parcelCloneShippingDate'));
        this.controller.set('parcel.deliveryMethod', this.controller.get('parcelCloneDeliveryMethod'));
        this.controller.set('parcel.courier', this.controller.get('parcelCloneCourier'));
        this.controller.set('parcel.trackNumber', this.controller.get('parcelCloneTrackNumber'));
        this.controller.set('parcel.notes', this.controller.get('parcelCloneNotes'));

        //ADDRESSES
        var parcel = this.controller.get('parcel');
        parcel.set('callInId', this.controller.get('oldcallInId'));
        parcel.set('street1In', this.controller.get('oldstreet1In'));
        parcel.set('street2In', this.controller.get('oldstreet2In'));
        parcel.set('street3In', this.controller.get('oldstreet3In'));
        parcel.set('cityIn', this.controller.get('oldcityIn'));
        parcel.set('countryIn', this.controller.get('oldcountryIn'));
        parcel.set('zipCodeIn', this.controller.get('oldzipCodeIn'));
        parcel.set('telephone1In', this.controller.get('oldtelephone1In'));
        parcel.set('telephone2In', this.controller.get('oldtelephone2In'));

        parcel.set('returnId', this.controller.get('oldreturnId'));
        parcel.set('street1', this.controller.get('oldstreet1'));
        parcel.set('street2', this.controller.get('oldstreet2'));
        parcel.set('street3', this.controller.get('oldstreet3'));
        parcel.set('country', this.controller.get('oldcountry'));
        parcel.set('city', this.controller.get('oldcity'));
        parcel.set('zipCode', this.controller.get('oldzipCode'));
        parcel.set('telephone1', this.controller.get('oldtelephone1'));
        parcel.set('telephone2', this.controller.get('oldtelephone2'));
      } else {
        _t.controller.get('parcel').rollbackAttributes();
      }

      if (sectionId !== null) {
        Ember['default'].$('#' + sectionId + ' .edit_element').hide(500, function () {
          _t.controller.set(sectionId + "Editing", false);
          Ember['default'].$('#' + sectionId + ' .line_element').show(250, function () {
            Ember['default'].$('#' + sectionId).removeClass('editing');
          });
        });
      }
    },

    onSaveEditing: function onSaveEditing() {
      var sectionId = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _t = this;
      functions['default'].onSaveLoanForm(this, 'loan-form').then(function () {
        if (sectionId !== null) {
          Ember['default'].$('#' + sectionId + ' .edit_element').hide(500, function () {
            _t.controller.set(sectionId + "Editing", false);
            Ember['default'].$('#' + sectionId + ' .line_element').show(250, function () {
              Ember['default'].$('#' + sectionId).removeClass('editing');
            });
          });
        } else {
          Ember['default'].$('#edit-loan-form').modal('hide');
        }
      });
    },

    onSwapReturnAddress: function onSwapReturnAddress(direction, event) {
      var _this2 = this;

      var addresses = this.controller.get('allShowrooms');
      if (addresses.length > 1) {
        (function () {
          var firstMove = "0%";
          var secondMove = "0%";
          if (direction === "next") {
            firstMove = "-100%";
            secondMove = "+200%";
          } else {
            firstMove = "+100%";
            secondMove = "-200%";
          }
          var parcel = _this2.controller.get('parcel');
          var _t = _this2;
          var changeTo = null;

          var sliders = null;
          //CLICK ARROW IMG
          if (Ember['default'].$(event.target).prop("tagName") === "IMG") {
            sliders = Ember['default'].$(event.target).parent().siblings('.address-card').get(0);
          }
          //CLICK SPAN (PARENT FROM ARROW IMG)
          else {
              sliders = Ember['default'].$(event.target).siblings('.address-card').get(0);
            }
          $(sliders).animate({ left: firstMove }, 250, function () {
            //CHANGE INFO
            for (var i = 0; i < addresses.length; i++) {
              if (addresses[i].get('id') === parcel.get('returnId')) {
                if (direction === "next") {
                  //If last address => next will be first address
                  if (i === addresses.length - 1) {
                    changeTo = addresses[0];
                  } else {
                    changeTo = addresses[i + 1];
                  }
                } else {
                  //If first address => prev is the last address;
                  if (i === 0) {
                    changeTo = addresses[addresses.length - 1];
                  } else {
                    changeTo = addresses[i - 1];
                  }
                }
                break;
              }
            }
            _t.controller.get('parcel').setProperties({
              returnId: changeTo.get('id'),
              street1: changeTo.get('street'),
              street2: changeTo.get('street2'),
              street3: changeTo.get('street3'),
              country: changeTo.get('country'),
              city: changeTo.get('city'),
              zipCode: changeTo.get('zipCode'),
              telephone1: changeTo.get('telephone'),
              telephone2: changeTo.get('telephone2')
            });
            //END CHANGE INFO
            $(sliders).animate({ left: secondMove }, 0, function () {
              $(sliders).animate({ left: "0" }, 250);
            });
          });
        })();
      }
    },

    onSwapCallInAddress: function onSwapCallInAddress(direction, event) {
      var _this3 = this;

      var addresses = this.controller.get('addressesRequester');
      if (addresses.length > 1) {
        (function () {
          var firstMove = "0%";
          var secondMove = "0%";
          if (direction === "next") {
            firstMove = "-100%";
            secondMove = "+200%";
          } else {
            firstMove = "+100%";
            secondMove = "-200%";
          }
          var parcel = _this3.controller.get('parcel');
          var booking = _this3.controller.get('booking');
          var changeTo = null;
          var _t = _this3;

          var sliders = null;
          //CLICK ARROW IMG
          if (Ember['default'].$(event.target).prop("tagName") === "IMG") {
            sliders = Ember['default'].$(event.target).parent().siblings('.address-card').get(0);
          }
          //CLICK SPAN (PARENT FROM ARROW IMG)
          else {
              sliders = Ember['default'].$(event.target).siblings('.address-card').get(0);
            }
          $(sliders).animate({ left: firstMove }, 250, function () {
            //CHANGE INFO
            for (var i = 0; i < addresses.length; i++) {
              if (addresses[i].get('id') === parcel.get('callInId')) {
                if (direction === "next") {
                  //If last address => next will be first address
                  if (i === addresses.length - 1) {
                    changeTo = addresses[0];
                  } else {
                    changeTo = addresses[i + 1];
                  }
                } else {
                  //If first address => prev is the last address;
                  if (i === 0) {
                    changeTo = addresses[addresses.length - 1];
                  } else {
                    changeTo = addresses[i - 1];
                  }
                }
                break;
              }
            }
            if (booking.get('isInternal')) {
              parcel.setProperties({
                callInId: changeTo.get('id'),
                street1In: changeTo.get('street1'),
                street2In: changeTo.get('street2'),
                street3In: changeTo.get('street3'),
                countryIn: changeTo.get('country'),
                cityIn: changeTo.get('city'),
                zipCodeIn: changeTo.get('zipCode'),
                telephone1In: changeTo.get('telephone1'),
                telephone2In: changeTo.get('telephone2')
              });
            } else {
              parcel.setProperties({
                callInId: changeTo.get('id'),
                street1In: changeTo.get('street1'),
                street2In: changeTo.get('street2'),
                street3In: changeTo.get('street3'),
                countryIn: changeTo.get('country'),
                cityIn: changeTo.get('city'),
                zipCodeIn: changeTo.get('zipCode'),
                telephone1In: changeTo.get('telephone'),
                callInMain: changeTo.get('main')
              });
            }
            //END CHANGE INFO
            $(sliders).animate({ left: secondMove }, 0, function () {
              $(sliders).animate({ left: "0" }, 250);
            });
          });
        })();
      }
    },

    _resetNewCallInAddress: function _resetNewCallInAddress() {
      var newCallInAddress = this.store.createRecord('contact-address', {
        street1: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipcode: '',
        telephone1: '',
        telephone2: '',
        setAsMain: false
      });
      this.controller.set('newCallInAddress', newCallInAddress);
    },

    onNewCallInAddress: function onNewCallInAddress() {
      var newCallInAddress = this.store.createRecord('contact-address', {
        street1: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipcode: '',
        telephone1: '',
        telephone2: '',
        main: false,
        contact: this.controller.get('booking.requester')
      });
      newCallInAddress.set('setAsMain', false);
      this.controller.set('newCallInAddress', newCallInAddress);

      Ember['default'].$('#new-callin-address').modal('show');
    },

    onCancelNewCallIn: function onCancelNewCallIn() {
      this._resetNewCallInAddress();
      Ember['default'].$('#new-callin-address').modal('hide');
    },

    onSaveNewCallIn: function onSaveNewCallIn() {
      var btn = Ember['default'].$('#save-new-callin-address-btn');
      btn.button('loading');
      var _t = this;
      var addresses = this.controller.get('addressesRequester');
      var newObj = this.controller.get('newCallInAddress');
      var contact = this.controller.get('booking.requester');

      if (newObj.validate()) {

        if (newObj.get('setAsMain')) {
          newObj.set('contact', null);
          newObj.set('public', contact);
        } else {
          newObj.set('contact', contact);
          newObj.set('public', null);
        }

        if (addresses.length === 0) {
          addresses.addObject(newObj);
        } else if (addresses.get(addresses.length - 1).get('id') === 0) {
          addresses.set(addresses.length - 1, newObj);
        } else {
          addresses.addObject(newObj);
          this.controller.set('moreThan1Addresses', true);
        }

        newObj.save().then(function (changeTo) {
          _t.controller.get('parcel').setProperties({
            callInId: changeTo.get('id'),
            street1In: changeTo.get('street1'),
            street2In: changeTo.get('street2'),
            street3In: changeTo.get('street3'),
            countryIn: changeTo.get('country'),
            cityIn: changeTo.get('city'),
            zipCodeIn: changeTo.get('zipCode'),
            telephone1In: changeTo.get('telephone1'),
            telephone2In: changeTo.get('telephone2')
          });
          Ember['default'].$('#new-callin-address').modal('hide');
          btn.button('reset');
        });
      } else {
        btn.button('reset');
      }
    },

    _resetNewReturnAddress: function _resetNewReturnAddress() {
      var controller = this.controller;
      var newReturnAddress = Ember['default'].Object.create({
        street: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipCode: '',
        telephone: '',
        telephone2: ''
      });
      this.controller.set('newReturnAddress', newReturnAddress);
      this.controller.newReturnAddressObserver = function () {
        var newReturnAddress = controller.get('newReturnAddress');
        var params = [];
        params.push(newReturnAddress.get('street'));
        params.push(newReturnAddress.get('street2'));
        params.push(newReturnAddress.get('street3'));
        params.push(newReturnAddress.get('country'));
        params.push(newReturnAddress.get('city'));
        params.push(newReturnAddress.get('zipCode'));
        params.push(newReturnAddress.get('telephone'));
        params.push(newReturnAddress.get('telephone2'));
        var hasSomeField = params.some(function (p) {
          return !Ember['default'].isBlank(p);
        });
        controller.set('newReturnHasSomeField', hasSomeField);
      };

      utils['default'].addObserver(controller, ['newReturnAddress.street', 'newReturnAddress.street2', 'newReturnAddress.street3', 'newReturnAddress.country', 'newReturnAddress.zipCode', 'newReturnAddress.city', 'newReturnAddress.telephone', 'newReturnAddress.telephone2'], 'newReturnAddressObserver');
    },

    onNewReturnAddress: function onNewReturnAddress() {
      this._resetNewReturnAddress();
      Ember['default'].$('#new-return-address').modal('show');
    },

    onCancelNewReturn: function onCancelNewReturn() {
      this._resetNewReturnAddress();
      Ember['default'].$('#new-return-address').modal('hide');
    },

    onSaveNewReturn: function onSaveNewReturn() {
      var btn = Ember['default'].$('#save-new-return-address-btn');
      btn.button('loading');
      var addresses = this.controller.get('allShowrooms');
      var newObj = this.controller.get('newReturnAddress');
      newObj.set('id', 0);
      if (addresses.get(addresses.length - 1).get('id') === 0) {
        addresses.set(addresses.length - 1, newObj);
      } else {
        addresses.addObject(newObj);
        this.controller.set('moreThan1Showroom', true);
      }
      this.controller.get('parcel').setProperties({
        returnId: newObj.get('id'),
        street1: newObj.get('street'),
        street2: newObj.get('street2'),
        street3: newObj.get('street3'),
        country: newObj.get('country'),
        city: newObj.get('city'),
        zipCode: newObj.get('zipCode'),
        telephone1: newObj.get('telephone'),
        telephone2: newObj.get('telephone2')
      });
      Ember['default'].$('#new-return-address').modal('hide');
      btn.button('reset');
    },

    _getAllStyles: function _getAllStyles() {
      return '<link rel="stylesheet" type="text/css" href="../template/loan_form_css_purified.css">';
      //NO MORE WEIRD CODE (SOMETIMES BREAK)
      var rulesUsed = [];
      // main part: walking through all declared style rules
      // and checking, whether it is applied to some element
      var sheets = document.styleSheets;
      for (var c = 0; c < sheets.length; c++) {
        var rules = sheets[c].rules || sheets[c].cssRules;
        for (var r = 0; r < rules.length; r++) {
          rulesUsed.push(rules[r]);
        }
      }

      var style = rulesUsed.map(function (cssRule) {
        if (cssRule.cssText !== undefined) {
          var cssText = cssRule.cssText;
        } else if (cssRule.style && cssRule.style.cssText) {
          var cssText = cssRule.style.cssText;
        }
        // some beautifying of css
        return cssText.replace(/(\{|;)\s+/g, "\$1\n  ").replace(/\A\s+}/, "}");
        //                 set indent for css here ^
      }).join("\n");
      return "<style>\n" + style + "\n</style>\n\n";
    },

    _generatePDF: function _generatePDF() {
      var _this4 = this;

      var $ = Ember['default'].$;

      // Datepicker fix
      $('.inline_date_value').each(function (index, el) {
        return $(el).attr('value', $(el).val());
      });

      var container = Ember['default'].$('#pdf_container').clone();
      container.css("background-color", "white");

      var printHTML = '<html><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><title>Bookalook</title><meta name="viewport" content="width=device-width, initial-scale=1">';
      var pdfTag = $('#pdf_container').prop('outerHTML');
      /*let linkTags = $('link').clone();
       let cssLinkTags = $.grep(linkTags, (element) => {
        return element.href.indexOf('.css') >= 0;
      });
       let cssToBeProcessed = cssLinkTags.length;
      let cssText = "<style>";
      var cssProcessed = 0;
       $(cssLinkTags).each((index, element) => {
        let href = element.href;
        $(element).attr('href', href);
         $.when($.get(href))
          .done(function(response) {
            cssText += response;
            cssProcessed ++;
          });
      _sleep(1);
      });
      */
      printHTML += this._getAllStyles();
      printHTML += '<style>b {font-weight:500; !important;}strong {font-weight: 500; !important;}tr {page-break-inside: avoid !important;} td {page-break-inside: avoid !important; }th { page-break-inside: avoid !important;}tbody {page-break-inside: avoid !important;}thead {page-break-inside: avoid !important;} tfoot {page-break-inside: avoid !important;}</style>';
      printHTML += '</head><body style="width: 780px !important; overflow: visible;"><div class="row"><div class="col-xs-12 relative no-padding">';
      printHTML += pdfTag;
      printHTML += '</div></div></body></html>';

      utils['default'].postAPIJSON('defaultParcelPdf', { html: printHTML, bookingNumber: this.controller.get('booking.id') }).then(function (res) {
        var random = Math.floor(Math.random() * 1000000);
        //let PDFURL =config.APIHost + '/' + res.booking.path + "?temp=" + random;

        var link = res.booking.path;
        var linkId = link.substr(link.lastIndexOf('/') + 1);
        var PDFURL = config['default'].APIURL + "pdfs/loanforms/" + linkId + "?temp=" + random;

        _this4.controller.set('PDFURL', PDFURL);
        _this4.send('closeMessageModal');
        _this4._openNewTab(PDFURL, random);
      }, function () {
        _this4.send('closeMessageModal');
        //this.send('openTopBarWithTime', 'Error downloading loan.', 3500);
        //console.log('error downloading loan');
      });
    },

    _openNewTab: function _openNewTab(link) {
      var random = arguments.length <= 1 || arguments[1] === undefined ? "" : arguments[1];

      /*
      let elementString = '<form autocomplete="off"   method="get" action="' + link + '" target="_blank"><input type="hidden" name="temp" value="'+random+'" /></form>'
       var frm = Ember.$(elementString);
       Ember.$("body").append(frm);
       frm.submit().remove();
       */
      var fixedLink = link.substr(link.lastIndexOf('/') + 1);
      window.open(config['default'].APIURL + 'pdfs/loanforms/' + fixedLink, '_blank' // <- This is what makes it open in a new window.
      );
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-bookings loan-form');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-bookings loan-form');
    },

    onToggleSetAsMainAddress: function onToggleSetAsMainAddress(newCallInAddress) {
      if (newCallInAddress.setAsMain) {
        Ember['default'].set(newCallInAddress, 'setAsMain', false);
      } else {
        Ember['default'].set(newCallInAddress, 'setAsMain', true);
      }
    },

    initializeFilters: function initializeFilters() {
      var controller = this.controller;
      controller.set('brandFilter', null);
      controller.set('collectionFilter', null);

      controller.set('showArchivedSamples', false);
      controller.set('sampleStatusFilter', Ember['default'].computed('showArchivedSamples', function () {
        return controller.get('showArchivedSamples') ? 'Archived' : 'Active';
      }));

      controller.set('searchInput', '');
      controller.set('itemFilter', '');
      controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
      controller.set('colourFilter', '');
      controller.set('statusContent', [{ id: 'All statuses' }, { id: 'BOOKED' }, { id: 'OVERLAP' }, { id: 'AVAILABLE' },
      //{id: 'IN TRANSFER'},
      { id: 'UNAVAILABLE' }]);

      controller.collectionsFilteredByBrand = Ember['default'].computed('brandFilter', 'collections', function () {

        controller.set('collectionFilter', null);
        controller.set('colourFilter', '');
        controller.set('statusFilter', '');

        controller.set('sizeFilter', '');
        if (Ember['default'].isNone(controller.get('collections')) || controller.get('collections').length == 0) {
          return utils['default'].addDefaultOption([], 'All Collections', 'filterName', null);;
        }
        var collections = controller.get('collections').filter(function (c) {
          if (c.get) {
            return !c.get('archived') && c.get('activeAlbums.length') && c.get('hasVisibleSamples');
          }
        });
        var parcelSamples = controller.get('parcel').get('samples');
        var parcelCollections = parcelSamples.map(function (s) {
          return s.get('collection').get('id');
        });
        var collectionsFiltered = collections.filter(function (c) {
          return parcelCollections.includes(c.get('id'));
        });

        if (controller.get('brandFilter') !== '0' && controller.get('brandFilter') !== null) {
          collectionsFiltered = collections.filter(function (collection) {
            return collection.get('brand.id') === controller.get('brandFilter') && !collection.get('archived');
          });
        }

        collectionsFiltered = collectionsFiltered.sort(function (a, b) {
          if (a.get && b.get) {
            //$order = ['year' => 'DESC', 'season' => 'ASC', 'collectionType' => 'ASC'];
            /*let yearComp = a.get('year').toLowerCase().localeCompare(b.get('year').toLowerCase());
             if (yearComp === 0){
             let seasonComp = b.get('season').toLowerCase().localeCompare(a.get('season').toLowerCase());
             if (seasonComp === 0){
             let typeComp =  a.get('collectionType').toLowerCase().localeCompare(b.get('collectionType').toLowerCase());
             if (typeComp === 0){
             return  a.get('title').toLowerCase().localeCompare(b.get('title').toLowerCase());
             } else {
             return typeComp;
             }
             } else {
             return seasonComp;
             }
             } else {
             return yearComp;
             }*/
            return b.get('filterName').toLowerCase().localeCompare(a.get('filterName').toLowerCase());
          } else {
            //FIRST ITEM (All Collections)
            if (a.get) {
              return 1;
            } else {
              return -1;
            }
          }
        });

        return utils['default'].addDefaultOption(collectionsFiltered, 'All Collections', 'filterName', null);
      });
    },

    onCloseNewCallInAddressModal: function onCloseNewCallInAddressModal() {
      Ember['default'].$('#new-callin-address').modal('hide');
      this.controller.get('newCallInAddress').rollbackAttributes();
    }

  });

  function filterByCollection(collectionList, collectionFilter) {
    var collectionsFiltered = collectionList;

    if (collectionFilter !== null && collectionFilter !== '') {
      collectionsFiltered = collectionList.filter(function (collection) {
        return collection.id === collectionFilter && collection.get('archived') === false;
      });
    }
    return collectionsFiltered;
  }

});