define('bookalook/templates/collections/partials/album-filters', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/collections/partials/album-filters.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","filter-size");
          dom.setAttribute(el1,"class","form-group validated-select filter no-padding-right");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"autocomplete","off");
          dom.setAttribute(el2,"id","no-autocomplete-sizes-albumfilters");
          dom.setAttribute(el2,"onsubmit","return false;");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["class","filter","placeholder","SIZES","searchEnabled",true,"content",["subexpr","@mut",[["get","allSizesContent",["loc",[null,[24,36],[24,51]]]]],[],[]],"value",["subexpr","@mut",[["get","sizeFilter",["loc",[null,[25,36],[25,46]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","name"],["loc",[null,[20,10],[28,12]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 60,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/collections/partials/album-filters.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","filter-wrapper col-xs-12 gradient-horizontal-background filters-row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","filters");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-sample");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-sample-albumfilters");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-colour");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-colours-albumfilters");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-material");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-materials-albumfilters");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(element0,3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1]),1,1);
        return morphs;
      },
      statements: [
        ["inline","select-2",[],["class","filter","placeholder","SAMPLE","searchEnabled",true,"content",["subexpr","@mut",[["get","Ember.itemsUnique",["loc",[null,[10,34],[10,51]]]]],[],[]],"value",["subexpr","@mut",[["get","itemFilter",["loc",[null,[11,34],[11,44]]]]],[],[]],"optionLabelPath","name","optionValuePath","name"],["loc",[null,[6,8],[14,10]]]],
        ["block","if",[["get","allSizesContent",["loc",[null,[17,10],[17,25]]]]],[],0,null,["loc",[null,[17,4],[31,11]]]],
        ["inline","select-2",[],["class","filter","placeholder","COLOURS","searchEnabled",true,"content",["subexpr","@mut",[["get","allColoursContent",["loc",[null,[38,34],[38,51]]]]],[],[]],"value",["subexpr","@mut",[["get","colourFilter",["loc",[null,[39,34],[39,46]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","name"],["loc",[null,[34,8],[42,10]]]],
        ["inline","select-2",[],["class","filter","placeholder","MATERIALS","searchEnabled",true,"content",["subexpr","@mut",[["get","allFabricsContent",["loc",[null,[51,34],[51,51]]]]],[],[]],"value",["subexpr","@mut",[["get","materialFilter",["loc",[null,[52,34],[52,48]]]]],[],[]],"optionLabelPath","name","optionValuePath","name"],["loc",[null,[47,8],[55,10]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});