define('bookalook/routes/inventory/transfer-functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.onOpenTransferModal = onOpenTransferModal;
  exports.onCancelCheckTransfer = onCancelCheckTransfer;
  exports.onChangeSampleTransferStatus = onChangeSampleTransferStatus;

  function onOpenTransferModal(sample, route) {
    var isCancel = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var transferId = sample.get('transferId');
    onCancelCheckTransfer(sample);
    var samplesInTransfer = utils['default'].getSamples().filter(function (s) {
      return s.get('transferId') === transferId && s.get('id') !== sample.get('id');
    });

    var controller = route.controller;

    controller.set('isCancel', isCancel);

    samplesInTransfer.setEach('checked', true);
    sample.set('toCheckout', true);

    controller.set('showroomOrigin', sample.get('showroom'));
    controller.set('sampleToCheck', sample);
    controller.set('otherTransferSamples', samplesInTransfer);

    utils['default'].lazyLoad(3000, '#check-in-transfer');
    Ember['default'].$('#check-in-transfer').modal();
  }

  function onCancelCheckTransfer(sample) {
    var transferId = sample.get('transferId');
    var samplesInTransfer = utils['default'].getSamples().filter(function (s) {
      return s.get('transferId') === transferId;
    });

    samplesInTransfer.forEach(function (sample) {
      sample.set('toCheckout', false);
      sample.set('checked', false);
    });
  }

  function onChangeSampleTransferStatus(sample, route) {
    var _this = this;

    var isCancel = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var transferId = sample.get('transferId');
    var promise;
    var samplesInTransfer = [];

    utils['default'].getSamples().forEach(function (s) {
      if (s.get('transferId') === transferId && s.get('toCheckout')) {
        samplesInTransfer.addObject(s.get('id'));
        s.set('transferId', null);
        if (!isCancel) {
          s.set('showroom', Ember['default'].showroom);
        }
        s.set('checked', false);
      }
    });

    promise = utils['default'].putAPIJSON('transferSample/' + (isCancel ? "cancel" : "checkin"), { transferSample: transferId, samples: samplesInTransfer });

    Ember['default'].RSVP.all([promise]).then(function () {
      var text = isCancel ? "CANCELLED" : "ACCEPTED";
      var data = { date: utils['default'].dateToDayFormat(new Date()), samples: samplesInTransfer };
      route._getDateData(data);

      if (!isCancel) {
        sample.set('showroom', Ember['default'].showroom);
      }

      _this.onCancelCheckTransfer(sample);
      route.send('openTopBar', 'Samples in transfer ' + text);
    });
  }

});