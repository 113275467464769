define('bookalook/routes/search/index', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/search/functions'], function (exports, Ember, UserValidatorMixin, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      var user = Ember['default'].user;
      if (!user || !user.get('isMP')) {
        this.transitionTo('index');
      }
    },

    model: function model(params) {
      this.set('searchCriteria', params.query || "");
      this.set('searchOption', params.target);
    },

    actions: {
      openModalOverviewSampleIndex: function openModalOverviewSampleIndex(sample) {
        this.onOpenModalOverviewSampleIndex(sample);
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var searchOption = this.get('searchOption');
      var searchCriteria = decodeURIComponent(this.get('searchCriteria'));

      controller.set('searchOption', searchOption);
      controller.set('searchCriteria', searchCriteria);

      controller.set('nPages', null);
      controller.set('route', this);

      controller.set('amountOfResults', 0);

      this._searchTarget(this.get('searchCriteria'), searchOption);
    },

    _searchTarget: function _searchTarget(searchCriteria, searchOption) {
      var _this = this;

      this.controller.set('isLoading', true);
      functions['default'].searchByCriteria(searchOption, searchCriteria, 1).then(function (result) {
        var searchResult = undefined;

        if (result.data.companies) {
          searchResult = result.data.companies;
        } else {
          searchResult = result.data.users;
        }

        _this.controller.set('searchResult', searchResult.items);

        _this.controller.set('nPages', Math.ceil(searchResult.total_count / searchResult.num_items_per_page));
        _this.controller.set('numResults', searchResult.total_count);
        _this.controller.set('isLoading', false);
      });
    },

    _searchTargetPage: function _searchTargetPage(page) {
      var _this2 = this;

      this.controller.set('isLoading', true);
      functions['default'].searchByCriteria(this.get('searchOption'), this.get('searchCriteria'), page).then(function (result) {
        var searchResult = undefined;

        if (result.data.companies) {
          searchResult = result.data.companies;
        } else {
          searchResult = result.data.users;
        }

        _this2.controller.set('searchResult', searchResult.items);
        _this2.controller.set('isLoading', false);
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-search');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-search');
    }
  });

});