define('bookalook/filters', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.resetFilters = resetFilters;
  exports.undoResetFilters = undoResetFilters;
  exports.removeScrollListener = removeScrollListener;
  exports.setScrollPaginationListener = setScrollPaginationListener;
  exports.removeObserversFromSetFilterComputeds = removeObserversFromSetFilterComputeds;
  exports.setFilterComputeds = setFilterComputeds;
  exports.filterByCollection = filterByCollection;

  var containerGlobal = null;
  var containerGlobalDocument = null;
  var controllerGlobal = null;

  function resetFilters(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;

    //For inventory: save current filter values
    if (route.routeName == 'inventory.index') {
      controller.set('undoBrandFilter', controller.get('brandFilter'));
      controller.set('undoCollectionFilter', controller.get('collectionFilter'));
      controller.set('undoShowroomFilter', controller.get('showroomFilter'));
      controller.set('undoSearchInput', controller.get('searchInput'));
      controller.set('undoColourFilter', controller.get('colourFilter'));
      if (Ember['default'].isNone(controller.get('albumFilter'))) {
        controller.set('undoAlbumFilter', null);
      } else {
        controller.set('undoAlbumFilter', controller.get('albumFilter'));
      }
      controller.set('undoItemFilter', controller.get('itemFilter'));
      controller.set('undoLookFilter', controller.get('lookFilter'));
      controller.set('undoSizeFilter', controller.get('sizeFilter'));
      controller.set('undoStatusFilter', controller.get('statusFilter'));
    }

    if (controller.get('allBrands.length') !== 2) {
      controller.set('brandFilter', null);
    }
    controller.set('albumPage', 1);
    controller.set('collectionFilter', null);
    controller.set('showroomFilter', null);
    controller.set('searchInput', '');
    controller.set('colourFilter', '');

    //Don't reset status filter for inventory page
    if (route.routeName != 'inventory.index') {
      controller.set('statusFilter', '');
    }

    controller.set('albumFilter', null);
    controller.set('lookFilter', null);
    controller.set('itemFilter', '');
    controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
    controller.set('sizeFilter', '');

    container = container || window;
    Ember['default'].activeFilter = container;
  }

  //Restore filter values before the reset

  function undoResetFilters(route, container) {
    var loadingScreen = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var controller = route.controller;

    //For inventory: restore old filter values
    if (route.routeName == 'inventory.index') {
      if (loadingScreen) {
        route.send('openMessageModal', '');
      }
      if (!Ember['default'].isNone(controller.get('undoBrandFilter'))) {
        controller.set('brandFilter', controller.get('undoBrandFilter'));
      }
      if (Ember['default'].isNone(controller.get('undoCollectionFilter'))) {
        controller.set('collectionFilter', null);
      } else {
        controller.set('collectionFilter', controller.get('undoCollectionFilter'));
      }

      //controller.set('showroomFilter', controller.get('undoShowroomFilter'));

      controller.set('searchInput', controller.get('undoSearchInput'));
      controller.set('colourFilter', controller.get('undoColourFilter'));

      controller.set('itemFilter', controller.get('undoItemFilter'));
      controller.set('lookFilter', controller.get('undoLookFilter'));

      controller.set('sizeFilter', controller.get('undoSizeFilter'));

      controller.set('statusFilter', controller.get('undoStatusFilter'));

      if (loadingScreen) {
        route.send('closeMessageModal');
      }
    }

    container = container || window;
    Ember['default'].activeFilter = container;

    if (route.routeName == 'inventory.index') {
      Ember['default'].run.later(function () {
        if (!Ember['default'].isNone(controller.get('undoAlbumFilter'))) {
          controller.set('albumFilter', controller.get('undoAlbumFilter'));
        }
        if (loadingScreen) {
          route.send('closeMessageModal');
        }
      }, 1000);
    }
  }

  function _scrollCalculation() {
    if (containerGlobal !== "#newBookingScrollTable" && containerGlobal !== "#addSamplesScrollTable" && containerGlobal !== "#addProposalsScrollTable" && containerGlobal !== "#selectArchiveSamplesScrollTable" && controllerGlobal.route.routeName != 'inventory.index') {
      return;
    }
    /*
     if(containerGlobal !== ("#newBookingScrollTable") && containerGlobal !== ("#addSamplesScrollTable") && containerGlobal !== window && !($(containerGlobal).data('bs.modal') || {}).isShown) {
     return;
     }
     */
    if ($(containerGlobal).scrollTop() + $(containerGlobal).height() > $(containerGlobalDocument).height() - 300) {
      if (!controllerGlobal.get('bookingDatesBlock') && !controllerGlobal.get('allSamplesUnvisible') && controllerGlobal.get('loadMore') && !controllerGlobal.get('isLoading') && !controllerGlobal.get('isManageLoading') && !controllerGlobal.get('loadMoreStatuses')) {
        controllerGlobal.incrementProperty('albumPage');
      }
    }
  }

  function removeScrollListener(route) {
    var intervals = route.get('poll');
    intervals.clearAll();
    //window.removeEventListener('scroll',_scrollCalculation, true);
  }

  function setScrollPaginationListener(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;
    var intervals = route.get('poll');
    intervals.clearAll();
    intervals.addPoll({
      interval: 50, // 0.05s
      callback: _scrollCalculation
    });
    /*window.removeEventListener('scroll',_scrollCalculation, true);
     var paginationInterval = window.addEventListener('scroll',_scrollCalculation, true);
     controller.set('paginationInterval', paginationInterval);*/
  }

  function removeObserversFromSetFilterComputeds(route) {
    var controller = route.controller;
    //resetAlbumPageObserver
    controller.removeObserver('showroomFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('brandFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('collectionFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('albumFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('searchInput', controller, 'resetAlbumPageObserver');
    controller.removeObserver('colourFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('statusFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('lookFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('sizeFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('itemFilter', controller, 'resetAlbumPageObserver');
    //searchFilterFunction
    controller.removeObserver('collectionFilter', controller, 'searchFilterFunction');
    controller.removeObserver('brandFilter', controller, 'searchFilterFunction');
    controller.removeObserver('showroomFilter', controller, 'searchFilterFunction');
    controller.removeObserver('lookFilter', controller, 'searchFilterFunction');
    controller.removeObserver('searchInput', controller, 'searchFilterFunction');
    controller.removeObserver('colourFilter', controller, 'searchFilterFunction');
    controller.removeObserver('statusFilter', controller, 'searchFilterFunction');
    controller.removeObserver('sizeFilter', controller, 'searchFilterFunction');
    controller.removeObserver('itemFilter', controller, 'searchFilterFunction');
    //getStatuses
    controller.removeObserver('isLoadingCollections', controller, 'getStatuses');
    controller.removeObserver('allCollections', controller, 'getStatuses');
    controller.removeObserver('booking.checkout', controller, 'getStatuses');
    controller.removeObserver('booking.checkin', controller, 'getStatuses');
    controller.removeObserver('bookingToAdd.checkout', controller, 'getStatuses');
    controller.removeObserver('bookingToAdd.checkin', controller, 'getStatuses');
    //refreshStatusesAfterChecksObserver
    controller.removeObserver('booking.checkout', controller, 'refreshStatusesAfterChecksObserver');
    controller.removeObserver('booking.checkin', controller, 'refreshStatusesAfterChecksObserver');
    controller.removeObserver('bookingToAdd.checkout', controller, 'refreshStatusesAfterChecksObserver');
    controller.removeObserver('bookingToAdd.checkin', controller, 'refreshStatusesAfterChecksObserver');
  }

  function setFilterComputeds(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;
    controllerGlobal = controller;
    containerGlobal = container;
    containerGlobalDocument = containerDocument;

    var $ = Ember['default'].$;
    var lazyContainer = container;
    containerDocument = containerDocument || document;
    container = container || window;

    _setVisibleSamples(onlySamplesSameShowroom, controller);

    if (controller.get('allBrands.length') !== 2) {
      controller.set('brandFilter', null);
    }

    controller.set('albumPage', 1);
    controller.set('collectionFilter', null);
    controller.set('showroomFilter', null);
    controller.set('searchInput', '');
    controller.set('colourFilter', '');
    controller.set('statusFilter', '');
    controller.set('albumFilter', null);
    controller.set('lookFilter', null);
    controller.set('itemFilter', '');
    controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
    controller.set('sizeFilter', '');

    controller.resetAlbumPageObserver = function () {
      //console.log("resetAlbumPageObserver");
      controller.set('albumPage', 1);
    };
    utils['default'].addObserver(controller, ['showroomFilter', 'brandFilter', 'collectionFilter', 'albumFilter', 'searchInput', 'colourFilter', 'statusFilter', 'lookFilter', 'sizeFilter', 'itemFilter'], 'resetAlbumPageObserver');

    // COMPUTED PROPERTIES
    controller.collectionsFilteredByBrand = Ember['default'].computed('brandFilter', function () {

      controller.set('collectionFilter', null);

      var colourFilter = controller.get('colourFilter');
      var statusFilter = controller.get('statusFilter');
      var searchInput = controller.get('searchInput');
      var itemFilter = controller.get('itemFilter');
      var sizeFilter = controller.get('sizeFilter');

      controller.set('colourFilter', '');
      controller.set('statusFilter', '');
      controller.set('searchInput', '');
      controller.set('itemFilter', '');
      controller.set('sizeFilter', '');

      _setVisibleSamples(onlySamplesSameShowroom, controller);

      controller.set('colourFilter', colourFilter);
      controller.set('statusFilter', statusFilter);
      controller.set('searchInput', searchInput);
      controller.set('itemFilter', itemFilter);
      controller.set('sizeFilter', sizeFilter);

      var collections = controller.get('collections').filter(function (c) {
        if (c.get) {
          return !c.get('archived') && c.get('activeAlbums.length');
        }
      });

      var collectionsFiltered = collections;

      if (controller.get('brandFilter') !== '0' && controller.get('brandFilter') !== null) {
        collectionsFiltered = collections.filter(function (collection) {
          return collection.get('brand.id') === controller.get('brandFilter') && !collection.get('archived');
        });
      }

      //Disable collections sorting on Inventory and other pages with filters
      //collectionsFiltered = collectionsFiltered.sort(function(a, b) {
      //  if (a.get && b.get){
      //$order = ['year' => 'DESC', 'season' => 'ASC', 'collectionType' => 'ASC'];
      /*let yearComp = a.get('year').toLowerCase().localeCompare(b.get('year').toLowerCase());
      if (yearComp === 0){
        let seasonComp = b.get('season').toLowerCase().localeCompare(a.get('season').toLowerCase());
        if (seasonComp === 0){
          let typeComp =  a.get('collectionType').toLowerCase().localeCompare(b.get('collectionType').toLowerCase());
          if (typeComp === 0){
            return  a.get('title').toLowerCase().localeCompare(b.get('title').toLowerCase());
          } else {
            return typeComp;
          }
        } else {
          return seasonComp;
        }
      } else {
        return yearComp;
      }*/
      //    return b.get('filterName').toLowerCase().localeCompare(a.get('filterName').toLowerCase());
      //  } else {
      //FIRST ITEM (All Collections)
      //    if (a.get){
      //      return 1;
      //    } else {
      //      return -1;
      //    }
      //  }
      //});

      return utils['default'].addDefaultOption(collectionsFiltered, 'All Collections', 'filterName', null);
    });

    controller.allCollections = Ember['default'].computed('collectionsFilteredByBrand', 'collectionFilter', function () {
      var collectionFilter = controller.get('collectionFilter');
      var collections = controller.get('collectionsFilteredByBrand');
      var collectionsFiltered = filterByCollection(collections, collectionFilter, controller);

      return collectionsFiltered;
    });

    controller.showroomsFilteredByBrand = Ember['default'].computed(function () {
      var showrooms = Ember['default'].user.get('showroom').get('company').get('showrooms');
      //Por si ya se ha desactivado en el controller
      if (showrooms.length > 1 && !controller.get('disableShowroomFilter')) {
        controller.set('disableShowroomFilter', false);
      } else {
        controller.set('disableShowroomFilter', true);
      }
      return utils['default'].addDefaultOption(showrooms, 'All Showrooms', 'label', null);
    });

    controller.allAlbums = Ember['default'].computed('allCollections', 'albumPage', 'albumFilter', function () {
      var albums;

      if (controller.get('albumFilter') && controller.get('albumFilter.id')) {
        albums = [controller.get('albumFilter')];
      } else {
        controller.set('albumFilter', null);
        controller.set('lookFilter', null);
        albums = this.get('allCollections').reduce(function (a, c) {
          if (c.get) {
            a = a.concat(c.get('albums').toArray());
          }
          return a;
        }, []);
      }
      utils['default'].lazyLoad(2000, lazyContainer);
      _hideControllerSamples(hideControllerSamples, controller);

      var albumsToShow = albums.slice(0, controller.get('albumPage'));

      //Dont show unpublished for Booking creation!
      var albumsToShowFiltered = [];
      if (route.routeName == 'bookings.index' || route.routeName == 'bookings.booking-details' || route.routeName == 'inventory.index') {
        albumsToShow.forEach(function (al) {
          if (al.get('published')) {
            albumsToShowFiltered.push(al);
          }
        });
      } else {
        //Copy!
        albumsToShowFiltered = albumsToShow.slice(0);
      }

      if (route.routeName !== 'inventory.index' && route.routeName !== 'collections.index') {
        //Adding samples to Booking!
        var samples = [];
        var booking = controller.get('booking') || controller.get('bookingToAdd');
        //Only ask Backend when the checkin/out dates are filled
        if (albumsToShowFiltered.length && booking.get('checkout') && booking.get('checkin')) {
          samples = albumsToShowFiltered[albumsToShowFiltered.length - 1].get('samples').toArray();

          /*controller.set('loadMoreStatuses', true);
          utils.getSampleStatusByBookingDates(route, samples, utils.parseDate(booking.get('checkout')), utils.parseDate(booking.get('checkin'))).then(() => {
            //controller.searchFilterFunction();
            controller.set('loadMoreStatuses', false);
          });*/
        }
      }

      var loadMore = !controller.get('allSamplesUnvisible') && controller.get('albumPage') < albums.length;
      controller.set('loadMore', loadMore);
      if (!loadMore) {
        controller.set('albumPage', albums.length);
      }

      return albumsToShowFiltered;
    });

    controller.getStatuses = function () {
      //console.log("getStatuses");

      if (controller.get('isLoadingCollections') || controller.get('isLoadingStatuses') || controller.get('noRefreshStatuses') == true) {
        controller.set('isLoading', false);
        return;
      }

      controller.set('isLoadingStatuses', true);
      controller.set('isLoading', true);
      controller.set('loadMoreStatuses', true);
      var booking = controller.get('booking') || controller.get('bookingToAdd');
      if (booking) {
        // let checkout = booking.get('checkout');
        // let checkin = booking.get('checkin');
        var checkout = null;
        var checkin = null;
        if (controller.get('bookingDetailsPage')) {
          checkout = new Date();
          checkin = new Date();
        } else {
          checkout = booking.get('checkout');
          checkin = booking.get('checkin');
        }
        if (checkout && checkin) {

          if (!controller.get('isAgency')) {
            //BRAND: TRADITIONAL BEHAVIOUR
            utils['default'].getSampleStatusByBookingDates(route, utils['default'].getSamples().filter(function (s) {
              return !s.get('archived');
            }), utils['default'].parseDate(checkout), utils['default'].parseDate(checkin)).then(function () {
              //controller.searchFilterFunction();
              controller.set('bookingDatesBlock', false);
              controller.set('loadMoreStatuses', false);
              controller.set('isLoading', false);
              //Disable observer for getStatuses
              controller.set('noRefreshStatuses', true);
              controller.set('isLoadingStatuses', false);
            });
          } else {
            //AGENCY: Calculating sample status for Agencies
            //          if ((controller.get('brandFilter')) != null || controller.get('bookingDetailsPage')) {
            if (controller.get('brandFilter') != null) {
              utils['default'].getSampleStatusForBrandByBookingDates(route, utils['default'].getSamples(), controller.get('brandFilter'), utils['default'].parseDate(checkout), utils['default'].parseDate(checkin)).then(function () {
                controller.set('bookingDatesBlock', false);
                controller.set('loadMoreStatuses', false);
                controller.set('isLoading', false);
                //Disable observer for getStatuses
                //controller.set('noRefreshStatuses', true);
                controller.set('isLoadingStatuses', false);
              });
            } else if (controller.get('bookingDetailsPage')) {
              controller.set('bookingDatesBlock', false);
              controller.set('loadMoreStatuses', false);
              controller.set('isLoading', false);
              //Disable observer for getStatuses
              //controller.set('noRefreshStatuses', true);
              controller.set('isLoadingStatuses', false);
            } else {
              controller.set('isLoadingStatuses', false);
              if (controller.get('brandFilter') != null) {
                controller.set('isLoading', false);
              }
            }
          }

          /*utils.getSampleStatusByBookingDates(route, utils.getSamples().filter(s => !s.get('archived')), utils.parseDate(checkout), utils.parseDate(checkin)).then(() => {
            //controller.searchFilterFunction();
            controller.set('bookingDatesBlock', false);
            controller.set('loadMoreStatuses', false);
            controller.set('isLoading', false);
            //Disable observer for getStatuses
            controller.set('noRefreshStatuses', true);
            controller.set('isLoadingStatuses', false);
          });
          */
        } else {
            controller.set('bookingDatesBlock', true);
            controller.set('loadMoreStatuses', false);
            controller.set('isLoading', false);
            controller.set('isLoadingStatuses', false);
          }
      } else {
        controller.set('loadMoreStatuses', false);
        controller.set('isLoading', false);
        controller.set('isLoadingStatuses', false);
      }
    };

    utils['default'].addObserver(controller, ['isLoadingCollections', 'allCollections', 'booking.checkout', 'booking.checkin', 'bookingToAdd.checkout', 'bookingToAdd.checkin', 'brandFilter'], 'getStatuses');

    controller.refreshStatusesAfterChecksObserver = function () {
      //console.log("refreshStatusesAfterChecksObserver");
      //Enable again observer for getStatuses
      controller.set('noRefreshStatuses', false);
    };
    utils['default'].addObserver(controller, ['booking.checkout', 'booking.checkin', 'bookingToAdd.checkout', 'bookingToAdd.checkin'], 'refreshStatusesAfterChecksObserver');

    controller.searchFilterFunction = function () {
      var _this = this;

      //console.log("searchFilterFunction");
      var brandFilter = controller.get('brandFilter');

      var lookFilter = controller.get('lookFilter');
      var collectionFilter = controller.get('collectionFilter');
      var albumFilter = controller.get('albumFilter');
      var searchInput = (this.get('searchInput') || '').toString().toLowerCase();
      var colourFilter = (this.get('colourFilter') || '').toLowerCase();
      var statusFilter = this.get('statusFilter');
      var showroomFilter = this.get('showroomFilter');
      var sizeFilter = (this.get('sizeFilter') || '').toLowerCase();
      var itemFilter = (this.get('itemFilter') || '').toLowerCase();
      var samples = route.routeName == "collections.index" ? controller.get('ddtSamples') : utils['default'].getSamples();
      var allSamplesUnvisible = true;
      var routeName = controller.route ? controller.route.routeName : '';
      samples.forEach(function (s) {
        var checked = /*!s.get('archived') && !s.get('look.archived') &&*/s.get('look.album.published');
        if (onlySamplesSameShowroom && checked) {
          checked = s.setVisibleIfForeignCanPropose(controller.get('booking.owner.showroom'));
        }

        if (brandFilter && checked) {
          checked = s.get('look.album.collection.brand.id') === brandFilter;
        }

        if (showroomFilter && checked) {
          checked = s.get('showroom.id') === showroomFilter;
        }

        if (collectionFilter && checked) {
          checked = s.get('look.album.collection.id') === collectionFilter;
        }

        if (albumFilter && checked) {
          checked = s.get('look.album.id') === albumFilter.get('id');
        }

        if (lookFilter && checked) {
          checked = s.get('look.id') === lookFilter;
        }

        if (searchInput !== '' && checked) {
          checked = s.get('searchString').toLowerCase().indexOf(searchInput) > -1;
        }

        if (colourFilter && colourFilter !== '' && checked) {
          checked = s.get('colourSearchString').toLowerCase().indexOf(colourFilter) > -1;
        }

        if (sizeFilter && sizeFilter !== '' && checked) {
          checked = s.get('sizeSearchString').toLowerCase() === sizeFilter;
        }

        if (itemFilter && itemFilter !== '' && itemFilter !== 'all samples' && checked) {
          checked = s.get('itemObject')['name'].toLowerCase() === itemFilter;
        }

        if (statusFilter && statusFilter !== 'All statuses' && checked) {
          var _status = null;
          var warning = s.warning;
          if (controller.get('bookingDetailsStatuses')) {
            _status = s.get('shoppingListStatus');
            if (_status) {
              checked = controller._checkStatusShoppingList(_status, statusFilter, warning);
            } else {
              checked = true;
            }
          } else {
            _status = routeName === 'inventory.index' ? s.get('inventoryStatus') : s.get('addSampleStatus');
            if (_status) {
              checked = controller._checkStatus(_status, statusFilter, warning);
            } else {
              checked = statusFilter === 'IN';
            }
          }
        }
        s.set('visible', checked);
        allSamplesUnvisible = allSamplesUnvisible && !checked;

        //Select sample through barcodes automatically
        if (container === '#addSamplesScrollTable') {
          var regexBarcode = /^\d{4}[\-\']\d{6}$/gm;
          if (checked && regexBarcode.test(searchInput)) {
            s.set('checked', true);
            $('#adding-samples-search-filter').val('');
            //this.send('openTopBar', 'Scanned sample ' + searchInput + ' has been selected');
            _this.send('openPositiveTopBar');
          }
        }
      });
      controller.set('allSamplesUnvisible', allSamplesUnvisible);

      _hideControllerSamples(hideControllerSamples, controller);

      if (container === '#manage-booking-samples') {
        Ember['default'].bulk.samples.setEach('visible', false);
      }

      utils['default'].lazyLoad(2000, lazyContainer);
    };
    utils['default'].addObserver(controller, ['collectionFilter', 'brandFilter', 'showroomFilter', 'lookFilter', 'searchInput', 'colourFilter', 'statusFilter', 'sizeFilter', 'itemFilter'], 'searchFilterFunction');

    controller._checkStatusShoppingList = function (status, statusFilter, warning) {
      status = status.toLowerCase();
      statusFilter = statusFilter.toLowerCase();

      var arraycontains = undefined;

      switch (statusFilter) {
        //INTERNAL
        case "booked":
          arraycontains = ['booked', 'in'].contains(status);
          break;
        case "out":
          arraycontains = ['check in today', 'out'].contains(status);
          break;
        case "check out today":
          arraycontains = status === 'check out today';
          break;
        case "late check out":
          arraycontains = status === 'in' && warning;
          break;
        case "check in today":
          arraycontains = status === 'check in today';
          break;
        case "late check in":
          arraycontains = status === 'out' && warning;
          break;
        case "canceled":
          arraycontains = status.indexOf("canceled") !== -1;
          break;
        //REQUESTED
        case "proposed":
          arraycontains = status.indexOf("propos") !== -1;
          break;
        case "declined":
          arraycontains = status.indexOf("declined") !== -1;
          break;
        default:
          arraycontains = status == statusFilter;
          break;
      }
      return arraycontains;
    };

    controller._checkStatus = function (status, statusFilter, warning) {
      status = status.toLowerCase();
      statusFilter = statusFilter.toLowerCase();

      var options = [];
      var arraycontains = undefined;

      if (statusFilter === 'in') {
        options = ['check out today', 'in'];
        arraycontains = options.contains(status);
      } else if (statusFilter === 'out') {
        options = ['check in today', 'out'];
        arraycontains = options.contains(status);
      } else if (statusFilter === 'late check-out') {
        arraycontains = status === 'in' && warning;
      } else if (statusFilter === 'late check-in') {
        arraycontains = status === 'out' && warning;
      } else {
        arraycontains = status === statusFilter;
      }

      return arraycontains;
    };

    controller.allBrands = Ember['default'].computed('collections.@each', function () {
      if (!controller.get('collections')) {
        return [];
      }

      _setVisibleSamples(onlySamplesSameShowroom, controller);

      var brandsArray = Ember['default'].A();

      //All Brands: Include it For Agencies & screens: Booking details
      if (!controller.get('isAgency')) {
        brandsArray = Ember['default'].A([{ id: null, name: 'All brands' }]);
      }

      var brands = controller.get('collections').reduce(function (brandList, nextCollection, index, array) {
        var content = nextCollection.get('brand.content');
        if (content) {
          brandList.addObject(content);
        }
        //IF LAST ELEMENT: SORT THE ARRAY ALPHABETICALLY
        if (index === array.get('length') - 1) {
          brandList = brandList.sort(function (a, b) {
            if (a.get && b.get) {
              return a.get('name').toLowerCase().localeCompare(b.get('name').toLowerCase());
            } else {
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });
        }
        return brandList;
        //}, Ember.A([{id: null, name:'All brands'}]));
      }, brandsArray);
      return brands;
    });

    controller.albumsFilteredByCollection = Ember['default'].computed('collectionFilter', function () {
      var collection = controller.get('allCollections')[0];

      controller.set('albumFilter', null);

      controller.set('lookFilter', null);
      controller.set('colourFilter', '');
      //controller.set('statusFilter', '');
      _setVisibleSamples(onlySamplesSameShowroom, controller);

      if (!collection || !collection.get) {
        return [];
      }
      var albums = collection.get('albums');
      var albumsFiltered = albums;

      if (controller.get('collectionFilter') !== '0') {
        albumsFiltered = albums.filter(function (album) {
          return album.get('collection.id') === controller.get('collectionFilter') && !album.get('archived') && album.get('published');
        });
      }

      albumsFiltered = albumsFiltered.sort(function (a, b) {
        if (a.get && b.get) {
          return a.get('albumTypeLabel').toLowerCase().localeCompare(b.get('albumTypeLabel').toLowerCase());
        } else {
          if (a.get) {
            return 1;
          } else {
            return -1;
          }
        }
      });

      return utils['default'].addDefaultOption(albumsFiltered, 'All Albums', 'albumTypeLabel', '');
    });

    controller.looksFilteredByAlbum = Ember['default'].computed('albumFilter', function () {
      controller.set('lookFilter', null);
      controller.set('colourFilter', '');
      controller.set('statusFilter', '');
      var album = controller.get('albumFilter');
      if (!album || !album.get) {
        return [];
      }
      var albumId = album.get('id');
      var looks = album.get('looks');
      var looksFiltered = looks;
      if (albumId !== '0') {
        looksFiltered = looks.filter(function (look) {
          return look.get('album.id') === albumId && look.get('archived') === false && look.get('hasActiveSamples');
        });
      }
      return utils['default'].addDefaultOption(looksFiltered, 'All Looks', 'position', '');
    });

    controller.oneBrand = Ember['default'].computed('allBrands.length', function () {
      if (controller.get('allBrands.length') === 2) {
        //controller.set('brandFilter', controller.get('allBrands')[1].get('id'));
        return true;
      }
      return false;
    });
    Ember['default'].activeFilter = container;
  }

  function _hideControllerSamples(hideControllerSamples, controller) {
    if (hideControllerSamples && controller.get('samples')) {
      controller.get('samples').forEach(function (sample) {
        return sample.set('visible', false);
      });
    }
  }

  function _setVisibleSamples(onlySamplesSameShowroom, controller) {
    var samples = controller.get('albumFilter.samples') || utils['default'].getSamples();

    if (onlySamplesSameShowroom) {
      samples.forEach(function (sample) {
        sample.setVisibleIfForeignCanPropose(controller.get('booking.owner.showroom'));
      });
    } else {
      samples.setEach('visible', true);
    }
    if (controller.searchFilterFunction) {
      controller.searchFilterFunction();
    }
  }

  function filterByCollection(collectionList, collectionFilter) {
    var collectionsFiltered = collectionList;

    if (collectionFilter !== null && collectionFilter !== '') {
      collectionsFiltered = collectionList.filter(function (collection) {
        return collection.id === collectionFilter && collection.get('archived') === false;
      });
    }
    return collectionsFiltered;
  }

});