define('bookalook/helpers/is-in', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var sampleStatus = params.shift();
		sampleStatus = sampleStatus || "";
		var statusToCompare = params;

		return statusToCompare.some(function (s) {
			s = s || "";
			return s.toLowerCase() === sampleStatus.toLowerCase();
		});
	});

});