define('bookalook/routes/admin/mp-users', ['exports', 'ember', 'bookalook/routes/admin/functions', 'bookalook/utils'], function (exports, Ember, functions, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    actions: {
      verify: functions['default'].verify,
      discard: functions['default'].discard,
      makeNewAgain: functions['default'].makeNewAgain,
      toggleUserSearchable: function toggleUserSearchable(user) {
        functions['default'].toggleUserSearchable(user, this);
      },
      changeUsersStatusFilter: functions['default'].changeUsersStatusFilter,

      deleteUser: function deleteUser(user) {
        functions['default'].removeMPUser(user, this);
      }
    },

    model: function model() {
      return this.store.findAll('user');
    },

    setupController: function setupController(controller, users) {
      functions['default'].setupAdminUserController(controller, users, 'isMP');

      //utils.getAPIJSON('admin/mp-users').then(numberJobs => {
      //  this.controller.set('numberJobs', numberJobs);
      //});
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-admin');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-admin');
    }
  });

});