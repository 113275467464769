define('bookalook/templates/svgs/plane', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 6
          }
        },
        "moduleName": "bookalook/templates/svgs/plane.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"version","1.1");
        dom.setAttribute(el1,"id","Capa_1");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"xmlns:xlink","http://www.w3.org/1999/xlink");
        dom.setAttribute(el1,"x","0px");
        dom.setAttribute(el1,"y","0px");
        dom.setAttribute(el1,"viewBox","0 0 108 106.9");
        dom.setAttribute(el1,"style","enable-background:new 0 0 108 106.9;");
        dom.setAttributeNS(el1,"http://www.w3.org/XML/1998/namespace","xml:space","preserve");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2,"id","XMLID_500_");
        dom.setAttribute(el2,"d","M62.4,51.2C63.3,50,64,49,64.8,48c4-5.2,8-10.4,12-15.7c1-1.3,0.8-2.2-0.7-3c-1.4-0.7-2.7-1.3-4.1-2\n	c-1-0.5-1.9-0.5-2.9,0.2c-4.2,3-8.4,5.9-12.6,8.8c-5.9,4.1-11.9,8.3-17.8,12.4c-0.4,0.3-1,0.5-1.5,0.5c-5.3,0.2-10.6,0.4-15.9,0.6\n	c-4.5,0.2-8.4,1.8-11.4,5.3c-0.6,0.7-1.2,1.6-1.6,2.5c-1,2.1-0.4,4,1.5,5.3c1.7,1.2,3.7,1.6,5.7,1.7c3.8,0.1,7.6,0,11.4,0\n	c21-0.1,42-0.3,63-0.4c3.8,0,6.5-1.7,8.2-5.1c1.7-3.5,3.4-6.9,5.1-10.4c0.8-1.6,0.5-2.3-1.1-3.1c-1-0.5-2-1-2.9-1.4\n	c-1.5-0.7-2.3-0.6-3.5,0.6c-2.5,2.5-5,5-7.6,7.5c-0.3,0.3-0.9,0.6-1.4,0.5c-7.8-0.5-15.6-1-23.5-1.5C63.1,51.3,62.9,51.2,62.4,51.2z\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});