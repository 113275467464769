define('bookalook/tests/helpers/remove-spaces.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/remove-spaces.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'helpers/remove-spaces.js should pass jshint.');
  });

});