define('bookalook/components/delete-process', ['exports', 'ember', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, utils, config) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    //entity
    //entityObj
    //route
    //modal
    //look=null
    //dummy -> for automatic update after open

    relatedBookings: [],
    inTransfer: false,

    isLoading: true,

    actions: {
      /*
       *  Open the modal and make a request in order to know if you can delete that element or not.
       *  @entity: element you want to delete. Can be collection, album, look or sample.
       * */
      deleteEntity: function deleteEntity() {
        var _this = this;

        var entity = this.get('entity');
        var entityObj = this.get('entityObj');
        var promise = undefined;
        var lookWillBeDeleted = false;
        var albumWillBeDeleted = false;
        var album = null;
        var albumToCheck = null;
        var albumToCheckId = null;
        var albumToCheckObj = null;
        var btn = Ember['default'].$("#delete-confirmation-btn");
        btn.button('loading');
        //Selected samples from album case
        if (entity === 'samplesFromAlbumView') {
          (function () {

            var selectedSamples = [];

            Ember['default'].bulk.allSamples.forEach(function (sample) {
              if (sample.checked && sample.get('look').get('id') == entityObj.get('id')) {
                selectedSamples.push(sample.get('id'));
                sample.set('visible', false);
              }
            });

            var postData = {
              selectedSamples: selectedSamples
            };
            promise = utils['default'].deleteAPIJSON('albums/delete/deleteSelectedSamples', postData);
            promise.then(function () {
              _this.get('route').send('openTopBar', 'Selected sample(s) deleted correctly.');
              _this.onCancelDeleteEntity();
              entityObj.reload();
            }, function () {
              _this.get('route').send('openTopBar', 'There was an error deleting ' + _this.get('singularEntityName') + '.');
              _this.onCancelDeleteEntity();
            });
          })();
        } else {

          //If we remove a look, check all looks from albums
          if (entity == 'looks') {
            albumToCheck = entityObj.get('album');
            albumToCheckId = albumToCheck.get('id');
          }

          //promise = entityObj.destroyRecord();
          promise = entityObj.destroyRecord();
          promise.then(function () {

            //If we remove a look, check all looks from albums
            if (entity == 'looks') {
              utils['default'].getAPIJSON('albums/' + albumToCheckId + '/isReadyForArchive').then(function (res) {
                if (res) {
                  //ReadyForArchive !
                  _this.get('route').store.findRecord('album', albumToCheckId).then(function (a) {
                    if (!a.get('archived')) {
                      a.set('archived', true);
                      _this.get('route').send('openTopBar', 'The ' + _this.get('singularEntityName') + ' was deleted correctly. Also, the album was archived because all looks are archived.');
                    } else {
                      _this.get('route').send('openTopBar', 'The ' + _this.get('singularEntityName') + ' was deleted correctly.');
                    }
                    _this.onCancelDeleteEntity();
                  });
                } else {
                  _this.onCancelDeleteEntity();
                }
              });
            } else {
              _this.get('route').send('openTopBar', 'The ' + _this.get('singularEntityName') + ' was deleted correctly.');
              _this.onCancelDeleteEntity();
            }
          }, function () {
            _this.get('route').send('openTopBar', 'There was an error removing the ' + _this.get('singularEntityName') + '.');
            _this.onCancelDeleteEntity();
          });
        }
      },

      cancelDeleteEntity: function cancelDeleteEntity() {
        this.onCancelDeleteEntity();
      },

      goToInventoryFromDelete: function goToInventoryFromDelete() {
        var extra = function extra() {
          Ember['default'].$('#delete-confirmation').modal('hide');
        };
        utils['default'].goToInventoryWithFilterStatus(this, "IN TRANSFER", extra);
      },

      getRelatedBookingsReport: function getRelatedBookingsReport() {
        this.onGetRelatedBookingsReport();
      }
    },

    onCancelDeleteEntity: function onCancelDeleteEntity() {
      Ember['default'].$('#delete-confirmation').modal('hide');
      var btn = Ember['default'].$("#delete-confirmation-btn");
      btn.button('reset');
      if (this.get('modal')) {
        Ember['default'].$(this.get('modal')).modal('show');
        Ember['default'].openModal();
      }
    },

    changeEntityObserver: (function () {
      this.set('isLoading', true);
      Ember['default'].run.once(this, 'changeEntity');
    }).observes('entity', 'entityObj', 'dummy'),

    changeEntity: function changeEntity() {
      var _this2 = this;

      var entity = this.get('entity');
      var entityObj = this.get('entityObj');
      //Caso: borrar samples desde vista Album
      if (entity === 'samplesFromAlbumView') {
        (function () {

          var selectedSamples = [];

          Ember['default'].bulk.allSamples.forEach(function (sample) {
            if (sample.checked && sample.get('look').get('id') == entityObj.get('id')) {
              selectedSamples.push(sample.get('id'));
            }
          });

          var postData = {
            selectedSamples: selectedSamples
          };

          utils['default'].postAPIJSON('albums/archivableSamples', postData).then(function (res) {
            _this2.set('relatedBookings', res.bookings.sortBy('id'));
            _this2.set('inTransfer', res.inTransfer);
            _this2.set('isLoading', false);
          });
        })();
      } else {
        utils['default'].getAPIJSON(this.get('entity') + '/' + this.get('entityObj.id') + '/archivable').then(function (res) {
          _this2.set('relatedBookings', res.bookings.sortBy('id'));
          _this2.set('inTransfer', res.inTransfer);
          _this2.set('isLoading', false);
        });
      }
    },

    onGetRelatedBookingsReport: function onGetRelatedBookingsReport() {
      var options = [];

      switch (this.get('entity')) {
        case 'samples':
          options.push('sample=' + this.get('entityObj.id'));
          break;
        case 'looks':
          options.push('look=' + this.get('entityObj.id'));
          break;
        case 'albums':
          //options.push('collection=' + this.get('entityObj.collection.id'));
          options.push('album=' + this.get('entityObj.id'));
          break;
        case 'collections':
          options.push('collection=' + this.get('entityObj.id'));
          break;
      }

      window.open(config['default'].APIURL + 'report?' + options.join('&'), '_blank' // <- This is what makes it open in a new window.
      );
    },

    singularEntityName: (function () {
      var entityName = this.get('entity');
      if (entityName === 'samplesFromAlbumView') {
        return 'selected sample(s)';
      } else {
        return entityName.substr(0, entityName.length - 1);
      }
    }).property('entity')
  });

});