define('bookalook/helpers/contains', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (params[0] && params[1]) {
      return params[0].contains(params[1]);
    }
  });

});