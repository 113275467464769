define('bookalook/components/booking-report-modal', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils'], function (exports, Ember, config, utils) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      var _this = this;

      /*console.log("pasa");*/
      this._super.apply(this, arguments);
      var $el = Ember['default'].$(this.element);
      var $modal = $el.find('#booking-report-modal');
      $modal.on('shown.bs.modal', function () {
        //this or return new Promise from the function
        utils['default'].getCollections(_this, false).then(function (newCollections) {
          _this.set('collections', newCollections);
          var brands = _this.get('allBrands');
          if (brands.length == 2) {
            _this.set('brandFilterEnabled', false);
            _this.set('brandFilter', brands[1]);
          } else {
            _this.set('brandFilterEnabled', true);
          }
        });
      });
    },

    actions: {
      getReport: function getReport() {
        var options = ['from=' + utils['default'].dateToDayFormat(this.get('fromDate')), 'to=' + utils['default'].dateToDayFormat(this.get('toDate')), 'showroom=' + Ember['default'].showroomId];

        if (this.get('brandFilter.id')) {
          options.push('brand=' + this.get('brandFilter.id'));
        }

        if (this.get('collectionFilter.id')) {
          options.push('collection=' + this.get('collectionFilter.id'));
        }

        if (this.get('albumFilter.id')) {
          options.push('album=' + this.get('albumFilter.id'));
        }

        window.open(config['default'].APIURL + 'report?' + options.join('&'), '_blank' // <- This is what makes it open in a new window.
        );
        Ember['default'].$('#booking-report-modal').modal('hide');
      }
    },

    datesSelected: Ember['default'].computed('fromDate', 'toDate', function () {
      return this.get('fromDate') && this.get('toDate');
    }),

    allBrands: Ember['default'].computed('collections.@each', function () {
      if (!this.get('collections')) {
        return [];
      }

      var brands = this.get('collections').reduce(function (brandList, nextCollection, index, array) {
        var content = nextCollection.get('brand.content');
        if (content) {
          brandList.addObject(content);
        }
        //IF LAST ELEMENT: SORT THE ARRAY ALPHABETICALLY
        if (index === array.get('length') - 1) {
          brandList = brandList.sort(function (a, b) {
            if (a.get && b.get) {
              return a.get('name').toLowerCase().localeCompare(b.get('name').toLowerCase());
            } else {
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });
        }
        return brandList;
      }, Ember['default'].A([{ id: null, name: 'All brands' }]));
      return brands;
    }),

    collectionsByBrand: Ember['default'].computed('brandFilter', function () {
      var _this2 = this;

      if (!this.get('collections')) {
        return [];
      }

      var collectionsFiltered = this.get('collections').filter(function (collection) {
        return collection.get('brand.id') === _this2.get('brandFilter.id');
      });
      this.set('collectionFilter', null);

      return utils['default'].addDefaultOption(collectionsFiltered, 'All Collections', 'filterName', null);
    }),

    albumsByCollection: Ember['default'].computed('collectionFilter', function () {
      if (!this.get('collectionFilter.id')) {
        this.set('albumFilter', null);
        return [];
      }

      var albumsFiltered = this.get('collectionFilter.albums');

      return utils['default'].addDefaultOption(albumsFiltered, 'All Albums', 'albumTypeLabel', null);
    })
  });

});