define('bookalook/routes/contacts-lists/functions', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils'], function (exports, Ember, config, utils) {

  'use strict';

  exports.setupController = setupController;
  exports.onAddListToInvite = onAddListToInvite;
  exports.onOpenInviteContactModal = onOpenInviteContactModal;
  exports.onOpenInviteAllContactsModal = onOpenInviteAllContactsModal;
  exports.onOpenMpSlider = onOpenMpSlider;
  exports.onSetEditionList = onSetEditionList;
  exports.onChangeSelectedList = onChangeSelectedList;
  exports.onSaveList = onSaveList;
  exports.onAddContact = onAddContact;
  exports.onDeleteLists = onDeleteLists;
  exports.onSaveContactFromUser = onSaveContactFromUser;
  exports.createMemberContact = createMemberContact;
  exports.addListsToContact = addListsToContact;
  exports.onSaveNewContact = onSaveNewContact;
  exports.onSaveEditContact = onSaveEditContact;
  exports.saveContactInList = saveContactInList;
  exports.onUploadExcel = onUploadExcel;
  exports.onSendInvitation = onSendInvitation;
  exports.onSendInvitationToLists = onSendInvitationToLists;
  exports.onAddAnotherAddress = onAddAnotherAddress;
  exports.onDownloadContacts = onDownloadContacts;
  exports.openNewContactModal = openNewContactModal;
  exports.openNewContactFromEditBookingModal = openNewContactFromEditBookingModal;
  exports.contactsUnique = contactsUnique;
  exports.contactsFilteredByTypeComputed = contactsFilteredByTypeComputed;
  exports.setAllContactsCheckedObserver = setAllContactsCheckedObserver;
  exports.setCanAddContactObserver = setCanAddContactObserver;
  exports.setListsFilteredBySelectedUserObserver = setListsFilteredBySelectedUserObserver;
  exports.setListsFilteredBySelectedUserObserverBooking = setListsFilteredBySelectedUserObserverBooking;
  exports.setComputedNewContact = setComputedNewContact;
  exports.onOpenNoAllowedContactsModal = onOpenNoAllowedContactsModal;
  exports.onCheckAllContacts = onCheckAllContacts;
  exports.setListsFilteredByContactToDuplicate = setListsFilteredByContactToDuplicate;

  function setupController(controller, contactLists) {
    controller.set('inviteFilterContent', [{ id: 1, text: 'LISTS' }, { id: 2, text: 'CONTACTS' }]);
    controller.set('inviteFilterSelected', 1);
    controller.listSelected = Ember['default'].computed.equal('inviteFilterSelected', 1);

    resetNonMembersArrays(controller, contactLists);
  }

  function resetNonMembersArrays(controller, contactLists) {
    var nonMemberContacts = [];
    contactLists.forEach(function (l) {
      return nonMemberContacts.addObjects(l.get('nonMembers'));
    });

    controller.set('nonMemberContacts', nonMemberContacts);
    controller.set('nonMemberContactsToInvite', []);
    controller.set('contactsListsInvite', Ember['default'].A(contactLists));
    controller.set('contactsListsToInvite', []);
  }

  function onAddListToInvite(route, list) {
    var lists = route.controller.get('contactsListsInvite');
    if (lists.contains(list)) {
      lists.removeObject(list);
      route.controller.get('contactsListsToInvite').addObject(list);
    } else {
      route.controller.get('contactsListsToInvite').removeObject(list);
      lists.addObject(list);
    }
  }

  function onOpenInviteContactModal(route, contact) {
    route.controller.set('invitedContact', contact);

    if (localStorage.getItem('rememberDontAsk')) {
      onSendInvitation(route, contact);
    } else {
      Ember['default'].$('#invite-join').modal();
      Ember['default'].openModal();
    }
  }

  function onOpenInviteAllContactsModal(route) {
    route.controller.set('inviteContactsSelectedLists', []);
  }

  function onOpenMpSlider(route, mp) {
    utils['default'].getAPIJSON('contacts/get_by_mp/' + mp.id).then(function (r) {
      var contact = utils['default'].pushPayload(route.store, 'contact', r.contact);
      route.send('openViewContactProfileModal', contact);
    });
  }

  function onSetEditionList(route, list) {
    list = list || route.store.createRecord('contacts-list', { owner: Ember['default'].user, privacy: "Only me", users: [Ember['default'].user] });
    route.controller.set('listToEdit', list);
    //Merging CI, we thing its unnecesary now
    //route.refresh();
  }

  function onChangeSelectedList(route, list) {
    var oldSelec = route.controller.get('selectedList');
    if (oldSelec == null || oldSelec.get('id') != list.get('id')) {
      onCheckAllContacts(route, false);
      route.controller.set('searchInput', '');
      route.controller.set('contactsSorting', null);
      route.controller.set('searchInput', '');
      route.controller.set('selectedList', list);
    }
  }

  function onSaveList(route, list) {
    var contactLists = route.controller.get('contactLists').slice(0);
    if (list.validate()) {
      Ember['default'].$('#new-list').modal('hide');
      route.send('openMessageModal', 'Saving list ' + list.get('name') + '. Please Wait.');
      list.save().then(function () {
        onChangeSelectedList(route, list);
        route.controller.notifyPropertyChange('contactLists');
        //Merging CI, we thing its unnecesary now
        //route.refresh();
        route.send('closeMessageModal');
      });
    }
  }

  function onAddContact(route) {
    route.controller.set('contactToAdd', route.store.createRecord('contact'));
    route.controller.set('addContactSelectedUser', 0);
    route.controller.set('canAddContact', false);

    route.controller.set('bookalookUsersLoading', false);
    var users = utils['default'].getAPIJSON('users/mp_contacts').then(function (res) {
      res.map(function (user) {
        //Add img src for show avatar in combo
        var safeString = user.safeString;
        var img = "<img src='" + user.avatar + "' class='avatar-select' />";
        var email = " <span class='display_none'>" + user.email + '</span>';
        user.safeString = new Ember['default'].Handlebars.SafeString(img + safeString + email);

        return user;
      });
      route.controller.set('bookalookUsers', res);
      route.controller.set('bookalookUsersLoading', "enabled");
    });

    route.controller.set('addContactSelectedLists', []);
    //Ember.$('#add-contact').modal();
    //Ember.openModal(1000, '#add-contact');
    Ember['default'].$('#new-refactor-contact').modal();
    Ember['default'].openModal(1000, '#new-refactor-contact');
    this.setCanAddContactObserver(route.controller);
  }

  function onDeleteLists(route) {
    var lists = route.controller.get('listsToDelete');
    route.transitionTo('contacts-lists.index').then(function () {
      lists.forEach(function (list) {
        list.destroyRecord();
      });
      onCheckAllContacts(route, false);
      route.controller.set('selectedList', null);
      route.controller.set('listsToDelete', []);
      route.controller.set('deleteMultiple', false);
    });
  }

  function onSaveContactFromUser(route) {
    //Check limits
    var contactsAllowedForPlanUser = route.controller.get('contactsAllowedForPlanUser');

    if (contactsAllowedForPlanUser != true) {
      this.onOpenNoAllowedContactsModal();
      //Do rollbackAttributes

      //Hide modals
      Ember['default'].$('#new-refactor-contact').modal('hide');
      Ember['default'].$('#add-contact').modal('hide');
    } else {

      var user = route.controller.get('addContactSelectedUser');

      route.store.find('contact', { user: user.id }).then(function (data) {
        var contact = data.objectAt(0);
        var selectedLists = route.controller.get('addContactSelectedLists');

        if (contact) {
          addListsToContact(selectedLists, contact).then(function () {
            Ember['default'].$('#new-refactor-contact').modal('hide');
            Ember['default'].$('#add-contact').modal('hide');
            //route.send('openTopBar', 'Contact added succesfully.');
            route.send('openPositiveTopBar');
          });
        } else {
          createMemberContact(route, user, selectedLists);
          Ember['default'].$('#new-refactor-contact').modal('hide');
          Ember['default'].$('#add-contact').modal('hide');
          //route.send('openTopBar', 'Contact added succesfully.');
          route.send('openPositiveTopBar');
        }
      });
    }
  }

  function createMemberContact(route, user, lists) {

    route.store.findRecord('user', user.id).then(function (userObject) {

      var createdContact = route.store.createRecord('contact', {
        name: userObject.get('name'),
        surname: userObject.get('surname'),
        jobTitle: userObject.get('jobTitle'),
        email: userObject.get('email'),
        company: userObject.get('company'),
        user: userObject
      });
      createdContact.get('contactsLists').addObjects(lists);
      createdContact.save().then(function () {
        route.controller.notifyPropertyChange('model');
        Ember['default'].run.later(function () {
          createdContact.reload();
        }, 1000);
      });
    });
    /*
    var user = this.store.findRecord().then(u )>;
    var createdContact = route.store.createRecord('contact', {
      name: user.name,
      surname: user.surname,
      jobTitle: user.jobTitle,
      email: user.email,
      company: user.company,
      user: user
    });
    createdContact.get('contactsLists').addObjects(lists);
    createdContact.save().then(function() {
      route.controller.notifyPropertyChange('contactLists');
      Ember.run.later(function() {
        createdContact.reload();
      }, 1000);
    });
    */
  }

  function addListsToContact(lists, contact) {
    var deferred = Ember['default'].$.Deferred();
    var contactsLists = contact.get('contactsLists');
    contactsLists.addObjects(lists);
    contact.save().then(function (newContact) {
      deferred.resolve(newContact);
    });
    //lists.forEach(l => l.reload());
    return deferred;
  }

  function onSaveNewContact(route) {
    var lists = route.controller.get('addContactSelectedLists');
    var address = route.controller.get('contactToAddAddress');
    var contact = route.controller.get('contactToAdd');
    var invite = route.controller.get('invite-join');
    var addressesDeferred = Ember['default'].$.Deferred();
    var deferred = Ember['default'].$.Deferred();
    var promises = [];

    var listsIds = lists.map(function (l) {
      return parseInt(l.get('id'));
    }, 10);
    var privaddresses = Ember['default'].A();
    contact.get('privateAddresses').forEach(function (a) {
      privaddresses.pushObject(a);
    });

    Ember['default'].$('#add-contact, #new-contact').modal('hide');
    route.send('openMessageModal', 'Creating contact...');

    return utils['default'].postAPIJSON('contacts/manualContact', { 'contactArray': contact, 'lists': listsIds, 'mainAddress': address, 'privateAddresses': privaddresses, 'inviteJoin': invite }).then(function (c) {
      lists.forEach(function (l) {
        return l.reload();
      });
      return route.store.findRecord('contact', c.contact.id).then(function (c) {
        //route.send('openTopBar', 'Contact has been created succesfully');
        route.send('openPositiveTopBar');
        route.send('closeMessageModal');
        return c;
      });
    }, function (fail) {
      route.send('openTopBar', 'Contact create has failed.');
      route.send('closeMessageModal');
    });
  }

  function onSaveEditContact(route) {
    var lists = route.controller.get('addContactSelectedLists');
    var address = route.controller.get('contactToEditAddress');
    var contact = route.controller.get('contactToEdit');
    var addressesDeferred = Ember['default'].$.Deferred();
    var deferred = Ember['default'].$.Deferred();
    var promises = [];

    if (!contact.validate() || !address.isFormDataEmpty() && !address.validate()) {
      return false;
    }

    contact.save();

    contact.get('privateAddresses').forEach(function (privateAddress) {
      var promise = privateAddress.save();
      promises.push(promise);
    });

    Ember['default'].RSVP.all(promises).then(function () {
      deferred.resolve();
    });

    deferred.done(function () {
      addListsToContact(lists, contact).done(function (editContact) {

        contact.get('privateAddresses').forEach(function (privateAddress) {
          privateAddress.set('contact', editContact);
          privateAddress.save();
        });

        if (!address.isFormDataEmpty()) {
          address.set('public', editContact);
          address.save().then(function () {
            Ember['default'].$('#add-contact, #new-contact').modal('hide');
            addressesDeferred.resolve();
          });
        } else {
          Ember['default'].$('#add-contact, #new-contact').modal('hide');
          addressesDeferred.resolve();
        }

        //Send Invitation
        var invite = route.controller.get('invite-join');
        //console.log(editContact.get('id'));
        if (invite) {
          var $ = Ember['default'].$;
          $.ajax({
            url: config['default'].APIURL + 'inviteToJoin',
            data: JSON.stringify({ ids: [editContact.get("id")] }),
            contentType: 'application/json',
            dataType: 'json',
            type: 'POST',
            success: function success() {
              route.send('openTopBar', 'Contact modified and invitation sent');
            }
          });
        } else {
          //route.send('openTopBar', 'Contact has been modified succesfully');
          route.send('openPositiveTopBar');
        }
      });
    });
    return addressesDeferred;
  }

  function saveContactInList(route, contact, list) {
    if (contact.get('user')) {
      return addListsToContact([list], contact);
    }
    var isUser = contact.constructor.typeKey === 'user';
    var user = isUser ? contact : contact.get('user');
    var company = isUser ? '' : contact.get('company');

    var deferred = Ember['default'].$.Deferred();
    var promises = [];

    var createdContact = route.store.createRecord('contact', {
      name: contact.get('name'),
      surname: contact.get('surname'),
      jobTitle: contact.get('jobTitle'),
      email: contact.get('email'),
      email2: contact.get('email2'),
      notes: contact.get('notes'),
      company: company,
      user: user
    });
    createdContact.get("contactsLists").addObject(list);
    createdContact.save().then(function (newContact) {
      route.controller.notifyPropertyChange('contactLists');
      var addresses = isUser ? null : contact.get('contactAddresses');
      if (addresses) {
        addresses.then(function (resAddresses) {
          resAddresses.forEach(function (address) {
            var newAddress = route.store.createRecord('contact-address', {
              contact: newContact
            });
            var properties = address.toJSON();
            delete properties['contact'];
            newAddress.setProperties(properties);
            var promise = newAddress.save();
            promises.push(promise);
          });

          // When all addresses are duplicated resolve
          Ember['default'].RSVP.all(promises).then(function () {
            deferred.resolve();
          });
        });
      }
    });

    return deferred;
  }

  function onUploadExcel(route) {
    var $ = Ember['default'].$;
    var input = $('#import-contacts-input')[0];

    var listToImport = route.controller.get('listToImport');
    route.send('openMessageModal', 'Importing contacts to ' + listToImport.get('name') + '. Please Wait.');

    var formData = new FormData();
    formData.append('bookalook_filebundle_excel[name]', listToImport.get('id'));
    formData.append('bookalook_filebundle_excel[file]', input.files[0]);

    $.ajax({
      url: config['default'].APIURL + 'excels/' + listToImport.get('id'),
      beforeSend: function beforeSend(request) {
        request.setRequestHeader("file", "file");
      },
      data: formData,
      processData: false,
      contentType: false,
      type: 'POST',
      success: function success(data) {
        if (data.excel.error) {
          route.send('closeMessageModal');
          route.send('openErrorsModal', [data.excel.error], 'Your excel file has some errors. Please fix them and try again.');
        } else if (data.excel.duplicated.length > 0) {
          route.send('closeMessageModal');
          route.send('openErrorsModal', data.excel.duplicated, 'Your excel file has some errors. Please fix them and try again.');
        } else {
          Ember['default'].run.later(function () {
            route.send('closeMessageModal');
            //Merging CI, we thing its unnecesary now
            //route.refresh();
          }, 2000);
          route.controller.set('listToImport', null);
          route.controller.get('selectedList').reload();
          route.controller.notifyPropertyChange('visibleContacts');
        }
      },
      error: function error(data) {
        var error = data.responseJSON.errors;
        route.send('closeMessageModal');
        if (error) {
          if (error.contacts) {
            route.send('openErrorsModal', [error.contacts], 'There was an error.');
          } else {
            route.send('openErrorsModal', [], 'There was an error. Please try again.');
          }
        } else {
          route.send('openErrorsModal', [], 'There was an error. Please try again.');
        }
      }
    });
  }

  function onSendInvitation(route, contact) {
    if (localStorage.getItem('rememberDontAsk') || route.controller.get('rememberDontAsk')) {
      localStorage.setItem('rememberDontAsk', true);
    }

    var $ = Ember['default'].$;
    $.ajax({
      url: config['default'].APIURL + 'inviteToJoin',
      data: JSON.stringify({ ids: [contact.get("id")] }),
      contentType: 'application/json',
      dataType: 'json',
      type: 'POST',
      success: function success() {
        route.send('openTopBar', 'Invitation sent.');
      }
    });
  }

  function onSendInvitationToLists(route, lists) {
    var ids = [];
    if (lists) {
      lists.forEach(function (list) {
        ids = ids.concat(list.get('contacts').filter(function (c) {
          return !c.get('hasUser');
        }));
      });
      ids = ids.mapBy('id');
    }

    var $ = Ember['default'].$;
    $.ajax({
      url: config['default'].APIURL + 'inviteToJoin',
      data: JSON.stringify({ ids: ids }),
      contentType: 'application/json',
      dataType: 'json',
      type: 'POST',
      success: function success() {
        route.send('openTopBar', 'Invitation sent.');
      }
    });
  }

  function onAddAnotherAddress(route, editContact) {
    var contact = editContact || route.controller.get('contactToAdd');
    var record = route.store.createRecord('contact-address', { contact: contact });
    contact.get("privateAddresses").addObject(record);
    return record;
  }

  function onDownloadContacts(contactsListId) {
    //var arrContacts = contacts.map(c => c.id);
    window.open(
    //config.APIURL + 'excels?ids=' + arrContacts.join(','),
    config['default'].APIURL + 'excels?listid=' + contactsListId, '_blank' // <- This is what makes it open in a new window.
    );
  }

  function openNewContactModal(route) {
    //Modal to add contact manually
    Ember['default'].$('#new-refactor-contact').modal('hide');

    Ember['default'].run.later(function () {
      //     Ember.$('body').addClass('modal-open');
      Ember['default'].openModal();
    }, 1000);

    var publicAddress = route.store.createRecord('contact-address');
    route.controller.set('contactToAddAddress', publicAddress);
    route.controller.set('contactToAdd', route.store.createRecord('contact', { publicAddress: publicAddress }));

    route.controller.set('cAddressesListNotLoading', false);
    route.controller.set('cAddressesListHide', true);
    utils['default'].getAPIJSON('contactsLists/addresses').then(function (items) {
      if (Ember['default'].isEmpty(items)) {
        route.controller.set('cAddressesListHide', true);
      } else {
        route.controller.set('cAddressesListHide', false);
      }
      route.controller.set('cAddressesListNotLoading', true);

      items.map(function (it) {
        var phrase = '<span class="safestring">' + it.fullName + ' | ' + it.addressSafeString + '</span>';
        var img = "";
        if (!Ember['default'].isNone(it.avatar)) {
          img = "<img src='" + it.avatar + "' class='avatar-select' />";
        } else {
          img = '<div class="avatar-select ' + it.type + '"><span class="letters">' + it.nameInitials + '</span></div>';
        }
        it.safeString = new Ember['default'].String.htmlSafe('<div class="user-select-ellipsis">' + img + phrase + '</div>');
      });
      route.controller.set('contactsAddresses', items);
    }, function () {
      route.controller.set('cAddressesListHide', true);
      route.controller.set('cAddressesListNotLoading', true);
      route.controller.set('contactsAddresses', []);
    });

    Ember['default'].$(".email-contact div input").blur(function () {
      var input = ".email-contact div input";
      var inputVal = Ember['default'].$(input).val();

      if (inputVal.length > 0) {
        utils['default'].postAPIJSON('users/mp_by_email', { 'email': inputVal }).then(function (res) {
          if (res.user) {
            route.controller.set('addContactSelectedUser', res.user);
            //Esconder modal de contacto manual nuevo y abrir contacto agregado
            Ember['default'].$('#new-refactor-manual-contact').modal('hide');
            Ember['default'].$('#new-contact-refactor-booking').modal('hide');

            Ember['default'].$('#add-mp-found').modal();
            Ember['default'].openModal();
          }
        });
      }
    });
  }

  function openNewContactFromEditBookingModal(route) {
    //Modal to add contact manually
    //Ember.$('#new-manual-contact-editing-booking').modal('hide');

    Ember['default'].run.later(function () {
      Ember['default'].$('#new-manual-contact-editing-booking').modal();
      Ember['default'].openModal();
    }, 500);

    var publicAddress = route.store.createRecord('contact-address');
    route.controller.set('contactToAddAddress', publicAddress);
    route.controller.set('contactToAdd', route.store.createRecord('contact', { publicAddress: publicAddress }));

    route.controller.set('cAddressesListNotLoading', false);
    route.controller.set('cAddressesListHide', true);
    utils['default'].getAPIJSON('contactsLists/addresses').then(function (items) {
      if (Ember['default'].isEmpty(items)) {
        route.controller.set('cAddressesListHide', true);
      } else {
        route.controller.set('cAddressesListHide', false);
      }
      route.controller.set('cAddressesListNotLoading', true);

      items.map(function (it) {
        var phrase = '<span class="safestring">' + it.fullName + ' | ' + it.addressSafeString + '</span>';
        var img = "";
        if (!Ember['default'].isNone(it.avatar)) {
          img = "<img src='" + it.avatar + "' class='avatar-select' />";
        } else {
          img = '<div class="avatar-select ' + it.type + '"><span class="letters">' + it.nameInitials + '</span></div>';
        }
        it.safeString = new Ember['default'].String.htmlSafe('<div class="user-select-ellipsis">' + img + phrase + '</div>');
      });
      route.controller.set('contactsAddresses', items);
    }, function () {
      route.controller.set('cAddressesListHide', true);
      route.controller.set('cAddressesListNotLoading', true);
      route.controller.set('contactsAddresses', []);
    });

    Ember['default'].$(".email-contact div input").blur(function () {
      var input = ".email-contact div input";
      var inputVal = Ember['default'].$(input).val();

      if (inputVal.length > 0) {
        utils['default'].postAPIJSON('users/mp_by_email', { 'email': inputVal }).then(function (res) {
          if (res.user) {
            route.controller.set('addContactSelectedUser', res.user);
            //Esconder modal de contacto manual nuevo y abrir contacto agregado
            Ember['default'].$('#new-contact-editing-booking').modal('hide');

            Ember['default'].$('#add-mp-found').modal();
            Ember['default'].openModal();
          }
        });
      }
    });
  }

  function contactsUnique() {
    return Ember['default'].computed('contactLists.@each.contactsNumber', function () {
      var contactsIds = this.get('contactLists').map(function (l) {
        return l.get('contactsIds');
      });
      var ids = contactsIds.reduce(function (prev, next) {
        prev.addObjects(next);
        return prev;
      }, Ember['default'].A());
      return ids.uniq();
    });
  }

  function contactsFilteredByTypeComputed() {
    return Ember['default'].computed('contactLists.@each.contacts', 'contactsType', function () {
      var array = this.get('contactLists').toArray();
      var contactsType = this.get('contactsType');

      var ret = [];
      for (var i = 0; i < array.length; i++) {
        var contacts = array[i].get("contacts").toArray();
        switch (contactsType) {

          case 2:
          case 3:
            ret = ret.concat(contacts.filterBy('hasUser', contactsType === 2));
            break;

          default:
            ret = ret.concat(contacts);
        }
      }

      return ret;
    });
  }

  function setAllContactsCheckedObserver(controller) {
    var key = 'allContactsChecked';
    var observe = function observe() {
      var checked = this.allContactsChecked;
      var allContacts = this.get('allContacts');
      allContacts.setProperties({ checked: false });
      allContacts.forEach(function (c) {
        return c.set('checked', checked);
      });
    };

    controller.removeObserver(key, observe);
    controller.addObserver(key, observe);
  }

  function setCanAddContactObserver(controller) {
    var key = ['addContactSelectedUser', 'addContactSelectedLists.length'];
    var observe = function observe() {
      var addContactSelectedUser = controller.get('addContactSelectedUser');
      var addContactSelectedLists = controller.get('addContactSelectedLists');
      controller.set('canAddContact', addContactSelectedUser && addContactSelectedLists.length);
    };
    utils['default'].addObserver(controller, key, observe);
  }

  function setListsFilteredBySelectedUserObserver(controller) {
    controller.addContactSelectedUserObserver = function () {
      var addContactSelectedUser = controller.get('addContactSelectedUser');
      var contactListsFiltered = Ember['default'].A();
      if (!Ember['default'].isNone(addContactSelectedUser)) {
        var contactLists = controller.get('contactLists');
        contactListsFiltered = contactLists.filter(function (cL) {
          var usersIds = cL.get('usersIds');
          return !usersIds.includes(addContactSelectedUser.id);
        });
      }
      controller.set('addContactSelectedLists', Ember['default'].A());
      controller.set('contactListsFiltered', contactListsFiltered);
    };

    utils['default'].addObserver(controller, ['addContactSelectedUser'], 'addContactSelectedUserObserver');
  }

  function setListsFilteredBySelectedUserObserverBooking(controller) {
    controller.addContactSelectedUserBookingObserver = function () {
      var addContactSelectedUser = controller.get('addContactSelectedUser');
      var contactListsFiltered = Ember['default'].A();
      if (!Ember['default'].isNone(addContactSelectedUser)) {
        var contactLists = controller.get('lists');
        contactListsFiltered = contactLists.filter(function (cL) {
          var usersIds = cL.get('usersIds');
          return !usersIds.includes(addContactSelectedUser.id);
        });
      }
      controller.set('addContactSelectedLists', Ember['default'].A());
      controller.set('contactListsFiltered', contactListsFiltered);
    };

    utils['default'].addObserver(controller, ['addContactSelectedUser'], 'addContactSelectedUserBookingObserver');
  }

  function setComputedNewContact(controller) {
    controller.createNewContactDisabled = Ember['default'].computed('contactToAdd.name', 'contactToAdd.surname', 'contactToAdd.jobTitle', 'contactToAdd.company', 'contactToAdd.email', 'addContactSelectedLists.length', function () {
      var contactToAdd = this.get('contactToAdd');

      return !contactToAdd || !contactToAdd.get('name') || !contactToAdd.get('email') || !contactToAdd.get('surname') || !contactToAdd.get('jobTitle') || !contactToAdd.get('company') || !controller.get('addContactSelectedLists.length');
    });
  }

  function onOpenNoAllowedContactsModal() {
    Ember['default'].run.later(function () {}, 1000);
    Ember['default'].$('#no-allowed-contacts').modal();
    Ember['default'].openModal(1000, '#no-allowed-contacts');
  }

  function onCheckAllContacts(route) {
    var changeCheckTo = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    var list = route.controller.get('selectedList');
    if (list != null) {
      var contacts = list.get('contacts');
      if (changeCheckTo === null) {
        changeCheckTo = !list.get('allContactsChecked');
      }
      contacts.map(function (c) {
        return c.set('checked', changeCheckTo);
      });
    }
  }

  function setListsFilteredByContactToDuplicate(controller) {
    var contactsToDeleteContact = controller.get('contactsToDelete');
    var contactListsFiltered = Ember['default'].A();
    if (!Ember['default'].isNone(contactsToDeleteContact)) {
      var contactLists = controller.get('contactLists');
      contactListsFiltered = contactLists.filter(function (cL) {
        var contactsIds = cL.get('contactsIds');
        return !contactsIds.includes(contactsToDeleteContact[0].id);
      });
    }
    controller.set('contactListsFiltered', contactListsFiltered);
  }

});