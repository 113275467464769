define('bookalook/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'bookalook/config/environment', 'simple-auth-oauth2/authenticators/oauth2', 'bookalook/utils'], function (exports, Ember, Resolver, loadInitializers, config, OAuth2, utils) {

	'use strict';

	var App;

	// Remove deprecation warnings
	Ember['default'].deprecate = function () {};
	Ember['default'].warn = function () {};

	Ember['default'].MODEL_FACTORY_INJECTIONS = true;

	App = Ember['default'].Application.extend({
			modulePrefix: config['default'].modulePrefix,
			podModulePrefix: config['default'].podModulePrefix,
			Resolver: Resolver['default']
	});

	loadInitializers['default'](App, config['default'].modulePrefix);

	/* Needed to add cliend id and secret to login request */
	OAuth2['default'].reopen({
			makeRequest: function makeRequest(url, data) {
					data.client_id = config['default']['simple-auth-oauth2'].client_id;
					data.client_secret = config['default']['simple-auth-oauth2'].client_secret;
					return Ember['default'].$.ajax({
							url: url,
							type: 'POST',
							data: JSON.stringify(data),
							dataType: 'json',
							contentType: 'application/json'
					});
			}
	});

	Ember['default'].getUrlParameter = function getUrlParameter(sParam) {
			var sPageURL = decodeURIComponent(window.location.search.substring(1)),
			    sURLVariables = sPageURL.split('&'),
			    sParameterName,
			    i;

			for (i = 0; i < sURLVariables.length; i++) {
					sParameterName = sURLVariables[i].split('=');

					if (sParameterName[0] === sParam) {
							return sParameterName[1] === undefined ? true : sParameterName[1];
					}
			}
	};

	Ember['default'].getUrlParameters = function getUrlParameters() {
			var sPageURL = window.location.search.substring(1);
			var sURLVariables;
			var resultArray = [];
			var i;

			if (sPageURL === "") {
					sURLVariables = [];
			} else {
					sURLVariables = sPageURL.split('&');
			}

			for (i = 0; i < sURLVariables.length; i++) {
					resultArray[i] = sURLVariables[i].split('=');
					resultArray[i][0] = decodeURIComponent(resultArray[i][0]);
					resultArray[i][1] = decodeURIComponent(resultArray[i][1]);
			}

			return resultArray;
	};

	Ember['default'].bulk = {
			lists: new Ember['default'].A([]),
			contacts: new Ember['default'].A([]),
			samples: new Ember['default'].A([]),
			allSamples: new Ember['default'].A([]),

			clearSamples: function clearSamples() {
					Ember['default'].bulk.allSamples.forEach(function (sample) {
							sample.set('checked', false);
							sample.set('toCheckout', false);
					});
					Ember['default'].bulk.samples.clear();
					Ember['default'].bulk.allSamples.clear();
			}
	};
	Ember['default'].bookings = new Ember['default'].Object();

	exports['default'] = App;

	Ember['default'].openModal = function (time, modalId) {
			setTimeout(function () {
					Ember['default'].$('body').addClass('modal-open');
			}, time || 1000);
			if (modalId) {
					Ember['default'].$(modalId).off().on('shown.bs.modal', function () {
							setTimeout(function () {
									Ember['default'].$('body').addClass('modal-open');
							}, time || 1000);
					});
			}
	};

	Ember['default'].formatDate = function (d) {
			var s = arguments.length <= 1 || arguments[1] === undefined ? '.' : arguments[1];

			if (typeof d === 'string') {
					d = utils['default'].parseDate(d);
			}

			if (Ember['default'].isNone(d) || d.getFullYear() === 1970) {
					d = new Date();
			}

			var r = [d.getDate(), d.getMonth() + 1, d.getFullYear() % 100].join(s);

			return r;
	};
	Ember['default'].dateFormat = "dd.mm.yy";
	Ember['default'].environment = config['default'].environment;

	Ember['default'].usaStates = [{
			id: "Alabama",
			code: "AL"
	}, {
			id: "Alaska",
			code: "AK"
	}, {
			id: "American Samoa",
			code: "AS"
	}, {
			id: "Arizona",
			code: "AZ"
	}, {
			id: "Arkansas",
			code: "AR"
	}, {
			id: "California",
			code: "CA"
	}, {
			id: "Colorado",
			code: "CO"
	}, {
			id: "Connecticut",
			code: "CT"
	}, {
			id: "Delaware",
			code: "DE"
	}, {
			id: "District Of Columbia",
			code: "DC"
	}, {
			id: "Federated States Of Micronesia",
			code: "FM"
	}, {
			id: "Florida",
			code: "FL"
	}, {
			id: "Georgia",
			code: "GA"
	}, {
			id: "Guam",
			code: "GU"
	}, {
			id: "Hawaii",
			code: "HI"
	}, {
			id: "Idaho",
			code: "ID"
	}, {
			id: "Illinois",
			code: "IL"
	}, {
			id: "Indiana",
			code: "IN"
	}, {
			id: "Iowa",
			code: "IA"
	}, {
			id: "Kansas",
			code: "KS"
	}, {
			id: "Kentucky",
			code: "KY"
	}, {
			id: "Louisiana",
			code: "LA"
	}, {
			id: "Maine",
			code: "ME"
	}, {
			id: "Marshall Islands",
			code: "MH"
	}, {
			id: "Maryland",
			code: "MD"
	}, {
			id: "Massachusetts",
			code: "MA"
	}, {
			id: "Michigan",
			code: "MI"
	}, {
			id: "Minnesota",
			code: "MN"
	}, {
			id: "Mississippi",
			code: "MS"
	}, {
			id: "Missouri",
			code: "MO"
	}, {
			id: "Montana",
			code: "MT"
	}, {
			id: "Nebraska",
			code: "NE"
	}, {
			id: "Nevada",
			code: "NV"
	}, {
			id: "New Hampshire",
			code: "NH"
	}, {
			id: "New Jersey",
			code: "NJ"
	}, {
			id: "New Mexico",
			code: "NM"
	}, {
			id: "New York",
			code: "NY"
	}, {
			id: "North Carolina",
			code: "NC"
	}, {
			id: "North Dakota",
			code: "ND"
	}, {
			id: "Northern Mariana Islands",
			code: "MP"
	}, {
			id: "Ohio",
			code: "OH"
	}, {
			id: "Oklahoma",
			code: "OK"
	}, {
			id: "Oregon",
			code: "OR"
	}, {
			id: "Palau",
			code: "PW"
	}, {
			id: "Pennsylvania",
			code: "PA"
	}, {
			id: "Puerto Rico",
			code: "PR"
	}, {
			id: "Rhode Island",
			code: "RI"
	}, {
			id: "South Carolina",
			code: "SC"
	}, {
			id: "South Dakota",
			code: "SD"
	}, {
			id: "Tennessee",
			code: "TN"
	}, {
			id: "Texas",
			code: "TX"
	}, {
			id: "Utah",
			code: "UT"
	}, {
			id: "Vermont",
			code: "VT"
	}, {
			id: "Virgin Islands",
			code: "VI"
	}, {
			id: "Virginia",
			code: "VA"
	}, {
			id: "Washington",
			code: "WA"
	}, {
			id: "West Virginia",
			code: "WV"
	}, {
			id: "Wisconsin",
			code: "WI"
	}, {
			id: "Wyoming",
			code: "WY"
	}];

});