define('bookalook/models/job-address', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    job: DS['default'].belongsTo('job', { async: true }),

    street1: DS['default'].attr('string'),
    street2: DS['default'].attr('string'),
    street3: DS['default'].attr('string'),
    country: DS['default'].attr('string'),
    state: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    zipCode: DS['default'].attr('string'),
    telephone: DS['default'].attr('string'),
    main: DS['default'].attr('boolean'),

    addressString: (function () {
      var str = this.get('street1') + ' ' + this.get('street2') + ' ' + this.get('street3') + ', ' + this.get('zipCode') + ' ' + this.get('city') + ' ' + this.get('state') + ' - ' + this.get('country');
      return str.replace(/\bnull\b/g, '');
    }).property('street1', 'street2', 'street3', 'zipCode', 'city', 'state', 'country'),

    addressWillMain: (function () {
      var _this = this;

      var willMain = this.get('willMain');
      if (!willMain) {
        return;
      }

      this.get('job.addresses').forEach(function (a) {
        if (a.get('id') !== _this.get('id')) {
          a.set('willMain', false);
        }
      });
    }).observes('willMain'),

    hasSomeField: (function () {
      var params = [];
      params.push(this.get('street1'));
      params.push(this.get('street2'));
      params.push(this.get('street3'));
      params.push(this.get('country'));
      params.push(this.get('city'));
      params.push(this.get('state'));
      params.push(this.get('zipCode'));
      params.push(this.get('telephone'));
      return params.some(function (p) {
        return !Ember.isBlank(p);
      });
    }).property('street1', 'street2', 'street3', 'country', 'city', 'state', 'zipCode', 'telephone1'),

    toJSON: function toJSON() {
      return {
        id: this.get('id'),
        street1: this.get('street1'),
        street2: this.get('street2'),
        street3: this.get('street3'),
        country: this.get('country'),
        city: this.get('city'),
        state: this.get('state'),
        zipCode: this.get('zipCode'),
        telephone: this.get('telephone'),
        main: this.get('main'),
        setAsMain: this.get('setAsMain')
      };
    },

    validations: {
      'city': {
        presence: true
      }
    }

  });

});