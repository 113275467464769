define('bookalook/templates/admin/modals/new-plan', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 8,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/admin/modals/new-plan.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1,"class","modal-title");
          var el2 = dom.createTextNode("Edit plan");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/admin/modals/new-plan.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1,"class","modal-title");
          var el2 = dom.createTextNode("Add plan");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/admin/modals/new-plan.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New contact ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","new-plan");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        dom.setAttribute(el3,"id","test_modal_contact_manually");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Basic Settings");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","hint-right no-padding-left col-xs-6 ember-view");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-group validated-input checkbox-plan");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                 ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        var el10 = dom.createTextNode("Access to contacts");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n\n\n\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_cancel");
        dom.setAttribute(el5,"data-dismiss","modal");
        var el6 = dom.createTextNode("Cancel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"data-toggle","modal");
        dom.setAttribute(el5,"class","btn_primary");
        var el6 = dom.createTextNode("Save");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 1]);
        var element1 = dom.childAt(element0, [3, 1, 1]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(15);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
        morphs[1] = dom.createMorphAt(element1,3,3);
        morphs[2] = dom.createMorphAt(element1,5,5);
        morphs[3] = dom.createMorphAt(element1,7,7);
        morphs[4] = dom.createMorphAt(element1,9,9);
        morphs[5] = dom.createMorphAt(element1,11,11);
        morphs[6] = dom.createMorphAt(element1,13,13);
        morphs[7] = dom.createMorphAt(element1,15,15);
        morphs[8] = dom.createMorphAt(element1,17,17);
        morphs[9] = dom.createMorphAt(element1,19,19);
        morphs[10] = dom.createMorphAt(element1,21,21);
        morphs[11] = dom.createMorphAt(dom.childAt(element1, [23, 1]),1,1);
        morphs[12] = dom.createElementMorph(element3);
        morphs[13] = dom.createAttrMorph(element4, 'disabled');
        morphs[14] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [
        ["block","if",[["get","planToAdd.name",["loc",[null,[6,14],[6,28]]]]],[],0,1,["loc",[null,[6,8],[10,15]]]],
        ["inline","validated-input",[],["label","Name*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[17,56],[17,65]]]]],[],[]],"property","name","class","hint-left no-padding-left col-xs-6"],["loc",[null,[17,12],[17,126]]]],
        ["inline","validated-input",[],["label","Number of Brands*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[18,65],[18,74]]]]],[],[]],"property","maxBrands","class","hint-right no-padding-right col-xs-6"],["loc",[null,[18,12],[18,142]]]],
        ["inline","validated-input",[],["label","Number of Showrooms*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[19,66],[19,75]]]]],[],[]],"property","maxShowrooms","class","hint-left no-padding-left col-xs-6"],["loc",[null,[19,12],[19,144]]]],
        ["inline","validated-input",[],["label","Number of Bookings*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[20,67],[20,76]]]]],[],[]],"property","maxBookings","class","hint-right no-padding-right col-xs-6"],["loc",[null,[20,12],[20,146]]]],
        ["inline","validated-input",[],["label","Number of Samples*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[21,68],[21,77]]]]],[],[]],"property","maxSamples","class","hint-left no-padding-left col-xs-6"],["loc",[null,[21,12],[21,144]]]],
        ["inline","validated-input",[],["label","Number of Users*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[22,65],[22,74]]]]],[],[]],"property","maxUsers","class","hint-right no-padding-right col-xs-6"],["loc",[null,[22,12],[22,141]]]],
        ["inline","validated-input",[],["label","Stripe identifier","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[23,66],[23,75]]]]],[],[]],"property","idStripe","class","no-padding-left hint-right col-xs-6"],["loc",[null,[23,12],[23,141]]]],
        ["inline","validated-input",[],["label","Currency* (EUR / GBP / USD)","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[24,75],[24,84]]]]],[],[]],"property","currency","class","hint-right no-padding-right col-xs-6"],["loc",[null,[24,12],[24,151]]]],
        ["inline","validated-input",[],["label","Weight*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[25,55],[25,64]]]]],[],[]],"property","weight","class","hint-right no-padding-left col-xs-12"],["loc",[null,[25,12],[25,129]]]],
        ["inline","textarea",[],["rows",5,"placeholder","Description","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[26,62],[26,71]]]]],[],[]],"value",["subexpr","@mut",[["get","planToAdd.description",["loc",[null,[26,78],[26,99]]]]],[],[]],"class","col-xs-12"],["loc",[null,[26,12],[26,119]]]],
        ["inline","input",[],["type","checkbox","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[30,46],[30,55]]]]],[],[]],"checked",["subexpr","@mut",[["get","planToAdd.contacts",["loc",[null,[30,64],[30,82]]]]],[],[]],"class",""],["loc",[null,[30,16],[30,93]]]],
        ["element","action",["cancelEdition",["get","planToAdd",["loc",[null,[44,42],[44,51]]]]],[],["loc",[null,[44,16],[44,54]]]],
        ["attribute","disabled",["get","createNewPlanDisabled",["loc",[null,[45,77],[45,98]]]]],
        ["element","action",["saveNewPlan",["get","planToAdd",["loc",[null,[45,54],[45,63]]]]],[],["loc",[null,[45,30],[45,65]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});