define('bookalook/initializers/coordinator-setup', ['exports', 'bookalook/models/coordinator'], function (exports, Coordinator) {

  'use strict';

  exports['default'] = {
    name: "setup coordinator",

    initialize: function initialize() {
      var app = arguments[1] || arguments[0];
      app.register("drag:coordinator", Coordinator['default']);
      app.inject("component", "coordinator", "drag:coordinator");
    }
  };

});