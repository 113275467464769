define('bookalook/routes/session/login-provisional', ['exports', 'ember', 'bookalook/routes/session/functions', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, sessionFunctions, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    authenticator: 'simple-auth-authenticator:oauth2-password-grant',

    beforeModel: function beforeModel(transition, queryParams) {
      var user = Ember['default'].user;
      if (user) {
        if (user.get('isMP')) {
          this.transitionTo('settings.personal-mp', user.get('id'));
        } else {
          this.transitionTo('inventory');
        }
      }
    },

    actions: {
      authenticate: function authenticate() {
        sessionFunctions['default'].onAuthenticate(this);
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-login');
      Ember['default'].$('#linkedchat').hide();
      this.controllerFor('application').set('hasSidebar', false);
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-login');
      this.controllerFor('application').set('hasSidebar', false);
    }
  });

});