define('bookalook/helpers/is-avatar-default', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //USE ONLY IN CASE OF OBJECT
    //IF MODEL: BETTER USE THE MODEL FUNCTION (get('isAvatarDefault'))
    if (!params[0]) {
      return true;
    }
    var avatar = params[0];
    if (Ember['default'].isNone(avatar)) {
      return true;
    } else {
      return avatar.indexOf('default') !== -1;
    }
  });

});