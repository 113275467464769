define('bookalook/components/image-gallery', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    /*  - Attributes
     *   · IMAGESRC
     *   ·
     */

    //<ACTIONS>
    actions: {},
    //</ACTIONS>

    didInsertElement: function didInsertElement() {}

  });
  //<ON ACTIONS>

  //</ON ACTIONS>

});