define('bookalook/templates/jobs/modals/shopping-list-overview', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 10
            },
            "end": {
              "line": 11,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"data-dismiss","modal");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","lookToView.collection.brand.name",["loc",[null,[10,39],[10,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 10
            },
            "end": {
              "line": 16,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"data-dismiss","modal");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" - Look ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element16,0,0);
          morphs[1] = dom.createMorphAt(element16,2,2);
          morphs[2] = dom.createMorphAt(element16,4,4);
          morphs[3] = dom.createMorphAt(element16,6,6);
          return morphs;
        },
        statements: [
          ["content","lookToView.collection.collectionType",["loc",[null,[15,39],[15,79]]]],
          ["content","lookToView.collection.season",["loc",[null,[15,80],[15,112]]]],
          ["content","lookToView.collection.year",["loc",[null,[15,113],[15,143]]]],
          ["content","lookToView.position",["loc",[null,[15,151],[15,174]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 16
                  },
                  "end": {
                    "line": 28,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element14 = dom.childAt(fragment, [1]);
                var element15 = dom.childAt(element14, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element14, 'class');
                morphs[1] = dom.createAttrMorph(element15, 'src');
                morphs[2] = dom.createElementMorph(element15);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["album ",["subexpr","if",[["subexpr","if-equal",[["get","look.id",["loc",[null,[25,51],[25,58]]]],["get","lookToView.id",["loc",[null,[25,59],[25,72]]]]],[],["loc",[null,[25,41],[25,73]]]],"selected"],[],["loc",[null,[25,36],[25,86]]]]]]],
                ["attribute","src",["concat",[["get","look.avatars.0.thumbnail",["loc",[null,[26,32],[26,56]]]]]]],
                ["element","action",["setJobLookToView",["get","look",["loc",[null,[26,88],[26,92]]]]],[],["loc",[null,[26,60],[26,94]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 14
                },
                "end": {
                  "line": 29,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","look.hasActiveSamples",["loc",[null,[24,22],[24,43]]]]],[],0,null,["loc",[null,[24,16],[28,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 12
              },
              "end": {
                "line": 30,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","look.isAvatarDefault",["loc",[null,[23,24],[23,44]]]]],[],0,null,["loc",[null,[23,14],[29,25]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 10
            },
            "end": {
              "line": 31,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 3,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","look.archived",["loc",[null,[22,22],[22,35]]]]],[],0,null,["loc",[null,[22,12],[30,23]]]]
        ],
        locals: ["look","as","lookIndex"],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 12
              },
              "end": {
                "line": 47,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"type","button");
            dom.setAttribute(el1,"class","look-avatar-overlay btn_bal_circle_no_border no_color zoom_btn");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 10
            },
            "end": {
              "line": 48,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","light-box",[],["href",["subexpr","@mut",[["get","lookToView.currentAvatarToView.avatar",["loc",[null,[37,21],[37,58]]]]],[],[]],"lazyload-src","images/default-image.png","lazyloadActive",true,"data-lightbox","overview-look-avatars","data-title",["subexpr","@mut",[["get","lookToView.publicDescription",["loc",[null,[41,31],[41,59]]]]],[],[]],"data-class","image_zoom lazyload"],0,null,["loc",[null,[36,12],[47,26]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 12
            },
            "end": {
              "line": 61,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","arrows");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","arrow_left");
          dom.setAttribute(el2,"src","images/arrow-back-thin.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","arrow_right");
          dom.setAttribute(el2,"src","images/arrow-back-thin.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [1]);
          var element13 = dom.childAt(element11, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element12);
          morphs[1] = dom.createElementMorph(element13);
          return morphs;
        },
        statements: [
          ["element","action",["slideCurrentJobAvatarToView","prev"],[],["loc",[null,[58,73],[58,120]]]],
          ["element","action",["slideCurrentJobAvatarToView","next"],[],["loc",[null,[59,74],[59,121]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 14
              },
              "end": {
                "line": 69,
                "column": 14
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element10, 'class');
            morphs[1] = dom.createElementMorph(element10);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["subexpr","if-equal",[["get","avatarIndex",["loc",[null,[68,43],[68,54]]]],["get","lookToView.currentAvatarToView.position",["loc",[null,[68,55],[68,94]]]]],[],["loc",[null,[68,33],[68,95]]]],"active"],[],["loc",[null,[68,28],[68,106]]]]]]],
            ["element","action",["setCurrentJobAvatarToView",["get","avatar",["loc",[null,[68,145],[68,151]]]],["get","avatarIndex",["loc",[null,[68,152],[68,163]]]]],[],["loc",[null,[68,108],[68,165]]]]
          ],
          locals: ["avatar","avatarIndex"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 10
            },
            "end": {
              "line": 71,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","carousel-buttons");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","lookToView.avatars",["loc",[null,[67,22],[67,40]]]]],[],0,null,["loc",[null,[67,14],[69,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 28
              },
              "end": {
                "line": 93,
                "column": 28
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element2, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","sample.colour1URL",["loc",[null,[92,42],[92,59]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 94,
                "column": 28
              },
              "end": {
                "line": 96,
                "column": 28
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","sample.colour2URL",["loc",[null,[95,42],[95,59]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 28
              },
              "end": {
                "line": 99,
                "column": 28
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","sample.colour3URL",["loc",[null,[98,42],[98,59]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 16
            },
            "end": {
              "line": 126,
              "column": 16
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sample");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","sample-collapsing-row");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"data-toggle","collapse");
          dom.setAttribute(el3,"data-parent","#shop-list-overview-look-samples-container");
          dom.setAttribute(el3,"unselectable","on");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","arrow-div");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5,"class","collapse-arrow");
          dom.setAttribute(el5,"src","images/forward.svg");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","item");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","colors");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","fabrics");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","first-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Size");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label_value");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Retail price");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label_value");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","second-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Care & Additional infos");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1, 1]);
          var element5 = dom.childAt(element4, [5]);
          var element6 = dom.childAt(element4, [7, 1]);
          var element7 = dom.childAt(element3, [3]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element8, [1]);
          var morphs = new Array(14);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createAttrMorph(element4, 'data-target');
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          morphs[3] = dom.createMorphAt(element5,1,1);
          morphs[4] = dom.createMorphAt(element5,2,2);
          morphs[5] = dom.createMorphAt(element5,3,3);
          morphs[6] = dom.createMorphAt(element6,1,1);
          morphs[7] = dom.createMorphAt(element6,3,3);
          morphs[8] = dom.createMorphAt(element6,5,5);
          morphs[9] = dom.createAttrMorph(element7, 'id');
          morphs[10] = dom.createAttrMorph(element7, 'class');
          morphs[11] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element9, [7]),0,0);
          morphs[13] = dom.createMorphAt(dom.childAt(element8, [3]),3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["collapser ",["subexpr","if",[["subexpr","if-equal",[["get","sampleToView",["loc",[null,[83,61],[83,73]]]],["get","sample.id",["loc",[null,[83,74],[83,83]]]]],[],["loc",[null,[83,51],[83,84]]]],"","collapsed"],[],["loc",[null,[83,46],[83,101]]]]," pointer"]]],
          ["attribute","data-target",["concat",["#shop-list-overview-look-sample-",["get","sample.id",["loc",[null,[84,99],[84,108]]]]]]],
          ["content","sample.itemLabel",["loc",[null,[89,45],[89,65]]]],
          ["block","if",[["get","sample.colour1",["loc",[null,[91,34],[91,48]]]]],[],0,null,["loc",[null,[91,28],[93,35]]]],
          ["block","if",[["get","sample.colour2",["loc",[null,[94,34],[94,48]]]]],[],1,null,["loc",[null,[94,28],[96,35]]]],
          ["block","if",[["get","sample.colour3",["loc",[null,[97,34],[97,48]]]]],[],2,null,["loc",[null,[97,28],[99,35]]]],
          ["content","sample.fabric1",["loc",[null,[103,30],[103,48]]]],
          ["inline","if",[["get","sample.fabric2",["loc",[null,[104,35],[104,49]]]],["subexpr","concat",[", ",["get","sample.fabric2",["loc",[null,[104,63],[104,77]]]]],[],["loc",[null,[104,50],[104,78]]]]],[],["loc",[null,[104,30],[104,80]]]],
          ["inline","if",[["get","sample.fabric3",["loc",[null,[105,35],[105,49]]]],["subexpr","concat",[", ",["get","sample.fabric3",["loc",[null,[105,63],[105,77]]]]],[],["loc",[null,[105,50],[105,78]]]]],[],["loc",[null,[105,30],[105,80]]]],
          ["attribute","id",["concat",["shop-list-overview-look-sample-",["get","sample.id",["loc",[null,[110,64],[110,73]]]]]]],
          ["attribute","class",["concat",["sample-collapsed-content collapse ",["subexpr","if",[["subexpr","if-equal",[["get","sampleToView",["loc",[null,[110,133],[110,145]]]],["get","sample.id",["loc",[null,[110,146],[110,155]]]]],[],["loc",[null,[110,123],[110,156]]]],"in",""],[],["loc",[null,[110,118],[110,166]]]]]]],
          ["content","sample.size",["loc",[null,[114,60],[114,75]]]],
          ["content","sample.retailPrice",["loc",[null,[116,60],[116,82]]]],
          ["inline","multiline-text",[],["text",["subexpr","@mut",[["get","sample.description",["loc",[null,[120,50],[120,68]]]]],[],[]],"class","input_label_value"],["loc",[null,[120,28],[120,96]]]]
        ],
        locals: ["sample","sampleIndex"],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 146,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/jobs/modals/shopping-list-overview.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Look Overview ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade bal-modal");
        dom.setAttribute(el1,"id","shopping-list-overview-modal");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"data-dismiss","modal");
        dom.setAttribute(el4,"class","close_button");
        dom.setAttribute(el4,"src","images/modal_x.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title link-style");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-subtitle");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body gradient-horizontal-background-toleft-60");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","album-slider-container");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","overview-look-avatars");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"id","shopping-list-overview-current-avatar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","look-avatar-overlay container");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("button");
        dom.setAttribute(el8,"type","button");
        dom.setAttribute(el8,"class","btn_bal_circle_no_border no_color download_circle_btn");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","icon");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","overview-look-info");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","content");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","samples");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h2");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Available samples");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"id","shop-list-overview-look-samples-container");
        dom.setAttribute(el8,"class","samples-container");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("div class=\"keywords\">\n\n            </div");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("script");
        var el6 = dom.createTextNode("\n          $shopListOverviewlookGroup = $('#shop-list-overview-look-samples-container');\n          $shopListOverviewlookGroup.on('show.bs.collapse','.collapse', function() {\n            $shopListOverviewlookGroup.find('.collapse.in').collapse('hide');\n          });\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element17 = dom.childAt(fragment, [2, 1, 1]);
        var element18 = dom.childAt(element17, [4]);
        var element19 = dom.childAt(element17, [6]);
        var element20 = dom.childAt(element19, [3]);
        var element21 = dom.childAt(element20, [1]);
        var element22 = dom.childAt(element20, [5]);
        var element23 = dom.childAt(element22, [1, 1]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element18, [3]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),1,1);
        morphs[3] = dom.createAttrMorph(element21, 'data-avatar-src');
        morphs[4] = dom.createAttrMorph(element21, 'style');
        morphs[5] = dom.createMorphAt(element20,3,3);
        morphs[6] = dom.createElementMorph(element23);
        morphs[7] = dom.createMorphAt(element22,3,3);
        morphs[8] = dom.createMorphAt(element20,7,7);
        morphs[9] = dom.createMorphAt(dom.childAt(element19, [5, 1, 1, 3]),1,1);
        morphs[10] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["block","link-to",["profile.brand-profile",["get","lookToView.collection.brand.id",["loc",[null,[9,45],[9,75]]]]],["class","link-style"],0,null,["loc",[null,[9,10],[11,22]]]],
        ["block","link-to",["search.looks",["subexpr","query-params",[],["departments",["get","lookToView.department",["loc",[null,[14,62],[14,83]]]],"brands",["get","lookToView.collection.brand.name",["loc",[null,[14,91],[14,123]]]],"collectionTypes",["get","lookToView.collectionSearchString",["loc",[null,[14,140],[14,173]]]]],["loc",[null,[14,36],[14,174]]]]],["class","link-style"],1,null,["loc",[null,[14,10],[16,22]]]],
        ["block","each",[["get","job.looks",["loc",[null,[21,18],[21,27]]]]],[],2,null,["loc",[null,[21,10],[31,19]]]],
        ["attribute","data-avatar-src",["concat",[["get","lookToView.currentAvatarToView.avatar",["loc",[null,[34,77],[34,114]]]]]]],
        ["attribute","style",["concat",["background-image: url('",["get","lookToView.currentAvatarToView.thumbnail",["loc",[null,[34,150],[34,190]]]],"');"]]],
        ["block","if",[["get","lookToView.avatars",["loc",[null,[35,16],[35,34]]]]],[],3,null,["loc",[null,[35,10],[48,17]]]],
        ["element","action",["downloadJobOverviewLookAvatar"],[],["loc",[null,[52,98],[52,140]]]],
        ["block","if",[["subexpr","if-higher",[["get","lookToView.avatars.length",["loc",[null,[56,29],[56,54]]]],1],[],["loc",[null,[56,18],[56,57]]]]],[],4,null,["loc",[null,[56,12],[61,19]]]],
        ["block","if",[["subexpr","if-higher",[["get","lookToView.avatars.length",["loc",[null,[65,27],[65,52]]]],1],[],["loc",[null,[65,16],[65,55]]]]],[],5,null,["loc",[null,[65,10],[71,17]]]],
        ["block","each",[["get","selectableSamples",["loc",[null,[79,24],[79,41]]]]],[],6,null,["loc",[null,[79,16],[126,25]]]],
        ["inline","partial",["jobs/modals/new-job"],[],["loc",[null,[145,0],[145,33]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});