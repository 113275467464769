define('bookalook/routes/session/new-password', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    CONTAINER_ID: '.update-form',
    SUCCESS_ID: '.session-action-complete',

    actions: {
      sendPassword: function sendPassword(pass1, pass2) {
        //this.controller.set('passwordChanged', true);
        this.onSendPassword(pass1, pass2);
      },
      checkPassword1: function checkPassword1() {
        this.onCheckWrongPassword(1);
      },
      checkPassword2: function checkPassword2() {
        this.onCheckWrongPassword(2);
      }

    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      this._super(controller, model);
      controller.set('userLogged', Ember['default'].user);

      controller.set('passwordChanged', false);
      controller.set('password1', '');
      controller.set('password2', '');
      controller.set('loginError', '');
      controller.set('password1Error', false);
      controller.set('password2Error', false);
    },

    onCheckWrongPassword: function onCheckWrongPassword() {
      var input = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      var id = '';
      if (input === 1) {
        id = 'password1';
      } else {
        id = 'password2';
      }
      var password = this.controller.get(id);
      if (this._checkMinLengthNewPassword(password)) {
        this.controller.set('loginError', '');
        this.controller.set(id + 'Error', false);
        return false;
      } else {
        this.controller.set('loginError', 'Oops! Use at least 6 characters');
        this.controller.set(id + 'Error', true);
        return true;
      }
    },

    _checkBothPasswordsNewPassword: function _checkBothPasswordsNewPassword() {
      //if (!this.onCheckWrongPassword(1) && !this.onCheckWrongPassword(2)){
      if (this._checkMatchNewPassword()) {

        var password1 = this.controller.get('password1');
        var password2 = this.controller.get('password2');

        //psw1
        if (this._checkMinLengthNewPassword(password1)) {
          this.controller.set('loginError', '');
          this.controller.set('password1Error', false);
        } else {
          this.controller.set('loginError', 'Oops! Use at least 6 characters');
          this.controller.set('password1Error', true);
          return false;
        }

        if (this._checkMinLengthNewPassword(password2)) {
          this.controller.set('loginError', '');
          this.controller.set('password2Error', false);
          return true;
        } else {
          this.controller.set('loginError', 'Oops! Use at least 6 characters');
          this.controller.set('password2Error', true);
          return false;
        }
      } else {

        this.controller.set('password1Error', true);
        this.controller.set('password2Error', true);
        this.controller.set('loginError', "Oops! Passwords don't match");
        return false;
      }
      //} else {
      //  return false;
      //}
    },

    _checkMinLengthNewPassword: function _checkMinLengthNewPassword(field) {
      return field.length >= 6;
    },

    _checkMatchNewPassword: function _checkMatchNewPassword() {
      var password = this.controller.get('password1');
      var password2 = this.controller.get('password2');
      return password === password2;
    },

    onSendPassword: function onSendPassword(password, password2) {
      var $ = Ember['default'].$;
      this.controller.set('password1', password);
      this.controller.set('password2', password2);

      if (this._checkBothPasswordsNewPassword()) {
        $('#update-button').button('loading');

        var url = "users/changepassword/change-password";
        //this.controller.set('redirect', 'your personal settings.');
        if (!this.get("session.isAuthenticated")) {
          var token = document.URL.substr(document.URL.indexOf('=') + 1);
          url = "users/resetting/reset/" + token;
          //this.controller.set('redirect', 'login page.');
        }

        utils['default'].postAPIJSON(url, { plainPassword: password }).then($.proxy(this.onPasswordChangeSuccess, this), $.proxy(this.onPasswordChangeError, this));
      } else {
        //console.log("faill");
      }
    },

    onPasswordChangeSuccess: function onPasswordChangeSuccess() {
      var _this = this;

      Ember['default'].$('#update-button').button('reset');
      this.controller.set('passwordChanged', true);
      Ember['default'].run.later(function () {
        _this.transitionTo('session.login');
      }, 3000);
    },

    onPasswordChangeError: function onPasswordChangeError() {
      Ember['default'].$('#update-button').button('reset');
      this.controller.set('loginError', "Oops! Something went wrong");
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-new');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-new');
    }
  });

});