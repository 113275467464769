define('bookalook/router', ['exports', 'ember', 'bookalook/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.reopen({
    notifyIntercom: (function () {

      // Script for LogRocket integration
      if (Ember['default'].user && config['default'].LogRocketId && LogRocket) {
        var logRocketId = config['default'].LogRocketId;
        window.LogRocket;
        window.LogRocket.init(logRocketId);

        LogRocket.identify(Ember['default'].user.get('email'), {
          name: Ember['default'].user.get('name'),
          email: Ember['default'].user.get('email')
        });
      }

      //MAQPIE settings
      if (Ember['default'].maqpie && !Ember['default'].maqpie.loaded) {

        var hashUser = CryptoJS.HmacSHA256(Ember['default'].maqpie.email, config['default'].MaqpieAppSecret).toString(CryptoJS.enc.Hex);

        window.MP = {
          data: {
            appId: config['default'].MaqpieAppId, // your app id. it's same for each user. required
            user: {
              username: Ember['default'].maqpie.username,
              vendorUserId: Ember['default'].maqpie.email,
              email: Ember['default'].maqpie.email
            },
            userHash: hashUser // required
          },
          settings: {
            styles: {
              headerColor: "#4545ef", // a primary chat color
              scrollColor: "#BABABA", // a color of the chat scroll
              fontFamily: "futura-pt"
            },
            position: {
              left: "15px"
            },
            mode: 'embedded',
            demoMode: 0
          }
        };

        (function () {
          var loadWidget = function loadWidget() {
            // load Maqpie widget - inserts <script> tag before the first <script> tag in <body>
            var d = document;
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.maqpie.com/widget/v1.0';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          var w = window;
          w.MP._queue = w.MP._queue || [];
          var m = ['renderLargeView', 'showLargeView', 'destroyLargeView', 'subscribe', 'unsubscribe'];
          var f = function f(method) {
            return function () {
              var args = Array.prototype.slice.call(arguments);
              args.unshift(method);
              w.MP._queue.push(args);
            };
          };
          for (var i = 0; i < m.length; i += 1) {
            w.MP[m[i]] = f(m[i]);
          }
          if (w.attachEvent) {
            // if page has already been loaded just call loadWidget function
            w.attachEvent('onload', loadWidget);
          } else {
            w.addEventListener('load', loadWidget, false);
          }
          loadWidget();
          Ember['default'].maqpie.loaded = true;
        })();
      }

      if (config['default'].IntercomEnabled) {
        if (Ember['default'].intercom) {
          if (Ember['default'].user) {
            if (!Ember['default'].intercom.registered) {
              // console.log("1");
              // Logged in bal but intercom not logged
              // Init user inter
              // In prod send hash for user verification
              if (config['default'].environment === "production") {
                Intercom('boot', {
                  app_id: config['default'].IntercomId,
                  user_id: Ember['default'].intercom.user_id,
                  email: Ember['default'].intercom.email,
                  user_hash: Ember['default'].intercom.user_hash
                });
              } else {
                Intercom('boot', {
                  app_id: config['default'].IntercomId,
                  user_id: Ember['default'].intercom.user_id,
                  email: Ember['default'].intercom.email
                });
              }
              Intercom('update');
              Ember['default'].intercom.registered = true;
            } else {
              //Update logged user chat
              // console.log("2");
              Intercom('update');
            }
          } else {//Update non-logged user chat
            // console.log("3");
            /* Inactive by Melissa's request
            Intercom('update');
            */
          }
        } else {
            // console.log("4");
            // Init empty inter
            /* Inactive by Melissa's request
            Ember.intercom = {};
            Intercom('boot', {
              app_id: config.IntercomId
            });
            */
          }
      } else {
          // console.log("Unactive intercom");
        }
    }).on('didTransition'),

    checkForAppcues: (function () {
      Ember['default'].run.scheduleOnce('afterRender', function () {
        //Appcues.start();
      });
    }).on('didTransition')
  });

  Router.map(function () {
    this.route('index', { path: '/' });

    this.route('barcodes', function () {
      this.route('scan', { path: 'scanning' });
      this.route('scan-booking', { path: 'scanning-booking/:id' });
    });

    // this.route('profile', function () {
    //   this.route('profile', {path: ':id'});
    // });

    // this.route('agencies', function () {
    //   this.route('agency', {path: ':id'});
    // });

    //this.route('work-in-progress', function () {});

    this.route('session', function () {
      this.route('login');
      this.route('login-provisional');
      this.route('login-success');
      this.route('logout');
      this.route('password-recovery');
      this.route('new-password');
    });

    this.route('register', function () {
      this.route('complete');
      this.route('mp');
      this.route('pr');
    });

    this.route('onboarding', function () {});

    this.route('privacy-and-cookie-policy', function () {});

    this.route('terms-and-conditions', function () {});

    this.route('user-generated-content-policy', function () {});

    this.route('admin', function () {
      this.route('agencies', { path: 'agencies' });
      this.route('brands', { path: 'brands' });
      this.route('pr-users', { path: 'pr-users' });
      this.route('mp-users', { path: 'mp-users' });
      this.route('plans', { path: 'plans' });
      this.route('white-list', { path: 'white-list' });
    });

    this.route('settings', function () {
      //this.route('personal-mp', {path: 'personal-mp/:id'});
      this.route('select-plan');
      this.route('trial-ended');
      this.route('payment-failed');
      this.route('account-settings');
      this.route('payment-method');
    });

    this.route('contacts-lists', function () {
      //this.route('contacts', {path: 'contacts'});
      //this.route('contacts-lists', {path: ':id'});
    });

    this.route('collections', function () {
      this.route('album', { path: 'album/:id' });
      this.route('collection', { path: ':id' });
    });

    this.route('ddt-samples', function () {
      this.route('index', { path: ':parcel' });
    });

    this.route('bookings', function () {
      //this.route('overview');
      //this.route('booking-overview', {path: 'overview/:id'});
      //this.route('change-dates', {path: 'change-dates/:id'});
      //this.route('check-out');
      //this.route('booking-join');
      this.route('booking-details', { path: 'booking-details/:id' });
      //this.route('shopping-list', {path: 'shopping-list/:id'});
      //this.route('loan-forms-list', {path: ':id/loan-forms'});

      this.route('loan-form', { path: ':id/loan-form/:parcel' });
      this.route('ddt', { path: ':id/ddt/:parcel' });
    });

    /*this.route('check-out', function () {
      this.route('check-out', {path: ':origin'});
    });*/

    this.route('inventory', function () {
      //this.route('check-out');
    });

    this.route('search', function () {
      this.route('looks', { path: 'looks' });
      this.route('index', { path: ':target/' });
      this.route('index', { path: ':target/:query' });
      // this.route('user-profile');
      // this.route('brand-profile');
      // this.route('agency-profile');
    });

    this.route('profile', function () {
      this.route('agency-profile', { path: 'agency/:id' });
      this.route('brand-profile', { path: 'brand/:id' });
      // this.route('collection-profile', {path: 'collection/:id'});
      // this.route('album-profile', {path: 'album/:id'});
      // this.route('user-profile', {path: 'user/:id'});
    });

    this.route('jobs', function () {
      this.route('view', { path: 'view/:id' });
      // this.route('form-view', {path: 'form-view/:id'});
      // this.route('form-edit', {path: 'form-edit/:id'});
      // this.route('shopping-list', {path: 'shopping-list/:id'});
      this.route('loan-form', { path: 'shopping-list/:id/loan-form/:parcel' });
    });

    // this.route('messages', function () {
    //   this.route('index', {path: ''});
    //   this.route('index', {path: ':idConversation'});
    // });

    this.route('chat', function () {
      this.route('index', { path: '' });
    });

    this.route('directory', function () {
      // this.route('collections', {path: 'collections/:id'});
    });

    this.route('members', function () {});
  });

  exports['default'] = Router;

});