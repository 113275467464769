define('bookalook/helpers/if-regex', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var patt = new RegExp(params[1]);

    return patt.test(o1);
  });

});