define('bookalook/routes/session/logout', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      Intercom('shutdown');
      localStorage.clear();
      delete Ember['default'].showroomId;
      delete Ember['default'].userId;
      delete Ember['default'].user;
      delete Ember['default'].agency;
      delete Ember['default'].intercom;
      delete Ember['default'].maqpie;
      utils['default'].removeCache();

      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate().then(function () {
          window.location.href = "/session/login";
        });
      } else {
        window.location.href = "/session/login";
      }
    }
  });

});