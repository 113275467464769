define('bookalook/helpers/substract', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var o2 = params[1];
    var substract = o1 - o2;
    return substract;
  });

});