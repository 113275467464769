define('bookalook/helpers/search-look-number', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (Ember['default'].isNone(params[0]) || Ember['default'].isNone(params[1]) || Ember['default'].isNone(params[2])) {
      return '';
    }
    var currentPage = params[0];
    var itemsPerPage = params[1];
    var lookIndex = params[2];
    var number = (currentPage - 1) * itemsPerPage + (lookIndex + 1);
    return "Look " + number;
  });

});