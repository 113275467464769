define('bookalook/routes/privacy-and-cookie-policy/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    activate: function activate() {
      Ember['default'].$('body').addClass('page-privacy-and-terms');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-privacy-and-terms');
    }
  });

});