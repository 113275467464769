define('bookalook/helpers/get-name-initials', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //USE ONLY IN CASE OF OBJECT
    //IF MODEL: BETTER USE THE MODEL FUNCTION (get('nameInitials'))
    if (!params[0]) {
      return;
    }
    var object = params[0];

    var fullname = "";

    if (!Ember['default'].isBlank(object.name)) {
      if (!Ember['default'].isBlank(object.surname)) {
        fullname = fullname + object.name + " " + object.surname;
      } else {
        fullname = fullname + object.name;
      }
    } else if (!Ember['default'].isBlank(object.surname)) {
      fullname = fullname + object.surname;
    }

    if (!Ember['default'].isBlank(fullname)) {
      var array = fullname.split(" ");
      var result = array[0].charAt(0);
      if (array[1]) {
        result = result.concat(array[1].charAt(0));
      }
      return result.toUpperCase();
    } else {
      return "";
    }
  });

});