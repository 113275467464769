define('bookalook/routes/jobs/loan-form', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, config, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      download: function download() {
        this.onDownload();
      }
    },

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        booking: this.store.findRecord('booking', params.id),
        parcelIndex: params.parcel
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      var allCheckoutParcels = controller.get('booking.allParcelsExceptMain');
      controller.set('userLogged', Ember['default'].user);

      controller.get('booking.samples').forEach(function (sample) {
        sample.set('checked', false);
      });

      var parcel = allCheckoutParcels[controller.get('parcelIndex')];
      Ember['default'].bulk.clearSamples();
      parcel.get('samples').forEach(function (sample) {
        sample.set('checked', true);
      });
      controller.set('parcel', parcel);

      controller.shippingDate = Ember['default'].computed('parcel', function () {
        var sampleStatus = this.booking.get('sampleStatus');
        var checkout = "";
        sampleStatus.some(function (singleStatus) {
          if (singleStatus.checkout !== '') {
            checkout = singleStatus.get('checkout');
            return true;
          }
        });
        return Ember['default'].formatDate(checkout);
      });
    },
    activate: function activate() {
      Ember['default'].$('body').addClass('page-bookings loan-form');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-bookings loan-form');
    }
  });

});