define('bookalook/tests/models/contact.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/contact.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'models/contact.js should pass jshint.\nmodels/contact.js: line 56, col 69, Missing semicolon.\n\n1 error');
  });

});