define('bookalook/helpers/find-first-avatar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.Helper.helper(function (params) {
    var array = params[0];
    if (array !== undefined) {
      var avatarIndex = -1;
      for (var i = 0; i < array.length; i += 1) {
        if (array[i]['position'] === 1) {
          avatarIndex = i;
        }
      }
      if (avatarIndex !== -1) {
        return array[avatarIndex]['thumbnail'];
      } else {
        return "images/default-image.png";
      }
    }
  });

});