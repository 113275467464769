define('bookalook/routes/collections/album', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, config, utils, UserValidatorMixin) {

  'use strict';

  var set;
  var delete_count = 0;
  var duplicate_count = 0;
  var avatarsOriginalOrder = [];
  var bulkUploadAvatars = [];

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {

      showArchivedSamples: function showArchivedSamples(visibility) {
        this.controller.set('showArchivedSamples', visibility);
      },
      openEditLookModal: function openEditLookModal(look) {
        this.onOpenEditLookModal(look);
      },
      openArchiveLookModal: function openArchiveLookModal(look) {
        this.controller.set('lookToArchive', look);

        Ember['default'].$('#archive-look').modal();
        Ember['default'].openModal();
      },

      deleteLook: function deleteLook(look) {
        this.onDeleteLook(look);
      },
      openNewLookModal: function openNewLookModal() {
        this.onOpenNewLookModal();
      },
      cancelEdition: function cancelEdition(look) {
        this.onCancelEdition(look);
      },
      saveLook: function saveLook(look) {
        this.onSaveLook(look);
      },
      addAnotherSample: function addAnotherSample(look) {
        this.onAddAnotherSample(look);
      },
      archiveLook: function archiveLook(look) {
        this.setLookArchived(look, true);
      },

      activateSample: function activateSample(sample) {
        var idModal = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        this.onActivateSample(sample, idModal);
      },

      activateSamples: function activateSamples() {
        this.onActivateSamples();
      },

      activateLook: function activateLook(look) {
        this.onActivateLook(look);
      },

      openArchiveAlbumModal: function openArchiveAlbumModal(album) {
        this.controller.set('albumToArchive', album);
      },
      archiveAlbum: function archiveAlbum(album) {
        album.set('archived', true).save();
      },
      activateAlbum: function activateAlbum(album) {
        this.onActivateAlbum(album);
      },

      openUnpublishAlbumModal: function openUnpublishAlbumModal(album) {
        this.controller.set('albumToUnpublish', album);
      },
      unpublishAlbum: function unpublishAlbum(album) {
        album.set('published', false).save();
      },
      publishAlbum: function publishAlbum(album) {
        this.onPublishAlbum(album);
      },

      deleteAlbum: function deleteAlbum() {
        this.onDeleteAlbum();
      },

      manageSampleNewLook: function manageSampleNewLook(look, sample, action) {
        var outofmodal = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

        this.onManageSampleNewLook(look, sample, action, outofmodal);
      },

      reorderItems: function reorderItems(itemModels) {
        this.currentOrderList = this.controller.get('looksSortList');
        this.onReorderItems(itemModels);
      },

      saveLooksOrder: function saveLooksOrder() {
        var skipReload = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.onSaveLooksOrder(skipReload);
      },
      cancelLooksOrder: function cancelLooksOrder() {
        this.onCancelLooksOrder();
      },
      uploadAlbumExcel: function uploadAlbumExcel() {
        this.onUploadAlbumExcel();
      },
      modCancelUploadPicture: function modCancelUploadPicture(id, modal, modalId) {
        this.onCancelUploadPicture(id, modal, modalId);
      },
      modNewUploadPicture: function modNewUploadPicture(id, tag, model, modalId) {
        this.onNewUploadPicture(id, tag, model, modalId);
      },
      openLookImageModal: function openLookImageModal() {
        Ember['default'].openModal();
      },

      openEditAlbumModal: function openEditAlbumModal() {
        this.onOpenEditAlbumModal();
      },
      saveAlbum: function saveAlbum() {
        this.controller.get('model').save();
      },
      cancelNewAlbum: function cancelNewAlbum(album) {
        album.rollbackAttributes();
      },
      rollbackAlbum: function rollbackAlbum() {
        this.controller.get('model').rollbackAttributes();
      },
      /*openDeleteModal(look)                {
        this.controller.set('lookToDelete', look);
      },*/
      error: function error() {
        this.transitionTo('collections.index');
      },
      openDeleteAlbumErrorModal: function openDeleteAlbumErrorModal(title, subtitle, relatedBookings) {
        this.onOpenDeleteAlbumErrorModal(title, subtitle, relatedBookings);
      },
      willTransition: function willTransition() {
        Ember['default'].$('#delete-album-error-modal').modal('hide');
      },
      openNewSamplesLimitReachedModal: function openNewSamplesLimitReachedModal() {
        this.onOpenNewSamplesLimitReachedModal();
      },
      cancelModal: function cancelModal() {
        this.oncancelmodel();
      },
      selectAvatar: function selectAvatar(position) {
        this.onSelectAvatar(position);
      },
      deleteImage: function deleteImage() {
        this.onDeleteImage();
      },
      deleteImageAvatar: function deleteImageAvatar(position) {
        this.onDeleteImageAvatar(position);
      },
      manageUploadResponse: function manageUploadResponse(data, model) {
        var positionIndex = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        this.onManageUploadResponse(data, model, positionIndex);
      },
      disableImageComponent: function disableImageComponent() {
        this.onDisableImageComponent();
      },
      enableImageComponent: function enableImageComponent() {
        this.onEnableImageComponent();
      },
      manageMultiuploadResponse: function manageMultiuploadResponse(data, model) {
        this.onManageMultiuploadResponse(data, model);
      },
      sortAvatars: function sortAvatars(list) {
        this.onSortAvatars(list);
      },
      checkSample: function checkSample(sample) {
        sample.set('checked', !sample.get('checked'));
      },
      checkAllSamples: function checkAllSamples(look) {
        var samples = null;
        var changeTo = true;
        if (this.controller.get('showArchivedSamples')) {
          samples = look.get('archivedSamples');
          changeTo = !look.get('areArchivedSamplesChecked');
        } else {
          samples = look.get('activeSamples');
          changeTo = !look.get('areActiveSamplesChecked');
        }
        for (var i = 0; i < samples.length; i++) {
          samples[i].set('checked', changeTo);
        }
      },

      checkArchivableCheckedSamples: function checkArchivableCheckedSamples(lookId) {
        this.onCheckArchivableCheckedSamples(lookId);
      },

      duplicateSelectedSamples: function duplicateSelectedSamples(look) {
        this.onDuplicateSelectedSamples(look);
      },

      changeGenderEntity: function changeGenderEntity() {
        var controller = this.controller;
        var entityObj = controller.get('lookToAdd');
        if (controller.get('lookToAdd.isNew')) {
          entityObj.get('samples').toArray().forEach(function (s) {
            return s.deleteRecord();
          });
          Ember['default'].$('#change-gender-modal').modal('hide');
          Ember['default'].$('#new-look').modal();
          Ember['default'].openModal();
          controller.set('_oldDepartment', entityObj.get('department'));
          controller.set('genderRelatedBookings', null);
          controller.set('genderInTransfer', null);
        } else {
          entityObj.get('samples').toArray().forEach(function (s) {
            return s.destroyRecord();
          });
          var promise = entityObj.save().then(function () {
            Ember['default'].$('#change-gender-modal').modal('hide');
            Ember['default'].$('#new-look').modal();
            Ember['default'].openModal();
            controller.set('_oldDepartment', entityObj.get('department'));
            controller.set('genderRelatedBookings', null);
            controller.set('genderInTransfer', null);
          });
          utils['default'].buttonLoading('#cancel-changeGender', promise);
          utils['default'].buttonLoading('#changeGender-confirmation-btn', promise);
        }
      },

      cancelChangeGenderEntity: function cancelChangeGenderEntity() {
        var controller = this.controller;
        controller.set('isCanceled', true);
        controller.set('lookToAdd.department', controller.get('_oldDepartment'));
        Ember['default'].$('#change-gender-modal').modal('hide');
        controller.set('genderRelatedBookings', null);
        controller.set('genderInTransfer', null);
        Ember['default'].$('#new-look').modal();
        Ember['default'].openModal();
      },

      goToInventoryFromChangeGender: function goToInventoryFromChangeGender() {
        var extra = function extra() {
          Ember['default'].$('#change-gender-modal').modal('hide');
        };
        utils['default'].goToInventoryWithFilterStatus(this, "IN TRANSFER", extra);
      },

      genderGetRelatedBookingsReport: function genderGetRelatedBookingsReport() {
        var options = [];
        options.push('look=' + this.get('lookToAdd.id'));
        window.open(config['default'].APIURL + 'report?' + options.join('&'), '_blank' // <- This is what makes it open in a new window.
        );
      },

      toggleImportHelp: function toggleImportHelp() {
        utils['default'].toggleHelp('import');
      }
    },

    model: function model(params) {
      return this.store.findRecord('album', params.id);
    },

    initializeFilters: function initializeFilters() {
      var controller = this.controller;
      var model = controller.get('model');
      if (model.get('archived')) {
        controller.set('showArchivedSamples', true);
      } else {
        //If has samples but none of them is active, show archived samples
        if (model.get('samples').get('length') > 0 && !model.get('hasActiveSamples')) {
          controller.set('showArchivedSamples', true);
        } else {
          controller.set('showArchivedSamples', false);
        }
      }
      controller.set('sampleStatusFilter', Ember['default'].computed('showArchivedSamples', function () {
        return controller.get('showArchivedSamples') ? 'Archived' : 'Active';
      }));
      var filterFabrics = utils['default'].addDefaultOption(Ember['default'].fabrics, 'All Materials', 'name', null);
      controller.set('allFabricsContent', filterFabrics);
      controller.set('materialFilter', '');
      controller.set('searchInput', '');
      controller.set('itemFilter', '');
      controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
      controller.set('colourFilter', '');
      controller.set('sizeFilter', '');

      this._setSizeFilter(controller);
      controller.set('statusContent', [{ id: 'All statuses' }, { id: 'BOOKED' }, { id: 'OVERLAP' }, { id: 'AVAILABLE' },
      //{id: 'IN TRANSFER'},
      { id: 'UNAVAILABLE' }]);
    },

    onReorderItems: function onReorderItems(itemModels) {
      var imageFirstLook = itemModels[0].get('getImage');
      this.controller.set('looksSortList', itemModels);
      this.controller.set('model.collection.avatar', imageFirstLook);
      this.controller.set('model.avatar', imageFirstLook);
    },
    onCancelLooksOrder: function onCancelLooksOrder() {
      this.onReorderItems(this.currentOrderList);
    },
    oncancelmodel: function oncancelmodel() {

      delete_count = 0;
      this.controller.set('maxSamplesReached', false);
      //this.controller.set('numSamples',0);
      // this.controller.set('numTempSamplesToAdd', 0);
      Ember['default'].$('#max-samples-reached').modal('hide');
      //   Ember.$('#new-look').modal('show');
      $("#new-look").scroll();
      $("#new-look").scrollTop();
    },
    onDeleteLook: function onDeleteLook(look) {
      look.destroyRecord();
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('userLogged', Ember['default'].user);
      controller.set('album', Ember['default'].computed('model', function () {
        return model;
      }));
      this.initializeFilters();
      controller.set('importExampleURL', config['default'].APIURL + 'excels/template/looks');
      controller.set('avatarsOrder', [1, 2, 3, 4, 5]);
      var total = 0;
      var brandid = Ember['default'].showroom.get('companyTypeAndId.id');
      var type = Ember['default'].showroom.get('companyTypeAndId.type');

      utils['default'].getAPIJSON('plans/numSamplesForPlanUser').then(function (limitPlanSamples) {
        controller.set('limitPlanSamples', limitPlanSamples);
      });

      var ispr = Ember['default'].user.get('type');

      if (ispr == "PR") {

        if (Ember['default'].user.get('stripeCustomerId')) {
          controller.set('ispr', '1');
        } else {
          controller.set('ispr', '');
        }
      } else {
        controller.set('ispr', '');
      }
      controller.looksFilteredByStatus = Ember['default'].computed('model.looks.@each.archived', 'showArchivedLooks', function () {
        var showArchived = controller.get('showArchivedLooks');
        var looksFiltered = controller.get('model.looks').filter(function (look) {
          // If album is archived show all looks
          return controller.get('model.archived') || look.get('archived') === showArchived;
        });
        return looksFiltered;
      });

      var coloursAndDef = utils['default'].addDefaultOption(Ember['default'].colours, '---', 'safeString', null);
      var fabricsAndDef = utils['default'].addDefaultOption(Ember['default'].fabrics, '---', 'name', null);
      controller.set('sorting', ['position', 'id']);
      controller.allLooks = Ember['default'].computed.sort('looksFilteredByStatus', 'sorting');
      controller.set('colours', coloursAndDef);
      controller.set('fabrics', fabricsAndDef);

      delete controller.looksSortList;
      controller.looksSortList = Ember['default'].computed('model.looks.@each.position', 'model.looks.@each.archived', function () {
        return controller.get('model.orderedLooks');
      });

      controller.filteredItems = Ember['default'].computed('lookToAdd.department', function () {
        var _this2 = this;

        var items = Ember['default'].items.filter(function (i) {
          return i.department === _this2.get('lookToAdd.departmentLabel');
        });
        if (this.get('lookToAdd.samples.length')) {
          this.get('lookToAdd.samples').forEach(function (s) {
            if (s.get('itemObject')) {
              items.addObject(s.get('itemObject'));
            }
          });
        }
        var sorted = items.sortBy('name');
        return sorted;
      });

      controller.departmentObserver = function () {
        if (Ember['default'].isNone(this.get('_oldDepartment'))) {
          this.set('_oldDepartment', this.get('lookToAdd.department'));
          return;
        }

        if (this.get('isCanceled')) {
          this.set('isCanceled', false);
          return;
        }

        var department = this.get('lookToAdd.department');
        var oldDepartment = this.get('_oldDepartment') || department;
        var lookHasSingleEmptySample = this.get('lookToAdd.samples.length') === 1 && Ember['default'].isNone(this.get('lookToAdd.samples').objectAt(0).get('item'));
        if (department !== oldDepartment) {
          if (!lookHasSingleEmptySample) {
            var entityObj = controller.get('lookToAdd');
            Ember['default'].$('#new-look').modal('hide');
            controller.set('isLoadingGender', true);
            Ember['default'].$('#change-gender-modal').modal();
            Ember['default'].openModal();
            if (this.get('lookToAdd.isNew')) {
              controller.set('isLoadingGender', false);
              controller.set('genderRelatedBookings', null);
              controller.set('genderInTransfer', null);
            } else {
              utils['default'].getAPIJSON('looks/' + entityObj.get('id') + '/archivable').then(function (res) {
                controller.set('genderRelatedBookings', res.bookings.sortBy('id'));
                controller.set('genderInTransfer', res.inTransfer);
                controller.set('isLoadingGender', false);
              });
            }
          }
        }
      };

      utils['default'].addObserver(controller, ['lookToAdd.department'], 'departmentObserver');

      controller.newItemValueObserver = function () {
        var _this3 = this;

        if (!controller.get('new-look-new-sample-loading')) {
          (function () {
            var newItemValue = controller.get('newItemValue');
            var look = _this3.get('lookToAdd');
            if (newItemValue !== null) {
              (function () {
                var _t = _this3;
                controller.set('new-look-new-sample-loading', true);
                utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countSamples').then(function (res) {
                  //Current samples count
                  controller.set('numSamples', res);
                  //Current samples count
                  var numSamples = controller.get('numSamples');

                  var totalSamples = undefined;
                  var numTempSamplesToAdd = controller.get('numTempSamplesToAdd');
                  if (!numTempSamplesToAdd) {
                    numTempSamplesToAdd = 0;
                    controller.set('numTempSamplesToAdd', 0);
                  }

                  totalSamples = numTempSamplesToAdd + numSamples + duplicate_count;
                  totalSamples = totalSamples - delete_count;
                  var limitPlanSamples = controller.get('limitPlanSamples');

                  //Check limits
                  //console.log(totalSamples+"("+limitPlanSamples+")="+numTempSamplesToAdd+"+"+numSamples+"+"+duplicate_count+"-"+delete_count);
                  if (limitPlanSamples && limitPlanSamples != 0 && totalSamples && totalSamples >= limitPlanSamples) {
                    controller.set('newItemValue', null);
                    //Open sample max reached limit
                    _t.send('openLimitationReachedModal', 'Sample', '#new-look');

                    //Ember.$('#new-look').modal('hide');
                    //Ember.$('#max-samples-reached').modal();
                    //Ember.openModal();
                    controller.set('new-look-new-sample-loading', false);
                    return;
                  } else {
                    numTempSamplesToAdd++;

                    controller.set('numTempSamplesToAdd', numTempSamplesToAdd);
                    controller.set('o', totalSamples);

                    look.get("samples").addObject(_t.store.createRecord('sample', {
                      look: look,
                      showroom: Ember['default'].showroom,
                      groupid: Date.now(),
                      main: true,
                      item: newItemValue
                    }));
                    controller.set('new-look-new-sample-loading', false);
                  }

                  controller.set('newItemValue', null);
                }, function () {
                  controller.set('new-look-new-sample-loading', false);
                });
              })();
            }
          })();
        }
      };

      controller.set('newItemValue', null);
      utils['default'].addObserver(controller, ['newItemValue'], 'newItemValueObserver');

      controller.continueDisabled = Ember['default'].computed('model.albumType', 'model.other', function () {
        var type = this.get('model.albumType');
        var disabled = type === 'Other' ? !this.get('model.other') : !type;
        return disabled;
      });

      Ember['default'].$('#new-look').on('hidden.bs.modal', function () {
        //remove the backdrop
        Ember['default'].$('.modal-backdrop').remove();
      });

      Ember['default'].$('#app-confirmation').on('hidden.bs.modal', function () {
        //remove the backdrop
        var backdropCount = Ember['default'].$('.modal-backdrop').length - 1;
        if (backdropCount > 1) {
          Ember['default'].$('.modal-backdrop').splice(1, backdropCount);
        }
      });

      controller.searchFilterFunction = function () {
        var showArchived = controller.get('showArchivedSamples');
        var searchInput = (this.get('searchInput') || '').toLowerCase();
        var itemFilter = (this.get('itemFilter') || '').toLowerCase();
        var sizeFilter = (this.get('sizeFilter') || '').toLowerCase();
        var colourFilter = (this.get('colourFilter') || '').toLowerCase();
        var materialFilter = (this.get('materialFilter') || '').toLowerCase();
        var allSamplesUnvisible = true;
        var filteringSamples = false;

        if (searchInput !== '' || itemFilter && itemFilter !== '' && itemFilter !== 'all samples' || colourFilter && colourFilter !== '' || materialFilter && materialFilter !== '' && materialFilter !== 'all materials' || sizeFilter && sizeFilter !== '') {
          filteringSamples = true;
        } else {
          filteringSamples = false;
        }
        controller.set('filteringSamples', filteringSamples);

        var samples = controller.get('model.samples');
        samples.forEach(function (s) {
          var checked = s.get('archived') === showArchived;

          if (searchInput !== '' && checked) {
            checked = s.get('searchString').toLowerCase().indexOf(searchInput) > -1;
          }

          if (itemFilter && itemFilter !== '' && itemFilter !== 'all samples' && checked) {
            checked = s.get('itemObject')['name'].toLowerCase() === itemFilter;
          }

          if (colourFilter && colourFilter !== '' && checked) {
            checked = s.get('colourSearchString').toLowerCase().indexOf(colourFilter) > -1;
          }

          if (materialFilter && materialFilter !== '' && materialFilter !== 'all materials' && checked) {
            var fabric1 = s.get('fabric1');
            var fabric2 = s.get('fabric2');
            var fabric3 = s.get('fabric3');
            if (fabric1 || fabric2 || fabric3) {
              var fabricMatchs = false;
              if (fabric1 && !fabricMatchs) {
                fabricMatchs = fabric1.toLowerCase() === materialFilter;
              }
              if (fabric2 && !fabricMatchs) {
                fabricMatchs = fabric2.toLowerCase() === materialFilter;
              }
              if (fabric3 && !fabricMatchs) {
                fabricMatchs = fabric3.toLowerCase() === materialFilter;
              }
              checked = fabricMatchs;
            }
          }

          if (sizeFilter && sizeFilter !== '' && checked) {
            checked = s.get('sizeSearchString').toLowerCase() === sizeFilter;
          }

          s.set('visible', checked);
          allSamplesUnvisible = allSamplesUnvisible && !checked;
        });
        controller.set('allSamplesUnvisible', allSamplesUnvisible);
      };
      utils['default'].addObserver(controller, ['model.samples', 'model.looks.@each.archived', 'model.samples.@each.archived', 'showArchivedSamples', 'searchInput', 'itemFilter', 'sizeFilter', 'colourFilter', 'materialFilter'], 'searchFilterFunction');
      controller.searchFilterFunction();

      controller.restartChecks = function () {
        var samples = controller.get('album.samples');
        if (samples.length) {
          samples.map(function (s) {
            return s.set('checked', false);
          });
        }
      };
      utils['default'].addObserver(controller, ['showArchivedSamples'], 'restartChecks');
    },

    onUploadAlbumExcel: function onUploadAlbumExcel() {

      var _this = this;
      var $ = Ember['default'].$;
      var fileArray = new Array();
      var input = $('#import-album-input')[0];

      var album = this.controller.get('model');

      var formData = new FormData();
      formData.append('bookalook_filebundle_excel[name]', album.get('id'));
      formData.append('bookalook_filebundle_excel[file]', input.files[0]);

      if (!input.files[0]) {
        return;
      }

      $('#import-album-input').wrap('<form>').closest('form').get(0).reset();
      $('#import-album-input').unwrap();

      this.send('openMessageModal', 'Importing looks to album. Please Wait.');
      $.ajax({

        url: config['default'].APIURL + 'excels/looks/' + album.get('id'),
        beforeSend: function beforeSend(request) {

          request.setRequestHeader("file", "file");
        },
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function success(data) {
          if (data.excel.errors) {

            if (Ember['default'].typeOf(data.excel.errors) === 'string') {
              data.excel.errors = [data.excel.errors];
            }
            _this.controller.set('excel_errors', data.excel.errors);
            _this.send('closeMessageModal');

            Ember['default'].$('#excel-upload-error').modal();
            Ember['default'].openModal();

            $('body').scrollTop('10000').show();
          } else {
            //_this.send('closeMessageModal');
            //_this.send('openMessageModal', 'Loading uploaded data...');
            var collection = _this.store.findRecord('collection', album.get('collection.id'), { reload: true }).then(function (c) {
              _this.onSaveLooksOrder(false).then(function () {
                _this.send('closeMessageModal');
                //_this.send('openTopBar', 'Looks uploaded correctly.');
                _this.send('openPositiveTopBar');
              });
            });
            /*_this.send('closeMessageModal');
            _this.transitionTo('collections.collection', album.get('collection.id'));*/
            /*
            album.reload();
            Ember.run.later(function () {
              _this.refresh();
              _this.onSaveLooksOrder();
            }, 3000);
            */
          }
        },
        error: function error(data) {
          var error = data.responseJSON.errors;

          _this.send('closeMessageModal');
          if (error) {

            if (error.contacts) {
              //_this.send('openErrorsModal', [error.contacts], 'Oops! We found some errors in the excel.');
              _this.send('closeMessageModal');
              Ember['default'].$('app-message').modal('hide');
              Ember['default'].$('#max-samples-at-once-reached').modal('show');
            } else if (error.maxSamplesReached) {
              _this.send('closeMessageModal');
              Ember['default'].$('app-message').modal('hide');
              _this.send('openLimitationReachedModal', 'Sample');
            } else {

              _this.send('openErrorsModal', [], 'Oops! We found some errors in the excel. Check you didn´t miss the mandatory fields marked with a star *. If you need additional help just drop us an email at info@bookalook.com and we will schedule a call');
            }
          } else {

            _this.send('openErrorsModal', [], 'Oops! We found some errors in the excel. Check you didn´t miss the mandatory fields marked with a star *. If you need additional help just drop us an email at info@bookalook.com and we will schedule a call');
          }
        }
      });
    },

    onActivateLook: function onActivateLook(look) {
      this.setLookArchived(look, false);
    },

    onActivateSample: function onActivateSample(sample) {
      var idModal = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var result = [];
      result.push(sample);
      Ember['default'].$(idModal).modal('hide');
      this.onActivateSamples(result).then(function () {
        if (idModal) {
          Ember['default'].$(idModal).modal();
          Ember['default'].openModal();
        }
      }, function () {
        if (idModal) {
          Ember['default'].$(idModal).modal();
          Ember['default'].openModal();
        }
      });
    },

    onActivateSamples: function onActivateSamples() {
      var samplesArray = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _t = this;
      var selectedSamples = [];
      var album = _t.controller.get('model');

      _t.send('openMessageModal', 'Reactivating samples');

      if (!samplesArray) {
        samplesArray = [];
        var samples = album.get('samples');
        samples.forEach(function (sample) {
          if (sample.get('checked')) {
            samplesArray.push(sample);
          }
        });
      }

      samplesArray.forEach(function (sample) {
        selectedSamples.push(sample.get('id'));
      });

      var postData = {
        selectedSamples: selectedSamples
      };
      return utils['default'].postAPIJSON('albums/reactivateSelectedSamples', postData).then(function (res) {
        return _t.store.findRecord('collection', album.get('collection.id'), { reload: true }).then(function (collection) {
          collection.set('archived', false);
          _t.send('closeMessageModal');
          _t.send('openPositiveTopBar');
          samplesArray.forEach(function (sample) {
            sample.set('archived', false);
          });
          return collection;
        }, function (fail) {
          return fail;
        });
      }, function (fail) {
        _t.send('openTopBar', "Oops, we couldn't archive your samples.");
        return fail;
      });
    },

    onActivateAlbum: function onActivateAlbum(album) {
      var t = this;
      utils['default'].putAPIJSON('albums/' + album.get('id') + '/reactivate').then(function () {
        album.set('archived', false);
        var looks = album.get('looks');
        looks.forEach(function (look) {
          t.setLookArchived(look, false);
        });
        album.reload();
      });
    },

    onOpenNewLookModal: function onOpenNewLookModal() {
      var _this4 = this;

      delete_count = 0;
      duplicate_count = 0;
      this.controller.set('maxSamplesReached', false);
      //this.reloadNumSamplesForShowroom(this.controller.get('model').get('collection').get('brand').get('showrooms'));

      utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countSamples').then(function (res) {
        //Current samples count
        _this4.controller.set('numSamples', res);
        _this4.controller.set('numTempSamplesToAdd', null);
        var limitPlanSamples = _this4.controller.get('limitPlanSamples');
        //We're going to allow empty looks creation after PR reaches sample limitation!
        //if (limitPlanSamples && limitPlanSamples != 0 && res && res >= limitPlanSamples) {
        //  this.send('openLimitationReachedModal', 'Sample');
        //  return;
        //} else {
        var collectionType = _this4.controller.get('model.collection.collectionType');
        // If collection type is women or haute couture, look is women
        var lookGender;
        if (collectionType === "Womenswear" || collectionType === "Haute Couture") {
          lookGender = "Women";
        } else if (collectionType === "Menswear") {
          lookGender = "Men";
        } else if (collectionType === "Boy (kidswear)") {
          lookGender = "Boy";
        } else if (collectionType === "Girl (kidswear)") {
          lookGender = "Girl";
        }

        var newLook = _this4.store.createRecord('look', { department: lookGender });
        _this4.controller.set('_oldDepartment', lookGender);
        _this4.controller.set('lookToAdd', newLook);
        _this4.set('_oldDepartment', _this4.get('lookToAdd.department'));
        var sortableObjectList = [1, 2, 3, 4, 5];
        _this4.controller.set('avat', [1, 2, 3, 4, 5]);
        _this4.onSetResponseImages();
        $('#new-look').modal('show');
        //}
      });

      /*
       this.onAddAnotherSample(newLook);
       */
    },

    onOpenEditLookModal: function onOpenEditLookModal(look) {
      var _t = this;

      delete_count = 0;
      duplicate_count = 0;

      this.controller.set('lookToAdd', look);
      this.controller.set('_oldDepartment', look.get('department'));

      this.controller.get('album').get('collection').then(function () {
        _t.reloadNumSamplesForShowroom(look.get('album').get('collection').get('brand').get('showrooms'));
        _t.controller.set('maxSamplesReached', false);

        //THIS slice is necessary for make a copy of the array and it won't be modified by reference
        avatarsOriginalOrder = look.get('avatars').slice(0);
        var sortableObjectList = [1, 2, 3, 4, 5];
        _t.controller.set('avatarsOrder', sortableObjectList);
        _t.onSetResponseImages(avatarsOriginalOrder);

        Ember['default'].$('#new-look').modal();
        Ember['default'].openModal();
      });
    },

    onOpenEditAlbumModal: function onOpenEditAlbumModal() {
      var album = this.controller.get('model');
      this.controller.set('albumToAdd', album);
    },

    reloadNumSamplesForShowroom: function reloadNumSamplesForShowroom(showrooms) {
      var _this5 = this;

      showrooms.forEach(function (sh) {
        utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countSamples').then(function (res) {
          //Current samples count
          _this5.controller.set('numSamples', res);
        });
      });
    },

    onAddAnotherSample: function onAddAnotherSample(editLook) {

      //Check samples limit for the plan user
      //var albumId = this.controller.get('model.id');

      //Current samples count
      var numSamples = this.controller.get('numSamples');

      var totalSamples;
      var numTempSamplesToAdd = this.controller.get('numTempSamplesToAdd');
      if (!numTempSamplesToAdd) {
        numTempSamplesToAdd = 0;
        this.controller.set('numTempSamplesToAdd', 0);
      }

      totalSamples = numTempSamplesToAdd + numSamples + duplicate_count;
      totalSamples = totalSamples - delete_count;
      var limitPlanSamples = this.controller.get('limitPlanSamples');
      var maxSamplesReached;

      //Check limits
      if (limitPlanSamples && limitPlanSamples != 0 && totalSamples && totalSamples >= limitPlanSamples) {
        //     maxSamplesReached = true;
        //this.controller.set('numTempSamplesToAdd', 0);
        this.controller.set('numSamples', numSamples);

        $('#max-samples-reached').modal('show');

        //this.controller.set('maxSamplesReached', true);
        //this.onOpenNewSamplesLimitReachedModal();
        return;
      } else {
        maxSamplesReached = false;
        this.controller.set('maxSamplesReached', false);
        numTempSamplesToAdd++;
        // this.controller.set('maxSamplesReached', numSamples);

        this.controller.set('numTempSamplesToAdd', numTempSamplesToAdd);
        set = totalSamples;

        this.controller.set('o', totalSamples);

        editLook.get("samples").addObject(this.store.createRecord('sample', {
          look: editLook,
          showroom: Ember['default'].showroom,
          groupid: Date.now()
        }));
      }
    },

    onSaveLook: function onSaveLook(look) {
      var _this6 = this;

      var msg = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var samples = look.get('samples').toArray();
      var promise = null;
      var validated = true;
      var _t = this;
      var errors = [];
      samples.forEach(function (sample) {
        var sampleValidated = sample.validate();
        if (!sampleValidated) {
          var errorLabel = '';
          if (sample.get('isNew')) {
            errorLabel = sample.get('itemLabel') + ' (new item)';
          } else {
            errorLabel = sample.get('itemLabel') + ' (' + sample.get('barcode') + ')';
          }
          errorLabel = errorLabel + ' is missing some field.';
          errors.push(errorLabel);
        }
        validated = sampleValidated && validated;
      });
      if (!validated) {
        //console.log(errors);
        return;
      } else {
        Ember['default'].$('#new-look').modal('hide');
      }
      this.onReorderAvatars(look);
      look.set('album', this.controller.get('model'));
      this.send('openMessageModal', 'Saving look.');

      samples.forEach(function (sample) {
        sample.set('look', look);
        if (sample.get("hasDirtyAttributes") && !sample.get('isSaving')) {
          if (!sample.get('groupid')) {
            sample.set('groupid', Math.random());
          }
          _this6._saveSampleDuplicates(sample);
        }
      });

      var serializedLook = look.serialize();
      serializedLook.samples = [];
      look.get('samples').forEach(function (s) {
        var serializedSample = s.serialize();
        serializedSample.id = s.get('id');
        serializedLook.samples.addObject(serializedSample);
      });
      serializedLook.avatar = "";
      if (look.get('id')) {
        promise = utils['default'].putAPIJSON('looks/updateWithSamples/' + look.get('id'), { look: serializedLook }).then(function () {
          _t._onRestartGalleryNoLazyLoad();
          _this6.onSaveLooksOrder().then(function () {
            if (msg) {
              _t.send('openTopBarWithTime', msg, 2000);
            }
            _this6._lookModelReload(look).then(function () {
              _t.controller.get('model').set('collection.avatar', _t.controller.get('model').get('avatar'));
            });
          });
          _this6.controller.set('_oldDepartment', null);
        });
      } else {
        promise = utils['default'].postAPIJSON('looks/createWithSamples', { look: serializedLook }).then(function (newLook) {
          look.destroyRecord();
          utils['default'].pushPayload(_this6.store, 'look', newLook.look);
          _this6.onSaveLooksOrder().then(function () {
            //this.controller.get('model').save().then(() => {
            _this6._lookModelReload(look).then(function () {
              _t.controller.get('model').set('collection.avatar', _t.controller.get('model').get('avatar'));
            });
            //});
          });
          _this6.controller.set('_oldDepartment', null);
        });
      }

      this.controller.set('numTempSamplesToAdd', 0);
    },

    _onRestartGalleryNoLazyLoad: function _onRestartGalleryNoLazyLoad() {
      var _t = this;
      _t.controller.set('restartGalleryNoLazyLoad', true);
      setTimeout(function () {
        _t.controller.set('restartGalleryNoLazyLoad', false);
      }, 50);
    },

    _cleanLook: function _cleanLook(look) {
      look.get('samples').forEach(function (sample) {
        try {
          sample.rollbackAttributes();
        } catch (e) {
          //'em all
        }
      });
    },

    _lookModelReload: function _lookModelReload(look) {
      duplicate_count = 0;
      delete_count = 0;
      this.send('closeMessageModal');
      //Reset input files
      Ember['default'].$('#looks-gallery-uploader-form').trigger("reset");
      var promise = this.controller.get('model').reload();
      this.controller.notifyPropertyChange('model');
      this._cleanLook(look);
      return promise;
    },

    _saveSampleDuplicates: function _saveSampleDuplicates(sample) {
      if (sample.get('isMain')) {
        sample.get('group').forEach(function (s) {
          if (!s.get('isSaving')) {
            s.setMainProperties(sample);
          }
        });
      }
    },

    onCancelEdition: function onCancelEdition(look) {
      var samples = look.get('samples');
      if (!Ember['default'].isNone(samples) && samples.get('length') > 0) {

        var copy = samples.toArray();
        copy.forEach(function (s) {
          s.rollbackAttributes();
        });
      }

      this.controller.set('_oldDepartment', undefined);
      look.rollbackAttributes();
      //look.set('avatars', avatarsOriginalOrder);
      this.controller.set('avatarsOrder', [1, 2, 3, 4, 5]);
      $('.selected-image').removeClass('selected-image');
      this.controller.set('numTempSamplesToAdd', 0);
      duplicate_count = 0;
      delete_count = 0;
      //Reset input files
      Ember['default'].$('#looks-gallery-uploader-form').trigger("reset");
    },

    setLookArchived: function setLookArchived(look, archived) {
      var disableLoading = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var t = this;
      look.set('archived', archived);
      Ember['default'].$('#archive-look').modal('hide');

      if (archived) {
        if (!disableLoading) {
          t.send('openMessageModal', 'Archiving look...');
        }
        //It should be already archived (archived=true only used in archive-process)
        t.store.findRecord('collection', t.controller.get('model').get('collection.id'), { reload: true }).then(function () {
          t.controller.notifyPropertyChange('model.looks.@each.archived');
          if (!disableLoading) {
            t.send('closeMessageModal');
          }
          t.send('openTopBar', 'The Look was archived correctly.');
        });
      } else {
        if (!disableLoading) {
          t.send('openMessageModal', 'Activating look...');
        }
        utils['default'].putAPIJSON('looks/' + look.get('id') + '/reactivate').then(function () {
          t.store.findRecord('collection', t.controller.get('model').get('collection.id'), { reload: true }).then(function (col) {
            col.set('archived', false);
            t.controller.notifyPropertyChange('model.looks.@each.archived');
            if (!disableLoading) {
              t.send('closeMessageModal');
            }
            t.send('openPositiveTopBar');
            return col;
          });
        });
      }
    },

    onPublishAlbum: function onPublishAlbum(album) {
      var _this7 = this;

      var _this = this;

      this.send('openMessageModal', 'Publishing album');
      utils['default'].putAPIJSON('albums/' + album.get('id') + '/publish').then(function () {
        album.set('published', true);
        album.reload();
        _this7.send('closeMessageModal');
      });
    },

    onDeleteAlbum: function onDeleteAlbum() {
      var _this8 = this;

      var model = this.controller.get('model');
      utils['default'].getAPIJSON('albums/' + model.get('id') + '/archivable').then(function (res) {
        var relatedBookings = res.bookings.sortBy('id');
        var inTransfer = res.inTransfer;
        if (relatedBookings.length === 0 && !inTransfer) {
          _this8.transitionTo('collections.collection', model.get('collection.id')).then(function () {
            model.destroyRecord();
            _this8.send('openTopBar', 'Album deleted');
          });
        } else {
          _this8.onOpenDeleteAlbumErrorModal('COMPUTER SAYS NO!', 'This Album can’t be deleted because one or more sample(s) are currently active in the following booking(s):', relatedBookings);
        }
      });
    },

    onOpenAlbumErrorModal: function onOpenAlbumErrorModal(title, subtitle, relatedBookings) {
      this.controller.set('title', title);
      this.controller.set('subtitle', subtitle);
      this.controller.set('relatedBookings', relatedBookings);
      Ember['default'].$('#delete-album-error-modal').modal();
    },

    onManageSampleNewLook: function onManageSampleNewLook(look, sample, action) {
      var _this9 = this;

      var outofmodal = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

      var _t = this;
      switch (action) {
        case 'duplicate':

          if (sample.validate()) {

            utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countSamples').then(function (res) {
              //Current samples count
              _this9.controller.set('numSamples', res);

              var numSamples = res;
              var numTempSamplesToAdd = _this9.controller.get('numTempSamplesToAdd');

              if (!numTempSamplesToAdd) {
                numTempSamplesToAdd = 0;
                _this9.controller.set('numTempSamplesToAdd', 0);
              }
              var totalSamples;
              totalSamples = numSamples + duplicate_count + numTempSamplesToAdd;
              totalSamples = totalSamples - delete_count;
              var limitPlanSamples = _this9.controller.get('limitPlanSamples');

              if (!sample.get('groupid')) {
                sample.set('groupid', Date.now());
              }
              if (limitPlanSamples && limitPlanSamples != 0 && totalSamples && totalSamples >= limitPlanSamples) {
                if (outofmodal) {
                  _this9.send('openLimitationReachedModal', 'Sample');
                } else {
                  _this9.send('openLimitationReachedModal', 'Sample', '#new-look');
                }
                return;
              } else {
                sample.copy().then(function (copy) {
                  copy.set('showroom', Ember['default'].showroom);
                  copy.set('visible', true);
                  look.get("samples").addObject(copy);
                  duplicate_count = duplicate_count + 1;
                  //this.controller.set('duplicate_count',duplicate_count);
                  if (outofmodal) {
                    (function () {
                      duplicate_count = 0;
                      _t.send('openMessageModal', 'Duplicating sample.');
                      var serializedLook = look.serialize();
                      serializedLook.samples = [];
                      look.get('samples').forEach(function (s) {
                        var serializedSample = s.serialize();
                        serializedSample.id = s.get('id');
                        serializedLook.samples.addObject(serializedSample);
                      });
                      serializedLook.avatar = "";
                      copy.save().then(function () {
                        if (look.get('id')) {
                          //utils.putAPIJSON('looks/updateWithSamples/' + look.get('id'), {look: serializedLook}).then((samp) => {

                          //look.reload().then((l) => {
                          //look.set('samples', l.get('samples'));
                          //look.get("samples").addObject(copy);

                          _t.controller.notifyPropertyChange('model.looks.@each.samples');

                          _t.send('closeMessageModal');
                          _t.send('openPositiveTopBar');
                          //});
                          //});
                        } else {
                            _t.send('closeMessageModal');
                          }
                      });
                    })();
                  }
                });
              }
            });
          }
          break;
        case 'delete':
          //Comprobar si está vacio
          if (sample.get('checkIsNewAndBlank')) {
            sample.destroyRecord().then(function () {
              if (outofmodal) {
                _t.send('openTopBarWithTime', 'The sample has been deleted.', 2000);
              } else {
                delete_count = delete_count + 1;
                Ember['default'].$('#new-look').modal('show');
              }
            });
          } else {
            utils['default'].openDefaultConfirmationModal(this, 'Delete Sample', 'You are about to delete this sample.', 'Do you wish to proceed?', 'Yes, delete this sample', 'No', 'new-look').then(function () {

              // var numSamples= this.controller.get('numSamples');
              //   var numTempSamplesToAdd= this.controller.get('numTempSamplesToAdd');
              //this.controller.set('maxSamplesReached', false);
              //delete_count = delete_count + 1;

              //if (numSamples) {

              //this.controller.set('numSamples', numSamples-1);
              //this.controller.set('numTempSamplesToAdd', numSamples-1);
              //  this.controller.set('maxSamplesReached', false);

              //}
              if (sample.get('isNew')) {
                sample.deleteRecord();
                if (outofmodal) {
                  _t.send('openTopBarWithTime', 'The sample has been deleted.', 2000);
                } else {
                  delete_count = delete_count + 1;
                  Ember['default'].$('#new-look').modal('show');
                }
              } else {
                sample.destroyRecord().then(function () {
                  if (outofmodal) {
                    _t.send('openTopBarWithTime', 'The sample has been deleted.', 2000);
                  } else {
                    delete_count = delete_count + 1;
                    Ember['default'].$('#new-look').modal('show');
                  }
                });
              }
            }, function () {
              if (outofmodal) {
                //_t.send('openTopBarWithTime', 'The sample has been deleted.', 2000);
              } else {
                  Ember['default'].$('#new-look').modal('show');
                }
            });
          }

          break;
        case 'archive':
          if (outofmodal) {
            sample.toggleProperty('archived');
            look.notifyPropertyChange('samples');
            //_t.send('openTopBarWithTime', 'The sample has been activated.', 2000);
            _t.send('openPositiveTopBar');
            sample.save();
          } else {
            sample.toggleProperty('archived');
            look.notifyPropertyChange('samples');
            sample.save();
            //_t.send('openTopBarWithTime', 'The sample has been activated.', 2000);
            _t.send('openPositiveTopBar');

            /*Ember.$("#sample-card-" + sample.get("id")).fadeOut('fast', function () {
              sample.toggleProperty('archived');
              look.notifyPropertyChange('samples');
              sample.save();
            });*/
          }
          break;
        case 'clear':
          sample.eachAttribute(function (name, meta) {
            switch (meta.type) {
              case 'number':
                sample.set(name, null);
                break;
              case 'string':
                sample.set(name, '');
                break;
              default:
                sample.set(name, '');
            }
          });
          break;
      }
    },

    onDuplicateSelectedSamples: function onDuplicateSelectedSamples(look) {
      var _this10 = this;

      var _t = this;

      //Foreach bulksamples as sample
      var selectedSamples = [];

      Ember['default'].bulk.allSamples.forEach(function (sample) {
        if (sample.checked && sample.get('look').get('id') == look.get('id')) {
          if (!sample.get('groupid')) {
            sample.set('groupid', Date.now());
          }
          selectedSamples.push(sample);
        }
      });

      utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countSamples').then(function (res) {
        //Current samples count
        _this10.controller.set('numSamples', res);
        var numSamples = res;
        var numTempSamplesToAdd = selectedSamples.length;

        if (!numTempSamplesToAdd) {
          numTempSamplesToAdd = 0;
          _this10.controller.set('numTempSamplesToAdd', 0);
        }

        var duplicate_count;
        var totalSamples;
        totalSamples = numSamples + numTempSamplesToAdd;
        var limitPlanSamples = _this10.controller.get('limitPlanSamples');

        if (limitPlanSamples && limitPlanSamples != 0 && totalSamples && totalSamples > limitPlanSamples) {
          _this10.send('openLimitationReachedModal', 'Sample');
          return;
        } else {
          (function () {
            _t.send('openMessageModal', 'Duplicating selected sample(s)');

            var promises = Ember['default'].A();

            selectedSamples.forEach(function (sample) {
              //console.log(sample.id);
              //each sample should be duplicated
              promises.push(sample.copy().then(function (copy) {
                copy.set('showroom', Ember['default'].showroom);
                look.get("samples").addObject(copy);
                //duplicate_count = duplicate_count + 1;
                //this.controller.set('duplicate_count',duplicate_count);
              }));
            });

            Ember['default'].RSVP.all(promises).then(function () {

              var serializedLook = look.serialize();
              serializedLook.samples = [];
              look.get('samples').forEach(function (s) {
                var serializedSample = s.serialize();
                serializedSample.id = s.get('id');
                serializedLook.samples.addObject(serializedSample);
              });

              serializedLook.avatar = "";

              if (look.get('id')) {
                utils['default'].putAPIJSON('looks/updateWithSamples/' + look.get('id'), { look: serializedLook }).then(function () {

                  //look.reload().then((l) => {
                  _t.controller.get('model').reload().then(function (m) {
                    //look.set('samples', l.get('samples'));
                    look.get('samples').forEach(function (sample) {
                      try {
                        if (!sample.get('id')) {
                          sample.destroyRecord();
                        } else {
                          sample.rollbackAttributes();
                        }
                      } catch (e) {
                        //'em all
                      }
                    });
                    look.rollbackAttributes();
                    _this10.controller.set('numTempSamplesToAdd', 0);
                    _t.send('closeMessageModal');
                    _t.controller.notifyPropertyChange('model.looks.@each.samples');
                    //_t.send('openTopBarWithTime', 'Selected sample(s) duplicated correctly.', 2000);
                    _t.send('openPositiveTopBar');
                  });
                });
              }
            });
          })();
        }
      });
    },

    onSaveLooksOrder: function onSaveLooksOrder() {
      var skipReload = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      var model = this.controller.get('model');
      var _t = this;

      var id = model.get('id');
      var list = this.controller.get('looksSortList');
      var listIds = list.map(function (l) {
        return l.id;
      });
      var _this = this;
      return utils['default'].postAPIJSON('albums/' + id + '/order', { order: listIds }).then(function () {
        list.forEach(function (look, index) {
          look.set('position', index + 1);
          _this._cleanLook(look);

          _this.controller.set('looksSortList', Ember['default'].computed('model.looks.@each.position', 'model.looks.@each.archived', function () {
            return this.get('model.orderedLooks');
          }));
        });
        if (!skipReload) return model.reload();
      });
    },

    onCancelUploadPicture: function onCancelUploadPicture(id, model, modalId) {
      this.send('cancelUploadPicture', id, model);
      Ember['default'].$(modalId).modal();
      Ember['default'].openModal();
    },
    onNewUploadPicture: function onNewUploadPicture(id, tag, model, modalId) {
      this.send('newUploadPicture', id, tag, model, true);
      Ember['default'].$(modalId).modal();
      Ember['default'].openModal();
    },
    addAnotherSampleon: function addAnotherSampleon(itemModels) {
      var imageFirstLook = itemModels[0].get('getImage');
      this.controller.set('looksSortList', itemModels);
      this.controller.set('model.collection.avatar', imageFirstLook);
      this.controller.set('model.avatar', imageFirstLook);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-album');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-album');
    },

    onOpenNewSamplesLimitReachedModal: function onOpenNewSamplesLimitReachedModal(route, plan) {

      /* Ember.run.later(function() {
       }, 1000);*/
      Ember['default'].$('#max-samples-reached').modal();
      Ember['default'].openModal(1000, '#max-samples-reached');
    },
    /**
     * Choose an avatar for acting on it
     * @param position The param is the position of clicked the button
     */
    onSelectAvatar: function onSelectAvatar(position) {
      var position = 'p:contains("' + position + '")';
      var wrapper = $('.gallery-button').find(position).parent('li');
      var imageSource = wrapper.find('img.look-avatar').attr('src');
      var currentSelectedElement = '.selected-image';
      var currentSelectedIndex = $(currentSelectedElement).find('p').text();

      if (wrapper.attr('disabled') !== 'disabled') {

        $('.selected-image').removeClass('selected-image');
        wrapper.addClass('selected-image');
        if (imageSource !== "") {
          //PONER EL EXSITENTE
          //First hide and show differents possibilities
          $('#preview-image').removeClass('hidden-preview');
          $('#new-look-image').removeClass('hidden-preview');
          $('#new-look-image').find('img.look-avatar').removeClass('hidden-preview');
          $('#delete-image').removeClass('hidden-preview');

          $('#empty-preview').addClass('hidden-preview');

          //then we set the image
          $(new Image()).attr('src', imageSource).load(function () {
            $('#preview-image').attr('src', this.src);
          });
        } else {
          //PONER EL VACÍO
          $('#preview-image').addClass('hidden-preview');
          $('#new-look-image').addClass('hidden-preview');
          $('#new-look-image').find('img').addClass('hidden-preview');
          $('#delete-image').addClass('hidden-preview');
          $('#empty-preview').removeClass('hidden-preview');
        }
      }
    },
    /**
     * Method to set avatars from response into its respective buttons.
     * @param avatars
     */
    onSetResponseImages: function onSetResponseImages(avatars) {
      var internalAvatars = [];
      var alreadySelected = $('.selected-image').find('p').text();

      if (avatars !== undefined && avatars.length) {
        internalAvatars = avatars;
      }

      //Clean previous src in buttonos.
      $('.gallery-button').find('img').attr('src', "");
      $('.gallery-button').removeClass('existing-image', 'selected-image');
      $('.gallery-button').removeClass('no-image');

      //Iterate in the response to set images.
      internalAvatars.forEach(function (value, key) {
        var avatarIndex = value.position;
        var wrapper = $('.buttons-wrapper').children().eq(key);
        var avatarPath = "";
        if (value.thumbnail) avatarPath = value.thumbnail;else avatarPath = value.avatar;
        var attackedImage = $('.gallery-button').find('p:contains("' + avatarIndex + '")').parent('li');
        if (avatarPath !== "" && avatarPath !== undefined) {
          attackedImage.find('img.look-avatar').attr('src', avatarPath);
          attackedImage.addClass('existing-image');
        } else {
          attackedImage.find('img.look-avatar').attr('src', "");
        }
      });

      for (var i = 0; i <= 5; i++) {
        var li = $('.buttons-wrapper').children().eq(i);
        if (li.find('img.look-avatar').attr('src') == "") {
          li.addClass('no-image');
        }
      }

      //Set selected
      if (alreadySelected !== undefined && alreadySelected !== "") {
        this.onSelectAvatar(alreadySelected);
      } else {
        this.onSelectAvatar(1);
      }
      //Clean the queue
      bulkUploadAvatars = [];
    },
    /**
     * Method associated to delete symbol(X) into the image preview, remove the image from the list
     */
    onDeleteImage: function onDeleteImage() {
      var id = $('.selected-image').find('p').text();
      var avatarsArray = this.controller.get('lookToAdd').get('avatars').slice(0);
      avatarsArray.splice(id - 1, 1);
      this.controller.get('lookToAdd').set('avatars', avatarsArray);
      this.onSetResponseImages(avatarsArray);
    },

    /**
     * Method associated to delete symbol(X) into the image preview, remove the image from the list
     */
    onDeleteImageAvatar: function onDeleteImageAvatar(position) {
      var avatarsArray = this.controller.get('lookToAdd').get('avatars').slice(0);
      avatarsArray.splice(position - 1, 1);
      var avatarsOrderedArray = [];

      //Update positions!!!
      avatarsArray.forEach(function (avatar) {
        var positionAvatar = avatar.position;
        if (positionAvatar > position) {
          positionAvatar = positionAvatar - 1;
          Ember['default'].set(avatar, 'position', positionAvatar);
        }
        avatarsOrderedArray.push(avatar);
      });

      this.controller.get('lookToAdd').set('avatars', avatarsOrderedArray);
      this.onSetResponseImages(avatarsOrderedArray);
    },

    /**
     * When we're going to save the look, the list must be reordered to be uploaded with correct positions.
     * @param look
     * The model is passed by parameter to allow saving it after the change
     */
    onReorderAvatars: function onReorderAvatars(look) {
      var currentOrder = this.controller.get('avatarsOrder');

      if (look.get('avatars')) {
        var avatars = look.get('avatars').splice(0);
        var newOrder = [];
        var position = 1;

        for (var i = 0; i <= currentOrder.length; i++) {
          var index = currentOrder[i];
          avatars.forEach(function (value, key) {
            if (value.position == index && index != "" && value.position != "") {
              var newAvatar = {
                look: value.look,
                id: value.id,
                avatar: value.avatar,
                thumbnail: value.thumbnail,
                position: position
              };
              position++;
              newOrder.push(newAvatar);
            }
          });
        }
        look.set('avatars', newOrder);
      }
    },
    /**
     * This method is prepared for disable an element and every possible son.
     * @param imageComponent
     * This is mainly for the imageComponent but being parameterized you can use for what you need.
     */
    onDisableImageComponent: function onDisableImageComponent() {
      $('.loader-container.loading-image').removeClass('hidden-preview');
      $('.gallery-button').attr('disabled', 'disabled');
      $('.upload-edit').find('*').attr('disabled', 'disabled');
      $('.bulk-upload-link').find('*').attr('disabled', 'disabled');
      $('#empty-preview').find('*').attr('disabled', 'disabled');
      $('.save-button').attr('disabled', 'disabled');
      $('.save-button').text('Loading...');
      bulkUploadAvatars = [];
    },
    /**
     * This method is prepared for enable an element and every possible son.
     * @param imageComponent
     * This is mainly for the imageComponent but being parameterized you can use for what you need.
     */
    onEnableImageComponent: function onEnableImageComponent(id) {
      $('#multi-upload').attr('style', '');
      $('.loader-container.loading-image').addClass('hidden-preview');
      $('.gallery-button').removeAttr('disabled');
      $('.upload-edit').find('*').removeAttr('disabled');
      $('.bulk-upload-link').find('*').removeAttr('disabled');
      $('#empty-preview').find('*').removeAttr('disabled');
      $('.save-button').removeAttr('disabled');
      if (id != "" && id != undefined && id != null) {
        $('.save-button').text('Save look');
      } else {
        $('.save-button').text('Add look');
      }
    },

    onManageUploadResponse: function onManageUploadResponse(data, model) {
      var positionIndex = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var path = config['default'].APIHost + '/' + data.image.webpath;
      var thumbnail = config['default'].APIHost + '/' + data.image.thumbnail;
      var selectedImageId = 0;
      if (positionIndex) {
        selectedImageId = positionIndex;
      } else {
        selectedImageId = $('.selected-image').find('p').text();
      }
      var object = {
        id: "",
        avatar: path,
        thumbnail: thumbnail,
        position: selectedImageId,
        look: model.id
      };
      this.onPushArray(object, model, selectedImageId);
      this.onEnableImageComponent(model.id);
      this.onSetResponseImages(model.get('avatars'));
    },

    onPushArray: function onPushArray(object, model, index) {
      var currentOrder = this.controller.get('avatarsOrder');
      var arrayAvatars = model.get('avatars');
      var newAvatarsOrder = [];
      //If it comes empty, must be defined as array
      if (arrayAvatars == undefined) {
        arrayAvatars = [];
      }

      arrayAvatars.push(object);
      $.each(arrayAvatars, function (key, value) {
        if (value.position == index) {
          newAvatarsOrder[key] = object;
        } else {
          newAvatarsOrder[key] = arrayAvatars[key];
        }
      });
      var cleanArray = newAvatarsOrder.filter(function (v) {
        return v !== '';
      });

      //Save it in the model.
      model.set('avatars', cleanArray);
    },
    onManageMultiuploadResponse: function onManageMultiuploadResponse(data, model) {
      var path = config['default'].APIHost + '/' + data.image.webpath;
      var thumbnail = config['default'].APIHost + '/' + data.image.thumbnail;
      var position = bulkUploadAvatars.length + 1;
      var limit = $("input[name=file1]").get(0).files.length * 1 - 1;
      var object = {
        id: "",
        avatar: path,
        thumbnail: thumbnail,
        position: position,
        look: model.id
      };

      if (bulkUploadAvatars.length < limit) {

        bulkUploadAvatars.push(object);
      } else if (bulkUploadAvatars.length == limit) {

        bulkUploadAvatars.push(object);
        model.set('avatars', bulkUploadAvatars);
        this.onEnableImageComponent(model.id);
        this.onSetResponseImages(bulkUploadAvatars);
      }
    },
    onSortAvatars: function onSortAvatars(list) {
      var _this = this;
      var attackedElement = $('.is-dropping').eq(0);
      var currentOrder = this.controller.get('lookToAdd').get('avatars');

      this.controller.set('avatarsOrder', list);
      var indice = attackedElement.find('p').text() * 1;
      //console.log(attackedElement, attackedElement.find('p').text()*1);
      setTimeout(function () {
        _this.onSelectAvatar(indice);
        _this.onSetResponseImages(_this.controller.get('lookToAdd').get('avatars'));
      }, 50);
    },

    _setSizeFilter: function _setSizeFilter(controller) {
      var _this11 = this;

      //Find any sample size and gather all into an array
      var allSizesContent = [{ id: 'All Sizes', safeString: 'All Sizes' }];
      this.controller.set('allSizesContent', allSizesContent);

      utils['default'].getAPIJSON('collections/filter/size').then(function (response) {
        response.filterSize.forEach(function (value) {
          if (value) {
            var sizeArray = { id: value, name: value, safeString: value };
            allSizesContent.push(sizeArray);
          }
        });

        allSizesContent = allSizesContent.sort(function (a, b) {
          return a.safeString.toLowerCase().localeCompare(b.safeString.toLowerCase());
        });

        _this11.controller.set('allSizesContent', allSizesContent);
      });
    },

    onCheckArchivableCheckedSamples: function onCheckArchivableCheckedSamples(lookId) {
      //Comprobar si chequeados son archivables

      var selectedSamples = [];

      Ember['default'].bulk.allSamples.forEach(function (sample) {
        if (sample.get('look').get('id') == lookId) {
          selectedSamples.push(sample.get('id'));
        }
      });

      var postData = {
        selectedSamples: selectedSamples
      };
      return utils['default'].postAPIJSON('albums/' + this.controller.get('model').get('id') + '/archivableSamples', postData);
    }

  });

});