define('bookalook/models/job-status', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    job: DS['default'].belongsTo('job', { async: true }),
    sample: DS['default'].belongsTo('sample', { async: true }),
    status: DS['default'].attr('string'),
    dateAction: DS['default'].attr('day'),
    shippingType: DS['default'].attr('string'),
    shippingCourier: DS['default'].attr('string'),
    shippingNumber: DS['default'].attr('string')
  });

});