define('bookalook/tests/acceptance/2-booking/booking-list-page-feature.jshint', function () {

  'use strict';

  QUnit.module('JSHint - acceptance/2-booking/booking-list-page-feature.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'acceptance/2-booking/booking-list-page-feature.js should pass jshint.');
  });

});