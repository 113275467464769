define('bookalook/helpers/substring', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[2]) {
      return;
    }
    var string = params[0];
    var start = params[1];
    var limit = params[2];
    var limite = string.length > limit ? limit : string.length;
    return string.substring(start, limite).toUpperCase();
  });

});