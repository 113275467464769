define('bookalook/routes/admin/functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.verify = verify;
  exports.discard = discard;
  exports.makeNewAgain = makeNewAgain;
  exports.toggleUserSearchable = toggleUserSearchable;
  exports.removeMPUser = removeMPUser;
  exports.removePRUser = removePRUser;
  exports.changeUsersStatusFilter = changeUsersStatusFilter;
  exports.getShowroomsAttrSum = getShowroomsAttrSum;
  exports.getSamplesAttrSum = getSamplesAttrSum;
  exports.setupAdminUserController = setupAdminUserController;
  exports.openNewPlanModal = openNewPlanModal;
  exports.onSaveNewPlan = onSaveNewPlan;
  exports.createNewPRUserInStripeModal = createNewPRUserInStripeModal;
  exports.onRemoveUserStripeCustomerId = onRemoveUserStripeCustomerId;

  function verify(user) {
    var controller = this.controller;
    var isUserType;
    user.set('statusRegister', 4);

    user.set('searchable', user.get('isMP'));
    user.save();
    //Creating user in maqpie
    utils['default'].postAPIJSON('maqpie/user/create_mq_and_update_bk_user', { id: user.get('id') });

    //  Ember.run.later(function() {
    //         Ember.user.reload();
    //    $('#adminid').show();
    //Ember.refresh();
    //      }, 1000);

    //this.refresh();

    //this.controller.set("statusFilter", '3');
    // this.transitionTo('admin.pr-users');
  }

  function discard(user) {
    user.set('statusRegister', 5);
    user.set('searchable', false);
    user.save();
  }

  function makeNewAgain(user) {
    user.set('statusRegister', 3);
    user.set('searchable', false);
    user.save();
  }

  function toggleUserSearchable(user, route) {
    var check = confirm("Are You Sure?");
    if (check == true) {
      utils['default'].putAPIJSON('users/' + user.id + '/toggle_searchable').then(function () {
        user.toggleProperty('searchable');
        route.send('openPositiveTopBar');
        //_t.send('openTopBar', 'User has been saved.');
      }, function (fail) {
        var errors = '';
        if (fail && fail.responseText) {
          errors = JSON.parse(fail.responseText).errors;
        }
        route.send('openTopBar', 'Ooops! ' + errors);
      });
    }
  }

  function removeMPUser(user, route) {
    var check = confirm("Are You Sure?");
    if (check == true) {
      utils['default'].putAPIJSON('users/' + user.id + '/remove_mp_user').then(function () {
        //Borrar de la lista
        Ember['default'].$('#mp-row-' + user.id).remove();
        route.send('openPositiveTopBar');
      }, function (fail) {
        var errors = '';
        if (fail && fail.responseText) {
          errors = JSON.parse(fail.responseText).errors;
        }
        route.send('openTopBar', 'Ooops! ' + errors);
      });
    }
  }

  function removePRUser(user, route) {

    utils['default'].getAPIJSON('users/' + user.id + '/is_removable').then(function () {

      var check = confirm("The PrUser will be completely removed from showroom. Also Stripe subscription for showroom will be updated " + " Are you sure? ");
      if (check == true) {
        utils['default'].deleteAPIJSON('users/' + user.id).then(function () {
          //Borrar
          Ember['default'].$('#pr-row-' + user.id).remove();
          route.send('openPositiveTopBar');
        }, function (fail) {
          var errors = '';
          if (fail && fail.responseText) {
            errors = JSON.parse(fail.responseText).errors;
          }
          route.send('openTopBar', 'Ooops! ' + errors);
        });
      }
    }, function (fail) {
      var errors = '';
      if (fail && fail.responseText) {
        errors = JSON.parse(fail.responseText).errors.user;
      }
      route.send('openTopBar', 'Info: ' + errors);
    });
  }

  function changeUsersStatusFilter(filter) {

    this.controller.set("statusFilter", filter);
  }

  function getShowroomsAttrSum(showrooms, attr) {
    return showrooms.map(function (sh) {
      return sh[attr].length;
    }).reduce(function (count, usersNumber) {
      return count + usersNumber;
    }, 0);
  }

  function getSamplesAttrSum(samples, attr) {
    return samples.map(function (sa) {
      return sa[attr].length;
    }).reduce(function (count, usersNumber) {
      return count + usersNumber;
    }, 0);
  }

  function setupAdminUserController(controller, users, isUserType) {
    controller.setProperties({
      users: users.filterBy(isUserType),
      isPRUsersPage: isUserType === 'isPR',
      isMPUsersPage: isUserType === 'isMP',
      statusFilter: 4,

      filteredUsers: Ember['default'].computed('users.@each.company', 'users.@each.statusRegister', 'statusFilter', function () {
        var _this = this;

        var statusFilter = this.get('statusFilter');

        utils['default'].getAPIJSON('admin/mp-users').then(function (numberJobs) {
          var users = _this.get('users');
          users.forEach(function (user) {
            var userId = user.get('id');
            user.set('numberJobs', numberJobs[userId]);
          });
        });

        return this.get('users').filterBy('statusRegister', statusFilter).sortBy('companyName');
      })
    });
  }

  function openNewPlanModal(route, plan) {
    var planObject = plan || route.store.createRecord('plan');
    route.controller.set('planToAdd', planObject);

    Ember['default'].run.later(function () {
      //    Ember.$('body').addClass('modal-open');
    }, 1000);
    Ember['default'].$('#new-plan').modal();
    Ember['default'].openModal(1000, '#new-plan');
    //Ember.openModal();
  }

  function onSaveNewPlan(route, plan) {
    //var plan  = route.controller.get('planToAdd');
    var deferred = Ember['default'].$.Deferred();
    var promises = [];

    if (!plan.validate()) {
      return false;
    }

    Ember['default'].RSVP.all(promises).then(function () {
      deferred.resolve();
    });

    deferred.done(function () {
      plan.save().then(function () {
        Ember['default'].$('#add-plan, #new-plan').modal('hide');
        route.refresh();
        route.send('openTopBar', 'Plan saved succesfully');
      });
    });

    return;
  }

  function createNewPRUserInStripeModal(route, plan, user) {

    var check = confirm("Are You Sure?");
    if (check == true) {
      Ember['default'].run.later(function () {
        //$('#bill').hide();
        //  $('#billmodel').show();

        $('#billmodeld').show();
        // $('#paymentinfo').hide();
      }, 50);
      utils['default'].putAPIJSON('plans/register/user/' + user + '/plan/' + plan).then(function (res) {
        //Refresh screen
        $('#billmodeld').hide();
        route.refresh();
        route.send('openTopBar', 'User registered in Stripe succesfully');
      });
    }

    return;
  }

  function onRemoveUserStripeCustomerId(route, user) {
    utils['default'].putAPIJSON('users/' + user + '/remove_stripe_data').then(function () {
      Ember['default'].$('#stripe-customer-id-remove-' + user.id).modal('hide');
      route.refresh();
      route.send('openTopBar', 'Data removed succesfully');
    });
  }

});