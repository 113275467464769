define('bookalook/routes/ddt-samples/index', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils', 'bookalook/routes/bookings/functions'], function (exports, Ember, config, UserValidatorMixin, utils, functions) {

  'use strict';

  exports.filterByCollection = filterByCollection;
  exports.removeScrollListener = removeScrollListener;
  exports._scrollCalculation = _scrollCalculation;
  exports.setScrollPaginationListener = setScrollPaginationListener;
  exports._hidingControllerSamples = _hidingControllerSamples;
  exports.removeListenersAndObservers = removeListenersAndObservers;

  var controllerGlobal = null;

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    poll: Ember['default'].inject.service(),

    actions: {
      willTransition: function willTransition() {
        //Remove listeners on transition
        removeListenersAndObservers(this);

        /*
        this.get('poll').clearAll();
        let _t = this;
        let controller = _t.controller;
        controller.removeObserver('collectionFilter', controller, 'albumsFilteredByCollectionObserver');
        controller.removeObserver('brandSearchFilter', controller, 'collectionsFilteredByBrandObserver');
        controller.removeObserver('collections', controller, 'collectionsFilteredByBrandObserver');
        controller.removeObserver('collectionsToArchive', controller, 'collectionsFilteredByBrandObserver');
        controller.removeObserver('brandFilter', controller, 'resetAlbumPageObserver');
        controller.removeObserver('collectionFilter', controller, 'resetAlbumPageObserver');
        controller.removeObserver('albumFilter', controller, 'resetAlbumPageObserver');
        controller.removeObserver('searchInput', controller, 'resetAlbumPageObserver');
        controller.removeObserver('colourFilter', controller, 'resetAlbumPageObserver');
        controller.removeObserver('sizeFilter', controller, 'resetAlbumPageObserver');
        controller.removeObserver('itemFilter', controller, 'resetAlbumPageObserver');
        */
      },
      download: function download() {
        var skipSave = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
        this.onDownload(skipSave);
      },
      saveLoanForm: function saveLoanForm() {
        var _this = this;

        functions['default'].onSaveLoanForm(this, 'ddt-sample').then(function () {
          return _this._generatePDF(true);
        });
      },
      willTransition: function willTransition(transition) {
        functions['default'].onTransitionLoanForm(this, transition);
      },
      openContactAddressSelector: function openContactAddressSelector() {
        functions['default'].onOpenContactAddressSelector(this);
      },
      confirmRemoveSample: function confirmRemoveSample(sample) {
        this.onConfirmRemoveSample(this, sample);
      },
      confirmRemoveCheckedSamples: function confirmRemoveCheckedSamples() {
        this.onConfirmRemoveCheckedSamples(this);
      },
      checkAllParcelSamples: function checkAllParcelSamples() {
        this.controller.get('parcel.samples').setEach('checked', true);
      },
      removeSampleFromDDTSample: function removeSampleFromDDTSample(sample) {
        this.onRemoveSampleFromDDTSample(this, sample);
      },
      clickSelectSample: function clickSelectSample(sample) {
        this.onClickSelectSample(sample);
      },
      addMoreSamplesToCreatedDDT: function addMoreSamplesToCreatedDDT() {
        this.onAddMoreSamplesToCreatedDDT();
      },
      addSelectedSamplesToCreatedDDT: function addSelectedSamplesToCreatedDDT(ddtSamplesToArchive) {
        this.onAddSelectedSamplesToCreatedDDT(this, ddtSamplesToArchive);
      },
      confirmAddSelectedSamplesToCreatedDDT: function confirmAddSelectedSamplesToCreatedDDT() {
        this.onConfirmAddSelectedSamplesToCreatedDDT(this);
      },
      cancelMoreSamplesToCreatedDDTModal: function cancelMoreSamplesToCreatedDDTModal() {
        this.onCancelMoreSamplesToCreatedDDTModal(this);
      },
      cancelAddArchiveSamples: function cancelAddArchiveSamples() {
        this.onCancelAddArchiveSamples(this);
      }
    },

    model: function model(params) {
      var parcel;
      if (params.parcel && params.parcel !== "new") {
        //Created parcel
        parcel = this.store.findRecord('ddt-sample', params.parcel).then(function (p) {
          return p.reload();
        });
        return Ember['default'].RSVP.hash({
          collections: utils['default'].postAPIJSON('collections/for_ddt_samples', { parcel: params.parcel }),
          parcel: parcel,
          showroomInfo: utils['default'].getAPIJSON('showrooms/info')
        });
      } else {
        //New parcel
        var samplesIds = Ember['default'].samplesToCheckout.map(function (s) {
          return s.get('id');
        });
        return Ember['default'].RSVP.hash({
          collections: utils['default'].postAPIJSON('collections/for_ddt_samples', { samples: samplesIds }),
          parcel: parcel,
          showroomInfo: utils['default'].getAPIJSON('showrooms/info')
        });
      }
    },
    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      //this.send('openMessageModal', 'Loading DDT Form...');
      if (this.controller) {
        transition.send('openMessageModal', 'Loading DDT Form...');
      }
      var param = transition["params"]["ddt-samples.index"]["parcel"];
      if (!param || param === "new" && Ember['default'].isBlank(Ember['default'].samplesToCheckout)) {
        transition.abort();
      }
    },

    afterModel: function afterModel(model) {

      var data = model;
      model.collections = utils['default'].pushArrayPayload(this.store, 'collection', data.collections.collection);
    },

    setupController: function setupController(controller, model) {
      //Ember.user.reload();
      this.send('closeMessageModal');

      var appRoute = this.controllerFor('application');
      var userLogged = appRoute.get('userLogged');
      controller.set('userLogged', userLogged);
      controller.setProperties(model);
      controller.set('isLoaded', false);
      controller.set('route', this);

      this.controller.set('loanFormPage', false);
      this.controller.set('ddtFormPage', true);
      this.controller.set('ddtSamplesPage', true);

      if (!controller.get('parcel')) {
        if (Ember['default'].isBlank(Ember['default'].samplesToCheckout)) {
          this.replaceWith('collections.index');
          return;
        } else {
          controller.set('parcel', this.store.createRecord('ddt-sample', {
            date: new Date(),
            checkinDate: new Date(),
            samples: Ember['default'].samplesToCheckout || [],
            showroom: Ember['default'].showroom
          }));
          controller.get('parcel').prefill();
          Ember['default'].bulk.clearSamples();
        }
      } else {
        if (!controller.get('parcel.checkinDate')) {
          controller.set('parcel.checkinDate', controller.get('parcel.date'));
        }
      }

      var sortedList = controller.get('collections').sortBy('brand.name', 'collectionType', 'year');
      controller.set('collections', sortedList);
      controller.set('collectionsToShow', sortedList);

      //For restore collections
      controller.set('collectionsFromDdt', model.collections);
      controller.set('disableShowroomFilter', true);

      //Barcodes: Obtain for checked samples
      var samplesId = [];
      controller.get('parcel.samples').forEach(function (sample) {
        if (sample.id) {
          samplesId.push(sample.id);
        }
      });

      utils['default'].postAPIJSON('barcode/base64/samples', { samples: samplesId }).then(function (data) {
        var barcodes64 = data;
        controller.set('barcodes64', barcodes64);
      });

      //utils.getSamples().setEach('checked', false);
      //controller.get('parcel.samples').setEach('checked', true);
      controller.set('today', new Date());
      controller.set('saveVettori', false);
      controller.set('PDFURL', null);

      var reasonContent = [{ id: 'Lost' }, { id: 'Damaged' }, { id: 'Gift' }, { id: 'Return' }, { id: 'Other' }];
      controller.set('reasonContent', reasonContent);
    },

    onDownload: function onDownload() {
      var _this2 = this;

      var skipSave = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      this.send('openMessageModal', 'Generating loan form...');
      if (skipSave) {
        this._generatePDF();
      } else {
        functions['default'].onSaveLoanForm(this, 'ddt-sample').then(function () {
          return _this2._generatePDF();
        });
      }
    },

    _getAllStyles: function _getAllStyles() {
      return '<link rel="stylesheet" type="text/css" href="../template/ddt_css_purified.css">';
    },

    _generatePDF: function _generatePDF() {
      var _this3 = this;

      var skipDownload = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var id = this.controller.get('parcel.id');
      var avatar = this.controller.get('showroomInfo.avatar');
      var lA = this.controller.get('showroomInfo.legalAddress');
      var vat = this.controller.get('showroomInfo.vat');
      var addressFooter = "";
      if (!Ember['default'].isBlank(lA)) {
        addressFooter = lA;
      }
      if (!Ember['default'].isBlank(vat)) {
        addressFooter = addressFooter + " Vat Number " + vat;
      }
      var legalAddress = this.controller.get('parcel.returnAddress');
      var headerImage = this.controller.get('showroomInfo.avatar');
      var ddtNumber = this.controller.get('parcel.number');

      var headerData = Ember['default'].formatDate(this.controller.get('parcel.date'));
      var $ = Ember['default'].$;

      // Datepicker fix
      $('.inline_date_value').each(function (index, el) {
        return $(el).attr('value', $(el).val());
      });

      var container = Ember['default'].$('#pdf_container').clone();
      container.css("background-color", "white");

      // Datepicker fix
      container.find('.inline_date_value').attr('value', Ember['default'].$('.inline_date_value').val());

      var printHTML = '<html><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><title>Bookalook</title><meta name="viewport" content="width=device-width, initial-scale=1">';
      var pdfTag = $('#pdf_container').prop('outerHTML');

      printHTML += this._getAllStyles();
      printHTML += '<style>tr {page-break-inside: avoid !important;} td {page-break-inside: avoid !important; }th { page-break-inside: avoid !important;}tbody {page-break-inside: avoid !important;}thead {page-break-inside: avoid !important;} tfoot {page-break-inside: avoid !important;}</style>';
      //printHTML += '</head><body style="width: 100% !important; font-size: 12px;"><div class="row"><div class="col-xs-12 relative no-padding">';
      printHTML += '</head><body style="width: 100% !important; overflow: visible; font-size: 12px;"><div class="row" style="padding: 2em 50px;"><div class="col-xs-12 relative no-padding">';
      printHTML += pdfTag;
      printHTML += '</div></div></body></html>';

      utils['default'].postAPIJSON('ddtSamples/pdf', { html: printHTML,
        addressFooter: addressFooter,
        avatar: avatar,
        legalAddress: legalAddress,
        headerImage: headerImage,
        headerData: headerData,
        ddtNumber: ddtNumber,
        id: id }).then(function (res) {
        var link = res.ddtSample.path;
        var linkId = link.substr(link.lastIndexOf('/') + 1);
        var PDFURL = config['default'].APIURL + "pdfs/ddtsamples/" + linkId;

        //let PDFURL =config.APIHost + '/' + res.booking.path;

        if (!skipDownload) {
          _this3._openNewTab(PDFURL);
          _this3.controller.set('PDFURL', PDFURL);
        }
        _this3.send('closeMessageModal');
      });
    },

    _openNewTab: function _openNewTab(link) {
      var random = arguments.length <= 1 || arguments[1] === undefined ? "" : arguments[1];

      var fixedLink = link.substr(link.lastIndexOf('/') + 1);
      window.open(config['default'].APIURL + 'pdfs/ddtsamples/' + fixedLink, '_blank' // <- This is what makes it open in a new window.
      );
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-bookings loan-form');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-bookings loan-form');
    },

    initializeFilters: function initializeFilters() {
      var controller = this.controller;
      controller.set('brandFilter', null);
      controller.set('collectionFilter', null);

      controller.set('searchInput', '');
      controller.set('itemFilter', '');
      controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
      controller.set('colourFilter', '');
      controller.set('statusContent', [{ id: 'All statuses' }, { id: 'BOOKED' }, { id: 'OVERLAP' }, { id: 'AVAILABLE' },
      //{id: 'IN TRANSFER'},
      { id: 'UNAVAILABLE' }]);

      controller.collectionsFilteredByBrand = Ember['default'].computed('brandFilter', 'collections', function () {

        controller.set('collectionFilter', null);
        controller.set('colourFilter', '');
        controller.set('statusFilter', '');

        controller.set('sizeFilter', '');
        if (Ember['default'].isNone(controller.get('collections')) || controller.get('collections').length == 0) {
          return utils['default'].addDefaultOption([], 'All Collections', 'filterName', null);
        }
        var collections = controller.get('collections').filter(function (c) {
          if (c.get) {
            return !c.get('archived') && c.get('activeAlbums.length') && c.get('hasVisibleSamples');
          }
        });
        var parcelSamples = controller.get('parcel').get('samples');
        var parcelCollections = parcelSamples.map(function (s) {
          return s.get('collection').get('id');
        });
        var collectionsFiltered = collections.filter(function (c) {
          return parcelCollections.includes(c.get('id'));
        });

        if (controller.get('brandFilter') !== '0' && controller.get('brandFilter') !== null) {
          collectionsFiltered = collections.filter(function (collection) {
            return collection.get('brand.id') === controller.get('brandFilter') && !collection.get('archived');
          });
        }

        collectionsFiltered = collectionsFiltered.sort(function (a, b) {
          if (a.get && b.get) {
            return b.get('filterName').toLowerCase().localeCompare(a.get('filterName').toLowerCase());
          } else {
            //FIRST ITEM (All Collections)
            if (a.get) {
              return 1;
            } else {
              return -1;
            }
          }
        });

        return utils['default'].addDefaultOption(collectionsFiltered, 'All Collections', 'filterName', null);
      });
    },

    onAddMoreSamplesToCreatedDDT: function onAddMoreSamplesToCreatedDDT() {
      var _this4 = this;

      Ember['default'].$('#add-samples-to-created-ddt').modal();
      Ember['default'].openModal();

      var _t = this;
      _t.controller.set('ddtCollectionsLoading', true);

      //Get archivable samples except the ones included into the DDTParcel
      utils['default'].getAPIJSON('collections/archivable').then(function (data) {
        var collections = utils['default'].pushArrayPayload(_this4.store, 'collection', data.collection);
        var samplesToBeArchived = Ember['default'].A();
        collections.map(function (c) {
          var cSamples = c.get('samples');
          samplesToBeArchived.pushObjects(cSamples);
          cSamples.map(function (s) {
            s.set('checked', true);
            s.set('visible', true);
            s.set('toCheckout', false);
            return s;
          });
          return c;
        });
        _t.controller.set('ddtSamples', samplesToBeArchived);
        if (Ember['default'].isNone(_t.controller.get('ddtSamplesToArchive'))) {
          _t.controller.set('ddtSamplesToArchive', Ember['default'].computed.filterBy('ddtSamples', 'toCheckout', true));
        }
        _t.controller.set('collectionsToArchive', collections);

        //Filters are included into this class (not using filters.js)
        _t.initializeFilters();
        var controller = _t.controller;

        //All Albums: for pagination
        _t.controller.allAlbums = Ember['default'].computed('allCollections', 'albumPage', 'albumFilter', function () {
          var albums;
          if (controller.get('albumFilter') && controller.get('albumFilter.id')) {
            albums = [controller.get('albumFilter')];
          } else {
            controller.set('albumFilter', null);
            controller.set('lookFilter', null);
            albums = this.get('allCollections').reduce(function (a, c) {
              if (c.get) {
                a = a.concat(c.get('albums').toArray());
              }
              return a;
            }, []);
          }
          utils['default'].lazyLoad(2000, '#selectArchiveSamplesScrollTable');
          _hidingControllerSamples(controller);

          var albumsToShow = albums.slice(0, controller.get('albumPage'));

          var albumsToShowFiltered = albumsToShow.slice(0);

          var loadMore = !controller.get('allSamplesUnvisible') && controller.get('albumPage') < albums.length;
          controller.set('loadMore', loadMore);
          if (!loadMore) {
            controller.set('albumPage', albums.length);
          }

          return albumsToShowFiltered;
        });

        //Initialize search Filter function
        controller.searchFilterFunction = function () {
          var lookFilter = controller.get('lookFilter');
          var brandSearchFilter = controller.get('brandSearchFilter');
          var collectionFilter = controller.get('collectionFilter');
          var albumFilter = controller.get('albumFilter');
          var searchInput = this.get('searchInput').toLowerCase();
          var colourFilter = (this.get('colourFilter') || '').toLowerCase();
          //var showroomFilter = this.get('showroomFilter');
          var itemFilter = (this.get('itemFilter') || '').toLowerCase();
          var samples = controller.get('ddtSamples');
          var allSamplesUnvisible = true;
          var routeName = controller.route ? controller.route.routeName : '';
          samples.forEach(function (s) {
            var checked = true;

            if (brandSearchFilter && checked) {
              checked = s.get('look.album.collection.brand.id') === brandSearchFilter;
            }

            if (collectionFilter && checked) {
              checked = s.get('look.album.collection.id') === collectionFilter;
            }

            if (albumFilter && albumFilter.get && checked) {
              checked = s.get('look.album.id') === albumFilter.get('id');
            }

            if (lookFilter && checked) {
              checked = s.get('look.id') === lookFilter;
            }

            if (searchInput !== '' && checked) {
              checked = s.get('searchString').toLowerCase().indexOf(searchInput) > -1;
            }

            if (colourFilter && colourFilter !== '' && checked) {
              checked = s.get('colourSearchString').toLowerCase().indexOf(colourFilter) > -1;
            }

            if (itemFilter && itemFilter !== '' && itemFilter !== 'all samples' && checked) {
              checked = s.get('itemObject')['name'].toLowerCase() === itemFilter;
            }

            s.set('visible', checked);
            allSamplesUnvisible = allSamplesUnvisible && !checked;
          });
          controller.set('allSamplesUnvisible', allSamplesUnvisible);
        };
        controller.searchFilterFunction();

        //Observer for collectionsFilteredByBrand (no computeds!)
        controller.collectionsFilteredByBrandObserver = function () {

          controller.set('collectionFilter', null);
          controller.set('colourFilter', '');

          controller.set('sizeFilter', '');
          if (Ember['default'].isNone(controller.get('collectionsToArchive')) || controller.get('collectionsToArchive').length == 0) {
            return utils['default'].addDefaultOption([], 'All Collections', 'filterName', null);;
          }

          var collections = controller.get('collectionsToArchive');

          var collectionsFiltered = collections;

          if (controller.get('brandSearchFilter') !== '0' && controller.get('brandSearchFilter') !== null) {
            collectionsFiltered = collections.filter(function (collection) {
              return collection.get('brand.id') === controller.get('brandSearchFilter') && !collection.get('archived');
            });
          }

          collectionsFiltered = collectionsFiltered.sort(function (a, b) {
            if (a.get && b.get) {
              return b.get('filterName').toLowerCase().localeCompare(a.get('filterName').toLowerCase());
            } else {
              //FIRST ITEM (All Collections)
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          controller.set('collectionsFilteredByBrand', utils['default'].addDefaultOption(collectionsFiltered, 'All Collections', 'filterName', null));
        };
        utils['default'].addObserver(controller, ['brandSearchFilter', 'collections', 'collectionsToArchive'], 'collectionsFilteredByBrandObserver');
        controller.notifyPropertyChange('collectionsToArchive');

        //Observer for albumsFilteredByCollection (no computeds!)
        controller.albumsFilteredByCollectionObserver = function () {
          var collection = controller.get('allCollections')[0];
          controller.set('albumFilter', null);
          controller.set('lookFilter', null);
          controller.set('colourFilter', '');
          controller.set('statusFilter', '');

          if (!collection || !collection.get) {
            return [];
          }
          var albums = collection.get('albums');
          var albumsFiltered = albums;

          if (controller.get('collectionFilter') !== '0') {
            albumsFiltered = albums.filter(function (album) {
              return album.get('collection.id') === controller.get('collectionFilter') && !album.get('archived');
            });
          }

          albumsFiltered = albumsFiltered.sort(function (a, b) {
            if (a.get && b.get) {
              return a.get('albumTypeLabel').toLowerCase().localeCompare(b.get('albumTypeLabel').toLowerCase());
            } else {
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          controller.set('albumsFilteredByCollection', utils['default'].addDefaultOption(albumsFiltered, 'All Albums', 'albumTypeLabel', null));
        };
        utils['default'].addObserver(controller, ['collectionFilter'], 'albumsFilteredByCollectionObserver');

        controller.allCollections = Ember['default'].computed('collectionsFilteredByBrand', 'collectionsToArchive', 'collectionFilter', function () {
          var collectionFilter = controller.get('collectionFilter');
          var collections = controller.get('collectionsToArchive');

          var collectionsFiltered = filterByCollection(collections, collectionFilter, controller);

          return collectionsFiltered;
        });

        utils['default'].addObserver(_t.controller, ['brandSearchFilter', 'collectionFilter', 'albumFilter', 'searchInput', 'itemFilter', 'colourFilter'], 'searchFilterFunction');
        _t.controller.set('ddtCollectionsLoading', false);
        //filters.setFilterComputeds(this, '#selectArchiveSamplesScrollTable','#selectArchiveSamplesScrollGallery');

        setScrollPaginationListener(_t, '#selectArchiveSamplesScrollTable', '#selectArchiveSamplesScrollGallery');
      });
    },

    onOpenAddSamplesModal: function onOpenAddSamplesModal() {
      Ember['default'].$('#add-samples-modal').modal();
    },

    onConfirmRemoveSample: function onConfirmRemoveSample(route, sample) {
      route.controller.set('samplesToRemove', [sample.id]);

      Ember['default'].$('#delete-sample-from-ddt').modal();
      Ember['default'].openModal();
    },

    onConfirmRemoveCheckedSamples: function onConfirmRemoveCheckedSamples(route, sample) {
      var parcel = route.controller.get('parcel');
      var arrayCheckedSamples = [];
      parcel.get('samples').forEach(function (s) {
        if (s.checked) {
          arrayCheckedSamples.push(s.id);
        }
      });

      route.controller.set('samplesToRemove', arrayCheckedSamples);

      Ember['default'].$('#delete-sample-from-ddt').modal();
      Ember['default'].openModal();
    },

    onRemoveSampleFromDDTSample: function onRemoveSampleFromDDTSample(route, samplesToRemove) {
      utils['default'].postAPIJSON('ddtSamples/' + route.controller.get('parcel.id') + '/remove_samples', { 'samples': samplesToRemove }).then(function (response) {

        //Update shown samples
        var parcel = route.controller.get('parcel');
        parcel.reload().then(function (p) {
          route.controller.set('parcel', p);

          var samplesIds = p.get('samples').map(function (s) {
            return s.get('id');
          });
          if (samplesIds.length > 0) {
            utils['default'].postAPIJSON('collections/for_ddt_samples', { samples: samplesIds }).then(function (response) {
              var model = route.get('model');
              model.collections = utils['default'].pushArrayPayload(route.store, 'collection', response.collection);
              route.controller.set('collectionsToShow', model.collections);

              route.send('openPositiveTopBar');

              utils['default'].openDefaultConfirmationModal(route, '<strong>Reactivate</strong> Sample(s)', 'Do you want to reactivate selected sample(s)?', '', 'Yes', 'No').then(function () {
                utils['default'].postAPIJSON('albums/reactivateSelectedSamples', { selectedSamples: samplesToRemove }).then(function (response) {
                  route.send('openPositiveTopBar');
                });
              });
            });
          } else {
            //Empty DDT!
            route.controller.set('collections', null);

            route.send('openPositiveTopBar');

            utils['default'].openDefaultConfirmationModal(route, '<strong>Reactivate</strong> Sample(s)', 'Do you want to reactivate selected sample(s)?', '', 'Yes', 'No').then(function () {
              utils['default'].postAPIJSON('albums/reactivateSelectedSamples', { selectedSamples: samplesToRemove }).then(function (response) {
                route.send('openPositiveTopBar');
              });
            });
          }
        });
      });
    },

    onAddSelectedSamplesToCreatedDDT: function onAddSelectedSamplesToCreatedDDT(route, samplesToAdd) {
      route.controller.set('samplesToAdd', samplesToAdd);
      route.controller.set('isLoading', true);

      Ember['default'].$('#archive-confirmation-for-added-samples').modal();
      Ember['default'].openModal();
    },

    onCancelAddArchiveSamples: function onCancelAddArchiveSamples(route) {
      removeListenersAndObservers(route);

      route.controller.set('samplesToAdd', null);
      route.controller.set('isLoading', true);
      Ember['default'].$('#archive-confirmation-for-added-samples').modal('hide');
      this.set('reasonSelected', '');

      var p = route.controller.get('parcel');

      var samplesIds = p.get('samples').map(function (s) {
        return s.get('id');
      });
      if (samplesIds.length > 0) {
        utils['default'].postAPIJSON('collections/for_ddt_samples', { samples: samplesIds }).then(function (response) {
          var model = route.get('model');
          model.collections = utils['default'].pushArrayPayload(route.store, 'collection', response.collection);
          //route.controller.set('collections', collectionsFromDdt);
          route.controller.set('collectionsToShow', model.collections);
          route.controller.get('parcel.samples').setEach('checked', false);
          route.controller.set('isLoading', false);
        });
      }
    },

    onConfirmAddSelectedSamplesToCreatedDDT: function onConfirmAddSelectedSamplesToCreatedDDT(route) {
      removeListenersAndObservers(route);

      var btn = Ember['default'].$('#archive-modal-btn-archive');
      btn.button('loading');

      var samplesToAdd = route.controller.get('samplesToAdd');
      var samplesIdSelectedToAdd = samplesToAdd.map(function (s) {
        return s.get('id');
      });

      utils['default'].postAPIJSON('ddtSamples/' + route.controller.get('parcel.id') + '/add_samples', { 'samples': samplesIdSelectedToAdd }).then(function (response) {

        //Update shown samples
        var parcel = route.controller.get('parcel');
        parcel.reload().then(function (p) {
          route.controller.set('parcel', p);

          var samplesIds = p.get('samples').map(function (s) {
            return s.get('id');
          });
          if (samplesIds.length > 0) {
            utils['default'].postAPIJSON('collections/for_ddt_samples', { samples: samplesIds }).then(function (response) {
              var model = route.get('model');

              var sortedList = response.collection.sortBy('brand.name', 'collectionType', 'year');

              model.collections = utils['default'].pushArrayPayload(route.store, 'collection', sortedList);

              route.controller.set('collectionsToShow', model.collections);
              route.controller.notifyPropertyChange('collections');

              utils['default'].postAPIJSON('barcode/base64/samples', { samples: samplesIds }).then(function (data) {
                //Refresh barcode render!
                var barcodes64 = data;
                route.controller.set('barcodes64', barcodes64);

                //Reset checked samples
                parcel.get('samples').forEach(function (s) {
                  s.set('checked', false);
                });

                btn.button('reset');
                Ember['default'].$('#archive-confirmation-for-added-samples').modal('hide');
                route.controller.set('isLoading', false);
                //route.send('openPositiveTopBar');
              });
            });
          } else {
              //Empty DDT!
              route.controller.set('collections', null);
              route.send('openPositiveTopBar');
            }
        });
      });
    },

    onClickSelectSample: function onClickSelectSample(sample) {
      var currentStatus = sample.get('checked');
      sample.set('checked', !currentStatus);
    },

    //Functions for add more samples to created DDT
    initializeFilters: function initializeFilters() {
      var controller = this.controller;

      controllerGlobal = controller;

      controller.set('albumPage', 1);
      controller.set('brandSearchFilter', null);
      controller.set('collectionFilter', null);
      controller.set('searchInput', '');
      controller.set('itemFilter', '');
      controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
      controller.set('colourFilter', '');

      controller.resetAlbumPageObserver = function () {
        controller.set('albumPage', 1);
      };

      utils['default'].addObserver(controller, ['brandFilter', 'collectionFilter', 'albumFilter', 'searchInput', 'colourFilter', 'sizeFilter', 'itemFilter'], 'resetAlbumPageObserver');

      //controller.set('allSearchBrands', function () {
      if (!controller.get('collectionsToArchive')) {
        return [];
      } else {
        var brands = controller.get('collectionsToArchive').reduce(function (brandList, nextCollection) {
          var content = nextCollection.get('brand.content');
          if (content) {
            brandList.addObject(content);
          }
          return brandList;
        }, Ember['default'].A([{ id: null, name: 'All brands' }]));
        controller.set('allSearchBrands', brands);
      }
    },

    onCancelMoreSamplesToCreatedDDTModal: function onCancelMoreSamplesToCreatedDDTModal(route) {
      removeListenersAndObservers(route);

      route.controller.set('isLoading', true);
      route.controller.set('samplesToAdd', null);
      var p = route.controller.get('parcel');

      var samplesIds = p.get('samples').map(function (s) {
        return s.get('id');
      });
      if (samplesIds.length > 0) {
        utils['default'].postAPIJSON('collections/for_ddt_samples', { samples: samplesIds }).then(function (response) {
          var model = route.get('model');
          model.collections = utils['default'].pushArrayPayload(route.store, 'collection', response.collection);
          route.controller.set('collectionsToShow', model.collections);
          //route.controller.set('collections', collectionsFromDdt);
          route.controller.get('parcel.samples').setEach('checked', false);
          route.controller.set('isLoading', false);
        });
      }
    }

  });

  function filterByCollection(collectionList, collectionFilter) {
    var collectionsFiltered = collectionList;

    if (collectionFilter !== null && collectionFilter !== '') {
      collectionsFiltered = collectionList.filter(function (collection) {
        return collection.id === collectionFilter && collection.get('archived') === false;
      });
    }
    return collectionsFiltered;
  }

  //Remove scroll listener

  function removeScrollListener(route) {
    var intervals = route.get('poll');
    intervals.clearAll();
  }

  //Scroll calculation functions

  function _scrollCalculation() {

    var container = '#selectArchiveSamplesScrollTable';
    var containerDocument = '#selectArchiveSamplesScrollGallery';

    if ($(container).scrollTop() + $(container).height() > $(containerDocument).height() - 300) {
      if (!controllerGlobal.get('bookingDatesBlock') && !controllerGlobal.get('allSamplesUnvisible') && controllerGlobal.get('loadMore') && !controllerGlobal.get('isLoading')) {
        controllerGlobal.incrementProperty('albumPage');
      }
    }
  }

  //Set a proper value for scroll listener (pagination purposes)

  function setScrollPaginationListener(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;
    var intervals = route.get('poll');
    intervals.clearAll();
    intervals.addPoll({
      interval: 50, // 0.05s
      callback: _scrollCalculation
    });
  }

  //Hide samples

  function _hidingControllerSamples(controller) {
    if (controller.get('samples')) {
      controller.get('samples').forEach(function (sample) {
        return sample.set('visible', false);
      });
    }
  }

  function removeListenersAndObservers(route) {
    route.get('poll').clearAll();
    var _t = route;
    var controller = _t.controller;
    controller.removeObserver('collectionFilter', controller, 'albumsFilteredByCollectionObserver');
    controller.removeObserver('brandSearchFilter', controller, 'collectionsFilteredByBrandObserver');
    controller.removeObserver('collections', controller, 'collectionsFilteredByBrandObserver');
    controller.removeObserver('collectionsToArchive', controller, 'collectionsFilteredByBrandObserver');
    controller.removeObserver('brandFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('collectionFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('albumFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('searchInput', controller, 'resetAlbumPageObserver');
    controller.removeObserver('colourFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('sizeFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('itemFilter', controller, 'resetAlbumPageObserver');
  }

});