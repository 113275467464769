define('bookalook/templates/components/looks-gallery', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 7,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","gallery left arrow");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","lazyload");
          dom.setAttribute(el2,"src","images/arrow-left.png");
          dom.setAttribute(el2,"data-src","images/arrow-left.png");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","gallery right arrow");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","lazyload");
          dom.setAttribute(el2,"src","images/arrow-right.png");
          dom.setAttribute(el2,"data-src","images/arrow-right.png");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["element","action",["changeLookSlide",["get","look.id",["loc",[null,[5,41],[5,48]]]],"left"],[],["loc",[null,[5,14],[5,57]]]],
          ["element","action",["changeLookSlide",["get","look.id",["loc",[null,[6,41],[6,48]]]],"right"],[],["loc",[null,[6,14],[6,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 10,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","gallery gallery-cover lazyload");
          dom.setAttribute(el1,"src","images/default-image.png");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'id');
          morphs[1] = dom.createAttrMorph(element1, 'name');
          morphs[2] = dom.createAttrMorph(element1, 'data-src');
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",[["get","look.id",["loc",[null,[9,58],[9,65]]]]]]],
          ["attribute","name",["concat",[["subexpr","get-array-object-parameter",[["get","look.avatars",["loc",[null,[9,104],[9,116]]]],0,"position"],[],["loc",[null,[9,75],[9,131]]]]]]],
          ["attribute","data-src",["concat",[["subexpr","get-array-object-parameter",[["get","look.avatars",["loc",[null,[9,203],[9,215]]]],0,"thumbnail"],[],["loc",[null,[9,174],[9,231]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","gallery gallery-cover lazyload");
          dom.setAttribute(el1,"id","0");
          dom.setAttribute(el1,"name","default-image");
          dom.setAttribute(el1,"src","images/default-image.png");
          dom.setAttribute(el1,"data-src","images/default-image.png");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 10
            },
            "end": {
              "line": 18,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","content text-left");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","look.publicDescription",["loc",[null,[17,41],[17,67]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 10
            },
            "end": {
              "line": 32,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","image_zoom_wrapper");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/eye-icon.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","light-box",[],["href",["subexpr","@mut",[["get","look.avatars.0.avatar",["loc",[null,[23,23],[23,44]]]]],[],[]],"lazyload-src","images/default-image.png","lazyloadActive",true,"data-lightbox","look-group","data-title",["subexpr","@mut",[["get","look.publicDescription",["loc",[null,[27,33],[27,55]]]]],[],[]],"data-lightbox","name","data-class","image_zoom lazyload"],["loc",[null,[22,14],[30,16]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 10
            },
            "end": {
              "line": 43,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","image_zoom_wrapper");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/eye-icon.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","light-box",[],["href","images/default-image.png","data-lightbox","look-group","data-title",["subexpr","@mut",[["get","look.publicDescription",["loc",[null,[38,33],[38,55]]]]],[],[]],"data-lightbox","name","data-class","image_zoom"],["loc",[null,[35,14],[41,16]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 8
              },
              "end": {
                "line": 52,
                "column": 8
              }
            },
            "moduleName": "bookalook/templates/components/looks-gallery.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","gallery gallery-slide lazyload");
            dom.setAttribute(el1,"src","images/default-image.png");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'id');
            morphs[1] = dom.createAttrMorph(element0, 'data-src');
            morphs[2] = dom.createAttrMorph(element0, 'data-full-src');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",[["get","avatar.position",["loc",[null,[51,60],[51,75]]]]]]],
            ["attribute","data-src",["concat",[["get","avatar.thumbnail",["loc",[null,[51,122],[51,138]]]]]]],
            ["attribute","data-full-src",["concat",[["get","avatar.avatar",["loc",[null,[51,159],[51,172]]]]]]]
          ],
          locals: ["avatar"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 6
            },
            "end": {
              "line": 53,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","look.avatars",["loc",[null,[50,26],[50,38]]]]],[],0,null,["loc",[null,[50,8],[52,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 6
            },
            "end": {
              "line": 55,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","gallery gallery-slide lazyload");
          dom.setAttribute(el1,"id","");
          dom.setAttribute(el1,"src","images/default-image.png");
          dom.setAttribute(el1,"data-src","images/default-image.png");
          dom.setAttribute(el1,"data-full-src","images/default-image.png");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 6
          }
        },
        "moduleName": "bookalook/templates/components/looks-gallery.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","image-column");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","gallery gallery-container look-image no-padding");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","look-index");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","image-description");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","description");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("script");
        var el4 = dom.createTextNode("\n      var images = document.querySelectorAll(\"#gallery-look-");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" .lazyload\");\n      lazyload(images);\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [6, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element6, 'id');
        morphs[1] = dom.createMorphAt(element6,1,1);
        morphs[2] = dom.createMorphAt(element6,2,2);
        morphs[3] = dom.createMorphAt(dom.childAt(element6, [4]),0,0);
        morphs[4] = dom.createMorphAt(element7,1,1);
        morphs[5] = dom.createMorphAt(element7,2,2);
        morphs[6] = dom.createMorphAt(element6,8,8);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
        morphs[8] = dom.createMorphAt(element4,3,3);
        return morphs;
      },
      statements: [
        ["attribute","id",["concat",["gallery-look-",["get","look.id",["loc",[null,[3,84],[3,91]]]]]]],
        ["block","if",[["get","look.avatars.1.avatar",["loc",[null,[4,12],[4,33]]]]],[],0,null,["loc",[null,[4,6],[7,13]]]],
        ["block","if",[["get","look.avatars",["loc",[null,[8,12],[8,24]]]]],[],1,2,["loc",[null,[8,6],[12,13]]]],
        ["content","look.position",["loc",[null,[13,30],[13,47]]]],
        ["block","if",[["get","look.publicDescription",["loc",[null,[16,16],[16,38]]]]],[],3,null,["loc",[null,[16,10],[18,17]]]],
        ["block","if",[["get","look.avatars",["loc",[null,[19,16],[19,28]]]]],[],4,5,["loc",[null,[19,10],[43,17]]]],
        ["block","if",[["get","look.avatars",["loc",[null,[49,12],[49,24]]]]],[],6,7,["loc",[null,[49,6],[55,14]]]],
        ["content","look.id",["loc",[null,[58,60],[58,71]]]],
        ["inline","partial",["global/inventory-gallery-footer"],[],["loc",[null,[62,2],[62,47]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});