define('bookalook/helpers/get-collection-label', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //USE ONLY IN CASE OF OBJECT
    //IF MODEL: BETTER USE THE MODEL FUNCTION (get('filterName'))
    if (!params[0]) {
      return;
    }
    var object = params[0];

    var year = object.year;
    var season = object.season;
    var collectionType = object.collectionType;

    return collectionType + " " + season + " " + year;
    //return year + " - " + season + " - " + collectionType;
  });

});