define('bookalook/components/simple-pagination', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    /** Atributos a pasarle:
     *  items => array que quieres paginar
     *  itemsByPage => numero de elementos de cada pagina (10 si no se lo pasas)
     */
    activePage: 1,
    itemsByPage: 10,

    actions: {
      goToFirstPage: function goToFirstPage() {
        if (this.get('previousActive')) this.set('activePage', 1);
      },
      goToLastPage: function goToLastPage() {
        if (this.get('nextActive')) this.set('activePage', this.get('totalPages'));
      },
      goToPreviousPage: function goToPreviousPage() {
        if (this.get('previousActive')) this.set('activePage', this.get('activePage') - 1);
      },
      goToNextPage: function goToNextPage() {
        if (this.get('nextActive')) this.set('activePage', this.get('activePage') + 1);
      },
      changePage: function changePage(page) {
        this.set('activePage', page);
      }
    },

    //Numero de paginas
    totalPages: Ember['default'].computed('items', function () {
      var items = this.get('items');
      var itemsByPage = parseInt(this.get('itemsByPage'));
      var length = items.length;
      if (length !== parseInt(length, 10)) {
        length = items.get('length');
      }
      var pages = Math.floor(length / itemsByPage);
      //Para sumar pagina con menos de (itemsByPage) elementos
      if (length % itemsByPage > 0) pages += 1;
      this.set('activePage', 1);
      return pages;
    }),

    //Debido a que aunque vaya de 0 a X, al en hbs empieza por 1
    arrayPages: Ember['default'].computed('totalPages', function () {
      var pages = this.get('totalPages');
      var result = [];
      for (var i = 0; i < pages; i++) {
        result[i] = i + 1;
      }
      return result;
    }),

    //Items paginados
    itemsPaginated: Ember['default'].computed('items', 'activePage', function () {
      var page = parseInt(this.get('activePage'));
      var itemsByPage = parseInt(this.get('itemsByPage'));
      var startingItem = (page - 1) * itemsByPage;
      var lastItem = startingItem + itemsByPage;
      var result = this.get('items').slice(startingItem, lastItem);
      return result;
    }),

    nextActive: Ember['default'].computed('activePage', function () {
      return this.get('activePage') < this.get('totalPages');
    }),
    previousActive: Ember['default'].computed('activePage', function () {
      return !(this.get('activePage') === 1);
    })
  });

});