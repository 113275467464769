define('bookalook/tests/routes/settings/functions.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/settings/functions.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/settings/functions.js should pass jshint.\nroutes/settings/functions.js: line 94, col 18, [\'starter\'] is better written in dot notation.\nroutes/settings/functions.js: line 95, col 18, [\'designer\'] is better written in dot notation.\nroutes/settings/functions.js: line 96, col 18, [\'pro\'] is better written in dot notation.\nroutes/settings/functions.js: line 97, col 18, [\'corporate\'] is better written in dot notation.\nroutes/settings/functions.js: line 111, col 26, [\'starter\'] is better written in dot notation.\nroutes/settings/functions.js: line 112, col 27, [\'designer\'] is better written in dot notation.\nroutes/settings/functions.js: line 113, col 22, [\'pro\'] is better written in dot notation.\nroutes/settings/functions.js: line 114, col 28, [\'corporate\'] is better written in dot notation.\nroutes/settings/functions.js: line 146, col 26, Missing semicolon.\nroutes/settings/functions.js: line 702, col 17, \'card\' was used before it was declared, which is illegal for \'let\' variables.\nroutes/settings/functions.js: line 983, col 18, [\'date\'] is better written in dot notation.\nroutes/settings/functions.js: line 994, col 18, [\'total\'] is better written in dot notation.\n\n12 errors');
  });

});