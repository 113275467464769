define('bookalook/tests/routes/search/looks.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/search/looks.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/search/looks.js should pass jshint.\nroutes/search/looks.js: line 293, col 45, [\'children\'] is better written in dot notation.\nroutes/search/looks.js: line 293, col 65, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (filter)\nroutes/search/looks.js: line 298, col 74, Missing semicolon.\nroutes/search/looks.js: line 493, col 45, [\'children\'] is better written in dot notation.\nroutes/search/looks.js: line 493, col 65, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (value)\nroutes/search/looks.js: line 603, col 34, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (type, newTypeFilters, Ember)\nroutes/search/looks.js: line 626, col 32, [\'children\'] is better written in dot notation.\nroutes/search/looks.js: line 626, col 60, [\'children\'] is better written in dot notation.\nroutes/search/looks.js: line 626, col 83, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (currentFilters, type)\nroutes/search/looks.js: line 632, col 41, [\'children\'] is better written in dot notation.\nroutes/search/looks.js: line 631, col 58, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (Ember)\nroutes/search/looks.js: line 636, col 58, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (currentFilters, type)\nroutes/search/looks.js: line 642, col 65, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (catValues)\nroutes/search/looks.js: line 646, col 58, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (currentFilters, type)\n\n14 errors');
  });

});