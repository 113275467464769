define('bookalook/tests/routes/profile/user-profile.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/profile/user-profile.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/profile/user-profile.js should pass jshint.\nroutes/profile/user-profile.js: line 36, col 58, Missing semicolon.\n\n1 error');
  });

});