define('bookalook/models/user', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment'], function (exports, Ember, DS, Validator, config) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    surname: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    // email2: DS.attr('string'),
    jobTitle: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    avatar: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_USER_IMAGE }),
    plainPassword: DS['default'].attr('string'),
    statusRegister: DS['default'].attr('number', { defaultValue: 1 }),
    statusOnBoarding: DS['default'].attr('number', { defaultValue: null }),
    firstLogin: DS['default'].attr('boolean', { defaultValue: true }),
    emailNotifications: DS['default'].attr('boolean', { defaultValue: false }),

    website: DS['default'].attr('string'),
    twitter: DS['default'].attr('string'),
    facebook: DS['default'].attr('string'),
    instagram: DS['default'].attr('string'),
    pinterest: DS['default'].attr('string'),

    type: DS['default'].attr('string'),

    company: DS['default'].attr('string'),
    mpcode: DS['default'].attr('string'),
    mpcompany: DS['default'].belongsTo('mp-company', { async: true }),

    roles: DS['default'].attr('array'),
    restOfTheWorld: DS['default'].attr('array', { defaultValue: [] }),
    freelance: DS['default'].attr('boolean'),
    influencer: DS['default'].attr('boolean'),
    searchable: DS['default'].attr('boolean'),

    areasInfluence: DS['default'].hasMany('areasInfluence', { async: true, inverse: null }),

    invitedBy: DS['default'].belongsTo('user', { async: true }),
    showroom: DS['default'].belongsTo('showroom', { async: true, inverse: 'users' }),
    addresses: DS['default'].hasMany('contact-address', { async: true }),

    registerPlan: DS['default'].attr('string'),
    stripeCustomerId: DS['default'].attr('string'),
    maqpieId: DS['default'].attr('string'),
    //plan     : DS.belongsTo('plan', { async: true, inverse: null }),
    date: DS['default'].attr('day'),
    firstPayment: DS['default'].attr('boolean', { defaultValue: false }),

    rolesContent: [{ id: 1, label: "Manager", value: ['ROLE_MANAGER'] },
    //{id: 2, label: "Admin", value: ['ROLE_ADMIN']},
    { id: 3, label: "Member", value: ['ROLE_MEMBER'] }],
    vatvalue: DS['default'].attr('string'),

    timezone: DS['default'].attr('number'),

    verified: (function () {
      return this.get('statusRegister') === 4;
    }).property('statusRegister'),

    hasExpiredSubscription: (function () {
      return this.get('statusRegister') == 6 ? true : false;
    }).property('statusRegister'),

    isInvited: (function () {
      return this._internalModel._relationships.get("invitedBy").members.size !== 0;
    }).property('invitedBy'),

    companyName: Ember['default'].computed('showroom.companyName', function () {
      return this.get('showroom.companyName');
    }),

    companyTypeAndId: Ember['default'].computed('showroom.companyTypeAndId', function () {
      return this.get('showroom.companyTypeAndId');
    }),

    mainAddress: (function () {
      return this.get('addresses').findBy('main', true);
    }).property('addresses.@each.main'),

    secondaryAddresses: (function () {
      return this.get('addresses').filterBy('main', false);
    }).property('addresses.@each.main'),

    allAddresses: (function () {
      var mainAddress = this.get('mainAddress');
      var secondaryAddresses = this.get('secondaryAddresses');
      var result = Ember['default'].A();
      if (!Ember['default'].isEmpty(mainAddress)) {
        result.addObject(mainAddress);
      }
      if (!Ember['default'].isEmpty(secondaryAddresses)) {
        result.addObjects(secondaryAddresses);
      }
      return result;
    }).property('addresses.@each.main'),

    isCurrentUser: (function () {
      return this.get('id') === localStorage.getItem('userId');
    }).property('id'),

    isPR: (function () {
      return this.get('type') === 'PR' || !this.get('type');
    }).property('type'),

    isMP: (function () {
      return this.get('type') === 'MP';
    }).property('type'),

    fullName: (function () {
      return this.get('name') + ' ' + this.get('surname');
    }).property('name', 'surname'),

    isSuperAdmin: (function () {
      if (!this.get('roles')) {
        return false;
      }
      return this.get('roles')[0] === 'ROLE_SUPER_ADMIN';
    }).property('roles'),

    isStripeManager: (function () {
      if (!this.get('roles')) {
        return false;
      }
      return this.get('roles')[0] === 'ROLE_MANAGER' && this.get('stripeCustomerId') != '';
    }).property('roles'),

    isManager: (function () {
      if (!this.get('roles')) {
        return false;
      }
      return this.get('isSuperAdmin') || this.get('roles')[0] === 'ROLE_MANAGER';
    }).property('roles'),

    isAdmin: (function () {
      if (!this.get('roles')) {
        return false;
      }
      return this.get('isManager') || this.get('roles')[0] === 'ROLE_ADMIN';
    }).property('roles'),

    isMember: (function () {
      if (!this.get('roles')) {
        return false;
      }
      return this.get('roles')[0] === 'ROLE_MEMBER';
    }).property('roles'),

    roleLabel: (function () {
      if (this.get('roles')) {
        return this.get('roles')[0].split('ROLE_')[1];
      }
    }).property('roles'),

    isRestOfTheWorld: function isRestOfTheWorld(brand) {
      var is = brand && this.get('restOfTheWorld').some(function (id) {
        return id === parseInt(brand.get('id'), 10);
      });
      return is;
    },

    getBrandArea: function getBrandArea(brand) {
      var userAreasInfluence = this.get('areasInfluence');
      var userAreasInfluenceByBrand = userAreasInfluence.filter(function (area) {
        return area.get('brand.id') === brand.get('id');
      });
      return userAreasInfluenceByBrand[0];
    },

    hasAreasOfInfluence: (function () {
      var _this = this;

      var userAreasInfluence = this.get('areasInfluence');
      var exceptId = this.get('exceptId');
      var hasAnyCountry = userAreasInfluence.some(function (area) {
        if (!exceptId || exceptId !== area.get('id')) {
          return area.get('countries.length') || _this.isRestOfTheWorld(area.get('brand'));
        }
      });
      this.set('exceptId', null);
      return hasAnyCountry;
    }).property('areasInfluence.@each.countriesCollapse', 'exceptId', 'restOfTheWorld.@each'),

    onlyValidAreasOfInfluence: (function () {
      var _this2 = this;

      var userAreasInfluence = this.get('areasInfluence');
      var userAreasInfluenceValid = userAreasInfluence.filter(function (area) {
        return area.get('countries.length') || _this2.isRestOfTheWorld(area.get('brand'));
      });
      return userAreasInfluenceValid;
    }).property('areasInfluence.@each.countriesCollapse', 'restOfTheWorld.@each'),

    firstSixValidAreasOfInfluence: (function () {
      var userAreasInfluence = this.get('onlyValidAreasOfInfluence');
      return userAreasInfluence.slice(0, 6);
    }).property('areasInfluence.@each.countriesCollapse', 'restOfTheWorld.@each'),

    notFirstSixValidAreasOfInfluence: (function () {
      var userAreasInfluence = this.get('onlyValidAreasOfInfluence');
      return userAreasInfluence.slice(6, userAreasInfluence.get('length') - 1);
    }).property('areasInfluence.@each.countriesCollapse', 'restOfTheWorld.@each'),

    userSafeString: (function () {
      var company = this.get('company');
      if (company == null) {
        company = 'Freelance';
      }

      return new Ember['default'].Handlebars.SafeString(this.get('name') + ' ' + this.get('surname') + ' - ' + company);
    }).property('name', 'surname', 'company'),

    isCorporate: (function () {
      var plan = this.get('registerPlan');
      if (plan != null) {
        plan = plan.toLowerCase();
        return plan.indexOf('corporate') !== -1;
      }
      return false;
    }).property('registerPlan'),

    isStarter: (function () {
      var plan = this.get('registerPlan');
      if (plan != null) {
        plan = plan.toLowerCase();
        return plan.indexOf('starter') !== -1;
      }
      return false;
    }).property('registerPlan'),

    isDesigner: (function () {
      var plan = this.get('registerPlan');
      if (plan != null) {
        plan = plan.toLowerCase();
        return plan.indexOf('designer') !== -1;
      }
      return false;
    }).property('registerPlan'),

    isPro: (function () {
      var plan = this.get('registerPlan');
      if (plan != null) {
        plan = plan.toLowerCase();
        return plan.indexOf('pro') !== -1;
      }
      return false;
    }).property('registerPlan'),

    isBrand: (function () {
      var sh = this.get('showroom');
      if (sh != null && sh.get('isAgency')) {
        return false;
      } else {
        return true;
      }
    }).property('showroom'),

    isFree: (function () {
      var plan = this.get('registerPlan');
      if (plan != null) {
        plan = plan.toLowerCase();
        return plan.indexOf('free') !== -1;
      }
      return false;
    }).property('registerPlan'),

    nameInitials: (function () {
      var fullname = this.get('fullName');
      var result = "";
      var array = fullname.split(" ");
      result = array[0].charAt(0);
      if (array[1]) {
        result = result.concat(array[1].charAt(0));
      }
      return result.toUpperCase();
    }).property('fullName'),

    isAvatarDefault: (function () {
      var avatar = this.get('avatar');
      if (Ember['default'].isNone(avatar)) return true;
      if (avatar.indexOf('default') !== -1) {
        return true;
      } else {
        return false;
      }
    }).property('avatar'),

    isFreelanceOrInfluencer: (function () {
      var is = this.get('freelance') || this.get('influencer');
      return is;
    }).property('freelance', 'influencer'),

    isOnboarding: (function () {
      var status = this.get('statusOnBoarding');
      if (Ember['default'].isNone(status) || status === 0 || status === 10) {
        return false;
      } else {
        return true;
      }
    }).property('statusOnBoarding'),

    companyWebsite: (function () {
      if (this.get('isPR')) {
        var sh = this.get('showroom');
        if (sh != null) {
          var company = sh.get('company');
          if (company != null) {
            return company.get('website');
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        var company = this.get('mpcompany');
        if (!Ember['default'].isNone(company)) {
          return company.get('website');
        } else {
          return false;
        }
      }
    }).property('showroom'),

    validations: {
      'name': {
        presence: true
      },

      'surname': {
        presence: true
      },

      'jobTitle': {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('freelance') || model.get('influencer')) {
              return true;
            } else {
              return !Ember['default'].isBlank(value);
            }
          },
          message: 'You must set a Job Title unless you are freelance or influencer.'
        }]
      },

      'email': {
        presence: true,
        length: { minimum: 3 }
      },

      'plainPassword': {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('isNew')) {
              if (typeof value !== 'undefined') {
                return value.length >= 6;
              } else {
                return false;
              }
            }
            return true;
          },
          message: 'The password must have more than 5 characters'
        }]
      },

      'avatar': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('isAvatarDefault');
          },
          message: 'You must set an avatar.'
        }]
      }
    }
  });

});