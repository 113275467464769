define('bookalook/templates/contacts-lists/partials/contact-row-brand-refactor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","circle_avatar");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","letters");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["content","contact.nameInitials",["loc",[null,[5,30],[5,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","circle_avatar no-border");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element16, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","contact.avatar",["loc",[null,[9,20],[9,34]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","active");
          dom.setAttribute(el2,"src","images/website-contact.svg");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [1]);
          var element15 = dom.childAt(element14, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element14, 'href');
          morphs[1] = dom.createAttrMorph(element15, 'title');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",[["subexpr","fix-url-protocol",[["get","contact.website",["loc",[null,[19,50],[19,65]]]]],[],["loc",[null,[19,31],[19,67]]]]]]],
          ["attribute","title",["concat",[["get","contact.website",["loc",[null,[19,83],[19,98]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 4
            },
            "end": {
              "line": 23,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","active");
          dom.setAttribute(el2,"src","images/facebook-contact.svg");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var element13 = dom.childAt(element12, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element12, 'href');
          morphs[1] = dom.createAttrMorph(element13, 'title');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://facebook.com/",["get","contact.facebook",["loc",[null,[22,54],[22,70]]]]]]],
          ["attribute","title",["concat",[["get","contact.facebook",["loc",[null,[22,88],[22,104]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","active");
          dom.setAttribute(el2,"src","images/instagram-contact.svg");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var element11 = dom.childAt(element10, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element10, 'href');
          morphs[1] = dom.createAttrMorph(element11, 'title');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://instagram.com/",["get","contact.instagram",["loc",[null,[25,55],[25,72]]]]]]],
          ["attribute","title",["concat",[["get","contact.instagram",["loc",[null,[25,90],[25,107]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"checked","");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"class","bal-checkbox-input");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          if (this.cachedFragment) { dom.repairClonedNode(element9,[],true); }
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element9, 'id');
          morphs[1] = dom.createElementMorph(element9);
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",["contact_cb_",["get","contact.id",["loc",[null,[30,73],[30,83]]]]]]],
          ["element","action",["selectContact",["get","contact",["loc",[null,[30,46],[30,53]]]]],[],["loc",[null,[30,21],[30,55]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 4
            },
            "end": {
              "line": 33,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"class","bal-checkbox-input");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element8, 'id');
          morphs[1] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",["contact_cb_",["get","contact.id",["loc",[null,[32,65],[32,75]]]]]]],
          ["element","action",["selectContact",["get","contact",["loc",[null,[32,38],[32,45]]]]],[],["loc",[null,[32,13],[32,47]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 12
              },
              "end": {
                "line": 52,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Send Message");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [
            ["element","action",["chatWithContact",["get","contact.email",["loc",[null,[49,47],[49,60]]]]],[],["loc",[null,[49,19],[49,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 12
              },
              "end": {
                "line": 59,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Edit contact");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Invite to Bookalook");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["element","action",["openEditManualContactModal",["get","contact",["loc",[null,[53,57],[53,64]]]]],[],["loc",[null,[53,19],[53,66]]]],
            ["element","action",["openInviteContactModal",["get","contact",["loc",[null,[56,53],[56,60]]]]],[],["loc",[null,[56,19],[56,62]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 4
            },
            "end": {
              "line": 70,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","dd-contact-list has-pophover-ui-refactor flat-dropdown left hover-disabled");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","dots_vertical_btn");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","icon");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","pophover-wrapper-ui-refactor");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pophover");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","flat-dropdown-list");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("View profile");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Duplicate");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Delete from list");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3, 1, 1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [5]);
          var element7 = dom.childAt(element4, [7]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element3, 'id');
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createElementMorph(element5);
          morphs[3] = dom.createMorphAt(element4,3,3);
          morphs[4] = dom.createElementMorph(element6);
          morphs[5] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",["dd-contact-",["get","contact.id",["loc",[null,[38,26],[38,36]]]]]]],
          ["element","action",["toggleActiveClass",["subexpr","concat",["#dd-contact-",["get","contact.id",["loc",[null,[38,175],[38,185]]]]],[],["loc",[null,[38,152],[38,186]]]]],[],["loc",[null,[38,123],[38,188]]]],
          ["element","action",["openViewContactProfileModal",["get","contact",["loc",[null,[45,56],[45,63]]]]],[],["loc",[null,[45,17],[45,65]]]],
          ["block","if",[["get","contact.hasUser",["loc",[null,[48,18],[48,33]]]]],[],0,1,["loc",[null,[48,12],[59,19]]]],
          ["element","action",["addContactToDuplicateListCL",["get","contact",["loc",[null,[60,56],[60,63]]]]],[],["loc",[null,[60,17],[60,65]]]],
          ["element","action",["addContactToDeleteList",["get","contact",["loc",[null,[63,51],[63,58]]]]],[],["loc",[null,[63,17],[63,60]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/contacts-lists/partials/contact-row-brand-refactor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("tr");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_avatar_col pointer");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_name_col pointer");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_position_col pointer");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_company_col pointer");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_country_col pointer");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_social_col");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","contact_check_col");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","bal-checkbox-label");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","dots_col");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element17 = dom.childAt(fragment, [0]);
        var element18 = dom.childAt(element17, [1]);
        var element19 = dom.childAt(element17, [3]);
        var element20 = dom.childAt(element17, [5]);
        var element21 = dom.childAt(element17, [7]);
        var element22 = dom.childAt(element17, [9]);
        var element23 = dom.childAt(element17, [11]);
        var element24 = dom.childAt(element17, [13]);
        var element25 = dom.childAt(element24, [3]);
        var morphs = new Array(17);
        morphs[0] = dom.createAttrMorph(element17, 'class');
        morphs[1] = dom.createElementMorph(element18);
        morphs[2] = dom.createMorphAt(element18,1,1);
        morphs[3] = dom.createElementMorph(element19);
        morphs[4] = dom.createMorphAt(element19,0,0);
        morphs[5] = dom.createElementMorph(element20);
        morphs[6] = dom.createMorphAt(element20,0,0);
        morphs[7] = dom.createElementMorph(element21);
        morphs[8] = dom.createMorphAt(element21,0,0);
        morphs[9] = dom.createElementMorph(element22);
        morphs[10] = dom.createMorphAt(element22,0,0);
        morphs[11] = dom.createMorphAt(element23,1,1);
        morphs[12] = dom.createMorphAt(element23,2,2);
        morphs[13] = dom.createMorphAt(element23,3,3);
        morphs[14] = dom.createMorphAt(element24,1,1);
        morphs[15] = dom.createAttrMorph(element25, 'for');
        morphs[16] = dom.createMorphAt(dom.childAt(element17, [15]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["contact_row ",["subexpr","if",[["get","contact.hasUser",["loc",[null,[1,28],[1,43]]]],"user","manual"],[],["loc",[null,[1,23],[1,61]]]]," ",["subexpr","if",[["get","contact.isAvatarDefault",["loc",[null,[1,67],[1,90]]]],"","avatar"],[],["loc",[null,[1,62],[1,104]]]]]]],
        ["element","action",["openViewContactProfileModal",["get","contact",["loc",[null,[2,80],[2,87]]]]],[],["loc",[null,[2,41],[2,89]]]],
        ["block","if",[["get","contact.isAvatarDefault",["loc",[null,[3,10],[3,33]]]]],[],0,1,["loc",[null,[3,4],[11,11]]]],
        ["element","action",["openViewContactProfileModal",["get","contact",["loc",[null,[13,78],[13,85]]]]],[],["loc",[null,[13,39],[13,87]]]],
        ["content","contact.fullName",["loc",[null,[13,88],[13,108]]]],
        ["element","action",["openViewContactProfileModal",["get","contact",["loc",[null,[14,82],[14,89]]]]],[],["loc",[null,[14,43],[14,91]]]],
        ["content","contact.jobTitle",["loc",[null,[14,92],[14,112]]]],
        ["element","action",["openViewContactProfileModal",["get","contact",["loc",[null,[15,81],[15,88]]]]],[],["loc",[null,[15,42],[15,90]]]],
        ["content","contact.company",["loc",[null,[15,91],[15,110]]]],
        ["element","action",["openViewContactProfileModal",["get","contact",["loc",[null,[16,81],[16,88]]]]],[],["loc",[null,[16,42],[16,90]]]],
        ["content","contact.countryLabel",["loc",[null,[16,91],[16,115]]]],
        ["block","if",[["get","contact.website",["loc",[null,[18,10],[18,25]]]]],[],2,null,["loc",[null,[18,4],[20,11]]]],
        ["block","if",[["get","contact.facebook",["loc",[null,[21,10],[21,26]]]]],[],3,null,["loc",[null,[21,4],[23,11]]]],
        ["block","if",[["get","contact.instagram",["loc",[null,[24,10],[24,27]]]]],[],4,null,["loc",[null,[24,4],[26,11]]]],
        ["block","if",[["get","contact.checked",["loc",[null,[29,10],[29,25]]]]],[],5,6,["loc",[null,[29,4],[33,11]]]],
        ["attribute","for",["concat",["contact_cb_",["get","contact.id",["loc",[null,[34,29],[34,39]]]]]]],
        ["block","if",[["get","bulkDisabledRefactor",["loc",[null,[37,10],[37,30]]]]],[],7,null,["loc",[null,[37,4],[70,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});