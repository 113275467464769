define('bookalook/helpers/first-position-array', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var array1 = params[0];

    if (array1.length > 0) {
      return array1[0];
    } else {
      return '';
    }
  });

});