define('bookalook/models/collection', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment'], function (exports, Ember, DS, Validator, config) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    title: DS['default'].attr('string', { defaultValue: '' }),
    year: DS['default'].attr('string'),
    archived: DS['default'].attr('boolean'),
    createdDate: DS['default'].attr('day'),
    archivedDate: DS['default'].attr('day'),
    season: DS['default'].attr('string'),
    collectionType: DS['default'].attr('string'),
    thumbnail: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_IMAGE }),

    avatar: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_IMAGE }),

    brand: DS['default'].belongsTo('brand', { async: true, inverse: null }),
    albums: DS['default'].hasMany('album', { async: true }),

    samples: (function () {
      var albums = this.get('albums');
      var samples = [];
      albums.forEach(function (album) {
        samples.addObjects(album.get('samples'));
      });
      return samples;
    }).property('albums.@each.samples'),

    activeAlbums: (function () {
      return this.get('albums').filter(function (l) {
        return !l.get('archived') && l.get('published');
      });
    }).property('albums.@each.published', 'albums.@each.archived'),

    notArchivedAlbums: (function () {
      return this.get('albums').filter(function (l) {
        return !l.get('archived');
      });
    }).property('albums.@each.archived'),

    seasonLabel: (function () {
      return this.get('season');
    }).property("season"),

    collectionTypeLabel: (function () {
      return this.get('collectionType');
    }).property("collectionType"),

    hasCheckedSamples: (function () {
      var albums = this.get('albums');
      var r = false;
      albums.forEach(function (album) {
        if (album.get('hasCheckedSamples')) {
          r = true;
        }
      });
      return r;
    }).property('albums.@each.hasCheckedSamples'),

    hasVisibleSamples: (function () {
      var albums = this.get('albums');
      var r = false;
      albums.forEach(function (album) {
        r = r || album.get('hasVisibleSamples');
      });
      return r;
    }).property('albums.@each.hasVisibleSamples'),

    hasVisibleAndCheckedSamples: (function () {
      return this.get('albums').some(function (album) {
        return album.get('hasVisibleAndCheckedSamples');
      });
    }).property('albums.@each.hasVisibleAndCheckedSamples'),

    hasVisibleAndNotRemovedSamples: (function () {
      return this.get('albums').some(function (album) {
        return album.get('hasVisibleAndNotRemovedSamples');
      });
    }).property('albums.@each.hasVisibleAndNotRemovedSamples'),

    name: (function () {
      return this.get('year') + " - " + this.get('seasonLabel');
    }).property('year', 'season'),

    filterName: (function () {
      //18 - W - F/W
      var year = this.get('year') || "";
      var season = this.get('season') || "";
      var collectionType = this.get('collectionType') || "";

      if (typeof year === 'number') {
        year = year.toString();
      }
      var y = year.charAt(2) + year.charAt(3);

      var t = collectionType.charAt(0);
      if (t === 'H') t = "HC";

      var s = null;
      switch (season) {
        case "Spring/Summer":
          s = "S/S";
          break;
        case "Pre-fall":
          s = season;
          break;
        case "Fall/Winter":
          s = "F/W";
          break;
        case "Cruise":
          s = season;
          break;
        case "Capsule":
          s = season;
          break;
        case "Year-round":
          s = season;
          break;
        default:
          s = season;
      }
      return y + " - " + t + " - " + s;
    }).property('year', 'season', 'collectionType'),

    getImage: (function () {
      var avatar = this.get('avatar');
      var thumbnail = this.get('thumbnail');
      var image = thumbnail;
      if (Ember['default'].isEmpty(thumbnail)) {
        image = avatar;
        if (Ember['default'].isEmpty(image)) {
          image = config['default'].DEFAULT_IMAGE;
        }
      }
      return image;
    }).property('avatar', 'thumbnail'),

    validations: {
      'year': {
        presence: true
      },

      'season': {
        presence: true
      },

      'collectionType': {
        presence: true
      },

      brand: {
        custom: {
          validation: function validation(key, value, model) {
            return model.get('brand.id') != null ? true : false;
          },
          message: 'Please choose a brand'
        }
      }
    }
  });

});