define('bookalook/helpers/my-showroom', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.extend({

    label: '',

    compute: function compute(params) {
      var _this = this;

      var showroom = params[0];
      var route = params[1];
      this.set('label', showroom.get('id') === Ember['default'].showroom.get('id') ? 'Mine' : showroom.get('city'));

      if (Ember['default'].isNone(this.get('label'))) {
        (route.store || route.get('store')).findRecord('showroom', showroom.get('id')).then(function () {
          _this.recompute();
        });
      }
      return this.get('label');
    }
  });

});