define('bookalook/routes/search/looks', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/search/functions', 'bookalook/routes/search/functions-new', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, functions, newFunctions, utils) {

  'use strict';

  var routeForPoll = null;

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    poll: Ember['default'].inject.service(),

    queryParams: {
      departments: {
        //assigning replace state as true
        replace: true
      },
      brands: {
        //assigning replace state as true
        replace: true
      },
      collectionTypes: {
        //assigning replace state as true
        replace: true
      }

    },

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      var user = Ember['default'].user;
      if (!user || !user.get('isMP')) {
        this.transitionTo('index');
      }
    },

    model: function model(params) {
      this.set('searchCriteria', params.query || "");
      this.set('parameters', Ember['default'].getUrlParameters());
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.get('poll').clearAll();

        var _t = this;
        var controller = _t.controller;
        this.controllerFor('application').set('inSearchPage', false);

        //CHANGE FILTERS LISTENERS
        controller.removeObserver('designersSearchInput', controller, 'designerFilterObserver');
        controller.removeObserver('collectionTypesSearchInput', controller, 'collectionTypesFilterObserver');
        controller.removeObserver('categoriesSearchInput', controller, 'categoriesFilterObserver');
        controller.removeObserver('itemsSearchInput', controller, 'itemsFilterObserver');
        controller.removeObserver('coloursSearchInput', controller, 'coloursObserver');
        controller.removeObserver('filtersAvailable.departments.@each.doc_count', controller, 'departmentsObserver');
      },
      openModalOverviewSampleIn: function openModalOverviewSampleIn(sample) {
        this.onOpenModalOverviewSampleIn(sample);
      },
      requestAlbumPermission: function requestAlbumPermission(albumID) {
        this.onRequestAlbumPermission(albumID);
      },
      changeFilter: function changeFilter(filter) {
        this.onChangeFilter(filter);
      },
      removeFilter: function removeFilter(filter) {
        this.onRemoveFilter(filter);
      },
      addObjectSearch: function addObjectSearch() {
        var obj = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        newFunctions['default'].addParameterToSearchBar(this, obj);
      },
      removeObjectSearch: function removeObjectSearch() {
        var obj = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        newFunctions['default'].removeParameterFromSearchBar(this, obj);
      },
      scrollSearchBar: function scrollSearchBar(direction) {
        newFunctions['default'].scrollSearchBar(this, direction);
      },
      toggleDepartmentFilter: function toggleDepartmentFilter(value) {
        this.onToggleDepartmentFilter(value);
      },
      masonryIsFinished: function masonryIsFinished() {
        this.onMasonryIsFinished();
      },
      clearFilters: function clearFilters() {
        var dep = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        var brand = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
        var collectionType = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
        this.onClearFilters(dep, brand, collectionType);
      },
      openFiltersList: function openFiltersList(type) {
        this.onOpenFiltersList(type, event);
      }

    },

    setupController: function setupController(controller) {
      this.controllerFor('application').set('inSearchPage', true);
      controller.set('masonryFinished', false);

      var searchCriteria = decodeURIComponent(this.get('searchCriteria'));
      var _t = this;
      routeForPoll = this;
      controller.set('firstSearch', true);

      controller.set('MenFilterDisabled', false);
      controller.set('MenFilterActive', false);
      controller.set('UnisexFilterDisabled', false);
      controller.set('UnisexFilterActive', false);
      controller.set('WomenFilterDisabled', false);
      controller.set('WomenFilterActive', false);
      controller.set('BoyFilterDisabled', false);
      controller.set('BoyFilterActive', false);
      controller.set('GirlFilterDisabled', false);
      controller.set('GirlFilterActive', false);

      controller.set('searchCriteria', searchCriteria);
      controller.set('resultingLooks', []);
      var parametros = [];
      if (this.controller.get('departments')) {
        parametros.push(['departments', this.controller.get('departments')]);
      }
      if (this.controller.get('brands')) {
        parametros.push(['brands', this.controller.get('brands')]);
      }
      if (this.controller.get('collectionTypes')) {
        parametros.push(['collectionTypes', this.controller.get('collectionTypes')]);
      }
      this.set('parameters', parametros);
      controller.set('searchFilters', Ember['default'].isEmpty(this.get('parameters')) ? [['departments', 'Women']] : this.get('parameters'));

      var activeDepartment = this.get('parameters').get('departments');

      controller.set('activeFilters', []);
      controller.set('filtersList', {});

      //PAGINATION COMPONENT
      controller.set('nPages', null);
      controller.set('route', this);
      controller.set('currentPage', 1);
      controller.set('itemsPerPage', 12);

      controller.set('masonryOptions', {
        itemSelector: '.sample_card_search_sizing',
        columnWidth: '.sample_card_search_sizing',
        gutter: 8,
        percentPosition: true,
        horizontalOrder: true
      });

      controller.set('amountOfResults', 0);

      _t._searchLooks(_t.get('searchCriteria'), controller.get('searchFilters')).then(function () {
        _t._updateActiveFiltersArray(controller.get('searchFilters'), controller.get('activeFilters'));
        //controller.set('masonryFinished', true);
        var intervals = _t.get('poll');
        intervals.clearAll();
        intervals.addPoll({
          interval: 50, // 0.05s
          callback: _t._scrollCalculation
        });
      });

      controller.set('designersSearchInput', null);
      controller.designerFilterObserver = function () {
        var filters = controller.get('filtersList.brands');
        var searchInput = (controller.get('designersSearchInput') || "").toLowerCase();
        if (!Ember['default'].isEmpty(filters)) {
          filters.map(function (f) {
            var childrens = f.children;
            var someChildrenVisible = false;
            childrens.map(function (c) {
              if (!searchInput || searchInput === "") {
                Ember['default'].set(c, 'visible', true);
                someChildrenVisible = true;
              } else {
                if (c.text.toLowerCase().indexOf(searchInput) > -1) {
                  Ember['default'].set(c, 'visible', true);
                  someChildrenVisible = true;
                } else {
                  Ember['default'].set(c, 'visible', false);
                }
              }
              return c;
            });
            Ember['default'].set(f, 'visible', someChildrenVisible);
            return f;
          });
        }
      };
      controller.designerFilterObserver();
      utils['default'].addObserver(controller, ['designersSearchInput'], 'designerFilterObserver');

      controller.set('collectionTypesSearchInput', null);
      controller.collectionTypesFilterObserver = function () {
        var filters = controller.get('filtersList.collectionTypes');
        var searchInput = (controller.get('collectionTypesSearchInput') || "").toLowerCase();
        if (!Ember['default'].isEmpty(filters)) {
          filters.map(function (f) {
            if (!searchInput || searchInput === "") {
              Ember['default'].set(f, 'visible', true);
            } else {
              if (f.key.toLowerCase().indexOf(searchInput) > -1) {
                Ember['default'].set(f, 'visible', true);
              } else {
                Ember['default'].set(f, 'visible', false);
              }
            }
            return f;
          });
        }
      };
      controller.collectionTypesFilterObserver();
      utils['default'].addObserver(controller, ['collectionTypesSearchInput'], 'collectionTypesFilterObserver');

      controller.set('categoriesFilter', null);
      controller.categoriesFilterObserver = function () {
        var filter = controller.get('categoriesFilter');
        if (filter && filter.key != "---") {
          _t.onChangeFilter(filter);
        }
        controller.set('categoriesFilter', null);
      };
      controller.categoriesFilterObserver();
      utils['default'].addObserver(controller, ['categoriesFilter'], 'categoriesFilterObserver');

      controller.set('itemsSearchInput', "");
      controller.itemsFilterObserver = function () {
        var filters = controller.get('filtersList.items');
        var searchInput = (controller.get('itemsSearchInput') || "").toLowerCase();
        if (!Ember['default'].isEmpty(filters)) {
          filters.map(function (f) {
            if (!searchInput || searchInput === "") {
              Ember['default'].set(f, 'visible', true);
            } else {
              if (f.key.toLowerCase().indexOf(searchInput) > -1) {
                Ember['default'].set(f, 'visible', true);
              } else {
                Ember['default'].set(f, 'visible', false);
              }
            }
            return f;
          });
        }
      };
      controller.itemsFilterObserver();
      utils['default'].addObserver(controller, ['itemsSearchInput'], 'itemsFilterObserver');

      /*controller.set('permissionsFilter', null);
      utils.addObserver(controller, ['permissionsFilter'], function () {
        let filter = controller.get('permissionsFilter');
        if (filter && filter.key != "---"){
          _t.onChangeFilter(filter);
        }
        controller.set('permissionsFilter', null);
      });*/

      controller.set('coloursFilter', null);
      controller.coloursObserver = function () {
        var filter = controller.get('coloursFilter');
        if (filter && filter.key != "---") {
          _t.onChangeFilter(filter);
        }
        controller.set('coloursFilter', null);
      };
      controller.coloursObserver();
      utils['default'].addObserver(controller, ['coloursFilter'], 'coloursObserver');

      controller.departmentsObserver = function () {
        var searchInput = (controller.get('departmentsSearchInput') || "").toLowerCase();
        if ('women'.indexOf(searchInput) > -1) {
          controller.set('WomenFilterVisible', true);
        } else {
          controller.set('WomenFilterVisible', false);
        }
        if ('men'.indexOf(searchInput) > -1) {
          controller.set('MenFilterVisible', true);
        } else {
          controller.set('MenFilterVisible', false);
        }
        if ('unisex'.indexOf(searchInput) > -1) {
          controller.set('UnisexFilterVisible', true);
        } else {
          controller.set('UnisexFilterVisible', false);
        }
        if ('boy'.indexOf(searchInput) > -1) {
          controller.set('BoyFilterVisible', true);
        } else {
          controller.set('BoyFilterVisible', false);
        }
        if ('girl'.indexOf(searchInput) > -1) {
          controller.set('GirlFilterVisible', true);
        } else {
          controller.set('GirlFilterVisible', false);
        }

        var departmentSelected = controller.get('departments');
        if (Ember['default'].isEmpty(departmentSelected)) {
          departmentSelected = 'Women';
        }
        controller.set('departmentSelected', departmentSelected);
      };
      utils['default'].addObserver(controller, ['filtersAvailable.departments.@each.doc_count', 'departmentsSearchInput'], 'departmentsObserver');
    },

    _updateActiveFiltersArray: function _updateActiveFiltersArray(searchFilters, activeFiltersArray) {
      var _this = this;

      searchFilters.forEach(function (filter, index) {
        activeFiltersArray.addObject({ 'id': index, 'key': filter[1], 'type': filter[0] });
        var listFilters = _this.controller.get('filtersAvailable.' + filter[0]);
        var filterFromList = null;
        if (filter[0] == "brands") {
          for (var group in listFilters) {
            if (!filterFromList) {
              if (listFilters.hasOwnProperty(group)) {
                var filteredGroup = listFilters[group];
                filterFromList = filteredGroup['children'].find(function (f) {
                  return f.key == filter[1];
                });
              }
            }
          }
        } else {
          filterFromList = listFilters.find(function (f) {
            return f.key == filter[1];
          });
        }
        if (filterFromList) {
          Ember['default'].set(filterFromList, 'disabled', true);
          if (filterFromList.type == "departments") {
            _this.controller.set(filterFromList.key + "FilterActive", true);
          }
          if (filterFromList.type == "brands") {
            // Ember.$('#brandsFilter').attr("data-placeholder", "DESIGNERS (1)");
            // Ember.$('#brandsFilter').data("select2").setPlaceholder();
            _this.controller.set("brandsPlaceholder", 1);
          } else if (filterFromList.type == "collectionTypes") {
            // Ember.$('#collectionTypesFilter').attr("data-placeholder", "COLLECTION (1)");
            // Ember.$('#collectionTypesFilter').data("select2").setPlaceholder();
            _this.controller.set("collectionTypesPlaceholder", 1);
          }
        }
      });
    },

    onOpenModalOverviewSampleIn: function onOpenModalOverviewSampleIn(sample) {
      this.send('openModalOverviewSampleSearch', sample);
    },

    onRequestAlbumPermission: function onRequestAlbumPermission(albumID) {
      utils['default'].postAPIJSON('albums/' + albumID + '/requestPermission', {});
      this.send('openDefaultMessageModal', 'Request sent', 'Amazing! You have requested access to the "ALBUM NAME" from the "COLLECTION AND BRAND"', 'You will receive a confirmation email once the PR responsible for your request gives you access to the album. Thank you');
    },

    onClearFilters: function onClearFilters() {
      var dep = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var br = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
      var coll = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      var _t = this;
      var controller = _t.controller;
      //IF NO SECTION: RESET TO CURRENT DEPARTMENT
      if (!dep && !br && !coll) {
        if (controller.get('MenFilterActive')) {
          dep = "Men";
        } else if (controller.get('UnisexFilterActive')) {
          dep = "Unisex";
        } else if (controller.get('WomenFilterActive')) {
          dep = "Women";
        } else if (controller.get('BoyFilterActive')) {
          dep = "Boy";
        } else if (controller.get('GirlFilterActive')) {
          dep = "Girl";
        }
      }
      this.controller.set('departments', dep);
      this.controller.set('brands', br);
      this.controller.set('collectionTypes', coll);

      var parametros = [];
      if (dep) {
        parametros.push(['departments', dep]);
      }
      if (br) {
        parametros.push(['brands', br]);
      }
      if (coll) {
        parametros.push(['collectionTypes', coll]);
      }
      this.set('parameters', parametros);
      //controller.set('searchFilters', Ember.isEmpty(this.get('parameters')) ? [['departments', 'Women']]: this.get('parameters'));
      controller.set('searchFilters', Ember['default'].isEmpty(this.get('parameters')) ? [] : this.get('parameters'));

      //window.history.replaceState( {} , 'Bookalook - Where samples are. Easy.', urlHash);

      this._resetSearchVars();

      var searchCriteria = "";

      var searchFilters = parametros;

      controller.set('searchCriteria', searchCriteria);
      controller.set('searchFilters', searchFilters);

      _t._searchLooks(searchCriteria, searchFilters).then(function () {
        _t._updateActiveFiltersArray(searchFilters, controller.get('activeFilters'));
      });

      //_t.onToggleDepartmentFilter(section);
    },

    _resetSearchVars: function _resetSearchVars() {
      var _t = this;
      var controller = _t.controller;

      controller.set('firstSearch', true);

      controller.set('searchCriteria', "");
      controller.set('resultingLooks', []);
      controller.set('searchFilters', [['departments', 'Women']]);
      controller.set('activeFilters', []);
      controller.set('filtersList', {});

      // Ember.$('#brandsFilter').attr("data-placeholder", "DESIGNERS");
      // Ember.$('#brandsFilter').data("select2").setPlaceholder();
      _t.controller.set("brandsPlaceholder", 0);
      // Ember.$('#collectionTypesFilter').attr("data-placeholder", "COLLECTION");
      // Ember.$('#collectionTypesFilter').data("select2").setPlaceholder();
      _t.controller.set("collectionTypesPlaceholder", 0);
      // Ember.$('#categoriesFilter').attr("data-placeholder", "CATEGORY");
      // Ember.$('#categoriesFilter').data("select2").setPlaceholder();
      _t.controller.set("categoriesPlaceholder", 0);
      // Ember.$('#itemsFilter').attr("data-placeholder", "ITEMS");
      // Ember.$('#itemsFilter').data("select2").setPlaceholder();
      _t.controller.set("itemsPlaceholder", 0);
      // Ember.$('#coloursFilter').attr("data-placeholder", "COLOURS");
      // Ember.$('#coloursFilter').data("select2").setPlaceholder();
      _t.controller.set("coloursPlaceholder", 0);

      controller.set('departmentSelected', null);
      controller.set('MenFilterVisible', true);
      controller.set('UnisexFilterVisible', true);
      controller.set('WomenFilterVisible', true);
      controller.set('BoyFilterVisible', true);
      controller.set('GirlFilterVisible', true);
    },

    onToggleDepartmentFilter: function onToggleDepartmentFilter(value) {
      if (this.controller.get('togglingDepartments') || this.controller.get('isLoading')) {
        return;
      }
      this.onClearFilters(value, null, null);
      /*
      this.controller.set('togglingDepartments', true);
      let listFilters = this.controller.get('filtersAvailable.departments');
      let filterFromList = listFilters.find(f => {return f.key == value});
      let dep = ["Women", "Men", "Unisex"];
      //UNSELECT OTHER DEPARTMENT FILTERS
      for (let i = 0; i<3;i++){
        if (value != dep[i]){
          var activeFilters = this.controller.get('activeFilters');
          var filter  = this._existsFilterArray(activeFilters, dep[i]);
          if (filter){
            var idFilterToDelete = filter.id;
            var searchFilters = this.controller.get('searchFilters');
            searchFilters.splice(idFilterToDelete, 1);
            activeFilters.removeAt(idFilterToDelete);
             activeFilters.forEach(filter => {
              if(filter.id > idFilterToDelete) {
                filter.id -= 1;
              }
            });
          }
          this.controller.set(dep[i]+"FilterActive", false);
          let obj = listFilters.find(f => f.key == dep[i]);
          Ember.set(obj, 'disabled', false);
        }
        if (i==2){
          this.controller.set('togglingDepartments', false);
        }
      }
       this.controller.set('departments', value);
      this.onChangeFilter(filterFromList);
      */
    },

    onChangeFilter: function onChangeFilter(filter) {
      if (this.controller.get('isLoading')) {
        return;
      }
      var type = filter.type;
      var value = filter.key;
      var newFilter = [type, value];
      var searchFilters = this.controller.get('searchFilters');
      var activeFilters = this.controller.get('activeFilters');
      var newActiveFilter = { id: searchFilters.length, key: newFilter[1], type: newFilter[0] };
      var existingFilter = this._existsFilterArray(activeFilters, newFilter[1]);

      if (!existingFilter) {
        Ember['default'].set(filter, 'disabled', true);
        if (type == "departments") {
          this.controller.set(value + "FilterActive", true);
        }
        //Ember.$('#filter_' + value.replace(/ /g,'')+ ' span').first().addClass('color_primary');
        searchFilters.push(newFilter);
        activeFilters.addObject(newActiveFilter);
        this._searchLooks(this.get('searchCriteria'), searchFilters);
      } else {
        if (type == "departments") {
          this.controller.set(value + "FilterActive", false);
        }
        var listFilters = this.controller.get('filtersAvailable.' + type);
        if (type != "brands") {
          var filterFromList = listFilters.find(function (f) {
            return f.key == value;
          });
          Ember['default'].set(filterFromList, 'disabled', false);
        } else {
          var filterFromList = null;
          for (var group in listFilters) {
            if (!filterFromList) {
              if (listFilters.hasOwnProperty(group)) {
                var filteredGroup = listFilters[group];
                filterFromList = filteredGroup['children'].find(function (f) {
                  return f.key == value;
                });
                if (filterFromList) {
                  Ember['default'].set(filterFromList, 'disabled', false);
                }
              }
            }
          }
        }
        //Ember.$('#filter_' + value.replace(/ /g,'')+ ' span').first().removeClass('color_primary');
        this.onRemoveFilter(existingFilter);
      }
      if (type != "departments") {
        var typePlaceholder = "BRANDS";
        if (type === "brands") {
          typePlaceholder = "DESIGNERS";
        } else if (type === "collectionTypes") {
          typePlaceholder = "COLLECTION";
        } else if (type === "categories") {
          typePlaceholder = "CATEGORY";
        } else if (type === "items") {
          typePlaceholder = "ITEMS";
        } else if (type === "colours") {
          typePlaceholder = "COLOURS";
        } /*else if (type === "permissions"){
          typePlaceholder = "PERMISSIONS";
          }*/

        var filtersCount = this.controller.get('activeFilters').filter(function (f) {
          return f.type == type;
        }).get('length');
        this.controller.set(type + "Placeholder", filtersCount);
        // Ember.$('#'+type+"Filter").attr("data-placeholder", (filtersCount == 0 ? typePlaceholder : typePlaceholder + " ("+filtersCount+")"));
        // Ember.$('#'+type+"Filter").data("select2").setPlaceholder();
        //Because search is removed if no filters so jquery fails searching in dom
        //if (activeFilters.length != 0){
        newFunctions['default'].handleEnableSearchBarArrows(this);
        //}
      }
    },

    onRemoveFilter: function onRemoveFilter(filter) {
      var searchFilters = this.controller.get('searchFilters');
      var activeFilters = this.controller.get('activeFilters');
      var idFilterToDelete = filter.id;

      if (filter === "all") {
        //activeFilters.forEach(filter => Ember.$('#filter_' + filter.key.replace(/ /g,'')+ ' span').first().removeClass('color_primary'));
        this.controller.set('activeFilters', []);
        this.controller.set('searchFilters', []);
        searchFilters = [];
      } else {
        searchFilters.splice(idFilterToDelete, 1);
        activeFilters.removeAt(idFilterToDelete);

        activeFilters.forEach(function (filter) {
          if (filter.id > idFilterToDelete) {
            filter.id -= 1;
          }
        });
      }
      this._searchLooks(this.get('searchCriteria'), searchFilters);
    },

    _searchLooks: function _searchLooks(searchCriteria) {
      var _this2 = this;

      var searchFilters = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
      var skipStopLoading = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var controller = this.controller;
      this.controller.set('isLoading', true);
      this.controller.set('masonryFinished', false);
      return functions['default'].searchByCriteria('looks', searchCriteria, 1, searchFilters).then(function (result) {
        var looks = result.data.looks;

        _this2.controller.set('resultingLooks', looks.items);
        _this2.controller.set('numResults', looks.total_count);
        _this2.controller.set('searchCriteria', decodeURIComponent(searchCriteria));

        looks.custom_parameters.aggregations.colours_name = {
          colours_name: {
            buckets: _this2._mergeColoursResponse(looks.custom_parameters.aggregations)
          }
        };
        looks.custom_parameters.aggregations.items = {
          items: {
            buckets: _this2._mergeitemsResponse(looks.custom_parameters.aggregations)
          }
        };
        _this2._orderAggregations(looks.custom_parameters.aggregations);
        _this2.controller.set('filtersAvailable', looks.custom_parameters.aggregations);
        _this2.controller.set('firstSearch', false);

        var filteredAggregations = result.data.aggresults;
        if (!Ember['default'].isNone(filteredAggregations)) {
          var _loop = function (type) {
            if (filteredAggregations.hasOwnProperty(type)) {
              (function () {
                filteredAggregations[type].custom_parameters.aggregations.colours_name = {
                  colours_name: {
                    buckets: _this2._mergeColoursResponse(filteredAggregations[type].custom_parameters.aggregations)
                  }
                };
                filteredAggregations[type].custom_parameters.aggregations.items = {
                  items: {
                    buckets: _this2._mergeitemsResponse(filteredAggregations[type].custom_parameters.aggregations)
                  }
                };
                _this2._orderAggregations(filteredAggregations[type].custom_parameters.aggregations);
                var activeFilters = _this2.controller.get('activeFilters');
                var newTypeFilters = filteredAggregations[type].custom_parameters.aggregations[type];
                /*newTypeFilters.map(nF => {
                  let isActive = activeFilters.some(aF => (aF.type == type && aF.key == nF.key));
                  if (isActive){
                    Ember.set(nF, 'disabled', true);
                  }
                  return nF;
                });*/
                activeFilters.map(function (aF) {
                  if (aF.type == type) {
                    var foundFilter = newTypeFilters.find(function (nF) {
                      return nF.key == aF.key;
                    });
                    if (foundFilter) {
                      Ember['default'].set(foundFilter, 'disabled', true);
                    }
                  }
                });
                _this2.controller.set('filtersAvailable.' + type, filteredAggregations[type].custom_parameters.aggregations[type]);
              })();
            }
          };

          for (var type in filteredAggregations) {
            _loop(type);
          }
        }
        var filters = _this2.controller.get('filtersAvailable');
        var currentFilters = _this2.controller.get('activeFilters');
        var filtersList = Ember['default'].A();
        if (!Ember['default'].isNone(filters)) {
          var _loop2 = function (type) {
            if (filters.hasOwnProperty(type)) {
              if (type == "brands") {
                var filteredType = filters[type];
                for (var group in filteredType) {
                  if (filteredType.hasOwnProperty(group)) {
                    var filteredGroup = filteredType[group];
                    filteredGroup['children'] = filteredGroup['children'].filter(function (fT) {
                      return !currentFilters.find(function (cF) {
                        return cF.type == type && cF.key == fT.key;
                      });
                    });
                  }
                }
                filtersList[type] = filteredType.filter(function (fT) {
                  return !Ember['default'].isEmpty(fT['children']);
                });
              } else if (type == "items") {
                var filteredType = filters[type];
                filtersList[type] = filteredType.filter(function (fT) {
                  return !currentFilters.find(function (cF) {
                    return cF.type == type && cF.key == fT.key;
                  });
                });
                var categoriesActive = currentFilters.filter(function (cF) {
                  return cF.type == "categories";
                });
                if (categoriesActive.length) {
                  (function () {
                    var catValues = categoriesActive.map(function (cA) {
                      return cA.key;
                    });
                    filtersList[type] = filtersList[type].filter(function (fL) {
                      return catValues.includes(fL.category);
                    });
                  })();
                }
              } else {
                var filteredType = filters[type];
                filtersList[type] = filteredType.filter(function (fT) {
                  return !currentFilters.find(function (cF) {
                    return cF.type == type && cF.key == fT.key;
                  });
                });
              }
            }
          };

          for (var type in filters) {
            _loop2(type);
          }
        }
        _this2.controller.set('filtersList', filtersList);

        _this2.controller.set('currentPage', looks.current_page_number);
        _this2.controller.set('itemsPerPage', looks.num_items_per_page);

        if (!skipStopLoading) {
          _this2.controller.set('isLoading', false);
          Ember['default'].$('#search-masonry-container').imagesLoaded(function () {
            Ember['default'].run.later(function () {
              controller.set('masonryFinished', true);
            }, 1500);
          });
        }
        _this2.controller.set('nPages', Math.ceil(looks.total_count / looks.num_items_per_page));
        return result;
      });
    },

    _orderAggregations: function _orderAggregations(aggregations) {
      aggregations.album_albumtype.album_albumtype.buckets.sortOn('key');
      aggregations.album_privacy.album_privacy.buckets.sortOn('key');
      aggregations.brand_name.brand_name.buckets.sortOn('key');
      aggregations.collection_collectionType.collection_collectionType.buckets.sortOn('key');
      aggregations.collection_season.collection_season.buckets.sortOn('key');
      aggregations.collection_year.collection_year.buckets.sortOn('key');
      aggregations.collection_seasonYear.collection_seasonYear.buckets.sortOn('key');
      aggregations.item.item.buckets.sortOn('key');
      aggregations.item_department.item_department.buckets.sortOn('key');

      aggregations.departments = aggregations.item_department.item_department.buckets.slice();
      aggregations.categories = aggregations.item.item.buckets.slice();
      aggregations.items = aggregations.items.items.buckets.slice();
      //aggregations.albums = aggregations.album_albumtype.album_albumtype.buckets.slice();
      //aggregations.permissions = aggregations.album_privacy.album_privacy.buckets.slice();
      aggregations.colours = aggregations.colours_name.colours_name.buckets.slice();
      aggregations.brands = aggregations.brand_name.brand_name.buckets.slice();
      //aggregations.seasons = aggregations.collection_season.collection_season.buckets.slice();
      aggregations.collectionTypes = aggregations.collection_collectionType.collection_collectionType.buckets.slice();

      delete aggregations.album_albumtype;
      delete aggregations.album_privacy;
      delete aggregations.brand_name;
      delete aggregations.collection_collectionType;
      delete aggregations.collection_season;
      delete aggregations.collection_year;
      delete aggregations.collection_seasonYear;
      delete aggregations.item;
      delete aggregations.item_department;
      delete aggregations.samples_colour1;
      delete aggregations.samples_colour2;
      delete aggregations.samples_colour3;
      delete aggregations.colours_name;

      var arrayDepartments = [];
      //Special treatment for departments filter
      this._mapForSelect2("departments", []);

      this._mapForSelect2("categories", aggregations.categories);
      this._mapForSelect2("items", aggregations.items);
      //this._mapForSelect2("albums", aggregations.albums);
      //this._mapForSelect2("permissions", aggregations.permissions);
      this._mapForSelect2("brands", aggregations.brands);
      //this._separateUsingFirstChar(aggregations.brands);
      aggregations.brands = this._separateUsingFirstChar(aggregations.brands);
      //this._mapForSelect2("seasons", aggregations.seasons);
      this._mapForSelect2("colours", aggregations.colours);
      this._mapForSelect2("collectionTypes", aggregations.collectionTypes);
    },

    _separateUsingFirstChar: function _separateUsingFirstChar(aggregation) {
      //let newBrands = [];
      var brandSorted = [];
      for (var i = 0; i < aggregation.length; i++) {
        var brand = aggregation[i];
        var _name = brand.id;
        var initial = _name.charAt(0).toUpperCase();
        initial = initial.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        if (typeof brandSorted[initial] == 'undefined') {
          brandSorted[initial] = [];
          brandSorted[initial].push(brand);
        } else {
          brandSorted[initial].push(brand);
        }
      }

      var selectContent = [];
      for (var firstLetter in brandSorted) {
        if (brandSorted.hasOwnProperty(firstLetter)) {
          selectContent.push({
            text: firstLetter,
            children: brandSorted[firstLetter],
            visible: true
          });
        }
      }
      //console.log(JSON.stringify(selectContent));
      //return JSON.stringify(selectContent);
      return selectContent;
    },

    _mapForSelect2: function _mapForSelect2(type, aggregation) {
      if (type != 'departments') {
        aggregation.map(function (b) {
          //let firstCharUppercase = b.key.charAt(0).toUpperCase() +  b.key.slice(1);
          Ember['default'].set(b, 'id', b.key);
          Ember['default'].set(b, 'text', b.key);
          Ember['default'].set(b, 'type', type);
          //Ember.set(b, 'label', firstCharUppercase);
          Ember['default'].set(b, 'disabled', b.doc_count === 0);
          Ember['default'].set(b, 'visible', true);
          return b;
        });
      }
      /*if (type != "colours" && type != "brands" && type != "collectionTypes"){
        aggregation.unshift({id: null, key: "---", label: "---"});
      }*/
    },

    _existsFilterArray: function _existsFilterArray(filtersArray, value) {
      return filtersArray.find(function (filter) {
        return filter.key === value;
      });
    },

    _sanitizeString: function _sanitizeString(string) {
      var outString = undefined;
      string = string.replace(/ /g, '');
      outString = string.replace(/[`~!@$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      return outString;
    },

    _mergeColoursResponse: function _mergeColoursResponse(aggregations) {
      var result;
      var returnableColours = [];
      var colour1 = aggregations.samples_colour1.samples_colour1.buckets;
      var colour2 = aggregations.samples_colour2.samples_colour2.buckets;
      var colour3 = aggregations.samples_colour3.samples_colour3.buckets;
      //let _t = this;
      result = colour1.concat(colour2);
      result = result.concat(colour3);
      result.sortOn('key');
      var colorsArray = Ember['default'].A();
      result.forEach(function (colour) {
        colorsArray.addObject(colour.key);
      });
      colorsArray.map(function (c) {
        var col1 = colour1.find(function (c1) {
          return c1.key == c;
        });
        var col2 = colour2.find(function (c2) {
          return c2.key == c;
        });
        var col3 = colour3.find(function (c3) {
          return c3.key == c;
        });
        var doc_count = !Ember['default'].isNone(col1) ? col1.doc_count : 0;
        doc_count = doc_count + (!Ember['default'].isNone(col2) ? col2.doc_count : 0);
        doc_count = doc_count + (!Ember['default'].isNone(col3) ? col3.doc_count : 0);
        //let firstLetterUppercase = c.charAt(0).toUpperCase() + c.slice(1);
        var divWithColour = new Ember['default'].Handlebars.SafeString("<div class='list-item-colour background-colour-" + c + "' ></div>");
        returnableColours.addObject({
          key: c,
          doc_count: doc_count,
          //label: firstLetterUppercase
          label: divWithColour
        });
      });
      return returnableColours;
    },

    _mergeitemsResponse: function _mergeitemsResponse(aggregations) {
      //let returnableitems = [];

      var categories = aggregations.item.item.buckets;
      var items = [];
      categories.sortOn('key');
      categories.forEach(function (c) {
        var sub = c.item_names.buckets;
        sub = sub.map(function (i) {
          i.category = c.key;
          i.label = i.key.charAt(0).toUpperCase() + i.key.slice(1);
          i.type = "items";
          return i;
        });
        //sub.sortOn('key');
        items.push(sub);
      });

      var itemsFullArray = [];
      for (var i = 0; i < items.length; i++) {
        itemsFullArray = itemsFullArray.concat(items[i]);
      }

      itemsFullArray.sortOn('key');

      return itemsFullArray;
    },

    _searchTargetPage: function _searchTargetPage(page) {
      var _this3 = this;

      var controller = this.controller;
      controller.set('masonryFinished', false);
      var searchFilters = this.controller.get('searchFilters');
      return functions['default'].searchByCriteria('looks', this.get('searchCriteria'), page, searchFilters).then(function (result) {
        var looks = result.data.looks;
        _this3.controller.set('currentPage', looks.current_page_number);
        var results = _this3.controller.get('resultingLooks');
        results.addObjects(looks.items);
        Ember['default'].$('#search-masonry-container').imagesLoaded(function () {
          Ember['default'].run.later(function () {
            controller.set('masonryFinished', true);
          }, 1500);
        });
        /*this.controller.set('resultingLooks', looks.items);
        Ember.$('body, html').animate({
          scrollTop: Ember.$('body, html').offset().top
        }, 500);*/
      });
    },

    onOpenModalOverviewSample: function onOpenModalOverviewSample(sample) {
      Ember['default'].openModal();
      this.controller.set('lookOverview', sample);
      this.controller.set('look', sample);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-search');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-search');
    },

    _scrollCalculation: function _scrollCalculation() {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 500) {
        var controller = routeForPoll.controller;
        if (controller.get('masonryFinished') && controller.get('resultingLooks.length') && !controller.get('isLoading') && controller.get('nPages') > controller.get('currentPage')) {
          controller.incrementProperty('currentPage');
          routeForPoll._searchTargetPage(controller.get('currentPage'));
        }
      } else {
        //console.log($(window).scrollTop() + $(window).height(), $(document).height() - 300);
      }
    },

    onMasonryIsFinished: function onMasonryIsFinished() {
      //console.log("go for it");
      this.controller.set('masonryFinished', true);
    },

    onOpenFiltersList: function onOpenFiltersList(type, event) {
      event.preventDefault();
      event.stopPropagation();

      var _t = this;
      var wasActive = _t.controller.get(type + 'FiltersListActive');

      _t.controller.set('departmentsFiltersListActive', false);
      _t.controller.set('departmentsSearchInput', "");

      _t.controller.set('designersFiltersListActive', false);
      _t.controller.set('designersSearchInput', "");

      _t.controller.set('collectionTypesFiltersListActive', false);
      _t.controller.set('collectionTypesSearchInput', "");

      _t.controller.set('categoriesFiltersListActive', false);
      _t.controller.set('categoriesSearchInput', "");

      _t.controller.set('itemsFiltersListActive', false);
      _t.controller.set('itemsSearchInput', "");

      _t.controller.set('coloursFiltersListActive', false);
      _t.controller.set('coloursSearchInput', "");

      _t.controller.set(type + 'FiltersListActive', true);
      if (!wasActive) {
        if (type == "designers" || type == "collectionTypes" || type == "items") {
          Ember['default'].run.later(function () {
            Ember['default'].$('#' + type + 'SearchInput').focus();
          }, 50);
        }
        Ember['default'].$(document).one('click', function closeMenu(e) {
          var element = Ember['default'].$(e.target);
          if (element.hasClass('my-select2-search') || element.prop("tagName") == "INPUT") {
            Ember['default'].$(document).one('click', closeMenu);
          } else {
            if (element.hasClass('list-item-colour')) {
              element = element.parent();
            }
            if (element.hasClass('my-select2-result-label')) {
              if (element.parent().hasClass('my-select2-result-unselectable') || element.parent().hasClass('my-select2-disabled')) {
                Ember['default'].$(document).one('click', closeMenu);
              } else {
                _t.controller.set(type + 'FiltersListActive', false);
              }
            } else if (element.hasClass('my-select2-results')) {
              Ember['default'].$(document).one('click', closeMenu);
            } else {
              _t.controller.set(type + 'FiltersListActive', false);
            }
          }
        });
      } else {
        //Close it if it was open
        _t.controller.set(type + 'FiltersListActive', false);
      }
    },

    _mergeItemsResponse2: function _mergeItemsResponse2(aggregations) {
      //let returnableItems = [];

      var categories = aggregations.item.item.buckets;
      var items = [];
      categories.sortOn('key');
      categories.forEach(function (c) {
        var sub = c.item_names.buckets;
        sub = sub.map(function (i) {
          i.category = c.key;
          i.label = i.key.charAt(0).toUpperCase() + i.key.slice(1);
          i.type = "items";
          return i;
        });
        sub.sortOn('key');
        items[c.key] = sub;
      });
      //console.log(items);

      var selectContent = [];
      for (var type in items) {
        if (items.hasOwnProperty(type)) {
          // if (items[type].length > 30){
          //   let chunks = [],
          //     i = 0,
          //     n = items[type].length;
          //
          //   while (i < n) {
          //     chunks.push(items[type].slice(i, i += 30));
          //   }
          //   let y = 0;
          //   while (y<chunks.length){
          //     selectContent.push(
          //       {
          //         text: type,
          //         children: chunks[y]
          //       }
          //     );
          //   }
          // } else {
          selectContent.push({
            text: type,
            children: items[type]
          });
          // }
        }
      }
      //console.log(selectContent);
      return selectContent;
    }

  });

});