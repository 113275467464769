define('bookalook/helpers/is-array-empty', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var array1 = params[0];

    if (array1.length > 0) {
      return false;
    } else {
      return true;
    }
  });

});