define('bookalook/routes/bookings/functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.isBookingOwner = isBookingOwner;
  exports.isBookingMember = isBookingMember;
  exports.setRequesterComputeds = setRequesterComputeds;
  exports.showroomSelectorObserver = showroomSelectorObserver;
  exports.openLoanFormTypeModal = openLoanFormTypeModal;
  exports.openAddTeamMemberModal = openAddTeamMemberModal;
  exports.onCancelAddTeamMember = onCancelAddTeamMember;
  exports.onSaveAddTeamMember = onSaveAddTeamMember;
  exports.onSaveLoanForm = onSaveLoanForm;
  exports.onOpenContactAddressSelector = onOpenContactAddressSelector;
  exports.onTransitionLoanForm = onTransitionLoanForm;
  exports.onDeleteBooking = onDeleteBooking;
  exports.onCancelBooking = onCancelBooking;
  exports.onCheckLookSamples = onCheckLookSamples;
  exports.compareAddresses = compareAddresses;
  exports.updateBookingStatus = updateBookingStatus;
  exports.setRequesterComputedsForEditBooking = setRequesterComputedsForEditBooking;

  function isBookingOwner(booking) {
    var r = false;
    var user = booking.get('owner');
    var currentUser = Ember['default'].user;
    if (parseInt(user.get('id')) === parseInt(currentUser.get('id'))) {
      r = true;
    }
    return r;
  }

  /*
   * booking can be a json object (when it comes from booking/index) or a ember object (if it comes from shopping list)
   */

  function isBookingMember(booking) {
    var users;
    var currentUser = Ember['default'].user;

    try {
      users = booking.get('users');
    } catch (e) {
      users = booking.users;
    }

    var r = users.some(function (user) {
      return parseInt(user.id) === parseInt(currentUser.get('id'));
    });
    return r;
  }

  function setRequesterComputeds(controller) {
    controller.set('allContacts', []);
    controller.set('allContactsList', controller.get('lists'));

    Ember['default'].set(controller, 'mainAddressRequester', Ember['default'].computed('bookingToAdd.requester', function () {
      var requester = controller.get('bookingToAdd.requester');
      if (controller.get('isNotFirstMainAddressRequester') && controller.get('bookingToAdd.isNew') && requester && requester.get('mainAddress')) {

        var addr = requester.get('mainAddress');
        if (controller.get('fromAddressBook')) {
          controller.get('bookingToAdd').setProperties({
            callInId: addr.get('id'),
            callInStreet1: addr.get('callInStreet1'),
            callInStreet2: addr.get('callInStreet2'),
            callInStreet3: addr.get('callInStreet3'),
            callInCountry: addr.get('callInStreet3'),
            callInCity: addr.get('city'),
            callInZipCode: addr.get('zipCode'),
            callInTelephone: addr.get('telephone1'),
            callInAttn: requester.get('fullName')
          });
        }
        controller.get('bookingToAdd').set('company', requester.get('company'));
        controller.get('bookingToAdd').set('returnAttn', Ember['default'].user.get('fullName'));

        controller.set('fromAddressBook', true);
        controller.set('fromAddressBookInverse', false);

        return requester.get('mainAddress');
      }
      controller.set('isNotFirstMainAddressRequester', true);
    }));

    showroomSelectorObserver(controller, 'comboShowroom', 'bookingToAdd');

    /*
      Ember.set(controller, 'addressesRequester',Ember.computed('bookingToAdd.requester', function () {
        var requester = controller.get('bookingToAdd.requester');
        var allAddresses = [];
    
        if(requester) {
          if(requester.get('privateAddresses')) {
            allAddresses.addObjects(requester.get('privateAddresses'));
            allAddresses.addObject(requester.get('publicAddress'));
            return allAddresses;
          }
        }
        return allAddresses;
      }));
      */

    if (!controller.get('allShowrooms')) {
      controller.set('allShowrooms', []);
      Ember['default'].agency.get('showrooms').then(function (shs) {
        var showrooms = shs;
        var firstElementShowroom = Ember['default'].Object.create({ 'id': '0', 'city': 'New address' });
        controller.get('allShowrooms').addObject(firstElementShowroom);
        showrooms.forEach(function (showroom) {
          return controller.get('allShowrooms').addObject(showroom);
        });
      });
    }

    controller.get('lists').forEach(function (list) {
      list.get('contacts').forEach(function (contact) {
        return controller.get('allContacts').addObject(contact);
      });
    });
  }

  function showroomSelectorObserver(controller, observed, modelString) {
    controller.showroomObserver = function () {
      var showroom = controller.get(observed);
      var modelToFill = controller.get(modelString);
      if (showroom) {
        if (showroom.get('id') !== "0") {
          controller.set('isShowroom', true);
          controller.set('isShowroomInverse', false);

          modelToFill.setProperties({
            returnId: showroom.get('id'),
            returnStreet1: showroom.get('street'),
            returnStreet2: showroom.get('street2'),
            returnCountry: showroom.get('country'),
            returnCity: showroom.get('city'),
            returnZipCode: showroom.get('zipCode'),
            returnTelephone: showroom.get('telephone')
          });
        } else {
          controller.set('isShowroom', false);
          controller.set('isShowroomInverse', true);

          modelToFill.setProperties({
            returnId: null,
            returnStreet1: '',
            returnStreet2: '',
            returnCountry: null,
            returnCity: '',
            returnZipCode: '',
            returnTelephone: ''
          });
        }
      }
    };
    utils['default'].addObserver(controller, [observed], 'showroomObserver');
  }

  function openLoanFormTypeModal(controller, titleText, samples) {
    var title = titleText || "SELECT LOAN FORM TYPE";
    controller.set('titleModalLoanForm', title);
    controller.set('selectedLoanType', null);
    controller.set('loanFormTypes', Ember['default'].A([{ id: 'loan-form', name: 'Basic' }, { id: 'ddt', name: 'DDT (Italy)' }]));

    controller.set('bookingToCreateLoanForm', controller.get('booking') || controller.get('bookingToCreateLoanForm'));

    Ember['default'].$('#select-new-loan-form-type').modal('show');
    Ember['default'].samplesToCheckout = samples || [];
  }

  function openAddTeamMemberModal(controller) {

    controller.set('bookalookTeamMembersLoading', false);

    var shUsers = controller.get('booking.owner.showroom.users');
    var booking = controller.get('booking.users');

    var shUsersParsed = Ember['default'].A();

    shUsers.forEach(function (user) {
      if (!booking.contains(user)) {
        //Add img src for show avatar in combo
        var safeString = user.get('name') + ' ' + user.get('surname');
        var img = "<img src='" + user.get('avatar') + "' class='avatar-select' />";
        Ember['default'].set(user, 'safeString', new Ember['default'].Handlebars.SafeString(img + safeString));

        shUsersParsed.push(user);
      }
    });

    shUsersParsed.sort(function (a, b) {
      if (a.get('name') < b.get('name')) return -1;
      if (a.get('name') > b.get('name')) return 1;
      return 0;
    });

    controller.set('teamMembersCanBeAdded', shUsersParsed);
    controller.set('addTeamMember', null);
    controller.set('bookalookTeamMembersLoading', "enabled");

    Ember['default'].$('#add-team-member-to-booking').modal('show');
  }

  function onCancelAddTeamMember() {
    Ember['default'].$('#add-team-member-to-booking').modal('hide');
  }

  function onSaveAddTeamMember(route, booking, memberToAdd) {
    Ember['default'].$('#saveAddTeamMember').button('loading');

    var bookingToSave = booking;

    route.send('openMessageModal', 'Adding team member to booking...');
    utils['default'].putAPIJSON('bookings/' + bookingToSave.get('id') + '/addTeamMember/' + memberToAdd.get('id')).then(function () {
      bookingToSave.get('users').addObject(memberToAdd);
      //route.send('openTopBar', memberToAdd.get('name') + ' has been added to the booking!');
      route.send('openPositiveTopBar');
      route.send('closeMessageModal');
      Ember['default'].$('#saveAddTeamMember').button('reset');
      Ember['default'].$('#add-team-member-to-booking').modal('hide');
    });
  }

  function onSaveLoanForm(route, type) {
    Ember['default'].$('#saveLoanFormButton').button('loading');
    var parcel = route.controller.get('parcel');
    var doTransition = parcel.get('isNew');

    if (type === 'ddt' || type === 'ddt-sample') {
      if (route.controller.get('saveVettori')) {
        Ember['default'].showroom.set('shippingCompanyAddress', parcel.get('shippingCompanyAddress'));
        Ember['default'].showroom.save();
      }
    }

    /*
    //Check the visibility of the checkbox and if it's checked to send an invitiation to join Bookalook
    if($('#sendInvitation').is(':visible') && $('#sendInvitation:checkbox:checked').length > 0){
      var email = route.controller.get('booking.requester.email');
      var name = route.controller.get('booking.requester.name');
      var company = Ember.user.get('company');
      var sender = Ember.user.get('fullName');
      utils.postAPIJSON('mandrill/sendMandrillEmail ',{to:email,toName:name,from:sender,company:company,subject:sender+' is preparing a booking for you on Bookalook',templateId:'mps-prospects-brand-is-preparing-your-samples'}).then(response => {
        if(response.data.status == 200){
          route.controller.set('responseTitle', 'Success');
        }else{
          route.controller.set('responseTitle','Error');
        }
        route.controller.set('error_log',response.data.message);
        Ember.$('#showResultModal').modal();
        Ember.openModal();
      });
    }*/
    var promise = parcel.save().then(function (newParcel) {
      Ember['default'].$('#saveLoanFormButton').button('reset');
      var promise2 = null;
      if (doTransition) {
        if (type === 'ddt-sample') {
          promise2 = route.transitionTo('ddt-samples', newParcel.get('id'));
        } else {
          promise2 = route.transitionTo('bookings.' + type, route.controller.get('booking.id'), newParcel.get('id'));
        }
      }
      if (type === 'ddt-sample') {
        route.controller.get('parcel').reload();
      } else {
        route.controller.get('booking').reload();
      }
      return promise2;
    });
    //utils.buttonLoading("#saveLoanFormButton", promise);
    return promise;
  }

  function onOpenContactAddressSelector(route) {
    Ember['default'].$('#contact-address-selector').modal();
    if (!route.controller.get('allContacts')) {
      utils['default'].getContactLists(route).then(function (lists) {
        var contactLists = lists.map(function (l) {
          return l.get('contacts');
        });
        var contacts = contactLists.reduce(function (prev, next) {
          prev.addObjects(next);
          return prev;
        }, Ember['default'].A());
        route.controller.set('allContacts', contacts.uniq());
      });
    }
  }

  function onTransitionLoanForm(route, transition) {
    var controller = route.controller;
    if (!controller.get('messageAccepted')) {
      if (controller.get('parcel.isDirty')) {
        transition.abort();
        utils['default'].openDefaultConfirmationModal(route, 'Caution', 'You have not saved the information.', 'If you leave this page the information will be lost. Do you wish to continue?').done(function () {
          controller.set('messageAccepted', true);
          controller.get('parcel').rollbackAttributes();
          transition.retry();
        });
      }
    } else {
      clearInterval(controller.get('paginationInterval'));
    }
  }

  function onDeleteBooking(route, booking) {
    route.send('openMessageModal', 'Closing Booking');

    booking.set('closed', true);
    booking.save().then(function () {
      route.send('closeMessageModal');
      updateBookingStatus(route);
    });
  }

  function onCancelBooking(route, booking, textOption) {
    var title = textOption === "cancel" ? "Cancel Booking" : "Delete booking";
    var action = textOption === "cancel" ? "cancel" : "delete";
    var msg = "";
    var doing = "";
    if (textOption === "delete") {
      msg = 'You are about to delete this booking. All data will be lost.';
      doing = "Deleting Booking. Please wait…";
    } else {
      msg = 'You are about to cancel this booking.';
      doing = "Cancelling Booking. Please wait…";
    }

    utils['default'].openDefaultConfirmationModal(route, title, msg, 'Do you want to proceed?', title, 'No').then(function () {
      route.send('openMessageModal', doing);
      if (action == "cancel") {
        booking.set('canceled', true);
        utils['default'].putAPIJSON('bookings/cancel/' + booking.get('id')).then(function () {
          route.refresh().then(function () {
            route.send('openPositiveTopBar');
            route.send('closeMessageModal', doing);
          });
        });
      } else {
        booking.set('deleted', true);
        booking.destroyRecord().then(function () {
          route.transitionTo('bookings.index').then(function () {
            route.send('openTopBar', 'Booking deleted');
            route.send('closeMessageModal');
          });
        });
      }
    });
  }

  function onCheckLookSamples(look) {
    var attr = arguments.length <= 1 || arguments[1] === undefined ? 'checked' : arguments[1];

    var samples = look.get('samples').filterBy('disabled', false);
    var allChecked = samples.isEvery(attr);
    samples.setEach(attr, !allChecked);
  }

  function compareAddresses(ad1, ad2) {
    return ad1.get('street') == ad2.get('street') && ad1.get('street1') == ad2.get('street1') && ad1.get('street2') == ad2.get('street2') && ad1.get('street3') == ad2.get('street3') && ad1.get('city') == ad2.get('city') && ad1.get('country') == ad2.get('country') && ad1.get('zipCode') == ad2.get('zipCode') && ad1.get('telephone') == ad2.get('telephone') && ad1.get('telephone1') == ad2.get('telephone1');

    //Sometimes fail (because null != undefined)
    return ad1.get('street') === ad2.get('street') && ad1.get('street2') === ad2.get('street2') && ad1.get('street3') === ad2.get('street3') && ad1.get('city') === ad2.get('city') && ad1.get('country') === ad2.get('country') && ad1.get('zipCode') === ad2.get('zipCode') && ad1.get('telephone') === ad2.get('telephone');
  }

  function updateBookingStatus(route) {
    utils['default'].getAPIJSON('bookings/status/' + route.controller.get('booking.id')).then(function (res) {
      var booking = route.controller.get('booking');
      booking.set('status', res.booking);
    });
  }

  function setRequesterComputedsForEditBooking(controller) {
    //controller.set('allContacts', []);
    controller.set('allContactsList', controller.get('lists'));

    Ember['default'].set(controller, 'mainAddressRequester', Ember['default'].computed('booking.requester', function () {
      var requester = controller.get('booking.requester');
      if (controller.get('isNotFirstMainAddressRequester') && requester && requester.get('mainAddress')) {

        var addr = requester.get('mainAddress');
        if (controller.get('fromAddressBook')) {
          controller.get('booking').setProperties({
            callInId: addr.get('id'),
            callInStreet1: addr.get('callInStreet1'),
            callInStreet2: addr.get('callInStreet2'),
            callInStreet3: addr.get('callInStreet3'),
            callInCountry: addr.get('callInStreet3'),
            callInCity: addr.get('city'),
            callInZipCode: addr.get('zipCode'),
            callInTelephone: addr.get('telephone1'),
            callInAttn: requester.get('fullName')
          });
        }

        controller.set('fromAddressBook', true);
        controller.set('fromAddressBookInverse', false);

        return requester.get('mainAddress');
      }
      controller.set('isNotFirstMainAddressRequester', true);
    }));

    showroomSelectorObserver(controller, 'comboShowroom', 'bookingToAdd');

    if (!controller.get('allShowrooms')) {
      controller.set('allShowrooms', []);
      Ember['default'].agency.get('showrooms').then(function (shs) {
        var showrooms = shs;
        var firstElementShowroom = Ember['default'].Object.create({ 'id': '0', 'city': 'New address' });
        controller.get('allShowrooms').addObject(firstElementShowroom);
        showrooms.forEach(function (showroom) {
          return controller.get('allShowrooms').addObject(showroom);
        });
      });
    }
  }

});