define('bookalook/adapters/application', ['exports', 'ember-data', 'bookalook/config/environment'], function (exports, DS, config) {

  'use strict';

  exports['default'] = DS['default'].RESTAdapter.extend({
    namespace: config['default'].APINamespace,
    host: config['default'].APIHost,
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    isInvalid: function isInvalid(status, params, data) {
      if (status === 400) {
        return new DS['default'].InvalidError(data.errors);
      } else {
        return data;
      }
    }
  });

});