define('bookalook/components/archive-process', ['exports', 'ember', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, utils, config) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    //entity
    //entityObj
    //route
    //modal
    //look=null
    //dummy -> for automatic update after open

    relatedBookings: [],
    inTransfer: false,
    reasonContent: [{ id: 'Lost' }, { id: 'Damaged' }, { id: 'Gift' }, { id: 'Return' }, { id: 'Other' }],
    reasonSelected: '',
    reasonDescription: '',
    isLoading: true,
    showReasonDescription: Ember['default'].computed('reasonSelected', 'entity', function () {
      if (this.get('entity') === "samples" || this.get('entity') === "looks" || this.get('entity') === 'samplesFromAlbumView' || this.get('entity') === 'ddt-samples') {
        return this.get('reasonSelected') === 'Gift' || this.get('reasonSelected') === 'Other';
      } else {
        return false;
      }
    }),
    archiveDisabled: Ember['default'].computed('reasonSelected', 'entity', function () {
      if (this.get('entity') === "samples" || this.get('entity') === "looks") {
        return this.get('reasonSelected') === null || this.get('reasonSelected') === '';
      } else {
        return false;
      }
    }),

    actions: {
      /*
      *  Open the modal and make a request in order to know if you can archive that element or not.
      *  @entity: element you want to archive. Can be collection, album, look or sample.
      * */
      archiveEntity: function archiveEntity() {
        var _this = this;

        var entity = this.get('entity');
        var entityObj = this.get('entityObj');
        var promise;
        var lookWillBeArchived = false;
        var albumWillBeArchived = false;
        var album = null;

        var btn = Ember['default'].$('#archive-modal-btn-archive');
        btn.button('loading');

        if (entity === 'looks') {
          //Check if album should be archived too (because all looks will be archived)
          album = entityObj.get('album');
          if (album.get('allLooksCount') == album.get('archivedLooksCount') + 1) {
            albumWillBeArchived = true;
          }
          promise = utils['default'].putAPIJSON(entity + '/' + entityObj.get('id') + '/archive', { look: { "archiveReason": this.get('reasonSelected'), "archiveDescription": this.get('reasonDescription') } });
        } else if (entity === 'samples') {
          //Check if look should be archived too (because all samples will be archived)
          var look = this.get('look');
          if (look.get('allSamplesCount') == look.get('archivedSamplesCount') + 1) {
            lookWillBeArchived = true;
            album = look.get('album');
            if (album.get('allLooksCount') == album.get('archivedLooksCount') + 1) {
              albumWillBeArchived = true;
            }
          }
          promise = utils['default'].putAPIJSON(entity + '/' + entityObj.get('id') + '/archive', { sample: { "archiveReason": this.get('reasonSelected'), "archiveDescription": this.get('reasonDescription') } });
        } else if (entity === 'albums') {
          promise = utils['default'].putAPIJSON(entity + '/' + entityObj.get('id') + '/archive');
        } else if (entity === 'samplesFromAlbumView') {
          (function () {

            var selectedSamples = [];
            var reasonSelected = _this.get('reasonSelected');

            Ember['default'].bulk.allSamples.forEach(function (sample) {
              if (sample.checked && sample.get('look').get('id') == entityObj.get('id')) {
                selectedSamples.push(sample.get('id'));
              }
            });

            var postData = {
              selectedSamples: selectedSamples,
              archiveReason: reasonSelected,
              archiveDescription: _this.get('reasonDescription')
            };
            promise = utils['default'].postAPIJSON('albums/archiveSelectedSamples', postData);
          })();
        } else if (entity === 'ddt-samples') {
          (function () {
            var reasonSelected = _this.get('reasonSelected');
            var selectedSamples = [];
            entityObj.forEach(function (sample) {
              if (sample.get('toCheckout')) {
                selectedSamples.push(sample.get('id'));
              }
            });
            var postData = {
              selectedSamples: selectedSamples,
              archiveReason: reasonSelected,
              archiveDescription: _this.get('reasonDescription')
            };
            promise = utils['default'].postAPIJSON('albums/archiveSelectedSamples', postData);
          })();
        } else {
          promise = utils['default'].putAPIJSON(entity + '/' + entityObj.get('id') + '/archive');
        }

        promise.then(function () {

          if (entity !== 'samplesFromAlbumView' && entity !== "ddt-samples") {
            entityObj.set('archived', true);
          }

          if (entity === 'albums') {
            var collection = _this.get('entityObj').get('collection');
            var albums = collection.get('activeAlbums');
            var activeLooks;
            if (albums.get('length') > 0) {
              activeLooks = albums.objectAt(0).get('activeLooks');
              if (activeLooks.get('length') > 0) {
                collection.set('avatar', activeLooks.objectAt(0).get('avatar'));
              } else {
                collection.set('avatar', config['default'].DEFAULT_IMAGE);
              }
            } else {
              collection.set('avatar', config['default'].DEFAULT_IMAGE);
            }
          }
          if (entity === 'samples') {
            //If all samples from look will be archived, archive look
            if (lookWillBeArchived) {
              (function () {
                var lookArchived = _this.get('look');
                var promiseArchiveLook = utils['default'].putAPIJSON('looks/' + _this.get('look').get('id') + '/archive', { look: { "archiveReason": _this.get('reasonSelected'), "archiveDescription": _this.get('reasonDescription') } });
                promiseArchiveLook.then(function () {
                  _this.get('look').reload().then(function () {
                    if (albumWillBeArchived) {
                      album.set('archived', true);
                      var promiseAlbum = utils['default'].putAPIJSON('albums/' + album.get('id') + '/archive');
                      promiseAlbum.then(function () {
                        _this.get('route').store.findRecord('album', lookArchived.get('album.id'), { reload: true });
                        var collection = album.get('collection');
                        var albums = collection.get('activeAlbums');
                        var activeLooks = undefined;
                        if (albums.get('length') > 0) {
                          activeLooks = albums.objectAt(0).get('activeLooks');
                          if (activeLooks.get('length') > 0) {
                            collection.set('avatar', activeLooks.objectAt(0).get('avatar'));
                          } else {
                            collection.set('avatar', config['default'].DEFAULT_IMAGE);
                          }
                        } else {
                          collection.set('avatar', config['default'].DEFAULT_IMAGE);
                        }
                        Ember['default'].$("#sample-card-" + entityObj.get("id")).fadeOut('fast', function () {
                          _this.get('look').notifyPropertyChange('samples');
                          _this.get('look').set('archived', true);
                          _this.get('look').notifyPropertyChange('archived');
                        });
                        album.notifyPropertyChange('looks');
                      });
                    } else {
                      Ember['default'].$("#sample-card-" + entityObj.get("id")).fadeOut('fast', function () {
                        _this.get('look').notifyPropertyChange('samples');
                        _this.get('look').set('archived', true);
                        _this.get('look').notifyPropertyChange('archived');
                      });
                    }
                  });
                });
              })();
            } else {
              _this.get('look').notifyPropertyChange('samples');
              Ember['default'].$("#sample-card-" + entityObj.get("id")).fadeOut('fast', function () {
                _this.get('look').notifyPropertyChange('samples');
              });
            }
          }
          if (entity === 'looks' && albumWillBeArchived) {
            album.set('archived', true);
            var promiseAlbum = utils['default'].putAPIJSON('albums/' + album.get('id') + '/archive');
            promiseAlbum.then(function () {
              var collection = album.get('collection');
              var albums = collection.get('activeAlbums');
              var activeLooks = undefined;
              if (albums.get('length') > 0) {
                activeLooks = albums.objectAt(0).get('activeLooks');
                if (activeLooks.get('length') > 0) {
                  collection.set('avatar', activeLooks.objectAt(0).get('avatar'));
                } else {
                  collection.set('avatar', config['default'].DEFAULT_IMAGE);
                }
              } else {
                collection.set('avatar', config['default'].DEFAULT_IMAGE);
              }
              album.notifyPropertyChange('looks');
              _this.get('route').store.findRecord('album', entityObj.get('album.id'), { reload: true });
            });
          }
          //After archive sample, check if look has been archived too
          if (lookWillBeArchived) {
            _this.get('look').reload();
            if (albumWillBeArchived) {
              _this.get('route').send('openTopBar', 'The look and his album was archived because all look\'s samples has been archived and all album\'s looks were archived.');
            } else {
              _this.get('route').send('openTopBar', 'The look was archived because all samples has been archived.');
            }
          } else if (albumWillBeArchived) {
            //entity check should not be necessary
            if (entity === 'looks') {
              _this.get('route').send('openMessageModal', 'Loading data');
              _this.get('route').store.findRecord('album', entityObj.get('album.id'), { reload: true }).then(function () {
                _this.get('route').send('openTopBar', 'The album was archived because all looks has been archived.');
                _this.get('route').send('closeMessageModal');
              });
            }
          } else {
            if (entity === 'looks') {
              _this.get('route').send('archiveLook', entityObj, true);
            } else if (entity !== "ddt-samples") {
              _this.get('route').send('openTopBar', 'The ' + _this.get('singularEntityName') + ' was archived correctly.');
            }
          }
          if (entity === 'looks') {
            _this.onCancelArchiveEntity();
          } else if (entity === "ddt-samples") {
            Ember['default'].$('#archive-confirmation').modal('hide');
            utils['default'].openDefaultConfirmationModal(_this.get('route'), '<strong>Create</strong> Sample DDT', 'Do you want to create a DDT?', '', 'Yes', 'No').then(function () {
              Ember['default'].samplesToCheckout = entityObj || [];
              _this.get('route').transitionTo('ddt-samples', 'new');
            });
            _this.get('route').send('openTopBar', 'The samples were archived correctly.');
          } else {
            entityObj.notifyPropertyChange('archived');
            entityObj.reload().then(function () {
              if (lookWillBeArchived) {
                Ember['default'].$('#archive-confirmation').modal('hide');
                _this.set('reasonSelected', '');
                var btnReset = Ember['default'].$('#archive-modal-btn-archive');
                btnReset.button('reset');
              } else {
                _this.onCancelArchiveEntity();
              }
            });
          }
        }, function () {
          _this.get('route').send('openTopBar', 'There was an error archiving the ' + _this.get('singularEntityName') + '.');
          if (lookWillBeArchived) {
            Ember['default'].$('#archive-confirmation').modal('hide');
            _this.set('reasonSelected', '');
            var btnReset = Ember['default'].$('#archive-modal-btn-archive');
            btnReset.button('reset');
          } else {
            _this.onCancelArchiveEntity();
          }
        });
      },

      cancelArchiveEntity: function cancelArchiveEntity() {
        this.onCancelArchiveEntity();
      },

      goToBookingFromArchive: function goToBookingFromArchive(bookingID) {
        this.get('route').transitionTo('bookings.shopping-list', bookingID);
        Ember['default'].$('#archive-confirmation').modal('hide');
      },
      goToInventoryFromArchive: function goToInventoryFromArchive() {
        var extra = function extra() {
          Ember['default'].$('#archive-confirmation').modal('hide');
        };
        utils['default'].goToInventoryWithFilterStatus(this, "IN TRANSFER", extra);
      },
      getRelatedBookingsReport: function getRelatedBookingsReport() {
        this.onGetRelatedBookingsReport();
      }
    },

    onCancelArchiveEntity: function onCancelArchiveEntity() {
      var btnReset = Ember['default'].$('#archive-modal-btn-archive');
      btnReset.button('reset');
      Ember['default'].$('#archive-confirmation').modal('hide');
      if (this.get('modal')) {
        Ember['default'].$(this.get('modal')).modal('show');
        Ember['default'].openModal();
      }
      this.set('reasonSelected', '');
    },

    changeEntityObserver: (function () {
      this.set('isLoading', true);
      Ember['default'].run.once(this, 'changeEntity');
    }).observes('entity', 'entityObj', 'dummy'),

    changeEntity: function changeEntity() {
      var _this2 = this;

      var entity = this.get('entity');
      var entityObj = this.get('entityObj');
      //Caso: archivar samples desde vista Album
      if (entity === 'samplesFromAlbumView') {
        (function () {

          var selectedSamples = [];

          Ember['default'].bulk.allSamples.forEach(function (sample) {
            if (sample.checked && sample.get('look').get('id') == entityObj.get('id')) {
              selectedSamples.push(sample.get('id'));
            }
          });

          var postData = {
            selectedSamples: selectedSamples
          };

          utils['default'].postAPIJSON('albums/archivableSamples', postData).then(function (res) {
            _this2.set('relatedBookings', res.bookings.sortBy('id'));
            _this2.set('inTransfer', res.inTransfer);
            _this2.set('isLoading', false);
          });
        })();
      } else if (entity === 'ddt-samples') {
        (function () {
          var selectedSamples = [];
          entityObj.forEach(function (sample) {
            if (sample.get('toCheckout')) {
              selectedSamples.push(sample.get('id'));
            }
          });
          var postData = {
            selectedSamples: selectedSamples
          };
          utils['default'].postAPIJSON('albums/archivableSamples', postData).then(function (res) {
            _this2.set('relatedBookings', res.bookings.sortBy('id'));
            _this2.set('inTransfer', res.inTransfer);
            _this2.set('isLoading', false);
          });
        })();
      } else {

        utils['default'].getAPIJSON(this.get('entity') + '/' + this.get('entityObj.id') + '/archivable').then(function (res) {
          _this2.set('relatedBookings', res.bookings.sortBy('id'));
          _this2.set('inTransfer', res.inTransfer);
          _this2.set('isLoading', false);
        });
      }
    },

    onGetRelatedBookingsReport: function onGetRelatedBookingsReport() {
      var options = [];

      switch (this.get('entity')) {
        case 'samples':
          options.push('sample=' + this.get('entityObj.id'));
          break;
        case 'looks':
          options.push('look=' + this.get('entityObj.id'));
          break;
        case 'albums':
          //options.push('collection=' + this.get('entityObj.collection.id'));
          options.push('album=' + this.get('entityObj.id'));
          break;
        case 'collections':
          options.push('collection=' + this.get('entityObj.id'));
          break;
      }

      window.open(config['default'].APIURL + 'report?' + options.join('&'), '_blank' // <- This is what makes it open in a new window.
      );
    },

    singularEntityName: (function () {
      var entityName = this.get('entity');

      if (entityName === 'samplesFromAlbumView' || entityName === 'ddt-samples') {
        return 'selected sample(s)';
      } else {
        return entityName.substr(0, entityName.length - 1);
      }
    }).property('entity')
  });

});