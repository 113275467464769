define('bookalook/templates/contacts-lists/modals/new-contact', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 8
            },
            "end": {
              "line": 68,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/modals/new-contact.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","clearfix");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","color_primary show text-uppercase");
          var el3 = dom.createTextNode("Address ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
          morphs[1] = dom.createMorphAt(element0,3,3);
          morphs[2] = dom.createMorphAt(element0,5,5);
          morphs[3] = dom.createMorphAt(element0,7,7);
          morphs[4] = dom.createMorphAt(element0,9,9);
          morphs[5] = dom.createMorphAt(element0,11,11);
          morphs[6] = dom.createMorphAt(element0,13,13);
          morphs[7] = dom.createMorphAt(element0,15,15);
          morphs[8] = dom.createMorphAt(element0,17,17);
          return morphs;
        },
        statements: [
          ["inline","plus-one",[["get","index",["loc",[null,[50,74],[50,79]]]]],[],["loc",[null,[50,63],[50,81]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[51,34],[51,41]]]]],[],[]],"property","street1","label","Street address","class","no-padding-left col-xs-6"],["loc",[null,[51,10],[51,118]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[52,34],[52,41]]]]],[],[]],"property","street2","label","Apt/floor/suite etc...","class","no-padding-right col-xs-6"],["loc",[null,[52,10],[52,127]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[53,34],[53,41]]]]],[],[]],"property","street3","label","Company/building","class","no-padding-left col-xs-6"],["loc",[null,[53,10],[53,120]]]],
          ["inline","validated-select",[],["placeholder","Country","content",["subexpr","@mut",[["get","Ember.countries",["loc",[null,[56,32],[56,47]]]]],[],[]],"model",["subexpr","@mut",[["get","address",["loc",[null,[57,32],[57,39]]]]],[],[]],"property","country","class","col-xs-6 no-padding-right","optionValuePath","name","optionLabelPath","name"],["loc",[null,[54,10],[62,12]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[63,34],[63,41]]]]],[],[]],"property","city","label","City","class","no-padding-left col-xs-6"],["loc",[null,[63,10],[63,105]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[64,34],[64,41]]]]],[],[]],"property","zipCode","label","Postcode","class","no-padding-right col-xs-6"],["loc",[null,[64,10],[64,113]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[65,34],[65,41]]]]],[],[]],"property","telephone1","label","Phone number 1","class","no-padding-left col-xs-6"],["loc",[null,[65,10],[65,121]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[66,34],[66,41]]]]],[],[]],"property","telephone2","label","Phone number 2","class","no-padding-right col-xs-6"],["loc",[null,[66,10],[66,122]]]]
        ],
        locals: ["address","index"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 105,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/contacts-lists/modals/new-contact.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New contact ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","new-contact");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        dom.setAttribute(el3,"id","test_modal_contact_manually");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title");
        var el6 = dom.createTextNode("New contact");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Personal data");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Legal Infos");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Main Address");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h5");
        var el7 = dom.createTextNode("Additional addresses");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" ADD ANOTHER ADDRESS ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("i");
        dom.setAttribute(el7,"class","glyphicon glyphicon-plus-sign color_primary");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        var el8 = dom.createTextNode("Add another address");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" PRIVATE NOTE ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary");
        var el8 = dom.createTextNode("Private Note:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" ADD TO CONTACT LIST ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-12 no-padding");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","color_primary");
        var el9 = dom.createTextNode("Add to contact list(s)*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","test_list_contacts_div");
        dom.setAttribute(el7,"class","form-group validated-select col-xs-6 no-padding");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_cancel");
        dom.setAttribute(el5,"data-dismiss","modal");
        dom.setAttribute(el5,"data-target","#add-contact");
        dom.setAttribute(el5,"data-toggle","modal");
        var el6 = dom.createTextNode("Cancel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"data-toggle","modal");
        dom.setAttribute(el5,"class","btn_primary");
        var el6 = dom.createTextNode("Create");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2, 1, 1]);
        var element2 = dom.childAt(element1, [3, 1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [5]);
        var element5 = dom.childAt(element2, [13, 3]);
        var element6 = dom.childAt(element1, [5]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [3]);
        var morphs = new Array(22);
        morphs[0] = dom.createMorphAt(element3,3,3);
        morphs[1] = dom.createMorphAt(element3,5,5);
        morphs[2] = dom.createMorphAt(element3,7,7);
        morphs[3] = dom.createMorphAt(element3,9,9);
        morphs[4] = dom.createMorphAt(element3,11,11);
        morphs[5] = dom.createMorphAt(element3,13,13);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [3]),3,3);
        morphs[7] = dom.createMorphAt(element4,3,3);
        morphs[8] = dom.createMorphAt(element4,5,5);
        morphs[9] = dom.createMorphAt(element4,7,7);
        morphs[10] = dom.createMorphAt(element4,9,9);
        morphs[11] = dom.createMorphAt(element4,11,11);
        morphs[12] = dom.createMorphAt(element4,13,13);
        morphs[13] = dom.createMorphAt(element4,15,15);
        morphs[14] = dom.createMorphAt(element4,17,17);
        morphs[15] = dom.createMorphAt(element2,9,9);
        morphs[16] = dom.createElementMorph(element5);
        morphs[17] = dom.createMorphAt(dom.childAt(element2, [17]),3,3);
        morphs[18] = dom.createMorphAt(dom.childAt(element2, [21, 3]),1,1);
        morphs[19] = dom.createElementMorph(element7);
        morphs[20] = dom.createAttrMorph(element8, 'disabled');
        morphs[21] = dom.createElementMorph(element8);
        return morphs;
      },
      statements: [
        ["inline","validated-input",[],["label","Name*","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[13,54],[13,66]]]]],[],[]],"property","name","class","hint-left no-padding-left col-xs-6"],["loc",[null,[13,10],[13,127]]]],
        ["inline","validated-input",[],["label","Surname","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[14,53],[14,65]]]]],[],[]],"property","surname","class","hint-right no-padding-right col-xs-6"],["loc",[null,[14,10],[14,131]]]],
        ["inline","validated-input",[],["label","Job title","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[15,53],[15,65]]]]],[],[]],"property","jobTitle","class","hint-left no-padding-left col-xs-6"],["loc",[null,[15,10],[15,130]]]],
        ["inline","validated-input",[],["label","Publication/Company","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[16,62],[16,74]]]]],[],[]],"property","company","class","hint-right no-padding-right col-xs-6"],["loc",[null,[16,10],[16,140]]]],
        ["inline","validated-input",[],["label","Email","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[17,53],[17,65]]]]],[],[]],"property","email","class","hint-left no-padding-left col-xs-6"],["loc",[null,[17,10],[17,127]]]],
        ["inline","validated-input",[],["label","Email 2","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[18,54],[18,66]]]]],[],[]],"property","email2","class","hint-right no-padding-right col-xs-6"],["loc",[null,[18,10],[18,131]]]],
        ["inline","validated-input",[],["label","VAT","model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[23,52],[23,64]]]]],[],[]],"property","vat","class","hint-left no-padding-left col-xs-6"],["loc",[null,[23,10],[23,124]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[28,34],[28,53]]]]],[],[]],"property","street1","label","Street address","class","no-padding-left col-xs-6"],["loc",[null,[28,10],[28,130]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[29,34],[29,53]]]]],[],[]],"property","street2","label","Apt/floor/suite etc...","class","no-padding-right col-xs-6"],["loc",[null,[29,10],[29,139]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[30,34],[30,53]]]]],[],[]],"property","street3","label","Company/building","class","no-padding-left col-xs-6"],["loc",[null,[30,10],[30,132]]]],
        ["inline","validated-select",[],["placeholder","Country","content",["subexpr","@mut",[["get","Ember.countries",["loc",[null,[33,32],[33,47]]]]],[],[]],"model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[34,32],[34,51]]]]],[],[]],"property","country","class","col-xs-6 hint-right no-padding-right","optionValuePath","name","optionLabelPath","name"],["loc",[null,[31,10],[39,12]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[40,34],[40,53]]]]],[],[]],"property","city","label","City","class","no-padding-left col-xs-6"],["loc",[null,[40,10],[40,117]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[41,34],[41,53]]]]],[],[]],"property","zipCode","label","Postcode","class","no-padding-right col-xs-6"],["loc",[null,[41,10],[41,125]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[42,34],[42,53]]]]],[],[]],"property","telephone1","label","Phone number 1","class","no-padding-left col-xs-6"],["loc",[null,[42,10],[42,133]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[43,34],[43,53]]]]],[],[]],"property","telephone2","label","Phone number 2","class","no-padding-right col-xs-6"],["loc",[null,[43,10],[43,134]]]],
        ["block","each",[["get","contactToAdd.privateAddresses",["loc",[null,[48,16],[48,45]]]]],[],0,null,["loc",[null,[48,8],[68,17]]]],
        ["element","action",["addAnotherAddress"],[],["loc",[null,[72,13],[72,45]]]],
        ["inline","textarea",[],["placeholder","Optional and only visible for you","value",["subexpr","@mut",[["get","contactToAdd.privateNotes",["loc",[null,[78,75],[78,100]]]]],[],[]],"cols","80","rows","4","class","col-xs-12"],["loc",[null,[78,10],[78,139]]]],
        ["inline","multiple-select",[],["list",["subexpr","@mut",[["get","addContactSelectedLists",["loc",[null,[88,19],[88,42]]]]],[],[]],"content",["subexpr","@mut",[["get","contactLists",["loc",[null,[89,22],[89,34]]]]],[],[]],"placeholder","Select Lists","optionLabelPath","name"],["loc",[null,[87,12],[92,14]]]],
        ["element","action",["cancelEdition",["get","contactToAdd",["loc",[null,[99,56],[99,68]]]]],[],["loc",[null,[99,30],[99,71]]]],
        ["attribute","disabled",["get","createNewContactDisabled",["loc",[null,[100,71],[100,95]]]]],
        ["element","action",["saveNewContact"],[],["loc",[null,[100,30],[100,59]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});