define('bookalook/helpers/capitalize', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return '';
    }
    return params[0].capitalize();
  });

});