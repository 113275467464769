define('bookalook/routes/check-out/functions', ['exports', 'ember', 'bookalook/utils', 'bookalook/routes/bookings/functions'], function (exports, Ember, utils, bookingFunctions) {

  'use strict';

  exports.onRemoveSample = onRemoveSample;
  exports.onOpenCheckOutModal = onOpenCheckOutModal;
  exports.onOpenCheckOutModalBrandRefactor = onOpenCheckOutModalBrandRefactor;
  exports.onCheckoutSamples = onCheckoutSamples;
  exports.onCheckInSample = onCheckInSample;
  exports.onOpenCheckInModal = onOpenCheckInModal;
  exports.onOpenCheckInModalBrandRefactor = onOpenCheckInModalBrandRefactor;
  exports.onConfirmSample = onConfirmSample;
  exports.confirmingSample = confirmingSample;
  exports.onAcceptSample = onAcceptSample;
  exports.acceptingSample = acceptingSample;
  exports.onDeclineSample = onDeclineSample;
  exports.decliningSample = decliningSample;
  exports.onCancelAndRemoveSample = onCancelAndRemoveSample;
  exports.cancelAndRemoveSamplePromise = cancelAndRemoveSamplePromise;
  exports.onCheckCheckoutSample = onCheckCheckoutSample;
  exports.onCheckCheckoutLook = onCheckCheckoutLook;
  exports.onCheckCheckoutLookVisibleSamples = onCheckCheckoutLookVisibleSamples;
  exports.onToggleChecksToCheckoutInBooking = onToggleChecksToCheckoutInBooking;
  exports.updateBookingStatus = updateBookingStatus;

  function onRemoveSample(route, sample) {
    var thisBooking = route.controller.get('booking');
    var bookingSampleStatuses = thisBooking.get('sampleStatus');
    var sampleSampleStatus = bookingSampleStatuses.filter(function (item) {
      var itemSample = item.get('sample');
      return itemSample.get('id') === sample.id;
    });
    if (sampleSampleStatus.length > 0) {
      sampleSampleStatus[0].destroyRecord();
    }
    thisBooking.get('samples').removeObject(sample);

    updateBookingStatus(route);
  }

  function onOpenCheckOutModal(route, sample, inventory) {
    inventory = inventory || '';
    Ember['default'].bulk.clearSamples();
    booking.samplesCheckedToCheckout.clearSamples();

    var booking = sample.get('booking' + inventory);
    route.controller.set('bookingToCheckout', booking);

    booking.get('job');
    booking.get('samples').forEach(function (bookingSample) {

      // TODO Move to sample model?
      // Shopping list conditions
      if (!inventory && (bookingSample.get('shoppingListInBooking') || bookingSample.get('isCanceled') || !bookingSample.get('isConfirmed'))) {
        return;
      }

      // Inventory conditions
      if (inventory && !Ember['default'].isNone(bookingSample.get('bookingInventoryId')) && bookingSample.get('bookingInventoryId') !== parseInt(booking.get('id'), 10)) {
        return;
      }
      if (inventory) {
        bookingSample.set('bookingInventory', booking);
      }

      if (!bookingSample.get('sampleStatus' + inventory + '.isConfirmed' + inventory)) {
        return;
      }

      bookingSample.set('toCheckout', false);
      bookingSample.set('checked', true);
    });
    sample.set('checked', false);
    route.controller.set('sampleToCheck', sample);
    route.controller.set('look', sample.get('look'));
    var container = '#checkout-step1';
    utils['default'].lazyLoad(3000, container);

    Ember['default'].$('#checkout-step1').modal();
  }

  function onOpenCheckOutModalBrandRefactor(route, sample, inventory) {
    inventory = inventory || '';
    Ember['default'].bulk.clearSamples();

    var booking = sample.get('booking' + inventory);
    route.controller.set('bookingToCheckout', booking);

    booking.get('job');
    booking.get('samples').forEach(function (bookingSample) {

      // TODO Move to sample model?
      // Shopping list conditions
      if (!inventory && (bookingSample.get('shoppingListInBooking') || bookingSample.get('isCanceled') || !bookingSample.get('isConfirmed'))) {
        return;
      }

      // Inventory conditions
      if (inventory && !Ember['default'].isNone(bookingSample.get('bookingInventoryId')) && bookingSample.get('bookingInventoryId') !== parseInt(booking.get('id'), 10)) {
        return;
      }
      if (inventory) {
        bookingSample.set('bookingInventory', booking);
      }

      if (!bookingSample.get('sampleStatus' + inventory + '.isConfirmed' + inventory)) {
        return;
      }

      bookingSample.set('toCheckout', false);
      bookingSample.set('checked', true);
    });
    sample.set('checked', true);
    sample.set('toCheckout', true);
    route.controller.set('sampleToCheck', sample);
    route.controller.set('look', sample.get('look'));
    var container = '#checkout-step1-brand-refactor';
    utils['default'].lazyLoad(3000, container);

    Ember['default'].$('#checkout-step1-brand-refactor').modal();
  }

  function onCheckoutSamples(route, sample, inventory) {
    route.send('openMessageModal', 'Doing check-out. Please Wait.');
    inventory = inventory || '';

    var booking = sample.get('booking' + inventory);
    var samplesChecked = booking.get('samples').filter(function (bookingSample) {
      return bookingSample.get('toCheckout');
    });

    //Sample could be deselected
    //samplesChecked.addObject(sample);

    //Ember.bulk.clearSamples();

    //Show errors if checkout fails!
    var promise = utils['default'].postAPIJSON('sampleStatus/checkout', { booking: booking.get('id'), samples: samplesChecked.mapBy('id') });

    promise.then(function (newStatuses) {
      //success
      utils['default'].pushArrayPayload(route.store, 'sampleStatus', newStatuses.sampleStatus);

      if (inventory) {
        Ember['default'].bulk.clearSamples();
        route.controller.notifyPropertyChange('dateFilter');
      } else {
        route.controller.get('booking').resetSampleStatusLabels().then(function () {
          route.onResetSamples();
          updateBookingStatus(route);

          route.controller.set('noRefreshStatuses', false);
        });
      }

      route.controller.set('bookingToCreateLoanForm', sample.get('booking' + inventory));
      bookingFunctions['default'].openLoanFormTypeModal(route.controller, "Do you want to create a new Loan Form?", samplesChecked);
      route.send('closeMessageModal');
    }, function (res) {
      //fail
      var errors = '';
      if (res && res.responseText) {
        errors = JSON.parse(res.responseText).errors;
      }

      route.send('closeMessageModal');
      route.send('openTopBar', 'Ooops! ' + errors);

      if (inventory) {
        route.controller.dateFilterFunction();
        route.onResetSamples(); //Clean selected samples!!!
      } else {
          route.controller.get('booking').resetSampleStatusLabels().then(function () {
            route.onResetSamples();
            updateBookingStatus(route);

            route.controller.set('noRefreshStatuses', false);
            //route.controller.get('booking').reload();
            route.store.findRecord('booking', route.controller.get('booking').get('id'), { reload: true });
          });
        }
    });
  }

  function onCheckInSample(route, sample, inventory) {
    route.send('openMessageModal', 'Doing check-in. Please Wait.');
    inventory = inventory || '';

    var booking = sample.get('booking' + inventory);
    var samplesChecked = booking.get('samples').filter(function (bookingSample) {
      return bookingSample.get('toCheckout');
    });

    //Sample could be deselected
    //samplesChecked.addObject(sample);

    //Try to checkin!

    var promise = utils['default'].postAPIJSON('sampleStatus/checkin', { booking: booking.get('id'), samples: samplesChecked.mapBy('id') });

    promise.then(function (newStatuses) {
      //success
      utils['default'].pushArrayPayload(route.store, 'sampleStatus', newStatuses.sampleStatus);

      if (inventory) {
        Ember['default'].bulk.clearSamples();
        route.controller.notifyPropertyChange('dateFilter');
      } else {
        route.controller.get('booking').resetSampleStatusLabels().then(function () {
          route.onResetSamples();
          updateBookingStatus(route);

          route.controller.set('noRefreshStatuses', false);
        });
      }

      route.send('closeMessageModal');
    }, function (res) {
      //fail
      var errors = '';
      if (res && res.responseText) {
        errors = JSON.parse(res.responseText).errors;
      }
      route.send('closeMessageModal');
      route.send('openTopBar', 'Ooops! ' + errors);

      if (inventory) {
        route.controller.dateFilterFunction();
        route.onResetSamples(); //Clean selected samples!!!
      } else {
          route.controller.get('booking').resetSampleStatusLabels().then(function () {
            route.onResetSamples();
            updateBookingStatus(route);

            route.controller.set('noRefreshStatuses', false);
            route.store.findRecord('booking', route.controller.get('booking').get('id'), { reload: true });
          });
        }
    });
  }

  function onOpenCheckInModal(route, sample, inventory) {
    inventory = inventory || '';
    Ember['default'].bulk.clearSamples();
    var booking = sample.get('booking' + inventory);
    booking.samplesCheckedToCheckout.clearSamples();

    route.controller.set('bookingToCheckout', booking);

    booking.get('job');
    booking.get('samples').forEach(function (bookingSample) {

      // TODO Move to sample model?
      // Shopping list conditions
      if (!inventory && bookingSample.get('shoppingListInBooking')) {
        return;
      }

      // Inventory conditions
      if (inventory && !Ember['default'].isNone(bookingSample.get('bookingInventoryId')) && bookingSample.get('bookingInventoryId') !== parseInt(booking.get('id'), 10)) {
        return;
      }
      if (inventory) {
        bookingSample.set('bookingInventory', booking);
      }
      if (!bookingSample.get('sampleStatus' + inventory + '.isOut' + inventory)) {
        return;
      }

      bookingSample.set('toCheckout', false);
      bookingSample.set('checked', bookingSample.get('showroom.id') === Ember['default'].showroom.get('id'));
    });

    sample.set('checked', false);
    sample.set('toCheckout', true);
    route.controller.set('sampleToCheck', sample);
    route.controller.set('look', sample.get('look'));
    var container = '#check-in';
    utils['default'].lazyLoad(3000, container);
    Ember['default'].$('#check-in').modal();
  }

  function onOpenCheckInModalBrandRefactor(route, sample, inventory) {
    inventory = inventory || '';
    Ember['default'].bulk.clearSamples();

    var booking = sample.get('booking' + inventory);
    route.controller.set('bookingToCheckout', booking);
    booking.get('job');
    booking.get('samples').forEach(function (bookingSample) {
      // TODO Move to sample model?
      // Shopping list conditions
      if (!inventory && bookingSample.get('shoppingListInBooking')) {
        return;
      }

      // Inventory conditions
      if (inventory && !Ember['default'].isNone(bookingSample.get('bookingInventoryId')) && bookingSample.get('bookingInventoryId') !== parseInt(booking.get('id'), 10)) {
        return;
      }
      if (inventory) {
        bookingSample.set('bookingInventory', booking);
      }
      if (!bookingSample.get('sampleStatus' + inventory + '.isOut' + inventory)) {
        return;
      }

      bookingSample.set('toCheckout', false);
      bookingSample.set('checked', bookingSample.get('showroom.id') === Ember['default'].showroom.get('id'));
    });
    sample.set('checked', true);
    sample.set('toCheckout', true);
    route.controller.set('sampleToCheck', sample);
    route.controller.set('look', sample.get('look'));
    var container = '#checkin-brand-refactor';
    utils['default'].lazyLoad(3000, container);

    Ember['default'].$('#checkin-brand-refactor').modal();
  }

  function onConfirmSample(route, sample) {
    var promise = this.confirmingSample(route, sample);

    utils['default'].buttonLoading('#sample-confirm-' + sample.get('id'), promise);
  }

  function confirmingSample(route, sample) {
    return utils['default'].postAPIJSON('sampleStatus/confirm', { booking: route.controller.get('booking.id'), sample: sample.get('id') }).then(function (res) {
      route.controller.get('booking').resetSampleStatusLabels().then(function () {
        sample.set('toCheckout', true);
        sample.set('isConfirmed', true);
        utils['default'].pushPayload(route.get('store'), 'sample-status', res.sampleStatus);
        updateBookingStatus(route);
      });
    }, function (res) {
      //fail
      var errors = '';
      if (res && res.responseText) {
        errors = JSON.parse(res.responseText).errors;
      }
      route.send('openTopBar', 'Ooops! ' + errors);

      route.controller.get('booking').resetSampleStatusLabels().then(function () {
        route.onResetSamples();
        updateBookingStatus(route);

        route.store.findRecord('booking', route.controller.get('booking').get('id'), { reload: true });
      });
    });
  }

  function onAcceptSample(route, sample) {
    var promise = this.acceptingSample(route, sample);

    utils['default'].buttonLoading('#sample-confirm-' + sample.get('id'), promise);
  }

  function acceptingSample(route, sample) {
    return utils['default'].postAPIJSON('sampleStatus/accept', { booking: route.controller.get('booking.id'), sample: sample.get('id') }).then(function (res) {
      route.controller.get('booking').resetSampleStatusLabels().then(function () {
        sample.set('toCheckout', true);
        sample.set('isConfirmed', true);
        utils['default'].pushPayload(route.get('store'), 'sample-status', res.sampleStatus);
        updateBookingStatus(route);
      });
    }, function (res) {
      //fail
      var errors = '';
      if (res && res.responseText) {
        errors = JSON.parse(res.responseText).errors;
      }
      route.send('openTopBar', 'Ooops! ' + errors);

      route.controller.get('booking').resetSampleStatusLabels().then(function () {
        route.onResetSamples();
        updateBookingStatus(route);

        route.store.findRecord('booking', route.controller.get('booking').get('id'), { reload: true });
      });
    });
  }

  function onDeclineSample(route, sample) {
    var promise = this.decliningSample(route, sample);

    utils['default'].buttonLoading('#sample-decline-' + sample.get('id'), promise);
  }

  function decliningSample(route, sample) {
    return utils['default'].postAPIJSON('sampleStatus/cancel', { booking: route.controller.get('booking.id'), sample: sample.get('id') }).then(function () {
      route.controller.get('booking').resetSampleStatusLabels();
      updateBookingStatus(route);
    }, function (res) {
      //fail
      var errors = '';
      if (res && res.responseText) {
        errors = JSON.parse(res.responseText).errors;
      }
      route.send('openTopBar', 'Ooops! ' + errors);

      route.controller.get('booking').resetSampleStatusLabels().then(function () {
        route.onResetSamples();
        updateBookingStatus(route);

        route.store.findRecord('booking', route.controller.get('booking').get('id'), { reload: true });
      });
    });
  }

  function onCancelAndRemoveSample(route, sample) {
    var promise = this.cancelAndRemoveSamplePromise(route, sample);
    utils['default'].buttonLoading('#sample-remove-' + sample.get('id'), promise);
    promise.then(function () {
      route.controller.get('booking').resetSampleStatusLabels();
      updateBookingStatus(route);
    }, function () {
      route.controller.get('booking').resetSampleStatusLabels();
      updateBookingStatus(route);
    });
  }

  function cancelAndRemoveSamplePromise(route, sample) {
    return utils['default'].postAPIJSON('sampleStatus/cancelAndRemove', { booking: route.controller.get('booking.id'), sample: sample.get('id') });
  }

  function onCheckCheckoutSample(sample) {
    sample.set('toCheckout', !sample.get('toCheckout'));
  }

  function onCheckCheckoutLook(look) {
    var check = true;
    if (look.get('allSamplesAreGoingToCheckout')) {
      check = false;
    } else {
      check = true;
    }
    var groupedSamples = look.get('groupedSamples');
    for (var i = 0; i < groupedSamples.length; i++) {
      var samples = groupedSamples[i]['all'];
      for (var x = 0; x < samples.length; x++) {
        if (samples[x].get('checked')) {
          samples[x].set('toCheckout', check);
        }
      }
    }
  }

  function onCheckCheckoutLookVisibleSamples(look) {
    var check = true;
    if (look.get('allVisibleSamplesAreGoingToCheckout')) {
      check = false;
    } else {
      check = true;
    }
    var groupedSamples = look.get('groupedSamples');
    for (var i = 0; i < groupedSamples.length; i++) {
      var samples = groupedSamples[i]['all'];
      for (var x = 0; x < samples.length; x++) {
        if (samples[x].get('checked') && samples[x].get('visible')) {
          samples[x].set('toCheckout', check);
        }
      }
    }
  }

  function onToggleChecksToCheckoutInBooking(booking) {
    var samplesChecked = booking.get('samples').filter(function (s) {
      return s.get('checked');
    });
    if (samplesChecked.some(function (s) {
      return !s.get('toCheckout');
    })) {
      samplesChecked.map(function (s) {
        return s.set('toCheckout', true);
      });
    } else {
      samplesChecked.map(function (s) {
        return s.set('toCheckout', false);
      });
    }
  }

  function updateBookingStatus(route) {
    utils['default'].getAPIJSON('bookings/status/' + route.controller.get('booking.id')).then(function (res) {
      var booking = route.controller.get('booking');
      booking.set('status', res.booking);
    });
  }

});