define('bookalook/models/areas-available', ['exports', 'ember-data', 'bookalook/utils', 'bookalook/mixins/model-validator'], function (exports, DS, utils, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    countries: DS['default'].hasMany('country', { async: true, inverse: null }),
    brand: DS['default'].belongsTo('brand', { async: true }),
    showroom: DS['default'].belongsTo('showroom', { async: true }),
    user: DS['default'].belongsTo('user', { async: true }),

    countriesCollapse: (function () {
      return utils['default'].buildCountriesAccordion(this.get('countries'));
    }).property('countries.@each'),

    hasContinent: function hasContinent(continent) {
      var _this = this;

      return continent.areas.every(function (a) {
        return _this.hasArea(a);
      });
    },

    hasArea: function hasArea(area) {
      var _this2 = this;

      return area.countries.every(function (c) {
        return _this2.hasCountry(c);
      });
    },

    hasCountry: function hasCountry(country) {
      return this.get('countries').some(function (c) {
        return c.get('name') === country.get('name');
      });
    },

    firstFiveCountries: (function () {
      return this.get('countries').slice(0, 5);
    }).property('countries.@each'),

    restOfCountries: (function () {
      var countries = this.get('countries');
      return countries.slice(5, countries.get('length') - 1);
    }).property('countries.@each')
  });

});