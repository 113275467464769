define('bookalook/models/ddt-sample', ['exports', 'ember-data', 'ember', 'bookalook/mixins/model-validator', 'ember-cli-copyable', 'bookalook/utils'], function (exports, DS, Ember, Validator, Copyable, utils) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], Copyable['default'], {
    vat: DS['default'].attr('string'),
    number: DS['default'].attr('string'),
    date: DS['default'].attr('day'),
    appearance: DS['default'].attr('string'),
    reason: DS['default'].attr('string'),
    shippingDate: DS['default'].attr('day'),
    shippingCompanyAddress: DS['default'].attr('string'),
    billingAddress: DS['default'].attr('string'),
    orderAddress: DS['default'].attr('string'),
    returnAddress: DS['default'].attr('string'),
    boxes: DS['default'].attr('string'),
    weight: DS['default'].attr('string'),
    shippingType: DS['default'].attr('string'),

    checkinDate: DS['default'].attr('day'),

    showroom: DS['default'].belongsTo('showroom', { async: true, inverse: null }),
    samples: DS['default'].hasMany('sample', { async: true }),

    shippingTypeLabel: (function () {
      return this.get('shippingType');
    }).property('shippingType'),

    toLabel: (function () {
      var orderAddress = this.get('orderAddress');
      var result = "-";
      if (!Ember['default'].isBlank(orderAddress)) {
        var array = orderAddress.split('\n');
        result = array[0];
      }
      return result;
    }).property('orderAddress'),

    getDDTParcelNumber: function getDDTParcelNumber() {
      return utils['default'].getAPIJSON('ddtSamples/prefillNoShowroom').then(function (data) {
        var ddtSample = data.ddtSample;
        return ddtSample.number;
      });
    },

    prefill: function prefill() {
      var _this = this;

      var _t = this;
      return utils['default'].getAPIJSON('ddtSamples/prefillNoShowroom').then(function (data) {
        var ddtSample = data.ddtSample;
        _t.set('number', ddtSample.number);
        _t.set('returnAddress', ddtSample.returnAddress);
        _t.set('shippingCompanyAddress', ddtSample.shippingCompanyAddress || "-");
        return _this;
      });
    }
  });

});