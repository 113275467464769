define('bookalook/helpers/date-format', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var d = params[0];
    var s = params[1] || '.';
    var canBeEmpty = params[2];
    var showToday = true;
    var todayDateTimezone = null;

    if (!Ember['default'].isNone(params[3])) {
      showToday = params[3];
    }

    //Date calculated for timezone
    if (!Ember['default'].isNone(params[4])) {
      todayDateTimezone = params[4];
    }

    if (Ember['default'].isNone(d)) {
      if (!canBeEmpty) {
        d = new Date();
        showToday = false;
      } else {
        return '-';
      }
    }

    if (typeof d === 'string') {
      d = utils['default'].parseDate(d);
    }

    //Show TODAY: take into account timezone day
    if (todayDateTimezone) {
      if (showToday && d.toDateString() === todayDateTimezone.toDateString()) {
        return 'TODAY';
      }
    } else {
      if (showToday && d.toDateString() === new Date().toDateString()) {
        return 'TODAY';
      }
    }
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear() % 100;
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    year = year < 10 ? '0' + year : year;

    return [day, month, year].join(s);
  });

});