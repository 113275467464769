define('bookalook/tests/router.jshint', function () {

  'use strict';

  QUnit.module('JSHint - router.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'router.js should pass jshint.\nrouter.js: line 15, col 13, Expected an assignment or function call and instead saw an expression.\nrouter.js: line 27, col 110, Missing semicolon.\nrouter.js: line 55, col 26, Missing semicolon.\nrouter.js: line 56, col 43, Missing semicolon.\nrouter.js: line 57, col 36, Missing semicolon.\nrouter.js: line 58, col 24, Missing semicolon.\nrouter.js: line 59, col 57, Missing semicolon.\nrouter.js: line 60, col 53, Missing semicolon.\nrouter.js: line 61, col 41, Missing semicolon.\nrouter.js: line 62, col 9, Missing semicolon.\nrouter.js: line 63, col 22, Missing semicolon.\nrouter.js: line 77, col 11, Missing semicolon.\nrouter.js: line 83, col 45, Missing semicolon.\nrouter.js: line 85, col 55, Missing semicolon.\n\n14 errors');
  });

});