define('bookalook/routes/check-out/check-out', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      checkoutBooking: function checkoutBooking(parcel) {
        var _this = this;

        this.send('openMessageModal', 'Doing check-out. Please Wait.');
        var promises = [];

        parcel.save();
        parcel.get('samples').forEach(function (sample) {
          sample.set('toCheckout', false);
          sample.set('checked', true);
          sample.set('status', 'OUT');
          var sampleStatus = sample.get('sampleStatus');
          var promise = sample.save().then(function () {
            sampleStatus.setProperties({
              checkout: new Date(),
              userCheckout: Ember['default'].user
            });
            sampleStatus.save();
          });
          promises.push(promise);
        });

        Ember['default'].RSVP.all(promises).then(function () {
          Ember['default'].run.later(function () {
            _this.send('closeMessageModal');

            var booking = parcel.get('booking');
            booking.get('addresses').addObject(parcel);
            _this.transitionTo('bookings.loan-form', booking.get('id'), booking.get('allParcelsExceptMain.length') - 1);
          }, 3000);
        });
      },

      cancelCheckoutBooking: function cancelCheckoutBooking() {
        this.transitionToOrigin();
      }
    },

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        origin: params.origin,
        collections: utils['default'].getCollections(this)
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      Ember['default'].bulk.clearSamples();

      var parcel = this.controllerFor(this.getOrigin()).get('parcelToAdd');
      parcel.get('samples').forEach(function (sample) {
        sample.set('checked', true);
      });
      controller.set('parcel', parcel);
    },

    getOrigin: function getOrigin() {
      return this.controller.get('origin');
    },

    transitionToOrigin: function transitionToOrigin() {
      var origin = this.getOrigin();
      if (origin === 'bookings.shopping-list' || origin === 'bookings.booking-details') {
        this.transitionTo(origin, this.controller.get('parcel.booking.id'), {
          queryParams: {
            search: ""
          }
        });
      } else {
        this.transitionTo(origin);
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-inventory');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-inventory');
    }
  });

});