define('bookalook/routes/settings/account-settings', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/config/environment', 'bookalook/routes/settings/functions', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, config, plansFunctions, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      selectMonthly: function selectMonthly() {
        plansFunctions['default'].changeSelectedInterval(this, 'month');
      },
      selectYearly: function selectYearly() {
        plansFunctions['default'].changeSelectedInterval(this, 'year');
      },
      setSelectedPlan: function setSelectedPlan(plan) {
        plansFunctions['default'].setSelectedPlan(this, plan);
      },
      goToStep2: function goToStep2() {
        this.onGoToStep2();
      },
      backToStep1: function backToStep1() {
        this.onBackToStep1();
      },
      createNewCard: function createNewCard() {
        plansFunctions['default'].createNewCard(this);
      },
      createNewCardAndPay: function createNewCardAndPay() {
        plansFunctions['default'].createNewCardAndPay(this);
      },
      completePurchase: function completePurchase() {
        plansFunctions['default'].completePurchase(this);
      },
      startCreateNewCard: function startCreateNewCard() {
        plansFunctions['default'].startCreateNewCard(this);
      },
      cancelCreateNewCard: function cancelCreateNewCard() {
        plansFunctions['default'].cancelCreateNewCard(this);
      },
      checkCardName: function checkCardName() {
        plansFunctions['default'].checkFailCardName(this);
      },
      checkCardNumber: function checkCardNumber() {
        plansFunctions['default'].checkFailCardNumber(this);
      },
      checkCardCvc: function checkCardCvc() {
        plansFunctions['default'].checkFailCardCvc(this);
      },

      //CONTROLLER ACTIONS
      cancelDDTSettings: function cancelDDTSettings() {
        this.onCancelDDTSettings();
      },
      saveDDTSettings: function saveDDTSettings() {
        this.onSaveDDTSettings();
      },
      toggleImportHelp: function toggleImportHelp() {
        this.controller.set('helpingDDT', !this.controller.get('helpingDDT'));
        utils['default'].toggleHelp('ddt-settings');
      },

      cancelCompanySettings: function cancelCompanySettings() {
        this.onCancelCompanySettings();
      },
      saveCompanySettings: function saveCompanySettings() {
        this.onSaveCompanySettings();
      },

      openEditShUserModal: function openEditShUserModal(shUser) {
        this.onOpenEditShUserModal(shUser);
      },
      cancelEditShUserModal: function cancelEditShUserModal() {
        this.onCancelEditShUserModal();
      },
      saveEditShUserModal: function saveEditShUserModal() {
        this.onSaveEditShUserModal();
      },
      saveShWithoutManagers: function saveShWithoutManagers() {
        this.onSaveShWithoutManagers();
      },
      openDeleteShUserModal: function openDeleteShUserModal(shUser) {
        this.onOpenDeleteShUserModal(shUser);
      },

      openInviteShUsersModal: function openInviteShUsersModal() {
        this.onOpenInviteShUsersModal();
      },
      cancelInviteShUsersModal: function cancelInviteShUsersModal() {
        this.onCancelInviteShUserModal();
      },
      inviteUsers: function inviteUsers() {
        this.onInviteUsers();
      },
      addMoreUsers: function addMoreUsers() {
        this.onAddMoreUsers();
      },
      removeUser: function removeUser(user) {
        this.onRemoveUser(user);
      },
      checkUserEmail: function checkUserEmail() {
        this.onCheckUserEmail(event);
      },

      /*openLimitationReachedModal: function (type, idPreviousModal) {
        console.log("pasa");
        this.send('openLimitationReachedModal', type, idPreviousModal);
      },
      closeLimitationReachedModal: function (type, idPreviousModal) {
        this.send('closeLimitationReachedModal');
      },*/

      openEditShAddressModal: function openEditShAddressModal() {
        this.onOpenEditShAddressModal();
      },
      cancelEditShAddressModal: function cancelEditShAddressModal() {
        this.onCancelEditShAddressModal();
      },
      saveEditShAddressModal: function saveEditShAddressModal() {
        this.onSaveEditShAddressModal();
      },

      checkNotEmpty: function checkNotEmpty() {
        this.onCheckNotEmpty(event);
      },

      openBillingDetailsSettings: function openBillingDetailsSettings() {
        this.onOpenBillingDetailsSettings();
      },
      cancelBillingDetailsSettings: function cancelBillingDetailsSettings() {
        this.onCancelBillingDetailsSettings();
      },
      saveBillingDetailsSettings: function saveBillingDetailsSettings() {
        this.onSaveBillingDetailsSettings();
      },
      openCreateShBrandModal: function openCreateShBrandModal() {
        var brand = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        this.onOpenCreateShBrandModal(brand);
      },
      cancelEditShBrandModal: function cancelEditShBrandModal() {
        this.onCancelEditShBrandModal();
      },
      saveEditShBrandModal: function saveEditShBrandModal() {
        this.onSaveEditShBrandModal();
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        controller.removeObserver('currentPlanLoaded', controller, 'currentPlanLoadedObserver');
        controller.removeObserver('showroom.users.length', controller, 'canAddMoreUsersFunction');
        controller.removeObserver('companyUsers.length', controller, 'canAddMoreUsersFunction');
        controller.removeObserver('showroom.ddtFormat', controller, 'ddtFormatObserver');
        controller.removeObserver('userCard', controller, 'canSaveBilling');
        controller.removeObserver('billingDetails.address1', controller, 'canSaveBilling');
        controller.removeObserver('billingDetails.country', controller, 'canSaveBilling');
        controller.removeObserver('billingDetails.city', controller, 'canSaveBilling');
        controller.removeObserver('billingDetails.zipCode', controller, 'canSaveBilling');
      }
    },

    beforeModel: function beforeModel(transition, queryParams) {
      if (!Ember['default'].user || !Ember['default'].user.get('isManager')) {
        //this.send('openTopBarWithTime', "You are not allowed to go there.", 3000);
        this.transitionTo('session.login');
      }
      this._super(transition, queryParams);
    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        showroom: this.store.findRecord('showroom', Ember['default'].showroomId),
        user: this.store.findRecord('user', Ember['default'].userId),
        plans: plansFunctions['default'].getUniquePlans(this),
        planFromShManager: plansFunctions['default'].getPlanFromShowroomManager()
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      this._super(controller, model);
      controller.set('userLogged', Ember['default'].user);

      //Load timezones
      controller.set('timezoneList', Ember['default'].timezones);

      var _t = this;
      var user = controller.get('user');
      var showroom = controller.get('showroom');
      var timezone = showroom.get('timezone');
      var shCompanyTypeId = showroom.get('companyTypeAndId');
      controller.set('loadingBrands', true);

      showroom.get('brands').then(function (brands) {
        var sortedBrands = undefined;
        sortedBrands = brands.sortBy('name');
        showroom.set('brands', sortedBrands);

        controller.set('loadingBrands', false);
      });

      if (showroom.get('isAgency') && !Ember['default'].isNone(showroom.get('agency').get('content'))) {
        controller.set('shAgency', showroom.get('agency').get('content'));
      }

      plansFunctions['default'].initializeController(this);

      this.controller.set('countryList', Ember['default'].countries);

      controller.currentPlanLoadedObserver = function () {
        if (!controller.get('corporateCurrent')) {
          var invoices = plansFunctions['default'].getInvoices(_t).then(function (res) {
            controller.set('last2invoices', res);
            if (!Ember['default'].isNone(res) && res.length > 0) {
              var last2invoices = [];
              last2invoices[0] = res[0];
              if (!Ember['default'].isNone(res[1])) {
                last2invoices[1] = res[1];
              }
              controller.set('invoices', res);
              controller.set('last2invoices', last2invoices);
            } else {
              controller.set('invoices', null);
              controller.set('last2invoices', null);
            }
            return res;
          });
        }
      };
      //When we have current plan available, check if we gotta get invoices
      utils['default'].addObserver(controller, ['currentPlanLoaded'], 'currentPlanLoadedObserver');

      var accountTypes = [{
        'id': '1',
        'name': 'Member'
      }, {
        'id': '2',
        'name': 'Manager'
      }];
      controller.set('accountTypes', accountTypes);
      var users = [
        //Ember.Object.create({'email': '', 'type': 'Member'}),
        //Ember.Object.create({'email': '', 'type': 'Member'})
      ];
      controller.set('companyUsers', users);

      controller.canAddMoreUsersFunction = function () {
        var result = false;
        if (controller.get('currentPlanLoaded')) {
          var addingUsersLength = controller.get('companyUsers.length');
          if (controller.get('starterCurrent') || controller.get('designerCurrent')) {
            result = false;
          } else {
            var currentUsers = controller.get('showroom.users.length') + addingUsersLength;
            if (controller.get('proCurrent')) {
              result = currentUsers < 3;
            } else {
              result = true;
            }
          }
        }
        controller.set('canAddMoreUsers', result);
      };
      controller.canAddMoreUsersFunction();
      utils['default'].addObserver(controller, ['currentPlanLoaded', 'showroom.users.length', 'companyUsers.length'], 'canAddMoreUsersFunction');

      controller.canSaveBilling = function () {
        //return _t._checkbillingDetailsObj();
      };
      utils['default'].addObserver(controller, ['userCard', 'billingDetails.address1', 'billingDetails.country', 'billingDetails.city', 'billingDetails.zipCode'], 'canSaveBilling');

      //observer for ddtFormat (preview)
      controller.ddtFormatObserver = function () {
        var ddtFormat = controller.get('showroom.ddtFormat');
        utils['default'].getAPIJSON('ddtParcels/previewDDTFormat/' + ddtFormat).then(function (previewFormat) {
          var previewCodeFormat = previewFormat.ddtParcel;
          Ember['default'].$('.code').text(previewCodeFormat);
        });
      };
      utils['default'].addObserver(controller, ['showroom.ddtFormat'], 'ddtFormatObserver');

      this.cleanBillingDetailsObj();

      //Observer for time zone!
      controller.timezoneObserver = function () {
        var _t = this;
        _t.send('openMessageModal');
        controller.get('showroom').save().then(function () {
          _t.send('closeMessageModal');
          _t.send('openPositiveTopBar');
        });
      };
      utils['default'].addObserver(controller, ['showroom.timezone'], 'timezoneObserver');
    },

    onGoToStep2: function onGoToStep2() {
      this.controller.set('doingStep2', true);
    },

    onBackToStep1: function onBackToStep1() {
      this.controller.set('doingStep2', false);
    },

    onCancelDDTSettings: function onCancelDDTSettings() {
      var _t = this;
      var controller = _t.controller;
      var sh = controller.get('showroom');
      sh.rollbackAttributes();
      Ember['default'].$('#ddt-settings-modal').modal('hide');
    },

    onSaveDDTSettings: function onSaveDDTSettings() {
      var _t = this;
      var controller = _t.controller;
      var sh = controller.get('showroom');
      if (sh.validate()) {
        (function () {
          var button = Ember['default'].$('#save-ddt-settings');
          button.button('loading');
          sh.save().then(function () {
            button.button('reset');
            Ember['default'].$('#ddt-settings-modal').modal('hide');
          }, function () {
            button.button('reset');
          });
        })();
      }
    },

    onCancelCompanySettings: function onCancelCompanySettings() {
      var _t = this;
      var controller = _t.controller;
      var company = null;
      if (controller.get('showroom.isAgency')) {
        company = controller.get('shAgency');
      } else {
        company = controller.get('showroom.company');
      }
      company.rollbackAttributes();
      Ember['default'].$('#edit-company-modal').modal('hide');
    },

    onSaveCompanySettings: function onSaveCompanySettings() {
      var _t = this;
      var controller = _t.controller;
      var company = null;
      if (controller.get('showroom.isAgency')) {
        company = controller.get('shAgency');
      } else {
        company = controller.get('showroom.company');
      }
      if (company.validate()) {
        (function () {
          var button = Ember['default'].$('#save-company-settings');
          button.button('loading');
          company.save().then(function () {
            button.button('reset');
            Ember['default'].$('#edit-company-modal').modal('hide');
          }, function () {
            button.button('reset');
          });
        })();
      }
    },

    onOpenEditShUserModal: function onOpenEditShUserModal(shUser) {
      var _t = this;
      _t.controller.set('shUserToEdit', shUser);
      Ember['default'].$('#edit-sh-user-modal').modal();
      Ember['default'].openModal();
    },

    onCancelEditShUserModal: function onCancelEditShUserModal() {
      var _t = this;
      var controller = _t.controller;
      var user = controller.get('shUserToEdit');
      user.rollbackAttributes();
      Ember['default'].$('#edit-sh-user-modal').modal('hide');
      Ember['default'].$('#showroom-without-managers-modal').modal('hide');
      //_t.controller.set('shUserToEdit', null);
    },

    onSaveShWithoutManagers: function onSaveShWithoutManagers() {
      var btn = Ember['default'].$('#save-sh-without-managers');
      btn.button('loading');
      Ember['default'].$('#showroom-without-managers-modal').modal('hide');
      var _t = this;
      var controller = _t.controller;
      var user = controller.get('shUserToEdit');
      var userToBeManager = controller.get('shUserToBeManager');
      //set userManager  manager roles then:
      _t.send('openMessageModal', 'Saving users...');
      userToBeManager.set('roles', ['ROLE_MANAGER']).save().then(function () {
        user.save().then(function () {
          btn.button('reset');
          controller.set('shUserToBeManager', null);
          _t.send('closeMessageModal');
        });
      });
    },

    onSaveEditShUserModal: function onSaveEditShUserModal() {
      var _t = this;
      var controller = _t.controller;
      var user = controller.get('shUserToEdit');
      var users = controller.get('showroom').get('users');

      if (_t._shUserRoleCanBeChanged(user, users)) {
        if (user.validate({ only: ['name', 'jobTitle', 'surname'] })) {
          Ember['default'].$('#edit-sh-user-modal').modal('hide');
          _t.send('openMessageModal', 'Saving user...');
          user.save().then(function () {
            _t.send('closeMessageModal');
            //_t.controller.set('shUserToEdit', null);
          }, function () {
            _t.send('closeMessageModal');
          });
        } else {
          //console.log("not valid user, some field is missing");
        }
      } else {
          (function () {
            Ember['default'].$('#edit-sh-user-modal').modal('hide');

            var noManagers = Ember['default'].A();
            users.forEach(function (u) {
              if (u.get('id') != user.get('id') && !u.get('isManager')) {
                //Add img src for show avatar in combo
                var safeString = u.get('name') + ' ' + u.get('surname');
                var img = "<img src='" + u.get('avatar') + "' class='avatar-select' />";
                Ember['default'].set(u, 'safeString', new Ember['default'].Handlebars.SafeString(img + safeString));
                noManagers.push(u);
              }
            });

            noManagers.sort(function (a, b) {
              if (a.get('name') < b.get('name')) return -1;
              if (a.get('name') > b.get('name')) return 1;
              return 0;
            });
            _t.controller.set('shNoManagers', noManagers);
            _t.controller.set('shUserToBeManager', null);

            Ember['default'].$('#showroom-without-managers-modal').modal();
            Ember['default'].openModal();
          })();
        }
    },

    _shUserRoleCanBeChanged: function _shUserRoleCanBeChanged(user, users) {
      if (user.get('isManager')) {
        return true;
      } else {
        var managers = users.filter(function (u) {
          return u.get('id') != user.get('id') && u.get('isManager');
        });
        return managers.get('length') > 0;
      }
    },

    onOpenDeleteShUserModal: function onOpenDeleteShUserModal(shUser) {
      var _t = this;
      utils['default'].openDefaultConfirmationModal(this, 'Delete Team Member', 'You are about to delete this team member.', 'Do you wish to proceed?', 'Delete', 'Cancel').then(function () {
        _t._deleteShUser(shUser);
      });
    },

    _deleteShUser: function _deleteShUser(shUser) {
      //TODO Logic Delete user (do in backend)
      console.log("Deleted");
    },

    onOpenInviteShUsersModal: function onOpenInviteShUsersModal() {
      var _t = this;
      var controller = this.controller;
      controller.set('companyUsers', []);
      var canAddMoreUsers = controller.get('canAddMoreUsers');
      if (canAddMoreUsers) {
        var users = controller.get('companyUsers');
        users.addObject(Ember['default'].Object.create({ 'email': '', 'type': "Member" }));
        Ember['default'].$('#invite-sh-users-modal').modal();
        Ember['default'].openModal();
      }
    },

    onCancelInviteShUserModal: function onCancelInviteShUserModal() {
      Ember['default'].$('#invite-sh-users-modal').modal('hide');
      this.controller.set('companyUsers', []);
    },

    onInviteUsers: function onInviteUsers() {
      var _this2 = this;

      var _t = this;
      var usersToInvite = this.controller.get('companyUsers');

      //Let's filter blank spaces before & after the email address
      var usersToInviteFiltered = Ember['default'].A();
      usersToInvite.forEach(function (u) {
        Ember['default'].set(u, 'email', u.email.trim());
        usersToInviteFiltered.push(u);
      });

      var promise = utils['default'].postAPIJSON('invitations/inviteUsers', usersToInviteFiltered);
      var button = Ember['default'].$('#save-invite-sh-users');
      button.button('loading');
      promise.then(function (res) {
        if (res.errors) {
          _t.send('openTopBarWithTime', 'This email address already exists on Bookalook.', 3500);
        } else {
          _t.send('openTopBarWithTime', 'Users have been invited.', 3500);
          Ember['default'].$('#invite-sh-users-modal').modal('hide');
          _this2.controller.set('companyUsers', []);
        }
        button.button('reset');
      }, function (fail) {
        button.button('reset');
        _t.send('openTopBarWithTime', 'This email address already exists on Bookalook.', 3500);
      });
    },

    onAddMoreUsers: function onAddMoreUsers() {
      var controller = this.controller;
      var canAddMoreUsers = controller.get('canAddMoreUsers');
      if (canAddMoreUsers) {
        var users = controller.get('companyUsers');
        users.addObject(Ember['default'].Object.create({ 'email': '', 'type': "Member" }));
      }
    },
    onRemoveUser: function onRemoveUser(user) {
      var users = this.controller.get('companyUsers');
      users.removeObject(user);
    },

    onCheckUserEmail: function onCheckUserEmail(event) {
      var email = Ember['default'].$(event.target).val();
      email = email.trim();
      var promise = utils['default'].postAPIJSON('users/pr_by_email', { 'email': email });
      promise.then(function (res) {
        if (Ember['default'].isNone(res.user)) {
          Ember['default'].$(event.target).parent().removeClass('error');
        } else {
          Ember['default'].$(event.target).parent().addClass('error');
        }
      }, function (fail) {
        Ember['default'].$(event.target).parent().addClass('error');
      });
    },

    onOpenEditShAddressModal: function onOpenEditShAddressModal() {
      var _t = this;
      Ember['default'].$('#edit-sh-address-modal').modal();
      Ember['default'].openModal();
    },

    onCancelEditShAddressModal: function onCancelEditShAddressModal() {
      var _t = this;
      var controller = _t.controller;
      var sh = controller.get('showroom');
      sh.rollbackAttributes();
      Ember['default'].$('#edit-sh-address-modal').modal('hide');
      //_t.controller.set('shUserToEdit', null);
    },

    onSaveEditShAddressModal: function onSaveEditShAddressModal() {
      var _t = this;
      var controller = _t.controller;
      var sh = controller.get('showroom');
      if (sh.validate()) {
        Ember['default'].$('#edit-sh-address-modal').modal('hide');
        _t.send('openMessageModal', 'Saving address...');
        sh.save().then(function () {
          _t.send('closeMessageModal');
        });
      } else {
        //console.log("not valid showroom, some field is missing");
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-change-plan');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-change-plan');
    },

    onOpenModal: function onOpenModal(model) {
      var idModal = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var _t = this;
      if (idModal !== null && idModal !== '') {
        _t.controller.set('previousModal', idModal);
        Ember['default'].$(idModal).modal('hide');
      } else {
        _t.controller.set('previousModal', null);
      }
      Ember['default'].$('#-modal').modal();
      Ember['default'].openModal();
    },

    onCloseModal: function onCloseModal() {
      var previousModal = this.controller.get('previousModal');
      Ember['default'].$("#modal").modal('hide');
      // let user = this.controller.get('prToEdit');
      // user.rollbackAttributes();
      if (previousModal !== null) {
        Ember['default'].$(previousModal).modal();
        Ember['default'].openModal();
        this.controller.set('previousModal', null);
      }
    },

    onSaveModal: function onSaveModal() {
      var _this3 = this;

      var _this = this;
      var previousModal = this.controller.get('previousModal');
      Ember['default'].$("#modal").modal('hide');
      var user = this.controller.get('prToEdit');
      _this.onOpenMessageModal('Saving profile...');
      user.save().then(function () {
        _this.send('closeMessageModal');
        //_t.onOpenTopBarWithTime("Profile saved correctly.", 3000);
        _t.onOpenPositiveTopBar();
        if (previousModal !== null) {
          Ember['default'].$(previousModal).modal();
          Ember['default'].openModal();
          _this3.controller.set('previousModal', null);
        }
      });
    },

    cleanBillingDetailsObj: function cleanBillingDetailsObj() {
      var _t = this;
      var userCard = _t.controller.get('userCard');
      var sh = _t.controller.get('showroom');
      var obj = {};
      if (Ember['default'].isNone(userCard)) {
        obj = {
          'address1': '',
          'address2': '',
          'country': null,
          'state': '',
          'city': '',
          'zipCode': '',
          'vat': sh.get('vat') ? sh.get('vat') : ''
        };
      } else {
        obj = {
          'address1': userCard.address_line1,
          'address2': userCard.address_line2,
          'country': userCard.address_country,
          'state': userCard.address_state,
          'city': userCard.address_city,
          'zipCode': userCard.address_zip,
          'vat': sh.get('vat') ? sh.get('vat') : ''
        };
      }

      Ember['default'].$('#billing-container-address1').removeClass('error');
      Ember['default'].$('#billing-container-country').removeClass('error');
      Ember['default'].$('#billing-container-city').removeClass('error');
      Ember['default'].$('#billing-container-zipCode').removeClass('error');

      _t.controller.set('billingDetails', obj);
    },

    onCheckNotEmpty: function onCheckNotEmpty(event) {
      var field = Ember['default'].$(event.target).val();
      if (!Ember['default'].isNone(field) && field.trim() !== "") {
        Ember['default'].$(event.target).parent().removeClass('error');
      } else {
        Ember['default'].$(event.target).parent().addClass('error');
      }
      //this._checkbillingDetailsObj();
    },

    onOpenBillingDetailsSettings: function onOpenBillingDetailsSettings() {
      var _t = this;
      _t.cleanBillingDetailsObj();
      Ember['default'].$('#billing-details-modal').modal();
      Ember['default'].openModal();
    },

    onCancelBillingDetailsSettings: function onCancelBillingDetailsSettings() {
      var _t = this;
      _t.cleanBillingDetailsObj();
      var sh = _t.controller.get('showroom');
      sh.rollbackAttributes();
      Ember['default'].$('#billing-details-modal').modal('hide');
    },

    _checkbillingDetailsObj: function _checkbillingDetailsObj() {
      var controller = this.controller;

      var error = false;

      var obj = controller.get('billingDetails');

      if (Ember['default'].isNone(obj)) {
        error = true;
      } else {
        if (Ember['default'].isNone(obj.address1) || obj.address1.trim() === "") {
          error = true;
          Ember['default'].$('#billing-container-address1').addClass('error');
        } else {
          Ember['default'].$('#billing-container-address1').removeClass('error');
        }

        if (Ember['default'].isNone(obj.country) || obj.country.trim() === "") {
          error = true;
          Ember['default'].$('#billing-container-country').addClass('error');
        } else {
          Ember['default'].$('#billing-container-country').removeClass('error');
        }

        if (Ember['default'].isNone(obj.city) || obj.city.trim() === "") {
          error = true;
          Ember['default'].$('#billing-container-city').addClass('error');
        } else {
          Ember['default'].$('#billing-container-city').removeClass('error');
        }

        if (Ember['default'].isNone(obj.zipCode) || obj.zipCode.trim() === "") {
          error = true;
          Ember['default'].$('#billing-container-zipCode').addClass('error');
        } else {
          Ember['default'].$('#billing-container-zipCode').removeClass('error');
        }
      }

      if (error) {
        controller.set('billingSaveEnabled', false);
      } else {
        controller.set('billingSaveEnabled', true);
      }
      return error;
    },

    onSaveBillingDetailsSettings: function onSaveBillingDetailsSettings() {
      var _t = this;
      var controller = _t.controller;
      var obj = controller.get('billingDetails');
      var userCard = controller.get('userCard');
      var sh = controller.get('showroom');

      var error = this._checkbillingDetailsObj();

      if (!error) {
        var billingApiObj = {
          stripe_data: {
            customerId: controller.get('stripeCustomerId'),
            cardId: userCard.id,
            addressCity: obj.city,
            addressCountry: obj.country,
            addressLine1: obj.address1,
            addressLine2: obj.address2,
            addressZip: obj.zipCode,
            addressState: obj.state,
            expMonth: userCard.exp_month,
            expYear: userCard.exp_year
          }
        };

        Ember['default'].$('#billing-details-modal').modal('hide');
        _t.send('openMessageModal', 'Saving billing information...');

        utils['default'].putAPIJSON('paywithstripe/modify_card_from_customer', billingApiObj).then(function (ok) {
          sh.save();
          plansFunctions['default'].getCustomerCards(_t.controller.get('stripeCustomerId')).then(function (data) {
            var response = data.stripe_data;
            var cards = response.cards;
            if (cards.get('length') > 0) {
              var card = cards.get(0);
              controller.set('userCard', card);
            } else {
              controller.set('userCard', null);
            }
            _t.send('closeMessageModal');
          });
        }, function (fail) {
          var response = fail.responseJSON;
          var message = response.message;
          if (Ember['default'].isNone(message)) {
            message = "An error has been found in your card.";
          }
          _t.send('openTopBar', message);
          _t.send('closeMessageModal');
          sh.rollbackAttributes();
        });
      }
    },

    onOpenCreateShBrandModal: function onOpenCreateShBrandModal() {
      var brand = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _t = this;
      var c = _t.controller;
      var brandToEdit = null;
      if (Ember['default'].isNone(brand)) {
        brandToEdit = _t.store.createRecord('brand');
        brandToEdit.get('showrooms').addObject(Ember['default'].showroom);
        brandToEdit.set('HQ', Ember['default'].showroom);
      } else {
        brandToEdit = brand;
      }
      c.set('brandToEdit', brandToEdit);
      Ember['default'].$('#edit-brand-modal').modal();
      Ember['default'].openModal();
      _t.send('setSkipSaveOnUploadImg', true);
    },

    onCancelEditShBrandModal: function onCancelEditShBrandModal() {
      var _t = this;
      var b = _t.controller.get('brandToEdit');
      b.rollbackAttributes();
      Ember['default'].$('#edit-brand-modal').modal('hide');
      _t.send('setSkipSaveOnUploadImg', false);
    },

    onSaveEditShBrandModal: function onSaveEditShBrandModal() {
      var _t = this;
      var c = _t.controller;
      var b = c.get('brandToEdit');
      var sh = c.get('showroom');
      if (b.validate()) {
        Ember['default'].$('#edit-brand-modal').modal('hide');
        if (b.get('isNew')) {
          _t.send('openMessageModal', 'Creating brand...');
          b.save().then(function () {
            var sortedBrands = sh.get('brands');
            sortedBrands = sortedBrands.sortBy('name');
            sh.set('brands', sortedBrands);

            sh.reload().then(function () {

              var sortedBrands = sh.get('brands');
              sortedBrands = sortedBrands.sortBy('name');
              sh.set('brands', sortedBrands);

              var brandsCount = _t.controller.get('agencyBrandsCount');
              _t.controller.set('agencyBrandsCount', brandsCount + 1);
              _t.send('closeMessageModal');
              _t.send('setSkipSaveOnUploadImg', false);
            });
          });
        } else {
          _t.send('openMessageModal', 'Saving brand...');
          b.save().then(function () {

            var sortedBrands = sh.get('brands');
            sortedBrands = sortedBrands.sortBy('name');
            sh.set('brands', sortedBrands);

            _t.send('closeMessageModal');
            _t.send('setSkipSaveOnUploadImg', false);
          });
        }
      } else {
        //console.log("not valid brand, some field is missing");
      }
    }

  });

});