define('bookalook/templates/register/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 154
            }
          },
          "moduleName": "bookalook/templates/register/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/register/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","container register-route");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","register-route-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("JOIN BOOKALOOK");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","register-route-message");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Fill in the form to validate and access your Bookalook trial.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),5,5);
        return morphs;
      },
      statements: [
        ["block","register-form",[],["user",["subexpr","@mut",[["get","user",["loc",[null,[8,24],[8,28]]]]],[],[]],"agency",["subexpr","@mut",[["get","agency",["loc",[null,[8,36],[8,42]]]]],[],[]],"brand",["subexpr","@mut",[["get","brand",["loc",[null,[8,49],[8,54]]]]],[],[]],"showroom",["subexpr","@mut",[["get","showroom",["loc",[null,[8,64],[8,72]]]]],[],[]],"store",["subexpr","@mut",[["get","store",["loc",[null,[8,79],[8,84]]]]],[],[]],"invited",["subexpr","@mut",[["get","invited",["loc",[null,[8,93],[8,100]]]]],[],[]],"brandId",["subexpr","@mut",[["get","brandId",["loc",[null,[8,109],[8,116]]]]],[],[]],"agencyId",["subexpr","@mut",[["get","agencyId",["loc",[null,[8,126],[8,134]]]]],[],[]],"registerPlan",["subexpr","@mut",[["get","plan",["loc",[null,[8,148],[8,152]]]]],[],[]]],0,null,["loc",[null,[8,2],[8,172]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});