define('bookalook/helpers/limit-string', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return;
    }
    var string = params[0];
    var limit = params[1] || 10;
    var points = string.length > limit ? '...' : '';
    return string.substring(0, limit) + points;
  });

});