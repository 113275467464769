define('bookalook/tests/helpers/get-collection-label-formatted.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/get-collection-label-formatted.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'helpers/get-collection-label-formatted.js should pass jshint.\nhelpers/get-collection-label-formatted.js: line 44, col 17, Missing semicolon.\n\n1 error');
  });

});