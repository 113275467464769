define('bookalook/serializers/application', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].RESTSerializer.extend({
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      var key = relationship.key,
          hasManyRecords = Ember['default'].get(record, key);

      // Embed hasMany relationship if records exist
      if (hasManyRecords) {
        json[key] = [];
        hasManyRecords.forEach(function (item) {
          if (item.id) {
            json[key].push(item.id);
          }
        });
      }
      // Fallback to default serialization behavior
      else {
          return this._super(record, json, relationship);
        }
    },
    // Extract embedded relations from the payload and load them into the store
    normalizeRelationships: function normalizeRelationships(type, hash) {
      var store = this.store;

      this._super(type, hash);

      type.eachRelationship(function (attr, relationship) {
        if (relationship.options.embed === 'always') {
          if (relationship.kind === 'hasMany' && hash[attr]) {
            hash[attr] = hash[attr].map(function (embeddedHash) {
              store.push(relationship.type, embeddedHash);
              return embeddedHash.id;
            });
          } else if (relationship.kind === 'belongsTo' && hash[attr]) {
            store.push(relationship.type, hash[attr]);
            hash[attr] = hash[attr].id;
          }
        }
      });
    }
  });

});