define('bookalook/tests/routes/chat/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/chat/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/chat/index.js should pass jshint.\nroutes/chat/index.js: line 39, col 24, Missing semicolon.\nroutes/chat/index.js: line 40, col 41, Missing semicolon.\nroutes/chat/index.js: line 41, col 34, Missing semicolon.\nroutes/chat/index.js: line 42, col 22, Missing semicolon.\nroutes/chat/index.js: line 43, col 55, Missing semicolon.\nroutes/chat/index.js: line 44, col 51, Missing semicolon.\nroutes/chat/index.js: line 45, col 39, Missing semicolon.\nroutes/chat/index.js: line 46, col 7, Missing semicolon.\nroutes/chat/index.js: line 47, col 20, Missing semicolon.\nroutes/chat/index.js: line 61, col 9, Missing semicolon.\nroutes/chat/index.js: line 67, col 43, Missing semicolon.\nroutes/chat/index.js: line 69, col 53, Missing semicolon.\n\n12 errors');
  });

});