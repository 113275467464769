define('bookalook/models/booking', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment', 'bookalook/utils', 'ember-cli-copyable'], function (exports, Ember, DS, Validator, config, utils, Copyable) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], Copyable['default'], {
    checkout: DS['default'].attr('day'),
    checkin: DS['default'].attr('day'),
    jobDateStart: DS['default'].attr('day'),
    jobDateEnd: DS['default'].attr('day'),
    dateCreated: DS['default'].attr('day'),

    company: DS['default'].attr('string'),
    country: DS['default'].attr('string'),

    deliveryMethod: DS['default'].attr('string'),
    courier: DS['default'].attr('string'),
    trackNumber: DS['default'].attr('string'),
    notes: DS['default'].attr('string'),

    returnMethod: DS['default'].attr('string'),
    returnCourier: DS['default'].attr('string'),
    returnTrackNumber: DS['default'].attr('string'),
    returnNotes: DS['default'].attr('string'),

    photographers: DS['default'].attr('string'),
    models: DS['default'].attr('string'),
    issue: DS['default'].attr('string'),
    publicationFeatureDate: DS['default'].attr('day'),

    jobType: DS['default'].attr('string'),

    active: DS['default'].attr('boolean'),
    disregarded: DS['default'].attr('boolean'),
    closed: DS['default'].attr('boolean'),
    canceled: DS['default'].attr('boolean'),
    deleted: DS['default'].attr('boolean'),

    callInStreet1: DS['default'].attr('string'),
    callInStreet2: DS['default'].attr('string'),
    callInStreet3: DS['default'].attr('string'),
    callInCountry: DS['default'].attr('string'),
    callInCity: DS['default'].attr('string'),
    callInState: DS['default'].attr('string'),
    callInZipCode: DS['default'].attr('string'),
    callInTelephone: DS['default'].attr('string'),
    callInAttn: DS['default'].attr('string'),

    returnStreet1: DS['default'].attr('string'),
    returnStreet2: DS['default'].attr('string'),
    returnStreet3: DS['default'].attr('string'),
    returnCountry: DS['default'].attr('string'),
    returnCity: DS['default'].attr('string'),
    returnState: DS['default'].attr('string'),
    returnZipCode: DS['default'].attr('string'),
    returnTelephone: DS['default'].attr('string'),
    returnAttn: DS['default'].attr('string'),

    shootingLocation: DS['default'].attr('string'),
    showroomCustomId: DS['default'].attr('number'),

    sampleStatus: DS['default'].hasMany('sample-status'),
    status: DS['default'].attr('string'),

    requester: DS['default'].belongsTo('contact', { async: true, inverse: null }),
    owner: DS['default'].belongsTo('user', { async: true }),
    job: DS['default'].belongsTo('job', { async: true }),

    users: DS['default'].hasMany('user', { async: true }),
    samples: DS['default'].hasMany('sample', { async: true, inverse: null }),
    addresses: DS['default'].hasMany('parcel', { async: true }),
    loanForms: DS['default'].hasMany('loan-form', { async: true }),

    deliveryMethodLabel: (function () {
      return this.get('deliveryMethod');
    }).property('deliveryMethod'),

    isForeign: (function () {
      return this.get('owner.showroom.id') !== Ember['default'].showroom.get('id');
    }).property('owner.showroom.id'),

    isNotForeign: (function () {
      return this.get('owner.showroom.id') === Ember['default'].showroom.get('id');
    }).property('owner.showroom.id'),

    isPersonal: (function () {
      return this.get('users').some(function (u) {
        return u.get('id') === Ember['default'].user.get('id');
      });
    }).property('users.@each.name'),

    isRequested: (function () {
      return this.get('job.id') != null;
    }).property('job.id'),

    isInternal: (function () {
      return !this.get('isRequested');
    }).property('isRequested'),

    jobTypeLabel: (function () {
      return this.get('jobType');
    }).property('jobType'),

    members: (function () {
      var _this2 = this;

      var members = this.get('users').filter(function (u) {
        return u.get('id') !== _this2.get('owner').get('id');
      });
      var sorted = members.sort(function (a, b) {
        return a.get('fullName').toLowerCase().localeCompare(b.get('fullName').toLowerCase());
      });
      return sorted;
    }).property('users'),

    membersNumber: (function () {
      return Math.max(this.get('members.length'), 0);
    }).property('users'),

    hasMembers: (function () {
      return this.get('membersNumber') > 0;
    }).property('users'),

    firstInvolvedUsers: (function () {
      var result = [];
      var owner = this.get('owner');
      var members = this.get('members');
      if (owner) {
        result = [owner];
        if (members != null && members.length > 0) {
          result.push(members[0]);
        }
      } else {
        if (members != null && members.length > 0) {
          result = [members[0]];
          if (members[1] != null) {
            result.push(members[1]);
          }
        }
      }
      return result;
    }).property('owner', 'members'),

    restInvolvedUsers: (function () {
      var restInvolved = [];
      var currentMember = 0;
      var members = this.get('members');
      if (members.get('length') > 1) {
        members.forEach(function (member) {
          currentMember++;
          if (currentMember > 1) {
            restInvolved.push(member);
          }
        });
      }

      return restInvolved;
    }).property('users'),

    isToday: (function () {
      var today = new Date();
      return Ember['default'].formatDate(today) === Ember['default'].formatDate(this.get('checkout'));
    }).property('checkout'),

    resetSampleStatusLabels: function resetSampleStatusLabels() {
      var data = {
        booking: this.get('id'),
        date: utils['default'].dateToDayFormat(new Date())
      };

      var _this = this;
      var promise = Ember['default'].$.ajax({
        url: config['default'].APIURL + 'statuses/shoppingList',
        data: JSON.stringify(data),
        type: 'POST',
        contentType: 'application/json',
        success: function success(res) {
          var statuses = res.data;
          statuses.forEach(function (status) {
            var sample = _this.store.peekRecord('sample', status.sample);
            if (sample) {
              sample.set('ddts', status.ddts);
              sample.set('shoppingListStatus', status.status);
              sample.set('warning', status.warning);
              sample.set('shoppingListInBooking', status.inBooking);
              sample.set('shoppingListInBookingShowroomCustom', status.inBookingShowroomCustom);
              sample.set('bookingPermission', status.permission);
              sample.set('toAdd', status.toAdd);
              sample.set('isCanceled', status.isCanceled);
              if (!Ember['default'].isBlank(status.featured)) {
                var featuredLabel = "";
                if (status.featured == "YES") {
                  featuredLabel = "FEATURED";
                } else if (status.featured == "NO") {
                  featuredLabel = "NOT FEATURED";
                } else {
                  featuredLabel = "RETURNED";
                }
                sample.set('featured', status.featured);
                sample.set('featuredLabel', featuredLabel);
              } else {
                sample.set('featured', null);
                sample.set('featuredLabel', null);
              }
            }
          });
        }
      });

      return promise;
    },

    allParcelsExceptMain: (function () {
      var parcels = this.get('addresses').sortBy('id');
      var parcelsToReturn = [];
      var first = true && Ember['default'].isNone(this.get('job.id'));

      parcels.forEach(function (parcel) {
        if (first) {
          first = false;
        } else {
          parcelsToReturn.addObject(parcel);
        }
      });

      return parcelsToReturn;
    }).property('addresses.@each'),

    //Can be closed when:
    // - All samples movements are complete
    canBeClosed: (function () {
      var bookingSamples = this.get('samples');
      var declinedOrCanceledFound = false;
      var returnedFound = false;

      var canBeClosed = bookingSamples.every(function (sample) {
        if (sample.get('archived')) {
          return true;
        }

        var spStatus = sample.get('shoppingListStatus');
        spStatus = spStatus || "";
        spStatus = spStatus.toLowerCase();
        if ((spStatus.indexOf('canceled') > -1 || spStatus.indexOf('declined') > -1) && !declinedOrCanceledFound) {
          declinedOrCanceledFound = true;
        }

        if (spStatus === 'returned' && !returnedFound) {
          returnedFound = true;
        }

        return spStatus === "returned" || spStatus.indexOf('canceled') > -1 || spStatus.indexOf('declined') > -1;
      });

      return canBeClosed && returnedFound && !declinedOrCanceledFound || canBeClosed && returnedFound && declinedOrCanceledFound;
    }).property('samples.@each.shoppingListStatus', 'samples.@each.sampleStatus'),

    canBeCancelled: (function () {
      if (this.get('closed')) {
        return false;
      }

      var bookingSamples = this.get('samples');

      var canBeCancelled = bookingSamples.every(function (sample) {
        var sStatus = sample.get('sampleStatus');
        var slStatus = sample.get('shoppingListStatus');
        slStatus = slStatus || "";
        slStatus = slStatus.toLowerCase();
        if (sStatus) {
          return !sStatus.get('checkout');
        } else {
          return true;
        }
      });
      return canBeCancelled;
    }).property('samples.@each.shoppingListStatus', 'samples.@each.sampleStatus'),

    someSampleHasCheckout: (function () {
      if (this.get('closed')) {
        return true;
      }
      var sampleStatuses = this.get('sampleStatus');
      if (!Ember['default'].isEmpty(sampleStatuses)) {
        return sampleStatuses.some(function (sS) {
          return !Ember['default'].isBlank(sS.get('checkout'));
        });
      }
      return false;
    }).property('sampleStatus.@each.checkout'),

    someSampleHasCheckin: (function () {
      if (this.get('closed')) {
        return true;
      }
      var sampleStatuses = this.get('sampleStatus');
      if (!Ember['default'].isEmpty(sampleStatuses)) {
        return sampleStatuses.some(function (sS) {
          return !Ember['default'].isBlank(sS.get('checkin'));
        });
      }
      return false;
    }).property('sampleStatus.@each.checkin'),

    _samplesNumber: (function () {
      return this._internalModel._relationships.get('samples').canonicalState.length;
    }).property('samples.@each'),

    createParcel: function createParcel() {
      var parcel = this.store.createRecord('parcel', {
        booking: this,
        user: Ember['default'].user,
        showroom: this.get('owner.showroom'),

        street1In: this.get('callInStreet1'),
        street2In: this.get('callInStreet2'),
        street3In: this.get('callInStreet3'),
        countryIn: this.get('callInCountry'),
        cityIn: this.get('callInCity'),
        stateIn: this.get('callInState'),
        zipCodeIn: this.get('callInZipCode'),
        telephone1In: this.get('callInTelephone'),
        attnIn: this.get('callInAttn'),

        street1: this.get('returnStreet1'),
        street2: this.get('returnStreet2'),
        street3: this.get('returnStreet3'),
        country: this.get('returnCountry'),
        city: this.get('returnCity'),
        zipCode: this.get('returnZipCode'),
        telephone1: this.get('returnTelephone'),
        attnReturn: this.get('returnAttn'),
        shootingLocation: this.get('shootingLocation'),

        deliveryMethod: this.get('deliveryMethod'),
        courier: this.get('courier'),
        trackNumber: this.get('trackNumber'),
        notes: this.get('notes'),

        company: this.get('company'),
        sender: Ember['default'].user.get('fullName'),

        dateReturn: this.get('checkin'),
        shippingDate: new Date(),

        samples: Ember['default'].samplesToCheckout || []
      });

      return parcel;
    },

    createDDTParcel: function createDDTParcel() {
      var parcel = this.store.createRecord('ddt-parcel', {
        booking: this,

        user: Ember['default'].user,
        date: new Date(),
        shippingDate: new Date(),
        checkinDate: this.get('checkin'),
        shippingCompanyAddress: Ember['default'].showroom.get('shippingCompanyAddress'),

        billingAddress: this._getBillingAddress(),
        orderAddress: this._getOrderAddress(),
        returnAddress: this._getReturnAddress(),
        showroom: Ember['default'].showroom,

        shippingType: 'Vettori',
        samples: Ember['default'].samplesToCheckout || []
      });

      return parcel;
    },

    _getBillingAddress: function _getBillingAddress() {
      return [this.get('requester.fullName'), this.get('callInStreet1'), this.get('callInStreet2') + ' ' + this.get('callInStreet3'), this.get('callInCity') + ' ' + this.get('callInState') + ' ' + this.get('callInZipCode'), this.get('callInCountry'), 'P. IVA ' + this.get('requester.vat')].join('\n').replace(/\bnull\b/g, '');
    },

    _getOrderAddress: function _getOrderAddress() {
      return [this.get('company'), this.get('callInStreet1'), this.get('callInStreet2') + ' ' + this.get('callInStreet3'), this.get('callInCity') + ' ' + this.get('callInState') + ' ' + this.get('callInZipCode'), this.get('callInCountry'), 'Attn: ' + this.get('callInAttn'), this.get('callInTelephone')].join('\n').replace(/\bnull\b/g, '');
    },

    _getReturnAddress: function _getReturnAddress() {
      return [Ember['default'].showroom.companyName, this.get('returnStreet1'), this.get('returnStreet2') + ' ' + this.get('returnStreet3'), this.get('returnCity') + ' ' + this.get('returnState') + ' ' + this.get('returnZipCode'), this.get('returnCountry'), this.get('returnTelephone')].join('\n').replace(/\bnull\b/g, '');
    },

    updateCallInWithJobAddress: function updateCallInWithJobAddress() {
      this.setProperties({
        callInStreet1: this.get('job.mainAddress.street1'),
        callInStreet2: this.get('job.mainAddress.street2'),
        callInStreet3: this.get('job.mainAddress.street3'),
        callInCountry: this.get('job.mainAddress.country'),
        callInCity: this.get('job.mainAddress.city'),
        callInState: this.get('job.mainAddress.state'),
        callInZipCode: this.get('job.mainAddress.zipCode'),
        callInTelephone: this.get('job.mainAddress.telephone'),
        callInAttn: this.get('requester.fullName')
      });
    },

    updateReturnWithShowroom: function updateReturnWithShowroom() {
      this.setProperties({
        returnStreet1: Ember['default'].showroom.get('street'),
        returnStreet2: Ember['default'].showroom.get('street2'),
        returnStreet3: Ember['default'].showroom.get('street3'),
        returnCountry: Ember['default'].showroom.get('country'),
        returnCity: Ember['default'].showroom.get('city'),
        returnState: Ember['default'].showroom.get('region'),
        returnZipCode: Ember['default'].showroom.get('zipCode'),
        returnTelephone: Ember['default'].showroom.get('telephone'),
        returnAttn: Ember['default'].user.get('fullName')
      });
    },

    samplesCheckedToCheckout: (function () {
      var samples = this.get('samples');
      return samples.filter(function (s) {
        return s.get("toCheckout");
      });
    }).property('samples.@each.toCheckout'),

    /*
     CHECKOUT:
     · Si estado es checkout late: rojo (aviso de ir tarde)
     · Si estado es checkout today: azul (actual)
     · Si estado es upcoming, new o incomplete: blanco (aun no hay que hacerlo)
     · Si es cualquier otro estado (checkin late, closed o active): verde (done)
      JOB
     Si checkout === verde (done):
     · Si esta en intervalo de fechas: azul (active)
     · Sino: verde (done)
     Sino: blanco (aun no hay que hacerlo)
      CHECKIN
     Si job esta en verde(done):
     · Si estado es checkin late: rojo (aviso de ir tarde)
     · Si estado es closed: verde (hecho)
     · Si estado es active: azul (actual)
     Sino: blanco(por hacer)
     */

    checkoutTimeline: (function () {
      var status = this.get('status');
      if (status === "CHECK-OUT LATE") {
        return "danger";
      } else if (status === "CHECKOUT TODAY") {
        return "active";
      } else if (status === "UPCOMING" || status === "NEW" || status === "INCOMPLETE") {
        return "";
      } else {
        return "done";
      }
    }).property('status'),

    callinTimeline: (function () {
      if (this.get('isInternal')) {
        return null;
      } else {
        var dateStart = this.get('job.callInDateStart');
        var dateEnd = this.get('job.callInDateEnd');

        var isInDateInterval = this.isInInterval(dateStart, dateEnd);

        var previusPhase = this.get('checkoutTimeline');
        if (previusPhase === "done") {
          if (isInDateInterval === -1) {
            return ""; //not yet
          } else if (isInDateInterval === 0) {
              return "active";
            } else {
              return "done";
            }
        } else {
          return "";
        }
      }
    }).property('isInternal', 'checkoutTimeline', 'job.callInDateStart', 'job.callInDateEnd'),

    jobTimeline: (function () {
      var jobDateStart = this.get('jobDateStart');
      var jobDateEnd = this.get('jobDateEnd');

      var isInDateInterval = this.isInInterval(jobDateStart, jobDateEnd);

      var previusPhase = "";
      if (this.get('isInternal')) {
        previusPhase = this.get('checkoutTimeline');
      } else {
        previusPhase = this.get('callinTimeline');
      }

      //Job Dates are null
      if (!jobDateStart && !jobDateEnd) {
        return previusPhase;
      }

      if (previusPhase === "done") {
        if (isInDateInterval === -1) {
          return ""; //not yet
        } else if (isInDateInterval === 0) {
            return "active";
          } else {
            return "done";
          }
      } else {
        return "";
      }
    }).property('isInternal', 'callinTimeline', 'checkoutTimeline'),

    jobReturnTimeline: (function () {
      if (this.get('isInternal')) {
        return null;
      } else {
        var dateStart = this.get('job.returnDateStart');
        var dateEnd = this.get('job.returnDateEnd');

        var isInDateInterval = this.isInInterval(dateStart, dateEnd);

        var previusPhase = this.get('jobTimeline');
        if (previusPhase === "done") {
          if (isInDateInterval === -1) {
            return ""; //not yet
          } else if (isInDateInterval === 0) {
              return "active";
            } else {
              return "done";
            }
        } else {
          return "";
        }
      }
    }).property('isInternal', 'jobTimeline', 'job.returnDateStart', 'job.returnDateEnd'),

    checkinTimeline: (function () {
      var previusPhase = "";
      if (this.get('isInternal')) {
        previusPhase = this.get('jobTimeline');
      } else {
        previusPhase = this.get('jobReturnTimeline');
      }

      if (previusPhase === "done") {
        var _status = this.get('status');
        if (_status === "ACTIVE LATE") {
          return "danger";
        } else if (_status === "CLOSED") {
          return "done";
        } else if (_status === "ACTIVE") {
          return "active";
        } else {
          return "";
        }
      } else {
        return "";
      }
    }).property('isInternal', 'jobTimeline', 'jobReturnTimeline', 'status'),

    isInInterval: function isInInterval(startDate, endDate) {
      var today = new Date();
      if (startDate && endDate && isFinite(today = today.valueOf()) && isFinite(startDate = startDate.valueOf()) && isFinite(endDate = endDate.valueOf())) {
        //DATES EXIST SO:
        if (today < startDate) {
          return -1;
        } else if (startDate <= today && today <= endDate) {
          return 0;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    },

    jobDatesObserver: (function () {
      var end = this.get('jobDateEnd');
      var start = this.get('jobDateStart');
      if (Ember['default'].isBlank(start)) {
        this.set('jobDateEnd', null);
      } else {
        if (Ember['default'].isBlank(end) || end < start) {
          this.set('jobDateEnd', start);
        }
      }
    }).observes('jobDateStart', 'jobDateEnd'),

    jobDateEndDisabled: (function () {
      return Ember['default'].isBlank(this.get('jobDateStart'));
    }).property('jobDateStart'),

    checkDatesObserver: (function () {
      var end = this.get('checkin');
      var start = this.get('checkout');
      if (!Ember['default'].isBlank(start) && end < start) {
        this.set('checkin', null);
      }
    }).observes('checkout', 'checkin'),

    hasMinimunAttrsSet: (function () {
      var checkout = !Ember['default'].isBlank(this.get('checkout'));
      var checkin = !Ember['default'].isBlank(this.get('checkin'));
      var requester = this._internalModel._relationships.get("requester").members.size !== 0;
      var company = !Ember['default'].isBlank(this.get('company'));
      var jobType = !Ember['default'].isBlank(this.get('jobType'));
      var country = !Ember['default'].isBlank(this.get('country'));
      return checkout && checkin && requester && company && country && jobType;
    }).property('checkout', 'checkin', 'requester', 'country', 'company', 'jobType'),

    validations: {
      'country': {
        presence: true
      }
    }
  });

});