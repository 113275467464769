define('bookalook/tests/routes/search/functions.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/search/functions.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/search/functions.js should pass jshint.\nroutes/search/functions.js: line 97, col 83, Missing semicolon.\n\n1 error');
  });

});