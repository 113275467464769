define('bookalook/templates/onboarding/partials/step-3', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 45,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/onboarding/partials/step-3.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-title");
          var el2 = dom.createTextNode("Add your main address");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-subtitle");
          dom.setAttribute(el1,"style","max-width: 520px;");
          var el2 = dom.createTextNode("This step is important so PRs at Brands & PR Agencies will know where you’re based and where they’ll ship their samples. You’ll be able to edit and add additional addresess from your account.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","onboarding-columns align-initial");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","onboarding-column");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Address 1*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Address 2");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix register-input-company validated-select");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Country*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","onboarding-column");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("City*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Postcode*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Telephone*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_primary pull-right single");
          dom.setAttribute(el1,"id","next-button-steps");
          dom.setAttribute(el1,"data-loading-text","Saving <i class='fa fa-circle-o-notch fa-spin'></i>");
          var el2 = dom.createTextNode("All done ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-check");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [5]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [3]);
          var element7 = dom.childAt(fragment, [7]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [5]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [1]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [3]),3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element6, [5]),3,3);
          morphs[6] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","positionAddress",["loc",[null,[10,34],[10,49]]]]],[],[]],"property","street1","class","no-padding hint-left col-xs-12"],["loc",[null,[10,10],[10,109]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","positionAddress",["loc",[null,[14,34],[14,49]]]]],[],[]],"property","street2","class","no-padding hint-left col-xs-12"],["loc",[null,[14,10],[14,109]]]],
          ["inline","validated-select",[],["class","col-xs-12 hint-left no-padding","content",["subexpr","@mut",[["get","Ember.countries",["loc",[null,[20,30],[20,45]]]]],[],[]],"property","country","optionLabelPath","name","optionValuePath","name","model",["subexpr","@mut",[["get","positionAddress",["loc",[null,[24,30],[24,45]]]]],[],[]]],["loc",[null,[18,10],[25,12]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","positionAddress",["loc",[null,[31,34],[31,49]]]]],[],[]],"property","city","class","no-padding hint-left col-xs-12"],["loc",[null,[31,10],[31,106]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","positionAddress",["loc",[null,[35,34],[35,49]]]]],[],[]],"property","zipCode","class","no-padding hint-left col-xs-12"],["loc",[null,[35,10],[35,109]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","positionAddress",["loc",[null,[39,34],[39,49]]]]],[],[]],"property","telephone1","class","no-padding hint-left col-xs-12"],["loc",[null,[39,10],[39,112]]]],
          ["element","action",["completeMpPositionStep"],[],["loc",[null,[43,54],[43,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 2
            },
            "end": {
              "line": 89,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/onboarding/partials/step-3.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-title");
          var el2 = dom.createTextNode("Add your Showroom");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-subtitle");
          var el2 = dom.createTextNode("This step is important so media professionals, stylist and influencers will know where to return samples and set-up appointments with you and your team.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","onboarding-columns align-initial");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","onboarding-column");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Address 1*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Address 2");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Telephone*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","onboarding-column");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix register-input-company validated-select");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Country*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("City*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Postcode*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_primary pull-right single");
          dom.setAttribute(el1,"id","next-button-steps");
          dom.setAttribute(el1,"data-loading-text","Saving <i class='fa fa-circle-o-notch fa-spin'></i>");
          var el2 = dom.createTextNode("Next");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-angle-right");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [5]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(fragment, [7]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [3]),3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [5]),3,3);
          morphs[6] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sh",["loc",[null,[53,32],[53,34]]]]],[],[]],"property","street","class","no-padding hint-left col-xs-12"],["loc",[null,[53,8],[53,93]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sh",["loc",[null,[57,32],[57,34]]]]],[],[]],"property","street2","class","no-padding hint-left col-xs-12"],["loc",[null,[57,8],[57,94]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sh",["loc",[null,[61,32],[61,34]]]]],[],[]],"property","telephone","class","no-padding hint-left col-xs-12"],["loc",[null,[61,8],[61,96]]]],
          ["inline","validated-select",[],["class","col-xs-12 hint-left no-padding","content",["subexpr","@mut",[["get","Ember.countries",["loc",[null,[69,28],[69,43]]]]],[],[]],"property","country","optionLabelPath","name","optionValuePath","name","model",["subexpr","@mut",[["get","sh",["loc",[null,[73,28],[73,30]]]]],[],[]]],["loc",[null,[67,8],[74,10]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sh",["loc",[null,[78,32],[78,34]]]]],[],[]],"property","city","class","no-padding hint-left col-xs-12"],["loc",[null,[78,8],[78,91]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sh",["loc",[null,[82,32],[82,34]]]]],[],[]],"property","zipCode","class","no-padding hint-left col-xs-12"],["loc",[null,[82,8],[82,94]]]],
          ["element","action",["completeShowroomStep"],[],["loc",[null,[87,52],[87,85]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 90,
            "column": 6
          }
        },
        "moduleName": "bookalook/templates/onboarding/partials/step-3.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","user.isMP",["loc",[null,[2,8],[2,17]]]]],[],0,1,["loc",[null,[2,2],[89,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});