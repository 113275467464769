define('bookalook/tests/routes/bookings/loan-form.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/bookings/loan-form.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/bookings/loan-form.js should pass jshint.\nroutes/bookings/loan-form.js: line 20, col 87, Missing semicolon.\nroutes/bookings/loan-form.js: line 21, col 87, Missing semicolon.\nroutes/bookings/loan-form.js: line 105, col 11, Missing semicolon.\nroutes/bookings/loan-form.js: line 137, col 11, \'samplesId\' is already defined.\nroutes/bookings/loan-form.js: line 242, col 42, [\'name\'] is better written in dot notation.\nroutes/bookings/loan-form.js: line 971, col 5, Unreachable \'var\' after \'return\'.\nroutes/bookings/loan-form.js: line 986, col 13, \'cssText\' is already defined.\nroutes/bookings/loan-form.js: line 989, col 14, \'cssText\' used out of scope.\nroutes/bookings/loan-form.js: line 1112, col 82, Unreachable \';\' after \'return\'.\nroutes/bookings/loan-form.js: line 1112, col 82, Unnecessary semicolon.\nroutes/bookings/loan-form.js: line 1120, col 99, Missing semicolon.\n\n11 errors');
  });

});