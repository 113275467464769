define('bookalook/initializers/ajax-initializer', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.initialize = initialize;

  function initialize() {
    // For logging purposes
    Ember['default'].ActionHandler.reopen({
      send: function send() {
        Ember['default'].lastAction = arguments[0];
        this._super.apply(this, arguments);
      }
    });

    Ember['default'].Router.reopen({
      onTransition: (function (transition) {
        Ember['default'].currentRoute = transition.targetName;
        Ember['default'].lastAction = 'loadRoute';

        var bar = Ember['default'].$('#transition_bar');
        bar.css({ width: '0%', opacity: 1 });
        bar.animate({ width: '70%' }, 700);

        transition.then(function () {
          Ember['default'].$(window).scrollTop(0);
          bar.clearQueue();
          bar.animate({ width: '100%', opacity: 0 }, 1500);
        }, function () {
          bar.clearQueue();
          bar.animate({ width: '100%', opacity: 0 }, 1500);
        });
        return;
      }).on('willTransition')
    });

    Ember['default'].$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      if (!jqXHR.crossDomain && !options.disableLog) {
        jqXHR.setRequestHeader('LOG-ROUTE', Ember['default'].currentRoute);
        jqXHR.setRequestHeader('LOG-ACTION', Ember['default'].lastAction);
      }
    });
  }

  exports['default'] = {
    name: 'ajax-initializer',
    initialize: initialize
  };

});