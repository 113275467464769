define('bookalook/components/add-samples-loan-form', ['exports', 'ember', 'bookalook/utils', 'bookalook/filters', 'bookalook/routes/bookings/functions'], function (exports, Ember, utils, filters, bookingFunctions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    isLoading: true,
    prevRoute: null,
    parcel: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('route', this);
    },

    actions: {
      save: function save() {
        var samplesToAdd = this.get('samplesList').filterBy('addToSelectedSamples');
        var selectedSamples = this.get('selectedSamples');

        selectedSamples.addObjects(samplesToAdd);
        selectedSamples.setEach('checked', true);
        selectedSamples.setEach('visible', true);
        selectedSamples.setEach('addToSelectedSamples', false);

        if (this.get('prevRoute') !== null) {
          this.resetFilters(this.get('prevRoute'));
          //bookingFunctions.onSaveLoanForm(this.get('prevRoute'), 'loan-form');
        }
      },

      cancel: function cancel() {
        if (this.get('prevRoute') !== null) {
          this.resetFilters(this.get('prevRoute'));
          //this.get('parcel').rollbackAttributes();
        }
        this.get('samplesList').setEach('visible', true);
        var samplesToAdd = this.get('samplesList').filterBy('addToSelectedSamples');
        samplesToAdd.setEach('addToSelectedSamples', false);
      },

      checkSample: function checkSample(sample) {
        sample.set('addToSelectedSamples', !sample.get('addToSelectedSamples'));
      },

      checkLook: function checkLook(look) {
        var check = !look.get('allSamplesAreGoingToBeAdded');
        var groupedSamples = look.get('groupedSamples');
        for (var i = 0; i < groupedSamples.length; i++) {
          var samples = groupedSamples[i]['all'];
          for (var x = 0; x < samples.length; x++) {
            if (samples[x].get('visible') && samples[x].get('isAvailableForLoan')) {
              samples[x].set('addToSelectedSamples', check);
            }
          }
        }
      },

      checkAll: function checkAll() {
        var samplesList = this.get('samplesList');
        var check = this.get('totalSelected') !== samplesList.get('length');
        samplesList.forEach(function (sample) {
          if (sample.get('visible') && sample.get('isAvailableForLoan')) {
            sample.set('addToSelectedSamples', check);
          }
        });
      },

      removeSample: function removeSample(sample) {
        var parcelSamples = this.get('parcel.samples');
        var selectedSamples = this.get('selectedSamples');
        selectedSamples.removeObject(sample);
        sample.set('checked', false);
        sample.set('visible', true);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var $el = Ember['default'].$(this.element);
      var $modal = $el.find('#add-samples-modal');
      $modal.on('shown.bs.modal', function () {
        _this.set('isLoading', true);
        utils['default'].getSamples().setEach('visible', false);

        // Hide samples of other showrooms if its foreign
        var showAll = !_this.get('booking.isForeign');
        _this.get('samplesList').forEach(function (s) {
          return s.set('visible', showAll || s.get('showroom.id') === Ember['default'].showroom.get('id'));
        });

        //Hide already selected samples
        _this.get('selectedSamples').setEach('visible', false);

        _this.set('allSamplesSelected', _this.get('samplesList.length') === _this.get('selectedSamples.length'));
        _this.get('booking').resetSampleStatusLabels().then(function () {
          return _this.set('isLoading', false);
        });
        //utils.lazyLoad(1000, '#add-samples-modal');
      });

      $modal.on('hidden.bs.modal', function () {
        _this.set('isLoading', true);
        _this.set('allSamplesSelected', false);
      });
    },

    allAlbums: (function () {
      var albums = this.get('collections').reduce(function (a, c) {
        return a.concat(c.get('albums').toArray());
      }, []);
      return albums;
    }).property('collections'),

    hasAvailableSamples: (function () {
      var samples = this.get('samplesList');
      if (!Ember['default'].isNone(samples)) {
        return samples.some(function (s) {
          return s.get('isAvailableForLoan');
        });
      } else {
        return false;
      }
    }).property('samplesList.@each.isAvailableForLoan'),

    samplesToAdd: (function () {
      return this.get('samplesList').filterBy('addToSelectedSamples');
    }).property('samplesList.@each.addToSelectedSamples'),

    totalSelected: (function () {
      var selected = this.get('selectedSamples');
      var toAdd = this.get('samplesToAdd');
      //return selected;
      return selected.get('length') + toAdd.get('length');
    }).property('selectedSamples', 'samplesToAdd'),

    resetFilters: function resetFilters(route) {
      var controller = route.controller;
      controller.searchFilterFunction();
      /*controller.set('brandFilter', null);
      controller.set('collectionFilter', null);
      controller.set('searchInput', null);
      controller.set('searchInput', '');*/

      /*controller.set('itemFilter', '');
      controller.set('colourFilter', '');
      */
    }
  });

});