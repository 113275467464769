define('bookalook/templates/ddt-samples/partials/ddt-samples-filters', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/ddt-samples-filters.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","filter-brand");
          dom.setAttribute(el1,"class","form-group validated-select filter no-padding-right");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"autocomplete","off");
          dom.setAttribute(el2,"id","no-autocomplete-brand-ddt");
          dom.setAttribute(el2,"onsubmit","return false;");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["class","filter","placeholder","BRAND","searchEnabled",true,"content",["subexpr","@mut",[["get","allSearchBrands",["loc",[null,[11,34],[11,49]]]]],[],[]],"value",["subexpr","@mut",[["get","brandSearchFilter",["loc",[null,[12,34],[12,51]]]]],[],[]],"optionLabelPath","name","optionValuePath","id"],["loc",[null,[7,8],[15,10]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 4
            },
            "end": {
              "line": 75,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/ddt-samples-filters.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","filter-size");
          dom.setAttribute(el1,"class","form-group validated-select filter no-padding-right");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"autocomplete","off");
          dom.setAttribute(el2,"id","no-autocomplete-sizes-ddt");
          dom.setAttribute(el2,"onsubmit","return false;");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["class","filter","placeholder","SIZES","searchEnabled",true,"content",["subexpr","@mut",[["get","allSizesContent",["loc",[null,[68,36],[68,51]]]]],[],[]],"value",["subexpr","@mut",[["get","sizeFilter",["loc",[null,[69,36],[69,46]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","name"],["loc",[null,[64,10],[72,12]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 91,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/ddt-samples/partials/ddt-samples-filters.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","filter-wrapper col-xs-12 gradient-horizontal-background filters-row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","filters");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-collection");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-collection-ddt");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-album");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-album-ddt");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-sample");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-sample-ddt");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","filter-colour");
        dom.setAttribute(el3,"class","form-group validated-select filter no-padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"autocomplete","off");
        dom.setAttribute(el4,"id","no-autocomplete-colours-ddt");
        dom.setAttribute(el4,"onsubmit","return false;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1]),1,1);
        morphs[4] = dom.createMorphAt(element0,9,9);
        morphs[5] = dom.createMorphAt(dom.childAt(element0, [11, 1]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","length-is-higher-than",[["get","allSearchBrands",["loc",[null,[4,33],[4,48]]]],2],[],["loc",[null,[4,10],[4,51]]]]],[],0,null,["loc",[null,[4,4],[18,11]]]],
        ["inline","select-2",[],["class","filter","placeholder","COLLECTION","searchEnabled",true,"content",["subexpr","@mut",[["get","collectionsFilteredByBrand",["loc",[null,[27,34],[27,60]]]]],[],[]],"value",["subexpr","@mut",[["get","collectionFilter",["loc",[null,[28,34],[28,50]]]]],[],[]],"optionLabelPath","filterName","optionValuePath","id","disabled",["subexpr","@mut",[["get","brandSearchFilter",["loc",[null,[31,35],[31,52]]]]],[],[]]],["loc",[null,[23,8],[32,10]]]],
        ["inline","select-2",[],["class","filter","placeholder","ALBUM","searchEnabled",true,"content",["subexpr","@mut",[["get","albumsFilteredByCollection",["loc",[null,[41,34],[41,60]]]]],[],[]],"value",["subexpr","@mut",[["get","albumFilter",["loc",[null,[42,34],[42,45]]]]],[],[]],"optionLabelPath","albumTypeLabel","enabled",["subexpr","@mut",[["get","collectionFilter",["loc",[null,[44,34],[44,50]]]]],[],[]]],["loc",[null,[37,8],[45,10]]]],
        ["inline","select-2",[],["class","filter","placeholder","SAMPLE","searchEnabled",true,"content",["subexpr","@mut",[["get","Ember.itemsUnique",["loc",[null,[54,34],[54,51]]]]],[],[]],"value",["subexpr","@mut",[["get","itemFilter",["loc",[null,[55,34],[55,44]]]]],[],[]],"optionLabelPath","name","optionValuePath","name"],["loc",[null,[50,8],[58,10]]]],
        ["block","if",[["get","allSizesContent",["loc",[null,[61,10],[61,25]]]]],[],1,null,["loc",[null,[61,4],[75,11]]]],
        ["inline","select-2",[],["class","filter","placeholder","COLOURS","searchEnabled",true,"content",["subexpr","@mut",[["get","allColoursContent",["loc",[null,[82,34],[82,51]]]]],[],[]],"value",["subexpr","@mut",[["get","colourFilter",["loc",[null,[83,34],[83,46]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","name"],["loc",[null,[78,8],[86,10]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});