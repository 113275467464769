define('bookalook/routes/admin/plans', ['exports', 'ember', 'bookalook/utils', 'bookalook/routes/admin/functions'], function (exports, Ember, utils, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    actions: {
      openNewPlanModal: function openNewPlanModal() {
        functions['default'].openNewPlanModal(this);
      },
      openEditPlanModal: function openEditPlanModal(plan) {
        functions['default'].openNewPlanModal(this, plan);
      },

      saveNewPlan: function saveNewPlan(plan) {
        functions['default'].onSaveNewPlan(this, plan);
      },
      saveEditPlan: function saveEditPlan() {
        functions['default'].onSaveEditPlan(this);
      },
      toggleActive: function toggleActive(plan) {
        //Ember.set(plan, 'active', !plan.active);
        var check = confirm("Are You Sure?");
        if (check == true) {
          plan.toggleProperty('active');
          utils['default'].putAPIJSON('plans/' + plan.id + '/toggleActive');
          this.send('openTopBar', 'Plan has been saved');
        }
      },

      deletePlan: function deletePlan(plan) {
        $("#" + plan.id).remove();
        this.send('openTopBar', 'Plan deleted succesfully');
        utils['default'].deleteAPIJSON('plans/' + plan.id);
      },
      cancelEdition: function cancelEdition(model) {
        if (!Ember['default'].isNone(model)) {
          model.rollbackAttributes();
          Ember['default'].$('#add-plan, #new-plan').modal('hide');
        }
      }

    },

    model: function model() {
      return this.store.findAll('plan');
      //return utils.getAPIJSON('admin/plans');
    },

    setupController: function setupController(controller, model) {
      //  model.forEach(plan => {
      //  });

      controller.setProperties({
        plans: model,
        isPlansPage: true
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-admin');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-admin');
    }

  });

});