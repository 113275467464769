define('bookalook/templates/bookings/partials/sample-row', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 6
              },
              "end": {
                "line": 19,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element8, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","unbound",[["get","sample.colour2URL",["loc",[null,[18,32],[18,49]]]]],[],["loc",[null,[18,22],[18,51]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 6
              },
              "end": {
                "line": 22,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1, 0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element7, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","unbound",[["get","sample.colour3URL",["loc",[null,[21,32],[21,49]]]]],[],["loc",[null,[21,22],[21,51]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 2
              },
              "end": {
                "line": 30,
                "column": 7
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","table_col_showroom table_col_hide_tablet");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","my-showroom",[["get","sample.showroom",["loc",[null,[29,24],[29,39]]]],["get","route",["loc",[null,[29,40],[29,45]]]]],[],["loc",[null,[29,10],[29,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 4
              },
              "end": {
                "line": 34,
                "column": 4
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createMorphAt(element6,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["sample_",["get","sample.addSampleStatus",["loc",[null,[33,28],[33,50]]]]]]],
            ["content","sample.addSampleStatus",["loc",[null,[33,54],[33,80]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 4
              },
              "end": {
                "line": 36,
                "column": 4
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"class","fa fa-circle-o-notch fa-spin");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 6
                },
                "end": {
                  "line": 47,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"type","checkbox");
              dom.setAttribute(el1,"checked","");
              dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
              dom.setAttribute(el1,"aria-label","");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              if (this.cachedFragment) { dom.repairClonedNode(element5,[],true); }
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element5, 'id');
              morphs[1] = dom.createElementMorph(element5);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[46,21],[46,35]]]]]]],
              ["element","action",["clickSelectSample",["get","sample",["loc",[null,[46,85],[46,91]]]]],["on","click"],["loc",[null,[46,56],[46,105]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 8
                  },
                  "end": {
                    "line": 50,
                    "column": 8
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("         ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 8
                  },
                  "end": {
                    "line": 52,
                    "column": 8
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"type","checkbox");
                dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
                dom.setAttribute(el1,"aria-label","");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element4, 'id');
                morphs[1] = dom.createElementMorph(element4);
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[51,23],[51,37]]]]]]],
                ["element","action",["clickSelectSample",["get","sample",["loc",[null,[51,87],[51,93]]]]],["on","click"],["loc",[null,[51,58],[51,107]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 6
                },
                "end": {
                  "line": 53,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","if-equal",[["get","sample.addSampleStatus",["loc",[null,[48,24],[48,46]]]],"unavailable"],[],["loc",[null,[48,14],[48,61]]]]],[],0,1,["loc",[null,[48,8],[52,15]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 6
                },
                "end": {
                  "line": 57,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","bal-checkbox-label");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element3, 'for');
              return morphs;
            },
            statements: [
              ["attribute","for",["concat",[["get","sample.checkId",["loc",[null,[56,22],[56,36]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 4
              },
              "end": {
                "line": 58,
                "column": 4
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","sample.checked",["loc",[null,[45,12],[45,26]]]]],[],0,1,["loc",[null,[45,6],[53,13]]]],
            ["block","unless",[["subexpr","if-equal",[["get","sample.addSampleStatus",["loc",[null,[55,26],[55,48]]]],"unavailable"],[],["loc",[null,[55,16],[55,63]]]]],[],2,null,["loc",[null,[55,6],[57,17]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 6
                },
                "end": {
                  "line": 61,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"type","checkbox");
              dom.setAttribute(el1,"checked","");
              dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
              dom.setAttribute(el1,"aria-label","");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'id');
              morphs[1] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[60,21],[60,35]]]]]]],
              ["element","action",["clickSelectSample",["get","sample",["loc",[null,[60,85],[60,91]]]]],["on","click"],["loc",[null,[60,56],[60,105]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 6
                },
                "end": {
                  "line": 67,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"type","checkbox");
              dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
              dom.setAttribute(el1,"aria-label","");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [4]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'id');
              morphs[1] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[65,23],[65,37]]]]]]],
              ["element","action",["clickSelectSample",["get","sample",["loc",[null,[65,87],[65,93]]]]],["on","click"],["loc",[null,[65,58],[65,107]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 4
              },
              "end": {
                "line": 72,
                "column": 4
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","bal-checkbox-label");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createAttrMorph(element2, 'for');
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","sample.checked",["loc",[null,[59,12],[59,26]]]]],[],0,1,["loc",[null,[59,6],[67,13]]]],
            ["attribute","for",["concat",[["get","sample.checkId",["loc",[null,[70,22],[70,36]]]]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 75,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","pointer");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","table_col_count pointer");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","gray-light-span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","table_col_colour pointer");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3,"class","list-block");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("img");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","table_col_barcode table_col_hide_tablet pointer");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","table_col_status pointer");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","table_col_calendar");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"class","image-calendar_uirefactor");
          dom.setAttribute(el4,"src","images/calendar.svg");
          dom.setAttribute(el4,"data-target","#sample-historial");
          dom.setAttribute(el4,"data-toggle","modal");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","table_col_check");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [0]);
          var element10 = dom.childAt(element9, [1]);
          var element11 = dom.childAt(element9, [3]);
          var element12 = dom.childAt(element11, [1]);
          var element13 = dom.childAt(element9, [5]);
          var element14 = dom.childAt(element13, [1]);
          var element15 = dom.childAt(element14, [1, 0]);
          var element16 = dom.childAt(element9, [7]);
          var element17 = dom.childAt(element9, [11]);
          var element18 = dom.childAt(element9, [13, 1, 1]);
          var element19 = dom.childAt(element9, [15]);
          var morphs = new Array(17);
          morphs[0] = dom.createElementMorph(element10);
          morphs[1] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
          morphs[2] = dom.createElementMorph(element11);
          morphs[3] = dom.createMorphAt(element12,0,0);
          morphs[4] = dom.createMorphAt(element12,2,2);
          morphs[5] = dom.createElementMorph(element13);
          morphs[6] = dom.createAttrMorph(element15, 'src');
          morphs[7] = dom.createMorphAt(element14,3,3);
          morphs[8] = dom.createMorphAt(element14,4,4);
          morphs[9] = dom.createElementMorph(element16);
          morphs[10] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
          morphs[11] = dom.createMorphAt(element9,9,9);
          morphs[12] = dom.createElementMorph(element17);
          morphs[13] = dom.createMorphAt(element17,1,1);
          morphs[14] = dom.createElementMorph(element18);
          morphs[15] = dom.createAttrMorph(element19, 'id');
          morphs[16] = dom.createMorphAt(element19,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[4,56],[4,62]]]]],[],["loc",[null,[4,22],[4,64]]]],
          ["inline","unbound",[["get","sample.itemLabel",["loc",[null,[5,20],[5,36]]]]],[],["loc",[null,[5,10],[5,38]]]],
          ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[11,72],[11,78]]]]],[],["loc",[null,[11,38],[11,80]]]],
          ["inline","plus-one",[["get","sampleIndex",["loc",[null,[12,45],[12,56]]]]],[],["loc",[null,[12,34],[12,58]]]],
          ["content","sampleGroup.all.length",["loc",[null,[12,59],[12,85]]]],
          ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[14,73],[14,79]]]]],[],["loc",[null,[14,39],[14,81]]]],
          ["attribute","src",["concat",[["subexpr","unbound",[["get","sample.colour1URL",["loc",[null,[16,30],[16,47]]]]],[],["loc",[null,[16,20],[16,49]]]]]]],
          ["block","if",[["get","sample.colour2URL",["loc",[null,[17,12],[17,29]]]]],[],0,null,["loc",[null,[17,6],[19,13]]]],
          ["block","if",[["get","sample.colour3URL",["loc",[null,[20,12],[20,29]]]]],[],1,null,["loc",[null,[20,6],[22,13]]]],
          ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[25,96],[25,102]]]]],[],["loc",[null,[25,62],[25,104]]]],
          ["inline","unbound",[["get","sample.barcode",["loc",[null,[26,20],[26,34]]]]],[],["loc",[null,[26,10],[26,36]]]],
          ["block","unless",[["get","disableShowroomFilter",["loc",[null,[28,12],[28,33]]]]],[],2,null,["loc",[null,[28,2],[30,18]]]],
          ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[31,73],[31,79]]]]],[],["loc",[null,[31,39],[31,81]]]],
          ["block","if",[["get","sample.addSampleStatus",["loc",[null,[32,10],[32,32]]]]],[],3,4,["loc",[null,[32,4],[36,11]]]],
          ["element","action",["openMinicalendarModal",["get","sample",["loc",[null,[40,44],[40,50]]]],"#new-booking-1-brand-refactor"],[],["loc",[null,[40,11],[40,84]]]],
          ["attribute","id",["concat",[["get","sample.barcode",["loc",[null,[43,12],[43,26]]]]]]],
          ["block","if",[["get","bookingPast",["loc",[null,[44,10],[44,21]]]]],[],5,6,["loc",[null,[44,4],[72,11]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 76,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/partials/sample-row.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Sample row | New booking step 2 ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","sample.visible",["loc",[null,[2,6],[2,20]]]]],[],0,null,["loc",[null,[2,0],[75,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});