define('bookalook/helpers/search-look-label', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return '';
    }
    var look = params[0];
    var result = "";
    var brandName = look.album.collection.brand.name;
    result = brandName + " - ";

    var year = look.album.collection.year;
    var season = look.album.collection.season;
    var collectionType = look.album.collection.collectionType;

    if (typeof year === 'number') {
      year = year.toString();
    }
    var y = year.charAt(2) + year.charAt(3);

    var t = collectionType.charAt(0);
    if (t === 'H') t = "HC";

    var s = null;
    switch (season) {
      case "Spring/Summer":
        s = "S/S";
        break;
      case "Pre-fall":
        s = season;
        break;
      case "Fall/Winter":
        s = "F/W";
        break;
      case "Cruise":
        s = season;
        break;
      case "Capsule":
        s = season;
        break;
      case "Year-round":
        s = season;
        break;
      default:
        s = season;
    }
    var collectionLabel = y + " - " + t + " - " + s;

    result = result + collectionLabel;
    return result;
  });

});