define('bookalook/templates/onboarding/partials/step-2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 6
              },
              "end": {
                "line": 10,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element7, 'src');
            morphs[1] = dom.createElementMorph(element7);
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","user.avatar",["loc",[null,[9,20],[9,31]]]]]]],
            ["element","action",["openUploadAvatarProfile",["get","user",["loc",[null,[9,70],[9,74]]]],"onboarding"],[],["loc",[null,[9,35],[9,89]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 6
              },
              "end": {
                "line": 16,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","images/add-user.svg");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createElementMorph(element6);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["no-avatar ",["subexpr","if",[["get","userAvatarError",["loc",[null,[11,35],[11,50]]]],"error"],[],["loc",[null,[11,30],[11,60]]]]]]],
            ["element","action",["openUploadAvatarProfile",["get","user",["loc",[null,[11,97],[11,101]]]],"onboarding"],[],["loc",[null,[11,62],[11,116]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-title");
          var el2 = dom.createTextNode("Set-up your Profile");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-subtitle");
          var el2 = dom.createTextNode("Fill-in your details to let people know who you are and what your position is. You can edit or add additional details once your are logged in to your account.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","avatar-and-medias onboarding-columns mp-user-settings");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","image-uploader onboarding-column");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","image-span");
          var el4 = dom.createTextNode("Upload profile picture");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","social-medias onboarding-column");
          var el3 = dom.createTextNode("\n\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("First name*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Last name*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input freelance-influencer-container");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","freelance-container");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","check_as_freelance");
          dom.setAttribute(el5,"class","bal-checkbox-label input_label");
          var el6 = dom.createTextNode("Freelance");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","influencer-container");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","check_as_influencer");
          dom.setAttribute(el5,"class","bal-checkbox-label input_label");
          var el6 = dom.createTextNode("Influencer");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Job title");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input company-name-container");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Company name*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("hr");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Website*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Instagram");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Facebook");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_primary pull-right single");
          dom.setAttribute(el1,"id","next-button-steps");
          var el2 = dom.createTextNode("Next");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-angle-right");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [5]);
          var element9 = dom.childAt(element8, [3]);
          var element10 = dom.childAt(element9, [5]);
          var element11 = dom.childAt(element9, [7]);
          var element12 = dom.childAt(fragment, [7]);
          var morphs = new Array(12);
          morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element9, [3]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
          morphs[6] = dom.createMorphAt(element11,3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element9, [9]),3,3);
          morphs[8] = dom.createMorphAt(dom.childAt(element9, [13]),3,3);
          morphs[9] = dom.createMorphAt(dom.childAt(element9, [15]),3,3);
          morphs[10] = dom.createMorphAt(dom.childAt(element9, [17]),3,3);
          morphs[11] = dom.createElementMorph(element12);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","user.isAvatarDefault",["loc",[null,[8,16],[8,36]]]]],[],0,1,["loc",[null,[8,6],[16,17]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","user",["loc",[null,[24,32],[24,36]]]]],[],[]],"property","name","class","no-padding hint-left col-xs-12"],["loc",[null,[24,8],[24,93]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","user",["loc",[null,[28,32],[28,36]]]]],[],[]],"property","surname","class","no-padding hint-left col-xs-12"],["loc",[null,[28,8],[28,96]]]],
          ["inline","input",[],["id","check_as_freelance","type","checkbox","model",["subexpr","@mut",[["get","user",["loc",[null,[33,64],[33,68]]]]],[],[]],"name","check_as_freelance","checked",["subexpr","@mut",[["get","user.freelance",["loc",[null,[33,103],[33,117]]]]],[],[]],"class","bal-checkbox-input"],["loc",[null,[33,10],[33,146]]]],
          ["inline","input",[],["id","check_as_influencer","type","checkbox","model",["subexpr","@mut",[["get","user",["loc",[null,[37,65],[37,69]]]]],[],[]],"name","check_as_influencer","checked",["subexpr","@mut",[["get","user.influencer",["loc",[null,[37,105],[37,120]]]]],[],[]],"class","bal-checkbox-input"],["loc",[null,[37,10],[37,149]]]],
          ["inline","unless",[["subexpr","if-or",[["get","user.influencer",["loc",[null,[43,59],[43,74]]]],["get","user.freelance",["loc",[null,[43,75],[43,89]]]]],[],["loc",[null,[43,52],[43,90]]]],"*"],[],["loc",[null,[43,43],[43,96]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","user",["loc",[null,[44,32],[44,36]]]]],[],[]],"property","jobTitle","disabled",["subexpr","if-or",[["get","user.influencer",["loc",[null,[44,73],[44,88]]]]],[],["loc",[null,[44,66],[44,89]]]],"class","no-padding hint-left col-xs-12"],["loc",[null,[44,8],[44,130]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","company",["loc",[null,[49,32],[49,39]]]]],[],[]],"property","name","disabled",["subexpr","if-or",[["get","user.influencer",["loc",[null,[49,72],[49,87]]]],["get","user.freelance",["loc",[null,[49,88],[49,102]]]]],[],["loc",[null,[49,65],[49,103]]]],"class","no-padding hint-left col-xs-12"],["loc",[null,[49,8],[49,144]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","company",["loc",[null,[56,32],[56,39]]]]],[],[]],"property","website","class","no-padding hint-left col-xs-12"],["loc",[null,[56,8],[56,99]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","user",["loc",[null,[60,32],[60,36]]]]],[],[]],"property","instagram","class","no-padding hint-left col-xs-12"],["loc",[null,[60,8],[60,98]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","user",["loc",[null,[64,32],[64,36]]]]],[],[]],"property","facebook","class","no-padding hint-left col-xs-12"],["loc",[null,[64,8],[64,97]]]],
          ["element","action",["completeMpProfileStep"],[],["loc",[null,[68,52],[68,86]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 6
              },
              "end": {
                "line": 80,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            morphs[1] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","company.avatar",["loc",[null,[79,20],[79,34]]]]]]],
            ["element","action",["openUploadAvatarProfile",["get","company",["loc",[null,[79,73],[79,80]]]],"onboarding"],[],["loc",[null,[79,38],[79,95]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 6
              },
              "end": {
                "line": 86,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","images/add-user.svg");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["no-avatar ",["subexpr","if",[["get","companyAvatarError",["loc",[null,[81,35],[81,53]]]],"error"],[],["loc",[null,[81,30],[81,63]]]]]]],
            ["element","action",["openUploadAvatarProfile",["get","company",["loc",[null,[81,100],[81,107]]]],"onboarding"],[],["loc",[null,[81,65],[81,122]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 0
            },
            "end": {
              "line": 105,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-title");
          var el2 = dom.createTextNode("Create your ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","onboarding-subtitle");
          var el2 = dom.createTextNode("Let's add your basic company details to start working on Bookalook. You can always edit this information later on your ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" settings page.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","company-name-box");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-check");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","avatar-and-medias onboarding-columns");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","image-uploader onboarding-column");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","image-span");
          var el4 = dom.createTextNode("Upload ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" picture");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","social-medias onboarding-column");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Website*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Instagram*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clearfix validated-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label");
          var el5 = dom.createTextNode("Facebook");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_primary pull-right single");
          dom.setAttribute(el1,"id","next-button-steps");
          var el2 = dom.createTextNode("Next");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-angle-right");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [7]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(fragment, [9]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(element3,1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [1]),3,3);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]),3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element4, [5]),3,3);
          morphs[8] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["inline","if",[["get","isAgency",["loc",[null,[70,50],[70,58]]]],"Company","Brand"],[],["loc",[null,[70,45],[70,78]]]],
          ["inline","if",[["get","isAgency",["loc",[null,[71,160],[71,168]]]],"company","brand"],[],["loc",[null,[71,155],[71,188]]]],
          ["content","onboardingObj.company_name",["loc",[null,[73,10],[73,40]]]],
          ["block","unless",[["get","company.isAvatarDefault",["loc",[null,[78,16],[78,39]]]]],[],0,1,["loc",[null,[78,6],[86,17]]]],
          ["inline","if",[["get","isAgency",["loc",[null,[87,43],[87,51]]]],"company","brand"],[],["loc",[null,[87,38],[87,71]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","company",["loc",[null,[92,32],[92,39]]]]],[],[]],"property","website","class","no-padding hint-left col-xs-12"],["loc",[null,[92,8],[92,99]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","company",["loc",[null,[96,32],[96,39]]]]],[],[]],"property","instagram","class","no-padding hint-left col-xs-12"],["loc",[null,[96,8],[96,101]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","company",["loc",[null,[100,32],[100,39]]]]],[],[]],"property","facebook","class","no-padding hint-left col-xs-12"],["loc",[null,[100,8],[100,100]]]],
          ["element","action",["completeBrandStep"],[],["loc",[null,[104,52],[104,82]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 107,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/onboarding/partials/step-2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","user.isMP",["loc",[null,[2,6],[2,15]]]]],[],0,1,["loc",[null,[2,0],[105,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});