define('bookalook/templates/global/maqpie', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/global/maqpie.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n(function() {\n\n    var l = function() {\n\n        var d = document;\n        var s = d.createElement('script');\n        s.type = 'text/javascript';\n        s.async = true;\n        s.src = 'https://widget.maqpie.com/widget/v1.0';\n        var x = d.getElementsByTagName('script')[0];\n        x.parentNode.insertBefore(s, x);\n    };\n    var w = window;\n    w.MP = w.MP || {};\n    w.MP._queue = w.MP._queue || [];\n    var m = ['renderLargeView', 'showLargeView', 'destroyLargeView', 'subscribe', 'unsubscribe'];\n    var f = function(method) {\n        return function() {\n            var args = Array.prototype.slice.call(arguments);\n            args.unshift(method);\n            w.MP._queue.push(args);\n        }\n    };\n    for (var i = 0; i != m.length; i += 1) {\n        w.MP[m[i]] = f(m[i]);\n    }\n    if (w.attachEvent) {\n        w.attachEvent('onload', l);\n    } else {\n        w.addEventListener('load', l, false);\n    }\n      alert(w.MP.user);\n}())\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});