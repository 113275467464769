define('bookalook/tests/routes/barcodes/scan.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/barcodes/scan.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/barcodes/scan.js should pass jshint.\nroutes/barcodes/scan.js: line 10, col 95, Missing semicolon.\nroutes/barcodes/scan.js: line 11, col 105, Missing semicolon.\nroutes/barcodes/scan.js: line 12, col 111, Missing semicolon.\nroutes/barcodes/scan.js: line 13, col 82, Missing semicolon.\nroutes/barcodes/scan.js: line 28, col 7, Missing semicolon.\n\n5 errors');
  });

});