define('bookalook/tests/routes/session/login-success.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/session/login-success.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/session/login-success.js should pass jshint.\nroutes/session/login-success.js: line 55, col 81, Missing semicolon.\n\n1 error');
  });

});