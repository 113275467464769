define('bookalook/routes/admin/white-list', ['exports', 'ember', 'bookalook/utils', 'bookalook/routes/admin/functions'], function (exports, Ember, utils, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    actions: {
      openNewVerifiedUserModal: function openNewVerifiedUserModal() {
        this.onOpenNewVerifiedUserModal();
      },
      openEditVerifiedUserModal: function openEditVerifiedUserModal(user) {
        this.onOpenEditVerifiedUserModal(user);
      },
      saveVerifiedUser: function saveVerifiedUser() {
        this.onSaveVerifiedUser();
      },
      openRemoveVerifiedUserModal: function openRemoveVerifiedUserModal(user) {
        this.onOpenRemoveVerifiedUserModal(user);
      },
      cancelVerifiedUser: function cancelVerifiedUser() {
        this.onCancelVerifiedUser();
      }

    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        verifiedUsers: this.store.findAll('register-white-list'),
        plans: this.store.findAll('plan')
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      //console.log(model.verifiedUsers);

      controller.set('isWhiteListPage', true);
      var availablePlans = model.plans.filter(function (p) {
        return p.get('active');
      });
      controller.set('availablePlans', availablePlans);

      var plans = model.plans.filter(function (p) {
        return true;
      });
      var arrayPlansIndexId = {};
      for (var i = 0, len = plans.length; i < len; i++) {
        arrayPlansIndexId[plans[i].id] = plans[i];
      }

      controller.set('arrayPlans', arrayPlansIndexId);

      var companyTypes = [{ 'id': 0, 'name': 'Agency' }, { 'id': 1, 'name': 'Brand' }, { 'id': 2, 'name': 'MP' }];
      controller.set('companyTypes', companyTypes);

      controller.set('saveDisabled', Ember['default'].computed('userToWhiteList.email', 'userToWhiteList.company_type', 'userToWhiteList.company_name', 'userToWhiteList.plan', function () {
        var user = controller.get('userToWhiteList');
        var error = false;
        if (!Ember['default'].isNone(user) && user.validate({ addErrors: false })) {
          error = false;
        } else {
          error = true;
        }

        return error;
      }));
    },

    onOpenNewVerifiedUserModal: function onOpenNewVerifiedUserModal() {
      var _t = this;
      var user = this.store.createRecord('register-white-list');
      _t.controller.set('userToWhiteList', user);
      //user.set('plan', null);
      Ember['default'].$('#verified-user-modal').modal();
      Ember['default'].openModal();
    },

    onOpenEditVerifiedUserModal: function onOpenEditVerifiedUserModal(user) {
      var _t = this;
      _t.controller.set('userToWhiteList', user);
      //user.set('plan', null);
      Ember['default'].$('#verified-user-modal').modal();
      Ember['default'].openModal();
    },

    onSaveVerifiedUser: function onSaveVerifiedUser() {
      var _t = this;
      var user = _t.controller.get('userToWhiteList');
      var promise = user.save().then(function () {
        Ember['default'].$('#verified-user-modal').modal('hide');
        //_t.send('openTopBar', 'User has been saved.');
      }, function (fail) {
        if (fail.errors[0].detail === "already-in-white") {
          _t.send('openTopBarWithTime', 'User already in white list.', 2000);
        }
        if (fail.errors[0].detail === "already-in-bal") {
          _t.send('openTopBarWithTime', 'User already in BOOKALOOK.', 2000);
        }
      });
      utils['default'].buttonLoading('#save-verified-user', promise);
    },

    onOpenRemoveVerifiedUserModal: function onOpenRemoveVerifiedUserModal(user) {
      var _t = this;
      utils['default'].openDefaultConfirmationModal(this, 'Warning', 'You will remove this user information from white list', 'If you accept, the data will be lost. Do you wish to continue?').done(function () {
        user.destroyRecord().then(function () {
          _t.send('openTopBar', 'User has been removed from white list.', 3000);
        });
        // _t.notifyPropertyChange('verifiedUsers');
        // _t.controller.notifyPropertyChange('verifiedUsers');
      });
    },

    onCancelVerifiedUser: function onCancelVerifiedUser() {
      var controller = this.controller;
      var user = controller.get('userToWhiteList');
      Ember['default'].$('#verified-user-modal').modal('hide');
      if (user.get('isNew')) {
        user.destroyRecord();
      } else {
        user.rollbackAttributes();
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-admin');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-admin');
    }

  });

});