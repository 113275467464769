define('bookalook/routes/bookings/ddt', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils', 'bookalook/routes/bookings/functions'], function (exports, Ember, config, UserValidatorMixin, utils, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      download: function download() {
        var skipSave = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
        this.onDownload(skipSave);
      },
      openAddSamplesModal: function openAddSamplesModal() {
        this.onOpenAddSamplesModal();
      },
      removeSample: function removeSample(sample) {
        this.onRemoveSample(sample);
      },
      saveLoanForm: function saveLoanForm() {
        this.onSaveLoanForm(this);
      },
      willTransition: function willTransition(transition) {
        functions['default'].onTransitionLoanForm(this, transition);
      },
      openContactAddressSelector: function openContactAddressSelector() {
        functions['default'].onOpenContactAddressSelector(this);
      }
    },

    model: function model(params) {
      var parcel;
      if (params.parcel && params.parcel !== "new") {
        parcel = this.store.findRecord('ddt-parcel', params.parcel);
      }

      return Ember['default'].RSVP.hash({
        collections: utils['default'].getBookingCollectionsForModal(this, params.id),
        booking: this.store.findRecord('booking', params.id),
        parcel: parcel,
        showroomInfo: utils['default'].getAPIJSON('bookings/' + params.id + '/showroomInfo')
      });
    },

    setupController: function setupController(controller, model) {
      Ember['default'].user.reload();

      controller.setProperties(model);
      controller.set('isLoaded', false);

      this.controller.set('userLogged', Ember['default'].user);
      this.controller.set('loanFormPage', false);
      this.controller.set('ddtFormPage', true);

      var booking = controller.get('booking');
      if (!controller.get('parcel')) {
        controller.set('parcel', booking.createDDTParcel());
        controller.get('parcel').getDDTParcelNumber().then(function (ddtNumber) {
          controller.set('parcel.number', ddtNumber);
        });
      }

      if (Ember['default'].user.get('isMP')) {

        this.controller.set('isLoading', true);
        var samplesToLoad = model.parcel.get('samples');
        var samples = [];
        samplesToLoad.forEach(function (sample) {
          if (sample.id) {
            samples.push(sample.id);
          }
        });

        // MP: MEDIANTE NUEVO ENTRYPOINT
        if (samples.length) {
          utils['default'].postAPIJSON('collections/samples', { 'samples': samples }).then(function (collection) {
            controller.set('looks', collection.collection[0].albums[0].looks);
            controller.set('collections', collection.collection);
            controller.set('isLoading', false);
            controller.set('brandLogo', collection.collection[0].brand.avatar);
          });
        }
      }

      if (Ember['default'].user.get('isPR')) {

        var sortedList = controller.get('collections').sortBy('brand.name', 'collectionType', 'year');
        controller.set('collections', sortedList);

        //Barcodes: Obtain for checked samples
        var samplesId = [];

        controller.get('booking.samples').forEach(function (sample) {
          if (sample.id) {
            samplesId.push(sample.id);
          }
        });

        utils['default'].postAPIJSON('barcode/base64/samples', { samples: samplesId }).then(function (data) {
          var barcodes64 = data;
          controller.set('barcodes64', barcodes64);
        });
      }

      //Barcodes
      if (Ember['default'].user.get('isMP')) {
        var samplesId = [];
        controller.get('booking.samples').forEach(function (sample) {
          if (sample.id) {
            samplesId.push(parseInt(sample.id));
          }
        });

        utils['default'].postAPIJSON('barcode/base64/samples', { samples: samplesId }).then(function (data) {
          var barcodes64 = data;
          controller.set('barcodes64', barcodes64);
        });
      }

      utils['default'].getSamples().setEach('checked', false);
      controller.get('parcel.samples').setEach('checked', true);
      controller.set('today', new Date());

      controller.set('saveVettori', false);
      controller.set('PDFURL', null);
    },

    onOpenAddSamplesModal: function onOpenAddSamplesModal() {
      Ember['default'].$('#add-samples-modal').modal();
    },

    onRemoveSample: function onRemoveSample(sample) {
      var parcelSamples = this.controller.get('parcel.samples');
      parcelSamples.removeObject(sample);

      sample.set('checked', false);
    },

    onDownload: function onDownload() {
      var _this = this;

      var skipSave = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      this.send('openMessageModal', 'Generating loan form...');
      if (skipSave) {
        this._generatePDF();
      } else {
        functions['default'].onSaveLoanForm(this, 'ddt').then(function () {
          return _this._generatePDF();
        });
      }
    },

    onSaveLoanForm: function onSaveLoanForm(route) {
      functions['default'].onSaveLoanForm(route, 'ddt');
    },

    _generatePDF: function _generatePDF() {
      var _this2 = this;

      var avatar = this.controller.get('showroomInfo.avatar');
      var lA = this.controller.get('showroomInfo.legalAddress');
      var vat = this.controller.get('showroomInfo.vat');
      var addressFooter = "";
      if (!Ember['default'].isBlank(lA)) {
        addressFooter = lA;
      }
      if (!Ember['default'].isBlank(vat)) {
        addressFooter = addressFooter + " Vat Number " + vat;
      }
      var legalAddress = this.controller.get('parcel.returnAddress');
      var headerImage = this.controller.get('showroomInfo.avatar');
      var ddtNumber = this.controller.get('parcel.number');
      var bookingId = this.controller.get('booking.id');

      var headerData = Ember['default'].formatDate(this.controller.get('parcel.date'));
      var $ = Ember['default'].$;

      // Datepicker fix
      $('.inline_date_value').each(function (index, el) {
        return $(el).attr('value', $(el).val());
      });

      var container = Ember['default'].$('#pdf_container').clone();
      container.css("background-color", "white");

      // Datepicker fix
      container.find('.inline_date_value').attr('value', Ember['default'].$('.inline_date_value').val());

      var printHTML = '<html><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><title>Bookalook</title><meta name="viewport" content="width=device-width, initial-scale=1">';
      var pdfTag = $('#pdf_container').prop('outerHTML');
      /*  let linkTags = $('link').clone();
         let cssLinkTags = $.grep(linkTags, (element) => {
          return element.href.indexOf('.css') >= 0;
        });
         $(cssLinkTags).each((index, element) => {
          let href = element.href;
          $(element).attr('href', href);
          printHTML += $(element).prop('outerHTML');
        });*/

      printHTML += this._getAllStyles();
      printHTML += '<style>tr {page-break-inside: avoid !important;} td {page-break-inside: avoid !important; }th { page-break-inside: avoid !important;}tbody {page-break-inside: avoid !important;}thead {page-break-inside: avoid !important;} tfoot {page-break-inside: avoid !important;}</style>';
      //printHTML += '</head><body style="width: 100% !important; font-size: 12px;"><div class="row"><div class="col-xs-12 relative no-padding">';
      printHTML += '</head><body style="width: 100% !important; overflow: visible; font-size: 12px;"><div class="row" style="padding: 2em 50px;"><div class="col-xs-12 relative no-padding">';
      printHTML += pdfTag;
      printHTML += '</div></div></body></html>';

      utils['default'].postAPIJSON('ddtPdf', { html: printHTML,
        addressFooter: addressFooter,
        avatar: avatar,
        legalAddress: legalAddress,
        headerImage: headerImage,
        headerData: headerData,
        bookingId: bookingId,
        ddtNumber: ddtNumber }).then(function (res) {
        var link = res.booking.path;
        var linkId = link.substr(link.lastIndexOf('/') + 1);
        var PDFURL = config['default'].APIURL + "pdfs/loanforms/" + linkId;

        //let PDFURL =config.APIHost + '/' + res.booking.path;
        _this2._openNewTab(PDFURL);
        _this2.controller.set('PDFURL', PDFURL);
        _this2.send('closeMessageModal');
      });
    },

    _openNewTab: function _openNewTab(link) {
      /*
       var frm = Ember.$('<form autocomplete="off"   method="get" action="' + link + '" target="_blank"></form>');
       Ember.$("body").append(frm);
       frm.submit().remove();
       */
      var fixedLink = link.substr(link.lastIndexOf('/') + 1);
      window.open(config['default'].APIURL + 'pdfs/loanforms/' + fixedLink, '_blank' // <- This is what makes it open in a new window.
      );
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-bookings loan-form');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-bookings loan-form');
    },
    _getAllStyles: function _getAllStyles() {
      return '<link rel="stylesheet" type="text/css" href="../template/ddt_css_purified.css">';
      //NO MORE WEIRD CODE (SOMETIMES BREAK)
      var rulesUsed = [];
      // main part: walking through all declared style rules
      // and checking, whether it is applied to some element
      var sheets = document.styleSheets;
      for (var c = 0; c < sheets.length; c++) {
        var rules = sheets[c].rules || sheets[c].cssRules;
        for (var r = 0; r < rules.length; r++) {
          rulesUsed.push(rules[r]);
        }
      }

      var style = rulesUsed.map(function (cssRule) {
        if (cssRule.cssText !== undefined) {
          var cssText = cssRule.cssText;
        } else if (cssRule.style && cssRule.style.cssText) {
          var cssText = cssRule.style.cssText;
        }
        // some beautifying of css
        return cssText.replace(/(\{|;)\s+/g, "\$1\n  ").replace(/\A\s+}/, "}");
        //                 set indent for css here ^
      }).join("\n");
      return "<style>\n" + style + "\n</style>\n\n";
    }
  });

});