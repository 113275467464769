define('bookalook/templates/admin/modals/edit-plan', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/admin/modals/edit-plan.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New contact ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","edit-plan");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        dom.setAttribute(el3,"id","test_modal_contact_manually");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title");
        var el6 = dom.createTextNode("Edit plan");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Basic Settings");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","hint-right no-padding-left col-xs-6 ember-view");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-group validated-input checkbox-plan");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                 ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        var el10 = dom.createTextNode("Email support");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","hint-right no-padding-right col-xs-6 ember-view");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-group validated-input checkbox-plan");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                 ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        var el10 = dom.createTextNode("Access to contacts");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n\n\n\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_cancel");
        dom.setAttribute(el5,"data-dismiss","modal");
        dom.setAttribute(el5,"data-target","#edit-plan");
        dom.setAttribute(el5,"data-toggle","modal");
        var el6 = dom.createTextNode("Cancel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"data-toggle","modal");
        dom.setAttribute(el5,"class","btn_primary");
        var el6 = dom.createTextNode("Create");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 1]);
        var element1 = dom.childAt(element0, [3, 1, 1]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(15);
        morphs[0] = dom.createMorphAt(element1,3,3);
        morphs[1] = dom.createMorphAt(element1,5,5);
        morphs[2] = dom.createMorphAt(element1,7,7);
        morphs[3] = dom.createMorphAt(element1,9,9);
        morphs[4] = dom.createMorphAt(element1,11,11);
        morphs[5] = dom.createMorphAt(element1,13,13);
        morphs[6] = dom.createMorphAt(element1,15,15);
        morphs[7] = dom.createMorphAt(element1,17,17);
        morphs[8] = dom.createMorphAt(element1,19,19);
        morphs[9] = dom.createMorphAt(element1,21,21);
        morphs[10] = dom.createMorphAt(dom.childAt(element1, [23, 1]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element1, [25, 1]),1,1);
        morphs[12] = dom.createElementMorph(element3);
        morphs[13] = dom.createAttrMorph(element4, 'disabled');
        morphs[14] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [
        ["inline","validated-input",[],["label","Name*","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[13,56],[13,66]]]]],[],[]],"property","name","class","hint-left no-padding-left col-xs-6"],["loc",[null,[13,12],[13,127]]]],
        ["inline","validated-input",[],["label","Number of Brands*","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[14,65],[14,75]]]]],[],[]],"property","maxBrands","class","hint-right no-padding-right col-xs-6"],["loc",[null,[14,12],[14,143]]]],
        ["inline","validated-input",[],["label","Number of Showrooms*","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[15,66],[15,76]]]]],[],[]],"property","maxShowrooms","class","hint-left no-padding-left col-xs-6"],["loc",[null,[15,12],[15,145]]]],
        ["inline","validated-input",[],["label","Number of Bookings*","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[16,67],[16,77]]]]],[],[]],"property","maxBookings","class","hint-right no-padding-right col-xs-6"],["loc",[null,[16,12],[16,147]]]],
        ["inline","validated-input",[],["label","Number of Samples*","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[17,68],[17,78]]]]],[],[]],"property","maxSamples","class","hint-left no-padding-left col-xs-6"],["loc",[null,[17,12],[17,145]]]],
        ["inline","validated-input",[],["label","Number of Users*","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[18,65],[18,75]]]]],[],[]],"property","maxUsers","class","hint-right no-padding-right col-xs-6"],["loc",[null,[18,12],[18,142]]]],
        ["inline","validated-input",[],["label","Stripe identifier","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[19,66],[19,75]]]]],[],[]],"property","idStripe","class","no-padding-left hint-right col-xs-6"],["loc",[null,[19,12],[19,141]]]],
        ["inline","validated-input",[],["label","Currency* (EUR / GBP / USD)","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[20,75],[20,84]]]]],[],[]],"property","currency","class","hint-right no-padding-right col-xs-6"],["loc",[null,[20,12],[20,151]]]],
        ["inline","validated-input",[],["label","Weight*","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[21,55],[21,64]]]]],[],[]],"property","weight","class","hint-right no-padding-left col-xs-12"],["loc",[null,[21,12],[21,129]]]],
        ["inline","textarea",[],["rows",5,"placeholder","Description","model",["subexpr","@mut",[["get","planToAdd",["loc",[null,[22,62],[22,71]]]]],[],[]],"value",["subexpr","@mut",[["get","planToAdd.description",["loc",[null,[22,78],[22,99]]]]],[],[]],"class","col-xs-12"],["loc",[null,[22,12],[22,119]]]],
        ["inline","input",[],["type","checkbox","label","Email support","checked",["subexpr","@mut",[["get","planToEdit.email",["loc",[null,[26,70],[26,86]]]]],[],[]],"class",""],["loc",[null,[26,16],[26,97]]]],
        ["inline","input",[],["type","checkbox","model",["subexpr","@mut",[["get","planToEdit",["loc",[null,[32,46],[32,56]]]]],[],[]],"checked",["subexpr","@mut",[["get","planToEdit.contacts",["loc",[null,[32,65],[32,84]]]]],[],[]],"class",""],["loc",[null,[32,16],[32,95]]]],
        ["element","action",["cancelEdition",["get","planToEdit",["loc",[null,[46,56],[46,66]]]]],[],["loc",[null,[46,30],[46,69]]]],
        ["attribute","disabled",["get","createNewPlanDisabled",["loc",[null,[47,69],[47,90]]]]],
        ["element","action",["saveEditPlan"],[],["loc",[null,[47,30],[47,57]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});