define('bookalook/components/inventory-report-modal', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils'], function (exports, Ember, config, utils) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      var _this = this;

      utils['default'].getBrands().then(function (response) {
        _this.set('brands', response.brand);
        if (response.brand.length == 1) {
          _this.set('brandFilterEnabled', false);
          _this.set('brandFilter', response.brand);
        } else {
          _this.set('brandFilterEnabled', true);
          _this.set('showBrandFilter', true);
        }
      });
      this.set('brandFilterEnabled', false);
      this.set('showBrandFilter', false);
      this.set('collectionFilterEnabled', false);
      this.set('collectionBrand', []);
      this.set('albumFilterEnabled', false);
      this.set('albumsCollection', []);
      this.set('brandFilter', null);
    },

    actions: {
      getReport: function getReport() {
        var options = ['showroom=' + Ember['default'].showroomId];

        var brandFilters = this.get('brandFilter');

        if (brandFilters) {
          (function () {
            var listOfBrands = [];
            // si id es null no se manda nada porque selecciono All Brands
            brandFilters.forEach(function (item) {
              if (item.id && listOfBrands !== null) {
                listOfBrands.push(item.id);
              } else {
                listOfBrands = null;
              }
            });
            if (listOfBrands && listOfBrands.length > 0) {
              options.push('brand=' + listOfBrands.join(','));
            }
          })();
        }

        if (this.get('collectionFilter.id')) {
          options.push('collection=' + this.get('collectionFilter.id'));
        }

        if (this.get('albumFilter.id')) {
          options.push('album=' + this.get('albumFilter.id'));
        }

        options.push('user=' + Ember['default'].user.id);

        window.open(config['default'].APIURL + 'inventory_report?' + options.join('&'), '_blank');
        Ember['default'].$('#inventory-report-modal').modal('hide');
      }
    },

    updateCollections: (function () {
      var _this2 = this;

      //limpiar collection y album
      var brands = this.get('brandFilter');
      this.set('collectionFilterEnabled', false);
      this.set('collectionFilter', null);
      if (brands) {
        var brandsId = brands.map(function (item) {
          return item.id;
        });
        utils['default'].getCollectionsByBrands(brandsId.join(',')).then(function (response) {
          _this2.set('collectionFilterEnabled', true);
          _this2.set('collectionBrand', response.collection);
        });
      }
    }).observes('brandFilter'),

    updateAlbums: (function () {
      var _this3 = this;

      //limpiar album
      this.set('albumFilterEnabled', false);
      this.set('albumFilter', null);
      if (this.get('collectionFilter')) {
        utils['default'].getAlbumsByCollection(this.get('collectionFilter').id).then(function (response) {
          _this3.set('albumFilterEnabled', true);
          _this3.set('albumsCollection', response.album);
        });
      }
    }).observes('collectionFilter'),

    allBrands: Ember['default'].computed('brands', function () {
      var brands = Ember['default'].A([{ id: null, name: 'All brands' }]);
      if (this.get('brands')) {
        brands = brands.concat(this.get('brands'));
      }
      return brands;
    }),

    collectionsByBrand: Ember['default'].computed('collectionBrand', function () {

      var collections = Ember['default'].A([{ id: null, name: 'All Collections' }]);
      if (this.get('collectionBrand')) {
        collections = collections.concat(this.get('collectionBrand'));
      }
      return collections;
    }),

    albumsByCollection: Ember['default'].computed('albumsCollection', function () {
      var albums = Ember['default'].A([{ id: null, name: 'All Albums' }]);
      if (this.get('albumsCollection')) {
        albums = albums.concat(this.get('albumsCollection'));
      }
      return albums;
    })

  });

});