define('bookalook/tests/helpers/wait-for-modal-open', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Test.registerAsyncHelper('waitForModalOpen', function (app, modal) {
    return Ember['default'].Test.promise(function (resolve) {
      find(modal).on('shown.bs.modal', function () {
        resolve();
      });
    });
  });

});