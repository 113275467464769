define('bookalook/templates/bookings/partials/booking-sample-row-brand-refactor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 8
                },
                "end": {
                  "line": 15,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element10, 'src');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",[["get","sample.colour2URL",["loc",[null,[14,26],[14,43]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 8
                },
                "end": {
                  "line": 18,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element9, 'src');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",[["get","sample.colour3URL",["loc",[null,[17,26],[17,43]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 6
                },
                "end": {
                  "line": 30,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-circle-o-notch fa-spin");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 8
                  },
                  "end": {
                    "line": 33,
                    "column": 8
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","sample_expired");
                var el2 = dom.createTextNode("EXPIRED");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 37,
                          "column": 16
                        },
                        "end": {
                          "line": 44,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1,"class","btn_bal_circle_no_border btn_camera YES bal-tooltip-container");
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","icon");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","bal-tooltip");
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      var el4 = dom.createTextNode("Mark as featured");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element8 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createElementMorph(element8);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["setFeaturedSample","YES",["get","sample",["loc",[null,[38,61],[38,67]]]]],[],["loc",[null,[38,26],[38,69]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 45,
                          "column": 16
                        },
                        "end": {
                          "line": 52,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1,"class","btn_bal_circle_no_border btn_camera NO bal-tooltip-container");
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","icon");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","bal-tooltip");
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      var el4 = dom.createTextNode("Mark as not featured");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createElementMorph(element7);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["setFeaturedSample","NO",["get","sample",["loc",[null,[46,60],[46,66]]]]],[],["loc",[null,[46,26],[46,68]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 53,
                          "column": 16
                        },
                        "end": {
                          "line": 60,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1,"class","btn_bal_circle_no_border btn_arrow_reverse bal-tooltip-container move-to-left");
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","icon");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","bal-tooltip");
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      var el4 = dom.createTextNode("Reverse featured");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element6 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createElementMorph(element6);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["setFeaturedSample","TBC",["get","sample",["loc",[null,[54,61],[54,67]]]]],[],["loc",[null,[54,26],[54,69]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 60,
                          "column": 16
                        },
                        "end": {
                          "line": 67,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1,"class","btn_bal_circle_no_border btn_arrow_reverse bal-tooltip-container move-to-left");
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","icon");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","bal-tooltip");
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      var el4 = dom.createTextNode("Reverse check-in");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element5 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createElementMorph(element5);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["openReverseCheckInModal",["get","sample",["loc",[null,[61,147],[61,153]]]]],[],["loc",[null,[61,112],[61,155]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 36,
                        "column": 14
                      },
                      "end": {
                        "line": 68,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","unless",[["subexpr","if-equal",[["get","sample.featured",["loc",[null,[37,36],[37,51]]]],"YES"],[],["loc",[null,[37,26],[37,58]]]]],[],0,null,["loc",[null,[37,16],[44,27]]]],
                    ["block","unless",[["subexpr","if-equal",[["get","sample.featured",["loc",[null,[45,36],[45,51]]]],"NO"],[],["loc",[null,[45,26],[45,57]]]]],[],1,null,["loc",[null,[45,16],[52,27]]]],
                    ["block","unless",[["subexpr","if-equal",[["get","sample.featured",["loc",[null,[53,36],[53,51]]]],"TBC"],[],["loc",[null,[53,26],[53,58]]]]],[],2,3,["loc",[null,[53,16],[67,27]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 71,
                            "column": 18
                          },
                          "end": {
                            "line": 80,
                            "column": 18
                          }
                        },
                        "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("button");
                        dom.setAttribute(el1,"class","btn_bal_circle_no_border btn_arrow_reverse bal-tooltip-container move-to-left");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","icon");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","bal-tooltip");
                        var el3 = dom.createTextNode("\n                        ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("span");
                        var el4 = dom.createTextNode("Reverse check-in");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                      ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element4 = dom.childAt(fragment, [1]);
                        var morphs = new Array(1);
                        morphs[0] = dom.createElementMorph(element4);
                        return morphs;
                      },
                      statements: [
                        ["element","action",["openReverseCheckInModal",["get","sample",["loc",[null,[73,149],[73,155]]]]],[],["loc",[null,[73,114],[73,157]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 70,
                          "column": 16
                        },
                        "end": {
                          "line": 81,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","unless",[["get","sample.shoppingListInBookingShowroomCustom",["loc",[null,[71,28],[71,70]]]]],[],0,null,["loc",[null,[71,18],[80,29]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.7",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 84,
                              "column": 20
                            },
                            "end": {
                              "line": 91,
                              "column": 20
                            }
                          },
                          "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                      ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("button");
                          dom.setAttribute(el1,"class","btn_bal_circle_no_border btn_arrow_reverse bal-tooltip-container move-to-left");
                          var el2 = dom.createTextNode("\n                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","icon");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","bal-tooltip");
                          var el3 = dom.createTextNode("\n                          ");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createElement("span");
                          var el4 = dom.createTextNode("Reverse check-out");
                          dom.appendChild(el3, el4);
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                        ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element3 = dom.childAt(fragment, [1]);
                          var morphs = new Array(1);
                          morphs[0] = dom.createElementMorph(element3);
                          return morphs;
                        },
                        statements: [
                          ["element","action",["openReverseCheckOutModal",["get","sample",["loc",[null,[85,152],[85,158]]]]],[],["loc",[null,[85,116],[85,160]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 83,
                            "column": 18
                          },
                          "end": {
                            "line": 92,
                            "column": 18
                          }
                        },
                        "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","if-equal",[["get","sample.showroom.id",["loc",[null,[84,36],[84,54]]]],["get","Ember.showroom.id",["loc",[null,[84,55],[84,72]]]]],[],["loc",[null,[84,26],[84,73]]]]],[],0,null,["loc",[null,[84,20],[91,27]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 82,
                          "column": 16
                        },
                        "end": {
                          "line": 93,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","unless",[["get","sample.shoppingListInBooking",["loc",[null,[83,28],[83,56]]]]],[],0,null,["loc",[null,[83,18],[92,29]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 68,
                        "column": 14
                      },
                      "end": {
                        "line": 95,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[70,29],[70,54]]]],"returned"],[],["loc",[null,[70,22],[70,66]]]]],[],0,null,["loc",[null,[70,16],[81,23]]]],
                    ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[82,29],[82,54]]]],"out","check in today"],[],["loc",[null,[82,22],[82,78]]]]],[],1,null,["loc",[null,[82,16],[93,23]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 10
                    },
                    "end": {
                      "line": 98,
                      "column": 10
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","hover-container");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","sample.featured",["loc",[null,[36,20],[36,35]]]]],[],0,1,["loc",[null,[36,14],[95,21]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 100,
                        "column": 12
                      },
                      "end": {
                        "line": 102,
                        "column": 12
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element2, 'class');
                    morphs[1] = dom.createMorphAt(element2,0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","class",["concat",["sample_",["get","sample.featuredLabel",["loc",[null,[101,36],[101,56]]]]]]],
                    ["content","sample.featuredLabel",["loc",[null,[101,60],[101,84]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 102,
                        "column": 12
                      },
                      "end": {
                        "line": 104,
                        "column": 12
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element1, 'class');
                    morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","class",["concat",["sample_",["get","sample.featuredLabel",["loc",[null,[103,35],[103,55]]]]]]],
                    ["content","sample.featuredLabel",["loc",[null,[103,65],[103,89]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 99,
                      "column": 10
                    },
                    "end": {
                      "line": 105,
                      "column": 10
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","if-equal",[["get","sample.featured",["loc",[null,[100,28],[100,43]]]],"TBC"],[],["loc",[null,[100,18],[100,50]]]]],[],0,1,["loc",[null,[100,12],[104,19]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 106,
                        "column": 12
                      },
                      "end": {
                        "line": 106,
                        "column": 64
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"src","images/warning.svg");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 110,
                            "column": 21
                          },
                          "end": {
                            "line": 110,
                            "column": 204
                          }
                        },
                        "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","color_primary");
                        var el2 = dom.createTextNode("Booking #");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                        return morphs;
                      },
                      statements: [
                        ["content","sample.shoppingListInBookingShowroomCustom",["loc",[null,[110,151],[110,197]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 109,
                          "column": 14
                        },
                        "end": {
                          "line": 111,
                          "column": 14
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["bookings.booking-details",["get","sample.shoppingListInBooking",["loc",[null,[110,59],[110,87]]]],["subexpr","query-params",[],["search",""],["loc",[null,[110,88],[110,112]]]]],[],0,null,["loc",[null,[110,21],[110,216]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 111,
                          "column": 14
                        },
                        "end": {
                          "line": 113,
                          "column": 14
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("Booking #");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["content","sample.shoppingListInBookingShowroomCustom",["loc",[null,[112,30],[112,76]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 12
                      },
                      "end": {
                        "line": 114,
                        "column": 12
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","sample.bookingPermission",["loc",[null,[109,20],[109,44]]]]],[],0,1,["loc",[null,[109,14],[113,21]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 105,
                      "column": 10
                    },
                    "end": {
                      "line": 115,
                      "column": 10
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [3]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createAttrMorph(element0, 'class');
                  morphs[2] = dom.createMorphAt(element0,0,0);
                  morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","sample.warning",["loc",[null,[106,18],[106,32]]]]],[],0,null,["loc",[null,[106,12],[106,71]]]],
                  ["attribute","class",["concat",["sample_",["get","sample.shoppingListStatus",["loc",[null,[107,34],[107,59]]]]]]],
                  ["content","sample.shoppingListStatus",["loc",[null,[107,63],[107,92]]]],
                  ["block","if",[["get","sample.shoppingListInBooking",["loc",[null,[108,18],[108,46]]]]],[],1,null,["loc",[null,[108,12],[114,19]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 8
                  },
                  "end": {
                    "line": 116,
                    "column": 8
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","isBookingMember",["loc",[null,[34,16],[34,31]]]]],[],0,null,["loc",[null,[34,10],[98,17]]]],
                ["block","if",[["get","sample.featured",["loc",[null,[99,16],[99,31]]]]],[],1,2,["loc",[null,[99,10],[115,17]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 6
                },
                "end": {
                  "line": 117,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","is-in",[["get","booking.status",["loc",[null,[31,21],[31,35]]]],"EXPIRED"],[],["loc",[null,[31,14],[31,46]]]]],[],0,1,["loc",[null,[31,8],[116,15]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 120,
                  "column": 6
                },
                "end": {
                  "line": 122,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","partial",["bookings/partials/booking-sample-row-buttons-brand-refactor"],[],["loc",[null,[121,8],[121,81]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 22
              },
              "end": {
                "line": 130,
                "column": 0
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","sample-row-with-hover");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_sample pointer");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_count pointer");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","gray-light-span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("/");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_colour pointer");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3,"class","list-block");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("li");
            var el5 = dom.createElement("img");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_size pointer");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_status");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_actions booking-sample-row-buttons");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","table_col_calendar");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"class","image-calendar_uirefactor");
            dom.setAttribute(el4,"src","images/calendar.svg");
            dom.setAttribute(el4,"data-target","#sample-historial");
            dom.setAttribute(el4,"data-toggle","modal");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1]);
            var element12 = dom.childAt(element11, [1]);
            var element13 = dom.childAt(element11, [3]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element11, [5]);
            var element16 = dom.childAt(element15, [1]);
            var element17 = dom.childAt(element16, [1, 0]);
            var element18 = dom.childAt(element11, [7]);
            var element19 = dom.childAt(element11, [14, 1, 1]);
            var morphs = new Array(14);
            morphs[0] = dom.createElementMorph(element12);
            morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
            morphs[2] = dom.createElementMorph(element13);
            morphs[3] = dom.createMorphAt(element14,0,0);
            morphs[4] = dom.createMorphAt(element14,2,2);
            morphs[5] = dom.createElementMorph(element15);
            morphs[6] = dom.createAttrMorph(element17, 'src');
            morphs[7] = dom.createMorphAt(element16,3,3);
            morphs[8] = dom.createMorphAt(element16,4,4);
            morphs[9] = dom.createElementMorph(element18);
            morphs[10] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
            morphs[11] = dom.createMorphAt(dom.childAt(element11, [10]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element11, [12]),1,1);
            morphs[13] = dom.createElementMorph(element19);
            return morphs;
          },
          statements: [
            ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[3,75],[3,81]]]]],[],["loc",[null,[3,41],[3,84]]]],
            ["content","sample.itemLabel",["loc",[null,[4,12],[4,32]]]],
            ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[7,74],[7,80]]]]],[],["loc",[null,[7,40],[7,83]]]],
            ["inline","plus-one",[["get","sampleIndex",["loc",[null,[8,47],[8,58]]]]],[],["loc",[null,[8,36],[8,60]]]],
            ["content","sampleGroup.all.length",["loc",[null,[8,61],[8,87]]]],
            ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[10,75],[10,81]]]]],[],["loc",[null,[10,41],[10,84]]]],
            ["attribute","src",["concat",[["get","sample.colour1URL",["loc",[null,[12,24],[12,41]]]]]]],
            ["block","if",[["get","sample.colour2URL",["loc",[null,[13,14],[13,31]]]]],[],0,null,["loc",[null,[13,8],[15,15]]]],
            ["block","if",[["get","sample.colour3URL",["loc",[null,[16,14],[16,31]]]]],[],1,null,["loc",[null,[16,8],[18,15]]]],
            ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[21,73],[21,79]]]]],[],["loc",[null,[21,39],[21,82]]]],
            ["content","sample.size",["loc",[null,[22,12],[22,27]]]],
            ["block","if",[["get","sample.loadingStatus",["loc",[null,[28,12],[28,32]]]]],[],2,3,["loc",[null,[28,6],[117,13]]]],
            ["block","unless",[["get","sample.archived",["loc",[null,[120,16],[120,31]]]]],[],4,null,["loc",[null,[120,6],[122,17]]]],
            ["element","action",["openMinicalendarModal",["get","sample",["loc",[null,[126,46],[126,52]]]],null,["get","booking.id",["loc",[null,[126,58],[126,68]]]]],[],["loc",[null,[126,13],[126,70]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 130,
              "column": 7
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sample.visible",["loc",[null,[1,28],[1,42]]]]],[],0,null,["loc",[null,[1,22],[130,7]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 131,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-brand-refactor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","sample.checked",["loc",[null,[1,6],[1,20]]]]],[],0,null,["loc",[null,[1,0],[130,14]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});