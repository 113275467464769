define('bookalook/tests/routes/ddt-samples/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/ddt-samples/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/ddt-samples/index.js should pass jshint.\nroutes/ddt-samples/index.js: line 36, col 19, Duplicate key \'willTransition\'.\nroutes/ddt-samples/index.js: line 79, col 27, [\'params\'] is better written in dot notation.\nroutes/ddt-samples/index.js: line 79, col 58, [\'parcel\'] is better written in dot notation.\nroutes/ddt-samples/index.js: line 178, col 46, Missing semicolon.\nroutes/ddt-samples/index.js: line 422, col 42, [\'name\'] is better written in dot notation.\nroutes/ddt-samples/index.js: line 443, col 84, Unreachable \';\' after \'return\'.\nroutes/ddt-samples/index.js: line 443, col 84, Unnecessary semicolon.\nroutes/ddt-samples/index.js: line 713, col 19, Duplicate key \'initializeFilters\'.\n\n8 errors');
  });

});