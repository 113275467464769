define('bookalook/models/register-white-list', ['exports', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment', 'bookalook/utils'], function (exports, DS, Validator, config, utils) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    email: DS['default'].attr('string'),
    company_type: DS['default'].attr('string'),
    company_name: DS['default'].attr('string'),
    plan: DS['default'].attr('number'),
    //brand:          DS.belongsTo('brand', {async: true, inverse: null}),
    //agency:         DS.belongsTo('agency', {async: true, inverse: null}),
    //showroom:       DS.belongsTo('showroom', {async: true, inverse: null}),

    companyTypeObserver: (function () {
      var type = this.get('company_type');
      if (!Ember.isBlank(type) && type == "MP") {
        this.set('company_name', null);
        this.set('plan', null);
      }
    }).observes('company_type'),

    validations: {
      'email': {
        presence: true,
        custom: [{
          validation: function validation(key, value, model) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!Ember.isNone(model.get('email')) && re.test(model.get('email'))) {
              return true;
            } else {
              return false;
            }
          }
        }]
      },

      'company_type': {
        presence: true
      },

      'company_name': {
        custom: [{
          validation: function validation(key, value, model) {
            if (!Ember.isBlank(model.get('company_type')) && model.get('company_type') == "MP") {
              return true;
            } else {
              return !Ember.isBlank(model.get('company_name')) && model.get('company_name').length > 2;
            }
          }
        }]
      },

      'plan': {
        custom: [{
          validation: function validation(key, value, model) {
            if (!Ember.isNone(model.get('company_type')) && model.get('company_type') == "MP") {
              return true;
            } else {
              return !Ember.isNone(model.get('plan'));
            }
          }
        }]
      }
    }
  });

});