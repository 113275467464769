define('bookalook/routes/bookings/index', ['exports', 'ember', 'ember-data', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/contacts-lists/functions', 'bookalook/utils', 'bookalook/routes/bookings/functions', 'bookalook/routes/bookings/new-booking', 'bookalook/filters_newbooking'], function (exports, Ember, DS, UserValidatorMixin, contactFunctions, utils, ___functions, newBookingFunctions, filters) {

  'use strict';

  var numBookings = 0;
  var limitPlanBookingsNew = 0;
  var isHidden = false;

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    poll: Ember['default'].inject.service(),

    actions: {
      seeToast: function seeToast() {
        this.send('openPositiveTopBar');
      },
      updateDates: function updateDates() {
        this.onUpdateDates();
      },
      addAnotherAddress: function addAnotherAddress() {
        contactFunctions['default'].onAddAnotherAddress(this);
      },
      openNewBookingModal: function openNewBookingModal() {
        newBookingFunctions['default'].onOpenNewBookingModal(this);
      },
      keySearch: function keySearch() {
        if (arguments.length == 2) {
          var _event = arguments[1];
          if (_event.keyCode == 13) {
            console.log('keysearch');
            newBookingFunctions['default'].filterByWord(this);
          }
        }
      },
      openNewContactModal: function openNewContactModal() {
        this.onOpenNewContactModal();
      },
      continueStep2: function continueStep2() {
        this.onContinueStep2();
      },
      continueStep3: function continueStep3() {
        this.onContinueStep3();
      },
      continueNewBookingStep2: function continueNewBookingStep2() {
        this.onContinueNewBookingStep2();
      },
      createOverview: function createOverview() {
        this.onCreateOverview();
      },
      createOverviewAndBooking: function createOverviewAndBooking() {
        this.onCreateOverviewAndBooking(event);
      },
      selectContactAddress: function selectContactAddress(address) {
        this.onSelectContactAddress(address);
      },
      selectReturnAddress: function selectReturnAddress(address) {
        this.onSelectReturnAddress(address);
      },
      saveNewContact: function saveNewContact(idModal) {
        this.onSaveNewContact(idModal);
      },
      saveNewContactRefactor: function saveNewContactRefactor(idModal) {
        this.onSaveNewContactRefactor(idModal);
      },
      goToDetails: function goToDetails(bookingId) {
        this.onGoToDetails(bookingId);
      },
      goToDetailsBrandRefactor: function goToDetailsBrandRefactor(bookingId) {
        this.onGoToDetailsBrandRefactor(bookingId);
      },
      changeAddress: function changeAddress(address) {
        this.onChangeAddress(address);
      },
      changeReturnAddress: function changeReturnAddress(address) {
        this.onChangeReturnAddress(address);
      },
      openSelectFromAddressBook: function openSelectFromAddressBook(address) {
        this.onOpenSelectFromAddressBook(address);
      },
      openSelectFromReturnAddressBook: function openSelectFromReturnAddressBook(address) {
        this.onOpenSelectFromReturnAddressBook(address);
      },
      saveDate: function saveDate(bookingToAdd) {
        this.onSaveDate(bookingToAdd);
      },
      cancelSaveDate: function cancelSaveDate(bookingToAdd) {
        this.onCancelSaveDate(bookingToAdd);
      },
      cancelEdition: function cancelEdition() {
        this.onCancelEdition();
      },
      showPersonalBookings: function showPersonalBookings() {
        this.controller.set('bookingFilter', 1);
      },
      showShowroomBookings: function showShowroomBookings() {
        this.onShowShowroomBookings();
      },
      showClosedBookings: function showClosedBookings() {
        this.controller.set('bookingFilter', 3);
      },
      setBookingsToShow: function setBookingsToShow(type) {
        this.onSetBookingsToShow(type);
      },
      clearCallInFields: function clearCallInFields(parcelToAdd) {
        this.onClearCallInFields(parcelToAdd);
      },
      clearReturnFields: function clearReturnFields(parcelToadd) {
        this.onClearReturnFields(parcelToadd);
      },
      checkLookSamples: function checkLookSamples(look) {
        ___functions['default'].onCheckLookSamples(look);
      },
      openReportModal: function openReportModal() {
        if (this.controller.get('userLogged').get('isDesigner')) {
          this.send('openLimitationReachedModal', 'Report');
        } else {
          Ember['default'].$('#booking-report-modal').modal();
          Ember['default'].openModal();
        }
        //utils.getCollections(this).then(newCollections => this.set('collections', newCollections));
      },
      sortBookings: function sortBookings(table, field) {
        this.onSortBookings(table, field);
      },
      sortBookingsBrandRefactor: function sortBookingsBrandRefactor(field) {
        this.onSortBookingsBrandRefactor(field);
      },
      willTransition: function willTransition() {
        //clearInterval(this.controller.get('paginationInterval'));
        this.get('poll').clearAll();
        var controller = this.controller;
        filters['default'].removeObserversFromSetFilterComputeds(this);
        //refreshFiltersCount
        controller.removeObserver('bookingsTypeFilter', controller, 'refreshFiltersCount');
        //pastCheckOut
        controller.removeObserver('bookingToAdd.checkout', controller, 'pastCheckOut');
        controller.removeObserver('bookingToAdd.checkin', controller, 'pastCheckOut');
        //statusSelectedObserver
        controller.removeObserver('statusSelected', controller, 'statusSelectedObserver');
        //returnAddressObserver
        controller.removeObserver('newReturnAddress.street', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.street2', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.street3', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.country', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.zipCode', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.city', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.telephone', controller, 'returnAddressObserver');
        controller.removeObserver('newReturnAddress.telephone2', controller, 'returnAddressObserver');
        //AddressesRequester
        controller.removeObserver('bookingToAdd.requester', controller, 'addressesRequesterFunction');
      },
      didTransition: function didTransition() {
        var route = this;
        Ember['default'].run.schedule('afterRender', this, function () {});
      },
      openNewBookingLimitReachedModal: function openNewBookingLimitReachedModal() {
        this.onOpenNewBookingLimitReachedModal(this);
      },
      cancelModal: function cancelModal() {
        Ember['default'].$('#max-bookings-reached').modal('hide');
      },
      clickSelectSample: function clickSelectSample(sample) {
        this.onClickSelectSample(sample);
      },
      checkLook: function checkLook(look) {
        this.onCheckLook(look);
      },
      checkLookVisibleSamples: function checkLookVisibleSamples(look) {
        this.onCheckLookVisibleSamples(look);
      },
      swapReturnAddress: function swapReturnAddress(direction) {
        this.onSwapReturnAddress(direction, event);
      },
      swapCallInAddress: function swapCallInAddress(direction) {
        this.onSwapCallInAddress(direction, event);
      },
      newCallInAddress: function newCallInAddress() {
        this.onNewCallInAddress();
      },
      cancelNewCallIn: function cancelNewCallIn() {
        this.onCancelNewCallIn();
      },
      closeNewCallInAddressModal: function closeNewCallInAddressModal() {
        this.onCancelNewCallIn();
      },
      saveNewCallIn: function saveNewCallIn() {
        this.onSaveNewCallIn();
      },
      newReturnAddress: function newReturnAddress() {
        this.onNewReturnAddress();
      },
      cancelNewReturn: function cancelNewReturn() {
        this.onCancelNewReturn();
      },
      saveNewReturn: function saveNewReturn() {
        this.onSaveNewReturn();
      },
      toggleSetAsMainAddress: function toggleSetAsMainAddress(newCallInAddress) {
        this.onToggleSetAsMainAddress(newCallInAddress);
      },
      cancelNewBooking: function cancelNewBooking() {
        this.onCancelNewBooking();
      },
      checkCheckoutSample: function checkCheckoutSample(sample) {
        sample.set('checked', !sample.get('checked'));
      },

      //toggleSetAsMainAddress: function (contact, address) {this.onToggleSetAsMainAddress(contact, address);},
      removeAddress: function removeAddress(address) {
        this.onRemoveAddress(address);
      },
      saveContactFromUserRefactor: function saveContactFromUserRefactor() {
        this.onSaveContactFromUserRefactor(this);
      },
      cancelAddMp: function cancelAddMp() {
        this.onCancelAddMp();
      },
      addContact: function addContact() {
        this.onAddContact();
      },
      cancelPastBookingWarningModal: function cancelPastBookingWarningModal(bookingToAdd) {
        bookingToAdd.set('checkout', null);bookingToAdd.set('checkin', null);
      },
      clearSamples: function clearSamples() {
        filters['default'].clearSamples(this);
      },
      querySamples: function querySamples(query, deferred) {
        utils['default'].searchSamples(query.term).then(function (response) {
          var data = Ember['default'].A([]);
          response.sample.forEach(function (element) {
            data.push({ id: element.name, text: element.name });
          });

          deferred.resolve(data);
        }, deferred.reject);
      }
    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        showroom: Ember['default'].showroom,
        bookingsList: utils['default'].getBookingsList(),
        todayDate: utils['default'].getAPIJSON('statuses/getTodayDateTimezone')
      });
    },

    setupController: function setupController(controller, model) {
      if (controller.get('bookingToAdd')) {
        controller.get('bookingToAdd').rollbackAttributes();
        controller.set('bookingToAdd', null);
      }
      var ispr = Ember['default'].user.get('type');
      var h4ck3rman = this.controller.get('bookingsTypeFilter');
      this.controller.set('bookingsTypeFilter', "hehehe");
      this.controller.set('bookingsTypeFilter', h4ck3rman);
      this.controller.set('userLogged', Ember['default'].user);
      if (ispr == "PR") {

        if (Ember['default'].user.get('stripeCustomerId')) {
          controller.set('ispr', '1');
        } else {
          controller.set('ispr', '');
        }
      } else {
        controller.set('ispr', '');
      }
      this._super(controller, model);

      //Today Date
      var splittedDates = model.todayDate.split("-");
      var todayDate = new Date(splittedDates[0], splittedDates[1] - 1, splittedDates[2]);
      controller.set('todayDates', todayDate);

      this.set('pastBookingWarned', false);

      controller.setProperties(model);
      this.initializeController();
      this._resetNewCallInAddress();
      this._resetNewReturnAddress();
      this._setupJobFilterStatus();

      //This setting will modify Brand Filtering for agencies
      if (model.showroom.companyTypeAndId.type && model.showroom.companyTypeAndId.type == 'agency') {
        controller.set('isAgency', true);
      }

      filters['default'].setFilterComputeds(this, '#newBookingScrollTable', '#newBookingScrollGallery');

      var response = model.bookingsList;
      //controller.get('bookingsList');

      // This 'weird' mapping is targeted to order by the 'fullName' new field.
      // They are separated in name and surname and we can't sort by two fields at once
      // THIS SHOULD BE FIXED AS WE DID FOR COLLECTIONLIST.
      // Right now Booking List is a json, not an array of models.
      // We should change backend to be able to use store from Ember and has the models availables.
      controller.set('bookings', response.booking.bookingsList.map(function (booking) {
        //Prevent crash if booking requester is null (it occurs when requester was deleted)
        if (booking.requester !== null) {
          var _name = booking.requester.name;
          var surname = booking.requester.surname;
          //To prevent null and white space as last character
          var fullname = '';
          if (_name && surname) {
            fullname = _name + ' ' + surname;
          } else if (_name) {
            fullname = _name;
          } else if (surname) {
            fullname = surname;
          } else {
            fullname = '';
          }
          booking.requester.fullName = fullname;
        }
        return booking;
      }));
      controller.set('numBookings', response.booking.numBookings);

      var maxBookings = 0;
      var _this = this;

      //Check bookings limit for the plan user
      utils['default'].getAPIJSON('plans/numBookingsForPlanUser').then(function (limitPlanBookings) {

        numBookings = controller.get('numBookings');
        limitPlanBookingsNew = limitPlanBookings;

        if (limitPlanBookings != 0 && numBookings >= limitPlanBookings) {

          controller.set('maxBookingsReached', true);
        } else {
          controller.set('maxBookingsReached', false);
        }
        if (limitPlanBookings != 0 && numBookings > limitPlanBookings) {

          controller.set('maxBookingsViewReached', true);
        } else {
          controller.set('maxBookingsViewReached', false);
        }
      });

      Ember['default'].set(controller, 'foreignBookings', controller.get('bookings').filter(function (b) {
        return b.isForeign;
      }));
      Ember['default'].set(controller, 'notForeignBookings', controller.get('bookings').filter(function (b) {
        return !b.isForeign;
      }));

      Ember['default'].set(controller, 'personalForeignBookings', controller.get('foreignBookings').filter(function (b) {
        return b.isPersonal;
      }));
      Ember['default'].set(controller, 'personalBookings', controller.get('notForeignBookings').filter(function (b) {
        return b.isPersonal;
      }));

      controller.newBookings = Ember['default'].computed('allBookings', 'newBookingsSorting', 'active', function () {
        var allBookings = controller.get('allBookings');
        //Check response to jump the code
        if (allBookings) {

          //Filter by new
          var filteredBookings = allBookings.filter(function (booking) {
            return !booking.active;
          });

          // Setting the criteria
          var criteria = controller.get('newBookingsSorting') || 'checkout-asc';

          // Getting criteria and direction respectively
          var criteriaKey = criteria.slice(0, criteria.length - 4);
          var order = criteria.slice(criteria.length - 4);

          // Applying the sorting
          var bookingsSorted = filteredBookings.sort(getSortFunction(order, criteriaKey));

          // Saving the criteria for next sorting comparison
          controller.set('newBookingsSorting', criteria);
          return bookingsSorted;
        }
      });

      controller.activeBookings = Ember['default'].computed('allBookings', 'activeBookingsSorting', 'active', function () {
        var allBookings = controller.get('allBookings');
        //Check response to skip the code
        if (allBookings) {

          //Filter by active
          var filteredBookings = allBookings.filter(function (booking) {
            return booking.active;
          });

          // Setting a criteria
          var criteria = controller.get('activeBookingsSorting') || 'checkout-asc';

          // Getting criteria and direction respectively
          var criteriaKey = criteria.slice(0, criteria.length - 4);
          var order = criteria.slice(criteria.length - 4);

          // Applying the sorting
          var bookingsSorted = filteredBookings.sort(getSortFunction(order, criteriaKey));

          // Saving the criteria for next sorting comparison
          controller.set('activeBookingsSorting', criteria);
          return bookingsSorted;
        }
      });

      controller.bookingFilteredByStatus = Ember['default'].computed('bookingsTypeFilter', 'bookingsFilteredSorting', function () {
        var bookingFilter = controller.get('bookingsTypeFilter');
        var bookingsFiltered = [];

        switch (bookingFilter) {
          case 'personal':
            bookingsFiltered = this.get('personalBookings');
            break;
          case 'showroom':
            bookingsFiltered = this.get('notForeignBookings');
            break;
          case 'foreign':
            bookingsFiltered = this.get('personalForeignBookings').filterBy('closed', false);
            break;
          case 'closed':
            bookingsFiltered = this.get('notForeignBookings').filterBy('closed', true);
            break;
        }
        return bookingsFiltered;
      });

      controller.startDateIn = Ember['default'].computed('bookingToAdd.checkout', function () {
        var dayCheckout = controller.get('bookingToAdd.checkout');
        dayCheckout = !dayCheckout ? new Date() : new Date(dayCheckout);
        var d = Ember['default'].formatDate(dayCheckout, '/', true);
        return d;
      });

      controller.set('allBookings', Ember['default'].computed('bookingFilteredByStatus', 'searchInput', function () {
        var searchInput = controller.get('searchInput').toLowerCase();
        var bookingsFiltered = controller.get('bookingFilteredByStatus');
        var bookingFilter = controller.get('bookingsTypeFilter');
        var bookingsToReturn = null;
        var bookingsFilteredBySearch = bookingsFiltered.filter(function (booking) {
          var search = booking.showroomCustomId + ' ' + booking.requester.name + ' ' + booking.requester.surname + ' ' + booking.company + ' ';
          try {
            search += booking.callInCountry || booking.job.mainAddress.country;
          } catch (e) {}

          if (search) {
            return search.toLowerCase().indexOf(searchInput) >= 0;
          } else {
            return true; //PUM CATAPUM
          }
        });

        bookingsToReturn = bookingsFilteredBySearch;

        // bookingsToReturn = bookingsFilteredBySearch.sortBy('checkout');
        //
        // if(bookingFilter === 'closed') {
        //   bookingsToReturn.sortBy('id');
        //   bookingsToReturn.reverse();
        // }

        return bookingsToReturn;
      }));

      controller.set('allBookingsFiltered', Ember['default'].computed('bookingsTypeFilter', 'searchInput', 'newFilter', 'incompleteFilter', 'checkoutLateFilter', 'checkoutTodayFilter', 'activeFilter', 'upcomingFilter', 'featuredFilter', 'expiredFilter', 'closedFilter', 'canceledFilter', 'allBookingsFilteredSorting', function () {
        var visible = true;

        var searchInput = controller.get('searchInput').toLowerCase();
        var bookingsFiltered = [];
        var bookingFilter = controller.get('bookingsTypeFilter');
        switch (bookingFilter) {
          case 'personal':
            bookingsFiltered = this.get('personalBookings');
            break;
          case 'showroom':
            bookingsFiltered = this.get('notForeignBookings');
            break;
          case 'foreign':
            bookingsFiltered = this.get('personalForeignBookings');
            break;
        }

        var bookingsFilteredBySearch = bookingsFiltered.filter(function (b) {
          //Filter by search input
          if (searchInput) {
            var search = b.showroomCustomId + ' ' + b.requester.name + ' ' + b.requester.surname + ' ' + b.company + ' ';

            if (b.ddts) {
              search = search + ' ' + b.ddts;
            }

            try {
              search += b.callInCountry || b.job.mainAddress.country;
            } catch (e) {}
            visible = search.toLowerCase().indexOf(searchInput) >= 0;
          }
          if (!visible) {
            return false;
          } else {
            var found = false;
            var hasFilter = false;
            //Filter by status: New
            if (controller.get('newFilter') && !found) {
              found = b.status === "NEW";
              hasFilter = true;
            }
            //Filter by status: Incomplete
            if (controller.get('incompleteFilter') && !found) {
              found = b.status === "INCOMPLETE";
              hasFilter = true;
            }
            //Filter by status: Checkout late
            if (controller.get('checkoutLateFilter') && !found) {
              found = b.status === "CHECK-OUT LATE";
              hasFilter = true;
            }
            //Filter by status: Today Checkout
            if (controller.get('checkoutTodayFilter') && !found) {
              found = b.status === "CHECK-OUT TODAY";
              hasFilter = true;
            }
            //Filter by status: Active
            if (controller.get('activeFilter') && !found) {
              found = b.status === "ACTIVE LATE" || b.status === "ACTIVE";
              hasFilter = true;
            }
            //Filter by status: Upcoming
            if (controller.get('upcomingFilter') && !found) {
              found = b.status === "UPCOMING";
              hasFilter = true;
            }
            //Filter by status: Featured
            if (controller.get('featuredFilter') && !found) {
              found = b.featured;
              hasFilter = true;
            }
            //Filter by status: Expired
            if (controller.get('expiredFilter') && !found) {
              found = b.status === "EXPIRED";
              hasFilter = true;
            }
            //Filter by status: Closed
            if (controller.get('closedFilter') && !found) {
              found = b.status === "CLOSED";
              hasFilter = true;
            }
            //Filter by status: Canceled
            if (controller.get('canceledFilter') && !found) {
              found = b.status === "CANCELED";
              hasFilter = true;
            }
            /*//Filter by starred
            if (controller.get('starredFilter') && !found){
              found = b.starred;
              hasFilter = true;
            }*/
            return hasFilter ? found : true;
          }
        });

        // Setting a criteria
        var criteria = controller.get('allBookingsFilteredSorting') || 'showroomCustomId-des';

        // Getting criteria and direction respectively
        var criteriaKey = criteria.slice(0, criteria.length - 4);
        var order = criteria.slice(criteria.length - 4);

        // Applying the sorting
        var bookingsSorted = bookingsFilteredBySearch.sort(getSortFunction(order, criteriaKey));

        // Saving the criteria for next sorting comparison
        controller.set('allBookingsFilteredSorting', criteria);

        return bookingsSorted;
      }));

      controller.refreshFiltersCount = function () {
        var bookings = null;
        var bookingFilter = controller.get('bookingsTypeFilter');
        switch (bookingFilter) {
          case 'personal':
            bookings = this.get('personalBookings');
            break;
          case 'showroom':
            bookings = this.get('notForeignBookings');
            break;
          case 'foreign':
            bookings = this.get('personalForeignBookings');
            break;
        }
        var newFilter = 0;
        var incompleteFilter = 0;
        var checkoutLateFilter = 0;
        var checkoutTodayFilter = 0;
        var activeFilter = 0;
        var upcomingFilter = 0;
        var featuredFilter = 0;
        var expiredFilter = 0;
        var closedFilter = 0;
        var canceledFilter = 0;
        // let starredFilter = 0;

        for (var i = 0; i < bookings.length; i++) {
          switch (bookings[i].status) {
            case "NEW":
              newFilter++;
              break;
            case "INCOMPLETE":
              incompleteFilter++;
              break;
            case "CHECK-OUT LATE":
              checkoutLateFilter++;
              break;
            case "ACTIVE LATE":
              activeFilter++;
              break;
            case "CHECK-OUT TODAY":
              checkoutTodayFilter++;
              break;
            case "CHECK-IN TODAY":
              checkinTodayFilter++;
              break;
            case "ACTIVE":
              activeFilter++;
              break;
            case "UPCOMING":
              upcomingFilter++;
              break;
            case "EXPIRED":
              expiredFilter++;
              break;
            case "CLOSED":
              closedFilter++;
              break;
            case "CANCELED":
              canceledFilter++;
              break;
          }
          if (bookings[i].featured) {
            featuredFilter++;
          }
          /*if (bookings[i].starred){
            starredFilter++;
          }*/
        }
        controller.set('newFilterCount', newFilter);
        controller.set('incompleteFilterCount', incompleteFilter);
        controller.set('checkoutLateFilterCount', checkoutLateFilter);
        controller.set('checkoutTodayFilterCount', checkoutTodayFilter);
        controller.set('activeFilterCount', activeFilter);
        controller.set('upcomingFilterCount', upcomingFilter);
        controller.set('featuredFilterCount', featuredFilter);
        controller.set('expiredFilterCount', expiredFilter);
        controller.set('closedFilterCount', closedFilter);
        controller.set('canceledFilterCount', canceledFilter);
        // controller.set('starredFilterCount', starredFilter);
      };
      controller.refreshFiltersCount();

      utils['default'].addObserver(controller, ['bookingsTypeFilter'], 'refreshFiltersCount');

      //Listen to checkout/checkin from new booking for detect Past Checkout
      controller.pastCheckOut = function () {
        //Clean selected samples when date is changed!
        Ember['default'].bulk.clearSamples();

        var bookingCheckout = this.get('bookingToAdd').get('checkout');
        var bookingCheckin = this.get('bookingToAdd').get('checkin');
        var pastBookingWarned = this.get('pastBookingWarned');
        //let dateToday = new Date();
        var dateToday = todayDate;
        dateToday.setHours(0, 0, 0, 0);

        if (!pastBookingWarned && bookingCheckout && bookingCheckin) {
          if (bookingCheckout.getTime() < dateToday.getTime()) {
            //Show warning modal
            Ember['default'].$('#past-booking-warning-modal').modal();
            Ember['default'].openModal();

            this.set('pastBookingWarned', true);
          }
        }

        if (bookingCheckout) {
          if (bookingCheckout.getTime() < dateToday.getTime()) {
            this.set('bookingPast', true);
          } else {
            this.set('bookingPast', false);
          }
        }
      };

      utils['default'].addObserver(controller, ['bookingToAdd.checkout', 'bookingToAdd.checkin'], 'pastCheckOut');

      //functions.showroomSelectorObserver(controller, 'comboShowroom', 'bookingToAdd');
      //let _t = this;

      controller.addressesRequesterFunction = function () {
        var b = controller.get('bookingToAdd');
        if (!Ember['default'].isNone(b)) {
          var requester = b.get('requester');
          if (!Ember['default'].isNone(requester)) {
            Ember['default'].set(controller, 'addressesRequester', requester.get('allAddresses'));
          } else {
            Ember['default'].set(controller, 'addressesRequester', null);
          }
        } else {
          Ember['default'].set(controller, 'addressesRequester', null);
        }
      };
      utils['default'].addObserver(controller, ['bookingToAdd.requester'], 'addressesRequesterFunction');

      controller.set('booking3disabled', Ember['default'].computed('bookingToAdd.country', 'bookingToAdd.requester.id', 'bookingToAdd.jobType', function () {
        var bookingToAdd = this.get('bookingToAdd');

        return !bookingToAdd || !bookingToAdd.get('requester.id') || !bookingToAdd.get('country') || !bookingToAdd.get('jobType');
      }));
      this.controller.notifyPropertyChange('brandFilter');

      controller.set('moreThan1Addresses', Ember['default'].computed('addressesRequester', function () {
        var a = controller.get('addressesRequester');
        if (a != null) {
          if (a.get('length') != null && a.get('length') > 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }));
      controller.set('moreThan1Showroom', Ember['default'].computed('allShowrooms.length', function () {
        var a = controller.get('allShowrooms');
        if (a != null) {
          if (a.get('length') != null && a.get('length') > 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }));

      /*controller.set('isLoadingCollections', true);
      //change: use inventory=true for same call
      utils.getCollectionsForInventory(this).then(c => {
        controller.set('collections', c);
        controller.notifyPropertyChange('brandFilter');
        controller.set('isLoadingCollections', false);
      });*/
      contactFunctions['default'].setComputedNewContact(controller);
      contactFunctions['default'].setListsFilteredBySelectedUserObserverBooking(controller);
    },

    _setupJobFilterStatus: function _setupJobFilterStatus() {
      this.controller.set('jobStatusFilter', []);
      this.controller.set('statusSelected', '-1');
      this.controller.set('activeFilterActive', true);
      this.controller.set('newFilterActive', true);

      this.controller.get('jobStatusFilter').addObjects([{ 'id': '-1', 'status': 'All statuses' }, { 'id': '0', 'status': 'New' }, { 'id': '1', 'status': 'Active' }]);
      this._setupJobFilterObserver();
    },

    _setupJobFilterObserver: function _setupJobFilterObserver() {
      var controller = this.controller;
      controller.statusSelectedObserver = function () {
        var statusSelected = controller.get('statusSelected');
        controller.set('activeFilterActive', false);
        controller.set('newFilterActive', false);

        switch (statusSelected) {
          case '-1':
            controller.set('activeFilterActive', true);
            controller.set('newFilterActive', true);
            break;

          case '0':
            controller.set('newFilterActive', true);
            break;

          case '1':
            controller.set('activeFilterActive', true);
            break;
        }
      };
      utils['default'].addObserver(controller, ['statusSelected'], 'statusSelectedObserver');
    },

    initializeController: function initializeController() {
      this.controller.set('bookingsTypeFilter', 'showroom');

      this.controller.set('parcelToadd', null);
      this.controller.set('searchInput', '');
      this.controller.set('allBookingsFilteredSorting', null);
      this.controller.set('newFilter', null);
      this.controller.set('incompleteFilter', null);
      this.controller.set('checkoutLateFilter', null);
      this.controller.set('checkoutTodayFilter', null);
      this.controller.set('activeFilter', null);
      this.controller.set('upcomingFilter', null);
      this.controller.set('featuredFilter', null);
      this.controller.set('expiredFilter', null);
      this.controller.set('closedFilter', null);
      this.controller.set('canceledFilter', null);
      this.controller.set('sampleSearchInput', '');
      this.controller.set('startDateOut', Ember['default'].formatDate(new Date(), '/', true));
      this.controller.set('isShowroom', true);
      this.controller.set('isShowroomInverse', false); //for validated-select
      this.controller.set('comboShowroom', '');
      this.controller.set('fromAddressBookInverse', false);
      this.controller.set('fromAddressBook', true);
      this.controller.set('collections', []);
      this.controller.set('store', this.store);

      this.controller.set('countriesAvailable', Ember['default'].countries);

      this.controller.set('noRefreshStatuses', false);

      if (typeof Ember['default'].bulk.samples === 'undefined') {
        Ember['default'].bulk.clearSamples();
      }

      var samples = new Object(Ember['default'].bulk.samples);
      Ember['default'].beginPropertyChanges();
      samples.forEach(function (sample) {
        return sample.set('checked', false);
      });
      Ember['default'].endPropertyChanges();

      newBookingFunctions['default'].resetController(this);
    },

    resetBookingDates: function resetBookingDates() {

      Ember['default'].bulk.clearSamples();
      this.controller.set('isLoading', true);

      this.controller.get('bookingToAdd').set('firstCheckin', new Date(this.controller.get('bookingToAdd.checkin')));
      this.controller.get('bookingToAdd').set('firstCheckout', new Date(this.controller.get('bookingToAdd.checkout')));
      this.controller.set('statusSelected', '-1');
      this.controller.set('searchInput', '');
      this.controller.set('sampleSearchInput', '');
      this.controller.get('samples').forEach(function (sample) {

        sample.set('disabled', false);
        sample.set('booked', false);
        sample.set('checked', false);
      });
    },

    onContinueStep2: function onContinueStep2() {

      this.controller.set('samples', utils['default'].getSamples());
      this.controller.get('samples').forEach(function (sample) {
        return sample.set('visible', true);
      });

      newBookingFunctions['default'].onUpdateBookingDates(this);
    },

    onContinueStep3: function onContinueStep3() {
      Ember['default'].openModal(1000, '#new-booking-3');
      this.controller.set('comboShowroom', Ember['default'].showroom);
    },

    onContinueNewBookingStep2: function onContinueNewBookingStep2() {
      filters['default'].removeScrollPaginationListener(this);
      this.controller.set('comboShowroom', Ember['default'].showroom);

      Ember['default'].openModal(1000, '#new-booking-2-brand-refactor');
    },

    onCreateOverview: function onCreateOverview() {
      var _this2 = this;

      var bookingToAdd = this.controller.get('bookingToAdd');
      this.send('openMessageModal', 'Creating a Booking overview, please wait.');
      if (bookingToAdd.get('checked') && !this.controller.get('fromAddressBook')) {
        var contactAddress = contactFunctions['default'].onAddAnotherAddress(this, this.controller.get('bookingToAdd.requester'));
        contactAddress.set('street1', bookingToAdd.get('callInStreet1'));
        contactAddress.set('street2', bookingToAdd.get('callInStreet2'));
        contactAddress.set('street3', bookingToAdd.get('callInStreet3'));
        contactAddress.set('country', bookingToAdd.get('callInCountry'));
        contactAddress.set('city', bookingToAdd.get('callInCity'));
        contactAddress.set('zipCode', bookingToAdd.get('callInZipCode'));
        contactAddress.set('telephone1', bookingToAdd.get('callInTelephone'));
        contactAddress.save();
      }
      this.transitionTo('bookings.overview').then(function () {
        _this2.send('closeMessageModal');
        Ember['default'].$('.modal-backdrop').remove();
      });
    },

    onCreateOverviewAndBooking: function onCreateOverviewAndBooking(e) {
      var _this3 = this;

      Ember['default'].$(e.target).attr('disabled', true);
      var btn = Ember['default'].$('#save-newbooking2-btn').button();
      btn.button('loading');
      //Disable continue button
      this.controller.set('booking3disabled', true);

      //Disable observer for getStatuses
      this.controller.set('noRefreshStatuses', true);

      var bookingToAdd = this.controller.get('bookingToAdd');
      this.send('openMessageModal', 'Creating a Booking overview, please wait.');
      if (bookingToAdd.get('checked') && !this.controller.get('fromAddressBook')) {
        var contactAddress = contactFunctions['default'].onAddAnotherAddress(this, this.controller.get('bookingToAdd.requester'));
        contactAddress.set('street1', bookingToAdd.get('callInStreet1'));
        contactAddress.set('street2', bookingToAdd.get('callInStreet2'));
        contactAddress.set('street3', bookingToAdd.get('callInStreet3'));
        contactAddress.set('country', bookingToAdd.get('callInCountry'));
        contactAddress.set('city', bookingToAdd.get('callInCity'));
        contactAddress.set('zipCode', bookingToAdd.get('callInZipCode'));
        contactAddress.set('telephone1', bookingToAdd.get('callInTelephone'));
        contactAddress.save();
      }

      //Create booking
      //Company modified
      this.controller.set('companyBookingToAdd', bookingToAdd.get('company'));

      this.send('openMessageModal', 'Creating your Booking, please wait.');
      this.controller.set('collectionFilter', '');

      bookingToAdd.set('samples', Ember['default'].bulk.samples);
      bookingToAdd.get('users').addObject(this.store.peekRecord('user', Ember['default'].userId));
      bookingToAdd.set('owner', this.store.peekRecord('user', Ember['default'].userId));

      bookingToAdd.save().then(function (newBooking) {
        ___functions['default'].setRequesterComputeds(_this3.controller);
        _this3.transitionTo('bookings.booking-details', newBooking.get('id'), {
          queryParams: {
            search: ""
          }
        }).then(function () {
          _this3.send('closeMessageModal');
          btn.button('reset');
          Ember['default'].$(e.target).attr('disabled', false);
          Ember['default'].$('.modal-backdrop').remove();
        });
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.errors) {
          if (res.errors.length > 0) {
            errors = res.errors[0].detail;
          }
        }

        _this3.send('closeMessageModal');
        btn.button('reset');
        Ember['default'].$(e.target).attr('disabled', false);
        _this3.send('openTopBar', 'Ooops! ' + errors);
      });
    },

    onUpdateDates: function onUpdateDates() {
      if (Ember['default'].bulk.samples.length) {
        Ember['default'].$('#new-booking-2').modal('hide');
        Ember['default'].$('#change-check-in-out').modal();
        Ember['default'].openModal();
      } else {
        newBookingFunctions['default'].onUpdateBookingDates(this);
      }
    },

    onChangeAddress: function onChangeAddress(address) {
      this.controller.get('bookingToAdd').setProperties({
        callInStreet1: address.get('street1'),
        callInStreet2: address.get('street2'),
        callInStreet3: address.get('street3'),
        callInCountry: address.get('country'),
        callInCity: address.get('city'),
        callInZipCode: address.get('zipCode'),
        callInTelephone: address.get('telephone1')
      });

      this.controller.set('fromReturnAddressBook', true);
      this.controller.set('fromAddressBookInverse', false);
      this.controller.set('mainAddressRequester', address);

      Ember['default'].openModal();
    },
    onChangeReturnAddress: function onChangeReturnAddress(address) {
      this.controller.get('comboShowroom').setProperties({
        callInStreet1: address.get('street1'),
        callInStreet2: address.get('street2'),
        callInStreet3: address.get('street3'),
        callInCountry: address.get('country'),
        callInCity: address.get('city'),
        callInZipCode: address.get('zipCode'),
        callInTelephone: address.get('telephone1')
      });
      this.controller.set('fromReturnAddressBook', true);
      this.controller.set('fromAddressBookInverse', false);
      this.controller.set('comboShowroom', address);

      Ember['default'].openModal();
    },

    onOpenNewContactModal: function onOpenNewContactModal() {
      this.controller.set('addContactSelectedLists', []);
      contactFunctions['default'].openNewContactModal(this);
    },

    onOpenNewBookingLimitReachedModal: function onOpenNewBookingLimitReachedModal(route) {
      route.send('openLimitationReachedModal', 'Booking');
    },

    onSaveNewContact: function onSaveNewContact() {
      var _this4 = this;

      var promise = contactFunctions['default'].onSaveNewContact(this);
      if (promise) {
        utils['default'].buttonLoading('#booking-create-date', promise);
        promise.done(function () {
          _this4.controller.get('bookingToAdd').set('requester', _this4.controller.get('contactToAdd'));
          _this4.controller.get('allContacts').addObject(_this4.controller.get('contactToAdd'));
          _this4.controller.notifyPropertyChange('bookingToAdd.requester');
          Ember['default'].$('#new-booking-3').modal();
          Ember['default'].openModal(1000, '#new-booking-3');
        });
      }
    },

    onSaveNewContactRefactor: function onSaveNewContactRefactor() {
      var _this5 = this;

      var promise = contactFunctions['default'].onSaveNewContact(this);
      if (promise) {
        utils['default'].buttonLoading('#booking-create-date', promise);
        promise.then(function (c) {
          _this5.controller.get('bookingToAdd').set('requester', c);
          _this5.controller.get('allContacts').addObject(c);
          _this5.controller.notifyPropertyChange('bookingToAdd.requester');
          // Ember.$('#new-booking-2-brand-refactor').modal();
          // Ember.openModal(1000, '#new-booking-2-brand-refactor');
          //Ember.$('#new-booking-2-brand-refactor').modal('show');
          Ember['default'].$('#new-booking-2-brand-refactor').modal();
          Ember['default'].openModal();
        });
      }
    },

    onCancelEdition: function onCancelEdition() {
      Ember['default'].$('#new-booking-3').modal('hide');
    },

    onSelectContactAddress: function onSelectContactAddress(address) {
      this.controller.get('addressesRequester').forEach(function (reqAddress) {
        reqAddress.set('active', false);
      });
      address.set('active', true);
      this.controller.set('selectedAddressBooking', address);
    },
    onSelectReturnAddress: function onSelectReturnAddress(address) {
      this.controller.get('allShowrooms').forEach(function (returnAddress) {
        returnAddress.set('active', false);
      });
      address.set('active', true);
      this.controller.set('selectedReturnAddressBooking', address);
    },

    onOpenSelectFromAddressBook: function onOpenSelectFromAddressBook(address) {
      var selectedAddressBooking = this.controller.get('selectedAddressBooking');
      if (Ember['default'].isNone(selectedAddressBooking)) {
        address.set('active', true);
        this.controller.set('selectedAddressBooking', address);
      }

      Ember['default'].openModal(1000, '#contact-address');
    },
    onOpenSelectFromReturnAddressBook: function onOpenSelectFromReturnAddressBook(address) {
      var allShowrooms = this.controller.get('allShowrooms');
      if (Ember['default'].isNone(allShowrooms)) {
        address.set('active', true);
        this.controller.set('allShowrooms', address);
      }

      Ember['default'].openModal(1000, '#showroom-address');
    },

    onCancelSaveDate: function onCancelSaveDate(bookingToAdd) {
      bookingToAdd.set('checkin', this.controller.get('bookingToAdd.firstCheckin'));
      bookingToAdd.set('checkout', this.controller.get('bookingToAdd.firstCheckout'));
      Ember['default'].openModal();
    },

    onSaveDate: function onSaveDate(bookingToAdd) {

      bookingToAdd.set('firstCheckin', bookingToAdd.get('checkin'));
      bookingToAdd.set('firstCheckout', bookingToAdd.get('checkout'));
      Ember['default'].beginPropertyChanges();
      var samples = new Object(Ember['default'].bulk.samples);
      samples.forEach(function (sample) {
        sample.set('checked', false);
      });
      Ember['default'].endPropertyChanges();
      newBookingFunctions['default'].onUpdateBookingDates(this);
      Ember['default'].openModal();
    },

    onGoToDetails: function onGoToDetails(booking) {

      //if (booking.active) {
      var samples = new Object(Ember['default'].bulk.samples);
      Ember['default'].beginPropertyChanges();
      samples.forEach(function (sample) {
        sample.set('checked', false);
      });
      Ember['default'].endPropertyChanges();
      Ember['default'].bulk.clearSamples();
      this.transitionTo('bookings.booking-details', booking.id, {
        queryParams: {
          search: ""
        }
      });
    },

    onGoToDetailsBrandRefactor: function onGoToDetailsBrandRefactor(booking) {
      if (this.controller.get('maxViewsReached') && !booking.active) {
        this.onOpenNewBookingLimitReachedModal();
      } else {
        this.onGoToDetails(booking);
      }
    },

    onClearCallInFields: function onClearCallInFields(parcelToAdd) {
      parcelToAdd.set('street1In', '');
      parcelToAdd.set('street2In', '');
      parcelToAdd.set('street3In', '');
      parcelToAdd.set('countryIn', '');
      parcelToAdd.set('cityIn', '');
      parcelToAdd.set('zipCodeIn', '');
      parcelToAdd.set('telephone1In', '');
      parcelToAdd.set('attnIn', '');
      this.controller.set('fromAddressBook', false);
      this.controller.set('fromAddressBookInverse', true);
    },
    onClearReturnFields: function onClearReturnFields(parcelToAdd) {
      parcelToAdd.set('returnAttn', '');
      parcelToAdd.set('returnCity', '');
      parcelToAdd.set('returnCountry', '');
      parcelToAdd.set('returnStreet1', '');
      parcelToAdd.set('returnStreet2', '');
      parcelToAdd.set('returnStreet3', '');
      parcelToAdd.set('returnTelephone', '');
      parcelToAdd.set('returnZipCode', '');
      this.controller.set('isShowroom', false);
      this.controller.set('isShowroomInverse', true);
    },
    onSortBookings: function onSortBookings(table, field) {
      var key = undefined;
      var selector = undefined;
      if (table === 'new') {
        key = 'newBookingsSorting';
        selector = '.new_bookings';
      } else {
        key = 'activeBookingsSorting';
        selector = '.active_bookings';
      }
      $(selector).find('th').removeClass('asc des');
      var previousField = this.controller.get(key);
      if (field == previousField.slice(0, previousField.length - 4)) {
        if (previousField.endsWith('-des')) {
          $(selector).find('.' + field).addClass('asc');
          field = field + '-asc';
        } else {
          $(selector).find('.' + field).addClass('des');
          field = field + '-des';
        }
      } else {
        $(selector).find('.' + field).addClass('asc');
        field = field + '-asc';
      }

      this.controller.set(key, field);
    },

    onSortBookingsBrandRefactor: function onSortBookingsBrandRefactor(field) {
      var key = 'allBookingsFilteredSorting';
      var selector = '.bookings-list';

      $(selector).find('th').removeClass('asc des');
      var previousField = this.controller.get(key);
      if (field == previousField.slice(0, previousField.length - 4)) {
        if (previousField.endsWith('-des')) {
          $(selector).find('.' + field).addClass('asc');
          field = field + '-asc';
        } else {
          $(selector).find('.' + field).addClass('des');
          field = field + '-des';
        }
      } else {
        $(selector).find('.' + field).addClass('asc');
        field = field + '-asc';
      }

      this.controller.set(key, field);
    },

    onShowShowroomBookings: function onShowShowroomBookings() {
      var _this6 = this;

      this.controller.set('bookingsToReturn', []);
      utils['default'].getAPIJSON('showrooms/bookings/' + Ember['default'].showroomId).then(function (bookingsShowroom) {
        bookingsShowroom.showroom.forEach(function (booking) {
          var peekBooking = utils['default'].peekBooking(_this6, booking);
          _this6.controller.get('bookingsToReturn').addObject(peekBooking);
        });
      });
      this.controller.set('bookingFilter', 2);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-bookings');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-bookings');
    },

    onSetBookingsToShow: function onSetBookingsToShow(type) {
      this.controller.set('bookingsTypeFilter', type);
    },
    onClickSelectSample: function onClickSelectSample(sample) {
      var currentStatus = sample.get('checked');
      sample.set('checked', !currentStatus);
    },

    onCheckLook: function onCheckLook(look) {
      var check = true;
      if (look.get('allSamplesAreChecked')) {
        check = false;
      } else {
        check = true;
      }
      var groupedSamples = look.get('groupedSamples');
      for (var i = 0; i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; x < samples.length; x++) {
          //Dont check if sample is UNAVAILABLE
          //if (samples[x].addSampleStatus != 'unavailable') {
          samples[x].set('checked', check);
          //}
        }
      }
    },

    onCheckLookVisibleSamples: function onCheckLookVisibleSamples(look) {
      var past = this.controller.get('bookingPast');
      var check = true;
      if (past) {
        check = !look.get('allVisibleSamplesAreCheckedPast');
      } else {
        check = !look.get('allVisibleSamplesAreChecked');
      }
      var groupedSamples = look.get('groupedSamples');
      for (var i = 0; i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; x < samples.length; x++) {
          if (!this.controller.get('bookingPast')) {
            if (samples[x].get('visible')) {
              samples[x].set('checked', check);
            }
          } else {
            //IF we are creating a PAST BOOKING, Dont check if sample is UNAVAILABLE
            if (samples[x].addSampleStatus == 'unavailable') {
              //Always unchecked
              samples[x].set('checked', false);
            } else {
              //Normal behaviour
              if (samples[x].get('visible')) {
                samples[x].set('checked', check);
              }
            }
          }

          //}
        }
      }
    },

    onSwapReturnAddress: function onSwapReturnAddress(direction) {
      var _this7 = this;

      var addresses = this.controller.get('allShowrooms');
      if (addresses.length > 1) {
        (function () {
          var firstMove = "0%";
          var secondMove = "0%";
          if (direction === "next") {
            firstMove = "-100%";
            secondMove = "+200%";
          } else {
            firstMove = "+100%";
            secondMove = "-200%";
          }
          var booking = _this7.controller.get('bookingToAdd');
          var _t = _this7;
          var changeTo = null;

          var sliders = null;
          //CLICK ARROW IMG
          if (Ember['default'].$(event.target).prop("tagName") === "IMG") {
            sliders = Ember['default'].$(event.target).parent().siblings('.address-card').get(0);
          }
          //CLICK SPAN (PARENT FROM ARROW IMG)
          else {
              sliders = Ember['default'].$(event.target).siblings('.address-card').get(0);
            }
          $(sliders).animate({ left: firstMove }, 250, function () {
            //CHANGE INFO
            for (var i = 0; i < addresses.length; i++) {
              if (addresses[i].get('id') === booking.get('returnId')) {
                if (direction === "next") {
                  //If last address => next will be first address
                  if (i === addresses.length - 1) {
                    changeTo = addresses[0];
                  } else {
                    changeTo = addresses[i + 1];
                  }
                } else {
                  //If first address => prev is the last address;
                  if (i === 0) {
                    changeTo = addresses[addresses.length - 1];
                  } else {
                    changeTo = addresses[i - 1];
                  }
                }
                break;
              }
            }
            booking.setProperties({
              returnId: changeTo.get('id'),
              returnStreet1: changeTo.get('street'),
              returnStreet2: changeTo.get('street2'),
              returnStreet3: changeTo.get('street3'),
              returnCountry: changeTo.get('country'),
              returnCity: changeTo.get('city'),
              returnZipCode: changeTo.get('zipCode'),
              returnTelephone: changeTo.get('telephone'),
              returnTelephone2: changeTo.get('telephone2')
            });
            _t.controller.set('fromReturnAddressBook', true);
            _t.controller.set('fromAddressBookInverse', false);
            //_t.controller.set('comboShowroom', changeTo);
            //END CHANGE INFO
            $(sliders).animate({ left: secondMove }, 0, function () {
              $(sliders).animate({ left: "0" }, 250);
            });
          });
        })();
      }
    },

    onSwapCallInAddress: function onSwapCallInAddress(direction) {
      var _this8 = this;

      var addresses = this.controller.get('addressesRequester');
      if (addresses.length > 1) {
        (function () {
          var firstMove = "0%";
          var secondMove = "0%";
          if (direction === "next") {
            firstMove = "-100%";
            secondMove = "+200%";
          } else {
            firstMove = "+100%";
            secondMove = "-200%";
          }
          var booking = _this8.controller.get('bookingToAdd');
          var changeTo = null;
          var _t = _this8;

          var sliders = null;
          //CLICK ARROW IMG
          if (Ember['default'].$(event.target).prop("tagName") === "IMG") {
            sliders = Ember['default'].$(event.target).parent().siblings('.address-card').get(0);
          }
          //CLICK SPAN (PARENT FROM ARROW IMG)
          else {
              sliders = Ember['default'].$(event.target).siblings('.address-card').get(0);
            }
          $(sliders).animate({ left: firstMove }, 250, function () {
            //CHANGE INFO
            for (var i = 0; i < addresses.length; i++) {
              if (addresses[i].get('id') === booking.get('callInId')) {
                if (direction === "next") {
                  //If last address => next will be first address
                  if (i === addresses.length - 1) {
                    changeTo = addresses[0];
                  } else {
                    changeTo = addresses[i + 1];
                  }
                } else {
                  //If first address => prev is the last address;
                  if (i === 0) {
                    changeTo = addresses[addresses.length - 1];
                  } else {
                    changeTo = addresses[i - 1];
                  }
                }
                break;
              }
            }
            booking.setProperties({
              callInId: changeTo.get('id'),
              callInStreet1: changeTo.get('street1'),
              callInStreet2: changeTo.get('street2'),
              callInStreet3: changeTo.get('street3'),
              callInCountry: changeTo.get('country'),
              callInCity: changeTo.get('city'),
              callInZipCode: changeTo.get('zipCode'),
              callInTelephone: changeTo.get('telephone1')
            });
            _t.controller.set('fromReturnAddressBook', true);
            _t.controller.set('fromAddressBookInverse', false);
            //END CHANGE INFO
            $(sliders).animate({ left: secondMove }, 0, function () {
              $(sliders).animate({ left: "0" }, 250);
            });
          });
        })();
      }
    },

    _resetNewCallInAddress: function _resetNewCallInAddress() {
      var newCallInAddress = this.store.createRecord('contact-address', {
        street1: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipcode: '',
        telephone1: '',
        telephone2: '',
        setAsMain: false
      });
      this.controller.set('newCallInAddress', newCallInAddress);
    },

    onNewCallInAddress: function onNewCallInAddress() {
      var newCallInAddress = this.store.createRecord('contact-address', {
        street1: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipcode: '',
        telephone1: '',
        telephone2: '',
        main: false,
        contact: this.controller.get('booking.requester')
      });
      newCallInAddress.set('setAsMain', false);
      this.controller.set('newCallInAddress', newCallInAddress);

      Ember['default'].$('#new-callin-address').modal('show');
    },

    onCancelNewCallIn: function onCancelNewCallIn() {
      this._resetNewCallInAddress();
      Ember['default'].$('#new-callin-address').modal('hide');
    },

    onCancelNewBooking: function onCancelNewBooking() {
      filters['default'].resetFilters(this);
      filters['default'].removeScrollPaginationListener(this);
      var samples = new Object(Ember['default'].bulk.samples);
      Ember['default'].beginPropertyChanges();
      samples.forEach(function (sample) {
        sample.set('checked', false);
      });
      Ember['default'].endPropertyChanges();
    },

    onSaveNewCallIn: function onSaveNewCallIn() {
      var btn = Ember['default'].$('#save-new-callin-address-btn');
      btn.button('loading');
      var _t = this;
      var addresses = this.controller.get('addressesRequester');
      var newObj = this.controller.get('newCallInAddress');
      var contact = this.controller.get('bookingToAdd.requester');

      if (newObj.validate()) {

        if (newObj.get('setAsMain')) {
          newObj.set('contact', null);
          newObj.set('public', contact);

          //Set The Main address for mark it as main in the New Booking Address Form
          _t.controller.set('mainAddressFromRequester', newObj);
        } else {
          newObj.set('contact', contact);
          newObj.set('public', null);
        }

        if (addresses.length === 0) {
          addresses.addObject(newObj);
        } else if (addresses.get(addresses.length - 1).get('id') === 0) {
          addresses.set(addresses.length - 1, newObj);
        } else {
          addresses.addObject(newObj);
          this.controller.set('moreThan1Addresses', true);
        }

        newObj.save().then(function (changeTo) {
          _t.controller.get('bookingToAdd').setProperties({
            callInId: changeTo.get('id'),
            callInStreet1: changeTo.get('street1'),
            callInStreet2: changeTo.get('street2'),
            callInStreet3: changeTo.get('street3'),
            callInCountry: changeTo.get('country'),
            callInCity: changeTo.get('city'),
            callInZipCode: changeTo.get('zipCode'),
            callInTelephone: changeTo.get('telephone1'),
            callInTelephone2: changeTo.get('telephone2')
          });
          Ember['default'].$('#new-callin-address').modal('hide');
          btn.button('reset');
        });
      } else {
        btn.button('reset');
      }
    },

    _resetNewReturnAddress: function _resetNewReturnAddress() {
      var controller = this.controller;
      var newReturnAddress = Ember['default'].Object.create({
        street: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipCode: '',
        telephone: '',
        telephone2: ''
      });
      this.controller.set('newReturnAddress', newReturnAddress);

      controller.returnAddressObserver = function () {
        var newReturnAddress = controller.get('newReturnAddress');
        var params = [];
        params.push(newReturnAddress.get('street'));
        params.push(newReturnAddress.get('street2'));
        params.push(newReturnAddress.get('street3'));
        params.push(newReturnAddress.get('country'));
        params.push(newReturnAddress.get('city'));
        params.push(newReturnAddress.get('zipCode'));
        params.push(newReturnAddress.get('telephone'));
        params.push(newReturnAddress.get('telephone2'));
        var hasSomeField = params.some(function (p) {
          return !Ember['default'].isBlank(p);
        });
        controller.set('newReturnHasSomeField', hasSomeField);
      };
      utils['default'].addObserver(controller, ['newReturnAddress.street', 'newReturnAddress.street2', 'newReturnAddress.street3', 'newReturnAddress.country', 'newReturnAddress.zipCode', 'newReturnAddress.city', 'newReturnAddress.telephone', 'newReturnAddress.telephone2'], 'returnAddressObserver');
    },

    onNewReturnAddress: function onNewReturnAddress() {
      this._resetNewReturnAddress();
      Ember['default'].$('#new-return-address').modal('show');
    },

    onCancelNewReturn: function onCancelNewReturn() {
      this._resetNewReturnAddress();
      Ember['default'].$('#new-return-address').modal('hide');
    },

    onSaveNewReturn: function onSaveNewReturn() {
      var btn = Ember['default'].$('#save-new-return-address-btn');
      btn.button('loading');
      var addresses = this.controller.get('allShowrooms');
      var newObj = this.controller.get('newReturnAddress');
      newObj.set('id', 0);
      if (addresses.get(addresses.length - 1).get('id') === 0) {
        addresses.set(addresses.length - 1, newObj);
      } else {
        addresses.addObject(newObj);
      }
      this.controller.get('bookingToAdd').setProperties({
        returnId: newObj.get('id'),
        returnStreet1: newObj.get('street'),
        returnStreet2: newObj.get('street2'),
        returnStreet3: newObj.get('street3'),
        returnCountry: newObj.get('country'),
        returnCity: newObj.get('city'),
        returnZipCode: newObj.get('zipCode'),
        returnTelephone: newObj.get('telephone'),
        returnTelephone2: newObj.get('telephone2')
      });
      Ember['default'].$('#new-return-address').modal('hide');
      btn.button('reset');
    },

    onToggleSetAsMainAddress: function onToggleSetAsMainAddress(newCallInAddress) {
      if (newCallInAddress.setAsMain) {
        Ember['default'].set(newCallInAddress, 'setAsMain', false);
      } else {
        Ember['default'].set(newCallInAddress, 'setAsMain', true);
      }
    },

    onRemoveAddress: function onRemoveAddress(address) {
      address.destroyRecord();
    },

    onSaveContactFromUserRefactor: function onSaveContactFromUserRefactor() {
      var route = this;
      var actionButton = Ember['default'].$('#add-mp-found-bookings-btn');
      var actionButton2 = Ember['default'].$('#new-contact-bookings-btn');
      actionButton.button('loading');
      actionButton2.button('loading');

      var user = route.controller.get('addContactSelectedUser');

      Ember['default'].$('#add-mp-found').modal('hide');
      Ember['default'].$('#new-contact-user-refactor-booking').modal('hide');
      route.send('openMessageModal', 'Adding contact...');

      route.store.find('contact', { user: user.id }).then(function (data) {
        actionButton.button('reset');
        actionButton2.button('reset');
        var contact = data.objectAt(0);
        var selectedLists = route.controller.get('addContactSelectedLists');

        if (contact) {
          contact.get('contactsLists').addObjects(selectedLists);

          contact.save().then(function () {
            route.controller.notifyPropertyChange('contactLists');

            route.controller.get('bookingToAdd').set('requester', contact);

            route.send('closeMessageModal');
            Ember['default'].$('#new-booking-2-brand-refactor').modal();
            Ember['default'].openModal();
            //route.send('openTopBar', 'Contact has been added and set as requester succesfully.');
            route.send('openPositiveTopBar');
          });
        } else {
          route.store.find('user', user.id).then(function (userDB) {
            var createdContact = route.store.createRecord('contact', {
              name: user.name,
              surname: user.surname,
              jobTitle: user.jobTitle,
              email: user.email,
              company: user.company,
              user: userDB
            });
            createdContact.get('contactsLists').addObjects(selectedLists);

            createdContact.save().then(function () {
              route.controller.notifyPropertyChange('contactLists');

              route.controller.get('bookingToAdd').set('requester', createdContact);

              route.send('closeMessageModal');
              Ember['default'].$('#new-booking-2-brand-refactor').modal();
              Ember['default'].openModal();
              //route.send('openTopBar', 'Contact has been added and set as requester succesfully.');
              route.send('openPositiveTopBar');
            });
          });
        }
      });
    },

    onCancelAddMp: function onCancelAddMp() {
      Ember['default'].$('#add-mp-found').modal('hide');
      this.controller.set('addContactSelectedUser', null);
      this.controller.set('addContactSelectedLists', []);
      this.controller.get('contactToAdd').set('email', '');
      Ember['default'].$('#new-contact-refactor-booking').modal();
      Ember['default'].openModal();
    },

    onCancelAddMp: function onCancelAddMp() {
      Ember['default'].$('#add-mp-found').modal('hide');
      this.controller.set('addContactSelectedUser', null);
      this.controller.set('addContactSelectedLists', []);
      this.controller.get('contactToAdd').set('email', '');
      Ember['default'].$('#new-contact-refactor-booking').modal();
      Ember['default'].openModal();
    },

    onAddContact: function onAddContact() {
      var route = this;

      Ember['default'].$('#new-booking-2-brand-refactor').modal('hide');

      route.controller.set('contactToAdd', route.store.createRecord('contact'));
      route.controller.set('addContactSelectedUser', 0);
      route.controller.set('canAddContact', false);

      route.controller.set('bookalookUsersLoading', false);
      var users = utils['default'].getAPIJSON('users/mp_contacts').then(function (res) {
        res.map(function (user) {
          //Add img src for show avatar in combo
          var safeString = user.safeString;
          var img = "<img src='" + user.avatar + "' class='avatar-select' />";
          var email = " <span class='display_none'>" + user.email + '</span>';
          user.safeString = new Ember['default'].Handlebars.SafeString(img + safeString + email);

          return user;
        });
        route.controller.set('bookalookUsers', res);
        route.controller.set('bookalookUsersLoading', "enabled");
      });

      route.controller.set('addContactSelectedLists', []);
      //Ember.$('#add-contact').modal();
      //Ember.openModal(1000, '#add-contact');
      Ember['default'].$('#new-contact-user-refactor-booking').modal();
      Ember['default'].openModal();
    }

  });

  function getSortFunction(order, criteria) {
    return function (a, b) {
      var elemA = undefined,
          elemB = undefined;
      if (criteria.indexOf('_') != -1) {
        // For two levels properties
        var criterias = criteria.split('_');
        if (criterias.length === 2) {
          elemA = a[criterias[0]] && a[criterias[0]][criterias[1]] || '';
          elemB = b[criterias[0]] && b[criterias[0]][criterias[1]] || '';
        }
      } else {
        // For one level properties
        elemA = a[criteria] || '';
        elemB = b[criteria] || '';
      }

      if (typeof elemA === 'string') {
        if (order === '-asc') {
          return elemA.localeCompare(elemB);
        } else {
          return -1 * elemA.localeCompare(elemB);
        }
      } else if (typeof elemA === 'number') {
        elemA = elemA != '' ? elemA : 0;
        elemB = elemB != '' ? elemB : 0;
        if (order === '-asc') {
          return elemA - elemB;
        } else {
          return elemB - elemA;
        }
      }
    };
  }

  /*
     Ember.$('#newBookingScrollTable').scroll(function() {

      if(!isHidden){
          //Animate off the screen while scrolling
          $('#newBookingScrollTable').animate({
              top: '-35px'
          }, 250, function() {
          //Make hidden to disable re-rendering
          $('#newBookingScrollTable')[0].style.visibility = "hidden";});
          isHidden = true;
      }
      clearTimeout($.data(this, 'scrollTimer'));
      $.data(this, 'scrollTimer', setTimeout(function() {
          //Animate back on the screen when finished scrolling and make visible
          $('#newBookingScrollTable')[0].style.visibility = "visible";
          $('#newBookingScrollTable').animate({
          top: '0px'
        }, 250, function() {});
        isHidden = false;
      }, 1500));
  });
  */

});