define('bookalook/static-tables', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/static-tables-data'], function (exports, Ember, config, utils, static_tables_data) {

  'use strict';

  exports.loadStaticTables = loadStaticTables;

  function loadStaticTables(route) {
    var tables = static_tables_data.staticTablesData;
    getTable(tables, 'seasons');
    getTable(tables, 'collectionTypes');
    getTable(tables, 'albumTypes');
    getTable(tables, 'colours', true);
    getTable(tables, 'departments');
    getTable(tables, 'fabrics');
    getTable(tables, 'jobTypes');
    getTable(tables, 'deliveryMethods');
    getTable(tables, 'albumPrivacy');
    getTable(tables, 'shippingTypes');

    getTable(tables, 'countries');
    getTable(tables, 'items');

    getTable(tables, 'timezones');

    createYearsTable();

    if (!Ember['default'].countries[0].get) {
      Ember['default'].countries = Ember['default'].countries.map(function (c) {
        return route.get('store').push('country', c);
      });
      Ember['default'].areas = utils['default'].buildCountriesAccordion(Ember['default'].countries);
    }
  }

  function getTable(tableData, tableName, makeSafeString) {
    var dataList = tableData.data[tableName];

    if (tableName === 'countries' || tableName === 'items' || tableName === 'timezones') {
      Ember['default'][tableName] = dataList;
      Ember['default'][tableName + 'Labels'] = dataList.map(function (item) {
        return item.name;
      });
    } else {
      Ember['default'][tableName] = dataList.map(function (item, index) {
        return { id: index + 1, name: item };
      });
      Ember['default'][tableName + 'Labels'] = dataList;
    }

    Ember['default'][tableName + 'LabelsDict'] = {};
    Ember['default'][tableName].forEach(function (item) {
      return Ember['default'][tableName + 'LabelsDict'][item.id] = item.name;
    });

    if (makeSafeString) {
      Ember['default'][tableName] = Ember['default'][tableName].map(function (c) {
        if (tableName === 'colours') {
          c.src = config['default'].IMAGEPATH + tableName + '/' + c.name.replace(/ /g, '').toLowerCase() + '.png';
        } else {
          c.src = config['default'].IMAGEPATH + tableName + '/' + c.id + '.png';
        }
        c.safeString = new Ember['default'].Handlebars.SafeString('<img style="width:20px" src="' + c.src + '"> ' + c.name);
        return c;
      });
    }

    // Database indexes starts in 1
    Ember['default'][tableName + 'Labels'].unshift('');

    if (tableName == "items") {
      (function () {
        var values = {};
        var noDuplicateItemLabels = Ember['default']['items'].filter(function (item) {
          var val = item['name'];
          var exists = values[val];
          values[val] = true;
          return !exists;
        });
        noDuplicateItemLabels.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        Ember['default']['itemsUnique'] = utils['default'].addDefaultOption(noDuplicateItemLabels, 'All samples', 'name', null);
        // Ember['itemsUnique'] = noDuplicateItemLabels;
        // Ember['itemsUnique'].unshift('');
      })();
    }
  }

  function createYearsTable() {
    var currentYear = new Date().getFullYear();
    var years = [];
    //Ember.years = [];
    for (var i = currentYear + 5; i >= 1947; i--) {
      years.push({ id: i });
    }
    //Ember.years = years;
    Ember['default'].set(Ember['default'], 'years', years);
  }

});