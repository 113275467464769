define('bookalook/tests/routes/collections/album.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/collections/album.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/collections/album.js should pass jshint.\nroutes/collections/album.js: line 530, col 44, [\'name\'] is better written in dot notation.\nroutes/collections/album.js: line 582, col 30, The array literal notation [] is preferable.\nroutes/collections/album.js: line 1454, col 9, \'position\' is already defined.\nroutes/collections/album.js: line 1518, col 49, Missing semicolon.\nroutes/collections/album.js: line 1533, col 43, Missing semicolon.\nroutes/collections/album.js: line 1597, col 14, Missing semicolon.\nroutes/collections/album.js: line 1589, col 25, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (index, position, newOrder)\nroutes/collections/album.js: line 1679, col 70, Missing semicolon.\n\n8 errors');
  });

});