define('bookalook/helpers/contains-text', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0] || "";
    var o2 = params[1] || "";

    o1 = o1.toLowerCase();
    o2 = o2.toLowerCase();

    return o1.indexOf(o2) > -1;
  });

});