define('bookalook/models/job', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, Ember, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    jobType: DS['default'].attr('string'),
    country: DS['default'].attr('id'),

    callInDateStart: DS['default'].attr('day'),
    callInDateEnd: DS['default'].attr('day'),
    jobDateStart: DS['default'].attr('day'),
    jobDateEnd: DS['default'].attr('day'),
    returnDateStart: DS['default'].attr('day'),
    returnDateEnd: DS['default'].attr('day'),

    lastUpdated: DS['default'].attr('day'),

    comments: DS['default'].attr('string'),

    addresses: DS['default'].hasMany('job-address', { async: true }),
    jobStatus: DS['default'].hasMany('job-status', { async: true }),
    files: DS['default'].hasMany('file', { async: true }),

    active: DS['default'].attr('boolean'),
    closed: DS['default'].attr('boolean'),
    updates: DS['default'].attr('boolean'),

    status: DS['default'].attr('string'),
    deleted: DS['default'].attr('boolean'),

    samples: (function () {
      return this.get('jobStatus').mapBy('sample');
    }).property('jobStatus.@each.sample', 'jobSamplesIds'),

    visibleSamples: (function () {
      return this.get('samples').filter(function (s) {
        return s.get('visible') && !Ember['default'].isNone(s.get('jobStatus.status')) && s.get('jobStatus.status') !== 'REMOVED';
      });
    }).property('samples.@each.visible'),

    visibleLooks: (function () {
      if (this.get('looks')) {
        return this.get('looks').filter(function (l) {
          return l.get('samples').some(function (s) {
            return !Ember['default'].isNone(s.get('jobStatus.status')) && s.get('jobStatus.status') !== 'REMOVED';
          });
        });
      }
    }).property('looks', 'jobSamplesIds').volatile(),

    looksNotRemoved: (function () {
      if (this.get('looks')) {
        return this.get('looks').filter(function (l) {
          return l.get('samples').some(function (s) {
            return !Ember['default'].isNone(s.get('jobStatus.status')) && s.get('jobStatus.status') !== 'REMOVED';
          });
        });
      }
    }).property('looks', 'jobSamplesIds').volatile(),

    jobSamplesIds: (function () {
      return this.get('jobStatus').map(function (js) {
        return parseInt(js.get('sample.id'));
      }, 10);
    }).property('jobStatus.@each.sample'),

    completeReset: function completeReset() {
      this.rollbackAttributes();
      this.get('addresses').forEach(function (a) {
        return a.rollbackAttributes();
      });
      this.get('files').forEach(function (f) {
        f.rollbackAttributes();
        f.set('toDelete', null);
      });
    },

    filesNotNew: (function () {
      return this.get('files').filter(function (f) {
        return !f.get('isNew');
      });
    }).property('files'),

    jobTypeLabel: (function () {
      return this.get('jobType');
    }).property('jobType'),

    countryLabel: (function () {
      return Ember['default'].countriesLabels[this.get('country')];
    }).property('country'),

    mainAddress: (function () {
      return this.get('addresses').findBy('main', true);
    }).property('addresses.@each.main'),

    secondaryAddresses: (function () {
      return this.get('addresses').filterBy('main', false);
    }).property('addresses.@each.main'),

    hasMinimunAttrsSet: (function () {
      var hasName = !Ember['default'].isEmpty(this.get('name'));
      var hasJobType = !Ember['default'].isNone(this.get('jobType'));
      var hasCountry = !Ember['default'].isNone(this.get('country'));

      return hasName && hasJobType && hasCountry;
    }).property('name', 'jobType', 'country'),

    hasDatesSet: (function () {
      if (Ember['default'].isNone(this.get('callInDateStart')) || Ember['default'].isNone(this.get('callInDateEnd')) || Ember['default'].isNone(this.get('jobDateStart')) || Ember['default'].isNone(this.get('jobDateEnd')) || Ember['default'].isNone(this.get('returnDateStart')) || Ember['default'].isNone(this.get('returnDateEnd'))) {
        return false;
      } else {
        return true;
      }
    }).property('callInDateStart', 'callInDateEnd', 'jobDateStart', 'jobDateEnd', 'returnDateStart', 'returnDateEnd'),

    isCompleted: (function () {
      var conditions = [this.get('hasMinimunAttrsSet'), this.get('hasDatesSet'), this.get('mainAddress.hasSomeField')];

      return conditions.every(function (c) {
        return c;
      });
    }).property('hasMinimunAttrsSet', 'callInDateStart', 'callInDateEnd', 'jobDateStart', 'jobDateEnd', 'returnDateStart', 'returnDateEnd', 'mainAddress.city'),

    areAllSamplesRequested: (function () {
      return this.get('samples').every(function (s) {
        return s.get('isRequested');
      });
    }).property('samples.@each.isRequested'),

    setSampleStatus: function setSampleStatus() {
      this.get('jobStatus').forEach(function (js) {
        var sample = js.get('sample');
        Ember['default'].set(sample, 'jobStatus', js);
        Ember['default'].set(sample, 'isRequested', js.get('status') !== 'OPEN');
        /*sample.setProperties({
          jobStatus: js,
          isRequested: js.get('status') !== 'OPEN'
        });*/
      });
      this.notifyPropertyChange('looks');
      this.notifyPropertyChange('samples');
      this.notifyPropertyChange('samples.@each.jobStatus');
    },

    //A Job can be closed when:
    // IS NOT EMPTY AND
    // - All samples are returned
    // - Have some returned sample and the others are canceled/declined/removed/unavailable
    canBeClosed: (function () {
      var samples = this.get('samples');
      if (Ember['default'].isEmpty(samples)) {
        return false;
      } else {
        if (samples.every(function (s) {
          return s.get('jobStatus.status') === 'RETURNED';
        })) {
          return true;
        } else {
          return samples.some(function (s) {
            return s.get('jobStatus.status') === 'RETURNED';
          }) && samples.every(function (s) {
            return s.get('jobStatus.status') === 'RETURNED' || s.get('jobStatus.status') === 'PROPOSAL CANCELED' || s.get('jobStatus.status') === 'CANCELED' || s.get('jobStatus.status') === 'DECLINED' || s.get('jobStatus.status') === 'REMOVED' || s.get('jobStatus.status') === 'UNAVAILABLE';
          });
        }
      }
    }).property('samples', 'samples.@each.jobStatus'),

    //A Job can be cancelled when:
    // IS EMPTY OR
    // - All samples are open
    // - All samles are requested
    // - All samples are booked
    // - All samples are cancelled
    // - All samples are declined
    // - All samples are unavailable
    // - All samples have a combination of the statues above
    canBeCancelled: (function () {
      var samples = this.get('samples');
      if (Ember['default'].isEmpty(samples)) {
        return true;
      } else {
        return !samples.some(function (s) {
          return s.get('jobStatus.status') !== 'OPEN' && s.get('jobStatus.status') !== 'REQUESTED' && s.get('jobStatus.status') !== 'BOOKED' && s.get('jobStatus.status') !== 'PROPOSED' && s.get('jobStatus.status') !== 'PROPOSAL CANCELED' && s.get('jobStatus.status') !== 'CANCELED' && s.get('jobStatus.status') !== 'DECLINED' && s.get('jobStatus.status') !== 'REMOVED' && s.get('jobStatus.status') !== 'UNAVAILABLE';
        });
      }
    }).property('samples', 'samples.@each.jobStatus'),

    isOpen: (function () {
      return !this.get('active') && !this.get('closed');
    }).property('active', 'closed'),

    isClosed: (function () {
      return this.get('active') && this.get('closed');
    }).property('closed', 'active'),

    isCancelled: (function () {
      return !this.get('active') && this.get('closed');
    }).property('closed', 'active'),

    isActive: (function () {
      return this.get('active') && !this.get('closed');
    }).property('active', 'closed'),

    jobStatusLabel: (function () {
      var status = undefined;
      var today = Date.now();

      var callInDateStart = Date.parse(this.get('callInDateStart'));
      var jobDateStart = Date.parse(this.get('jobDateStart'));
      var returnDateStart = Date.parse(this.get('returnDateStart'));
      var returnDateEnd = Date.parse(this.get('returnDateEnd'));

      if (today < callInDateStart) {
        status = "UPCOMING";
      } else if (today >= callInDateStart && today <= jobDateStart) {
        status = "CALL-IN DAY";
      } else if (today >= jobDateStart && today <= returnDateStart) {
        status = "JOB DAY";
      } else if (today >= returnDateStart && today <= returnDateEnd) {
        status = "RETURN DAY";
      } else if (today > returnDateEnd) {
        status = "TIME OUT";
      } else {
        status = "-";
      }
      return status;
    }).property('callInDateStart', 'callInDateEnd', 'jobDateStart', 'jobDateEnd', 'returnDateStart', 'returnDateEnd'),

    canChangeJobCallInInfo: (function () {
      return false;
    }).property('bookings'),

    cantRequestAll: (function () {
      var samples = this.get('samples');
      return samples.every(function (sample) {
        return sample.get('isRequested');
      });
    }).property('samples.@each.isRequested'),

    /*
    //checkedValue cant be higher than startValue.
    //if startValue is null -> checkedValue must be null
    _checkDate(startValue, checkedValue){
      let end = this.get(checkedValue);
      let start = this.get(startValue);
      if (Ember.isBlank(start)){
        this.set(checkedValue, null);
      } else {
        if (Ember.isBlank(end) || (end < start) ){
          this.set(checkedValue, start);
        }
      }
    },
     callInDateEndObserver: function(){
      this._checkDate('callInDateStart', 'callInDateEnd');
    }.observes('callInDateStart', 'callInDateEnd'),
     jobDateStartObserver: function(){
      this._checkDate('callInDateEnd', 'jobDateStart');
    }.observes('callInDateEnd', 'jobDateStart'),
     jobDateEndObserver: function(){
      this._checkDate('jobDateStart', 'jobDateEnd');
    }.observes('jobDateStart', 'jobDateEnd'),
     returnDateStartObserver: function(){
      this._checkDate('jobDateEnd', 'returnDateStart');
    }.observes('jobDateEnd', 'returnDateStart'),
     returnDateEndObserver: function(){
      this._checkDate('returnDateStart', 'returnDateEnd');
    }.observes('returnDateStart', 'returnDateEnd'),*/

    _wrongDate: function _wrongDate(startValue, checkedValue) {
      var end = this.get(checkedValue);
      var start = Ember['default'].isNone(startValue) ? null : this.get(startValue);
      if (Ember['default'].isBlank(end) || start && end < start) {
        return true;
      } else {
        return false;
      }
    },

    validations: {
      'name': {
        presence: true
      },
      'callInDateStart': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model._wrongDate(null, 'callInDateStart');
          },
          message: 'You must set callInDateStart.'
        }]
      },
      'callInDateEnd': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model._wrongDate('callInDateStart', 'callInDateEnd');
          },
          message: 'CallInDateEnd cant be lower than callInDateStart.'
        }]
      },
      'jobDateStart': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model._wrongDate('callInDateEnd', 'jobDateStart');
          },
          message: 'JobDateStart cant be lower than callInDateEnd.'
        }]
      },
      'jobDateEnd': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model._wrongDate('jobDateStart', 'jobDateEnd');
          },
          message: 'JobDateEnd cant be lower than jobDateStart.'
        }]
      },
      'returnDateStart': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model._wrongDate('jobDateEnd', 'returnDateStart');
          },
          message: 'ReturnDateStart cant be lower than jobDateEnd.'
        }]
      },
      'returnDateEnd': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model._wrongDate('returnDateStart', 'returnDateEnd');
          },
          message: 'ReturnDateEnd cant be lower than returnDateStart.'
        }]
      }
    }
  });

});