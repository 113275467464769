define('bookalook/routes/profile/collection-profile', ['exports', 'ember', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, utils, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      didTransition: function didTransition() {
        this.onDidTransition();
      }
    },

    model: function model(params) {
      return this.store.findRecord('collection', params.id);
      //Get collection from DB: no archived albums, no unpublished albums, no looks without image
    },

    setupController: function setupController(controller, model) {
      model.reload();
      this._super(controller, model);

      controller.setProperties({
        collection: model
      });
    },

    onDidTransition: function onDidTransition() {
      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        Ember['default'].$('.slick-carousel').slick({
          speed: 800,
          infinite: true,
          slidesToShow: 1,
          variableWidth: true
        });
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-settings page-settings-brand');
      Ember['default'].$('.search_suggestion_wrapper').removeClass('show');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-settings page-settings-brand');
    }
  });

});