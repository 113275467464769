define('bookalook/components/slider-pagination', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    /** Atributos a pasarle:
     *  items => array que quieres paginar
     *  itemsByPage => numero de elementos de cada pagina (10 si no se lo pasas)
     */
    activePage: 1,
    itemsByPage: 3,
    changeLocked: false,

    actions: {
      goToFirstPage: function goToFirstPage() {
        if (this.get('previousActive')) this.set('activePage', 1);
      },
      goToLastPage: function goToLastPage() {
        if (this.get('nextActive')) this.set('activePage', this.get('totalPages'));
      },
      goToPreviousPage: function goToPreviousPage() {
        var _this = this;

        if (this.get('previousActive') && !this.get('changeLocked')) {
          (function () {
            var t = _this;
            t.set('changeLocked', true);
            var button = null;

            button = Ember['default'].$("#slider-pagination-left");

            var sliders = Ember['default'].$(button).siblings('.slider_pagination_content').get(0);
            $(sliders).animate({ left: "+100%" }, 250, function () {
              $(sliders).animate({ left: "-200%" }, 0, function () {
                t.set('activePage', t.get('activePage') - 1);
                $(sliders).animate({ left: "0" }, 250, function () {
                  t.set('changeLocked', false);
                });
              });
            });
          })();
        }
      },
      goToNextPage: function goToNextPage() {
        var _this2 = this;

        if (this.get('nextActive') && !this.get('changeLocked')) {
          (function () {
            var t = _this2;
            t.set('changeLocked', true);
            var button = null;

            button = Ember['default'].$("#slider-pagination-right");

            var sliders = Ember['default'].$(button).siblings('.slider_pagination_content').get(0);
            $(sliders).animate({ left: "-100%" }, 250, function () {
              $(sliders).animate({ left: "+200%" }, 0, function () {
                t.set('activePage', t.get('activePage') + 1);
                $(sliders).animate({ left: "0" }, 250, function () {
                  t.set('changeLocked', false);
                });
              });
            });
          })();
        }
      },
      changePage: function changePage(page) {
        this.set('activePage', page);
      }
    },

    //Numero de paginas
    totalPages: Ember['default'].computed('items', function () {
      var items = this.get('items');
      var itemsByPage = parseInt(this.get('itemsByPage'));
      if (items) {
        var _length = items.length;
        if (_length !== parseInt(_length, 10) && _length != null) {
          _length = items.get('length');
        }

        var pages = Math.floor(_length / itemsByPage);
        //Para sumar pagina con menos de (itemsByPage) elementos
        if (_length % itemsByPage > 0) pages += 1;
        this.set('activePage', 1);
        return pages;
      } else return 0;
    }),

    //Debido a que aunque vaya de 0 a X, al en hbs empieza por 1
    arrayPages: Ember['default'].computed('totalPages', function () {
      var pages = this.get('totalPages');
      var result = [];
      for (var i = 0; i < pages; i++) {
        result[i] = i + 1;
      }
      return result;
    }),

    //Items paginados
    itemsPaginated: Ember['default'].computed('items', 'activePage', function () {
      var page = parseInt(this.get('activePage'));
      var itemsByPage = parseInt(this.get('itemsByPage'));
      var startingItem = (page - 1) * itemsByPage;
      var lastItem = startingItem + itemsByPage;
      var result = [];
      if (this.get('items') && this.get('items').length) {
        result = this.get('items').slice(startingItem, lastItem);
      }

      return result;
    }),

    nextActive: Ember['default'].computed('items', 'activePage', function () {
      return this.get('activePage') < this.get('totalPages');
    }),
    previousActive: Ember['default'].computed('items', 'activePage', function () {
      return this.get('activePage') > 1;
    }),
    showArrows: Ember['default'].computed('totalPages', function () {
      return this.get('totalPages') > 1;
    })
  });

});