define('bookalook/helpers/if-function', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (params[0] && params[0].get(params[1])) {
      return params[0].get(params[1]).apply(params[0], [params[2]]);
    }
  });

});