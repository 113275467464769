define('bookalook/models/mp-company', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    website: DS['default'].attr('string'),

    validations: {
      'name': {
        presence: true,
        length: {
          minimum: 3
        }
      },

      'website': {
        presence: true
      }
    }
  });

});