define('bookalook/routes/settings/functions', ['exports', 'ember', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, utils, config) {

  'use strict';

  exports.getUniquePlans = getUniquePlans;
  exports.setUniquePlansInController = setUniquePlansInController;
  exports.getCounts = getCounts;
  exports.setSelectedPlan = setSelectedPlan;
  exports.setCurrentPlan = setCurrentPlan;
  exports.getPlanByType = getPlanByType;
  exports.checkLimitations = checkLimitations;
  exports.initializeController = initializeController;
  exports.getPlanFromShowroomManager = getPlanFromShowroomManager;
  exports.getSubscriptionStatus = getSubscriptionStatus;
  exports.getStripeCustomerInfo = getStripeCustomerInfo;
  exports.getCustomerCards = getCustomerCards;
  exports.changeSelectedInterval = changeSelectedInterval;
  exports.checkDowngrade = checkDowngrade;
  exports.switchPlan = switchPlan;
  exports.startCreateNewCard = startCreateNewCard;
  exports.cancelCreateNewCard = cancelCreateNewCard;
  exports.createNewCardAndPay = createNewCardAndPay;
  exports.createNewCard = createNewCard;
  exports.changeCardOnPaymentFailed = changeCardOnPaymentFailed;
  exports.apiCreateNewCardAndPay = apiCreateNewCardAndPay;
  exports.apiCreateNewCard = apiCreateNewCard;
  exports.apiDeleteCard = apiDeleteCard;
  exports.restartNewCard = restartNewCard;
  exports.checkFailCardName = checkFailCardName;
  exports.checkFailCardNumber = checkFailCardNumber;
  exports.checkFailCardCvc = checkFailCardCvc;
  exports.checkFailCardDate = checkFailCardDate;
  exports.checkFailNewCard = checkFailNewCard;
  exports.completePurchase = completePurchase;
  exports.getInvoices = getInvoices;
  exports.setMonthsYearsArrays = setMonthsYearsArrays;

  function getUniquePlans(route) {
    return route.store.findAll('plan').then(function (data) {
      var filteredPlans = Ember['default'].A();
      var starter = data.find(function (p) {
        var name = p.get('idStripe').toLowerCase();
        return name.indexOf('starter') !== -1;
      });
      var newdesigner = data.find(function (p) {
        var name = p.get('idStripe').toLowerCase();
        return name.indexOf('newdesigner') !== -1;
      });
      var pro = data.find(function (p) {
        var name = p.get('idStripe').toLowerCase();
        return name.indexOf('pro') !== -1;
      });
      var corporate = data.find(function (p) {
        var name = p.get('idStripe').toLowerCase();
        return name.indexOf('corporate') !== -1;
      });
      filteredPlans['starter'] = starter;
      filteredPlans['designer'] = newdesigner;
      filteredPlans['pro'] = pro;
      filteredPlans['corporate'] = corporate;
      return filteredPlans;
    });
  }

  /**
   * Set unique plans in controller variables (starterPlan, designerPlan, proPlan, corporatePlan).
   *
   * @param route : route ( in a controller it is 'this' )
   * @param plans : array ( getUniquePlans() )
   */

  function setUniquePlansInController(route, plans) {
    var controller = route.controller;

    var starterPlan = plans['starter'];
    var designerPlan = plans['designer'];
    var proPlan = plans['pro'];
    var corporatePlan = plans['corporate'];

    controller.set('starterPlan', starterPlan);
    controller.set('designerPlan', designerPlan);
    controller.set('proPlan', proPlan);
    controller.set('corporatePlan', corporatePlan);
  }

  /**
   * Get showroom brands, samples, users, and bookings.
   * Set them in controller vars: agencyBrandsCount, samplesCount, usersCount, bookingsCount
   *
   * @param route : route ( in a controller it is 'this' )
   */

  function getCounts(route) {
    var _t = route;
    var controller = _t.controller;
    var user = _t.controller.get('user');

    var deferred = Ember['default'].$.Deferred();
    var promises = [];

    var agencyBrandsCount = 0;
    var samplesCount = 0;
    var usersCount = 0;
    var bookingsCount = 0;

    if (!user.get('isBrand')) {
      promises.push(utils['default'].getAPIJSON('agencies/areas/' + Ember['default'].showroom.companyTypeAndId.id).then(function (data) {
        agencyBrandsCount = data.agency.length;
      }));
    } else {
      agencyBrandsCount = 1;
    }
    promises.push(utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countAllSamples').then(function (res) {
      samplesCount = res;
    }));
    promises.push(utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countUsers').then(function (res) {
      usersCount = res;
    }));
    promises.push(utils['default'].getAPIJSON('showrooms/' + Ember['default'].showroomId + '/countBookings').then(function (res) {
      bookingsCount = res;
    }));

    Ember['default'].RSVP.all(promises).then(function () {
      deferred.resolve();
    });

    deferred.done(function () {
      controller.set('agencyBrandsCount', agencyBrandsCount);
      controller.set('samplesCount', samplesCount);
      controller.set('usersCount', usersCount);
      controller.set('bookingsCount', bookingsCount);
      controller.set('countsLoaded', true);
      checkLimitations(route);
    });
  }

  /**
   * Set a plan as selected and unselect the other ones.
   * Set a simpleString('starter'/'pro'/'designer'/'corporate') in controller var selectedPlanString
   * Set a plan entity in controller var selectedPlan
   *
   * Using controller vars: starterSelected, designerSelected, proSelected, corporateSelected
   *
   * @param route : route ( in a controller it is 'this' )
   * @param plan : string ( SimpleString (Pro) / PlanID (ProYearlyEUR ) / Name (Pro Yearly (EUR)) )
   */

  function setSelectedPlan(route, plan) {
    var _t = route;
    plan = plan.toLowerCase();
    var plansStrings = ['starter', 'designer', 'pro', 'corporate'];
    for (var i = 0; i < plansStrings.length; i++) {
      var selected = plan.indexOf(plansStrings[i]) !== -1;
      _t.controller.set(plansStrings[i] + 'Selected', selected);
      if (selected) {
        _t.controller.set('selectedPlanString', plansStrings[i]);
        _t.controller.set('selectedPlan', getPlanByType(route, plansStrings[i]));
      }
    }
  }

  /**
   * Set a plan as current and unselect the other ones.
   * Set a simpleString('starter'/'pro'/'designer'/'corporate') in controller var currentPlanString
   * Set a plan entity in controller var currentPlan
   * Set true the currentPlanLoaded flag in controller
   *
   * Using controller vars: starterCurrent, designerCurrent, proCurrent, corporateCurrent
   *
   * @param route : route ( in a controller it is 'this' )
   * @param plan : string ( SimpleString (Pro) / PlanID (ProYearlyEUR ) / Name (Pro Yearly (EUR)) )
   */

  function setCurrentPlan(route, plan) {
    var _t = route;
    plan = plan.toLowerCase();
    var plansStrings = ['starter', 'designer', 'pro', 'corporate'];
    for (var i = 0; i < plansStrings.length; i++) {
      var selected = plan.indexOf(plansStrings[i]) !== -1;
      _t.controller.set(plansStrings[i] + 'Current', selected);
      if (selected) {
        _t.controller.set('currentPlanString', plansStrings[i]);
        _t.controller.set('currentPlan', getPlanByType(_t, plansStrings[i]));
        _t.controller.set('currentPlanLoaded', true);
        _t.controller.notifyPropertyChange('currentPlanLoaded');
      }
    }
  }

  /**
   * Return a plan entity.
   * Need getUniquePlans() array setted in controller.plans
   *
   * @param route : route ( in a controller it is 'this' )
   * @param plan : string ( SimpleString (starter/designer/pro/corporate) )
   */

  function getPlanByType(route, plan) {
    var _t = route;
    var plans = _t.controller.get('plans');
    plan = plan.toLowerCase();
    if (plan === "newdesigner") {
      plan = "designer";
    }
    return plans[plan];
  }

  /**
   * Check if current user can change the plan to another one and set the vars in controller.
   *
   * This function will set all plans: [plan simple string]Disabled as true or false (for ex. starterDisabled)
   *
   * Set somePlanAvailable true/false if the user can change to any plan
   * Also set limitationsChecked flag to true when finished
   *
   * @param route : route ( in a controller it is 'this' )
   */

  function checkLimitations(route) {
    var _t = route;
    var agencyBrandsCount = _t.controller.get('agencyBrandsCount');
    var samplesCount = _t.controller.get('samplesCount');
    var usersCount = _t.controller.get('usersCount');
    var bookingsCount = _t.controller.get('bookingsCount');

    var plans = _t.controller.get('plans');
    for (var key in plans) {
      if (key === "starter" || key === "designer" || key === "pro" || key === "corporate") {
        if (parseInt(plans[key].get('maxBrands')) != 0 && agencyBrandsCount > parseInt(plans[key].get('maxBrands')) || parseInt(plans[key].get('maxSamples')) != 0 && samplesCount > parseInt(plans[key].get('maxSamples')) || parseInt(plans[key].get('maxUsers')) != 0 && usersCount > parseInt(plans[key].get('maxUsers')) || parseInt(plans[key].get('maxBookings')) != 0 && bookingsCount > parseInt(plans[key].get('maxBookings'))) {
          _t.controller.set(key + 'Disabled', true);
        } else {
          _t.controller.set(key + 'Disabled', false);
        }
      }
    }
    if (!_t.controller.get('starterDisabled') || !_t.controller.get('designerDisabled') || !_t.controller.get('proDisabled')) {
      _t.controller.set('somePlanAvailable', true);
    } else {
      _t.controller.set('somePlanAvailable', false);
    }
    _t.controller.set('limitationsChecked', true);
  }

  /**
   * YOU NEED TO CALL IN MODEL: {
   *  plans: plansFunctions.getUniquePlans(this),
   *  planFromShManager: plansFunctions.getPlanFromShowroomManager()
   * }
   * Then, after controller.setProperties(model) in Controller setupController() you can call this function
   * @param route
   */

  function initializeController(route) {
    var _t = route;
    var controller = _t.controller;

    //SET PLANS
    var plans = controller.get('plans');
    setUniquePlansInController(_t, plans);

    controller.set('selectPlanAvailable', false);
    controller.set('currentPlanLoaded', false);
    controller.set('checkLimitations', false);
    controller.set('countsLoaded', false);

    controller.set('maxBookingsReached', false);
    controller.set('maxUsersReached', false);
    controller.set('maxSamplesReached', false);
    controller.set('maxBrandsReached', false);

    controller.set('subscriptionLoading', true);

    //Get current user data from db
    var dataShManager = controller.get('planFromShManager');
    if (!Ember['default'].isNone(dataShManager.planId) && !Ember['default'].isBlank(dataShManager.planId)) {
      setCurrentPlan(_t, dataShManager.planId);
      if (controller.get('trialEndedPage')) {
        if (!controller.get('corporateCurrent')) {
          setSelectedPlan(_t, dataShManager.planId);
        }
      }
      if (controller.get('selectPlanPage') && route.get('session').get('remaining_days')) {
        setSelectedPlan(_t, dataShManager.planId);
      }
      if (dataShManager.currency) {
        _t.controller.set('currency', dataShManager.currency.toUpperCase());
      }
      if (dataShManager.planInterval === "year") {
        _t.controller.set('monthlySelected', false);
        _t.controller.set('currentIntervalMonthly', false);
        _t.controller.set('intervalString', 'Yearly');
        _t.controller.set('yearlySelected', true);
        _t.controller.set('currentIntervalYearly', true);
      } else {
        _t.controller.set('monthlySelected', true);
        _t.controller.set('currentIntervalMonthly', true);
        _t.controller.set('intervalString', 'Monthly');
        _t.controller.set('yearlySelected', false);
        _t.controller.set('currentIntervalYearly', false);
      }
    }

    //Get user data(samples, bookings, users...)
    getCounts(_t);

    //Get current user data from stripe
    getSubscriptionStatus().then(function (res) {
      _t.controller.set('stripeCustomerId', res.stripeCustomerId);
      var amount = res.planAmount;
      var quantity = res.planQuantity;
      var planCurrency = res.planCurrency;
      //We retrieve amount whitout commas so we have to /100 to get the correct price
      if (!Ember['default'].isNone(amount)) {
        if (typeof amount === "number") {
          amount = amount / 100;
          amount = amount.toString();
        } else if (typeof amount === "string") {
          amount = amount.slice(0, -2);
        }

        amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      _t.controller.set('planAmount', amount);
      _t.controller.set('planQuantity', quantity);
      _t.controller.set('currency', planCurrency);
      getStripeCustomerInfo(res.stripeCustomerId).then(function (data) {
        var customer = data.stripe_data.customer;
        var currency = customer.currency;
        _t.controller.set('currency', currency.toUpperCase());
        var subscriptions = customer.subscriptions;
        var subscription = null;
        if (subscriptions.length > 0) {
          subscription = subscriptions[0];
          _t.controller.set('subscription', subscription);

          _t.controller.set('subscriptionLoading', false);

          if (subscription.planInterval === "year") {
            _t.controller.set('monthlySelected', false);
            _t.controller.set('currentIntervalMonthly', false);
            _t.controller.set('intervalString', 'Yearly');
            _t.controller.set('yearlySelected', true);
            _t.controller.set('currentIntervalYearly', true);
          } else {
            _t.controller.set('monthlySelected', true);
            _t.controller.set('currentIntervalMonthly', true);
            _t.controller.set('intervalString', 'Monthly');
            _t.controller.set('yearlySelected', false);
            _t.controller.set('currentIntervalYearly', false);
          }
          setCurrentPlan(_t, subscription.plan);
          if (controller.get('trialEndedPage')) {
            if (!controller.get('corporateCurrent')) {
              setSelectedPlan(_t, subscription.plan);
            }
          }
          if (controller.get('selectPlanPage') && route.get('session').get('remaining_days')) {
            setSelectedPlan(_t, subscription.plan);
          }
        }
      });

      getCustomerCards(res.stripeCustomerId).then(function (data) {
        var response = data.stripe_data;
        var cards = response.cards;
        if (cards.get('length') > 0) {
          var card = cards.get(0);
          controller.set('userCard', card);
        } else {
          controller.set('userCard', null);
        }
      });
    });

    //All plans will be unselected at the begin
    setSelectedPlan(_t, 'zzzzz');

    controller.monthlyYearlyObserver = function () {
      controller.set('starterSelected', false);
      controller.set('designerSelected', false);
      controller.set('proSelected', false);
      controller.set('corporateSelected', false);
    };

    //Unselect all plans if interval changes
    utils['default'].addObserver(controller, ['monthlySelected', 'yearlySelected'], 'monthlyYearlyObserver');

    controller.nextStepIntervalsObserver = function () {
      var monthlySelected = controller.get('monthlySelected');
      var yearlySelected = controller.get('yearlySelected');
      var starter = controller.get('starterSelected');
      var designer = controller.get('designerSelected');
      var pro = controller.get('proSelected');
      var corporate = controller.get('corporateSelected');
      var intervalSelected = false;
      var planSelected = false;

      if (!starter && !designer && !pro && !corporate) {
        planSelected = false;
      } else {
        planSelected = true;
      }
      if (!monthlySelected && !yearlySelected) {
        intervalSelected = false;
      } else {
        intervalSelected = true;
      }

      if (planSelected && intervalSelected) {
        controller.set('nextStep1', true);
      } else {
        controller.set('nextStep1', false);
      }
    };

    //If interval and plan aren't selected, Next button is disabled
    utils['default'].addObserver(controller, ['monthlySelected', 'yearlySelected', 'starterSelected', 'designerSelected', 'proSelected', 'corporateSelected'], 'nextStepIntervalsObserver');

    controller.selectPlanAvailableObserver = function () {
      if (controller.get('somePlanAvailable') && (controller.get('starterCurrent') || controller.get('designerCurrent') || controller.get('proCurrent'))) {
        controller.set('selectPlanAvailable', true);
      } else {
        controller.set('selectPlanAvailable', false);
      }
    };

    //Corporate can't change plan.
    //If user can't change the plan, do not show next button or interval selects
    utils['default'].addObserver(controller, ['somePlanAvailable', 'currentPlanLoaded'], 'selectPlanAvailableObserver');

    //Create obj newCard
    restartNewCard(_t);

    setMonthsYearsArrays(_t);

    controller.setLimitationsObserver = function () {
      if (controller.get('countsLoaded') && controller.get('currentPlanLoaded')) {
        var plan = controller.get('currentPlan');
        var agencyBrandsCount = _t.controller.get('agencyBrandsCount');
        var samplesCount = _t.controller.get('samplesCount');
        var usersCount = _t.controller.get('usersCount');
        var bookingsCount = _t.controller.get('bookingsCount');

        if (parseInt(plan.get('maxBrands')) != 0 && agencyBrandsCount >= parseInt(plan.get('maxBrands'))) {
          controller.set('maxBrandsReached', true);
        } else {
          controller.set('maxBrandsReached', false);
        }
        if (parseInt(plan.get('maxSamples')) != 0 && samplesCount >= parseInt(plan.get('maxSamples'))) {
          controller.set('maxSamplesReached', true);
        } else {
          controller.set('maxSamplesReached', false);
        }
        if (parseInt(plan.get('maxUsers')) != 0 && usersCount >= parseInt(plan.get('maxUsers'))) {
          controller.set('maxUsersReached', true);
        } else {
          controller.set('maxUsersReached', false);
        }
        if (parseInt(plan.get('maxBookings')) != 0 && bookingsCount >= parseInt(plan.get('maxBookings'))) {
          controller.set('maxBookingsReached', true);
        } else {
          controller.set('maxBookingsReached', false);
        }
      }
    };

    utils['default'].addObserver(controller, ['countsLoaded', 'currentPlanLoaded', 'agencyBrandsCount', 'samplesCount', 'usersCount', 'bookingsCount'], 'setLimitationsObserver');
  }

  function getPlanFromShowroomManager() {
    return utils['default'].getAPIJSON('plans/planFromShowroomManager');
  }

  function getSubscriptionStatus() {
    return utils['default'].getAPIJSON('plans/getSubscriptionStatus');
  }

  /*
  "stripe_data": {
    "customer": {
      "id": "",
      "currency": "eur",
      "description": "",
      "email": "",
      "subscriptions": [
        {
          "id": "sub_",
          "plan": "CorporateYearlyEUR",
          "planName": "Corporate (Yearly / EUR)",
          "planInterval": "year",
          "status": "active"
        }
      ]
    }
  }*/

  function getStripeCustomerInfo(stripeCustomerId) {
    return utils['default'].getAPIJSON('paywithstripe/get_customer/' + stripeCustomerId);
  }

  /**
   * Return a cards array, but BAL only uses 1 card.
   *
  {
    "stripe_data": {
      "cards": [
        {
          "id": "card_XXXX",
          "brand": "Visa",
          "exp_month": 6,
          "exp_year": 2017,
          "last4": "1881",
          "name": "ggrrr",
          "address_city": "DSeAs",
          "address_country": "asdasdas",
          "address_line1": "c\/asdasdas,2e",
          "address_line2": "sadasd",
          "address_state": "Sfrty",
          "address_zip": "12345"
        }
      ],
      "legal": {
        "vat": null,
        "companyName": ""
      }
    }
  }
   * @param stripeCustomerId Could be null (and uses Ember.user.stripeCustomerId)
   * @return {*}
   */

  function getCustomerCards() {
    var stripeCustomerId = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

    var id = stripeCustomerId || Ember['default'].user.get('stripeCustomerId');
    return utils['default'].getAPIJSON('paywithstripe/get_cards_customer/' + id);
  }

  function changeSelectedInterval(route, interval) {
    if (interval === "year") {
      route.controller.set('monthlySelected', false);
      route.controller.set('yearlySelected', true);
      route.controller.set('intervalString', 'Yearly');
    } else {
      route.controller.set('monthlySelected', true);
      route.controller.set('yearlySelected', false);
      route.controller.set('intervalString', 'Monthly');
    }
  }

  function checkDowngrade(route) {
    var stripePlanId = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    if (!stripePlanId) {
      stripePlanId = route.controller.get('selectedPlan.stripeId');
    }
    return utils['default'].getAPIJSON('plans/checkDowngrade/plan/' + stripePlanId);
  }

  function switchPlan(route) {
    var stripeCustomerId = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
    var stripePlanId = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    //if (!stripePlanId){
    //  stripePlanId = route.controller.get('selectedPlan').get('idStripe');
    //}
    //Users can not change currencies
    //stripePlanId = stripePlanId.slice(0, -3);

    //Build Striple Plan Identifier!
    stripePlanId = route.controller.get('selectedPlan').get('type');

    if (stripePlanId == 'Designer') {
      stripePlanId = 'NewDesigner';
    }

    var period = route.controller.get('monthlySelected') ? 'Monthly' : 'Yearly';
    stripePlanId = stripePlanId + period;
    stripePlanId = stripePlanId + route.controller.get('currency');
    if (!stripeCustomerId) {
      stripeCustomerId = route.controller.get('stripeCustomerId');
      if (!stripeCustomerId) {
        stripeCustomerId = Ember['default'].user.get('stripeCustomerId');
      }
    }
    return utils['default'].putAPIJSON("paywithstripe/switch_plans/customer/" + stripeCustomerId + "/plan/" + stripePlanId);
  }

  function startCreateNewCard(route) {
    var _t = route;
    var controller = _t.controller;
    restartNewCard(_t);
    controller.set('creatingNewCard', true);
  }

  function cancelCreateNewCard(route) {
    var _t = route;
    var controller = _t.controller;
    restartNewCard(_t);
    controller.set('creatingNewCard', false);
  }

  function createNewCardAndPay(route) {
    var controller = route.controller;
    var card = controller.get('userCard');
    var button1 = Ember['default'].$('#save-card-button');
    var button2 = Ember['default'].$('#complete-purchase-button');
    button1.button('loading');
    button2.button('loading');
    if (!checkFailNewCard(route)) {
      var msg = card ? 'Replacing' : 'Creating';
      msg = msg + ' card and doing the purchase...';
      route.send('openMessageModal', msg);

      //CREATE NEW ONE
      apiCreateNewCardAndPay(route).then(function (success) {
        var user = Ember['default'].user;
        route.get('session').set('set_end_of_trial', "");
        //user.set('statusRegister', 4);
        user.reload().then(function (u) {
          route.send('openTopBar', 'Purchase completed! Congratulations!');
          route.get('session').set('remaining_days', 0);
          localStorage.setItem('trialEndDate', null);
          localStorage.setItem('managerSubscriptionExpired', false);
          localStorage.setItem('firstPaymentDone', true);

          route.send('closeMessageModal');
          route.transitionTo('settings.account-settings');
        });
      }, function (fail) {
        route.send('closeMessageModal');
        Ember['default'].$('#card-error-modal').modal();
        Ember['default'].openModal();
        button1.button('reset');
        button2.button('reset');
      });
    } else {
      button1.button('reset');
      button2.button('reset');
    }
  }

  function createNewCard(route) {
    var controller = route.controller;
    var card = controller.get('userCard');
    var button = Ember['default'].$('#save-card-button');
    button.button('loading');
    if (!checkFailNewCard(route)) {
      var msg = card ? 'Replacing card...' : 'Creating card...';
      route.send('openMessageModal', msg);
      //CREATE NEW ONE
      apiCreateNewCard(route).then(function (success) {
        //console.log(success);

        var stripeCustomerId = controller.get('stripeCustomerId') || Ember['default'].user.get('stripeCustomerId');
        getCustomerCards(stripeCustomerId).then(function (data) {
          var response = data.stripe_data;
          var cards = response.cards;
          if (cards.get('length') > 0) {
            if (_card) {
              //route.send('openTopBarWithTime', 'Card replaced.', 3000);
              route.send('openPositiveTopBar');
            } else {
              //route.send('openTopBarWithTime', 'Card created.', 3000);
              route.send('openPositiveTopBar');
            }
            var _card = cards.get(0);
            controller.set('userCard', _card);
            controller.set('creatingNewCard', false);
            restartNewCard(route);
            route.send('closeMessageModal');
            button.button('reset');
          } else {
            controller.set('userCard', null);
            route.send('closeMessageModal');
            button.button('reset');
          }
        }, function (fail) {
          controller.set('userCard', null);route.send('closeMessageModal');button.button('reset');
        });
      }, function (fail) {
        route.send('closeMessageModal');
        Ember['default'].$('#card-error-modal').modal();
        Ember['default'].openModal();
        button.button('reset');
      });
    } else {
      button.button('reset');
    }
  }

  function changeCardOnPaymentFailed(route) {
    var controller = route.controller;
    var card = controller.get('userCard');
    var button = Ember['default'].$('#save-card-button');
    button.button('loading');
    if (!checkFailNewCard(route)) {
      var msg = card ? 'Replacing card...' : 'Creating card...';
      route.send('openMessageModal', msg);
      apiCreateNewCard(route).then(function (success) {
        route.send('openTopBar', 'Payment updated. Loading user...');
        route.store.findRecord('user', Ember['default'].userId).then(function (res) {
          //button.button('reset');
          route.send('closeMessageModal');
          Ember['default'].user = res;
          route.controller.set('user', res);
          route.transitionTo('settings.account-settings');
        });
      }, function (fail) {
        route.send('closeMessageModal');
        Ember['default'].$('#card-error-modal').modal();
        Ember['default'].openModal();
        button.button('reset');
      });
    } else {
      button.button('reset');
    }
  }

  function apiCreateNewCardAndPay(route) {
    var controller = route.controller;
    var newCard = controller.get('newCard');

    var stripeCustomerId = route.controller.get('stripeCustomerId');
    if (!stripeCustomerId) {
      stripeCustomerId = Ember['default'].user.get('stripeCustomerId');
    }

    //Build Striple Plan Identifier!
    var stripePlanId = route.controller.get('selectedPlan').get('type');
    if (stripePlanId == 'Designer') {
      stripePlanId = 'NewDesigner';
    }
    var period = route.controller.get('monthlySelected') ? 'Monthly' : 'Yearly';
    stripePlanId = stripePlanId + period;
    stripePlanId = stripePlanId + route.controller.get('currency');

    var data = {
      stripe_data: {
        customerId: stripeCustomerId,
        numberCard: newCard.number,
        name: newCard.name,
        expMonth: newCard.expireMonth,
        expYear: newCard.expireYear,
        cvc: newCard.cvc,
        planId: stripePlanId
      }
    };

    return utils['default'].postAPIJSON("paywithstripe/create_card_and_purchase_plan", data);
  }

  function apiCreateNewCard(route) {
    var controller = route.controller;
    var newCard = controller.get('newCard');
    var stripeCustomerId = Ember['default'].user.get('stripeCustomerId') || controller.get('stripeCustomerId');
    var data = {
      stripe_data: {
        customerId: stripeCustomerId,
        numberCard: newCard.number,
        name: newCard.name,
        expMonth: newCard.expireMonth,
        expYear: newCard.expireYear,
        cvc: newCard.cvc
      }
    };
    return utils['default'].postAPIJSON("paywithstripe/create_card_for_customer", data);
  }

  function apiDeleteCard(route) {
    var controller = route.controller;
    var userCard = controller.get('userCard');
    var stripeCustomerId = Ember['default'].user.get('stripeCustomerId') || controller.get('stripeCustomerId');
    var card = {
      stripe_data: {
        customerId: stripeCustomerId,
        cardId: userCard.id
      }
    };
    return utils['default'].deleteAPIJSON("paywithstripe/delete_card_from_customer", card);
  }

  function restartNewCard(route) {
    var _t = route;
    var controller = _t.controller;
    var newCard = {
      'name': null,
      'number': null,
      'expireYear': 0,
      'expireMonth': 0,
      'cvc': null,
      'errors': {
        'name': null,
        'number': null,
        'expireYear': null,
        'expireMonth': null,
        'cvc': null
      }
    };
    controller.set('newCard', newCard);
  }

  function checkFailCardName(route) {
    var controller = route.controller;
    var name = controller.get('newCard.name');
    if (!Ember['default'].isNone(name) && !Ember['default'].isBlank(name)) {
      controller.set('newCard.errors.name', null);
      return null;
    } else {
      controller.set('newCard.errors.name', true);
      return true;
    }
  }

  function checkFailCardNumber(route) {
    var controller = route.controller;
    var number = controller.get('newCard.number');
    if (!Ember['default'].isNone(number) && !Ember['default'].isBlank(number)) {
      controller.set('newCard.errors.number', null);
      return null;
    } else {
      controller.set('newCard.errors.number', true);
      return true;
    }
  }

  function checkFailCardCvc(route) {
    var controller = route.controller;
    var cvc = controller.get('newCard.cvc');
    if (!Ember['default'].isNone(cvc) && !Ember['default'].isBlank(cvc) && (cvc.length == 3 || cvc.length == 4) && !isNaN(cvc)) {
      controller.set('newCard.errors.cvc', null);
      return null;
    } else {
      controller.set('newCard.errors.cvc', true);
      return true;
    }
  }

  function checkFailCardDate(route) {
    var controller = route.controller;
    var expireMonth = controller.get('newCard.expireMonth');
    var expireYear = controller.get('newCard.expireYear');
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();

    if (expireMonth == 0) {
      controller.set('newCard.errors.expireMonth', true);
    }
    if (expireYear == 0) {
      controller.set('newCard.errors.expireYear', true);
    }
    if (expireMonth == 0 || expireYear == 0) {
      return true;
    }

    //console.log(expireMonth, expireYear, currentMonth, currentYear);
    if (expireYear < currentYear) {
      controller.set('newCard.errors.expireMonth', null);
      controller.set('newCard.errors.expireYear', true);
      return true;
    } else if (expireYear == currentYear && expireMonth < currentMonth) {
      controller.set('newCard.errors.expireMonth', true);
      controller.set('newCard.errors.expireYear', null);
      return true;
    } else {
      controller.set('newCard.errors.expireMonth', null);
      controller.set('newCard.errors.expireYear', null);
      return null;
    }
  }

  function checkFailNewCard(route) {
    var controller = route.controller;
    var nameFails = checkFailCardName(route);
    var numberFails = checkFailCardNumber(route);
    var dateFails = checkFailCardDate(route);
    //let dateFails = false;
    var cvcFails = checkFailCardCvc(route);
    if (nameFails || numberFails || dateFails || cvcFails) {
      return true;
    } else {
      return false;
    }
  }

  function completePurchase(route) {
    var controller = route.controller;
    var button = Ember['default'].$('#save-card-button');
    var button2 = Ember['default'].$('#complete-purchase-button');
    button.button('loading');
    button2.button('loading');
    route.send('openMessageModal', 'Completing purchase...');
    switchPlan(route).then(function (res) {
      var user = Ember['default'].user;
      route.get('session').set('set_end_of_trial', "");
      //user.set('statusRegister', 4);
      user.reload().then(function (u) {
        route.send('openTopBar', 'Purchase completed! Congratulations!');
        route.get('session').set('remaining_days', 0);
        localStorage.setItem('trialEndDate', null);
        localStorage.setItem('managerSubscriptionExpired', false);
        localStorage.setItem('firstPaymentDone', true);

        route.send('closeMessageModal');
        //button.button('reset');
        //button2.button('reset');
        route.transitionTo('settings.account-settings');
      });
    }, function (fail) {
      route.send('openTopBar', 'There was an error with your card. Please, modify the card data and try again.');
      route.send('closeMessageModal');
      button.button('reset');
      button2.button('reset');
    });

    /*if (controller.get('creatingNewCard')){
      if (!checkFailNewCard(route)){
        switchPlan(route).then(res=>{console.log(res);});
      }
    } else {
      if (controller.get('userCard')){
        switchPlan(route).then(res=>{console.log(res);});
      } else {
        if (!checkFailNewCard(route)){
          switchPlan(route).then(res=>{console.log(res);});
        }
      }
    }*/
  }

  function getInvoices(route) {
    var stripeCustomerId = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    if (!stripeCustomerId) {
      stripeCustomerId = route.controller.get('stripeCustomerId');
      if (!stripeCustomerId) {
        stripeCustomerId = Ember['default'].user.get('stripeCustomerId');
      }
    }
    return utils['default'].getAPIJSON('paywithstripe/get_invoices_customer/' + stripeCustomerId).then(function (data) {
      var invoices = data.stripe_data.invoices;
      for (var i = 0; i < invoices.length; i++) {
        var date = invoices[i].date;
        var t = new Date(date * 1000);
        var day = ('0' + t.getDate()).slice(-2);
        var month = ('0' + (t.getMonth() + 1)).slice(-2);
        invoices[i]['date'] = [day, month, t.getFullYear()].join('.');
        var amount = invoices[i].total;
        if (!Ember['default'].isNone(amount)) {
          if (typeof amount === "number") {
            amount = amount / 100;
            amount = amount.toString();
          } else if (typeof amount === "string") {
            amount = amount.slice(0, -2);
          }
          amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        invoices[i]['total'] = amount;
      }
      return invoices;
    });
  }

  function setMonthsYearsArrays(route) {
    var controller = route.controller;

    //MONTHS AND YEARS FROM KARTIK
    controller.set('monthsArray', []);
    controller.get('monthsArray').addObjects([{ 'id': "0", 'type': '-' }, { 'id': "1", 'type': '01' }, { 'id': "2", 'type': '02' }, { 'id': "3", 'type': '03' }, { 'id': "4", 'type': '04' }, { 'id': "5", 'type': '05' }, { 'id': "6", 'type': '06' }, { 'id': "7", 'type': '07' }, { 'id': "8", 'type': '08' }, { 'id': "9", 'type': '09' }, { 'id': "10", 'type': '10' }, { 'id': "11", 'type': '11' }, { 'id': "12", 'type': '12' }]);

    controller.set('yearsArray', [{ id: 0, text: '-' }, { id: 2018, text: '2018' }, { id: 2019, text: '2019' }, { id: 2020, text: '2020' }, { id: 2021, text: '2021' }, { id: 2022, text: '2022' }, { id: 2023, text: '2023' }, { id: 2024, text: '2024' }, { id: 2025, text: '2025' }, { id: 2026, text: '2026' }, { id: 2027, text: '2027' }, { id: 2028, text: '2028' }, { id: 2029, text: '2029' }, { id: 2030, text: '2030' }, { id: 2031, text: '2031' }, { id: 2032, text: '2032' }, { id: 2033, text: '2033' }, { id: 2034, text: '2034' }, { id: 2035, text: '2035' }, { id: 2036, text: '2036' }, { id: 2036, text: '2037' }, { id: 2036, text: '2038' }, { id: 2036, text: '2039' }, { id: 2036, text: '2040' }, { id: 2036, text: '2041' }, { id: 2036, text: '2042' }, { id: 2036, text: '2043' }, { id: 2036, text: '2044' }, { id: 2036, text: '2045' }, { id: 2036, text: '2046' }, { id: 2036, text: '2047' }, { id: 2036, text: '2048' }, { id: 2036, text: '2049' }, { id: 2036, text: '2051' }, { id: 2036, text: '2052' }, { id: 2036, text: '2053' }, { id: 2036, text: '2054' }, { id: 2036, text: '2055' }, { id: 2036, text: '2056' }, { id: 2036, text: '2057' }, { id: 2036, text: '2058' }, { id: 2036, text: '2059' }, { id: 2036, text: '2060' }]);
  }

});