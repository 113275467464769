define('bookalook/components/contact-address-selector', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
    },

    selectedContactObserver: (function () {
      var _this = this;

      var $el = Ember['default'].$('#contact-address-selector .modal-body');

      $el.fadeOut(function () {
        _this.set('selectedContact', _this.get('selectedContactPrevious'));
        $el.fadeIn();
      });
    }).observes('selectedContactPrevious'),

    actions: {
      selectContactAddress: function selectContactAddress(address) {
        var _this2 = this;

        if (this.get('isDDT')) {
          if (!Ember['default'].isNone(address)) {
            this.get('parcel').setProperties({
              billingAddress: address.getBillingAddress(this.get('selectedContact.fullName'), this.get('selectedContact.vat')),
              orderAddress: address.getOrderAddress(this.get('selectedContact.fullName'), this.get('selectedContact.company'))
            });
          }
        } else {
          this.get('parcel').setProperties({
            attnIn: this.get('selectedContact.fullName'),
            requester: this.get('selectedContact.fullName')
          });

          if (!Ember['default'].isNone(address)) {
            this.get('parcel').setProperties({
              street1In: address.get('street1'),
              street2In: address.get('street2'),
              street3In: address.get('street3'),
              countryIn: address.get('country'),
              cityIn: address.get('city'),
              stateIn: address.get('region'),
              zipCodeIn: address.get('zipCode'),
              telephone1In: address.get('telephone1')
            });
          }
        }

        Ember['default'].run.later(function () {
          return _this2.set('selectedContactPrevious', null);
        }, 1000);
      }
    }
  });

});