define('bookalook/routes/admin/agencies', ['exports', 'ember', 'bookalook/utils', 'bookalook/routes/admin/functions'], function (exports, Ember, utils, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    actions: {
      toggleSearchable: function toggleSearchable(agency) {
        var check = confirm("Are You Sure?");
        if (check == true) {
          Ember['default'].set(agency, 'searchable', !agency.searchable);
          utils['default'].putAPIJSON('agencies/' + agency.id + '/toggleSearchable');
        }
      }
    },

    model: function model() {
      return utils['default'].getAPIJSON('admin/agencies');
    },

    setupController: function setupController(controller, model) {
      model.forEach(function (agency) {
        //agency.usersNumber = functions.getShowroomsAttrSum(agency.showrooms, 'users');
        //agency.samplesNumber = functions.getShowroomsAttrSum(agency.showrooms, 'samples');
        //agency.brandsNumber = functions.getShowroomsAttrSum(agency.showrooms, 'brands');

        /*var sumBookingNumber = 0;
         agency.showrooms.forEach(showroom => {
            showroom.bookingNumber = functions.getSamplesAttrSum(showroom.samples, 'bookings');
            sumBookingNumber += showroom.bookingNumber;
        });
         agency.bookingNumber = sumBookingNumber;*/
      });

      controller.setProperties({
        agencies: model,
        isAgenciesPage: true
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-admin');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-admin');
    }
  });

});