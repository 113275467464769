define('bookalook/helpers/clean-searchfilter', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var route = params[0];
    var isFirstSearch = route.controller.get('isFirstSearch');
    if (isFirstSearch) {
      route.controller.set('searchFilters', []); // resetea filtros de búsqueda. Si usáis filtros de búsqueda en cualquier otra parte, usad este nombre de controller.
      route.controller.set('isFirstSearch', false);
    }
  });

});