define('bookalook/models/brand', ['exports', 'ember-data', 'ember', 'bookalook/mixins/model-validator', 'bookalook/config/environment'], function (exports, DS, Ember, Validator, config) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    avatar: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_COMPANY_IMAGE }),

    website: DS['default'].attr('string'),
    twitter: DS['default'].attr('string'),
    facebook: DS['default'].attr('string'),
    instagram: DS['default'].attr('string'),
    pinterest: DS['default'].attr('string'),

    searchable: DS['default'].attr('boolean'),

    restOfTheWorld: DS['default'].attr('array', { defaultValue: [] }),

    HQ: DS['default'].belongsTo('showroom', { async: true, inverse: 'brandsHQ' }),
    showrooms: DS['default'].hasMany('showroom', { async: true, inverse: 'brands' }),

    isAvatarDefault: (function () {
      var avatar = this.get('avatar');
      if (Ember['default'].isNone(avatar)) return true;
      if (avatar.indexOf('default') !== -1) {
        return true;
      } else {
        return false;
      }
    }).property('avatar'),

    validations: {
      'name': {
        presence: true,
        length: { minimum: 2 }
      },

      'website': {
        presence: true,
        length: { minimum: 3 }
      },

      'instagram': {
        custom: [{
          validation: function validation(key, value, model) {
            //If agency => instagram is not mandatory
            var hq = model.get('HQ');
            var ig = model.get('instagram');
            if (!Ember['default'].isNone(hq)) {
              if (hq.get('isAgency')) {
                return true;
              } else {
                if (Ember['default'].isBlank(ig)) {
                  return false;
                } else {
                  return ig.length >= 3;
                }
              }
            } else {
              return true;
            }
          },
          message: "You must set an instagram account."
        }]
      },

      'avatar': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('isAvatarDefault');
          },
          message: 'You must set an avatar.'
        }]
      }

    }
  });

});