define('bookalook/helpers/user-is-brand', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var user = Ember['default'].user;
    var isBrand = false;
    if (user) {
      isBrand = user.get('isBrand');
    }
    return isBrand;
  });

});