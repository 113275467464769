define('bookalook/components/validated-radio-input', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // Attributes
    label: '',
    model: null,
    property: '',

    init: function init() {
      this._super.apply(this, arguments);

      var binding = Ember['default'].Binding.from("model." + this.get("property")).to("groupValue");
      binding.connect(this);

      binding = Ember['default'].Binding.from("model.errors." + this.get("property")).to("propertyErrorMessages");
      binding.connect(this);
    },

    checkErrors: (function () {
      var model = this.get("model");
      var modelErrors = model.get("errors");
      var property = this.get("property");

      var errors = modelErrors.errorsFor(property);
      var errorMessage = errors[0] ? errors[0].message : '';

      this.set("error", errorMessage);
    }).observes("propertyErrorMessages")
  });

});