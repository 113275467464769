define('bookalook/components/back-to-top', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    modalObserver: (function () {
      var _this = this;

      this._super.apply(this, arguments);
      var target = this.get('modal') || window;

      Ember['default'].$(target).on('scroll', function () {
        if (Ember['default'].$(target).scrollTop() > 300) {
          Ember['default'].$(_this.element).find('.back_to_top').addClass('is-active');
        } else {
          Ember['default'].$(_this.element).find('.back_to_top').removeClass('is-active');
        }
      });

      Ember['default'].$(target).on('hidden.bs.modal', function () {
        Ember['default'].$('.back_to_top').removeClass('is-active');
      });
    }).on('didInsertElement'),

    actions: {
      backToTop: function backToTop() {
        var target = this.get('modal') || window;
        if (this.get('modal')) {
          Ember['default'].$(target).animate({ scrollTop: 0 }, 'slow');
        } else {
          Ember['default'].$('body, html').animate({
            scrollTop: Ember['default'].$('body, html').offset().top
          }, 500);
        }
        return false;
      }
    }
  });

});