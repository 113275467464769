define('bookalook/models/sample', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment', 'bookalook/utils', 'ember-cli-copyable'], function (exports, Ember, DS, Validator, config, utils, Copyable) {

  'use strict';

  var jobStatusMap = {
    '-': 'REQUESTED',
    'DECLINED': 'UNAVAILABLE',
    'CANCELED': 'UNAVAILABLE',
    'PENDING': 'REQUESTED',
    'CONFIRMED': 'BOOKED',
    'IN': 'BOOKED',
    'OUT': 'SENT',
    'RETURNED': 'RETURNED',
    'ACCEPTED': 'BOOKED',
    'BOOKED': 'BOOKED'
  };

  exports['default'] = DS['default'].Model.extend(Validator['default'], Copyable['default'], {
    retailPrice: DS['default'].attr('string'),
    wholesale: DS['default'].attr('string'),
    salePrice: DS['default'].attr('string'),
    manufacturer: DS['default'].attr('string'),
    styleNumber: DS['default'].attr('string'),
    size: DS['default'].attr('string'),
    reference: DS['default'].attr('string'),
    note: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    pattern1: DS['default'].attr('string'),
    pattern2: DS['default'].attr('string'),
    pattern3: DS['default'].attr('string'),
    archived: DS['default'].attr('boolean'),
    archiveReason: DS['default'].attr('string'),
    archiveDescription: DS['default'].attr('string'),
    colour1: DS['default'].attr('string'),
    colour2: DS['default'].attr('string'),
    colour3: DS['default'].attr('string'),
    fabric1: DS['default'].attr('string'),
    fabric2: DS['default'].attr('string'),
    fabric3: DS['default'].attr('string'),
    item: DS['default'].attr('static-id'),
    groupid: DS['default'].attr('number'),

    look: DS['default'].belongsTo('look', { async: true }),
    showroom: DS['default'].belongsTo('showroom', { async: true, inverse: null }),

    sampleStatus: (function () {
      var _this = this;

      var booking = this.get('booking');
      if (booking) {
        var sampleStatus = booking.get('sampleStatus');
        sampleStatus = sampleStatus.find(function (st) {
          return st.get('sample.id') === _this.get('id');
        });
        return sampleStatus;
      }
    }).property('booking'),

    sampleStatusInventory: (function () {
      var _this2 = this;

      var booking = this.get('bookingInventory');
      if (booking) {
        var sampleStatus = booking.get('sampleStatus');
        sampleStatus = sampleStatus.find(function (st) {
          if (st.get('sample')) {
            // avoid issue when no sample is loaded associated to sampleStatus
            return st.get('sample.id') === _this2.get('id');
          } else {
            return false;
          }
        });
        return sampleStatus;
      }
    }).property('bookingInventory'),

    archiveReasonFormat: (function () {
      var archiveReason = this.get('archiveReason');
      return Ember['default'].isEmpty(archiveReason) ? 'Other' : archiveReason;
    }).property('archiveReason'),

    statusJob: (function () {
      var status;
      if (this.get('shoppingListStatus')) {
        if (parseInt(this.get('booking.id')) !== this.get('shoppingListInBooking') && this.get('shoppingListStatus') === "out") {
          status = 'BOOKED';
        } else {
          status = jobStatusMap[this.get('shoppingListStatus').toUpperCase()];
        }
      }
      return status;
    }).property('shoppingListStatus'),

    isForeignEditable: (function () {

      return this.get('showroom.id') === Ember['default'].showroom.get('id') && this.get('sampleStatus.dateAccepted');
    }).property('sampleStatus', 'showroom'),

    isProposalEditable: (function () {
      var status = this.get('shoppingListStatus') || "";

      status = status.toUpperCase();
      return status && (status.indexOf('PROPOS') > -1 || status.indexOf('CANCELED BY') > -1 || status.indexOf('DECLINED BY') > -1) && this.get('showroom.id') === Ember['default'].showroom.get('id');
    }).property('shoppingListStatus'),

    isFromLoggedUserShowroom: (function () {
      return this.get('showroom.id') === Ember['default'].showroom.get('id');
    }).property('showroom'),

    isNewSample: (function () {
      return !this.get('lastcheckout') && !this.get('lastcheckin');
    }).property('lastcheckout', 'lastcheckin'),

    _getFilteredBookingHelper: (function () {
      var id = parseInt(this.get('id'), 10);
      var bookingsList = [];

      if (Ember['default'].bookings.get('bookingsList')) {
        var bookings = Ember['default'].bookings.get('bookingsList');

        for (var b = 0; b < bookings.length; b++) {
          var booking = bookings[b];

          for (var s = 0; s < booking.samples.length; s++) {

            if (id === parseInt(booking.samples[s], 10)) {
              bookingsList.push(booking);
            }
          }
        }
      }

      return bookingsList;
    }).property('Ember.bookings.bookingsList'),

    booking: (function () {
      var _this3 = this;

      var bookingsList = this.get('_getFilteredBookingHelper');
      var filteredBooking;

      if (bookingsList.length === 1) {
        filteredBooking = bookingsList[0];
      } else {
        filteredBooking = bookingsList.find(function (b) {
          return b.sampleStatus.some(function (ss) {
            return ss.sample === parseInt(_this3.get('id'), 10) && ss.checkout && !ss.dateDeclined && !ss.checkin;
          });
        });

        if (!filteredBooking) {
          filteredBooking = bookingsList[0];
        }
      }

      if (!Ember['default'].isNone(filteredBooking)) {
        return utils['default'].peekBooking(this, filteredBooking);
      }
    }).property('_getFilteredBookingHelper'),

    /*parcel: function() {
      let allParcelsExceptMain = this.get('booking.allParcelsExceptMain');
      let sampleParcel = null;
       sampleParcel = allParcelsExceptMain.find(parcel => {
        return parcel.get('samples').find(sample => sample === this);
      });
      return sampleParcel;
    }.property('booking'),*/

    setMainProperties: function setMainProperties(mainSample) {
      this.setProperties(mainSample.getProperties('retailPrice', 'wholesale', 'salePrice', 'manufacturer', 'styleNumber', 'note', 'description', 'pattern1', 'pattern2', 'pattern3', 'colour1', 'colour2', 'colour3', 'fabric1', 'fabric2', 'fabric3', 'item'));
    },

    setVisibleIfForeignCanPropose: function setVisibleIfForeignCanPropose(foreignShowroom) {
      var sampleBrandId = this.get('collection.brand.id');
      var foreignShowroomBrandIds = foreignShowroom.get('brands').mapBy('id');

      this.set('visible', this.get('isFromLoggedUserShowroom') && foreignShowroomBrandIds.contains(sampleBrandId));
    },

    checkId: (function () {
      return 'sample-check-' + Math.random();
    }).property('id').volatile(),

    album: (function () {
      return this.get('look.album');
    }).property('look'),

    collection: (function () {
      var r = [];
      if (!Ember['default'].isNone(this.get('album'))) {
        r = this.get('album').get('collection');
      }
      return r;
    }).property('album'),

    collectionTitle: function collectionTitle() {
      return this.get('collection').get('title');
    },

    searchString: (function () {
      var _this4 = this;

      var attrs = ['itemLabel', 'description', 'styleNumber', 'booking.company', 'colour1Label', 'colour2Label', 'colour3Label', 'fabric1Label', 'fabric2Label', 'fabric3Label', 'showroom.city', 'bookingCompany', 'reference', 'id', 'barcode', 'barcodeHyphens', 'item.name', 'ddts'];
      return attrs.map(function (a) {
        return _this4.get(a);
      }).join(' ');
    }).property('itemLabel', 'description', 'styleNumber', 'booking.company', 'colour1Label', 'colour2Label', 'colour3Label', 'fabric1Label', 'fabric2Label', 'fabric3Label', 'showroom.city', 'bookingCompany', 'reference', 'id', 'barcode', 'barcodeHyphens', 'ddts', 'item.name'),

    colourSearchString: (function () {
      var _this5 = this;

      var attrs = ['colour1Label', 'colour2Label', 'colour3Label'];
      return attrs.map(function (a) {
        return _this5.get(a);
      }).join('');
    }).property('colour1Label', 'colour2Label', 'colour3Label'),

    sizeSearchString: (function () {
      var _this6 = this;

      var attrs = ['size'];
      return attrs.map(function (a) {
        return _this6.get(a);
      }).join('');
    }).property('size'),

    itemObject: (function () {
      return Ember['default'].items[this.get('item') - 1];
    }).property("item"),

    itemLabel: (function () {
      return Ember['default'].itemsLabelsDict[this.get('item')];
    }).property("item"),
    colour1Label: (function () {
      return this.get('colour1');
    }).property("colour1"),
    colour2Label: (function () {
      return this.get('colour2');
    }).property("colour2"),
    colour3Label: (function () {
      return this.get('colour3');
    }).property("colour3"),
    fabric1Label: (function () {
      return this.get('fabric1');
    }).property("fabric1"),
    fabric2Label: (function () {
      return this.get('fabric2');
    }).property("fabric2"),
    fabric3Label: (function () {
      return this.get('fabric3');
    }).property("fabric3"),
    colour1URL: (function () {
      if (this.get('colour1')) {
        return config['default'].IMAGEPATH + 'colours/' + this.get('colour1').replace(/ /g, '').toLowerCase() + '.png';
      }
    }).property("colour1"),
    colour2URL: (function () {
      if (this.get('colour2')) {
        return config['default'].IMAGEPATH + 'colours/' + this.get('colour2').replace(/ /g, '').toLowerCase() + '.png';
      }
    }).property("colour2"),
    colour3URL: (function () {
      if (this.get('colour3')) {
        return config['default'].IMAGEPATH + 'colours/' + this.get('colour3').replace(/ /g, '').toLowerCase() + '.png';
      }
    }).property("colour3"),

    checkedObserver: (function () {
      this.set('oldStatus', !this.get('checked'));
      Ember['default'].bulk.allSamples.addObject(this);
      if (this.get('checked')) {
        Ember['default'].bulk.samples.addObject(this);
      } else {
        Ember['default'].bulk.samples.removeObject(this);
      }
    }).observes('checked'),

    isConfirmed: (function () {
      var sampleStatus = this.get('sampleStatus');
      return sampleStatus.get('dateConfirmed');
    }).property('sampleStatus'),

    barcode: (function () {
      if (this.get('id') && this.get('look')) {
        var barcode = '';
        var brandId = this.get('look.album.collection.brand.id');
        if (!Ember['default'].isNone(brandId)) {
          var sampleId = this.get('id');
          //Fill with 0's (brandId = 4 digits / sampleID = 6 digits)
          var strBrandId = "" + brandId;
          var padBrand = "0000";
          strBrandId = padBrand.substring(0, padBrand.length - strBrandId.length) + strBrandId;
          var strSampleId = "" + sampleId;
          var padSample = "000000";
          strSampleId = padSample.substring(0, padSample.length - strSampleId.length) + strSampleId;
          barcode = strBrandId + '-' + strSampleId;
        } else {
          barcode = '';
        }
        return barcode;
      } else {
        return '';
      }
    }).property(),

    barcodeHyphens: (function () {
      if (this.get('look')) {
        var brandId = this.get('look').get('album').get('collection').get('brand').get('id');
        var sampleId = this.get('id');
        //Fill with 0's (brandId = 4 digits / sampleID = 6 digits)
        var strBrandId = "" + brandId;
        var padBrand = "0000";
        strBrandId = padBrand.substring(0, padBrand.length - strBrandId.length) + strBrandId;
        var strSampleId = "" + sampleId;
        var padSample = "000000";
        strSampleId = padSample.substring(0, padSample.length - strSampleId.length) + strSampleId;
        var barcode = strBrandId + "'" + strSampleId;

        return barcode;
      }
    }).property(),

    isAvailableForLoan: (function () {
      var st = this.get('shoppingListStatus');
      if (Ember['default'].isNone(st)) {
        return false;
      } else {
        st = st.toLowerCase();
        return !(st.indexOf('propos') != -1 || st.indexOf('canceled') != -1 || st.indexOf('declined') != -1);
      }
    }).property('shoppingListStatus'),

    checkIsNewAndBlank: (function () {
      if (!this.get('isNew')) {
        return false;
      } else {
        if (this.get('isMain')) {
          return Ember['default'].isBlank(this.get('colour1')) && Ember['default'].isBlank(this.get('colour2')) && Ember['default'].isBlank(this.get('colour3')) && Ember['default'].isBlank(this.get('fabric1')) && Ember['default'].isBlank(this.get('fabric2')) && Ember['default'].isBlank(this.get('fabric3')) && Ember['default'].isBlank(this.get('pattern1')) && Ember['default'].isBlank(this.get('pattern2')) && Ember['default'].isBlank(this.get('pattern3')) && Ember['default'].isBlank(this.get('manufacturer')) && Ember['default'].isBlank(this.get('retailPrice')) && Ember['default'].isBlank(this.get('wholesale')) && Ember['default'].isBlank(this.get('styleNumber')) && Ember['default'].isBlank(this.get('reference')) && Ember['default'].isBlank(this.get('size')) && Ember['default'].isBlank(this.get('salePrice')) && Ember['default'].isBlank(this.get('description')) && Ember['default'].isBlank(this.get('note'));
        } else {
          return Ember['default'].isBlank(this.get('reference')) && Ember['default'].isBlank(this.get('size'));
        }
      }
    }).property(),

    validations: {
      'item': {
        presence: true,
        exclusion: { "in": ['---'], message: "Can't be blank" }
      },

      'colour1': {
        presence: true,
        exclusion: { "in": ['---'], message: "Can't be blank" }
      },

      'fabric1': {
        presence: true,
        exclusion: { "in": ['---'], message: "Can't be blank" }
      }
    }
  });

});