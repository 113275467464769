define('bookalook/components/validated-input', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // Attributes
    label: '',
    model: null,
    property: '',

    init: function init() {
      this._super.apply(this, arguments);

      var binding = Ember['default'].Binding.from("model." + this.get("property")).to("value");
      binding.connect(this);

      binding = Ember['default'].Binding.from("model.errors." + this.get("property")).to("propertyErrorMessages");
      binding.connect(this);
    },

    focusOut: function focusOut() {
      if (!this.get('disableCheckOnFocus')) {
        var model = this.get("model");
        if (model) {
          var modelErrors = model.get("errors");
          var property = this.get("property");

          modelErrors.remove(property);
          model.validate({ only: [property] });
        }
      }
    },

    checkErrors: (function () {
      var model = this.get("model");
      if (model) {
        var modelErrors = model.get("errors");
        var property = this.get("property");

        var errors = modelErrors.errorsFor(property);
        var errorMessage = errors[0] ? errors[0].message : '';

        this.set("error", errorMessage);
      }
    }).observes("propertyErrorMessages")
  });

});