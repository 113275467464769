define('bookalook/tests/routes/barcodes/scan-booking.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/barcodes/scan-booking.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/barcodes/scan-booking.js should pass jshint.\nroutes/barcodes/scan-booking.js: line 10, col 95, Missing semicolon.\nroutes/barcodes/scan-booking.js: line 11, col 78, Missing semicolon.\n\n2 errors');
  });

});