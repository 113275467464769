define('bookalook/tests/routes/bookings/booking-details.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/bookings/booking-details.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/bookings/booking-details.js should pass jshint.\nroutes/bookings/booking-details.js: line 70, col 93, Missing semicolon.\nroutes/bookings/booking-details.js: line 101, col 74, Missing semicolon.\nroutes/bookings/booking-details.js: line 103, col 91, Missing semicolon.\nroutes/bookings/booking-details.js: line 104, col 119, Missing semicolon.\nroutes/bookings/booking-details.js: line 105, col 125, Missing semicolon.\nroutes/bookings/booking-details.js: line 106, col 99, Missing semicolon.\nroutes/bookings/booking-details.js: line 117, col 87, Missing semicolon.\nroutes/bookings/booking-details.js: line 118, col 87, Missing semicolon.\nroutes/bookings/booking-details.js: line 119, col 64, Missing semicolon.\nroutes/bookings/booking-details.js: line 120, col 106, Missing semicolon.\nroutes/bookings/booking-details.js: line 263, col 33, Confusing use of \'!\'.\nroutes/bookings/booking-details.js: line 314, col 105, Missing semicolon.\nroutes/bookings/booking-details.js: line 318, col 125, Missing semicolon.\nroutes/bookings/booking-details.js: line 593, col 9, \'requester\' used out of scope.\nroutes/bookings/booking-details.js: line 594, col 38, \'requester\' used out of scope.\nroutes/bookings/booking-details.js: line 606, col 80, Missing semicolon.\nroutes/bookings/booking-details.js: line 625, col 38, [\'all\'] is better written in dot notation.\nroutes/bookings/booking-details.js: line 2057, col 28, Missing semicolon.\nroutes/bookings/booking-details.js: line 2069, col 9, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\n\n19 errors');
  });

});