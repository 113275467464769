define('bookalook/components/mini-calendar', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    /*  - Attributes -
     *   · sample (form application.hbs)
     *   · modal (en el caso de que se abra en un modal tenemos que tener una referencia del mismo)
     */
    route: null,

    calendar: [[]],
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    bookings: [],
    bookingId: null,
    logs: null,
    months: [{ id: 1, name: 'January' }, { id: 2, name: 'February' }, { id: 3, name: 'March' }, { id: 4, name: 'April' }, { id: 5, name: 'May' }, { id: 6, name: 'June' }, { id: 7, name: 'July' }, { id: 8, name: 'August' }, { id: 9, name: 'September' }, { id: 10, name: 'October' }, { id: 11, name: 'November' }, { id: 12, name: 'December' }],
    selectedBooking: null,

    //<ACTIONS>
    actions: {
      nextMonth: function nextMonth() {
        this.onNextMonth();
      },
      previousMonth: function previousMonth() {
        this.onPreviousMonth();
      },
      closeCalendar: function closeCalendar() {
        this.onCloseCalendar();
      },
      closeModalBackdropFade: function closeModalBackdropFade() {
        $('.modal-backdrop.fade').hide();
      },
      chooseBooking: function chooseBooking(booking) {
        this.set('selectedBooking', booking);
      },
      goToBookingWithDDT: function goToBookingWithDDT(bId, ddt) {
        this.get('route').send('goToBookingWithDDT', bId, ddt);
      }

    },
    //</ACTIONS>

    //<OBSERVERS>
    sampleObserver: (function () {
      var _this2 = this;

      if (this.get('sample')) {

        this.getBookingsInvolvedMonths(this.get('sample')).then(function (_bookings) {
          var bookings = _this2.getBookingsBySample(_bookings, _this2.get('sample'));
          _this2.set('bookings', bookings);
          if (!Ember['default'].isEmpty(_this2.get('bookings'))) {
            _this2.set('selectedBooking', _this2.get('bookings')[0]);
            if (_this2.get('bookingId')) {
              //Highlight booking selected!
              _this2.get('bookings').forEach(function (bookng) {
                if (bookng.id == _this2.get('bookingId')) {
                  _this2.set('selectedBooking', bookng);
                }
              });

              //If none selected, select first booking!
              if (!_this2.get('selectedBooking')) {
                _this2.set('selectedBooking', _this2.get('bookings')[0]);
              }
            } else {
              //Highlight first booking!
              _this2.set('selectedBooking', _this2.get('bookings')[0]);
            }
          } else {
            _this2.set('selectedBooking', null);
          }
          _this2.renderCalendar();
        });
      }
    }).observes('sample', 'random'),

    //<OBSERVERS>
    dateObserver: (function () {
      if (this.get('sample')) {
        this.renderCalendar();
      }
    }).observes('month', 'year', 'selectedBooking'),

    selectedBookingObserver: (function () {
      var b = this.get('selectedBooking');
      var date = null;
      if (b != null) {
        // b.sampleStatus.forEach(singleStatus => {
        //   if(singleStatus.checkout) {
        //     date = singleStatus.checkout;
        //   }
        // });
        date = b.statusDate;

        if (date != null) {
          var objDate = new Date(date);

          this.set('month', objDate.getMonth() + 1);
          this.set('year', objDate.getFullYear());
        }
      }
    }).observes('selectedBooking'),

    bookingsObserver: (function () {
      var bookings = this.get('bookings');
      for (var i = 0; i < bookings.length; i++) {
        //bookings[i].calendarColor = this.rainbow(bookings.length, i);
        var color = this.rainbowMelissa(i);
        bookings[i].calendarColor = color[0];
        bookings[i].calendarFont = color[1];
      }
    }).observes('bookings'),

    //</OBSERVERS>
    //<FUNCTIONS>

    daysInMonth: function daysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },

    createArray: function createArray(length) {
      var arr = new Array(length || 0),
          i = length;
      if (arguments.length > 1) {
        var args = Array.prototype.slice.call(arguments, 1);
        while (i--) {
          arr[length - 1 - i] = this.createArray.apply(this, args);
        }
      }
      return arr;
    },

    renderCalendar: function renderCalendar() {
      var calendar = this.createArray(6, 5);
      var selectedMonth = this.get('month'); //we need it as base 1 for daysInMonth function ;D
      var selectedYear = this.year;
      var daysInMainMonth = this.daysInMonth(selectedYear, selectedMonth);

      var firstWeekdayOfMonth = new Date(selectedYear + "-" + this._minTwoDigits(selectedMonth) + "-01").getDay();
      firstWeekdayOfMonth = firstWeekdayOfMonth === 0 ? 7 : firstWeekdayOfMonth; //Day of the week as base 1 (monday -> 1, sunday -> 7)

      var lastWeekdayOfMonth = new Date(selectedYear + "-" + selectedMonth + "-" + daysInMainMonth).getDay();
      lastWeekdayOfMonth = lastWeekdayOfMonth === 0 ? 7 : lastWeekdayOfMonth; //Day of the week as base 1 (monday -> 1, sunday -> 7)

      var daysToSubstract = firstWeekdayOfMonth - 1;
      var dateFirstPosition = new Date(selectedYear, selectedMonth - 1, 1);
      dateFirstPosition.setDate(dateFirstPosition.getDate() - daysToSubstract);

      for (var i = 0; i < 42; i++) {
        var currentObj = { date: new Date(dateFirstPosition), day: dateFirstPosition.getDate(), past: false };

        calendar[Math.floor(i / 7)][i % 7] = currentObj;
        currentObj.bookings = [];
        this.addStyleToDay(currentObj, this.bookings);

        var classes = "";
        if (currentObj.selectedBooking) classes += "selectedBooking ";
        if (currentObj.disabled) classes += "disabled-day ";
        if (currentObj.checkout) classes += "check-out unavailable ";
        if (currentObj.checkin) classes += "check-in unavailable ";
        if (currentObj.past) classes += "past-day ";
        if (currentObj.unavailable) classes += "unavailable ";
        if (currentObj.today) classes += "today ";
        currentObj.classes = classes;

        dateFirstPosition.setDate(dateFirstPosition.getDate() + 1);
      }
      this.set('calendar', calendar);
      //this.createLog(this.get('bookings'));
    },

    getBookingsInvolvedMonths: function getBookingsInvolvedMonths(sample) {
      var postData = {
        from: null,
        to: null,
        sampleId: sample.id
      };
      return utils['default'].postAPIJSON('bookings/dates', postData);
    },

    getBookingsBySample: function getBookingsBySample(bookings, sample) {
      var _this3 = this;

      var sampleStatus = [];
      var addresses = [];
      var bookings = bookings.booking;

      // now that we have the involved bookings, we'll take the sampleStatus of the current
      // sample. ECMA6!
      bookings.forEach(function (booking) {
        if (booking.sampleStatus) {
          sampleStatus = booking.sampleStatus.filter(function (singleStatus) {
            if (!Ember['default'].isNone(singleStatus.sample)) {
              return parseInt(singleStatus.sample.id, 10) === parseInt(sample.get('id'), 10);
            } else {
              return false;
            }
          });
        }
        if (booking.addresses) {
          addresses = booking.addresses.filter(function (parcel) {
            return parcel.samples.some(function (sample) {
              return parseInt(sample, 10) === parseInt(_this3.sample.get('id'), 10);
            });
          });
        }
        booking.sampleStatus = sampleStatus;
        booking.addresses = addresses;
      });
      return bookings;
    },

    addStyleToDay: function addStyleToDay(dayObject, bookings) {
      var _this4 = this;

      var _this = this;
      dayObject.disabled = dayObject.date.getMonth() + 1 !== this.month;

      if (dayObject.date.valueOf() === new Date().setHours(0, 0, 0, 0).valueOf()) {
        dayObject.today = true;
      }

      dayObject.past = this.isPastDate(dayObject.date);
      bookings.forEach(function (booking) {
        if (!booking.sampleStatus.length) {
          booking.sampleStatus.push({ checkin: booking.checkin, checkout: booking.checkout, fromBooking: true });
        }
        booking.sampleStatus.forEach(function (singleStatus) {
          var ssCOut = false;
          var ssCIn = false;
          if (!singleStatus.dateDeclined) {
            if (!singleStatus.checkin) {
              //En el caso de que no tenga checkin en el sample status del booking debemos coger el checkin del booking
              singleStatus.checkin = booking.checkin;
              singleStatus.checkinFromBooking = true;
            }

            if (!singleStatus.checkout) {
              singleStatus.checkout = booking.checkout;
              singleStatus.checkoutFromBooking = true;
            }

            if (singleStatus.checkout) {

              if (utils['default'].parseDate(singleStatus.checkout).valueOf() === dayObject.date.valueOf()) {
                //dayObject.checkout = true;
                ssCOut = true;
                /*if(dayObject.unavailable) {
                  //dayObject.checkin = true;
                  ssCIn = true;
                }*/
              }
            }
            if (singleStatus.checkin) {

              if (utils['default'].parseDate(singleStatus.checkin).valueOf() === dayObject.date.valueOf()) {
                //dayObject.checkin = true;
                ssCIn = true;

                /*if(dayObject.unavailable) {
                  //dayObject.checkout = true;
                  ssCOut = true;
                }*/
              }
            }

            if (singleStatus.checkin && singleStatus.checkout) {
              //En caso de que existan ambos hay que poner rayitas entre ambos días

              if (!dayObject.past) {
                dayObject.past = _this4.isPastDate(dayObject.date);
              }
              if (_this.isLater(utils['default'].parseDate(singleStatus.checkout), dayObject.date) && _this.isPrevious(utils['default'].parseDate(singleStatus.checkin), dayObject.date)) {
                dayObject.unavailable = true;
              }
            }

            if (!singleStatus.fromBooking) {
              if ((dayObject.unavailable || ssCOut || ssCIn) && _this.isBetween(singleStatus, dayObject.date)) {
                dayObject.bookings.push(booking);
                if (booking.id == _this4.get('selectedBooking').id) {
                  dayObject.selectedBooking = true;
                  if (singleStatus.checkout) {
                    dayObject.checkout = ssCOut;
                    dayObject.checkin = ssCIn;
                  }
                }
              }
            }
          }
        });
      });
    },

    isPastDate: function isPastDate(d) {
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      if (typeof d === 'string') {
        d = utils['default'].parseDate(d);
      }

      return d.valueOf() < today.valueOf();
    },

    isLater: function isLater(sampleDate, objectDate) {
      sampleDate.setHours(0, 0, 0, 0);
      objectDate.setHours(0, 0, 0, 0);

      return objectDate.valueOf() > sampleDate.valueOf();
    },

    isPrevious: function isPrevious(sampleDate, objectDate) {
      sampleDate.setHours(0, 0, 0, 0);
      objectDate.setHours(0, 0, 0, 0);

      return objectDate.valueOf() < sampleDate.valueOf();
    },

    isBetween: function isBetween(statusDate, objectDate) {
      var checkout = utils['default'].parseDate(statusDate.checkout);
      var checkin = utils['default'].parseDate(statusDate.checkin);
      if (checkout.valueOf() === objectDate.valueOf()) {
        return true;
      }
      if (checkin.valueOf() === objectDate.valueOf()) {
        return true;
      }
      if (statusDate.checkout && this.isLater(checkout, objectDate) && statusDate.checkin && this.isPrevious(checkin, objectDate)) {
        return true;
      }
      return false;
      if (checkout.valueOf() === objectDate.valueOf() || checkin.valueOf() === objectDate.valueOf() || statusDate.checkout && this.isLater(checkout, objectDate) && statusDate.checkin && this.isPrevious(checkin, objectDate)) {
        return true;
      } else {
        return false;
      }
    },

    rainbow: function rainbow(numOfSteps, step) {
      // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
      // Adam Cole, 2011-Sept-14
      // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
      var r = undefined,
          g = undefined,
          b = undefined;
      var h = step / numOfSteps;
      var i = ~ ~(h * 6);
      var f = h * 6 - i;
      var q = 1 - f;
      switch (i % 6) {
        case 0:
          r = 1;g = f;b = 0;break;
        case 1:
          r = q;g = 1;b = 0;break;
        case 2:
          r = 0;g = 1;b = f;break;
        case 3:
          r = 0;g = q;b = 1;break;
        case 4:
          r = f;g = 0;b = 1;break;
        case 5:
          r = 1;g = 0;b = q;break;
      }
      var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
      return c;
    },

    rainbowMelissa: function rainbowMelissa(number) {
      var colors = [['#530077', '#ffffff'], ['#00498e', '#ffffff'], ['#008e4c', '#000000'], ['#c9c400', '#000000'], ['#cd6100', '#000000'], ['#c70000', '#ffffff'], ['#8e006f', '#ffffff'], ['#20207e', '#ffffff'], ['#008e8b', '#ffffff'], ['#35a132', '#000000'], ['#c98000', '#000000'], ['#c90019', '#ffffff']];
      return colors[number % 12];
    },

    //</FUNCTIONS>

    //<ACTIONS>
    onNextMonth: function onNextMonth() {
      if (this.get('month') < 12) {
        this.set('month', this.get('month') + 1);
      } else {

        this.set('year', this.get('year') + 1);
        this.set('month', 1);
      }
    },

    onPreviousMonth: function onPreviousMonth() {
      if (this.get('month') > 1) {
        this.set('month', this.get('month') - 1);
      } else {
        this.set('year', this.get('year') - 1);
        this.set('month', 12);
      }
    },

    onCloseCalendar: function onCloseCalendar() {
      var idModal = this.get('modal');

      if (idModal) {
        Ember['default'].$(idModal).modal('show');
        Ember['default'].openModal();
      }
      this.set('sample', null);
    },

    _minTwoDigits: function _minTwoDigits(n) {
      return n > 9 ? "" + n : "0" + n;
    }
    //</ACTIONS>
  });

});