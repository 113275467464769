define('bookalook/templates/components/validated-radio-input', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/components/validated-radio-input.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","radio-inline");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createElement("div");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","radio-label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [2]);
          var element2 = dom.childAt(element0, [4]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element0,1,1);
          morphs[1] = dom.createAttrMorph(element1, 'for');
          morphs[2] = dom.createAttrMorph(element2, 'for');
          morphs[3] = dom.createMorphAt(element2,0,0);
          return morphs;
        },
        statements: [
          ["inline","radio-button",[],["checked",["subexpr","@mut",[["get","groupValue",["loc",[null,[4,29],[4,39]]]]],[],[]],"value",["subexpr","@mut",[["get","label.value",["loc",[null,[4,46],[4,57]]]]],[],[]],"id",["subexpr","@mut",[["get","label.value",["loc",[null,[4,61],[4,72]]]]],[],[]]],["loc",[null,[4,6],[4,74]]]],
          ["attribute","for",["get","label.value",["loc",[null,[4,87],[4,98]]]]],
          ["attribute","for",["get","label.value",["loc",[null,[4,154],[4,165]]]]],
          ["content","label.label",["loc",[null,[4,168],[4,183]]]]
        ],
        locals: ["label"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/components/validated-radio-input.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","validated-hint");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element3, 'class');
        morphs[1] = dom.createMorphAt(element3,1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["form-group validated-radio-input ",["subexpr","if",[["get","error",["loc",[null,[1,50],[1,55]]]],"error"],[],["loc",[null,[1,45],[1,65]]]]]]],
        ["block","each",[["get","labels",["loc",[null,[2,10],[2,16]]]]],[],0,null,["loc",[null,[2,2],[6,11]]]],
        ["content","error",["loc",[null,[7,30],[7,39]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});