define('bookalook/models/look', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment'], function (exports, Ember, DS, Validator, config) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    publicDescription: DS['default'].attr('string'),
    privateDescription: DS['default'].attr('string'),
    archived: DS['default'].attr('boolean'),
    archiveReason: DS['default'].attr('string'),
    archiveDescription: DS['default'].attr('string'),
    avatar: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_IMAGE }),
    avatars: DS['default'].attr('array'),
    thumbnail: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_IMAGE }),

    department: DS['default'].attr('string'),
    position: DS['default'].attr('number', { defaultValue: 9999 }),

    album: DS['default'].belongsTo('album', { async: true }),
    samples: DS['default'].hasMany('sample', { async: true }),

    activeSamples: Ember['default'].computed.filterBy('samples', 'archived', false),
    archivedSamples: Ember['default'].computed.filterBy('samples', 'archived', true),

    allSamplesCount: (function () {
      return this.get('samples').get('length');
    }).property("samples"),

    activeSamplesCount: (function () {
      return this.get('activeSamples').get('length');
    }).property("activeSamples"),

    archivedSamplesCount: (function () {
      return this.get('archivedSamples').get('length');
    }).property("archivedSamples"),

    collection: (function () {
      return this.get('album.collection');
    }).property('album'),

    collectionSearchString: (function () {
      return this.get('album.collection.filterName');
    }).property('album.collection', 'album.collection.filterName'),

    departmentLabel: (function () {
      return this.get('department');
    }).property("department"),

    orderedSamples: (function () {
      return this.get('samples').sortBy('itemLabel');
    }).property('samples'),

    groupedSamples: (function () {
      var _this = this;

      var groups = {};
      var samples = this.get('samples');

      samples.forEach(function (sample) {
        if (sample.get('archived') && !_this.get('archived')) {
          return;
        }

        var groupid = sample.get('groupid') || sample.get('id') || Math.random();

        if (!groups[groupid]) {
          groups[groupid] = { main: [sample], duplicates: [] };
        } else {
          groups[groupid].duplicates.push(sample);
        }
      });

      var globalPosition = 1;
      var groupsArray = Ember['default'].$.map(groups, function (value) {
        var position = 1;
        value.all = [value.main[0]].concat(value.duplicates);
        value.all = value.all.map(function (val) {
          val.set('position', position);
          val.set('globalPosition', globalPosition);
          val.set('groupLength', value.all.length);
          val.set('group', value.all);
          val.set('isMain', position === 1);
          position++;
          globalPosition++;
          return val;
        });
        return [value];
      });

      return groupsArray;
    }).property('samples.@each'),

    groupedSamplesWithArchived: (function () {
      var groups = {};
      var samples = this.get('samples');

      samples.forEach(function (sample) {
        //if(sample.get('archived') && !this.get('archived')) { return; }

        var groupid = sample.get('groupid') || sample.get('id') || Math.random();

        if (!groups[groupid]) {
          groups[groupid] = { main: [sample], duplicates: [] };
        } else {
          groups[groupid].duplicates.push(sample);
        }
      });

      var globalPosition = 1;
      var groupsArray = Ember['default'].$.map(groups, function (value) {
        var position = 1;
        value.all = [value.main[0]].concat(value.duplicates);
        value.all = value.all.map(function (val) {
          val.set('position', position);
          val.set('globalPosition', globalPosition);
          val.set('groupLength', value.all.length);
          val.set('group', value.all);
          val.set('isMain', position === 1);
          position++;
          globalPosition++;
          return val;
        });
        return [value];
      });

      return groupsArray;
    }).property('samples.@each'),

    groupedSamplesAll: (function () {
      var groups = {};
      var samples = this.get('samples');

      samples.forEach(function (sample) {

        var groupid = sample.get('groupid') || sample.get('id') || Math.random();

        if (!groups[groupid]) {
          groups[groupid] = { main: [sample], duplicates: [] };
        } else {
          groups[groupid].duplicates.push(sample);
        }
      });

      var globalPosition = 1;
      var groupsArray = Ember['default'].$.map(groups, function (value) {
        var position = 1;
        value.all = [value.main[0]].concat(value.duplicates);
        value.all = value.all.map(function (val) {
          val.set('position', position);
          val.set('globalPosition', globalPosition);
          val.set('groupLength', value.all.length);
          val.set('group', value.all);
          val.set('isMain', position === 1);
          position++;
          globalPosition++;
          return val;
        });
        return [value];
      });

      return groupsArray;
    }).property('samples.@each'),

    hasCheckedSamples: (function () {
      var samples = this.get('samples');
      var r = false;
      /*  samples.forEach(function(sample){
          if(sample.get('checked')){
            r = true;
            break;
          }
        });
      */
      samples.some(function (sample, index) {

        if (sample.get('checked')) {
          r = true;
          return true; /*Break*/
        }
      });

      return r;
    }).property('samples.@each.checked'),

    allSamplesAreChecked: (function () {
      var groupedSamples = this.get('groupedSamples');
      var allChecked = true;
      for (var i = 0; allChecked && i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; allChecked && x < samples.length; x++) {
          if (!samples[x].get('checked')) {
            allChecked = false;
          }
        }
      }
      return allChecked;
    }).property('samples.@each.checked'),

    allVisibleSamplesAreChecked: (function () {
      var groupedSamples = this.get('groupedSamples');
      var allChecked = true;
      for (var i = 0; allChecked && i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; allChecked && x < samples.length; x++) {
          if (samples[x].get('visible') && !samples[x].get('checked')) {
            allChecked = false;
          }
        }
      }
      return allChecked;
    }).property('samples.@each.visible', 'samples.@each.checked'),

    allVisibleSamplesAreCheckedPast: (function () {
      var groupedSamples = this.get('groupedSamples');
      var allChecked = true;
      for (var i = 0; allChecked && i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; allChecked && x < samples.length; x++) {
          if (samples[x].addSampleStatus != "unavailable" && samples[x].get('visible') && !samples[x].get('checked')) {
            allChecked = false;
          }
        }
      }
      return allChecked;
    }).property('samples.@each.visible', 'samples.@each.checked', 'samples.@each.addSampleStatus'),

    hasVisibleAndNotUnavailableAndNotArchivedSamples: (function () {
      if (this.get('samples')) {
        return this.get('samples').find(function (s) {
          return !s.get('archived') && s.get('visible') && s.addSampleStatus != "unavailable";
        });
      }
      return false;
    }).property('samples.@each.visible', 'samples.@each.addSampleStatus'),

    allSamplesAreGoingToBeAdded: (function () {
      var groupedSamples = this.get('groupedSamples');
      var allChecked = true;
      for (var i = 0; allChecked && i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; allChecked && x < samples.length; x++) {
          if (samples[x].get('visible') && !samples[x].get('addToSelectedSamples') && samples[x].get('isAvailableForLoan')) {
            allChecked = false;
          }
        }
      }
      return allChecked;
    }).property('samples.@each.addToSelectedSamples'),

    allSamplesAreGoingToCheckout: (function () {
      var groupedSamples = this.get('groupedSamples');
      var allChecked = true;
      for (var i = 0; allChecked && i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; allChecked && x < samples.length; x++) {
          if (samples[x].get('checked')) {
            if (!samples[x].get('toCheckout')) {
              allChecked = false;
            }
          }
        }
      }
      return allChecked;
    }).property('samples.@each.toCheckout'),

    allVisibleSamplesAreGoingToCheckout: (function () {
      var groupedSamples = this.get('groupedSamples');
      var allChecked = true;
      for (var i = 0; allChecked && i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; allChecked && x < samples.length; x++) {
          if (samples[x].get('checked') && samples[x].get('visible')) {
            if (!samples[x].get('toCheckout')) {
              allChecked = false;
            }
          }
        }
      }
      return allChecked;
    }).property('samples.@each.toCheckout', 'samples.@each.visible'),

    hasVisibleSamples: (function () {
      var samples = this.get('samples');

      var r = false;
      samples.forEach(function (sample) {
        r = r || sample.get('visible');
      });
      return r;
    }).property('samples.@each.visible'),

    hasVisibleAndNotArchivedSamples: (function () {
      var samples = this.get('samples');

      var r = false;
      samples.forEach(function (sample) {
        r = r || sample.get('visible') && !sample.get('archived');
      });
      return r;
    }).property('samples.@each.visible'),

    hasVisibleAndCheckedSamples: (function () {
      return this.get('samples').some(function (sample) {
        return sample.get('checked') && sample.get('visible');
      });
    }).property('samples.@each.checked', 'samples.@each.visible'),

    hasVisibleAndNotRemovedSamples: (function () {
      var samples = this.get('samples');

      var r = false;
      samples.forEach(function (s) {
        r = r || s.get('visible') && !Ember['default'].isNone(s.get('jobStatus.status')) && s.get('jobStatus.status') !== 'REMOVED';
      });
      return r;
    }).property('samples.@each.visible', 'samples.@each.jobStatus'),

    getImage: (function () {
      var avatar = this.get('avatar');
      var thumbnail = this.get('thumbnail');
      var image = thumbnail;
      if (Ember['default'].isEmpty(thumbnail)) {
        image = avatar;
        if (Ember['default'].isEmpty(image)) {
          image = config['default'].DEFAULT_IMAGE;
        }
      }
      return image;
    }).property('avatar', 'thumbnail'),

    areActiveSamplesChecked: (function () {
      var samples = this.get('activeSamples');
      var allChecked = true;
      for (var x = 0; allChecked && x < samples.length; x++) {
        if (!samples[x].get('checked')) {
          allChecked = false;
        }
      }
      return allChecked;
    }).property('activeSamples.@each.checked', 'activeSamples', 'activeSamples.@each'),

    areArchivedSamplesChecked: (function () {
      var samples = this.get('archivedSamples');
      var allChecked = true;
      for (var x = 0; allChecked && x < samples.length; x++) {
        if (!samples[x].get('checked')) {
          allChecked = false;
        }
      }
      return allChecked;
    }).property('archivedSamples.@each.checked'),

    getAvatars: (function () {
      return this.get('avatars');
    }).property('avatars'),

    hasActiveSamples: (function () {
      var samples = this.get('samples');
      var r = false;
      samples.forEach(function (sample) {
        r = r || !sample.get('archived');
      });
      return r;
    }).property('samples.@each.archived'),

    hasArchivedSamples: (function () {
      var samples = this.get('samples');
      var r = false;
      samples.forEach(function (sample) {
        r = r || sample.get('archived');
      });
      return r;
    }).property('samples.@each.archived'),

    hasSamplesVisiblesAndAvailablesForLoan: (function () {
      var samples = this.get('samples');

      var r = false;
      samples.forEach(function (sample) {
        r = r || sample.get('visible') && sample.get('isAvailableForLoan');
      });
      return r;
    }).property('samples.@each.visible', 'samples.@each.isAvailableForLoan'),

    countSamples: (function () {
      return this.get('samples').length;
    }).property('samples'),

    isAvatarDefault: (function () {
      var avatars = this.get('avatars');
      if (!Ember['default'].isNone(avatars) && !Ember['default'].isEmpty(avatars)) {
        return false;
        //Avatars should not have default avatars, so this is not needed
        return !avatars.some(function (a) {
          return a.avatar.indexOf('default') === -1;
        });
      } else {
        return true;
      }
    }).property('avatars')
  });

});