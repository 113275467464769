define('bookalook/templates/collections/album', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 7,
              "column": 65
            }
          },
          "moduleName": "bookalook/templates/collections/album.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","album.other",["loc",[null,[7,50],[7,65]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 65
            },
            "end": {
              "line": 7,
              "column": 92
            }
          },
          "moduleName": "bookalook/templates/collections/album.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","album.albumType",["loc",[null,[7,73],[7,92]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 10
              },
              "end": {
                "line": 17,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/collections/album.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn_bal_circle quick_btn");
            dom.setAttribute(el1,"type","button");
            dom.setAttribute(el1,"data-toggle","modal");
            dom.setAttribute(el1,"data-target","#import-list");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","icon");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/album.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_circle add_btn");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_circle order_looks_btn");
          dom.setAttribute(el1,"type","button");
          dom.setAttribute(el1,"data-toggle","modal");
          dom.setAttribute(el1,"data-target","#order-looks");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element7);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["element","action",["openNewLookModal"],[],["loc",[null,[14,63],[14,92]]]],
          ["block","unless",[["subexpr","if-or",[["get","userLogged.isStarter",["loc",[null,[15,27],[15,47]]]],["get","userLogged.isDesigner",["loc",[null,[15,48],[15,69]]]]],[],["loc",[null,[15,20],[15,70]]]]],[],0,null,["loc",[null,[15,10],[17,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 6
            },
            "end": {
              "line": 37,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/collections/album.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","right_col");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dropdown group_actions");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","dropdown-toggle btn_bal_primary");
          dom.setAttribute(el3,"data-toggle","dropdown");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","caret");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3,"class","dropdown-menu group_actions_menu");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("Active");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("Archived");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[1] = dom.createAttrMorph(element5, 'class');
          morphs[2] = dom.createElementMorph(element5);
          morphs[3] = dom.createAttrMorph(element6, 'class');
          morphs[4] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["content","sampleStatusFilter",["loc",[null,[30,83],[30,105]]]],
          ["attribute","class",["concat",[["subexpr","unless",[["get","showArchivedSamples",["loc",[null,[32,74],[32,93]]]],"active"],[],["loc",[null,[32,65],[32,104]]]]]]],
          ["element","action",["showArchivedSamples",false],[],["loc",[null,[32,18],[32,57]]]],
          ["attribute","class",["concat",[["subexpr","if",[["get","showArchivedSamples",["loc",[null,[33,69],[33,88]]]],"active"],[],["loc",[null,[33,64],[33,99]]]]]]],
          ["element","action",["showArchivedSamples",true],[],["loc",[null,[33,18],[33,56]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 12
                    },
                    "end": {
                      "line": 52,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  dom.setAttribute(el1,"class","text-center");
                  var el2 = dom.createTextNode("No archived samples found.");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 54,
                          "column": 16
                        },
                        "end": {
                          "line": 56,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/collections/album.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[55,18],[55,62]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 53,
                        "column": 14
                      },
                      "end": {
                        "line": 57,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","look.hasVisibleSamples",["loc",[null,[54,22],[54,44]]]]],[],0,null,["loc",[null,[54,16],[56,23]]]]
                  ],
                  locals: ["look"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 52,
                      "column": 12
                    },
                    "end": {
                      "line": 58,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","model.orderedLooks",["loc",[null,[53,22],[53,40]]]]],[],0,null,["loc",[null,[53,14],[57,23]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 10
                  },
                  "end": {
                    "line": 59,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","allSamplesUnvisible",["loc",[null,[50,18],[50,37]]]]],[],0,1,["loc",[null,[50,12],[58,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 62,
                        "column": 14
                      },
                      "end": {
                        "line": 65,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[64,16],[64,60]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 67,
                            "column": 18
                          },
                          "end": {
                            "line": 70,
                            "column": 18
                          }
                        },
                        "moduleName": "bookalook/templates/collections/album.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[69,20],[69,64]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 66,
                          "column": 16
                        },
                        "end": {
                          "line": 71,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/collections/album.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","unless",[["get","look.samples",["loc",[null,[67,28],[67,40]]]]],[],0,null,["loc",[null,[67,18],[70,29]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 14
                      },
                      "end": {
                        "line": 72,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","look.archived",["loc",[null,[66,22],[66,35]]]]],[],0,null,["loc",[null,[66,16],[71,23]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 61,
                      "column": 12
                    },
                    "end": {
                      "line": 73,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","look.hasVisibleSamples",["loc",[null,[62,20],[62,42]]]]],[],0,1,["loc",[null,[62,14],[72,21]]]]
                ],
                locals: ["look"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 10
                  },
                  "end": {
                    "line": 74,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","model.orderedLooks",["loc",[null,[61,20],[61,38]]]]],[],0,null,["loc",[null,[61,12],[73,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 8
                },
                "end": {
                  "line": 75,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/collections/album.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","filteringSamples",["loc",[null,[48,16],[48,32]]]]],[],0,1,["loc",[null,[48,10],[74,17]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 10
                  },
                  "end": {
                    "line": 80,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","text-center");
                var el2 = dom.createTextNode("No archived samples found.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 84,
                          "column": 16
                        },
                        "end": {
                          "line": 87,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/collections/album.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[86,18],[86,62]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 83,
                        "column": 14
                      },
                      "end": {
                        "line": 88,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","unless",[["get","look.samples",["loc",[null,[84,26],[84,38]]]]],[],0,null,["loc",[null,[84,16],[87,27]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 82,
                      "column": 12
                    },
                    "end": {
                      "line": 89,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","look.archived",["loc",[null,[83,20],[83,33]]]]],[],0,null,["loc",[null,[83,14],[88,21]]]]
                ],
                locals: ["look"],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 89,
                      "column": 12
                    },
                    "end": {
                      "line": 92,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  dom.setAttribute(el1,"class","text-center");
                  var el2 = dom.createTextNode("No archived samples found.");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 80,
                    "column": 10
                  },
                  "end": {
                    "line": 93,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","model.orderedLooks",["loc",[null,[82,20],[82,38]]]]],[],0,1,["loc",[null,[82,12],[92,21]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 8
                },
                "end": {
                  "line": 94,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/collections/album.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","filteringSamples",["loc",[null,[77,16],[77,32]]]]],[],0,1,["loc",[null,[77,10],[93,17]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 6
              },
              "end": {
                "line": 97,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/collections/album.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.hasArchivedSamples",["loc",[null,[46,14],[46,38]]]]],[],0,1,["loc",[null,[46,8],[94,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 105,
                      "column": 12
                    },
                    "end": {
                      "line": 107,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  dom.setAttribute(el1,"class","text-center");
                  var el2 = dom.createTextNode("No active samples found.");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 109,
                          "column": 16
                        },
                        "end": {
                          "line": 111,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/collections/album.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[110,18],[110,62]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 14
                      },
                      "end": {
                        "line": 112,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","look.hasVisibleSamples",["loc",[null,[109,22],[109,44]]]]],[],0,null,["loc",[null,[109,16],[111,23]]]]
                  ],
                  locals: ["look"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 107,
                      "column": 12
                    },
                    "end": {
                      "line": 113,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","model.orderedLooks",["loc",[null,[108,22],[108,40]]]]],[],0,null,["loc",[null,[108,14],[112,23]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 10
                  },
                  "end": {
                    "line": 114,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","allSamplesUnvisible",["loc",[null,[105,18],[105,37]]]]],[],0,1,["loc",[null,[105,12],[113,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 117,
                        "column": 14
                      },
                      "end": {
                        "line": 120,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[119,16],[119,60]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 122,
                            "column": 18
                          },
                          "end": {
                            "line": 125,
                            "column": 18
                          }
                        },
                        "moduleName": "bookalook/templates/collections/album.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[124,20],[124,64]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 121,
                          "column": 16
                        },
                        "end": {
                          "line": 126,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/collections/album.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","unless",[["get","look.samples",["loc",[null,[122,28],[122,40]]]]],[],0,null,["loc",[null,[122,18],[125,29]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 120,
                        "column": 14
                      },
                      "end": {
                        "line": 127,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","unless",[["get","look.archived",["loc",[null,[121,26],[121,39]]]]],[],0,null,["loc",[null,[121,16],[126,27]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 116,
                      "column": 12
                    },
                    "end": {
                      "line": 128,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","look.hasVisibleSamples",["loc",[null,[117,20],[117,42]]]]],[],0,1,["loc",[null,[117,14],[127,21]]]]
                ],
                locals: ["look"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 10
                  },
                  "end": {
                    "line": 129,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","model.orderedLooks",["loc",[null,[116,20],[116,38]]]]],[],0,null,["loc",[null,[116,12],[128,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 101,
                  "column": 8
                },
                "end": {
                  "line": 130,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/collections/album.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","filteringSamples",["loc",[null,[103,16],[103,32]]]]],[],0,1,["loc",[null,[103,10],[129,17]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 132,
                    "column": 10
                  },
                  "end": {
                    "line": 135,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","text-center");
                var el2 = dom.createTextNode("No archived samples found.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 139,
                          "column": 16
                        },
                        "end": {
                          "line": 142,
                          "column": 16
                        }
                      },
                      "moduleName": "bookalook/templates/collections/album.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","partial",["collections/partials/look-card"],[],["loc",[null,[141,18],[141,62]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 138,
                        "column": 14
                      },
                      "end": {
                        "line": 143,
                        "column": 14
                      }
                    },
                    "moduleName": "bookalook/templates/collections/album.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","unless",[["get","look.samples",["loc",[null,[139,26],[139,38]]]]],[],0,null,["loc",[null,[139,16],[142,27]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 137,
                      "column": 12
                    },
                    "end": {
                      "line": 144,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","unless",[["get","look.archived",["loc",[null,[138,24],[138,37]]]]],[],0,null,["loc",[null,[138,14],[143,25]]]]
                ],
                locals: ["look"],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 144,
                      "column": 12
                    },
                    "end": {
                      "line": 147,
                      "column": 12
                    }
                  },
                  "moduleName": "bookalook/templates/collections/album.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  dom.setAttribute(el1,"class","text-center");
                  var el2 = dom.createTextNode("No archived samples found.");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 10
                  },
                  "end": {
                    "line": 148,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/collections/album.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","model.orderedLooks",["loc",[null,[137,20],[137,38]]]]],[],0,1,["loc",[null,[137,12],[147,21]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 130,
                  "column": 8
                },
                "end": {
                  "line": 149,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/collections/album.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","filteringSamples",["loc",[null,[132,16],[132,32]]]]],[],0,1,["loc",[null,[132,10],[148,17]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 6
              },
              "end": {
                "line": 150,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/collections/album.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.hasActiveSamples",["loc",[null,[101,14],[101,36]]]]],[],0,1,["loc",[null,[101,8],[149,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 4
            },
            "end": {
              "line": 151,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/collections/album.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showArchivedSamples",["loc",[null,[44,12],[44,31]]]]],[],0,1,["loc",[null,[44,6],[150,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 151,
              "column": 4
            },
            "end": {
              "line": 164,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/collections/album.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-12 text-center");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","empty-warn-in-big");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","images/new_bookings_black.svg");
          dom.setAttribute(el3,"title","Create a look!");
          dom.setAttribute(el3,"data-toggle","modal");
          dom.setAttribute(el3,"data-target","#new-look");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("You currently have no looks on this album.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Start by creating a look in just one click.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"type","button");
          dom.setAttribute(el3,"class","btn_bal_primary");
          dom.setAttribute(el3,"data-toggle","modal");
          dom.setAttribute(el3,"data-target","#new-look");
          var el4 = dom.createTextNode("Create new look\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [7]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [
          ["element","action",["openNewLookModal"],[],["loc",[null,[155,51],[155,80]]]],
          ["element","action",["openNewLookModal"],[],["loc",[null,[159,32],[159,61]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 184,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/collections/album.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 relative");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-wrapper clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","section_title_uirefactor");
        var el5 = dom.createTextNode("Looks");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","section_subtitle_uirefactor");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","menu-wrapper clearfix filters_top");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","left_col");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-group validated-input searcher");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","images/lupa.png");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"autocomplete","off");
        dom.setAttribute(el5,"id","no-autocomplete-search");
        dom.setAttribute(el5,"onsubmit","return false;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 1]);
        var element9 = dom.childAt(element8, [3]);
        var morphs = new Array(21);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 3]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element9, [3, 3]),1,1);
        morphs[3] = dom.createMorphAt(element9,5,5);
        morphs[4] = dom.createMorphAt(element8,5,5);
        morphs[5] = dom.createMorphAt(element8,7,7);
        morphs[6] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[7] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[8] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[9] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[10] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[12] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[13] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[14] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,20,20,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,22,22,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,24,24,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,26,26,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,28,28,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,30,30,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","if-equal",[["get","album.albumType",["loc",[null,[7,24],[7,39]]]],"Other"],[],["loc",[null,[7,14],[7,48]]]]],[],0,1,["loc",[null,[7,8],[7,99]]]],
        ["block","unless",[["get","album.archived",["loc",[null,[13,18],[13,32]]]]],[],2,null,["loc",[null,[13,8],[19,19]]]],
        ["inline","input",[],["type","text","id","samples-search-filter","class","search-filter","value",["subexpr","@mut",[["get","searchInput",["loc",[null,[24,85],[24,96]]]]],[],[]],"placeholder","Search..."],["loc",[null,[24,10],[24,122]]]],
        ["block","unless",[["subexpr","if-or",[["get","userLogged.isStarter",["loc",[null,[27,23],[27,43]]]],["get","userLogged.isDesigner",["loc",[null,[27,44],[27,65]]]]],[],["loc",[null,[27,16],[27,66]]]]],[],3,null,["loc",[null,[27,6],[37,17]]]],
        ["inline","partial",["collections/partials/album-filters"],[],["loc",[null,[40,4],[40,52]]]],
        ["block","if",[["get","model.looks.length",["loc",[null,[42,10],[42,28]]]]],[],4,5,["loc",[null,[42,4],[164,11]]]],
        ["inline","partial",["collections/modals/new-look"],[],["loc",[null,[169,0],[169,41]]]],
        ["inline","partial",["collections/modals/import-list"],[],["loc",[null,[170,0],[170,44]]]],
        ["inline","partial",["collections/modals/order-looks"],[],["loc",[null,[171,0],[171,44]]]],
        ["inline","partial",["collections/modals/archive-look"],[],["loc",[null,[172,0],[172,45]]]],
        ["inline","partial",["collections/modals/delete-album"],[],["loc",[null,[173,0],[173,45]]]],
        ["inline","partial",["collections/modals/delete-look"],[],["loc",[null,[174,0],[174,44]]]],
        ["inline","partial",["collections/modals/archive-samples"],[],["loc",[null,[175,0],[175,48]]]],
        ["inline","partial",["collections/modals/archive-album"],[],["loc",[null,[176,0],[176,46]]]],
        ["inline","partial",["collections/modals/publish-album"],[],["loc",[null,[177,0],[177,46]]]],
        ["inline","partial",["collections/modals/image-look-cropper"],[],["loc",[null,[178,0],[178,51]]]],
        ["inline","partial",["collections/modals/max-samples-reached"],[],["loc",[null,[179,0],[179,52]]]],
        ["inline","partial",["global/app-confirm-modal"],[],["loc",[null,[180,0],[180,38]]]],
        ["inline","partial",["global/delete-album-error-modal"],[],["loc",[null,[181,0],[181,45]]]],
        ["inline","partial",["collections/modals/change-gender-process"],[],["loc",[null,[182,0],[182,54]]]],
        ["inline","partial",["collections/modals/excel-upload-error"],[],["loc",[null,[183,0],[183,51]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }()));

});