define('bookalook/transforms/day', ['exports', 'ember-data', 'bookalook/utils'], function (exports, DS, utils) {

  'use strict';

  exports['default'] = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        if (serialized.date) {
          serialized = serialized.date.split(' ')[0];
        }

        var date = new Date(serialized);
        //explorer fix
        if (isNaN(date)) {
          var newSerialized = undefined;
          newSerialized = serialized.replace('-', '/');
          date = new Date(newSerialized);
          //Safari fix
          if (isNaN(date)) {
            newSerialized = serialized.slice(0, serialized.indexOf('T'));
            date = new Date(newSerialized);
          }
        }
        return date;
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return utils['default'].dateToDayFormat(deserialized);
      }
    }
  });

});