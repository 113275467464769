define('bookalook/tests/filters_inventory.jshint', function () {

  'use strict';

  QUnit.module('JSHint - filters_inventory.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'filters_inventory.js should pass jshint.\nfilters_inventory.js: line 127, col 5, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 128, col 5, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 129, col 5, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 152, col 2, Unnecessary semicolon.\nfilters_inventory.js: line 167, col 2, Unnecessary semicolon.\nfilters_inventory.js: line 292, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 293, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 294, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 295, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 296, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 297, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 298, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 299, col 6, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 300, col 7, Misleading line break before \'||\'; readers may interpret this as an expression boundary.\nfilters_inventory.js: line 354, col 38, Missing semicolon.\n\n15 errors');
  });

});