define('bookalook/tests/utils.jshint', function () {

  'use strict';

  QUnit.module('JSHint - utils.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'utils.js should pass jshint.\nutils.js: line 75, col 11, Missing semicolon.\nutils.js: line 81, col 23, Missing semicolon.\nutils.js: line 94, col 11, Missing semicolon.\nutils.js: line 100, col 23, Missing semicolon.\nutils.js: line 191, col 123, \'object spread property\' is only available in ES9 (use \'esversion: 9\').\nutils.js: line 191, col 134, Missing semicolon.\nutils.js: line 200, col 23, Missing semicolon.\nutils.js: line 205, col 77, \'object spread property\' is only available in ES9 (use \'esversion: 9\').\nutils.js: line 205, col 88, Missing semicolon.\nutils.js: line 213, col 23, Missing semicolon.\nutils.js: line 246, col 5, Missing semicolon.\nutils.js: line 253, col 80, Missing semicolon.\nutils.js: line 266, col 69, Missing semicolon.\nutils.js: line 332, col 999, Missing semicolon.\nutils.js: line 424, col 38, Missing semicolon.\nutils.js: line 454, col 38, Missing semicolon.\n\n16 errors');
  });

});