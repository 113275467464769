define('bookalook/tests/helpers/_when', ['bookalook/tests/helpers/test-helpers'], function (test_helpers) {

  'use strict';

  test_helpers._when(/^I fill in (.*) with (.*)$/, function (input, val, assert, assertStr) {
    fillIn(input, val);
    andThen(function () {
      return assert.equal(find(input).val(), val, assertStr);
    });
  });

  test_helpers._when(/^I set "(.*)" with "(.*)" in "(.*)" controller$/, function (prop, val, controller, assert, assertStr) {
    getController(controller).set(prop, val);
    andThen(function () {
      return assert.equal(getController(controller).get(prop), val, assertStr);
    });
  });

  test_helpers._when(/^I set "(.*)" with date "(.*)" in "(.*)" controller$/, function (prop, val, controller, assert, assertStr) {
    var date = new Date(val);
    getController(controller).set(prop, date);
    andThen(function () {
      return assert.equal(getController(controller).get(prop), date, assertStr);
    });
  });

  test_helpers._when(/^I set "(.*)" with "(.*)" model in "(.*)" controller$/, function (prop, model, controller, assert, assertStr) {
    model = model.split(' ');
    var modelName = model[0];
    var id = model[1];

    model = getStore().peekRecord(modelName, id);
    getController(controller).set(prop, model);
    andThen(function () {
      return assert.equal(getController(controller).get(prop).get('id'), model.get('id'), assertStr);
    });
  });

  test_helpers._when(/^I click (.*)$/, function (id, assert, assertStr) {
    click(id);
    andThen(function () {
      return assert.ok(true, assertStr);
    });
  });

});