define('bookalook/routes/collections/collection', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      openNewAlbumModal: function openNewAlbumModal() {
        this.controller.set('albumToAdd', this.store.createRecord('album', { collection: this.controller.get('model') }));
        this.controller.set('albumToAdd.privacy', 'Public');
      },
      showArchivedAlbums: function showArchivedAlbums(visibility) {
        if (this.controller.get('showArchivedAlbums') !== visibility) {
          this.controller.set('showArchivedAlbums', visibility);
          this.onDidTransition();
        }
      },
      saveAlbum: function saveAlbum(album) {
        this.onSaveAlbum(album);
      },
      cancelNewAlbum: function cancelNewAlbum(album) {
        this.onCancelNewAlbum(album);
      },
      activateCollection: function activateCollection() {
        this.onActivateCollection(this.controller.get('model'));
      },
      deleteCollection: function deleteCollection() {
        this.onDeleteCollection();
      },
      openChangePermissionModal: function openChangePermissionModal(album) {
        this.onOpenChangePermissionModal(album);
      },
      changePermission: function changePermission(album) {
        this.onChangePermission(album);
      },
      didTransition: function didTransition() {
        this.onDidTransition();
      },

      openTransferSamplesModal: function openTransferSamplesModal() {
        this.onOpenTransferSamplesModal();
      },
      continueToTransferStep2: function continueToTransferStep2() {
        this.onContinueToTransferStep2();
      },
      completeTransferSamples: function completeTransferSamples() {
        this.onCompleteTransferSamples();
      },
      closeTransferModal: function closeTransferModal() {
        this.onCloseTransferModal();
      },
      willTransition: function willTransition() {
        Ember['default'].$('#sample-historial').modal('hide');Ember['default'].$('#transfer-sample-step-2').modal('hide');
      },
      openEditCollectionModal: function openEditCollectionModal() {
        this.onOpenEditCollectionModal();
      },
      saveCollection: function saveCollection(collection) {
        this.onSaveCollection(collection);
      },
      rollbackCollection: function rollbackCollection() {
        this.controller.get('model').rollbackAttributes();
      },
      error: function error() {
        this.transitionTo('collections.index');
      },
      openEditAlbumModal: function openEditAlbumModal(album) {
        this.onOpenEditAlbumModal(album);
      },
      openUnpublishAlbumModal: function openUnpublishAlbumModal(album) {
        this.onOpenUnpublishAlbumModal(album);
      },
      unpublishAlbum: function unpublishAlbum(album) {
        this.onUnpublishAlbum(album);
      },
      publishAlbum: function publishAlbum(album) {
        this.onPublishAlbum(album);
      },
      openArchiveAlbumModal: function openArchiveAlbumModal(album) {
        this.onOpenArchiveAlbumModal(album);
      },
      activateAlbum: function activateAlbum(album) {
        this.onActivateAlbum(album);
      },
      openDeleteAlbumModal: function openDeleteAlbumModal(album) {
        this.onOpenDeleteAlbumModal(album);
      },
      deleteAlbum: function deleteAlbum() {
        this.onDeleteAlbum();
      },
      openDeleteAlbumErrorModal: function openDeleteAlbumErrorModal(title, subtitle, relatedBookings) {
        this.onOpenDeleteAlbumErrorModal(title, subtitle, relatedBookings);
      }

    },

    model: function model(params) {
      this.set('selectedCollection', params.id);
      return this.store.findRecord('collection', params.id, { reload: true });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('userLogged', Ember['default'].user);

      /*if (Ember.$('body').hasClass('isPR')) {
        controller.set('isPR', true);
      }*/

      controller.set('lookFilter', null);
      controller.set('searchInput', '');
      if (model.get('archived')) {
        controller.set('showArchivedAlbums', true);
      } else {
        //If has albums and none of them is active, show archived albums
        if (model.get('albums').get('length') > 0 && model.get('notArchivedAlbums').get('length') == 0) {
          controller.set('showArchivedAlbums', true);
        } else {
          controller.set('showArchivedAlbums', false);
        }
      }
      controller.set('albumsStatusFilter', Ember['default'].computed('showArchivedAlbums', function () {
        return controller.get('showArchivedAlbums') ? 'Archived' : 'Active';
      }));
      controller.set('selectedCollection', this.get('selectedCollection'));
      controller.set('isLoading', true);
      controller.set('user', Ember['default'].user);

      controller.set('statusContent', [{ id: 'All statuses' }, { id: 'AVAILABLE' },
      //{id: 'IN TRANSFER'},
      { id: 'UNAVAILABLE' }]);

      controller.set('continueDisabled', Ember['default'].computed('albumToAdd.albumType', 'albumToAdd.other', function () {
        var type = this.get('albumToAdd.albumType');
        var disabled = type === 'Other' ? !this.get('albumToAdd.other') : !type;
        return disabled;
      }));

      controller.set('albumsFilteredByStatus', Ember['default'].computed('model.albums.@each.archived', 'showArchivedAlbums', function () {
        var showArchived = controller.get('showArchivedAlbums');
        var albumsFiltered = controller.get('model.albums').filter(function (album) {
          return album.get('archived') === showArchived;
        });
        return albumsFiltered;
      }));

      controller.set('allAlbums', Ember['default'].computed('albumsFilteredByStatus', 'albumFilter', 'searchInput', function () {
        var albumsFiltered = controller.get('albumsFilteredByStatus');
        var searchInput = controller.get('searchInput').toLowerCase();

        var albumsFilteredBySearch = albumsFiltered.filter(function (album) {
          var title = null;
          if (album.get('albumTypeLabel') === "Other") {
            title = album.get('other');
          } else {
            title = album.get('albumTypeLabel');
          }
          title = title.toLowerCase();
          return title.indexOf(searchInput) >= 0;
        });
        setTimeout(function () {
          if (Ember['default'].$('.slick-carousel:not(.slick-initialized)').length) {
            Ember['default'].$('.slick-carousel:not(.slick-initialized)').slick({
              speed: 300,
              infinite: true,
              slidesToShow: 5,
              draggable: true,
              swipeToSlide: true,
              variableWidth: true
            });
          }
        }, 250);
        return albumsFilteredBySearch.sortBy('published', 'lastSaved', 'lastPublished').reverse();
      }));

      controller.set('allAlbumsTransfer', Ember['default'].computed('albumsFilteredByStatus', 'albumFilter', 'lookFilter', 'searchInput', 'statusFilter', function () {
        var albumsFiltered = controller.get('albumsFilteredByStatus');
        var searchInput = controller.get('searchInput');
        var lookFilter = controller.get('lookFilter');
        var statusFilter = controller.get('statusFilter');
        var allSamplesUnvisible = true;

        if (controller.get('albumFilter') && controller.get('albumFilter.id')) {
          albumsFiltered = [controller.get('albumFilter')];
        }

        controller.get('model.samples').forEach(function (s) {
          s.set('visible', s.get('showroom.id') === Ember['default'].showroom.get('id'));

          var checked = s.get('visible');

          if (lookFilter && checked) {
            checked = s.get('look.id') === lookFilter;
          }

          if (searchInput !== '' && checked) {
            checked = s.get('searchString').toLowerCase().indexOf(searchInput) > -1;
          }

          if (statusFilter && statusFilter !== 'All statuses' && checked) {
            var status = s.get('addSampleStatus');
            checked = status.toLowerCase() === statusFilter.toLowerCase();
          }

          allSamplesUnvisible = allSamplesUnvisible && !checked;
          s.set('visible', checked);
        });

        controller.set('allSamplesUnvisible', allSamplesUnvisible);

        utils['default'].lazyLoad(2000, '#transfer-sample-step-2');
        return albumsFiltered.sortBy('published', 'lastSaved', 'lastPublished').reverse();
      }));

      controller.set('allAlbumsByVisibleSample', Ember['default'].computed('albumsFilteredByStatus', function () {
        var albumsFiltered = controller.get('albumsFilteredByStatus');
        var albumsFilteredBySampleVisible = albumsFiltered.filter(function (album) {
          return album.get('hasVisibleSamples');
        });

        return utils['default'].addDefaultOption(albumsFilteredBySampleVisible, 'All Albums', 'albumTypeLabel', '').sortBy('published', 'lastSaved', 'lastPublished');
      }));

      controller.set('looksFilteredByAlbum', Ember['default'].computed('albumFilter', function () {
        controller.set('lookFilter', null);
        controller.set('colourFilter', '');
        controller.set('statusFilter', '');
        var album = controller.get('albumFilter');
        if (!album || !album.get) {
          return [];
        }
        var albumId = album.get('id');
        var looks = album.get('looks');
        var looksFiltered = looks;
        if (albumId !== '0') {
          looksFiltered = looks.filter(function (look) {
            return look.get('album.id') === albumId && look.get('archived') === false && look.get('hasActiveSamples') && look.get('hasVisibleSamples');
          });
        }
        return utils['default'].addDefaultOption(looksFiltered, 'All Looks', 'position', '');
      }));

      controller.set('brandShowroomsExceptUserShowroom', Ember['default'].computed('model.brand.showrooms.@each', function () {
        var showroomsFiltered = controller.get('model.brand.showrooms').filter(function (s) {
          return s.get('id') !== Ember['default'].showroom.get('id');
        });
        return showroomsFiltered;
      }));
    },

    onActivateCollection: function onActivateCollection(collection) {
      var t = this;
      utils['default'].putAPIJSON('collections/' + collection.get('id') + '/reactivate').then(function () {
        collection.reload().then(function () {
          t.send('openPositiveTopBar');
        });
        collection.set('archived', false);
      });
    },

    onSaveAlbum: function onSaveAlbum(album) {
      var _this2 = this;

      if (album.get('isNew')) {
        album.save().then(function (newAlbum) {
          //this.controller.notifyPropertyChange('model');
          _this2.transitionTo('collections.album', newAlbum.get('id'));
        });
      } else {
        album.save();
      }
    },

    onCancelNewAlbum: function onCancelNewAlbum(album) {
      album.rollbackAttributes();
    },

    onDeleteCollection: function onDeleteCollection() {

      var model = this.controller.get('model');
      var promise = model.destroyRecord().then(function () {

        /* this.transitionTo('collections.index').then(() => {
           Ember.$('#delete-collection').modal('hide');
         });*/
      });
      this.transitionTo('collections.index');
      Ember['default'].$('#delete-collection').modal('hide');
      utils['default'].buttonLoading('#delete-button', promise);
    },

    onOpenChangePermissionModal: function onOpenChangePermissionModal(album) {
      this.controller.set('albumToModal', album);
      Ember['default'].openModal();
    },

    onOpenTransferSamplesModal: function onOpenTransferSamplesModal() {
      this.controller.set('showroomToTransfer', null);
      Ember['default'].$('#transfer-sample-step-1').modal();
    },

    onCloseTransferModal: function onCloseTransferModal() {
      this.controller.get('model.samples').forEach(function (s) {
        var isVisible = s.get('showroom.id') === Ember['default'].showroom.get('id');
        if (isVisible) {
          s.set('checked', false);
        }
      });
      Ember['default'].$('#transfer-sample-step-2').modal('hide');
    },

    onContinueToTransferStep2: function onContinueToTransferStep2() {
      var _this3 = this;

      var showroomSamples = [];
      this.controller.set('isLoading', true);
      this.controller.get('model.samples').forEach(function (s) {
        var isVisible = s.get('showroom.id') === Ember['default'].showroom.get('id');
        if (isVisible) {
          showroomSamples.push(s.get('id'));
        }
        s.set('visible', isVisible);
      });

      utils['default'].postAPIJSON('statuses/transferSample', { samples: showroomSamples }).then(function (res) {
        var statuses = res.data;
        statuses.forEach(function (status) {
          var sample = _this3.store.peekRecord('sample', status.sample);
          sample.set('addSampleStatus', status.status);

          var statusLabel = status.status.toUpperCase();
          sample.set('disabled', statusLabel !== 'AVAILABLE' && statusLabel !== 'IN BOOKING(S)');
        });
        _this3.controller.set('isLoading', false);
      });

      this.controller.notifyPropertyChange('allAlbumsByVisibleSample');

      Ember['default'].openModal();
      utils['default'].lazyLoad(2000, '#transfer-sample-step-2');
    },

    onCompleteTransferSamples: function onCompleteTransferSamples() {
      var _this4 = this;

      var samples = this.controller.get('model.samples').filterBy('checked');
      var IsSomeSampleInBookings = samples.some(function (s) {
        var status = s.get('addSampleStatus') || "";
        return status.toUpperCase() === 'IN BOOKING(S)';
      });
      var samplesIds = samples.mapBy('id');

      if (IsSomeSampleInBookings) {
        Ember['default'].$('#transfer-sample-step-2').modal('hide');
        utils['default'].openDefaultConfirmationModal(this, 'this samples are in bookings!', 'They willl be cancelled').then(function () {
          return _this4._sendTransferSamples(samplesIds, true);
        }, function () {
          return Ember['default'].$('#transfer-sample-step-2').modal();
        });
      } else {
        this._sendTransferSamples(samplesIds);
      }
    },

    _sendTransferSamples: function _sendTransferSamples(samples, cancelBookings) {
      var _this5 = this;

      utils['default'].postAPIJSON('transferSample', {
        "transfersample": {
          showRoomRecipient: this.controller.get('showroomToTransfer.id'),
          samples: samples,
          cancelBookings: cancelBookings
        }
      }).then(function () {
        Ember['default'].$('#transfer-sample-step-2').modal('hide');
        _this5.send('openTopBar', 'Samples in transfer');
        _this5.controller.set('showroomToTransfer', null);
        _this5.controller.get('model.samples').setEach('checked', false);
      });
    },

    onChangePermission: function onChangePermission(album) {
      var privacy = album.get('privacy');
      var newPrivacy = privacy === "Public" ? "Private" : "Public";
      album.set('privacy', newPrivacy).save();
    },

    onOpenEditCollectionModal: function onOpenEditCollectionModal() {
      var collection = this.controller.get('model');
      this.controller.set('collectionToAdd', collection);
    },

    onSaveCollection: function onSaveCollection(collection) {
      var _this = this;
      if (collection.validate()) {
        Ember['default'].$('#new-collection').modal('hide');
        collection.save().then(function () {
          _this.controller.notifyPropertyChange('model');
        });
      }
    },

    onDidTransition: function onDidTransition() {
      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        Ember['default'].$('.slick-carousel').slick({
          speed: 300,
          infinite: true,
          slidesToShow: 5,
          draggable: true,
          swipeToSlide: true,
          variableWidth: true
        });
      });
      setTimeout(function () {
        Ember['default'].$('.slick-carousel').resize();
      }, 750);
      setTimeout(function () {
        Ember['default'].$('.slick-carousel').slick("unslick");
        Ember['default'].$('.slick-carousel').slick({
          speed: 300,
          infinite: true,
          slidesToShow: 5,
          draggable: true,
          swipeToSlide: true,
          variableWidth: true
        });
      }, 1500);
      setTimeout(function () {
        Ember['default'].$('.slick-carousel').resize();
      }, 3250);
    },

    onOpenEditAlbumModal: function onOpenEditAlbumModal(album) {
      this.controller.set('albumToAdd', album);
      Ember['default'].$('#new-album').modal();
      Ember['default'].openModal();
    },

    onOpenUnpublishAlbumModal: function onOpenUnpublishAlbumModal(album) {
      this.controller.set('albumToUnpublish', album);
      Ember['default'].$('#publish-album').modal();
      Ember['default'].openModal();
    },

    onUnpublishAlbum: function onUnpublishAlbum(album) {
      album.set('published', false).save();
    },

    onPublishAlbum: function onPublishAlbum(album) {
      var _this6 = this;

      var _this = this;

      this.send('openMessageModal', 'Publishing album');
      utils['default'].putAPIJSON('albums/' + album.get('id') + '/publish').then(function () {
        album.set('published', true);
        album.reload();
        _this6.send('closeMessageModal');
      });
    },

    onOpenArchiveAlbumModal: function onOpenArchiveAlbumModal(album) {
      this.controller.set('albumToArchive', album);
      Ember['default'].$('#archive-album').modal();
      Ember['default'].openModal();
    },

    onActivateAlbum: function onActivateAlbum(album) {
      var t = this;
      utils['default'].putAPIJSON('albums/' + album.get('id') + '/reactivate').then(function () {
        //album.set('archived', false);
        //let looks = album.get('looks');
        //looks.forEach(function (look) {
        //  t.setLookArchived(look, false);
        //});
        t.controller.get('model').reload().then(function (col) {
          col.set('archived', false);t.send('openPositiveTopBar');return col;
        });
        album.set('archived', false);
      });
    },

    onOpenDeleteAlbumModal: function onOpenDeleteAlbumModal(album) {
      this.controller.set('albumToDelete', album);
      Ember['default'].$('#delete-album').modal();
      Ember['default'].openModal();
    },

    onDeleteAlbum: function onDeleteAlbum() {
      var _this7 = this;

      var model = this.controller.get('albumToDelete');
      utils['default'].getAPIJSON('albums/' + model.get('id') + '/archivable').then(function (res) {
        var relatedBookings = res.bookings.sortBy('id');
        var inTransfer = res.inTransfer;
        if (relatedBookings.length === 0 && !inTransfer) {
          //this.transitionTo('collections.collection', model.get('collection.id')).then(() => {
          model.destroyRecord();
          _this7.send('openTopBar', 'Album deleted');
          //});
        } else {
            _this7.send('openTopBar', 'Delete album has failed: one or more sample(s) are currently active in some booking(s)');
            //Funcion heredada de album.js: Comentada porque en realidad no existe ningun onOpenDeleteAlbumErrorModal en la app
            //this.onOpenDeleteAlbumErrorModal('COMPUTER SAYS NO!', 'This Album can’t be deleted because one or more sample(s) are currently active in the following booking(s):', relatedBookings);
          }
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-collections');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-collections');
    }
  });

});