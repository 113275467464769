define('bookalook/models/demo-request', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    email: DS['default'].attr('string'),
    name: DS['default'].attr('string'),
    surname: DS['default'].attr('string'),
    company: DS['default'].attr('string'),
    website: DS['default'].attr('string'),

    validations: {
      'email': {
        presence: true,
        email: true
      },
      'name': {
        presence: true
      },
      'surname': {
        presence: true
      },
      'company': {
        presence: true
      }
    }
  });

});