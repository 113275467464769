define('bookalook/tests/routes/register/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/register/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'routes/register/index.js should pass jshint.');
  });

});