define('bookalook/helpers/colour-url', ['exports', 'ember', 'bookalook/config/environment'], function (exports, Ember, config) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var colour = params[0];
    var colourURL = config['default'].IMAGEPATH + 'colours/' + colour.replace(/ /g, '').toLowerCase() + '.png';
    return colourURL;
  });

});