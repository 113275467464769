define('bookalook/routes/index', ['exports', 'ember', 'simple-auth/mixins/application-route-mixin', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, ApplicationRouteMixin, utils, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ApplicationRouteMixin['default'], {
    invitationUrl: 'https://bookalook.app.waitlisted.co/api/v1/reservation',
    referralUrl: 'https://www.bookalook.com?ref=',
    requestError: 'Please fill in all fields',

    authenticator: 'simple-auth-authenticator:oauth2-password-grant',

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      var user = Ember['default'].user;
      if (user) {
        if (user.get('isMP')) {
          this.replaceWith('search.looks');
        } else {
          this.replaceWith('inventory');
        }
      } else {
        this.replaceWith('session.login');
      }
    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        instagramImages: utils['default'].getInstagramImages(),
        invitationRequest: this.store.createRecord('invitation-request')
      });
    },

    actions: {
      checkSpot: function checkSpot() {
        this.onCheckSpot();
      },
      openCheckSpotModal: function openCheckSpotModal() {
        this.onOpenCheckSpotModal();
      },
      requestInvitation: function requestInvitation() {
        this.onRequestInvitation();
      },
      willTransition: function willTransition() {
        this.onWillTransition();
      },
      shareFacebook: function shareFacebook() {
        this.onShareFacebook();
      }
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      controller.setProperties(model);

      controller.set('instagramImages', model.instagramImages.images);

      Ember['default'].hotjar_ID = config['default'].hotjar_ID;
      Ember['default'].$(document).ready(function () {
        Ember['default'].run.scheduleOnce('afterRender', _this, function () {
          Ember['default'].$('.back_to_top').hide();
          Ember['default'].$('#follower').removeAttr('style');
          this.setHandIcon();
          this.setImageRotation();
          Ember['default'].$('#linkedchat').show();

          this.initialY = Ember['default'].$('.carousel-wrapper').offset().top;
          this.finalY = Ember['default'].$('#third-article').offset().top;
          this.isInAnimation = false;
          this.index = 0;
          this.isMobile = false;

          window.addEventListener('scroll', Ember['default'].$.proxy(this.onScroll, this));
          window.addEventListener('resize', Ember['default'].$.proxy(this.onScroll, this));

          this.onMobileCarousel();
          window.addEventListener('resize', Ember['default'].$.proxy(this.onMobileCarousel, this));
          Ember['default'].run.later(function () {
            return Ember['default'].$(window).trigger('resize');
          }, 2000);

          var checkEmail = Ember['default'].getUrlParameter('checkEmail');
          if (checkEmail) {
            controller.set('checkSpotEmail', checkEmail);
            this.onCheckSpot();
          }

          var anchor = window.location.hash.split('?')[0];
          if (anchor === '#request-a-demo') {
            Ember['default'].$('#request-my-demo').modal();
          } else if (anchor === '#request-my-invite') {
            Ember['default'].$('#request-invite-email input').get(0).focus();
          }
        });
      });

      //fbq('track', "PageView");
    },

    onScroll: function onScroll() {
      var _this2 = this;

      var $ = Ember['default'].$;
      var computer = $('.laptop-wrapper');
      var pointers = $('.articles-points');
      var logoBig = $('.page-home .logotype');
      var logoLittle = $('.page-home .logotype-min');
      var scrollY = window.scrollY || window.pageYOffset;

      if (scrollY > 1) {
        logoBig.addClass('hide-away');
        logoLittle.addClass('show');
      } else {
        logoBig.removeClass('hide-away');
        logoLittle.removeClass('show');
      }

      if (scrollY > this.initialY && scrollY <= this.finalY) {
        if (!computer.hasClass('fixed')) {
          computer.removeClass('fixed-bottom');
          computer.addClass('fixed');
          pointers.addClass('show');
        }
      } else if (scrollY > this.finalY) {
        computer.removeClass('fixed');
        if (scrollY - 200 > this.finalY) {
          pointers.removeClass('show');
        }

        if (!computer.hasClass('fixed')) {
          computer.addClass('fixed-bottom');
        }
      } else {
        computer.removeClass('fixed');
        computer.removeClass('fixed-bottom');
        pointers.removeClass('show');
      }

      var articles = $('.carousel-articles .article');
      var tops = [6, -82, -170];
      $.each(articles, function (index, element) {
        var article = $(element);
        var top = article.offset().top - article.height() / 2;
        var bottom = article.offset().top + article.height() / 2;
        if (!_this2.isInAnimation && scrollY > top && scrollY <= bottom) {
          var targetY = tops[index];
          _this2.isInAnimation = true;
          $('.sprite-screen-img').animate({ top: targetY + '%' }, {
            complete: function complete() {
              return _this2.isInAnimation = false;
            }
          });
        }
      });
    },

    onMobileCarousel: function onMobileCarousel() {
      var _this3 = this;

      var $ = Ember['default'].$;
      var width = $('body').width();
      var tops = [6, -82, -170];
      if (width <= 752 && width !== null) {
        if (!this.isMobile) {
          this.interval = setInterval(function () {
            var targetY = tops[_this3.index];
            _this3.isInAnimation = true;
            $('.sprite-screen-img').animate({ top: targetY + '%' }, 1000);
            if (_this3.index < 2) {
              _this3.index++;
            } else {
              _this3.index = 0;
            }
          }, 3000);
          this.isMobile = true;
        }
      } else {
        clearInterval(this.interval);
        this.isMobile = false;
        $('.sprite-screen-img').animate({ top: tops[0] + '%' }, {
          complete: function complete() {
            return _this3.isInAnimation = false;
          }
        });
      }
    },

    onRequestInvitation: function onRequestInvitation() {
      var _this4 = this;

      this.controller.set('emailTaken', null);
      this.controller.set('isAlreadyRequested', false);

      var invitationRequest = this.controller.get('invitationRequest');
      if (invitationRequest.validate()) {
        var refcode;
        var data;
        var promise;

        (function () {
          location.hash = 'request-my-invite';
          var invitation = invitationRequest.getProperties('email', 'name', 'surname', 'company');

          refcode = Ember['default'].getUrlParameter('ref');
          data = {
            referal: refcode,
            reservation: {
              email: invitation.email,
              name: invitation.name,
              refcode: refcode,
              responses: {
                company: invitation.company,
                surname: invitation.surname
              }
            }
          };
          promise = Ember['default'].$.ajax({
            type: 'POST',
            url: _this4.invitationUrl,
            data: data,
            timeout: 5000
          });

          utils['default'].buttonLoading('#request-invite-button', promise);

          promise.success(function (res) {
            _this4.controller.set('requestPosition', res.reservation.position);
            _this4.controller.set('requestAffiliate', res.reservation.affiliate);

            _this4.controller.set('requestReferral', _this4.referralUrl + res.reservation.affiliate);
            Ember['default'].$('#request-waiting-list').modal();
            ga('send', 'pageview', '/request-my-invite/ok');
            fbq('track', 'RequestedInvite');
            _this4._storeRequestData(data, res);
          });
          promise.fail(function (res) {
            if (!res.responseJSON || res.statusText === 'timeout') {
              _this4._storeRequestData(data, res);
              return;
            }
            if (res.responseJSON.errors.email[0] === 'has already been taken') {
              _this4._requestListSpot(invitation.email).then(function () {
                return Ember['default'].$('#request-waiting-list').modal();
              });
            }
          });
        })();
      }
    },

    _storeRequestData: function _storeRequestData(data, res) {
      var requestData = {
        name: data.reservation.name,
        surname: data.reservation.responses.surname,
        email: data.reservation.email,
        company: data.reservation.responses.company,
        refered: data.reservation.refcode
      };
      if (res.reservation) {
        requestData.refcode = res.reservation.affiliate;
        requestData.position = res.reservation.position;
      }
      utils['default'].postAPIJSON('formContactUs', requestData).then(function () {
        if (!res.reservation) {
          ga('send', 'pageview', '/request-my-invite/ok');
          Ember['default'].$('#request-waiting-list').modal();
        }
      });
    },

    _requestListSpot: function _requestListSpot(email) {
      var _this5 = this;

      return Ember['default'].$.get(this.invitationUrl, { email: email }, function (res) {
        _this5.controller.set('requestPosition', res.reservation.position);
        _this5.controller.set('requestAffiliate', res.reservation.affiliate);

        _this5.controller.set('requestReferral', _this5.referralUrl + res.reservation.affiliate);
        _this5.controller.set('isAlreadyRequested', true);
      });
    },

    onCheckSpot: function onCheckSpot() {
      var _this6 = this;

      var email = this.controller.get('checkSpotEmail');
      var promise = this._requestListSpot(email);

      utils['default'].buttonLoading('#check-my-spot-button', promise);
      promise.success(function () {
        Ember['default'].$('#check-my-spot').modal('hide');
        Ember['default'].$('#request-waiting-list').modal();
        Ember['default'].openModal();
        _this6.controller.set('isCheckSpot', true);
      }).fail(function () {
        return _this6.controller.set('spotEmailNotValid', true);
      });
    },

    onOpenCheckSpotModal: function onOpenCheckSpotModal() {
      this.controller.set('spotEmailNotValid', false);
      this.controller.set('checkSpotEmail', null);

      Ember['default'].$('#check-my-spot').modal();
    },

    activate: function activate() {

      Ember['default'].$('body').addClass('page-home');
      this.controllerFor('application').set('hasSidebar', false);

      Ember['default'].$(window).on('scroll', function () {
        if (Ember['default'].$('#preview').length > 0) {
          var submenu = Ember['default'].$('#submenu');
          var preview = Ember['default'].$('#preview');
          var preview_offset = preview.offset();
          if (Ember['default'].$(window).scrollTop() >= preview_offset.top) {
            submenu.addClass('fixed');
          } else {
            submenu.removeClass('fixed');
          }
        }
      });
    },

    onWillTransition: function onWillTransition() {
      Ember['default'].$('.back_to_top').show();
      Ember['default'].$(document).unbind('scroll');
      Ember['default'].$(document).unbind('resize');
      Ember['default'].$(document).unbind('mousemove');
      Ember['default'].$('#linkedchat').hide();
    },

    onShareFacebook: function onShareFacebook() {
      FB.ui({
        method: 'share',
        href: 'https://bookalook.com?ref=' + this.controller.get('requestAffiliate'),
        quote: "I just signed up for Bookalook, join me!"
      }, function () {});
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-home');
      this.controllerFor('application').set('hasSidebar', false);
    },

    setHandIcon: function setHandIcon() {
      var mouseX = 0;
      var mouseY = 0;
      var limitX = Ember['default'].$('#request-invite').outerWidth();
      var limitY = Ember['default'].$('#request-invite').outerHeight() + 10;
      if (limitY < 100) {
        // size on $('#request-invite') IS NOT THE SAME when u go to index from Login (just in QA and PRO, I don't know why)
        limitY = 107;
      }

      Ember['default'].$(document).mousemove(function (e) {
        var offset = Ember['default'].$('#handlayer').offset();
        mouseX = Math.min(e.pageX - offset.left, limitX);
        mouseY = Math.min(e.pageY - offset.top, limitY);

        if (mouseX < 0) {
          mouseX = 0;
        }
        if (mouseY < 0) {
          mouseY = 0;
        }

        if (mouseX !== 0 && mouseX !== limitX) {
          if (mouseY > limitY - 40) {
            mouseY = limitY;
          } else {
            mouseY = 0;
          }
        }
      });

      Ember['default'].$('#request-invite').mouseover(function () {
        Ember['default'].$('#follower').fadeOut('fast');
      });

      Ember['default'].$('#request-invite').mouseleave(function () {
        Ember['default'].$("#follower").fadeIn('fast');
      });

      // cache the selector
      var follower = Ember['default'].$("#follower");
      var xp = 0,
          yp = 0;
      setInterval(function () {
        // change 12 to alter damping higher is slower
        xp += (mouseX - xp) / 2;
        yp += (mouseY - yp) / 2;
        follower.css({ left: xp - 20, top: yp - 30 });
      }, 30);
    },

    setImageRotation: function setImageRotation() {
      var img = Ember['default'].$('#follower');
      var element = Ember['default'].$('#request-invite');
      if (img.length > 0) {
        var mouse = function mouse() {
          var elementOffset = element.offset();
          var offset = img.offset();
          var center_x = offset.left + img.width() / 2;
          var center_y = offset.top + img.height() / 2;

          var offsetX = 20;
          var elementMaxX = elementOffset.left + element.outerWidth() - offsetX;
          var element_x = Math.max(Math.min(center_x, elementMaxX), elementOffset.left + offsetX);
          var element_y = elementOffset.top + element.outerHeight() / 2;
          var radians = Math.atan2(element_x - center_x, element_y - center_y);
          var degree = radians * (180 / Math.PI) * -1 + 360;
          img.css('-moz-transform', 'rotate(' + degree + 'deg)');
          img.css('-webkit-transform', 'rotate(' + degree + 'deg)');
          img.css('-o-transform', 'rotate(' + degree + 'deg)');
          img.css('-ms-transform', 'rotate(' + degree + 'deg)');
        };
        Ember['default'].$(document).mousemove(mouse);
      }
    }
  });

});