define('bookalook/templates/components/looks-gallery-uploader-images', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 8
                },
                "end": {
                  "line": 30,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/components/looks-gallery-uploader-images.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","look-avatar handle");
              dom.setAttribute(el1,"src","");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","fileUpload without-image");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2,"type","button");
              dom.setAttribute(el2,"class","btn_bal_circle_form add_btn");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","icon");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"type","file");
              dom.setAttribute(el2,"class","upload input-empty");
              dom.setAttribute(el2,"accept",".gif, .jpeg, .jpg, .png,");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","upload-actions-container handle");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","sorter");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"class","btn_bal_basic");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","footer");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"class","fileUpload btn_bal_basic edit_btn pull-left");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("input");
              dom.setAttribute(el4,"type","file");
              dom.setAttribute(el4,"class","upload");
              dom.setAttribute(el4,"accept",".gif, .jpeg, .jpg, .png,");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","icon");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"type","button");
              dom.setAttribute(el3,"class","btn_bal_basic close_btn pull-right");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(fragment, [5]);
              var element2 = dom.childAt(element1, [3]);
              var element3 = dom.childAt(fragment, [7]);
              var element4 = dom.childAt(element3, [3]);
              var element5 = dom.childAt(element4, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var element8 = dom.childAt(element4, [3]);
              var morphs = new Array(10);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0,0,0);
              morphs[2] = dom.createElementMorph(element1);
              morphs[3] = dom.createAttrMorph(element2, 'id');
              morphs[4] = dom.createMorphAt(dom.childAt(element3, [1, 1]),0,0);
              morphs[5] = dom.createElementMorph(element5);
              morphs[6] = dom.createAttrMorph(element6, 'id');
              morphs[7] = dom.createAttrMorph(element7, 'for');
              morphs[8] = dom.createAttrMorph(element8, 'id');
              morphs[9] = dom.createElementMorph(element8);
              return morphs;
            },
            statements: [
              ["element","action",["selectAvatar",["get","item",["loc",[null,[8,37],[8,41]]]]],[],["loc",[null,[8,13],[8,43]]]],
              ["content","item",["loc",[null,[8,44],[8,52]]]],
              ["element","action",["setInputEvents","empty-look-image",["get","lookToAdd",["loc",[null,[10,93],[10,102]]]],"look",["get","item",["loc",[null,[10,110],[10,114]]]]],["preventDefault",false],["loc",[null,[10,48],[10,137]]]],
              ["attribute","id",["concat",["empty-look-image-",["get","item",["loc",[null,[14,115],[14,119]]]],"-input"]]],
              ["inline","fa-icon",["arrows-h"],[],["loc",[null,[18,58],[18,80]]]],
              ["element","action",["setInputEvents","new-look-image",["get","lookToAdd",["loc",[null,[21,131],[21,140]]]],"look",["get","item",["loc",[null,[21,148],[21,152]]]]],["preventDefault",false],["loc",[null,[21,88],[21,175]]]],
              ["attribute","id",["concat",["new-look-image-",["get","item",["loc",[null,[22,105],[22,109]]]],"-input"]]],
              ["attribute","for",["concat",["new-look-image-",["get","item",["loc",[null,[23,45],[23,49]]]],"-input"]]],
              ["attribute","id",["concat",["delete-image-",["get","index",["loc",[null,[25,98],[25,103]]]]]]],
              ["element","action",["deleteImageAvatar",["get","item",["loc",[null,[25,136],[25,140]]]]],[],["loc",[null,[25,107],[25,142]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 31,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/components/looks-gallery-uploader-images.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","sortable-item",[],["tagName","li","class","gallery-button sortable-item","model",["subexpr","@mut",[["get","item",["loc",[null,[7,81],[7,85]]]]],[],[]],"group",["subexpr","@mut",[["get","group",["loc",[null,[7,92],[7,97]]]]],[],[]],"handle",".handle"],0,null,["loc",[null,[7,8],[30,26]]]]
          ],
          locals: ["item","index"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 32,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery-uploader-images.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","avatarsOrder",["loc",[null,[6,14],[6,26]]]]],[],0,null,["loc",[null,[6,6],[31,15]]]]
        ],
        locals: ["group"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 4
          }
        },
        "moduleName": "bookalook/templates/components/looks-gallery-uploader-images.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","look-image no-padding-left");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","custom-sortable looks-gallery-uploader-images");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"autocomplete","off");
        dom.setAttribute(el3,"id","looks-gallery-uploader-form");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]),1,1);
        return morphs;
      },
      statements: [
        ["block","sortable-group",[],["tagName","ul","direction","x","class","btn-group no-padding-tabs sortable-group buttons-wrapper","onChange","sortAvatars"],0,null,["loc",[null,[4,4],[32,23]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});