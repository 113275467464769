define('bookalook/tests/routes/contacts-lists/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/contacts-lists/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/contacts-lists/index.js should pass jshint.\nroutes/contacts-lists/index.js: line 59, col 62, Missing semicolon.\nroutes/contacts-lists/index.js: line 188, col 31, Missing semicolon.\nroutes/contacts-lists/index.js: line 211, col 28, Missing semicolon.\nroutes/contacts-lists/index.js: line 356, col 73, Missing semicolon.\nroutes/contacts-lists/index.js: line 574, col 4, Missing semicolon.\nroutes/contacts-lists/index.js: line 582, col 4, Unnecessary semicolon.\n\n6 errors');
  });

});