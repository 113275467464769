define('bookalook/tests/helpers/search-look-label.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/search-look-label.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'helpers/search-look-label.js should pass jshint.\nhelpers/search-look-label.js: line 45, col 17, Missing semicolon.\n\n1 error');
  });

});