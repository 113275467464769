define('bookalook/helpers/check-position-pophover', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var maxForBottomPos = params[0];
    var items = params[1];
    var current = params[2];

    if (current > maxForBottomPos && items - current < maxForBottomPos) {
      return true;
    } else {
      return false;
    }
  });

});