define('bookalook/ember-cli-masonry-grid/tests/modules/ember-cli-masonry-grid/components/masonry-grid.jshint', function () {

  'use strict';

  QUnit.module('JSHint - modules/ember-cli-masonry-grid/components/masonry-grid.js');
  QUnit.test('should pass jshint', function (assert) {
    assert.expect(1);
    assert.ok(true, 'modules/ember-cli-masonry-grid/components/masonry-grid.js should pass jshint.');
  });

});