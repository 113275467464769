define('bookalook/routes/directory/collections-directory', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, config, utils, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    model: function model() {
      return Ember['default'].RSVP.hash({
        collections: utils['default'].getAPIJSON('collections/list/1')
      });
    },

    actions: {},

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      var collections = controller.get('collections');
    }
  });

});