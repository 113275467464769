define('bookalook/routes/inventory/index', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/routes/check-out/functions', 'bookalook/routes/inventory/transfer-functions', 'bookalook/filters_inventory', 'bookalook/filters', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, environment, utils, checkFunctions, transferFunctions, filtersInventory, filters, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    poll: Ember['default'].inject.service(),

    actions: {
      goToScanning: function goToScanning() {
        this.onGoToScanning();
      },
      openCheckOutModal: function openCheckOutModal(sample) {
        this.onOpenCheckOutModal(sample);
      },
      openCheckOutModalBrandRefactor: function openCheckOutModalBrandRefactor(sample) {
        this.onOpenCheckOutModalBrandRefactor(sample);
      },
      checkCheckoutLook: function checkCheckoutLook(look) {
        checkFunctions['default'].onCheckCheckoutLook(look);
      },
      checkCheckoutLookVisibleSamples: function checkCheckoutLookVisibleSamples(look) {
        checkFunctions['default'].onCheckCheckoutLookVisibleSamples(look);
      },

      toggleChecksToCheckoutInBooking: function toggleChecksToCheckoutInBooking(booking) {
        checkFunctions['default'].onToggleChecksToCheckoutInBooking(booking);
      },
      checkCheckoutSample: function checkCheckoutSample(sample) {
        checkFunctions['default'].onCheckCheckoutSample(sample);
      },
      openCheckOut2Modal: function openCheckOut2Modal(sample) {
        checkFunctions['default'].onOpenCheckOut2Modal(this, sample, 'Inventory');
      },
      checkoutSamples: function checkoutSamples(sample) {
        this.controller.set('disableStatusFilter', false);
        checkFunctions['default'].onCheckoutSamples(this, sample, 'Inventory');
      },
      checkOutParcel: function checkOutParcel(parcel) {
        checkFunctions['default'].onCheckOutParcel(this, parcel);
      },
      openCheckInModal: function openCheckInModal(sample) {
        this.onOpenCheckInModal(sample);
      },
      openCheckInModalBrandRefactor: function openCheckInModalBrandRefactor(sample) {
        this.onOpenCheckInModalBrandRefactor(sample);
      },
      checkInSample: function checkInSample(sample) {
        this.controller.set('disableStatusFilter', false);
        checkFunctions['default'].onCheckInSample(this, sample, 'Inventory');
      },
      cancelCheck: function cancelCheck() {
        this.controller.set('collections', this.controller.get('cloneCollections'));
        //Remove all checked samples to checkout
        var samples = new Object(Ember['default'].bulk.samples);
        Ember['default'].beginPropertyChanges();
        samples.forEach(function (sample) {
          sample.set('checked', false);
          sample.set('toCheckout', false);
        });
        Ember['default'].endPropertyChanges();

        this.controller.set('disableStatusFilter', false);
        filters['default'].undoResetFilters(this, null, true);
      },

      confirmSample: function confirmSample(sample) {
        checkFunctions['default'].onConfirmSample(this, sample);
      },
      declineSample: function declineSample(sample) {
        checkFunctions['default'].onDeclineSample(this, sample);
      },
      selectAllCheckSamples: function selectAllCheckSamples() {
        this.onSelectAllCheckSamples();
      },
      clearCheckSamples: function clearCheckSamples() {
        this.onClearCheckSamples();
      },
      clearSamples: function clearSamples() {
        filtersInventory['default'].clearSamples(this);
      },
      clearFilters: function clearFilters() {
        if (this.controller.get('dateFilter') == this.controller.get('todayDate')) {
          filtersInventory['default'].clearFilters(this);
        } else {
          this.controller.set('dateFilter', this.controller.get('todayDate'));
        }
      },
      filterByWord: function filterByWord() {
        filtersInventory['default'].filterByWord(this);
      },
      keySearch: function keySearch() {
        if (arguments.length == 2) {
          var _event = arguments[1];
          if (_event.keyCode == 13) {
            filtersInventory['default'].filterByWord(this);
          }
        }
      },
      openCheckinTransferModal: function openCheckinTransferModal(sample) {
        transferFunctions['default'].onOpenTransferModal(sample, this);
      },
      cancelCheckTransfer: function cancelCheckTransfer(sample) {
        transferFunctions['default'].onCancelCheckTransfer(sample);
      },
      checkInSampleTransfer: function checkInSampleTransfer(sample) {
        transferFunctions['default'].onChangeSampleTransferStatus(sample, this);
      },
      openCancelTransferModal: function openCancelTransferModal(sample) {
        transferFunctions['default'].onOpenTransferModal(sample, this, true);
      },

      cancelTransferSample: function cancelTransferSample(sample) {
        transferFunctions['default'].onChangeSampleTransferStatus(sample, this, true);
      },

      willTransition: function willTransition() {
        //clearInterval(this.controller.get('paginationInterval'));
        this.get('poll').clearAll();
        var controller = this.controller;
        //dateFilterFunction
        controller.removeObserver('dateFilter', controller, 'dateFilterFunction');
        //selectAllCheckObserver
        controller.removeObserver('selectAllCheck', controller, 'selectAllCheckObserver');
        filtersInventory['default'].removeObserversFromSetFilterComputeds(this);
        filtersInventory['default'].removeScrollPaginationListener();
      },
      openGenerateBarcodesModal: function openGenerateBarcodesModal() {
        this.onOpenGenerateBarcodesModal();
      },

      checkLookSamples: function checkLookSamples(look) {
        this.onCheckLookSamples(look);
      },
      printZplSamples: function printZplSamples() {
        this.onPrintZplSamples();
      },
      generateImgPdf: function generateImgPdf() {
        this.onGenerateImgPdf();
      },
      clickedCheckbox: function clickedCheckbox(item) {
        this.onClickedCheckbox(item);
      },
      cancelGeneration: function cancelGeneration() {
        this.onCancelGeneration();
      },
      printAllSamples: function printAllSamples() {
        this.onPrintAllSamples();
      },
      printAllZplSamples: function printAllZplSamples() {
        this.onPrintAllZplSamples();
      },
      closeSelectZplPrinter: function closeSelectZplPrinter() {
        this.onCloseSelectZplPrinter();
      },
      printSelectedSamples: function printSelectedSamples() {
        this.onPrintSelectedSamples();
      },
      changeBarcodeSetting: function changeBarcodeSetting(value) {
        this.onChangeBarcodeSetting(value);
      },
      selectAllInventory: function selectAllInventory() {
        this.onSelectAllInventory();
      },
      selectCompleteLook: function selectCompleteLook(lookId) {
        this.onSelectCompleteLook(lookId);
      },
      selectAllBarcodes: function selectAllBarcodes() {
        this.onSelectAllBarcodes();
      },
      openReport: function openReport() {
        Ember['default'].$('#inventory-report-modal').modal();
      },
      toggleFilters: function toggleFilters() {
        utils['default'].onToggleFilters();
      },
      querySamples: function querySamples(query, deferred) {
        utils['default'].searchSamples(query.term).then(function (response) {
          var data = Ember['default'].A([]);
          response.sample.forEach(function (element) {
            data.push({ id: element.name, text: element.name });
          });

          deferred.resolve(data);
        }, deferred.reject);
      }
    },

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      var rgstrPlan = (Ember['default'].user.get('registerPlan') || '').toLowerCase();
      if (rgstrPlan.indexOf('starter') !== -1) {
        this.replaceWith('collections.index');
      }
    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        collections: utils['default'].getCollectionsPaginated(this, 1),
        todayDate: utils['default'].getAPIJSON('statuses/getTodayDateTimezone')
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      var splittedDates = model.todayDate.split("-");
      var todayDate = new Date(splittedDates[0], splittedDates[1] - 1, splittedDates[2]);
      controller.set('todayDate', todayDate);

      controller.set('disableStatusFilter', false);

      controller.set('collectionsPaginated', model.collections);
      controller.set('pagination', model.collections.meta);

      controller.set('userLogged', Ember['default'].user);
      this.controller.set('getDataPromise', null);

      var _this = this;
      controller.set('isBrand', Ember['default'].user.get('isBrand'));

      controller.set('isLoading', true);
      controller.set('route', this);

      //brand filters
      this._setBrandFilter(controller);
      //Set sizes filters
      this._setSizeFilter(controller);

      filtersInventory['default'].setFilterComputeds(this, window, document);
      filtersInventory['default'].setScrollPaginationListener();

      //Resetting filters
      controller.set('undoBrandFilteri', null);
      filtersInventory['default'].resetFilters(this);

      controller.set('selectAllCheck', false);

      this._setStatusFilter(controller);

      this._initializaModalFilter();

      this.controller.set('generatingBarcodes', false);

      controller.dateFilterFunction = function () {
        filtersInventory['default'].clearFilters(_this);

        var date = this.get('dateFilter');

        Ember['default'].bookings.set('bookingsDate', date);
        var todayDates = controller.get('todayDate');
        if (todayDates) {
          this.set('isNotTodayDate', date.toDateString() !== todayDates.toDateString());
        } else {
          this.set('isNotTodayDate', date.toDateString() !== new Date().toDateString());
        }

        Ember['default'].bookings.set('isNotTodayDate', this.get('isNotTodayDate'));

        if (this.get('isNotTodayDate')) {
          controller.set('statusContent', controller.get('futureStatusFilter'));
        } else {
          controller.set('statusContent', controller.get('presentStatusFilter'));
        }

        //Remove isLoading
        _this.send('closeMessageModal');
        _this.controller.set('isLoading', false);

        var data = { date: utils['default'].dateToDayFormat(date), samples: utils['default'].getSamples('paginated').mapBy('id') };
        _this._getDateData(data);
      };
      utils['default'].addObserver(controller, ['dateFilter'], 'dateFilterFunction');

      // First call
      controller.propertyDidChange('brandFilteri');
      controller.set('startDate', todayDate);
      controller.set('dateFilter', todayDate);

      Ember['default'].run.later(this, function () {}, 500);

      this.controller.selectAllCheckObserver = function () {
        var selectAllCheck = _this.controller.get('selectAllCheck');
        Ember['default'].$('#checkout-step1 .blankCheckbox').each(function (i, cb) {
          if (selectAllCheck && !cb.checked || !selectAllCheck && cb.checked) {
            cb.click();
          }
        });
      };
      utils['default'].addObserver(this.controller, ['selectAllCheck'], 'selectAllCheckObserver');

      qz.security.setCertificatePromise(function (resolve, reject) {
        $.ajax("/lib/qz/qz-digital-certificate.txt").then(resolve, reject);
        if (true == false) reject("error");
      });

      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          utils['default'].postAPIJSON('qz/sign_messages', { request: toSign }).then(function (response) {
            if (response.certificate != null) {
              resolve(response.certificate);
            } else {
              reject("error");
            }
          });
        };
      });

      var zplBtnTooltipString = "To print in ZPL you need a QZ Client and a ZPL printer";
      qz.websocket.connect().then(function () {
        var printers = [];
        if (qz.websocket.isActive()) {
          Ember['default'].QZActive = true;
          // console.log("QZ CONNECTED");
          _this.controller.set('qzActive', true);
          printers = qz.printers.find()['catch'](function (e) {
            // console.log("QZ Error getting printers", e);
            return [];
          });
        } else {
          Ember['default'].QZActive = false;
          // console.log("QZ NOT CONNECTED");
          _this.controller.set('qzActive', false);
        }
        return printers;
      }).then(function (printers) {
        if (printers.length > 0) {
          // console.log(printers.length + " have been found.");
          _this.controller.set('printers', printers);
          _this.controller.set('zplPrinterBtnTooltip', "");
          for (var i = 0; i < printers.length; i++) {
            // console.log("Impresora " + i + " = " + printers[i]);
          }
        } else {
            // console.log("No printers have been found");
            _this.controller.set('printers', false);
            _this.controller.set('zplPrinterBtnTooltip', zplBtnTooltipString);
          }
      })['catch'](function (e) {
        if (e.message != "An open connection with QZ Tray already exists") {
          _this.controller.set('qzActive', false);
          _this.controller.set('printers', false);
          _this.controller.set('zplPrinterBtnTooltip', zplBtnTooltipString);
        }
      });

      $('#inventory-search-filter').on('keydown', function (e) {
        var content = $('#inventory-search-filter').val();

        if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode == 189) {
          content = content + e.key;
          $('#inventory-search-filter').val(content);
        }
      });
    },

    _initializaModalFilter: function _initializaModalFilter() {
      // inicializar filters antiguos
      filters['default'].setFilterComputeds(this, window, document);
      filters['default'].setScrollPaginationListener(this, window, document);
      this.controller.set('undoBrandFilter', null);
      filters['default'].resetFilters(this);
    },
    _getDateData: function _getDateData(data) {
      var _this = this;

      //Refresh samples list with status filter after checkout/checkin

      filtersInventory['default'].undoResetFilters(_this);

      //  _this.controller.set('isLoading', true);
      var promise = Ember['default'].$.ajax({
        url: environment['default'].APIURL + 'statuses/inventory',
        data: JSON.stringify(data),
        type: 'POST',
        contentType: 'application/json',
        success: function success(res) {
          var statuses = res.data;
          statuses.forEach(function (status) {
            var sample = _this.store.peekRecord('sample', status.sample);
            if (sample) {
              sample.set('inventoryStatus', status.status);
              sample.set('bookingCompany', status.bookingCompany);
              sample.set('bookingInventoryId', status.booking);
              sample.set('warning', status.warning);
              if (status.status === 'in transfer') {
                sample.set('recipientShowroomId', status.recipientShowroomId);
                sample.set('transferId', status.transferId);
              }
            }
          });

          _this.controller.set('isLoading', false);

          _this.controller.notifyPropertyChange('albumFilteri');

          utils['default'].lazyLoad(2000);
        }
      });

      this.controller.set('getDataPromise', promise);
    },

    _setBrandFilter: function _setBrandFilter(controller) {
      utils['default'].getBrands().then(function (response) {
        controller.set('brands', response.brand);
      });
    },

    _setSizeFilter: function _setSizeFilter(controller) {
      var _this2 = this;

      //Find any sample size and gather all into an array
      var allSizesContent = [];

      utils['default'].getAPIJSON('collections/filter/size').then(function (response) {
        response.filterSize.forEach(function (value) {
          if (value) {
            var sizeArray = { id: value, name: value, safeString: value };
            allSizesContent.push(sizeArray);
          }
        });

        allSizesContent = allSizesContent.sort(function (a, b) {
          return a.safeString.toLowerCase().localeCompare(b.safeString.toLowerCase());
        });

        _this2.controller.set('allSizesContent', allSizesContent);
      });
    },

    _setStatusFilter: function _setStatusFilter(controller) {
      controller.set('presentStatusFilter', [{ id: 'All statuses' }, { id: 'CHECK OUT TODAY' }, { id: 'CHECK IN TODAY' }, { id: 'IN' }, { id: 'LATE CHECK-IN' }, { id: 'OUT' }, { id: 'LATE CHECK-OUT' }]);

      //{id: 'IN TRANSFER'},
      controller.set('futureStatusFilter', [{ id: 'All statuses' }, { id: 'BOOKED' }, { id: 'IN' }]);

      controller.set('statusContent', controller.get('presentStatusFilter'));
    },

    onGoToScanning: function onGoToScanning() {
      this.transitionTo('barcodes.scan');
    },

    onCancelGeneration: function onCancelGeneration() {
      $('.samples-id').attr('checked', false);
      this.controller.set('generatingBarcodes', false);
    },

    onOpenCheckOutModal: function onOpenCheckOutModal(sample) {
      var _this3 = this;

      var button = Ember['default'].$('#checkout-sample-' + sample.get('id'));
      button.button('loading');
      this.controller.set('cloneCollections', this.controller.get('collections'));
      utils['default'].getBookingCollectionsForModal(this, sample.get('bookingInventoryId')).then(function (collections) {
        var sortedCollections = collections.sortBy('brand.name');
        _this3.controller.set('collections', sortedCollections);
        var samples = utils['default'].getSamples();
        samples.setEach('visible', true);
        _this3.controller.propertyDidChange('brandFilter');
        _this3.store.findRecord('booking', sample.get('bookingInventoryId')).then(function (booking) {
          button.button('reset');
          sample.set('bookingInventory', booking);
          _this3.controller.set('selectAllCheck', false);
          checkFunctions['default'].onOpenCheckOutModal(_this3, sample, 'Inventory');
        });
      }, function (fail) {
        console.log('error get collections');
      });
    },

    onOpenCheckOutModalBrandRefactor: function onOpenCheckOutModalBrandRefactor(sample) {
      var _this4 = this;

      this.controller.set('disableStatusFilter', true);

      var button = Ember['default'].$('#btn-action-checkout-sample-' + sample.get('id'));
      button.button('loading');
      this.controller.set('cloneCollections', this.controller.get('collections'));
      utils['default'].getBookingCollectionsForModal(this, sample.get('bookingInventoryId')).then(function (collections) {
        var sortedCollections = collections.sortBy('brand.name');
        _this4.controller.set('collections', sortedCollections);
        var samples = utils['default'].getSamples();
        samples.setEach('visible', true);
        _this4.controller.propertyDidChange('brandFilter');
        _this4.store.findRecord('booking', sample.get('bookingInventoryId')).then(function (booking) {
          button.button('reset');
          sample.set('bookingInventory', booking);
          _this4.controller.set('selectAllCheck', false);
          checkFunctions['default'].onOpenCheckOutModalBrandRefactor(_this4, sample, 'Inventory');
          filters['default'].resetFilters(_this4, '#checkout-step1-brand-refactor', '#checkout-step1-brand-refactor .modal-dialog');
        });
      }, function (fail) {
        console.log('error get collections');
      });
    },

    onOpenCheckInModal: function onOpenCheckInModal(sample) {
      var _this5 = this;

      var button = Ember['default'].$('#checkin-sample-' + sample.get('id'));
      button.button('loading');
      this.controller.set('cloneCollections', this.controller.get('collections'));
      utils['default'].getBookingCollectionsForModal(this, sample.get('bookingInventoryId')).then(function (collections) {
        var sortedCollections = collections.sortBy('brand.name');
        _this5.controller.set('collections', sortedCollections);
        var samples = utils['default'].getSamples();
        samples.setEach('visible', true);
        _this5.controller.propertyDidChange('brandFilter');
        _this5.store.findRecord('booking', sample.get('bookingInventoryId')).then(function (booking) {
          button.button('reset');
          sample.set('bookingInventory', booking);
          _this5.controller.set('selectAllCheck', false);
          checkFunctions['default'].onOpenCheckInModal(_this5, sample, 'Inventory');
        });
      }, function (fail) {
        console.log('error get collections');
      });
    },

    onOpenCheckInModalBrandRefactor: function onOpenCheckInModalBrandRefactor(sample) {
      var _this6 = this;

      this.controller.set('disableStatusFilter', true);

      var button = Ember['default'].$('#btn-action-checkin-sample-' + sample.get('id'));
      button.button('loading');
      this.controller.set('cloneCollections', this.controller.get('collections'));
      utils['default'].getBookingCollectionsForModal(this, sample.get('bookingInventoryId')).then(function (collections) {
        var sortedCollections = collections.sortBy('brand.name');
        _this6.controller.set('collections', sortedCollections);
        var samples = utils['default'].getSamples();
        samples.setEach('visible', true);
        _this6.store.findRecord('booking', sample.get('bookingInventoryId')).then(function (booking) {
          button.button('reset');
          sample.set('bookingInventory', booking);
          _this6.controller.set('selectAllCheck', false);
          checkFunctions['default'].onOpenCheckInModalBrandRefactor(_this6, sample, 'Inventory');
          filters['default'].resetFilters(_this6, '#checkin-brand-refactor');
        });
      }, function (fail) {
        console.log('error get collections');
      });
    },

    onClickedCheckbox: function onClickedCheckbox(item) {
      //Check if we're checking or unchecking
      var element = $('.samples-id:input[value="' + item + '"]');
      var currentState = element.prop('checked');
      var totalChecked = $('.samples-id:checked').length;
      var totalUnchecked = $('.samples-id:not(checked)').length;

      if (currentState && totalChecked == 1) {
        $('#printButton').attr('disabled', false);
      }
      if (totalChecked == 0 && !currentState) {
        $('#printButton').attr('disabled', true);
      }
      // element.attr('checked', true);
    },

    onSelectCompleteLook: function onSelectCompleteLook(lookId) {
      var all = $('.look-id-' + lookId);
      var checked = $('.look-id-' + lookId + ':checked');
      if (all.length > checked.length) {
        all.prop('checked', true);
        $('#look-id-' + lookId).prop('checked', true);
        $('#printButton').attr('disabled', false);
      } else {
        all.prop('checked', false);
        $('#look-id-' + lookId).prop('checked', false);
        if ($('.samples-id:checked').length == 0) {
          $('#printButton').attr('disabled', true);
        }
      }
    },

    onSelectAllCheckSamples: function onSelectAllCheckSamples() {
      Ember['default'].$('#checkout-step1 .blankCheckbox').each(function (i, cb) {
        if (!cb.checked) {
          cb.click();
        }
      });
    },

    onSelectAllBarcodes: function onSelectAllBarcodes() {
      Ember['default'].$('.samples-id').each(function (i, cb) {
        if (!cb.checked) {
          cb.click();
        }
      });
    },

    onChangeBarcodeSetting: function onChangeBarcodeSetting(value) {
      this.controller.set('barcodeSetting', value);
    },

    onPrintSelectedSamples: function onPrintSelectedSamples() {
      var _t = this;
      var controller = _t.controller;
      this.controller.set('printAllSamples', false);
      Ember['default'].$('#select-zpl-printer').modal();
      Ember['default'].openModal();
      controller.set('loadingSettings', true);
      if (Ember['default'].isBlank(controller.get('barcodeSetting'))) {
        utils['default'].getAPIJSON('personal_settings/for_logged_user').then(function (success) {
          var data = success.personal_settings.data;
          var barcode = data.find(function (s) {
            return s.type == "BARCODE";
          });
          controller.set('barcodeSetting', barcode.value);
          controller.set('loadingSettings', false);
        }, function (fail) {
          controller.set('loadingSettings', false);
          _t.send('openTopBar', 'Error retrieving user settings.');
        });
      } else {
        controller.set('loadingSettings', false);
      }
    },

    onClearCheckSamples: function onClearCheckSamples() {
      Ember['default'].$('#checkout-step1 .blankCheckbox').each(function (i, cb) {
        if (cb.checked) {
          cb.click();
        }
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-inventory');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-inventory');
    },

    onOpenGenerateBarcodesModal: function onOpenGenerateBarcodesModal() {
      this.controller.set('generatingBarcodes', !this.controller.get('generatingBarcodes'));
      //Not neccesary not (because we dont have Select all) (if we enable this function again, we should refactor it and
      //do not use this flow, we should use ember attr because load all collections is so much load in some accounts)
      //this.controller.set('albumPage',this.controller.get('allCollections').length);
    },

    onSelectAllInventory: function onSelectAllInventory() {
      var totalDeselected = 1;
      if ($('.samples-id:checked') && $('.samples-id:checked').length) {
        totalDeselected = $('.samples-id').length - $('.samples-id:checked').length;
      }
      $('.samples-id').prop('checked', Boolean(totalDeselected));
      $('#printButton').prop('disabled', !Boolean(totalDeselected));
    },

    onCheckLookSamples: function onCheckLookSamples(look) {
      var attr = arguments.length <= 1 || arguments[1] === undefined ? 'checked' : arguments[1];

      var samples = look.get('samples');
      var allChecked = samples.isEvery(attr);
      samples.setEach(attr, !allChecked);
    },

    onPrintAllSamples: function onPrintAllSamples() {
      var _t = this;
      var controller = _t.controller;
      utils['default'].openDefaultConfirmationModal(_t, 'Print all', 'You are about to print barcodes for *all* the samples you may have listed with the filters. If you did not use filters, barcodes will be printed for all samples in your inventory.', 'Are you sure you want to proceed?', 'Print all', 'Cancel').then(function () {
        _t.controller.set('printAllSamples', true);
        Ember['default'].$('#select-zpl-printer').modal();
        Ember['default'].openModal();
        controller.set('loadingSettings', true);
        if (Ember['default'].isBlank(controller.get('barcodeSetting'))) {
          utils['default'].getAPIJSON('personal_settings/for_logged_user').then(function (success) {
            var data = success.personal_settings.data;
            var barcode = data.find(function (s) {
              return s.type == "BARCODE";
            });
            controller.set('barcodeSetting', barcode.value);
            controller.set('loadingSettings', false);
          }, function (fail) {
            controller.set('loadingSettings', false);
            _t.send('openTopBar', 'Error retrieving user settings.');
          });
        } else {
          controller.set('loadingSettings', false);
        }
      });
    },

    onCloseSelectZplPrinter: function onCloseSelectZplPrinter() {
      this.controller.set('printAllSamples', false);
    },

    //Print all filtered samples from inventory!
    onPrintAllZplSamples: function onPrintAllZplSamples() {
      var printer = $('#printers-list').find(":selected").text();
      var size = this.controller.get('barcodeSetting') || '2x1in';
      var samplesVisible = utils['default'].getSamples('paginated'); //obtiene los que se cargaron solamente hasta ese momento
      var samplesToPrint = [];

      if (samplesVisible.length > 0) {
        samplesVisible.forEach(function (sam) {
          if (sam.visible) {
            samplesToPrint.push(sam.id);
          }
        });
        utils['default'].putAPIJSON('personal_settings/' + size);
        var samples = utils['default'].postAPIJSON('barcode/zpl/samples', { 'samples': samplesToPrint, 'size': size }).then(function (samples) {
          var zpls = [];
          for (var i = 0; i < samples.length; i++) {
            zpls.push(samples[i]['zpl']);
          }
          var config = qz.configs.create(printer);
          qz.print(config, zpls);
        });
      } else {
        this.send('openTopBar', "There is no listed samples to print");
      }
    },

    onPrintZplSamples: function onPrintZplSamples() {
      var samplesToPrint = [];
      var selectedSamples = $('.samples-id:checked').not('.samples-group');
      var printer = $('#printers-list').find(":selected").text();
      var size = this.controller.get('barcodeSetting') || '2x1in';
      if (selectedSamples.length > 0) {
        selectedSamples.each(function () {
          samplesToPrint.push($(this).val());
        });
        utils['default'].putAPIJSON('personal_settings/' + size);
        var samples = utils['default'].postAPIJSON('barcode/zpl/samples', { 'samples': samplesToPrint, 'size': size }).then(function (samples) {
          var zpls = [];
          for (var i = 0; i < samples.length; i++) {
            zpls.push(samples[i]['zpl']);
          }
          var config = qz.configs.create(printer);
          qz.print(config, zpls);
        });
      } else {
        this.send('openTopBar', "You haven't selected samples");
      }
    },

    onGenerateImgPdf: function onGenerateImgPdf() {
      var _this7 = this;

      var samplesToPrint = Ember['default'].bulk.samples;
      var arraySamplesId = [];
      samplesToPrint.forEach(function (sample) {
        arraySamplesId.push(sample.get('id'));
      });
      utils['default'].postAPIJSON('barcode/base64/pdf', { samples: arraySamplesId }).then(function (res) {
        var PDFURL = environment['default'].APIHost + '/' + res.barcode.path;
        _this7._openNewTab(PDFURL);
      });
    },

    _openNewTab: function _openNewTab(link) {
      var frm = Ember['default'].$('<form autocomplete="off"   method="get" action="' + link + '" target="_blank"></form>');
      Ember['default'].$("body").append(frm);
      frm.submit().remove();
    },

    onResetSamples: function onResetSamples() {
      Ember['default'].bulk.clearSamples();
      this.controller.notifyPropertyChange('allCollectionsShoppingList');
      this.controller.notifyPropertyChange('allCollectionsShoppingList.@each.hasVisibleAndCheckedSamples');
      this.controller.notifyPropertyChange('allCollectionsi');
      this.controller.notifyPropertyChange('brandFilteri');
      this.controller.notifyPropertyChange('albumFilteri');
      this.controller.notifyPropertyChange('statusFilteri');
      this.controller.notifyPropertyChange('itemFilteri');
    }

  });

});