define('bookalook/templates/collections/collection', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 8,
              "column": 85
            }
          },
          "moduleName": "bookalook/templates/collections/collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","section_subtitle_uirefactor");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.title",["loc",[null,[8,65],[8,80]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/collections/collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","left_col");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","btn_bal_circle add_btn");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"data-toggle","modal");
          dom.setAttribute(el2,"data-target","#new-album");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","icon");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAlbumModal"],[],["loc",[null,[14,63],[14,93]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 6
            },
            "end": {
              "line": 36,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/collections/collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","right_col");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dropdown group_actions");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","dropdown-toggle btn_bal_primary");
          dom.setAttribute(el3,"data-toggle","dropdown");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","caret");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3,"class","dropdown-menu group_actions_menu");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("Active");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("Archived");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[1] = dom.createAttrMorph(element5, 'class');
          morphs[2] = dom.createElementMorph(element5);
          morphs[3] = dom.createAttrMorph(element6, 'class');
          morphs[4] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["content","albumsStatusFilter",["loc",[null,[29,83],[29,105]]]],
          ["attribute","class",["concat",[["subexpr","unless",[["get","showArchivedAlbums",["loc",[null,[31,73],[31,91]]]],"active"],[],["loc",[null,[31,64],[31,102]]]]]]],
          ["element","action",["showArchivedAlbums",false],[],["loc",[null,[31,18],[31,56]]]],
          ["attribute","class",["concat",[["subexpr","if",[["get","showArchivedAlbums",["loc",[null,[32,68],[32,86]]]],"active"],[],["loc",[null,[32,63],[32,97]]]]]]],
          ["element","action",["showArchivedAlbums",true],[],["loc",[null,[32,18],[32,55]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 14
                },
                "end": {
                  "line": 46,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/collections/collection.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","partial",["collections/partials/album-card"],[],["loc",[null,[45,16],[45,61]]]]
            ],
            locals: ["album"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 12
              },
              "end": {
                "line": 47,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/collections/collection.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","allAlbums",["loc",[null,[44,22],[44,31]]]]],[],0,null,["loc",[null,[44,14],[46,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 12
              },
              "end": {
                "line": 49,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/collections/collection.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","text-center");
            var el2 = dom.createTextNode("No albums found.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 8
            },
            "end": {
              "line": 51,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","albums-gallery clearfix");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","allAlbums.length",["loc",[null,[43,18],[43,34]]]]],[],0,1,["loc",[null,[43,12],[49,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 8
            },
            "end": {
              "line": 58,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","empty-warn-in-big");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/new_bookings_black.svg");
          dom.setAttribute(el2,"title","Create an album!");
          dom.setAttribute(el2,"data-toggle","modal");
          dom.setAttribute(el2,"data-target","#new-album");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("You currently have no albums on this collections.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Start by creating an album in just one click.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","btn_bal_primary");
          dom.setAttribute(el2,"data-toggle","modal");
          dom.setAttribute(el2,"data-target","#new-album");
          var el3 = dom.createTextNode("Create album");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [7]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAlbumModal"],[],["loc",[null,[53,53],[53,83]]]],
          ["element","action",["openNewAlbumModal"],[],["loc",[null,[56,34],[56,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 83,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/collections/collection.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 content-for-page");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-wrapper clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","section_title_uirefactor");
        var el5 = dom.createTextNode("Albums");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","section_subtitle_uirefactor");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","menu-wrapper clearfix filters_top");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-group validated-input searcher");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","images/lupa.png");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"autocomplete","off");
        dom.setAttribute(el5,"id","no-autocomplete-search");
        dom.setAttribute(el5,"onsubmit","return false;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","albums-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [1, 1]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element8, [3]);
        var morphs = new Array(25);
        morphs[0] = dom.createMorphAt(element10,0,0);
        morphs[1] = dom.createMorphAt(element10,2,2);
        morphs[2] = dom.createMorphAt(element10,4,4);
        morphs[3] = dom.createMorphAt(element9,5,5);
        morphs[4] = dom.createMorphAt(element11,1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element11, [3, 3]),1,1);
        morphs[6] = dom.createMorphAt(element11,5,5);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [5, 1]),1,1);
        morphs[8] = dom.createMorphAt(fragment,3,3,contextualElement);
        morphs[9] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[10] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[12] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[13] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[14] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,27,27,contextualElement);
        morphs[21] = dom.createMorphAt(fragment,29,29,contextualElement);
        morphs[22] = dom.createMorphAt(fragment,31,31,contextualElement);
        morphs[23] = dom.createMorphAt(fragment,33,33,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,35,35,contextualElement);
        return morphs;
      },
      statements: [
        ["content","model.collectionTypeLabel",["loc",[null,[7,46],[7,75]]]],
        ["content","model.seasonLabel",["loc",[null,[7,76],[7,97]]]],
        ["content","model.year",["loc",[null,[7,98],[7,112]]]],
        ["block","if",[["get","model.title",["loc",[null,[8,12],[8,23]]]]],[],0,null,["loc",[null,[8,6],[8,92]]]],
        ["block","unless",[["get","model.archived",["loc",[null,[12,16],[12,30]]]]],[],1,null,["loc",[null,[12,6],[19,17]]]],
        ["inline","input",[],["type","text","id","collections-search-filter","class","search-filter","value",["subexpr","@mut",[["get","searchInput",["loc",[null,[23,89],[23,100]]]]],[],[]],"placeholder","Search..."],["loc",[null,[23,10],[23,126]]]],
        ["block","unless",[["subexpr","if-or",[["get","userLogged.isStarter",["loc",[null,[26,23],[26,43]]]],["get","userLogged.isDesigner",["loc",[null,[26,44],[26,65]]]]],[],["loc",[null,[26,16],[26,66]]]]],[],2,null,["loc",[null,[26,6],[36,17]]]],
        ["block","if",[["get","model.albums.length",["loc",[null,[41,14],[41,33]]]]],[],3,4,["loc",[null,[41,8],[58,15]]]],
        ["inline","partial",["collections/modals/new-album"],[],["loc",[null,[64,0],[64,42]]]],
        ["inline","partial",["collections/modals/new-collection"],[],["loc",[null,[65,0],[65,48]]]],
        ["inline","partial",["collections/modals/archive-collection"],[],["loc",[null,[66,0],[66,51]]]],
        ["inline","partial",["collections/modals/delete-collection"],[],["loc",[null,[67,0],[67,50]]]],
        ["inline","partial",["collections/modals/activate-collection"],[],["loc",[null,[68,0],[68,52]]]],
        ["inline","partial",["collections/modals/transfer/step1"],[],["loc",[null,[70,0],[70,47]]]],
        ["inline","partial",["collections/modals/transfer/step2"],[],["loc",[null,[71,0],[71,47]]]],
        ["inline","partial",["collections/modals/import-list"],[],["loc",[null,[73,0],[73,44]]]],
        ["inline","partial",["collections/modals/order-looks"],[],["loc",[null,[74,0],[74,44]]]],
        ["inline","partial",["collections/modals/archive-look"],[],["loc",[null,[75,0],[75,45]]]],
        ["inline","partial",["collections/modals/delete-album"],[],["loc",[null,[76,0],[76,45]]]],
        ["inline","partial",["collections/modals/delete-look"],[],["loc",[null,[77,0],[77,44]]]],
        ["inline","partial",["collections/modals/archive-album"],[],["loc",[null,[78,0],[78,46]]]],
        ["inline","partial",["collections/modals/publish-album"],[],["loc",[null,[79,0],[79,46]]]],
        ["inline","partial",["collections/modals/image-look-cropper"],[],["loc",[null,[80,0],[80,51]]]],
        ["inline","partial",["global/app-confirm-modal"],[],["loc",[null,[81,0],[81,38]]]],
        ["inline","partial",["global/delete-album-error-modal"],[],["loc",[null,[82,0],[82,45]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});