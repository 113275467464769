define('bookalook/templates/bookings/partials/look-card-loan-old', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 16
                  },
                  "end": {
                    "line": 34,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","partial",["bookings/partials/sample-row-loan-old"],[],["loc",[null,[33,18],[33,69]]]]
              ],
              locals: ["sample"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 14
                },
                "end": {
                  "line": 35,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","look.samples",["loc",[null,[32,24],[32,36]]]]],[],0,null,["loc",[null,[32,16],[34,25]]]]
            ],
            locals: ["look"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 42,
                "column": 4
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row look-card-wrapper");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","look_wrapper clearfix ");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","look-info col-xs-12 no-padding");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-xs-12 no-padding title_loan_border size-title-loan");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("strong");
            dom.setAttribute(el5,"class","pull-left");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","pull-right");
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("strong");
            dom.setAttribute(el6,"class","color_primary");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              - ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","look-table-wrapper col-xs-12 no-padding");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-xs-12 no-padding");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("table");
            dom.setAttribute(el5,"class","table tr_min_height loan-table ddt-loan-print");
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("thead");
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("tr");
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
            var el9 = dom.createTextNode("SAMPLE");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
            var el9 = dom.createComment("");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
            var el9 = dom.createTextNode("COLOR");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
            var el9 = dom.createTextNode("MATERIAL");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.setAttribute(el8,"style","width: 200px; border-bottom: 2px solid black;");
            var el9 = dom.createTextNode("DESCRIPTION");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
            var el9 = dom.createTextNode("BARCODE");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("tbody");
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1, 1]);
            var element6 = dom.childAt(element5, [1, 1]);
            var element7 = dom.childAt(element6, [3]);
            var element8 = dom.childAt(element7, [3]);
            var element9 = dom.childAt(element5, [3, 1, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
            morphs[1] = dom.createMorphAt(element7,1,1);
            morphs[2] = dom.createMorphAt(element8,0,0);
            morphs[3] = dom.createMorphAt(element8,2,2);
            morphs[4] = dom.createMorphAt(element7,5,5);
            morphs[5] = dom.createMorphAt(dom.childAt(element9, [1, 1, 3]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
            return morphs;
          },
          statements: [
            ["content","collection.brand.name",["loc",[null,[9,38],[9,63]]]],
            ["content","collection.collectionType",["loc",[null,[11,14],[11,43]]]],
            ["content","collection.season",["loc",[null,[11,75],[11,96]]]],
            ["content","collection.year",["loc",[null,[11,97],[11,116]]]],
            ["content","album.albumType",["loc",[null,[12,16],[12,35]]]],
            ["inline","if",[["subexpr","if-equal",[["get","booking.owner.company",["loc",[null,[23,77],[23,98]]]],"1 GRANARY"],[],["loc",[null,[23,67],[23,111]]]],"DESIGNER","STYLE NUMBER"],[],["loc",[null,[23,62],[23,139]]]],
            ["block","each",[["get","album.looks",["loc",[null,[31,22],[31,33]]]]],[],0,null,["loc",[null,[31,14],[35,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 74,
                          "column": 22
                        },
                        "end": {
                          "line": 76,
                          "column": 22
                        }
                      },
                      "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","partial",["bookings/partials/sample-row-loan-old"],[],["loc",[null,[75,24],[75,75]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 73,
                        "column": 20
                      },
                      "end": {
                        "line": 77,
                        "column": 20
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","sample.checked",["loc",[null,[74,28],[74,42]]]]],[],0,null,["loc",[null,[74,22],[76,29]]]]
                  ],
                  locals: ["sample"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 72,
                      "column": 18
                    },
                    "end": {
                      "line": 78,
                      "column": 18
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","look.samples",["loc",[null,[73,28],[73,40]]]]],[],0,null,["loc",[null,[73,20],[77,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 16
                  },
                  "end": {
                    "line": 79,
                    "column": 15
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","look.hasCheckedSamples",["loc",[null,[72,24],[72,46]]]]],[],0,null,["loc",[null,[72,18],[78,25]]]]
              ],
              locals: ["look"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 6
                },
                "end": {
                  "line": 86,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","row look-card-wrapper");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","look_wrapper clearfix ");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","look-info col-xs-12 no-padding");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col-xs-12 no-padding title_loan_border size-title-loan");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("strong");
              dom.setAttribute(el5,"class","pull-left");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","pull-right");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("  ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("strong");
              dom.setAttribute(el6,"class","color_primary");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode(" ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                - ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","look-table-wrapper col-xs-12 no-padding");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col-xs-12 no-padding");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("table");
              dom.setAttribute(el5,"class","table tr_min_height loan-table ddt-loan-print");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("thead");
              var el7 = dom.createTextNode("\n                  ");
              dom.appendChild(el6, el7);
              var el7 = dom.createElement("tr");
              var el8 = dom.createTextNode("\n                    ");
              dom.appendChild(el7, el8);
              var el8 = dom.createElement("th");
              dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
              var el9 = dom.createTextNode("SAMPLE");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode("\n                    ");
              dom.appendChild(el7, el8);
              var el8 = dom.createElement("th");
              dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
              var el9 = dom.createComment("");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode("\n                    ");
              dom.appendChild(el7, el8);
              var el8 = dom.createElement("th");
              dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
              var el9 = dom.createTextNode("COLOR");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode("\n                    ");
              dom.appendChild(el7, el8);
              var el8 = dom.createElement("th");
              dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
              var el9 = dom.createTextNode("MATERIAL");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode("\n                    ");
              dom.appendChild(el7, el8);
              var el8 = dom.createElement("th");
              dom.setAttribute(el8,"style","width: 200px; border-bottom: 2px solid black;");
              var el9 = dom.createTextNode("DESCRIPTION");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode("\n                    ");
              dom.appendChild(el7, el8);
              var el8 = dom.createElement("th");
              dom.setAttribute(el8,"style","border-bottom: 2px solid black;");
              var el9 = dom.createTextNode("BARCODE");
              dom.appendChild(el8, el9);
              dom.appendChild(el7, el8);
              var el8 = dom.createTextNode("\n                  ");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("\n                ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("tbody");
              var el7 = dom.createTextNode("\n");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              var el7 = dom.createTextNode("              ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n            ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n          ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var element1 = dom.childAt(element0, [1, 1]);
              var element2 = dom.childAt(element1, [3]);
              var element3 = dom.childAt(element2, [3]);
              var element4 = dom.childAt(element0, [3, 1, 1]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              morphs[1] = dom.createMorphAt(element2,1,1);
              morphs[2] = dom.createMorphAt(element3,0,0);
              morphs[3] = dom.createMorphAt(element3,2,2);
              morphs[4] = dom.createMorphAt(element2,5,5);
              morphs[5] = dom.createMorphAt(dom.childAt(element4, [1, 1, 3]),0,0);
              morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
              return morphs;
            },
            statements: [
              ["content","collection.brand.name",["loc",[null,[49,40],[49,65]]]],
              ["content","collection.collectionTypeLabel",["loc",[null,[51,16],[51,50]]]],
              ["content","collection.seasonLabel",["loc",[null,[51,82],[51,108]]]],
              ["content","collection.year",["loc",[null,[51,109],[51,128]]]],
              ["content","album.albumTypeLabel",["loc",[null,[52,18],[52,42]]]],
              ["inline","if",[["subexpr","if-equal",[["get","userLogged.company",["loc",[null,[63,79],[63,97]]]],"1 GRANARY"],[],["loc",[null,[63,69],[63,110]]]],"DESIGNER","STYLE NUMBER"],[],["loc",[null,[63,64],[63,138]]]],
              ["block","each",[["get","album.looks",["loc",[null,[71,24],[71,35]]]]],[],0,null,["loc",[null,[71,16],[79,24]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 4
              },
              "end": {
                "line": 87,
                "column": 4
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","album.hasCheckedSamples",["loc",[null,[44,12],[44,35]]]]],[],0,null,["loc",[null,[44,6],[86,13]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 89,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  \n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("   \n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","userLogged.isMP",["loc",[null,[4,10],[4,25]]]]],[],0,1,["loc",[null,[4,4],[87,11]]]]
        ],
        locals: ["album"],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 90,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/partials/look-card-loan-old.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Look card | Checkout step 1 ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","each",[["get","collection.albums",["loc",[null,[2,10],[2,27]]]]],[],0,null,["loc",[null,[2,2],[89,11]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});