define('bookalook/templates/search/overlay/search-suggestion', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 2
            },
            "end": {
              "line": 34,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("center");
          dom.setAttribute(el1,"class","margin-top");
          var el2 = dom.createElement("h2");
          var el3 = dom.createTextNode("Searching...");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 2
                },
                "end": {
                  "line": 39,
                  "column": 2
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("center");
              dom.setAttribute(el1,"class","margin-top margin-bottom");
              var el2 = dom.createElement("h2");
              var el3 = dom.createTextNode("Type at least 3 chars...");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 44,
                            "column": 2
                          },
                          "end": {
                            "line": 46,
                            "column": 2
                          }
                        },
                        "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("center");
                        dom.setAttribute(el1,"class","margin-top");
                        var el2 = dom.createElement("h2");
                        var el3 = dom.createTextNode("NO RESULTS FOUND");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 43,
                          "column": 2
                        },
                        "end": {
                          "line": 47,
                          "column": 2
                        }
                      },
                      "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","unless",[["get","isDebouncing",["loc",[null,[44,12],[44,24]]]]],[],0,null,["loc",[null,[44,2],[46,13]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 42,
                        "column": 2
                      },
                      "end": {
                        "line": 48,
                        "column": 2
                      }
                    },
                    "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","unless",[["get","hashtagsFound.items",["loc",[null,[43,12],[43,31]]]]],[],0,null,["loc",[null,[43,2],[47,13]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 41,
                      "column": 2
                    },
                    "end": {
                      "line": 49,
                      "column": 2
                    }
                  },
                  "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","unless",[["get","usersFound.items",["loc",[null,[42,12],[42,28]]]]],[],0,null,["loc",[null,[42,2],[48,13]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 2
                  },
                  "end": {
                    "line": 50,
                    "column": 2
                  }
                },
                "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","unless",[["get","companiesFound.items",["loc",[null,[41,12],[41,32]]]]],[],0,null,["loc",[null,[41,2],[49,13]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 2
                },
                "end": {
                  "line": 51,
                  "column": 2
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","looksFound.items",["loc",[null,[40,12],[40,28]]]]],[],0,null,["loc",[null,[40,2],[50,13]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 52,
                "column": 2
              }
            },
            "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","minimumSearchLength",["loc",[null,[37,12],[37,31]]]]],[],0,1,["loc",[null,[37,2],[51,13]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 10
                },
                "end": {
                  "line": 61,
                  "column": 10
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","pointer");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","black-span-hashtag");
              var el3 = dom.createTextNode("#");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element18);
              morphs[1] = dom.createMorphAt(dom.childAt(element18, [0]),1,1);
              return morphs;
            },
            statements: [
              ["element","action",["goToSearch",["subexpr","concat",["#",["get","hashtag.name",["loc",[null,[60,48],[60,60]]]]],[],["loc",[null,[60,36],[60,61]]]],"looks"],[],["loc",[null,[60,14],[60,71]]]],
              ["content","hashtag.name",["loc",[null,[60,119],[60,135]]]]
            ],
            locals: ["hashtag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 2
              },
              "end": {
                "line": 66,
                "column": 2
              }
            },
            "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row text-center");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12 ");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3,"class","list-inline");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","hashtagsFound.items",["loc",[null,[59,18],[59,37]]]]],[],0,null,["loc",[null,[59,10],[61,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 76,
                    "column": 12
                  },
                  "end": {
                    "line": 78,
                    "column": 12
                  }
                },
                "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","../images/lock-closed.png");
                dom.setAttribute(el1,"class","img_album_search pull-right");
                dom.setAttribute(el1,"title","Private album, click here to request access");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 8
                },
                "end": {
                  "line": 94,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-xs-4 align-left no-margin");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row box_search_looks pointer");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col-xs-5 no-padding");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"class","img_item");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col-xs-7");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","text_item");
              var el5 = dom.createTextNode("\n                  ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("p");
              var el6 = dom.createElement("strong");
              dom.setAttribute(el6,"class","black-span");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                  ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","text-uppercase");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                  ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","text-uppercase");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                  ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","text-uppercase");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n\n                ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var element14 = dom.childAt(element13, [3]);
              var element15 = dom.childAt(element14, [1, 1]);
              var element16 = dom.childAt(element14, [3, 1]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(element13,1,1);
              morphs[1] = dom.createElementMorph(element14);
              morphs[2] = dom.createAttrMorph(element15, 'src');
              morphs[3] = dom.createMorphAt(dom.childAt(element16, [1, 0]),0,0);
              morphs[4] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
              morphs[5] = dom.createMorphAt(dom.childAt(element16, [5]),0,0);
              morphs[6] = dom.createMorphAt(dom.childAt(element16, [7]),0,0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","look.isPrivate",["loc",[null,[76,18],[76,32]]]]],[],0,null,["loc",[null,[76,12],[78,19]]]],
              ["element","action",["openModalOverviewSample",["get","look",["loc",[null,[79,52],[79,56]]]]],[],["loc",[null,[79,17],[79,58]]]],
              ["attribute","src",["concat",[["get","look.avatar",["loc",[null,[81,28],[81,39]]]]]]],
              ["content","look.album.collection.brand.name",["loc",[null,[85,48],[85,84]]]],
              ["content","look.department",["loc",[null,[86,46],[86,65]]]],
              ["content","look.album.collection.season",["loc",[null,[87,46],[87,78]]]],
              ["content","look.album.collection.year",["loc",[null,[88,46],[88,76]]]]
            ],
            locals: ["look"],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 8
                },
                "end": {
                  "line": 99,
                  "column": 141
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","pointer see_all");
              var el2 = dom.createTextNode("SEE ALL (");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(")");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element12);
              morphs[1] = dom.createMorphAt(element12,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["goToSearch",["get","searchCriteria",["loc",[null,[99,52],[99,66]]]],"looks"],[],["loc",[null,[99,30],[99,76]]]],
              ["content","looksFound.total_count",["loc",[null,[99,110],[99,136]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 0
              },
              "end": {
                "line": 104,
                "column": 0
              }
            },
            "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","search_suggestion_criteria");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row text-center");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 no-margin no-padding");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element17, [1, 1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element17, [3, 1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","looksFound.items",["loc",[null,[74,16],[74,32]]]]],[],0,null,["loc",[null,[74,8],[94,17]]]],
            ["block","if",[["get","seeAllLooks",["loc",[null,[99,14],[99,25]]]]],[],1,null,["loc",[null,[99,8],[99,148]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 10
                  },
                  "end": {
                    "line": 127,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col-xs-3 text-center box_search_users no-padding");
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 no-padding");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                var el4 = dom.createElement("img");
                dom.setAttribute(el4,"class","user_image_colour");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3,"class","black-span");
                var el4 = dom.createElement("strong");
                dom.setAttribute(el4,"class","black-span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode(" ");
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("br");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n              ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","black-span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("br");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n              ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","black-span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var element8 = dom.childAt(element7, [1, 1, 0]);
                var element9 = dom.childAt(element7, [3, 1]);
                var element10 = dom.childAt(element9, [0]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element8, 'src');
                morphs[1] = dom.createMorphAt(element10,0,0);
                morphs[2] = dom.createMorphAt(element10,2,2);
                morphs[3] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
                morphs[4] = dom.createMorphAt(dom.childAt(element9, [6]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","src",["concat",[["get","user.avatar",["loc",[null,[118,31],[118,42]]]]]]],
                ["content","user.name",["loc",[null,[121,63],[121,76]]]],
                ["content","user.surname",["loc",[null,[121,77],[121,93]]]],
                ["content","user.jobTitle",["loc",[null,[122,39],[122,56]]]],
                ["content","user.company",["loc",[null,[123,39],[123,55]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 113,
                  "column": 10
                },
                "end": {
                  "line": 128,
                  "column": 10
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["profile.user-profile",["get","user.id",["loc",[null,[114,44],[114,51]]]]],[],0,null,["loc",[null,[114,10],[127,22]]]]
            ],
            locals: ["user"],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 132,
                  "column": 12
                },
                "end": {
                  "line": 132,
                  "column": 145
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","pointer see_all");
              var el2 = dom.createTextNode("SEE ALL (");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(")");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element6);
              morphs[1] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["goToSearch",["get","searchCriteria",["loc",[null,[132,56],[132,70]]]],"users"],[],["loc",[null,[132,34],[132,80]]]],
              ["content","usersFound.total_count",["loc",[null,[132,114],[132,140]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 2
              },
              "end": {
                "line": 138,
                "column": 2
              }
            },
            "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","search_suggestion_criteria");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","row");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","row text-center no-margin no-padding");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"clas","col-xs-12");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element11, [3, 1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","usersFound.items",["loc",[null,[113,18],[113,34]]]]],[],0,null,["loc",[null,[113,10],[128,19]]]],
            ["block","if",[["get","seeAllUsers",["loc",[null,[132,18],[132,29]]]]],[],1,null,["loc",[null,[132,12],[132,152]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 149,
                    "column": 10
                  },
                  "end": {
                    "line": 158,
                    "column": 10
                  }
                },
                "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col-xs-3 text-center no-padding div_brand");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 no-padding");
                var el3 = dom.createTextNode("\n              	");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3,"class","brands_image");
                var el4 = dom.createElement("a");
                var el5 = dom.createTextNode("  ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("img");
                dom.setAttribute(el5,"class","suggestion_image");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 no-padding");
                var el3 = dom.createTextNode("\n              	");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("strong");
                dom.setAttribute(el3,"class","text-uppercase black-span pull-left");
                var el4 = dom.createElement("a");
                var el5 = dom.createTextNode(" ");
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [1, 1, 0]);
                var element3 = dom.childAt(element2, [1]);
                var element4 = dom.childAt(element1, [3, 1, 0]);
                var morphs = new Array(4);
                morphs[0] = dom.createElementMorph(element2);
                morphs[1] = dom.createAttrMorph(element3, 'src');
                morphs[2] = dom.createElementMorph(element4);
                morphs[3] = dom.createMorphAt(element4,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["closeSearcher"],[],["loc",[null,[152,42],[152,68]]]],
                ["attribute","src",["concat",[["get","company.avatar",["loc",[null,[152,83],[152,97]]]]]]],
                ["element","action",["closeSearcher"],[],["loc",[null,[155,70],[155,96]]]],
                ["content","company.name",["loc",[null,[155,98],[155,114]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 148,
                  "column": 10
                },
                "end": {
                  "line": 159,
                  "column": 10
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",[["subexpr","if",[["subexpr","if-equal",[["get","company.type",["loc",[null,[149,35],[149,47]]]],"brand"],[],["loc",[null,[149,25],[149,56]]]],"profile.brand-profile","profile.agency-profile"],[],["loc",[null,[149,21],[149,106]]]],["get","company.id",["loc",[null,[149,107],[149,117]]]]],[],0,null,["loc",[null,[149,10],[158,22]]]]
            ],
            locals: ["company"],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 164,
                  "column": 14
                },
                "end": {
                  "line": 164,
                  "column": 160
                }
              },
              "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","pointer see_all");
              var el2 = dom.createTextNode("SEE ALL (");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(")");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["goToSearch",["get","searchCriteria",["loc",[null,[164,62],[164,76]]]],"companies"],[],["loc",[null,[164,40],[164,90]]]],
              ["content","companiesFound.total_count",["loc",[null,[164,125],[164,155]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 2
              },
              "end": {
                "line": 169,
                "column": 2
              }
            },
            "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","search_suggestion_criteria");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row div_companies");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 no-padding");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row text-center no-margin no-padding");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"clas","col-xs-12 ");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","companiesFound.items",["loc",[null,[148,18],[148,38]]]]],[],0,null,["loc",[null,[148,10],[159,19]]]],
            ["block","if",[["get","seeAllCompanies",["loc",[null,[164,20],[164,35]]]]],[],1,null,["loc",[null,[164,14],[164,167]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 2
            },
            "end": {
              "line": 175,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" HASHTAGS ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" / ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" SAMPLES ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" / ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" USERS ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" / ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" BRANDS / AGENCIES ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" / ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("NO RESULTS ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,11,11,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,17,17,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,23,23,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","headerSearchInput.length",["loc",[null,[36,8],[36,32]]]]],[],0,null,["loc",[null,[36,2],[52,9]]]],
          ["block","if",[["get","hashtagsFound.items",["loc",[null,[55,8],[55,27]]]]],[],1,null,["loc",[null,[55,2],[66,9]]]],
          ["block","if",[["get","looksFound.items",["loc",[null,[70,6],[70,22]]]]],[],2,null,["loc",[null,[70,0],[104,7]]]],
          ["block","if",[["get","usersFound.items",["loc",[null,[109,8],[109,24]]]]],[],3,null,["loc",[null,[109,2],[138,9]]]],
          ["block","if",[["get","companiesFound.items",["loc",[null,[144,8],[144,28]]]]],[],4,null,["loc",[null,[144,2],[169,9]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 177,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/search/overlay/search-suggestion.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row search_div dropdown");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","left");
        dom.setAttribute(el3,"id","search_criteria");
        dom.setAttribute(el3,"data-toggle","dropdown");
        dom.setAttribute(el3,"aria-haspopup","true");
        dom.setAttribute(el3,"aria-expanded","true");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","search_selected_icon");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"class","img_logo");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","../images/arrow-down.png");
        dom.setAttribute(el4,"class","fa_caret_down pointer");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","dropdown-menu-pink col-xs-12");
        dom.setAttribute(el3,"aria-labelledby","search_criteria");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","search_dropdown_icon");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","../images/search/pin.png");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("EVERYTHING\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","search_dropdown_icon");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","../images/search/hanger.png");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("SAMPLES\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","search_dropdown_icon");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","../images/search/hashtag.png");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("HASHTAGS\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","search_dropdown_icon");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","../images/search/brands.png");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("BRANDS & AGENCIES\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","search_dropdown_icon");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","../images/search/people.png");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("PEOPLE\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-10 left_input");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pull-right pointer");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","../images/close.svg");
        dom.setAttribute(el4,"class","img_close");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element19 = dom.childAt(fragment, [0]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element20, [1, 1, 0]);
        var element22 = dom.childAt(element20, [3]);
        var element23 = dom.childAt(element22, [1]);
        var element24 = dom.childAt(element22, [3]);
        var element25 = dom.childAt(element22, [5]);
        var element26 = dom.childAt(element22, [7]);
        var element27 = dom.childAt(element22, [9]);
        var element28 = dom.childAt(element20, [7, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element21, 'src');
        morphs[1] = dom.createElementMorph(element23);
        morphs[2] = dom.createElementMorph(element24);
        morphs[3] = dom.createElementMorph(element25);
        morphs[4] = dom.createElementMorph(element26);
        morphs[5] = dom.createElementMorph(element27);
        morphs[6] = dom.createMorphAt(dom.childAt(element20, [5]),1,1);
        morphs[7] = dom.createElementMorph(element28);
        morphs[8] = dom.createMorphAt(element19,3,3);
        return morphs;
      },
      statements: [
        ["attribute","src",["get","searchSelectedIcon",["loc",[null,[4,51],[4,69]]]]],
        ["element","action",["searchOption","all"],[],["loc",[null,[8,10],[8,41]]]],
        ["element","action",["searchOption","looks"],[],["loc",[null,[11,10],[11,43]]]],
        ["element","action",["searchOption","hashtags"],[],["loc",[null,[14,10],[14,46]]]],
        ["element","action",["searchOption","companies"],[],["loc",[null,[17,10],[17,47]]]],
        ["element","action",["searchOption","users"],[],["loc",[null,[20,10],[20,43]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","headerSearchInput",["loc",[null,[25,32],[25,49]]]]],[],[]],"placeholder",["subexpr","@mut",[["get","searchPlaceholder",["loc",[null,[25,62],[25,79]]]]],[],[]],"id","searchInput","class","col-xs-12 input_search no-padding"],["loc",[null,[25,6],[25,140]]]],
        ["element","action",["closeSearcher"],[],["loc",[null,[28,55],[28,81]]]],
        ["block","if",[["get","isOverlayLoading",["loc",[null,[32,8],[32,24]]]]],[],0,1,["loc",[null,[32,2],[175,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});