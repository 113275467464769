define('bookalook/tests/routes/contacts-lists/functions.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/contacts-lists/functions.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/contacts-lists/functions.js should pass jshint.\nroutes/contacts-lists/functions.js: line 356, col 28, [\'contact\'] is better written in dot notation.\nroutes/contacts-lists/functions.js: line 731, col 72, Missing semicolon.\n\n2 errors');
  });

});