define('bookalook/tests/components/mini-calendar.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components/mini-calendar.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'components/mini-calendar.js should pass jshint.\ncomponents/mini-calendar.js: line 187, col 9, \'bookings\' is already defined.\ncomponents/mini-calendar.js: line 271, col 15, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\ncomponents/mini-calendar.js: line 330, col 7, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\ncomponents/mini-calendar.js: line 335, col 5, Unreachable \'if\' after \'return\'.\ncomponents/mini-calendar.js: line 337, col 7, Misleading line break before \'||\'; readers may interpret this as an expression boundary.\ncomponents/mini-calendar.js: line 339, col 7, Misleading line break before \'||\'; readers may interpret this as an expression boundary.\ncomponents/mini-calendar.js: line 342, col 9, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\n\n7 errors');
  });

});