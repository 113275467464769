define('bookalook/helpers/is-equal', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var o2 = params[1];

    return parseInt(o1.id, 10) === parseInt(o2.id, 10);
  });

});