define('bookalook/routes/members/index', ['exports', 'ember', 'bookalook/routes/contacts-lists/functions', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, contactFunctions, config, utils, UserValidatorMixin) {

  'use strict';


  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      checkMp: function checkMp(mp) {
        this.onCheckMp(mp);
      },
      cancelAddMp: function cancelAddMp() {
        this.onCancelAddMp();
      },
      openUrl: function openUrl(url) {
        var tobeFixed = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
        var mp = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
        this.onOpenUrl(url, tobeFixed, mp);
      },
      addContacts: function addContacts() {
        this.onAddContacts();
      },
      saveContactFromUser: function saveContactFromUser() {
        this.onSaveContactFromUser();
      }

    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        contactLists: utils['default'].getContactLists(this),
        mpUsers: utils['default'].getAPIJSON('users/last_mps')
      });
    },

    setupController: function setupController(controller, model) {
      var ispr = Ember['default'].user.get('type');
      controller.setProperties(model);
      controller.set('userLogged', Ember['default'].user);
      var contactLists = model.contactLists;
      var mpUsers = model.mpUsers;
      if (mpUsers.length > 0) {
        mpUsers[mpUsers.length - 1].last = true;
      }
      this.controller.set('searchInput', '');
      this.controller.set('jobFilter', '');
      this.controller.set('countryFilter', 'All countries');
      this.controller.set('companyFilter', '');
      this.controller.set('websiteFilter', '');
      this.controller.set('facebookFilter', '');
      this.controller.set('instagramFilter', '');
      this.controller.set('sortByFilter', '');
      this._super(controller, model);
      contactFunctions['default'].setupController(controller, contactLists);

      controller.set('setbuttonfree', '');

      //contacts sum
      controller.contactsUnique = contactFunctions['default'].contactsUnique();

      contactFunctions['default'].setComputedNewContact(controller);

      //Get user plan configuration for contacts
      if (ispr == "PR") {
        utils['default'].getAPIJSON('plans/contactsAllowedForPlanUser').then(function (res) {
          controller.set('contactsAllowedForPlanUser', res);
          if (res == false) {
            controller.set('setbuttonfree', '1');
          }
        });
      }

      controller.visibleMps = Ember['default'].computed('mpUsers', 'searchInput', 'jobFilter', 'countryFilter', 'companyFilter', 'websiteFilter', 'facebookFilter', 'instagramFilter', function () {
        var searchInput = controller.get('searchInput').toLowerCase();
        var mps = this.get('mpUsers');
        mps = mps.filter(function (c) {
          var visible = true;
          //Filter by search input
          if (searchInput) {
            var search = c.name + ' ' + c.surname + ' ' + c.country + ' ' + c.jobTitle + ' ' + c.company;
            if (c.instagram != null) {
              search = search + ' ' + c.instagram;
            }
            if (c.website != null) {
              search = search + ' ' + c.website;
            }
            if (c.facebook != null) {
              search = search + ' ' + c.facebook;
            }
            if (c.country != null && c.country != "" && c.country != "None") {
              search = search + ' ' + c.country;
            }
            visible = search.toLowerCase().indexOf(searchInput) >= 0;
          }
          if (!visible) {
            return false;
          } else {
            var found = true;
            //Filter by instagram
            if (controller.get('instagramFilter') && found) {
              found = c.instagram != null && c.instagram != "";
            }
            //Filter by website
            if (controller.get('websiteFilter') && found) {
              found = c.website != null && c.website != "";
            }
            //Filter by facebook
            if (controller.get('facebookFilter') && found) {
              found = c.facebook != null && c.facebook != "";
            }
            //Filter by job
            if (controller.get('jobFilter') !== "" && found) {
              var jobFilter = controller.get('jobFilter');
              var jobTitle = c.jobTitle;
              found = jobTitle.toLowerCase().indexOf(jobFilter.toLowerCase()) >= 0;
            }
            //Filter by company
            if (controller.get('companyFilter') !== "" && found) {
              var companyFilter = controller.get('companyFilter');
              var company = c.company;
              found = company.toLowerCase().indexOf(companyFilter.toLowerCase()) >= 0;
            }
            //Filter by country
            var countryFilter = controller.get('countryFilter');
            if (controller.get('countryFilter') !== "All countries" && found) {
              var company = c.country;
              if (company != null) found = company.toLowerCase().indexOf(controller.get('countryFilter').toLowerCase()) >= 0;else found = false;
            }
            return found;
          }
        });
        return mps;
      });

      controller.sortByFilterObserver = function () {
        var mps = controller.get('mpUsers');
        var sortFilter = controller.get('sortByFilter');
        controller.set('mpUsers', mps.sortBy(sortFilter));
      };

      utils['default'].addObserver(controller, ['sortByFilter'], 'sortByFilterObserver');

      controller.set('sortList', [{ id: 'dateCreated', text: 'Lastest' }, { id: "safeString", text: 'Full name' }, { id: "surname", text: 'Surnames' }, { id: "company", text: 'Company/Job Title' }, { id: "country", text: 'Country' }]);
      var firstCountriesOptions = [{ id: '', name: 'All countries' }, { id: "None", name: 'None' }];
      //To duplicate the var and do not change Ember.countries
      var countries = Ember['default'].countries.copy();
      controller.set('countriesList', countries.unshiftObjects(firstCountriesOptions));

      controller.checkedMps = Ember['default'].computed.filterBy('mpUsers', 'checked', true);
      controller.bulkDisabled = Ember['default'].computed.empty('checkedMps');

      controller.set('addContactSelectedLists', []);

      controller.addContactSelectedListsObserver = function () {
        var lists = controller.get('addContactSelectedLists');
        if (!Ember['default'].isNone(lists) && lists.get('length') > 0) {
          controller.set('listNotSelected', false);
        } else {
          controller.set('listNotSelected', true);
        }
      };

      utils['default'].addObserver(controller, ['addContactSelectedLists.length'], 'addContactSelectedListsObserver');
    },

    onDuplicateLists: function onDuplicateLists() {
      var _this = this;
      var lists = this.controller.get('checkedLists');
      this.send('openMessageModal', 'Duplicating ' + lists.get('length') + ' lists. Please Wait.');

      var promises = [];
      lists.forEach(function (list) {
        var promise = _this.duplicateList(list);
        promises.push(promise);
      });

      // When all lists are duplicated close modal
      Ember['default'].$.when.apply(window, promises).then(function () {
        _this.send('closeMessageModal');
      });
    },

    onCheckMp: function onCheckMp(mp) {
      if (mp.disableCheck) {
        Ember['default'].set(mp, 'disableCheck', false);
      } else {
        Ember['default'].set(mp, 'checked', !mp.checked);
      }
    },

    onCancelAddMp: function onCancelAddMp(contactToAdd) {
      Ember['default'].$('#add-mp').modal('hide');
      this.controller.set('addContactSelectedUser', null);
      this.controller.set('addContactSelectedLists', []);
    },

    onOpenUrl: function onOpenUrl(url) {
      var tobeFixed = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
      var mp = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      var generalFormatUrl = url;
      if (url.indexOf('//') == -1) {
        generalFormatUrl = "http://" + url;
      }
      window.open(generalFormatUrl, '_blank');

      //Disable check from parent in member card
      if (mp) {
        Ember['default'].set(mp, 'disableCheck', true);
      }
    },

    onAddContacts: function onAddContacts(url) {
      var users = this.controller.get('checkedMps');
      this.controller.set('multipleSelected', this.controller.get('checkedMps.length') > 1);
      //This var is only to reuse contactFunctions.setCanAddContactObserver function
      this.controller.set('addContactSelectedUser', users);
      this.controller.set('canAddContact', false);
      this.controller.set('addContactSelectedLists', []);
      contactFunctions['default'].setCanAddContactObserver(this.controller);

      Ember['default'].$('#add-mp').modal();
      Ember['default'].openModal();
    },

    onSaveContactFromUser: function onSaveContactFromUser() {
      var _this2 = this;

      var route = this;
      //Check limits
      var actionButton = Ember['default'].$('#add-mp-members-btn');
      actionButton.button('loading');
      var contactsAllowedForPlanUser = route.controller.get('contactsAllowedForPlanUser');
      if (contactsAllowedForPlanUser != true) {
        Ember['default'].$('#add-mp').modal('hide');
        actionButton.button('reset');
        contactFunctions['default'].onOpenNoAllowedContactsModal();
      } else {
        (function () {
          Ember['default'].$('#add-mp').modal('hide');
          actionButton.button('reset');

          route.send('openMessageModal', 'Adding contact... Please wait.');

          var checkedMps = _this2.controller.get('checkedMps');
          var checkedNumber = checkedMps.length;
          var promises = [];
          var deferred = Ember['default'].$.Deferred();

          var _loop = function (i) {
            var user = checkedMps[i];
            var promise = route.store.find('contact', { user: user.id }).then(function (data) {
              var contact = data.objectAt(0);
              var selectedLists = route.controller.get('addContactSelectedLists');
              var promise = null;
              if (contact) {
                contact.get('contactsLists').addObjects(selectedLists);
                return contact.save();
              } else {
                return route.store.find('user', user.id).then(function (userDB) {
                  var createdContact = route.store.createRecord('contact', {
                    name: user.name,
                    surname: user.surname,
                    jobTitle: user.jobTitle,
                    email: user.email,
                    company: user.company,
                    user: userDB
                  });
                  createdContact.get('contactsLists').addObjects(selectedLists);
                  return createdContact.save();
                });
              }
            });
            promises.push(promise);
          };

          for (var i = 0; i < checkedNumber; i++) {
            _loop(i);
          }
          Ember['default'].RSVP.all(promises).then(function () {
            deferred.resolve();
          });
          deferred.done(function () {
            //route.send('openTopBar', checkedNumber +' members has been added to contacts.');
            route.send('openPositiveTopBar');
            var mpUsers = route.controller.get('mpUsers');
            for (var x = 0; x < mpUsers.length; x++) {
              Ember['default'].set(mpUsers[x], 'checked', false);
            }

            route.refresh().then(function () {
              route.send('closeMessageModal');
            });
          });
        })();
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-members');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-members');
    }
  });

});