define('bookalook/helpers/if-last-element', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //Index Element
    var o1 = params[0];

    //Array Length
    var o2 = params[1];

    if (Ember['default'].isNone(o1) || Ember['default'].isNone(o2)) {
      return false;
    }
    return o1 + 1 >= o2;
  });

});