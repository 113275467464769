define('bookalook/routes/profile/album-profile', ['exports', 'bookalook/utils', 'ember', 'bookalook/mixins/user-validator-mixin'], function (exports, utils, Ember, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      requestAlbumPermission: function requestAlbumPermission(albumID) {
        this.onRequestAlbumPermission(albumID);
      },
      openModalOverviewSampleIn: function openModalOverviewSampleIn(sample, isPrivate) {
        this.onOpenModalOverviewSampleIn(sample, isPrivate);
      },
      showPreviousLook: function showPreviousLook(look) {
        this.onShowNextLook(look);
      },
      showNextLook: function showNextLook(look) {
        this.onShowNextLook(look);
      }
    },

    model: function model(params) {
      //return utils.getAPIJSON('albums/' + params.id);

      return this.store.findRecord('album', params.id);
    },

    setupController: function setupController(controller, model) {
      controller.set("album", model.album);
      //this._super(controller, model);
      //utils.getAPIJSON('albums/' + model.id + '/profile_action').then(function (res) {
      //   controller.setProperties({
      //     album: res
      //   });
      // });

      //Adding avatars to the model

      //console.log(model.looks);

      model.reload();

      //console.log(model.avatars);

      //utils.getAPIJSON('albums/' + model.id + "/avatars").then((res) => {
      //  controller.set("avatars", res.look);
      //  console.log(res.album.looks);
      //});

      //Calculating privacy album for mpUser

      utils['default'].getAPIJSON('albums/' + model.id + '/get_privacy').then(function (res) {
        model.set('isPrivate', res.album);
        controller.set('album', model);
      });

      //controller.set("album", model);
    },
    /**
     *
     * @param sample
     * @param isPrivate
     * @param direction
     * @returns {*}
     */
    checkDefaultImage: function checkDefaultImage(sample, isPrivate, direction) {
      var regex = new RegExp('.+default-image.png');
      var thumbnailUrl = sample.get('thumbnail');
      var thumbUrl = sample.get('avatars')[0].thumbnail;
      var isNext = direction === 'next' ? true : false;
      /*console.log('check default image');
      console.log(thumbnailUrl);
      console.log(thumbUrl);*/
      if (thumbUrl === null || regex.test(thumbUrl)) {
        return isNext ? this.onShowNextLook(sample) : this.onShowPreviousLook(sample);
      } else {
        return this.onOpenModalOverviewSampleIn(sample, isPrivate);
      }
    },

    onOpenModalOverviewSampleIn: function onOpenModalOverviewSampleIn(sample, isPrivate) {
      sample.set('isPrivate', isPrivate);
      var album = this.controller.get('album');
      if (album.get('lookWithAvatars').length <= 1) {
        this.send('openModalOverviewSample', sample);
      } else {
        this.send('openModalOverviewSamplePreviousNext', sample);
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-settings page-settings-brand');
      Ember['default'].$('.search_suggestion_wrapper').removeClass('show');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-settings page-settings-brand');
    },
    onRequestAlbumPermission: function onRequestAlbumPermission(albumID) {
      utils['default'].postAPIJSON('albums/' + albumID + '/requestPermission', {});
      this.send('openDefaultMessageModal', 'Request sent', 'Amazing! You have requested access to the "ALBUM NAME" from the "COLLECTION AND BRAND"', 'You will receive a confirmation email once the PR responsible for your request gives you access to the album. Thank you');
    },
    onShowPreviousLook: function onShowPreviousLook(look) {
      var looks = this.controller.get('album.looks');
      var index = looks.indexOf(look);
      //The first look will navigate to the last look
      if (index > 0) {
        index = index - 1;
      } else {
        index = looks['content'].length - 1;
      }
      var nextLook = looks.objectAt(index);

      var isPrivate = this.controller.get('album').get('isPrivate');

      this.checkDefaultImage(nextLook, isPrivate, 'previous');
    },
    onShowNextLook: function onShowNextLook(look) {
      var looks = this.controller.get('album.looks');
      var index = looks.indexOf(look);
      if (index == looks['content'].length - 1) {
        index = 0;
      } else {
        index++;
      }
      var nextLook = looks.objectAt(index);

      var isPrivate = this.controller.get('album').get('isPrivate');

      this.checkDefaultImage(nextLook, isPrivate, 'next');
    }
  });

});