define('bookalook/components/validated-select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // Attributes
    label: '',
    model: null,
    property: '',

    init: function init() {
      this._super.apply(this, arguments);

      var binding = Ember['default'].Binding.from("model." + this.get("property")).to("value");
      binding.connect(this);

      binding = Ember['default'].Binding.from("model.errors." + this.get("property")).to("propertyErrorMessages");
      binding.connect(this);

      if (!this.hasOwnProperty('searchEnabled')) {
        this.set("searchEnabled", true);
      }

      if (!this.hasOwnProperty('enabled')) {
        this.set("enabled", true);
      }

      if (!this.hasOwnProperty('optionLabelPath')) {
        this.set("optionLabelPath", 'name');
      }

      if (!this.hasOwnProperty('optionValuePath')) {
        this.set("optionValuePath", 'id');
      }

      if (!this.hasOwnProperty('optionDescriptionPath')) {
        this.set("optionDescriptionPath", 'dummy');
      }
    },

    focusOut: function focusOut() {
      var model = this.get("model");
      var modelErrors = model.get("errors");
      var property = this.get("property");

      modelErrors.remove(property);
      model.validate({ only: [property] });
    },

    enabledObserver: (function () {
      this.set('enabled', !this.get('disabled'));
    }).observes('disabled'),

    checkErrors: (function () {
      var model = this.get("model");
      if (model) {
        var modelErrors = model.get("errors");
        var property = this.get("property");

        var errors = modelErrors.errorsFor(property);
        var errorMessage = errors[0] ? errors[0].message : '';

        this.set("error", errorMessage);
      }
    }).observes("propertyErrorMessages")
  });

});