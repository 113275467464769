define('bookalook/helpers/uppercase', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var response = "";
    if (params[0] != null) {
      response = params[0].toUpperCase();
    }
    return response;
  });

});