define('bookalook/templates/components/looks-gallery-uploader', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 8
                },
                "end": {
                  "line": 38,
                  "column": 8
                }
              },
              "moduleName": "bookalook/templates/components/looks-gallery-uploader.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","handle pull-right");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [3]);
              var element8 = dom.childAt(fragment, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7,0,0);
              morphs[2] = dom.createElementMorph(element8);
              morphs[3] = dom.createMorphAt(element8,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["selectAvatar",["get","item",["loc",[null,[36,37],[36,41]]]]],[],["loc",[null,[36,13],[36,43]]]],
              ["content","item",["loc",[null,[36,44],[36,52]]]],
              ["element","action",["selectAvatar",["get","item",["loc",[null,[37,66],[37,70]]]]],[],["loc",[null,[37,42],[37,72]]]],
              ["inline","fa-icon",["arrows-h"],[],["loc",[null,[37,73],[37,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/components/looks-gallery-uploader.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","sortable-item",[],["tagName","li","class","gallery-button sortable-item","model",["subexpr","@mut",[["get","item",["loc",[null,[34,81],[34,85]]]]],[],[]],"group",["subexpr","@mut",[["get","group",["loc",[null,[34,92],[34,97]]]]],[],[]],"handle",".handle"],0,null,["loc",[null,[34,8],[38,26]]]]
          ],
          locals: ["item","index"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 4
            },
            "end": {
              "line": 40,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery-uploader.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","avatarsOrder",["loc",[null,[33,14],[33,26]]]]],[],0,null,["loc",[null,[33,6],[39,15]]]]
        ],
        locals: ["group"],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery-uploader.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"id","preview-image");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","fileUpload edit-link upload-edit");
          dom.setAttribute(el1,"id","new-look-image");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","edit-image");
          dom.setAttribute(el2,"src","images/pen_icon.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","file");
          dom.setAttribute(el2,"class","upload");
          dom.setAttribute(el2,"accept",".gif, .jpeg, .jpg, .png,");
          dom.setAttribute(el2,"id","new-look-image-input");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","delete-image");
          dom.setAttribute(el1,"class","upload-edit");
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("×");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","empty-preview");
          dom.setAttribute(el1,"class","hidden-preview fileUpload");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","upload-round-button edit-image");
          var el3 = dom.createTextNode("+");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","file");
          dom.setAttribute(el2,"class","upload input-empty");
          dom.setAttribute(el2,"accept",".gif, .jpeg, .jpg, .png,");
          dom.setAttribute(el2,"id","empty-look-image-input");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("a {{action 'openLookImageModal'}} class=\"edit-link\" data-target=\"#image_look_cropper\" data-toggle=\"modal\" data-dismiss=\"modal\">Add/edit picture</a");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [3]);
          var element5 = dom.childAt(fragment, [5]);
          var element6 = dom.childAt(fragment, [7]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createElementMorph(element5);
          morphs[2] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["element","action",["setInputEvents","new-look-image",["get","lookToAdd",["loc",[null,[53,50],[53,59]]]],"look"],["preventDefault",false],["loc",[null,[53,7],[53,89]]]],
          ["element","action",["deleteImage"],[],["loc",[null,[57,7],[57,31]]]],
          ["element","action",["setInputEvents","empty-look-image",["get","lookToAdd",["loc",[null,[58,54],[58,63]]]],"look"],["preventDefault",false],["loc",[null,[58,9],[58,93]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 2
            },
            "end": {
              "line": 75,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/components/looks-gallery-uploader.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","hidden-preview");
          dom.setAttribute(el1,"id","preview-image");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","fileUpload edit-link hidden-preview upload-edit");
          dom.setAttribute(el1,"id","new-look-image");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","edit-image hidden-preview");
          dom.setAttribute(el2,"src","images/pen_icon.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","file");
          dom.setAttribute(el2,"class","upload hidden-preview");
          dom.setAttribute(el2,"accept",".gif, .jpeg, .jpg, .png,");
          dom.setAttribute(el2,"id","new-look-image-input");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","delete-image");
          dom.setAttribute(el1,"class","upload-edit hidden-preview");
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("×");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","empty-preview");
          dom.setAttribute(el1,"class","fileUpload");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","upload-round-button edit-image");
          var el3 = dom.createTextNode("+");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","file");
          dom.setAttribute(el2,"class","upload input-empty");
          dom.setAttribute(el2,"accept",".gif, .jpeg, .jpg, .png,");
          dom.setAttribute(el2,"id","empty-look-image-input");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var element3 = dom.childAt(fragment, [7]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'src');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","lookToAdd.avatar",["loc",[null,[65,39],[65,55]]]]]]],
          ["element","action",["setInputEvents","new-look-image",["get","lookToAdd",["loc",[null,[66,52],[66,61]]]],"look"],["preventDefault",false],["loc",[null,[66,9],[66,91]]]],
          ["element","action",["deleteImage"],[],["loc",[null,[70,9],[70,33]]]],
          ["element","action",["setInputEvents","empty-look-image",["get","lookToAdd",["loc",[null,[71,54],[71,63]]]],"look"],["preventDefault",false],["loc",[null,[71,9],[71,93]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 6
          }
        },
        "moduleName": "bookalook/templates/components/looks-gallery-uploader.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","look-image col-xs-3 no-padding-left");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<ul id=\"order-buttons\" class=\"btn-group no-padding-tabs sortable-group\">");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<li {{action \"selectAvatar\" 1}} id=\"look-image-1\" class=\"gallery-button selected-image sortable-item\">");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<img src=\"\" />");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<p>1</p>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<span class=\"handle pull-right\">{{fa-icon \"arrows-h\"}}</span>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</li>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<li {{action \"selectAvatar\" 2}} id=\"look-image-2\" class=\"gallery-button sortable-item\">");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<img src=\"\" />");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<p>2</p>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<span class=\"handle pull-right\">{{fa-icon \"arrows-h\"}}</span>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</li>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<li {{action \"selectAvatar\" 3}} id=\"look-image-3\" class=\"gallery-button sortable-item\">");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<img src=\"\" />");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<p>3</p>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<span class=\"handle pull-right\">{{fa-icon \"arrows-h\"}}</span>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</li>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<li {{action \"selectAvatar\" 4}} id=\"look-image-4\" class=\"gallery-button sortable-item\">");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<img src=\"\" />");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<p>4</p>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<span class=\"handle pull-right\">{{fa-icon \"arrows-h\"}}</span>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</li>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<li {{action \"selectAvatar\" 5}} id=\"look-image-5\" class=\"gallery-button sortable-item\">");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<img src=\"\" />");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<p>5</p>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("<span class=\"handle pull-right\">{{fa-icon \"arrows-h\"}}</span>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</li>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("</ul>");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","looks-gallery-uploader");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","custom-sortable");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","image-container");
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","loader-container loading-image hidden-preview");
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("center");
        var el6 = dom.createElement("h3");
        var el7 = dom.createTextNode("Loading...");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sk-folding-cube");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","sk-cube1 sk-cube white-loading");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","sk-cube2 sk-cube white-loading");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","sk-cube4 sk-cube white-loading");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","sk-cube3 sk-cube white-loading");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"id","multi-upload");
        dom.setAttribute(el4,"class","bulk-upload-link");
        var el5 = dom.createTextNode("Bulk upload / edit\n  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","file");
        dom.setAttribute(el5,"class","upload");
        dom.setAttribute(el5,"accept",".gif, .jpeg, .jpg, .png,");
        dom.setAttribute(el5,"name","file1");
        dom.setAttribute(el5,"id","multi-upload-input");
        dom.setAttribute(el5,"multiple","multiple");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [1, 55]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [5]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
        morphs[1] = dom.createMorphAt(element10,3,3);
        morphs[2] = dom.createElementMorph(element11);
        return morphs;
      },
      statements: [
        ["block","sortable-group",[],["tagName","ul","direction","x","class","btn-group no-padding-tabs sortable-group buttons-wrapper","onChange","sortAvatars"],0,null,["loc",[null,[32,4],[40,23]]]],
        ["block","if",[["get","lookToAdd.avatars",["loc",[null,[51,8],[51,25]]]]],[],1,2,["loc",[null,[51,2],[75,9]]]],
        ["element","action",["setInputEvents","multi-upload",["get","lookToAdd",["loc",[null,[76,46],[76,55]]]],"look"],["preventDefault",false],["loc",[null,[76,5],[76,85]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});