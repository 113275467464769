define('bookalook/routes/search/functions-new', ['exports', 'bookalook/utils', 'ember'], function (exports, utils, Ember) {

  'use strict';

  exports.handleEnableSearchBarArrows = handleEnableSearchBarArrows;
  exports.scrollSearchBar = scrollSearchBar;
  exports.checkSearchBarScrollPosition = checkSearchBarScrollPosition;
  exports.addParameterToSearchBar = addParameterToSearchBar;
  exports.removeParameterFromSearchBar = removeParameterFromSearchBar;
  exports.addOrRemoveNewFilter = addOrRemoveNewFilter;
  exports.addOrRemoveNewFilterOld = addOrRemoveNewFilterOld;
  exports.setUnactiveFilters = setUnactiveFilters;
  exports.initializeFilters = initializeFilters;

  function handleEnableSearchBarArrows(route) {
    var _t = route;
    //Scrollable div > Parent div (- arrows width)
    Ember['default'].run.later(function () {
      if (document.getElementById('searchbar-slider').scrollWidth > Ember['default'].$('#searchbar-container').width() - 30) {
        _t.controller.set('enableSearchBarArrows', true);
      } else {
        _t.controller.set('enableSearchBarArrows', false);
      }
      checkSearchBarScrollPosition(_t);
    }, 100);
  }

  function scrollSearchBar(route, direction) {
    var _t = route;
    if (direction == "left") {
      Ember['default'].$('#searchbar-slider').animate({
        scrollLeft: "-=300px"
      }, 300, function () {
        checkSearchBarScrollPosition(_t);
      });
    } else {
      Ember['default'].$('#searchbar-slider').animate({
        scrollLeft: "+=300px"
      }, 300, function () {
        checkSearchBarScrollPosition(_t);
      });
    }
  }

  function checkSearchBarScrollPosition(route) {
    var _t = route;
    var $elem = Ember['default'].$('#searchbar-slider');
    var width = $elem.outerWidth();
    var newScrollLeft = $elem.scrollLeft();
    var scrollWidth = $elem.get(0).scrollWidth;
    if (scrollWidth - newScrollLeft == width) {
      _t.controller.set('searchBarArrowRight', false);
    } else {
      _t.controller.set('searchBarArrowRight', true);
    }
    if (newScrollLeft === 0) {
      _t.controller.set('searchBarArrowLeft', false);
    } else {
      _t.controller.set('searchBarArrowLeft', true);
    }
  }

  function addParameterToSearchBar(route, obj) {
    var _t = route;
    var filters = _t.controller.get('searchBarParameters');
    if (Ember['default'].isNone(filters)) {
      filters = Ember['default'].A();
    }
    filters.pushObject({ name: Math.random() });
    handleEnableSearchBarArrows(_t);
  }

  function removeParameterFromSearchBar(route, obj) {
    var _t = route;
    addOrRemoveNewFilter(route, obj.type, obj);
    //let filters = _t.controller.get('searchBarParameters');
    //filters.removeObject(obj);
    handleEnableSearchBarArrows(_t);
  }

  function addOrRemoveNewFilter(route, type) {
    var obj = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    var controller = route.controller;
    var newFilter = obj ? obj : controller.get(type + 'Filter');
    var typePlaceholder = controller.get(type + 'Placeholder');
    var filters = controller.get(type + 'ActiveFilters');
    if (!Ember['default'].isNone(newFilter) && !Ember['default'].isNone(newFilter.id)) {
      if (filters.includes(newFilter)) {
        Ember['default'].set(newFilter, 'disabled', false);
        filters.removeObject(newFilter);
      } else {
        Ember['default'].set(newFilter, 'dateFilter', new Date());
        Ember['default'].set(newFilter, 'type', type);
        Ember['default'].set(newFilter, 'disabled', true);
        filters.pushObject(newFilter);
      }
      controller.set(type + 'Filter', null);
      controller.notifyPropertyChange(type + 'ActiveFilters');
      Ember['default'].$('#' + type + "Filter").attr("data-placeholder", filters.length == 0 ? typePlaceholder : typePlaceholder + " (" + filters.length + ")");
      Ember['default'].$('#' + type + "Filter").data("select2").setPlaceholder();
      handleEnableSearchBarArrows(route);
    } else {
      controller.set(type + 'Filter', null);
    }
  }

  function addOrRemoveNewFilterOld(route, type) {
    var obj = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    var controller = route.controller;
    var newFilter = obj ? obj : controller.get(type + 'Filter');
    var typePlaceholder = controller.get(type + 'Placeholder');
    var filters = controller.get(type + 'ActiveFilters');
    if (!Ember['default'].isNone(newFilter) && !Ember['default'].isNone(newFilter.id)) {
      if (filters.includes(newFilter)) {
        Ember['default'].set(newFilter, 'disabled', false);
        filters.removeObject(newFilter);
      } else {
        Ember['default'].set(newFilter, 'dateFilter', new Date());
        Ember['default'].set(newFilter, 'type', type);
        Ember['default'].set(newFilter, 'disabled', true);
        filters.pushObject(newFilter);
      }
      controller.set(type + 'Filter', null);
      controller.notifyPropertyChange(type + 'ActiveFilters');
      Ember['default'].$('#' + type + "Filter").attr("data-placeholder", filters.length == 0 ? typePlaceholder : typePlaceholder + " (" + filters.length + ")");
      Ember['default'].$('#' + type + "Filter").data("select2").setPlaceholder();
      handleEnableSearchBarArrows(route);
    } else {
      controller.set(type + 'Filter', null);
    }
  }

  function setUnactiveFilters(route, type) {
    var controller = route.controller;
    var list = controller.get(type + 'List').slice(0);
    var activeFilters = controller.get(type + 'ActiveFilters');
    var unactiveFilters = list.filter(function (f) {
      return !activeFilters.includes(f);
    });
    controller.set(type + "UnactiveFilters", unactiveFilters);
  }

  /**
   * Initialize filters. (list, active and unactive filters, filter var, placeholder )
   * @param route
   */

  function initializeFilters(route) {
    var controller = route.controller;
    //let arrayStringsFilters = ['designer', 'collection', 'category', 'item', 'colour', 'permission'];
    var arrayStringsFilters = ['designer'];
    for (var i = 0; i < arrayStringsFilters.length; i++) {
      var plural = "";
      if (arrayStringsFilters[i] == "item") {
        plural = "items";
      } else if (arrayStringsFilters[i] == "category") {
        plural = "categories";
      } else {
        plural = arrayStringsFilters[i] + "s";
      }
      var list = controller.get(plural);
      controller.set(arrayStringsFilters[i] + 'Placeholder', plural.toUpperCase());
      controller.set(arrayStringsFilters[i] + 'List', Ember['default'].A([{ id: null, name: '---' }]).pushObjects(controller.get("designers")));
      controller.set(arrayStringsFilters[i] + 'UnactiveFilters', Ember['default'].A([{ id: null, name: '---' }]).pushObjects(controller.get(plural)));
      controller.set(arrayStringsFilters[i] + 'ActiveFilters', Ember['default'].A());
      controller.set(arrayStringsFilters[i] + 'Filter', null);
    }
  }

});