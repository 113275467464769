define('bookalook/templates/components/validated-select', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/components/validated-select.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","form-group validated-select");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"class","sr-only");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element1, 'for');
        morphs[1] = dom.createMorphAt(element1,0,0);
        morphs[2] = dom.createMorphAt(element0,3,3);
        return morphs;
      },
      statements: [
        ["attribute","for",["get","placeholder",["loc",[null,[2,15],[2,26]]]]],
        ["content","placeholder",["loc",[null,[2,45],[2,60]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[4,30],[4,37]]]]],[],[]],"value",["subexpr","@mut",[["get","value",["loc",[null,[5,30],[5,35]]]]],[],[]],"placeholder",["subexpr","@mut",[["get","placeholder",["loc",[null,[6,30],[6,41]]]]],[],[]],"optionLabelPath",["subexpr","@mut",[["get","optionLabelPath",["loc",[null,[8,30],[8,45]]]]],[],[]],"optionValuePath",["subexpr","@mut",[["get","optionValuePath",["loc",[null,[9,30],[9,45]]]]],[],[]],"optionDescriptionPath",["subexpr","@mut",[["get","optionDescriptionPath",["loc",[null,[10,30],[10,51]]]]],[],[]],"searchEnabled",["subexpr","@mut",[["get","searchEnabled",["loc",[null,[11,30],[11,43]]]]],[],[]],"enabled",["subexpr","@mut",[["get","enabled",["loc",[null,[12,30],[12,37]]]]],[],[]],"class",["subexpr","concat",[["subexpr","if",[["get","error",[]],"error"],[],[]]," "],[],[]]],["loc",[null,[3,4],[13,6]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});