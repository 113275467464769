define('bookalook/helpers/create-barcode', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //(!params[0] && !params[1]) => true
    //(params[0] || params[1]) => false;
    var brandId = params[0];
    var sampleId = params[1];
    var barcode = "LOADING...";

    if (brandId != null && sampleId != null) {
      //Fill with 0's (brandId = 4 digits / sampleID = 6 digits)
      var strBrandId = "" + brandId;
      var padBrand = "0000";
      strBrandId = padBrand.substring(0, padBrand.length - strBrandId.length) + strBrandId;
      var strSampleId = "" + sampleId;
      var padSample = "000000";
      strSampleId = padSample.substring(0, padSample.length - strSampleId.length) + strSampleId;
      barcode = strBrandId + '-' + strSampleId;
      return barcode;
    }
    return barcode;
  });

});