define('bookalook/tests/routes/jobs/shopping-list.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/jobs/shopping-list.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/jobs/shopping-list.js should pass jshint.\nroutes/jobs/shopping-list.js: line 26, col 77, Missing semicolon.\nroutes/jobs/shopping-list.js: line 286, col 58, Missing semicolon.\n\n2 errors');
  });

});