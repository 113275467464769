define('bookalook/routes/profile/agency-profile', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      collapseAreaAndScroll: function collapseAreaAndScroll(textSelector, textId, scrSel, scrId) {
        this.onCollapseAreaAndScroll(textSelector, textId, scrSel, scrId);
      },
      collapseArea: function collapseArea(selector, id) {
        this.onCollapseArea(selector, id);
      }
    },

    model: function model(params) {
      return this.store.findRecord('agency', params.id);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var agency = model;

      var showrooms = agency.get('showrooms');
      var firstSh = showrooms.get('firstObject');
      var fromDB = firstSh.store.findRecord('showroom', firstSh.get('id'));
      agency.set('HQ', fromDB);
      controller.set('loadingBrands', true);
      fromDB.then(function (sh) {
        sh.get('brands').then(function (brands) {
          var sortedBrands = undefined;
          sortedBrands = brands.sortBy('name');
          firstSh.set('brands', sortedBrands);
          //controller.notifyPropertyChange('model.HQ.brands');
          controller.set('loadingBrands', false);
        });
      });

      controller.set('loadingDiscoverMore', true);
      controller.set('discoverAgencies', null);
      utils['default'].getAPIJSON('discover/agencies/' + model.id).then(function (agencies) {
        controller.set('discoverAgencies', agencies);
        controller.set('loadingDiscoverMore', false);
      }, function () {
        controller.set('loadingDiscoverMore', false);
      });
    },

    onCollapseArea: function onCollapseArea(selector, id) {
      var el = Ember['default'].$('#' + selector + id).find('a');
      var oldText = el.text();
      var newText = oldText.trim()[0] === 'S' ? oldText.replace('Show more', 'Hide') : oldText.replace('Hide', 'Show more');
      el.text(newText);
    },

    onCollapseAreaAndScroll: function onCollapseAreaAndScroll(textSelector, scrSel, id) {
      var el = Ember['default'].$('#' + textSelector + id).find('a');
      var scr = Ember['default'].$('#' + scrSel + id);
      var oldText = el.text();
      var newText = "";
      //console.log(oldText.trim()[0]);
      if (oldText.trim()[0] === 'S') {
        newText = oldText.replace('Show all', 'Hide');
      } else {
        newText = oldText.replace('Hide', 'Show all');
        $('html, body').animate({ scrollTop: scr.offset().top });
      }
      el.text(newText);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-settings page-settings-agency');
      Ember['default'].$('.search_suggestion_wrapper').removeClass('show');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-settings page-settings-agency');
    }
  });

});