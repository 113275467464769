define('bookalook/templates/global/sample-view-card-toggle', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 74,
            "column": 6
          }
        },
        "moduleName": "bookalook/templates/global/sample-view-card-toggle.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Main Sample Card Toggle  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"data-toggle","collapse");
        dom.setAttribute(el2,"data-parent","#view-look-samples-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","info-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","images/forward.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","info-labels");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","input_label_value");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","input_label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","count-container");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("/");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","sample-content");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sample-main-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Colour 1");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Material 1");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Pattern 1");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Colour 2");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Material 2");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Pattern 2");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Colour 3");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Material 3");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Pattern 3");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sample-secondary-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Style Number");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Reference");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Size");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Manufacturer");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sample-prices-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Retail Price");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Wholesale");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label");
        var el7 = dom.createTextNode("Sale Price");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","input_label_value");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sample-descriptions-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Care & Additional infos");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("Private description");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label_value");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","footer-color");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1, 3]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element1, [3]);
        var element5 = dom.childAt(element0, [3]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element7, [3]);
        var element10 = dom.childAt(element7, [5]);
        var element11 = dom.childAt(element6, [3]);
        var element12 = dom.childAt(element6, [5]);
        var element13 = dom.childAt(element6, [7]);
        var morphs = new Array(28);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createAttrMorph(element1, 'data-target');
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[4] = dom.createMorphAt(element3,0,0);
        morphs[5] = dom.createMorphAt(element3,1,1);
        morphs[6] = dom.createMorphAt(element4,0,0);
        morphs[7] = dom.createMorphAt(element4,2,2);
        morphs[8] = dom.createAttrMorph(element5, 'id');
        morphs[9] = dom.createAttrMorph(element5, 'class');
        morphs[10] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element8, [7]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element8, [11]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element9, [7]),0,0);
        morphs[15] = dom.createMorphAt(dom.childAt(element9, [11]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element10, [7]),0,0);
        morphs[18] = dom.createMorphAt(dom.childAt(element10, [11]),0,0);
        morphs[19] = dom.createMorphAt(dom.childAt(element11, [3]),0,0);
        morphs[20] = dom.createMorphAt(dom.childAt(element11, [7]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element11, [11]),0,0);
        morphs[22] = dom.createMorphAt(dom.childAt(element11, [15]),0,0);
        morphs[23] = dom.createMorphAt(dom.childAt(element12, [3]),0,0);
        morphs[24] = dom.createMorphAt(dom.childAt(element12, [7]),0,0);
        morphs[25] = dom.createMorphAt(dom.childAt(element12, [11]),0,0);
        morphs[26] = dom.createMorphAt(dom.childAt(element13, [1, 3]),0,0);
        morphs[27] = dom.createMorphAt(dom.childAt(element13, [3, 3]),0,0);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["sample-container card-gradient-border ",["subexpr","if",[["get","sample.archived",["loc",[null,[2,55],[2,70]]]],"archived"],[],["loc",[null,[2,50],[2,83]]]]]]],
        ["attribute","class",["concat",["sample-toggle ",["subexpr","if",[["subexpr","if-equal",[["get","sampleToView",["loc",[null,[3,43],[3,55]]]],["get","sample",["loc",[null,[3,56],[3,62]]]]],[],["loc",[null,[3,33],[3,63]]]],"","collapsed"],[],["loc",[null,[3,28],[3,80]]]]," pointer"]]],
        ["attribute","data-target",["concat",["#toggle_sample_",["get","sample.id",["loc",[null,[3,143],[3,152]]]]]]],
        ["content","sample.itemLabel",["loc",[null,[7,40],[7,60]]]],
        ["content","sample.barcode",["loc",[null,[8,34],[8,52]]]],
        ["inline","if",[["get","sample.archived",["loc",[null,[8,57],[8,72]]]]," | ARCHIVED",""],[],["loc",[null,[8,52],[8,91]]]],
        ["inline","plus-one",[["get","sampleIndex",["loc",[null,[11,44],[11,55]]]]],[],["loc",[null,[11,33],[11,57]]]],
        ["content","sampleGroup.all.length",["loc",[null,[11,58],[11,84]]]],
        ["attribute","id",["concat",["toggle_sample_",["get","sample.id",["loc",[null,[13,27],[13,36]]]]]]],
        ["attribute","class",["concat",["collapse ",["subexpr","if",[["subexpr","if-equal",[["get","sampleToView",["loc",[null,[13,71],[13,83]]]],["get","sample",["loc",[null,[13,84],[13,90]]]]],[],["loc",[null,[13,61],[13,91]]]],"in",""],[],["loc",[null,[13,56],[13,101]]]]," sample-toggle-content"]]],
        ["inline","if",[["get","sample.colour1",["loc",[null,[19,49],[19,63]]]],["get","sample.colour1",["loc",[null,[19,64],[19,78]]]],"-"],[],["loc",[null,[19,44],[19,84]]]],
        ["inline","if",[["get","sample.fabric1",["loc",[null,[21,49],[21,63]]]],["get","sample.fabric1",["loc",[null,[21,64],[21,78]]]],"-"],[],["loc",[null,[21,44],[21,84]]]],
        ["inline","if",[["get","sample.pattern1",["loc",[null,[23,49],[23,64]]]],["get","sample.pattern1",["loc",[null,[23,65],[23,80]]]],"-"],[],["loc",[null,[23,44],[23,86]]]],
        ["inline","if",[["get","sample.colour2",["loc",[null,[27,49],[27,63]]]],["get","sample.colour2",["loc",[null,[27,64],[27,78]]]],"-"],[],["loc",[null,[27,44],[27,84]]]],
        ["inline","if",[["get","sample.fabric2",["loc",[null,[29,49],[29,63]]]],["get","sample.fabric2",["loc",[null,[29,64],[29,78]]]],"-"],[],["loc",[null,[29,44],[29,84]]]],
        ["inline","if",[["get","sample.pattern2",["loc",[null,[31,49],[31,64]]]],["get","sample.pattern2",["loc",[null,[31,65],[31,80]]]],"-"],[],["loc",[null,[31,44],[31,86]]]],
        ["inline","if",[["get","sample.colour3",["loc",[null,[35,49],[35,63]]]],["get","sample.colour3",["loc",[null,[35,64],[35,78]]]],"-"],[],["loc",[null,[35,44],[35,84]]]],
        ["inline","if",[["get","sample.fabric3",["loc",[null,[37,49],[37,63]]]],["get","sample.fabric3",["loc",[null,[37,64],[37,78]]]],"-"],[],["loc",[null,[37,44],[37,84]]]],
        ["inline","if",[["get","sample.pattern3",["loc",[null,[39,49],[39,64]]]],["get","sample.pattern3",["loc",[null,[39,65],[39,80]]]],"-"],[],["loc",[null,[39,44],[39,86]]]],
        ["inline","if",[["get","sample.styleNumber",["loc",[null,[44,47],[44,65]]]],["get","sample.styleNumber",["loc",[null,[44,66],[44,84]]]],"-"],[],["loc",[null,[44,42],[44,90]]]],
        ["inline","if",[["get","sample.reference",["loc",[null,[46,47],[46,63]]]],["get","sample.reference",["loc",[null,[46,64],[46,80]]]],"-"],[],["loc",[null,[46,42],[46,86]]]],
        ["inline","if",[["get","sample.size",["loc",[null,[48,47],[48,58]]]],["get","sample.size",["loc",[null,[48,59],[48,70]]]],"-"],[],["loc",[null,[48,42],[48,76]]]],
        ["inline","if",[["get","sample.manufacturer",["loc",[null,[50,47],[50,66]]]],["get","sample.manufacturer",["loc",[null,[50,67],[50,86]]]],"-"],[],["loc",[null,[50,42],[50,92]]]],
        ["inline","if",[["get","sample.retailPrice",["loc",[null,[54,47],[54,65]]]],["get","sample.retailPrice",["loc",[null,[54,66],[54,84]]]],"-"],[],["loc",[null,[54,42],[54,90]]]],
        ["inline","if",[["get","sample.wholesale",["loc",[null,[56,47],[56,63]]]],["get","sample.wholesale",["loc",[null,[56,64],[56,80]]]],"-"],[],["loc",[null,[56,42],[56,86]]]],
        ["inline","if",[["get","sample.salePrice",["loc",[null,[58,47],[58,63]]]],["get","sample.salePrice",["loc",[null,[58,64],[58,80]]]],"-"],[],["loc",[null,[58,42],[58,86]]]],
        ["inline","if",[["get","sample.description",["loc",[null,[63,49],[63,67]]]],["get","sample.description",["loc",[null,[63,68],[63,86]]]],"-"],[],["loc",[null,[63,44],[63,92]]]],
        ["inline","if",[["get","sample.note",["loc",[null,[67,49],[67,60]]]],["get","sample.note",["loc",[null,[67,61],[67,72]]]],"-"],[],["loc",[null,[67,44],[67,78]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});