define('bookalook/helpers/get-collection-label-formatted', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //USE ONLY IN CASE OF OBJECT
    //IF MODEL: BETTER USE THE MODEL FUNCTION (get('filterName'))
    if (!params[0]) {
      return;
    }
    var object = params[0];

    var year = object.year;
    var season = object.season;
    var collectionType = object.collectionType;

    if (typeof year === 'number') {
      year = year.toString();
    }
    var y = year.charAt(2) + year.charAt(3);

    var t = collectionType.charAt(0);
    if (t === 'H') t = "HC";

    var s = null;
    switch (season) {
      case "Spring/Summer":
        s = "S/S";
        break;
      case "Pre-fall":
        s = season;
        break;
      case "Fall/Winter":
        s = "F/W";
        break;
      case "Cruise":
        s = season;
        break;
      case "Capsule":
        s = season;
        break;
      case "Year-round":
        s = season;
        break;
      default:
        s = season;
    }
    return y + " - " + t + " - " + s;
  });

});