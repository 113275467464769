define('bookalook/serializers/booking', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].RESTSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      owner: { serialize: 'ids', deserialize: 'records' },
      addresses: { serialize: 'ids', deserialize: 'records' },
      sampleStatus: { serialize: 'ids', deserialize: 'records' },
      requester: { serialize: 'ids', deserialize: 'records' },
      loanForms: { serialize: 'ids', deserialize: 'records' },
      status: { serialize: false }
    }
  });

});