define('bookalook/templates/collections/partials/duplicated-sample-card', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/partials/duplicated-sample-card.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","pointer");
          var el2 = dom.createTextNode("Activate sample");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","pointer");
          var el2 = dom.createTextNode("Delete sample");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[17,61],[17,70]]]],["get","sample",["loc",[null,[17,71],[17,77]]]],"archive"],[],["loc",[null,[17,30],[17,89]]]],
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[18,61],[18,70]]]],["get","sample",["loc",[null,[18,71],[18,77]]]],"delete"],[],["loc",[null,[18,30],[18,88]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 10
              },
              "end": {
                "line": 22,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/collections/partials/duplicated-sample-card.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","pointer");
            var el2 = dom.createTextNode("Delete sample");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[21,61],[21,70]]]],["get","sample",["loc",[null,[21,71],[21,77]]]],"delete"],[],["loc",[null,[21,30],[21,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 12
                },
                "end": {
                  "line": 25,
                  "column": 12
                }
              },
              "moduleName": "bookalook/templates/collections/partials/duplicated-sample-card.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","pointer");
              var el2 = dom.createTextNode("Archive sample");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["openArchiveModal","samples",["get","sample",["loc",[null,[24,72],[24,78]]]],"#new-look",["get","lookToAdd",["loc",[null,[24,91],[24,100]]]]],[],["loc",[null,[24,34],[24,102]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 10
              },
              "end": {
                "line": 26,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/collections/partials/duplicated-sample-card.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","userLogged.isStarter",["loc",[null,[23,22],[23,42]]]]],[],0,null,["loc",[null,[23,12],[25,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/partials/duplicated-sample-card.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sample.isNew",["loc",[null,[20,16],[20,28]]]]],[],0,1,["loc",[null,[20,10],[26,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/collections/partials/duplicated-sample-card.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","sample-wrapper clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","collapse-header collapsed duplicated clearfix");
        dom.setAttribute(el2,"data-toggle","collapse");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sample-item col-xs-6");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"class","purple_arrow");
        dom.setAttribute(el4,"src","images/purple_arrow.png");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strong");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("Sample ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" - BARCODE: ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","sample-item-span text-uppercase");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"class","icon_duplicated");
        dom.setAttribute(el5,"src","images/duplicated-sample.png");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Duplicate of sample ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-4");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("SHOWROOM: ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","color_primary text-uppercase");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","collapse-inner clearfix padding-left padding-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-3");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","col-xs-12");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-9");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sample-detail");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-6 no-padding");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Reference:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-6 padding-left");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Size:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [3]);
        var element8 = dom.childAt(element4, [3]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element9, [3, 1]);
        var morphs = new Array(11);
        morphs[0] = dom.createAttrMorph(element4, 'id');
        morphs[1] = dom.createAttrMorph(element5, 'data-target');
        morphs[2] = dom.createMorphAt(element7,1,1);
        morphs[3] = dom.createMorphAt(element7,3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [5]),2,2);
        morphs[5] = dom.createMorphAt(dom.childAt(element5, [3, 1, 1]),0,0);
        morphs[6] = dom.createAttrMorph(element8, 'class');
        morphs[7] = dom.createAttrMorph(element8, 'id');
        morphs[8] = dom.createMorphAt(dom.childAt(element9, [1, 1]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element10, [1]),3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(element10, [3]),3,3);
        return morphs;
      },
      statements: [
        ["attribute","id",["concat",["sample-card-",["get","sample.id",["loc",[null,[1,55],[1,64]]]]]]],
        ["attribute","data-target",["concat",["#",["get","groupIndex",["loc",[null,[2,100],[2,110]]]],["get","sample.position",["loc",[null,[2,114],[2,129]]]],"#",["get","groupIndex",["loc",[null,[2,134],[2,144]]]],["get","sample.position",["loc",[null,[2,148],[2,163]]]]]]],
        ["content","sample.globalPosition",["loc",[null,[5,44],[5,69]]]],
        ["content","sample.barcode",["loc",[null,[5,81],[5,99]]]],
        ["content","sampleGroup.main.0.position",["loc",[null,[6,136],[6,167]]]],
        ["content","sample.showroom.city",["loc",[null,[9,88],[9,112]]]],
        ["attribute","class",["concat",["collapse ",["subexpr","if",[["get","sample.isNew",["loc",[null,[12,28],[12,40]]]],"in"],[],["loc",[null,[12,23],[12,47]]]]]]],
        ["attribute","id",["concat",[["get","groupIndex",["loc",[null,[12,55],[12,65]]]],["get","sample.position",["loc",[null,[12,69],[12,84]]]]]]],
        ["block","if",[["get","sample.archived",["loc",[null,[16,14],[16,29]]]]],[],0,1,["loc",[null,[16,8],[27,15]]]],
        ["inline","validated-input",[],["type","text","model",["subexpr","@mut",[["get","sample",["loc",[null,[34,48],[34,54]]]]],[],[]],"property","reference","label","Reference","class","col-xs-12 no-padding"],["loc",[null,[34,12],[34,124]]]],
        ["inline","validated-input",[],["type","text","model",["subexpr","@mut",[["get","sample",["loc",[null,[38,48],[38,54]]]]],[],[]],"property","size","label","Size","class","col-xs-12 no-padding"],["loc",[null,[38,12],[38,114]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});