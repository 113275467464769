define('bookalook/helpers/get-currency-symbol', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return;
    }
    var currency = params[0].toUpperCase();
    if (currency === "GBP") {
      return "£";
    } else if (currency === "USD") {
      return "$";
    } else if (currency === "EUR") {
      return "€";
    } else {
      if (!Ember['default'].isNone(Ember['default'].user)) {
        var stripePlan = Ember['default'].user.get('registerPlan');
        if (!Ember['default'].isNone(stripePlan) && stripePlan != "") {
          stripePlan = stripePlan.toUpperCase();
          if (stripePlan.indexOf("GBP") !== -1) {
            return "£";
          } else if (stripePlan.indexOf("USD") !== -1) {
            return "$";
          } else if (stripePlan.indexOf("EUR") !== -1) {
            return "€";
          }
        }
      }
    }
    return "";
  });

});