define('bookalook/templates/global/partials/sample-edit-card-content', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 156,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/global/partials/sample-edit-card-content.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Colour 1*");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","validated-select");
          dom.setAttribute(el2,"style","position: relative;");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Material 1*");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","validated-select");
          dom.setAttribute(el2,"style","position: relative;");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Pattern 1");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Manufacturer");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Colour 2");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Material 2");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Pattern 2");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Retail Price");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Colour 3");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Material 3");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Pattern 3");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Wholesale");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Style Number");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Reference");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Size");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Sale Price");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sample-descriptions-container");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","input_label");
          var el4 = dom.createTextNode("Care & Additional infos");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","input_label");
          dom.setAttribute(el3,"style","margin-top: 3px;");
          var el4 = dom.createTextNode("Private description");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(fragment, [3]);
          var element7 = dom.childAt(fragment, [5]);
          var element8 = dom.childAt(fragment, [7]);
          var element9 = dom.childAt(fragment, [9]);
          var morphs = new Array(18);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
          morphs[2] = dom.createMorphAt(element5,11,11);
          morphs[3] = dom.createMorphAt(element5,15,15);
          morphs[4] = dom.createMorphAt(element6,3,3);
          morphs[5] = dom.createMorphAt(element6,7,7);
          morphs[6] = dom.createMorphAt(element6,11,11);
          morphs[7] = dom.createMorphAt(element6,15,15);
          morphs[8] = dom.createMorphAt(element7,3,3);
          morphs[9] = dom.createMorphAt(element7,7,7);
          morphs[10] = dom.createMorphAt(element7,11,11);
          morphs[11] = dom.createMorphAt(element7,15,15);
          morphs[12] = dom.createMorphAt(element8,3,3);
          morphs[13] = dom.createMorphAt(element8,7,7);
          morphs[14] = dom.createMorphAt(element8,11,11);
          morphs[15] = dom.createMorphAt(element8,15,15);
          morphs[16] = dom.createMorphAt(dom.childAt(element9, [1]),3,3);
          morphs[17] = dom.createMorphAt(dom.childAt(element9, [3]),3,3);
          return morphs;
        },
        statements: [
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","colours",["loc",[null,[12,32],[12,39]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[13,32],[13,38]]]]],[],[]],"property","colour1","optionLabelPath","safeString","optionValuePath","name","class","hint-right"],["loc",[null,[11,8],[18,8]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","fabrics",["loc",[null,[22,32],[22,39]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[23,32],[23,38]]]]],[],[]],"property","fabric1","optionLabelPath","name","optionValuePath","name","class","hint-right"],["loc",[null,[21,8],[28,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[33,19],[33,25]]]]],[],[]],"property","pattern1"],["loc",[null,[30,6],[35,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[40,19],[40,25]]]]],[],[]],"property","manufacturer"],["loc",[null,[37,6],[42,8]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","colours",["loc",[null,[47,32],[47,39]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[48,32],[48,38]]]]],[],[]],"property","colour2","optionLabelPath","safeString","optionValuePath","name","class","hint-left"],["loc",[null,[46,6],[53,8]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","fabrics",["loc",[null,[56,32],[56,39]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[57,32],[57,38]]]]],[],[]],"property","fabric2","optionLabelPath","name","optionValuePath","name","class","hint-left"],["loc",[null,[55,6],[62,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[67,19],[67,25]]]]],[],[]],"property","pattern2"],["loc",[null,[64,6],[69,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[74,19],[74,25]]]]],[],[]],"property","retailPrice"],["loc",[null,[71,6],[76,8]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","colours",["loc",[null,[81,32],[81,39]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[82,32],[82,38]]]]],[],[]],"property","colour3","optionLabelPath","safeString","optionValuePath","name","class","hint-left"],["loc",[null,[80,6],[87,8]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","fabrics",["loc",[null,[90,32],[90,39]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[91,32],[91,38]]]]],[],[]],"property","fabric3","optionLabelPath","name","optionValuePath","name","class","hint-left"],["loc",[null,[89,6],[96,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[101,19],[101,25]]]]],[],[]],"property","pattern3"],["loc",[null,[98,6],[103,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[108,19],[108,25]]]]],[],[]],"property","wholesale"],["loc",[null,[105,6],[110,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[117,19],[117,25]]]]],[],[]],"property","styleNumber"],["loc",[null,[114,6],[119,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[124,19],[124,25]]]]],[],[]],"property","reference"],["loc",[null,[121,6],[126,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[131,19],[131,25]]]]],[],[]],"property","size"],["loc",[null,[128,6],[133,8]]]],
          ["inline","validated-input",[],["type","text","class","hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[138,19],[138,25]]]]],[],[]],"property","salePrice"],["loc",[null,[135,6],[140,8]]]],
          ["inline","textarea",[],["value",["subexpr","@mut",[["get","sample.description",["loc",[null,[146,18],[146,36]]]]],[],[]]],["loc",[null,[145,8],[147,10]]]],
          ["inline","textarea",[],["value",["subexpr","@mut",[["get","sample.note",["loc",[null,[152,18],[152,29]]]]],[],[]]],["loc",[null,[151,8],[153,10]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 156,
              "column": 2
            },
            "end": {
              "line": 217,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/global/partials/sample-edit-card-content.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Colour 1*");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Material 1*");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Pattern 1");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Manufacturer");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Colour 2");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Material 2");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Pattern 2");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Retail Price");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Colour 3");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Material 3");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Pattern 3");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Wholesale");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Style Number");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Reference");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Size");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label");
          var el3 = dom.createTextNode("Sale Price");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","input_label_value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sample-descriptions-container");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","input_label");
          var el4 = dom.createTextNode("Care & Additional infos");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","input_label_value");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","input_label");
          var el4 = dom.createTextNode("Private description");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","input_label_value");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var element3 = dom.childAt(fragment, [7]);
          var element4 = dom.childAt(fragment, [9]);
          var morphs = new Array(18);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [7]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [11]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [15]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element1, [7]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element1, [11]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element1, [15]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element2, [7]),0,0);
          morphs[10] = dom.createMorphAt(dom.childAt(element2, [11]),0,0);
          morphs[11] = dom.createMorphAt(dom.childAt(element2, [15]),0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[13] = dom.createMorphAt(element3,7,7);
          morphs[14] = dom.createMorphAt(element3,11,11);
          morphs[15] = dom.createMorphAt(dom.childAt(element3, [15]),0,0);
          morphs[16] = dom.createMorphAt(dom.childAt(element4, [1, 3]),0,0);
          morphs[17] = dom.createMorphAt(dom.childAt(element4, [3, 3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","if",[["get","sample.colour1",["loc",[null,[159,43],[159,57]]]],["get","sample.colour1",["loc",[null,[159,58],[159,72]]]],"-"],[],["loc",[null,[159,38],[159,78]]]],
          ["inline","if",[["get","sample.fabric1",["loc",[null,[161,43],[161,57]]]],["get","sample.fabric1",["loc",[null,[161,58],[161,72]]]],"-"],[],["loc",[null,[161,38],[161,78]]]],
          ["inline","if",[["get","sample.pattern1",["loc",[null,[163,43],[163,58]]]],["get","sample.pattern1",["loc",[null,[163,59],[163,74]]]],"-"],[],["loc",[null,[163,38],[163,80]]]],
          ["inline","if",[["get","sample.manufacturer",["loc",[null,[165,43],[165,62]]]],["get","sample.manufacturer",["loc",[null,[165,63],[165,82]]]],"-"],[],["loc",[null,[165,38],[165,88]]]],
          ["inline","if",[["get","sample.colour2",["loc",[null,[169,43],[169,57]]]],["get","sample.colour2",["loc",[null,[169,58],[169,72]]]],"-"],[],["loc",[null,[169,38],[169,78]]]],
          ["inline","if",[["get","sample.fabric2",["loc",[null,[171,43],[171,57]]]],["get","sample.fabric2",["loc",[null,[171,58],[171,72]]]],"-"],[],["loc",[null,[171,38],[171,78]]]],
          ["inline","if",[["get","sample.pattern2",["loc",[null,[173,43],[173,58]]]],["get","sample.pattern2",["loc",[null,[173,59],[173,74]]]],"-"],[],["loc",[null,[173,38],[173,80]]]],
          ["inline","if",[["get","sample.retailPrice",["loc",[null,[175,43],[175,61]]]],["get","sample.retailPrice",["loc",[null,[175,62],[175,80]]]],"-"],[],["loc",[null,[175,38],[175,86]]]],
          ["inline","if",[["get","sample.colour3",["loc",[null,[179,43],[179,57]]]],["get","sample.colour3",["loc",[null,[179,58],[179,72]]]],"-"],[],["loc",[null,[179,38],[179,78]]]],
          ["inline","if",[["get","sample.fabric3",["loc",[null,[181,43],[181,57]]]],["get","sample.fabric3",["loc",[null,[181,58],[181,72]]]],"-"],[],["loc",[null,[181,38],[181,78]]]],
          ["inline","if",[["get","sample.pattern3",["loc",[null,[183,43],[183,58]]]],["get","sample.pattern3",["loc",[null,[183,59],[183,74]]]],"-"],[],["loc",[null,[183,38],[183,80]]]],
          ["inline","if",[["get","sample.wholesale",["loc",[null,[185,43],[185,59]]]],["get","sample.wholesale",["loc",[null,[185,60],[185,76]]]],"-"],[],["loc",[null,[185,38],[185,82]]]],
          ["inline","if",[["get","sample.styleNumber",["loc",[null,[189,43],[189,61]]]],["get","sample.styleNumber",["loc",[null,[189,62],[189,80]]]],"-"],[],["loc",[null,[189,38],[189,86]]]],
          ["inline","validated-input",[],["type","text","class","col-xs-12 hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[194,19],[194,25]]]]],[],[]],"property","reference"],["loc",[null,[191,6],[196,8]]]],
          ["inline","validated-input",[],["type","text","class","col-xs-12 hint-left no-padding","model",["subexpr","@mut",[["get","sample",["loc",[null,[201,19],[201,25]]]]],[],[]],"property","size"],["loc",[null,[198,6],[203,8]]]],
          ["inline","if",[["get","sample.salePrice",["loc",[null,[205,43],[205,59]]]],["get","sample.salePrice",["loc",[null,[205,60],[205,76]]]],"-"],[],["loc",[null,[205,38],[205,82]]]],
          ["inline","if",[["get","sample.description",["loc",[null,[210,45],[210,63]]]],["get","sample.description",["loc",[null,[210,64],[210,82]]]],"-"],[],["loc",[null,[210,40],[210,88]]]],
          ["inline","if",[["get","sample.note",["loc",[null,[214,45],[214,56]]]],["get","sample.note",["loc",[null,[214,57],[214,68]]]],"-"],[],["loc",[null,[214,40],[214,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 219,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/global/partials/sample-edit-card-content.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","sample-content");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","sample.isMain",["loc",[null,[7,8],[7,21]]]]],[],0,1,["loc",[null,[7,2],[217,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});