define('bookalook/helpers/format-url', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var url = params[0];
    var website = url;
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      website = 'http://' + url.toString();
    }
    return website;
  });

});