define('bookalook/routes/settings/payment-method', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/config/environment', 'bookalook/routes/settings/functions', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, config, plansFunctions, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      createNewCard: function createNewCard() {
        plansFunctions['default'].createNewCard(this);
      },
      completePurchase: function completePurchase() {
        plansFunctions['default'].completePurchase(this);
      },
      startCreateNewCard: function startCreateNewCard() {
        plansFunctions['default'].startCreateNewCard(this);
      },
      cancelCreateNewCard: function cancelCreateNewCard() {
        plansFunctions['default'].cancelCreateNewCard(this);
      },
      checkCardName: function checkCardName() {
        plansFunctions['default'].checkFailCardName(this);
      },
      checkCardNumber: function checkCardNumber() {
        plansFunctions['default'].checkFailCardNumber(this);
      },
      checkCardCvc: function checkCardCvc() {
        plansFunctions['default'].checkFailCardCvc(this);
      },
      updatePaymentMethod: function updatePaymentMethod() {
        plansFunctions['default'].changeCardOnPaymentFailed(this);
      }

    },

    beforeModel: function beforeModel() {
      if (!Ember['default'].user.get('isManager')) {
        this.replaceWith('session.login');
      }
    },

    model: function model() {
      return plansFunctions['default'].getSubscriptionStatus();
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var _t = this;
      controller.set('userLogged', Ember['default'].user);

      controller.set('subscriptionStatus', model);
      controller.set('loadingCards', true);
      controller.set('userCard', null);

      plansFunctions['default'].restartNewCard(_t);

      plansFunctions['default'].getCustomerCards(model.stripeCustomerId).then(function (data) {
        var response = data.stripe_data;
        var cards = response.cards;
        if (cards.get('length') > 0) {
          controller.set('userCard', cards.get(0));
        }
        controller.set('loadingCards', false);
      }, function (fail) {
        controller.set('loadingCards', false);
      });

      plansFunctions['default'].setMonthsYearsArrays(_t);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-payment-failed');
      if (!Ember['default'].user.get('isManager')) {
        Ember['default'].$('body').addClass('no-manager');
      }
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-payment-failed');
      Ember['default'].$('body').removeClass('no-manager');
    }

  });

});