define('bookalook/models/ddt-parcel', ['exports', 'ember-data', 'ember', 'bookalook/mixins/model-validator', 'ember-cli-copyable', 'bookalook/utils'], function (exports, DS, Ember, Validator, Copyable, utils) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], Copyable['default'], {
    billingAddress: DS['default'].attr('string'),
    orderAddress: DS['default'].attr('string'),
    returnAddress: DS['default'].attr('string'),
    shippingCompanyAddress: DS['default'].attr('string'),

    appearance: DS['default'].attr('string'),
    reason: DS['default'].attr('string'),
    weight: DS['default'].attr('string'),
    boxes: DS['default'].attr('string'),

    checkinDate: DS['default'].attr('day'),
    date: DS['default'].attr('day'),
    shippingDate: DS['default'].attr('day'),
    shippingType: DS['default'].attr('string'),
    number: DS['default'].attr('string'),

    loanForm: DS['default'].belongsTo('loan-form', { async: true }),
    booking: DS['default'].belongsTo('booking', { async: true, inverse: null }),
    samples: DS['default'].hasMany('sample', { async: true }),

    showroom: DS['default'].belongsTo('showroom', { async: true, inverse: null }),

    shippingTypeLabel: (function () {
      return this.get('shippingType');
    }).property('shippingType'),

    getDDTParcelNumber: function getDDTParcelNumber() {
      return utils['default'].getAPIJSON('ddtParcels/prefillNoShowroom').then(function (data) {
        var ddtParcel = data.ddtParcel;
        return ddtParcel.number;
      });
    }
  });

});