define('bookalook/models/contact', ['exports', 'ember-data', 'bookalook/mixins/model-validator', 'ember'], function (exports, DS, Validator, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    surname: DS['default'].attr('string'),
    jobTitle: DS['default'].attr('string'),
    company: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    email2: DS['default'].attr('string'),
    notes: DS['default'].attr('array'),
    privateNotes: DS['default'].attr('string'),
    vat: DS['default'].attr('string'),

    privateAddresses: DS['default'].hasMany('contact-address', { async: true, inverse: 'contact' }),
    publicAddress: DS['default'].belongsTo('contact-address', { async: true, inverse: 'public' }),

    contactsLists: DS['default'].hasMany('contacts-list', { async: true }),
    user: DS['default'].belongsTo('user', { async: true }),

    hasUser: (function () {
      return this._internalModel._relationships.get("user").members.size !== 0;
    }).property('user'),

    hasNotUser: (function () {
      return this._internalModel._relationships.get("user").members.size == 0;
    }).property('user'),

    mainAddress: (function () {
      var user = this.get('user');
      var address = user.get('isMP') ? this.get('user.mainAddress') : this.get('publicAddress');

      if (address && Ember['default'].isNone(address.get('id'))) {
        address = this.store.createRecord('contact-address');
        this.set('publicAddress', address);

        address = this.get('publicAddress');
      }
      return address;
    }).property('publicAddress', 'user.name'),

    allAddresses: (function () {
      var user = this.get('user');
      var allAddresses = [];
      if (this.get('publicAddress.content')) {
        allAddresses.addObject(this.get('publicAddress.content'));
      }

      if (user.get('isMP')) {
        if (!Ember['default'].isNone(this.get('user.mainAddress'))) {
          allAddresses.addObject(this.get('user.mainAddress'));
        }
        if (!Ember['default'].isEmpty(this.get('user.secondaryAddresses'))) {
          allAddresses.addObjects(this.get('user.secondaryAddresses'));
        }
      }

      allAddresses.addObjects(this.get('privateAddresses'));

      return allAddresses;
    }).property('publicAddress.street1', 'privateAddresses.@each.street1'),

    country: (function () {
      var country = undefined;
      if (this.get('user.isMP')) {
        country = this.get('mainAddress.country');
      } else {
        country = this.get('publicAddress.country');
      }
      return country;
    }).property('publicAddress.country'),

    completeData: (function () {
      var label = "";
      var name = this.get('name');
      var surname = this.get('surname');
      var company = this.get('company');
      if (!Ember['default'].isBlank(name)) {
        label = !Ember['default'].isBlank(surname) ? name + " " + surname : name;
      } else if (!Ember['default'].isBlank(surname)) {
        label = surname;
      }
      if (!Ember['default'].isBlank(company)) {
        label = label + ", " + company;
      }
      return label;
    }).property('name', 'surname', 'company'),

    fullName: (function () {
      var name = this.get('name');
      var surname = this.get('surname');
      //To prevent null and white space as last character
      if (name && surname) {
        return name + ' ' + surname;
      } else if (name) {
        return name;
      } else if (surname) {
        return surname;
      } else {
        return '';
      }
    }).property('name', 'surname'),

    website: (function () {
      if (this.get('hasUser')) {
        var user = this.get('user');
        var property = user.get('website');
        if (property != null && property != "") {
          return property;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).property('hasUser'),

    facebook: (function () {
      if (this.get('hasUser')) {
        var user = this.get('user');
        var property = user.get('facebook');
        if (property != null && property != "") {
          return property;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).property('hasUser'),

    instagram: (function () {
      if (this.get('hasUser')) {
        var user = this.get('user');
        var property = user.get('instagram');
        if (property != null && property != "") {
          return property;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).property('hasUser'),

    countryLabel: (function () {
      var country = this.get('country');
      if (country != null) switch (country.toLowerCase()) {
        case "united states of america":
          country = "USA";
          break;
        case "united kingdom":
          country = "UK";
          break;
      }
      return country;
    }).property('country'),

    nameInitials: (function () {
      var fullname = this.get('fullName');
      var array = fullname.split(" ");
      var result = array[0].charAt(0);
      if (array[1]) {
        result = result.concat(array[1].charAt(0));
      }
      return result.toUpperCase();
    }).property('fullName'),

    isAvatarDefault: (function () {
      if (this.get('hasUser')) {
        return this.get('user').get('isAvatarDefault');
      } else {
        return true;
      }
    }).property('hasUser', 'user'),

    avatar: (function () {
      if (this.get('hasUser')) {
        return this.get('user').get('avatar');
      } else {
        return false;
      }
    }).property('hasUser', 'user'),

    isMissingMandatories: (function () {
      return Ember['default'].isBlank(this.get('email')) || Ember['default'].isBlank(this.get('name')) || Ember['default'].isBlank(this.get('surname')) || Ember['default'].isBlank(this.get('jobTitle')) || Ember['default'].isBlank(this.get('company')) || Ember['default'].isEmpty(this.get('contactsLists'));
    }).property('email', 'name', 'surname', 'jobTitle', 'company', 'contactsLists'),

    validations: {
      'name': {
        presence: true
      },
      'email': {
        custom: {
          validation: function validation(key, value, model) {
            var email = model.get('email');
            if (!Ember['default'].isEmpty(email)) {
              return model._validateEmail(email);
            }
            return true;
          },
          message: 'Is not a valid email'
        }
      },
      'email2': {
        custom: {
          validation: function validation(key, value, model) {
            var email = model.get('email2');
            if (!Ember['default'].isEmpty(email)) {
              return model._validateEmail(email);
            }
            return true;
          },
          message: 'Is not a valid email'
        }
      }
    },
    _validateEmail: function _validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  });

});