define('bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 0
              },
              "end": {
                "line": 4,
                "column": 0
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" No actions available ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 8,
                        "column": 10
                      },
                      "end": {
                        "line": 11,
                        "column": 10
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1,"type","button");
                    dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                    dom.setAttribute(el1,"class","btn_bal_mini accept");
                    var el2 = dom.createTextNode("Confirm");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element15 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element15, 'id');
                    morphs[1] = dom.createElementMorph(element15);
                    return morphs;
                  },
                  statements: [
                    ["attribute","id",["concat",["sample-confirm-",["get","sample.id",["loc",[null,[9,55],[9,64]]]]]]],
                    ["element","action",["acceptSample",["get","sample",["loc",[null,[9,92],[9,98]]]]],[],["loc",[null,[9,68],[9,100]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 11,
                        "column": 10
                      },
                      "end": {
                        "line": 14,
                        "column": 10
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1,"type","button");
                    dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                    dom.setAttribute(el1,"class","btn_bal_mini accept");
                    var el2 = dom.createTextNode("Confirm");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element14 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element14, 'id');
                    morphs[1] = dom.createElementMorph(element14);
                    return morphs;
                  },
                  statements: [
                    ["attribute","id",["concat",["sample-confirm-",["get","sample.id",["loc",[null,[12,55],[12,64]]]]]]],
                    ["element","action",["confirmSample",["get","sample",["loc",[null,[13,41],[13,47]]]]],[],["loc",[null,[13,16],[13,49]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 7,
                      "column": 8
                    },
                    "end": {
                      "line": 17,
                      "column": 8
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                  dom.setAttribute(el1,"class","btn_bal_mini cancel");
                  var el2 = dom.createTextNode("Decline");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element16 = dom.childAt(fragment, [2]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createAttrMorph(element16, 'id');
                  morphs[2] = dom.createElementMorph(element16);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","booking.isNotForeign",["loc",[null,[8,16],[8,36]]]]],[],0,1,["loc",[null,[8,10],[14,17]]]],
                  ["attribute","id",["concat",["sample-decline-",["get","sample.id",["loc",[null,[15,53],[15,62]]]]]]],
                  ["element","action",["declineSample",["get","sample",["loc",[null,[16,41],[16,47]]]]],[],["loc",[null,[16,16],[16,49]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 17,
                      "column": 8
                    },
                    "end": {
                      "line": 20,
                      "column": 8
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                  dom.setAttribute(el1,"class","btn_bal_mini cancel");
                  var el2 = dom.createTextNode("Cancel");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element13 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element13, 'id');
                  morphs[1] = dom.createElementMorph(element13);
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["sample-decline-",["get","sample.id",["loc",[null,[18,53],[18,62]]]]]]],
                  ["element","action",["declineSample",["get","sample",["loc",[null,[19,41],[19,47]]]]],[],["loc",[null,[19,16],[19,49]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 6
                  },
                  "end": {
                    "line": 21,
                    "column": 6
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","sample.toAdd",["loc",[null,[7,14],[7,26]]]]],[],0,1,["loc",[null,[7,8],[20,15]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 6
                  },
                  "end": {
                    "line": 24,
                    "column": 6
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                dom.setAttribute(el1,"class","btn_bal_mini cancel");
                var el2 = dom.createTextNode("Cancel");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element12 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element12, 'id');
                morphs[1] = dom.createElementMorph(element12);
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["sample-decline-",["get","sample.id",["loc",[null,[22,51],[22,60]]]]]]],
                ["element","action",["declineSample",["get","sample",["loc",[null,[23,39],[23,45]]]]],[],["loc",[null,[23,14],[23,47]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 0
                },
                "end": {
                  "line": 25,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","if-equal",[["get","sample.showroom.id",["loc",[null,[6,22],[6,40]]]],["get","Ember.showroom.id",["loc",[null,[6,41],[6,58]]]]],[],["loc",[null,[6,12],[6,59]]]]],[],0,1,["loc",[null,[6,6],[24,13]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 0
                },
                "end": {
                  "line": 30,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
              dom.setAttribute(el1,"class","btn_bal_mini cancel");
              var el2 = dom.createTextNode("Cancel");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element11, 'id');
              morphs[1] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["sample-decline-",["get","sample.id",["loc",[null,[28,45],[28,54]]]]]]],
              ["element","action",["declineSample",["get","sample",["loc",[null,[29,35],[29,41]]]]],[],["loc",[null,[29,10],[29,43]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 4
                  },
                  "end": {
                    "line": 59,
                    "column": 4
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"class","btn_bal_mini checkout");
                var el2 = dom.createTextNode("Check-out");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element9);
                return morphs;
              },
              statements: [
                ["element","action",["openCheckOutModalBrandRefactor",["get","sample",["loc",[null,[58,70],[58,76]]]]],[],["loc",[null,[58,28],[58,78]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 0
                },
                "end": {
                  "line": 62,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
              dom.setAttribute(el1,"class","btn_bal_mini cancel");
              var el2 = dom.createTextNode("Cancel");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createAttrMorph(element10, 'id');
              morphs[2] = dom.createElementMorph(element10);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","if-equal",[["get","sample.showroom.id",["loc",[null,[57,20],[57,38]]]],["get","Ember.showroom.id",["loc",[null,[57,39],[57,56]]]]],[],["loc",[null,[57,10],[57,57]]]]],[],0,null,["loc",[null,[57,4],[59,11]]]],
              ["attribute","id",["concat",["sample-decline-",["get","sample.id",["loc",[null,[60,47],[60,56]]]]]]],
              ["element","action",["declineSample",["get","sample",["loc",[null,[61,37],[61,43]]]]],[],["loc",[null,[61,12],[61,45]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 2
                  },
                  "end": {
                    "line": 68,
                    "column": 2
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                dom.setAttribute(el1,"class","btn_bal_mini cancel");
                var el2 = dom.createTextNode("Cancel");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element8, 'id');
                morphs[1] = dom.createElementMorph(element8);
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["sample-decline-",["get","sample.id",["loc",[null,[66,47],[66,56]]]]]]],
                ["element","action",["declineSample",["get","sample",["loc",[null,[67,35],[67,41]]]]],[],["loc",[null,[67,10],[67,43]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 69,
                      "column": 4
                    },
                    "end": {
                      "line": 89,
                      "column": 4
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"class","btn_bal_mini checkin");
                  var el2 = dom.createTextNode("Check-in");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element7);
                  return morphs;
                },
                statements: [
                  ["element","action",["openCheckInModalBrandRefactor",["get","sample",["loc",[null,[70,69],[70,75]]]]],[],["loc",[null,[70,28],[70,77]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 2
                  },
                  "end": {
                    "line": 90,
                    "column": 2
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","if-equal",[["get","sample.showroom.id",["loc",[null,[69,20],[69,38]]]],["get","Ember.showroom.id",["loc",[null,[69,39],[69,56]]]]],[],["loc",[null,[69,10],[69,57]]]]],[],0,null,["loc",[null,[69,4],[89,11]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 0
                },
                "end": {
                  "line": 91,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","sample.shoppingListInBooking",["loc",[null,[65,8],[65,36]]]]],[],0,1,["loc",[null,[65,2],[90,9]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 94,
                    "column": 2
                  },
                  "end": {
                    "line": 96,
                    "column": 2
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"class","btn_bal_mini cancel");
                var el2 = dom.createTextNode("Remove");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element6);
                return morphs;
              },
              statements: [
                ["element","action",["removeSample",["get","sample",["loc",[null,[95,52],[95,58]]]]],[],["loc",[null,[95,28],[95,60]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 93,
                  "column": 0
                },
                "end": {
                  "line": 97,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","booking.isNotForeign",["loc",[null,[94,8],[94,28]]]]],[],0,null,["loc",[null,[94,2],[96,9]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 2
                  },
                  "end": {
                    "line": 101,
                    "column": 2
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"class","btn_bal_mini accept");
                var el2 = dom.createTextNode("Accept");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"class","btn_bal_mini cancel");
                var el2 = dom.createTextNode("Decline");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var element5 = dom.childAt(fragment, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element4);
                morphs[1] = dom.createElementMorph(element5);
                return morphs;
              },
              statements: [
                ["element","action",["acceptSample",["get","sample",["loc",[null,[99,50],[99,56]]]]],[],["loc",[null,[99,26],[99,58]]]],
                ["element","action",["declineSample",["get","sample",["loc",[null,[100,51],[100,57]]]]],[],["loc",[null,[100,26],[100,59]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 102,
                      "column": 4
                    },
                    "end": {
                      "line": 105,
                      "column": 2
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"class","btn_bal_mini accept");
                  var el2 = dom.createTextNode("Accept");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"class","btn_bal_mini cancel");
                  var el2 = dom.createTextNode("Decline");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var element3 = dom.childAt(fragment, [3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element2);
                  morphs[1] = dom.createElementMorph(element3);
                  return morphs;
                },
                statements: [
                  ["element","action",["acceptSampleProposal",["get","sample",["loc",[null,[103,60],[103,66]]]]],[],["loc",[null,[103,28],[103,68]]]],
                  ["element","action",["declineSampleProposal",["get","sample",["loc",[null,[104,61],[104,67]]]]],[],["loc",[null,[104,28],[104,69]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 106,
                        "column": 3
                      },
                      "end": {
                        "line": 108,
                        "column": 3
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("				");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1,"type","button");
                    dom.setAttribute(el1,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                    dom.setAttribute(el1,"class","btn_bal_mini cancel");
                    var el2 = dom.createTextNode("Cancel");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element1, 'id');
                    morphs[1] = dom.createElementMorph(element1);
                    return morphs;
                  },
                  statements: [
                    ["attribute","id",["concat",["sample-cancel-",["get","sample.id",["loc",[null,[107,46],[107,55]]]]]]],
                    ["element","action",["cancelSampleProposal",["get","sample",["loc",[null,[107,91],[107,97]]]]],[],["loc",[null,[107,59],[107,99]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 105,
                      "column": 2
                    },
                    "end": {
                      "line": 109,
                      "column": 4
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","contains-text",[["get","sample.shoppingListStatus",["loc",[null,[106,24],[106,49]]]],"propos"],[],["loc",[null,[106,9],[106,59]]]]],[],0,null,["loc",[null,[106,3],[108,10]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 101,
                    "column": 2
                  },
                  "end": {
                    "line": 110,
                    "column": 2
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","contains-text",[["get","sample.shoppingListStatus",["loc",[null,[102,25],[102,50]]]],"new proposal"],[],["loc",[null,[102,10],[102,66]]]]],[],0,1,["loc",[null,[102,4],[109,11]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 0
                },
                "end": {
                  "line": 111,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","if-equal",[["get","sample.shoppingListStatus",["loc",[null,[98,18],[98,43]]]],"requested"],[],["loc",[null,[98,8],[98,56]]]]],[],0,1,["loc",[null,[98,2],[110,9]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 113,
                  "column": 0
                },
                "end": {
                  "line": 115,
                  "column": 0
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","btn_bal_mini cancel");
              var el2 = dom.createTextNode("Cancel");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["declineSample",["get","sample",["loc",[null,[114,49],[114,55]]]]],[],["loc",[null,[114,24],[114,57]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 0
              },
              "end": {
                "line": 116,
                "column": 0
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
            morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[5,13],[5,38]]]],"available","unavailable","booked","overlap"],[],["loc",[null,[5,6],[5,84]]]]],[],0,null,["loc",[null,[5,0],[25,7]]]],
            ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[27,13],[27,38]]]],"pending"],[],["loc",[null,[27,6],[27,49]]]]],[],1,null,["loc",[null,[27,0],[30,7]]]],
            ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[56,13],[56,38]]]],"in","check out today"],[],["loc",[null,[56,6],[56,62]]]]],[],2,null,["loc",[null,[56,0],[62,7]]]],
            ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[64,13],[64,38]]]],"out","check in today"],[],["loc",[null,[64,6],[64,62]]]]],[],3,null,["loc",[null,[64,0],[91,7]]]],
            ["block","if",[["get","sample.isCanceled",["loc",[null,[93,6],[93,23]]]]],[],4,5,["loc",[null,[93,0],[111,7]]]],
            ["block","if",[["subexpr","is-in",[["get","sample.shoppingListStatus",["loc",[null,[113,13],[113,38]]]],"in transfer"],[],["loc",[null,[113,6],[113,53]]]]],[],6,null,["loc",[null,[113,0],[115,7]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 117,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-in",[["get","booking.status",["loc",[null,[2,13],[2,27]]]],"EXPIRED"],[],["loc",[null,[2,6],[2,38]]]]],[],0,1,["loc",[null,[2,0],[116,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 118,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/partials/booking-sample-row-buttons-brand-refactor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","isBookingMember",["loc",[null,[1,6],[1,21]]]]],[],0,null,["loc",[null,[1,0],[117,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});