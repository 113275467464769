define('bookalook/routes/admin/pr-users', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/admin/functions', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, functions, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      verify: functions['default'].verify,
      discard: functions['default'].discard,
      makeNewAgain: functions['default'].makeNewAgain,
      toggleUserSearchable: function toggleUserSearchable(user) {
        functions['default'].toggleUserSearchable(user, this);
      },
      changeUsersStatusFilter: functions['default'].changeUsersStatusFilter,
      insertPRUserInStripe: functions['default'].insertPRUserInStripe,
      createNewPRUserInStripeModal: function createNewPRUserInStripeModal(plan, user) {
        functions['default'].createNewPRUserInStripeModal(this, plan, user);
      },
      removeUserStripeCustomerId: function removeUserStripeCustomerId(user) {
        functions['default'].onRemoveUserStripeCustomerId(this, user.id);
      },
      deletePRUser: function deletePRUser(user) {
        functions['default'].removePRUser(user, this);
      }
    },

    model: function model() {
      return this.store.findAll('user');
    },

    setupController: function setupController(controller, users) {
      var _this = this;

      functions['default'].setupAdminUserController(controller, users, 'isPR');
      //this.controller.set('planSelected', '0');
      this.controller.set('plansAvailable', []);

      utils['default'].getAPIJSON('plans').then(function (plans) {

        plans.plan.forEach(function (plan) {
          _this.controller.get('plansAvailable').addObjects([{ 'id': plan.id, 'plan': plan.idStripe }]);
        });
      });

      //  this.controller.get('plansAvailable').addObjects([
      //    {'id' : '0', 'plan' : 'No plan'},
      //    {'id' : '1', 'plan' : 'Free'},
      //    {'id' : '2', 'plan' : 'NewDesigner'},
      //  ]);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-admin');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-admin');
    }

  });

});