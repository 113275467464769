define('bookalook/routes/jobs/form-view', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/jobs/functions', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, functions, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      cancelJob: function cancelJob(job) {
        functions['default'].cancelJob(this, job);
      },
      deleteJob: function deleteJob(job) {
        functions['default'].cancelJob(this, job, 'delete');
      },
      openLoanForms: function openLoanForms(jobId) {
        functions['default'].onOpenLoanForms(jobId, this.controller);
      },
      closeJob: function closeJob(job) {
        this.onCloseJob(job);
      },
      downloadLoanForm: function downloadLoanForm(loanFormId, bookingId) {
        this.onDownloadLoanForm(loanFormId, bookingId);
      },
      downloadLoanFormDDT: function downloadLoanFormDDT(bookingId, ddtParcelId) {
        this.onDownloadLoanFormDDT(bookingId, ddtParcelId);
      }
    },

    model: function model(params) {
      var _this = this;

      return Ember['default'].RSVP.hash({
        job: this.store.findRecord('job', params.id).then(function (r) {
          return r;
        }, function () {
          return _this.transitionTo('jobs.index');
        }),
        loanForms: utils['default'].getAPIJSON('jobs/' + params.id + '/loanforms')
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);
      controller.set('userLogged', Ember['default'].user);
      controller.set('isFormPage', true);
      var id = model.job.id;

      if (model.loanForms.loanForm[0]) {
        var booking = [];
        utils['default'].getAPIJSON('bookings/' + model.loanForms.loanForm[0].booking.id).then(function (res) {
          controller.set('booking', res);
          //console.log(res);
        });
      }
      controller.set('loanForms', model.loanForms.loanForm);
      controller.set('jobCanBeClosed', controller.get('job.canBeClosed'));
    },

    onCloseJob: function onCloseJob(job) {
      utils['default'].openDefaultConfirmationModal(this, 'Close Job', 'You are about to close this job.', 'Do you wish to proceed?', 'Yes, close this job', 'No').then(function () {
        job.set('closed', true);
        utils['default'].putAPIJSON('jobs/close/' + job.get('id'));
      });
    },
    onDownloadLoanForm: function onDownloadLoanForm(loanFormId, bookingId) {
      var _this2 = this;

      this.controller.get('booking').booking.addresses.forEach(function (address) {
        if (address.loanForm == loanFormId) {
          _this2.transitionTo('bookings.loan-form', bookingId, address.id);
        }
      });
    },

    onDownloadLoanFormDDT: function onDownloadLoanFormDDT(bookingId, parcelDDTId) {
      this.transitionTo('bookings.ddt', bookingId, parcelDDTId);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-jobs page-jobs-form-view');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-jobs page-jobs-form-view');
    }
  });

});