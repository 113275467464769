define('bookalook/templates/ddt-samples/partials/sample-row-loan-old', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 15,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["content","sample.colour2Label",["loc",[null,[14,10],[14,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 19,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["content","sample.colour3Label",["loc",[null,[18,10],[18,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["content","sample.fabric2Label",["loc",[null,[25,10],[25,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 4
            },
            "end": {
              "line": 30,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["content","sample.fabric3Label",["loc",[null,[29,10],[29,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 4
            },
            "end": {
              "line": 41,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element5, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",["data:image/png;base64,",["subexpr","get-array-index-barcode-sample-property",[["get","barcodes64",["loc",[null,[40,84],[40,94]]]],["get","sample.id",["loc",[null,[40,95],[40,104]]]]],[],["loc",[null,[40,42],[40,106]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 4
            },
            "end": {
              "line": 43,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1,"class","fa fa-circle-o-notch fa-spin");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 6
              },
              "end": {
                "line": 56,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
            dom.setAttribute(el1,"aria-label","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'id');
            morphs[1] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[55,21],[55,35]]]]]]],
            ["element","action",["clickSelectSample",["get","sample",["loc",[null,[55,85],[55,91]]]]],["on","click"],["loc",[null,[55,56],[55,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 6
              },
              "end": {
                "line": 58,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
            dom.setAttribute(el1,"aria-label","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'id');
            morphs[1] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[57,21],[57,35]]]]]]],
            ["element","action",["clickSelectSample",["get","sample",["loc",[null,[57,85],[57,91]]]]],["on","click"],["loc",[null,[57,56],[57,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 2
            },
            "end": {
              "line": 61,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" Remove sample from DDT!");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1,"class","hidden-print");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"title","Remove sample from DDT");
          dom.setAttribute(el2,"id","remove_sample_x");
          dom.setAttribute(el2,"style","font-size:20px; position: absolute !important; margin-top: -20px;");
          dom.setAttribute(el2,"class","remove_sample_button hidden-print");
          var el3 = dom.createTextNode("\n        x\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1,"class","hidden-print checkbox-for-ddt-sample");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","bal-checkbox-label");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [3, 1]);
          var element3 = dom.childAt(fragment, [5]);
          var element4 = dom.childAt(element3, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element3,1,1);
          morphs[2] = dom.createAttrMorph(element4, 'for');
          return morphs;
        },
        statements: [
          ["element","action",["confirmRemoveSample",["get","sample",["loc",[null,[48,169],[48,175]]]]],[],["loc",[null,[48,138],[48,177]]]],
          ["block","if",[["get","sample.checked",["loc",[null,[54,12],[54,26]]]]],[],0,1,["loc",[null,[54,6],[58,13]]]],
          ["attribute","for",["concat",[["get","sample.checkId",["loc",[null,[59,22],[59,36]]]]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/ddt-samples/partials/sample-row-loan-old.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Sample row | New booking step 2 ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("tr");
        dom.setAttribute(el1,"class","loan-tr");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    Look ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [2]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [5]);
        var element9 = dom.childAt(element6, [7]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        morphs[1] = dom.createMorphAt(element7,4,4);
        morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
        morphs[3] = dom.createMorphAt(element8,1,1);
        morphs[4] = dom.createMorphAt(element8,3,3);
        morphs[5] = dom.createMorphAt(element8,5,5);
        morphs[6] = dom.createMorphAt(element9,1,1);
        morphs[7] = dom.createMorphAt(element9,3,3);
        morphs[8] = dom.createMorphAt(element9,5,5);
        morphs[9] = dom.createMorphAt(dom.childAt(element6, [9]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element6, [11]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element6, [13]),1,1);
        morphs[12] = dom.createMorphAt(element6,15,15);
        return morphs;
      },
      statements: [
        ["content","sample.itemLabel",["loc",[null,[4,12],[4,32]]]],
        ["content","sample.look.position",["loc",[null,[5,9],[5,33]]]],
        ["content","sample.styleNumber",["loc",[null,[8,4],[8,26]]]],
        ["content","sample.colour1Label",["loc",[null,[11,4],[11,27]]]],
        ["block","if",[["get","sample.colour2Label",["loc",[null,[13,10],[13,29]]]]],[],0,null,["loc",[null,[13,4],[15,11]]]],
        ["block","if",[["get","sample.colour3Label",["loc",[null,[17,10],[17,29]]]]],[],1,null,["loc",[null,[17,4],[19,11]]]],
        ["content","sample.fabric1Label",["loc",[null,[22,4],[22,27]]]],
        ["block","if",[["get","sample.fabric2Label",["loc",[null,[24,10],[24,29]]]]],[],2,null,["loc",[null,[24,4],[26,11]]]],
        ["block","if",[["get","sample.fabric3Label",["loc",[null,[28,10],[28,29]]]]],[],3,null,["loc",[null,[28,4],[30,11]]]],
        ["content","sample.description",["loc",[null,[33,4],[33,26]]]],
        ["content","sample.reference",["loc",[null,[36,4],[36,24]]]],
        ["block","if",[["get","barcodes64",["loc",[null,[39,10],[39,20]]]]],[],4,5,["loc",[null,[39,4],[43,11]]]],
        ["block","if",[["get","parcel.id",["loc",[null,[45,8],[45,17]]]]],[],6,null,["loc",[null,[45,2],[61,9]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});