define('bookalook/helpers/get-array-index-barcode-sample-property', ['exports', 'ember'], function (exports, Ember) {

  'use strict';


  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var array = params[0];
    var index = params[1];
    if (array != null && index != null) {
      var resultObject = search(index, array);
      if (resultObject != null) {
        return resultObject;
      }
    }
  });

  function search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].sampleId === nameKey) {
        return myArray[i].barcode;
      }
    }
  }

});