define('bookalook/routes/settings/trial-ended', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/settings/functions'], function (exports, Ember, UserValidatorMixin, plansFunctions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      selectMonthly: function selectMonthly() {
        plansFunctions['default'].changeSelectedInterval(this, 'month');
      },
      selectYearly: function selectYearly() {
        plansFunctions['default'].changeSelectedInterval(this, 'year');
      },
      setSelectedPlan: function setSelectedPlan(plan) {
        plansFunctions['default'].setSelectedPlan(this, plan);
      },
      goToStep2: function goToStep2() {
        this.onGoToStep2();
      },
      backToStep1: function backToStep1() {
        this.onBackToStep1();
      },
      createNewCard: function createNewCard() {
        plansFunctions['default'].createNewCard(this);
      },
      createNewCardAndPay: function createNewCardAndPay() {
        plansFunctions['default'].createNewCardAndPay(this);
      },
      completePurchase: function completePurchase() {
        plansFunctions['default'].completePurchase(this);
      },
      startCreateNewCard: function startCreateNewCard() {
        plansFunctions['default'].startCreateNewCard(this);
      },
      cancelCreateNewCard: function cancelCreateNewCard() {
        plansFunctions['default'].cancelCreateNewCard(this);
      },
      checkCardName: function checkCardName() {
        plansFunctions['default'].checkFailCardName(this);
      },
      checkCardNumber: function checkCardNumber() {
        plansFunctions['default'].checkFailCardNumber(this);
      },
      checkCardCvc: function checkCardCvc() {
        plansFunctions['default'].checkFailCardCvc(this);
      }

    },

    beforeModel: function beforeModel() {
      var trialEndDate = localStorage.getItem('trialEndDate');
      var todayDate = new Date().getTime();
      var dateDif = new Date(trialEndDate - todayDate);
      var daysToEnd = Math.round(dateDif / 1000 / 60 / 60 / 24);
      if (!Ember['default'].isNone(Ember['default'].user) && daysToEnd <= 0) {
        if (JSON.parse(localStorage.getItem('firstPaymentDone'))) {
          if (JSON.parse(localStorage.getItem('managerSubscriptionExpired'))) {
            this.replaceWith('settings.payment-failed');
          } else {
            this.replaceWith('session.login');
          }
        } else {
          //this.replaceWith('settings.trial-ended');
        }
      } else {
          this.replaceWith('session.login');
        }
      /*if (!Ember.user || !this.get('session.set_end_of_trial')){
        //this.send('openTopBarWithTime', "You subscription has not expired.", 3000);
        this.replaceWith('session.login');
      }*/
    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        showroom1: this.store.findRecord('showroom', Ember['default'].showroomId),
        user: this.store.findRecord('user', Ember['default'].userId),
        plans: plansFunctions['default'].getUniquePlans(this),
        planFromShManager: plansFunctions['default'].getPlanFromShowroomManager()
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      this._super(controller, model);
      controller.set('userLogged', Ember['default'].user);

      var showroom1 = controller.get('showroom1');

      controller.set('trialEndedPage', true);
      controller.set('doingStep2', false);
      plansFunctions['default'].initializeController(this);
    },

    onGoToStep2: function onGoToStep2() {
      this.controller.set('doingStep2', true);
      Ember['default'].$('body, html').animate({
        scrollTop: Ember['default'].$('body, html').offset().top
      }, 250);
    },

    onBackToStep1: function onBackToStep1() {
      this.controller.set('doingStep2', false);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-trial-end');
      if (!Ember['default'].user.get('isManager')) {
        Ember['default'].$('body').addClass('no-manager');
      }
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-trial-end');
      Ember['default'].$('body').removeClass('no-manager');
    },

    onOpenModal: function onOpenModal(model) {
      var idModal = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var _t = this;
      if (idModal !== null && idModal !== '') {
        _t.controller.set('previousModal', idModal);
        Ember['default'].$(idModal).modal('hide');
      } else {
        _t.controller.set('previousModal', null);
      }
      Ember['default'].$('#-modal').modal();
      Ember['default'].openModal();
    },

    onCloseModal: function onCloseModal() {
      var previousModal = this.controller.get('previousModal');
      Ember['default'].$("#modal").modal('hide');
      // let user = this.controller.get('prToEdit');
      // user.rollbackAttributes();
      if (previousModal !== null) {
        Ember['default'].$(previousModal).modal();
        Ember['default'].openModal();
        this.controller.set('previousModal', null);
      }
    },

    onSaveModal: function onSaveModal() {
      var _this2 = this;

      var _this = this;
      var previousModal = this.controller.get('previousModal');
      Ember['default'].$("#modal").modal('hide');
      var user = this.controller.get('prToEdit');
      _this.onOpenMessageModal('Saving profile...');
      user.save().then(function () {
        _this.send('closeMessageModal');
        //_t.onOpenTopBarWithTime("Profile saved correctly.", 3000);
        _t.onOpenPositiveTopBar();
        if (previousModal !== null) {
          Ember['default'].$(previousModal).modal();
          Ember['default'].openModal();
          _this2.controller.set('previousModal', null);
        }
      });
    }

  });

});