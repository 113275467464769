define('bookalook/models/parcel', ['exports', 'ember-data', 'ember', 'bookalook/mixins/model-validator', 'ember-cli-copyable'], function (exports, DS, Ember, Validator, Copyable) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], Copyable['default'], {
    street1In: DS['default'].attr('string'),
    street2In: DS['default'].attr('string'),
    street3In: DS['default'].attr('string'),
    countryIn: DS['default'].attr('string'),
    cityIn: DS['default'].attr('string'),
    zipCodeIn: DS['default'].attr('string'),
    telephone1In: DS['default'].attr('string'),
    telephone2In: DS['default'].attr('string'),
    attnIn: DS['default'].attr('string'),

    street1: DS['default'].attr('string'),
    street2: DS['default'].attr('string'),
    street3: DS['default'].attr('string'),
    country: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    zipCode: DS['default'].attr('string'),
    telephone1: DS['default'].attr('string'),
    telephone2: DS['default'].attr('string'),
    attnReturn: DS['default'].attr('string'),

    sender: DS['default'].attr('string'),
    requester: DS['default'].attr('string'),
    company: DS['default'].attr('string'),
    dateReturn: DS['default'].attr('day'),
    shippingDate: DS['default'].attr('day'),
    shootingLocation: DS['default'].attr('string'),

    deliveryMethod: DS['default'].attr('string'),
    courier: DS['default'].attr('string'),
    trackNumber: DS['default'].attr('string'),
    notes: DS['default'].attr('string'),

    booking: DS['default'].belongsTo('booking', { async: true, inverse: null }),
    samples: DS['default'].hasMany('sample', { async: true }),
    user: DS['default'].belongsTo('user', { async: true }),
    showroom: DS['default'].belongsTo('showroom', { async: true, inverse: null }),
    loanForm: DS['default'].belongsTo('loan-form', { async: true }),

    isShowroom: (function () {
      return this.get('showroom.street');
    }).property('showroom.street'),

    deliveryMethodLabel: (function () {
      return Ember['default'].deliveryMethodsLabels[this.get('deliveryMethod')];
    }).property('deliveryMethod'),

    numberOfSamples: (function () {
      return this.get('samples.length');
    }).property('samples'),

    checkoutDate: (function () {
      return this.get('samples.firstObject.sampleStatus.checkout');
    }).property('samples.@each.sampleStatus'),

    validations: {}
  });

});