define('bookalook/helpers/get-fullname', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //USE ONLY IN CASE OF OBJECT
    //IF MODEL: BETTER USE THE MODEL FUNCTION (get('fullname'))
    if (!params[0]) {
      return;
    }
    var object = params[0];

    var fullname = "";

    if (!Ember['default'].isBlank(object.name)) {
      if (!Ember['default'].isBlank(object.surname)) {
        fullname = fullname + object.name + " " + object.surname;
      } else {
        fullname = fullname + object.name;
      }
    } else if (!Ember['default'].isBlank(object.surname)) {
      fullname = fullname + object.surname;
    }

    return fullname;
  });

});