define('bookalook/components/multiple-select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    actions: {
      removeValue: function removeValue(value) {
        var lists = this.get('list');
        lists.removeObject(value);
        this.get('content').toArray().addObject(value);
      }
    },

    valueObserver: (function () {
      if (this.get('selectedValue')) {
        var type = this.get('type');
        var lists = this.get('list');
        var value = this.controller.get('selectedValue');

        if (type) {
          value = this.get('store').push(type, { id: this.get('selectedValue.id'), name: this.get('selectedValue.name') });
        }
        lists.addObject(value);

        this.set('selectedValue', null);
      }
    }).observes('selectedValue'),

    options: (function () {
      if (this.get('content') && this.get('list')) {
        return this.get('content').toArray().removeObjects(this.get('list').toArray());
      }
    }).property('selectedValue', 'content', 'list.@each')
  });

});