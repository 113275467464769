define('bookalook/templates/profile/brand-profile-old', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element9, 'href');
          morphs[1] = dom.createAttrMorph(element9, 'title');
          morphs[2] = dom.createMorphAt(element9,0,0);
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://www.twitter.com/",["get","model.twitter",["loc",[null,[14,39],[14,52]]]]]]],
          ["attribute","title",["concat",["https://www.twitter.com/",["get","model.twitter",["loc",[null,[14,105],[14,118]]]]]]],
          ["inline","fa-icon",["twitter"],["title","Twitter"],["loc",[null,[14,122],[14,159]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element8, 'href');
          morphs[1] = dom.createAttrMorph(element8, 'title');
          morphs[2] = dom.createMorphAt(element8,0,0);
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://www.facebook.com/",["get","model.facebook",["loc",[null,[18,40],[18,54]]]]]]],
          ["attribute","title",["concat",["https://www.facebook.com/",["get","model.facebook",["loc",[null,[18,108],[18,122]]]]]]],
          ["inline","fa-icon",["facebook"],["title","Facebook"],["loc",[null,[18,126],[18,165]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element7, 'href');
          morphs[1] = dom.createAttrMorph(element7, 'title');
          morphs[2] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://instagram.com/",["get","model.instagram",["loc",[null,[22,37],[22,52]]]]]]],
          ["attribute","title",["concat",["https://instagram.com/",["get","model.instagram",["loc",[null,[22,103],[22,118]]]]]]],
          ["inline","fa-icon",["instagram"],["title","Instragram"],["loc",[null,[22,122],[22,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 2
            },
            "end": {
              "line": 27,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element6, 'href');
          morphs[1] = dom.createAttrMorph(element6, 'title');
          morphs[2] = dom.createMorphAt(element6,0,0);
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://www.pinterest.com/",["get","model.pinterest",["loc",[null,[26,41],[26,56]]]]]]],
          ["attribute","title",["concat",["https://www.pinterest.com/",["get","model.pinterest",["loc",[null,[26,111],[26,126]]]]]]],
          ["inline","fa-icon",["pinterest"],["title","Pinterest"],["loc",[null,[26,130],[26,171]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 2
            },
            "end": {
              "line": 31,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element5, 'href');
          morphs[1] = dom.createAttrMorph(element5, 'title');
          morphs[2] = dom.createMorphAt(element5,0,0);
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",[["subexpr","format-url",[["get","model.website",["loc",[null,[30,26],[30,39]]]]],[],["loc",[null,[30,13],[30,41]]]]]]],
          ["attribute","title",["concat",[["subexpr","format-url",[["get","model.website",["loc",[null,[30,79],[30,92]]]]],[],["loc",[null,[30,66],[30,94]]]]]]],
          ["inline","fa-icon",["globe"],["title","Website"],["loc",[null,[30,96],[30,131]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 2
              },
              "end": {
                "line": 65,
                "column": 2
              }
            },
            "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-3");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","profile_showroom_info");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","profile_showroom_city");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","profile_showroom_members");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" members");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element4, [7]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element4, [9]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element4, [11]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element4, [13]),0,0);
            return morphs;
          },
          statements: [
            ["content","showroom.city",["loc",[null,[56,44],[56,61]]]],
            ["content","showroom.users.length",["loc",[null,[57,47],[57,72]]]],
            ["content","showroom.street",["loc",[null,[58,14],[58,33]]]],
            ["content","showroom.street2",["loc",[null,[59,14],[59,34]]]],
            ["content","showroom.zipCode",["loc",[null,[60,14],[60,34]]]],
            ["content","showroom.country",["loc",[null,[61,14],[61,34]]]],
            ["content","showroom.telephone",["loc",[null,[62,14],[62,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 2
            },
            "end": {
              "line": 66,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["subexpr","is-equal",[["get","showroom",["loc",[null,[53,22],[53,30]]]],["get","model.HQ",["loc",[null,[53,31],[53,39]]]]],[],["loc",[null,[53,12],[53,40]]]]],[],0,null,["loc",[null,[53,2],[65,13]]]]
        ],
        locals: ["showroom"],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 8
              },
              "end": {
                "line": 82,
                "column": 8
              }
            },
            "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","profile_collection_card");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","profile_collection_info");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","profile_collection_brand");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","profile_collection_type");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" - ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element1, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'style');
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
            morphs[2] = dom.createMorphAt(element2,0,0);
            morphs[3] = dom.createMorphAt(element2,2,2);
            morphs[4] = dom.createMorphAt(element2,4,4);
            return morphs;
          },
          statements: [
            ["attribute","style",["concat",["background-image: url(",["get","collection.avatar",["loc",[null,[76,76],[76,93]]]],")"]]],
            ["content","model.name",["loc",[null,[78,51],[78,65]]]],
            ["content","collection.collectionType",["loc",[null,[79,50],[79,79]]]],
            ["content","collection.season",["loc",[null,[79,82],[79,103]]]],
            ["content","collection.year",["loc",[null,[79,104],[79,123]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 2
            },
            "end": {
              "line": 85,
              "column": 2
            }
          },
          "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","profile_collection_card_wrapper");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element3,0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]),1,1);
          return morphs;
        },
        statements: [
          ["content","collection.seasonLabel",["loc",[null,[73,26],[73,52]]]],
          ["block","link-to",["profile.collection-profile",["get","collection.id",["loc",[null,[75,48],[75,61]]]]],[],0,null,["loc",[null,[75,8],[82,20]]]]
        ],
        locals: ["collection"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 87,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/profile/brand-profile-old.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-3 no-padding-left");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3,"class","profile_avatar");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-9");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3,"class","profile_name");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3,"class","profile_type");
        var el4 = dom.createTextNode("BRAND");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row profile_social_icons");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createElement("p");
        dom.setAttribute(el2,"class","profile_description");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2,"class","profile_section_title");
        var el3 = dom.createTextNode("Showrooms");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-3");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","profile_showroom_info profile_showroom_info_hq");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","profile_showroom_badge");
        var el5 = dom.createTextNode("HQ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","profile_showroom_city");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","profile_showroom_members");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" members");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2,"class","profile_section_title");
        var el3 = dom.createTextNode("Collections");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [1, 1]);
        var element12 = dom.childAt(fragment, [2]);
        var element13 = dom.childAt(fragment, [8]);
        var element14 = dom.childAt(element13, [1, 1]);
        var morphs = new Array(17);
        morphs[0] = dom.createAttrMorph(element11, 'src');
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [3, 1]),0,0);
        morphs[2] = dom.createMorphAt(element12,1,1);
        morphs[3] = dom.createMorphAt(element12,3,3);
        morphs[4] = dom.createMorphAt(element12,5,5);
        morphs[5] = dom.createMorphAt(element12,7,7);
        morphs[6] = dom.createMorphAt(element12,9,9);
        morphs[7] = dom.createMorphAt(dom.childAt(fragment, [4, 0]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element14, [3]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element14, [5]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element14, [7]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element14, [9]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element14, [11]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element14, [13]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element14, [15]),0,0);
        morphs[15] = dom.createMorphAt(element13,3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(fragment, [12]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","src",["get","model.avatar",["loc",[null,[3,15],[3,27]]]]],
        ["content","model.name",["loc",[null,[7,29],[7,43]]]],
        ["block","if",[["get","model.twitter",["loc",[null,[13,8],[13,21]]]]],[],0,null,["loc",[null,[13,2],[15,9]]]],
        ["block","if",[["get","model.facebook",["loc",[null,[17,8],[17,22]]]]],[],1,null,["loc",[null,[17,2],[19,9]]]],
        ["block","if",[["get","model.instagram",["loc",[null,[21,8],[21,23]]]]],[],2,null,["loc",[null,[21,2],[23,9]]]],
        ["block","if",[["get","model.pinterest",["loc",[null,[25,8],[25,23]]]]],[],3,null,["loc",[null,[25,2],[27,9]]]],
        ["block","if",[["get","model.website",["loc",[null,[29,8],[29,21]]]]],[],4,null,["loc",[null,[29,2],[31,9]]]],
        ["content","model.description",["loc",[null,[34,48],[34,69]]]],
        ["content","model.HQ.city",["loc",[null,[42,42],[42,59]]]],
        ["content","model.HQ.users.length",["loc",[null,[43,45],[43,70]]]],
        ["content","model.HQ.street",["loc",[null,[44,12],[44,31]]]],
        ["content","model.HQ.street2",["loc",[null,[45,12],[45,32]]]],
        ["content","model.HQ.zipCode",["loc",[null,[46,12],[46,32]]]],
        ["content","model.HQ.country",["loc",[null,[47,12],[47,32]]]],
        ["content","model.HQ.telephone",["loc",[null,[48,12],[48,34]]]],
        ["block","each",[["get","model.showrooms",["loc",[null,[52,10],[52,25]]]]],[],5,null,["loc",[null,[52,2],[66,11]]]],
        ["block","each",[["get","model.collections",["loc",[null,[72,10],[72,27]]]]],[],6,null,["loc",[null,[72,2],[85,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});