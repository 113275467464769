define('bookalook/helpers/country-label', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return;
    }
    var country = params[0];
    var countryLower = country.toLowerCase();
    if (countryLower === "united states of america") {
      country = "USA";
    } else if (countryLower.indexOf("united states of america") !== -1) {
      country = "USA";
    } else if (countryLower === "united kingdom") {
      country = "UK";
    }
    return country;
  });

});