define('bookalook/templates/components/register-form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 8
            },
            "end": {
              "line": 37,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","validated-radio-input",[],["model",["subexpr","@mut",[["get","agency",["loc",[null,[34,40],[34,46]]]]],[],[]],"property","type","labels",["subexpr","@mut",[["get","radioLabels",["loc",[null,[34,70],[34,81]]]]],[],[]],"class","col-xs-12"],["loc",[null,[34,10],[34,101]]]],
          ["inline","validated-input",[],["label","Company name","model",["subexpr","@mut",[["get","agency",["loc",[null,[35,55],[35,61]]]]],[],[]],"property","name","class","col-xs-12 hint-left"],["loc",[null,[35,10],[35,107]]]],
          ["inline","validated-input",[],["label","Website","model",["subexpr","@mut",[["get","agency",["loc",[null,[36,50],[36,56]]]]],[],[]],"property","website","class","col-xs-12 hint-left"],["loc",[null,[36,10],[36,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 10
              },
              "end": {
                "line": 48,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/components/register-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 margin-bottom");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("Agency invited: ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 hint-left validated-input");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 hint-left validated-input");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
            return morphs;
          },
          statements: [
            ["inline","input",[],["value",["subexpr","@mut",[["get","agency.name",["loc",[null,[43,28],[43,39]]]]],[],[]],"disabled",true,"class","form-control"],["loc",[null,[43,14],[43,76]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","agency.website",["loc",[null,[46,28],[46,42]]]]],[],[]],"disabled",true,"class","form-control"],["loc",[null,[46,14],[46,79]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 10
              },
              "end": {
                "line": 58,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/components/register-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 margin-bottom");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("Brand invited: ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 hint-left validated-input");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-12 hint-left validated-input");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
            return morphs;
          },
          statements: [
            ["inline","input",[],["value",["subexpr","@mut",[["get","brand.name",["loc",[null,[53,28],[53,38]]]]],[],[]],"disabled",true,"class","form-control"],["loc",[null,[53,14],[53,75]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","brand.website",["loc",[null,[56,28],[56,41]]]]],[],[]],"disabled",true,"class","form-control"],["loc",[null,[56,14],[56,78]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 8
            },
            "end": {
              "line": 59,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","agencyId",["loc",[null,[38,16],[38,24]]]]],[],0,1,["loc",[null,[38,10],[58,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 10
            },
            "end": {
              "line": 83,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-12 form-group");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-2 validated-radio-input");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createElement("div");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","image-avatar pull-left");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-7 col-sm-6 col-md-7 pull-right");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("strong");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-7 col-sm-6 col-md-7 pull-right");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [2]);
          var element3 = dom.childAt(element0, [3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element1,1,1);
          morphs[1] = dom.createAttrMorph(element2, 'for');
          morphs[2] = dom.createAttrMorph(element3, 'src');
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","radio-button",[],["checked",["subexpr","@mut",[["get","similarBrandSelected",["loc",[null,[71,39],[71,59]]]]],[],[]],"value",["subexpr","@mut",[["get","brand",["loc",[null,[71,66],[71,71]]]]],[],[]],"id",["subexpr","@mut",[["get","brand.brandId",["loc",[null,[71,75],[71,88]]]]],[],[]]],["loc",[null,[71,16],[71,90]]]],
          ["attribute","for",["concat",[["get","brand.brandId",["loc",[null,[71,104],[71,117]]]]]]],
          ["attribute","src",["concat",[["get","brand.avatar",["loc",[null,[74,28],[74,40]]]]]]],
          ["content","brand.name",["loc",[null,[77,24],[77,38]]]],
          ["content","brand.description",["loc",[null,[80,16],[80,37]]]]
        ],
        locals: ["brand"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 8
            },
            "end": {
              "line": 98,
              "column": 112
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 99,
              "column": 8
            },
            "end": {
              "line": 99,
              "column": 121
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 109,
              "column": 8
            },
            "end": {
              "line": 109,
              "column": 110
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 111,
              "column": 8
            },
            "end": {
              "line": 113,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","validated-select",[],["placeholder","State","model",["subexpr","@mut",[["get","showroom",["loc",[null,[112,55],[112,63]]]]],[],[]],"property","region","class","usa-states col-xs-12","optionLabelPath","id","content",["subexpr","@mut",[["get","usaStates",["loc",[null,[112,140],[112,149]]]]],[],[]]],["loc",[null,[112,10],[112,151]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 8
            },
            "end": {
              "line": 115,
              "column": 116
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 116,
              "column": 8
            },
            "end": {
              "line": 116,
              "column": 120
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 130,
              "column": 80
            },
            "end": {
              "line": 130,
              "column": 133
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Terms & Conditions");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 14
            },
            "end": {
              "line": 131,
              "column": 87
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("User Generated Content Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 108
            },
            "end": {
              "line": 131,
              "column": 171
            }
          },
          "moduleName": "bookalook/templates/components/register-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Privacy & Cookie Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 152,
            "column": 9
          }
        },
        "moduleName": "bookalook/templates/components/register-form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","register-form col-xs-12 col-sm-8 col-sm-offset-2");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","form-inner row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 col-sm-10 col-sm-offset-1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","tabs-menu row");
        dom.setAttribute(el4,"role","tablist");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"role","presentation");
        dom.setAttribute(el5,"class","col-xs-4 active");
        dom.setAttribute(el5,"id","step1-menu-item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs-menu-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7,"class","item-label");
        var el8 = dom.createTextNode("ABOUT YOU");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","glyphicon glyphicon-triangle-right pull-right");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"role","presentation");
        dom.setAttribute(el5,"class","col-xs-4");
        dom.setAttribute(el5,"id","step2-menu-item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs-menu-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7,"class","item-label");
        var el8 = dom.createTextNode("YOUR COMPANY");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","glyphicon glyphicon-triangle-right pull-right");
        dom.setAttribute(el8,"aria-hidden","true");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"role","presentation");
        dom.setAttribute(el5,"class","col-xs-4");
        dom.setAttribute(el5,"id","step3-menu-item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs-menu-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7,"class","item-label");
        var el8 = dom.createTextNode("YOUR SHOWROOM");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" Tab panes ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tab-content row");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane active col-xs-12 col-sm-10 col-sm-offset-1");
        dom.setAttribute(el4,"id","step1");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-sm-offset-8");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","raised btn btn-primary btn-block btn-step-one");
        var el7 = dom.createTextNode("NEXT");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane col-xs-12 col-sm-10 col-sm-offset-1");
        dom.setAttribute(el4,"id","step2");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-sm-offset-8");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","raised btn btn-primary btn-block btn-step-two");
        var el7 = dom.createTextNode("NEXT");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane col-xs-12 col-sm-10 col-sm-offset-1");
        dom.setAttribute(el4,"id","step3");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","text-confirm text-center");
        var el6 = dom.createTextNode("Please, confirm if your Brand is one of the listed below:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 form-group");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-2 validated-radio-input");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createElement("div");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-10 brand-not-listed row");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("No, my brand is not listed above.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 col-sm-4 col-sm-offset-8");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","raised btn btn-primary btn-block btn-step-two-confirm");
        var el7 = dom.createTextNode("NEXT");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tab-pane col-xs-12 col-sm-10 col-sm-offset-1");
        dom.setAttribute(el4,"id","step4");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("<div class=\"col-xs-12\">How many people in your showroom might use Bookalook?</div>\n        {{#validated-input label=\"Type a number\" model=showroom property=\"peopleNumber\" class=\"col-xs-12 hint-left\"}}{{/validated-input}}");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 margin-top");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","row");
        var el7 = dom.createTextNode("\n           ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-1 validated-input hint-right");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","validated-hint");
        var el9 = dom.createTextNode("You must accept terms & conditions");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-11");
        var el8 = dom.createTextNode("\n              Please tick this box to confirm that you have read and accept our ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(",\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" and our ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(".\n              If you do not accept these Terms of Business, specially the Terms of Business for Brands and PR Agencies you must not register to use our Platform.\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n           \n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 margin-top");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","raised btn btn-primary btn-block btn-step-three text-uppercase");
        var el7 = dom.createTextNode("Create account");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n$( document ).ready(function() {\n   // console.log( \"ready!\" );\n     $('.beta_topbar ').hide();\n       $('.main_menu_wrapper').hide();\n       $('#test_footer').hide();\n  \n});\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 1, 5]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [13, 0]);
        var element7 = dom.childAt(element4, [3]);
        var element8 = dom.childAt(element7, [3, 0]);
        var element9 = dom.childAt(element4, [5]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [3, 1]);
        var element12 = dom.childAt(element11, [2]);
        var element13 = dom.childAt(element9, [5, 0]);
        var element14 = dom.childAt(element4, [7]);
        var element15 = dom.childAt(element14, [17, 1]);
        var element16 = dom.childAt(element15, [3]);
        var element17 = dom.childAt(element14, [19, 0]);
        var morphs = new Array(25);
        morphs[0] = dom.createMorphAt(element5,1,1);
        morphs[1] = dom.createMorphAt(element5,3,3);
        morphs[2] = dom.createMorphAt(element5,5,5);
        morphs[3] = dom.createMorphAt(element5,7,7);
        morphs[4] = dom.createMorphAt(element5,9,9);
        morphs[5] = dom.createMorphAt(element5,11,11);
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createMorphAt(element7,1,1);
        morphs[8] = dom.createElementMorph(element8);
        morphs[9] = dom.createMorphAt(element10,1,1);
        morphs[10] = dom.createMorphAt(element11,1,1);
        morphs[11] = dom.createAttrMorph(element12, 'for');
        morphs[12] = dom.createElementMorph(element13);
        morphs[13] = dom.createMorphAt(element14,1,1);
        morphs[14] = dom.createMorphAt(element14,3,3);
        morphs[15] = dom.createMorphAt(element14,5,5);
        morphs[16] = dom.createMorphAt(element14,7,7);
        morphs[17] = dom.createMorphAt(element14,9,9);
        morphs[18] = dom.createMorphAt(element14,11,11);
        morphs[19] = dom.createMorphAt(element14,13,13);
        morphs[20] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
        morphs[21] = dom.createMorphAt(element16,1,1);
        morphs[22] = dom.createMorphAt(element16,3,3);
        morphs[23] = dom.createMorphAt(element16,5,5);
        morphs[24] = dom.createElementMorph(element17);
        return morphs;
      },
      statements: [
        ["inline","validated-input",[],["label","Name","model",["subexpr","@mut",[["get","user",["loc",[null,[21,45],[21,49]]]]],[],[]],"property","name","class","col-xs-12 col-sm-6 hint-left"],["loc",[null,[21,8],[21,104]]]],
        ["inline","validated-input",[],["label","Surname","model",["subexpr","@mut",[["get","user",["loc",[null,[22,48],[22,52]]]]],[],[]],"property","surname","class","col-xs-12 col-sm-6 hint-right"],["loc",[null,[22,8],[22,111]]]],
        ["inline","validated-input",[],["label","Job title","model",["subexpr","@mut",[["get","user",["loc",[null,[23,50],[23,54]]]]],[],[]],"property","jobTitle","class","col-xs-12 hint-left"],["loc",[null,[23,8],[23,104]]]],
        ["inline","validated-input",[],["label","Email","model",["subexpr","@mut",[["get","user",["loc",[null,[24,46],[24,50]]]]],[],[]],"property","email","class","col-xs-12 hint-left"],["loc",[null,[24,8],[24,97]]]],
        ["inline","validated-input",[],["label","Password","type","password","model",["subexpr","@mut",[["get","user",["loc",[null,[25,65],[25,69]]]]],[],[]],"property","plainPassword","class","col-xs-12 hint-left"],["loc",[null,[25,8],[25,124]]]],
        ["inline","validated-input",[],["label","Plan","model",["subexpr","@mut",[["get","user",["loc",[null,[26,45],[26,49]]]]],[],[]],"property","registerPlan","class","hide col-xs-12 hint-left"],["loc",[null,[26,8],[26,108]]]],
        ["element","action",["checkStepOne"],[],["loc",[null,[28,64],[28,89]]]],
        ["block","unless",[["get","invited",["loc",[null,[33,18],[33,25]]]]],[],0,1,["loc",[null,[33,8],[59,19]]]],
        ["element","action",["checkStepTwo"],[],["loc",[null,[61,64],[61,89]]]],
        ["block","each",[["get","similarBrands",["loc",[null,[68,18],[68,31]]]]],[],2,null,["loc",[null,[68,10],[83,19]]]],
        ["inline","radio-button",[],["checked",["subexpr","@mut",[["get","similarBrandSelected",["loc",[null,[86,39],[86,59]]]]],[],[]],"value",null,"id",["subexpr","@mut",[["get","nullBrandId",["loc",[null,[86,74],[86,85]]]]],[],[]]],["loc",[null,[86,16],[86,87]]]],
        ["attribute","for",["concat",[["get","nullBrandId",["loc",[null,[86,101],[86,112]]]]]]],
        ["element","action",["checkStepThree"],[],["loc",[null,[93,64],[93,91]]]],
        ["block","validated-input",[],["label","Street address","model",["subexpr","@mut",[["get","showroom",["loc",[null,[98,56],[98,64]]]]],[],[]],"property","street","class","col-xs-12 hint-left"],3,null,["loc",[null,[98,8],[98,132]]]],
        ["block","validated-input",[],["label","Apt / Floor / Suite...","model",["subexpr","@mut",[["get","showroom",["loc",[null,[99,64],[99,72]]]]],[],[]],"property","street2","class","col-xs-12 hint-left"],4,null,["loc",[null,[99,8],[99,141]]]],
        ["inline","validated-select",[],["placeholder","Country","content",["subexpr","@mut",[["get","Ember.countries",["loc",[null,[102,28],[102,43]]]]],[],[]],"model",["subexpr","@mut",[["get","showroom",["loc",[null,[103,28],[103,36]]]]],[],[]],"property","country","class","col-xs-12 hint-left col-sm-6","optionLabelPath","name","optionValuePath","name"],["loc",[null,[100,8],[108,10]]]],
        ["block","validated-input",[],["label","City","model",["subexpr","@mut",[["get","showroom",["loc",[null,[109,46],[109,54]]]]],[],[]],"property","city","class","col-xs-12 col-sm-6 hint-right"],5,null,["loc",[null,[109,8],[109,130]]]],
        ["block","if",[["get","isUsa",["loc",[null,[111,14],[111,19]]]]],[],6,null,["loc",[null,[111,8],[113,15]]]],
        ["block","validated-input",[],["label","Postcode","model",["subexpr","@mut",[["get","showroom",["loc",[null,[115,50],[115,58]]]]],[],[]],"property","zipCode","class","col-xs-12 col-sm-6 hint-left"],7,null,["loc",[null,[115,8],[115,136]]]],
        ["block","validated-input",[],["label","Telephone","model",["subexpr","@mut",[["get","showroom",["loc",[null,[116,51],[116,59]]]]],[],[]],"property","telephone","class","col-xs-12 col-sm-6 hint-right"],8,null,["loc",[null,[116,8],[116,140]]]],
        ["inline","input",[],["type","checkbox","property","acceptTerms","checked",["subexpr","@mut",[["get","showroom.checked",["loc",[null,[125,32],[125,48]]]]],[],[]],"class","form-control check-terms"],["loc",[null,[122,14],[126,60]]]],
        ["block","link-to",["terms-and-conditions"],[],9,null,["loc",[null,[130,80],[130,145]]]],
        ["block","link-to",["user-generated-content-policy"],[],10,null,["loc",[null,[131,14],[131,99]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],11,null,["loc",[null,[131,108],[131,183]]]],
        ["element","action",["checkStepFour"],[],["loc",[null,[139,50],[139,76]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
    };
  }()));

});