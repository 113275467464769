define('bookalook/models/file', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment'], function (exports, Ember, DS, Validator, config) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    path: DS['default'].attr('string'),
    job: DS['default'].belongsTo('job'),

    webpath: (function () {
      return config['default'].APIHost + '/uploads/documents/files/' + this.get('path');
    }).property('path'),

    save: function save() {
      if (this.get('toDelete')) {
        return this._super();
      }

      if (Ember['default'].isNone(this.get('file'))) {
        return;
      }

      var formData = this.getFormData();
      return Ember['default'].$.ajax({
        url: config['default'].APIURL + 'files',
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("file", "file");
        },
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function success() {}
      });
    },

    getFormData: function getFormData() {
      var file = this.get('file');
      var formData = new FormData();

      formData.append('bookalook_filebundle_file[name]', this.get('name'));
      formData.append('bookalook_filebundle_file[job]', this.get('job.id'));
      formData.append('bookalook_filebundle_file[file]', file);

      return formData;
    },

    nameWithoutExtension: (function () {
      var name = this.get('name');
      if (Ember['default'].isBlank(name)) {
        return name;
      }

      var lastDotPosition = name.lastIndexOf(".");
      if (lastDotPosition === -1) {
        return name;
      } else {
        return name.substring(0, lastDotPosition);
      }
    }).property('name')

  });

});