define('bookalook/components/inline-combo-edition', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['inline_edition'],
    classNameBindings: ['is-dirty'],

    'is-dirty': false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', '-');
      }
      this.set('initialValue', this.get('value'));
    },

    click: function click() {
      if (Ember['default'].user.get('isPR')) {
        var $el = Ember['default'].$(this.element);
        $el.unbind("select2-selecting");
        if (!$el.hasClass('edit_mode')) {
          $el.fadeOut(function () {
            $el.addClass('edit_mode');
            $el.show();
            $el.find('.select2-container').select2('open');
            $el.on("select2-selecting", function () {
              $el.blur();
            });
          });
        }
      }
      //Disable parent click function(was toggling look card in new-look.hbs)
      return false;
    },

    focusOut: function focusOut() {
      var $el = Ember['default'].$(this.element);
      $el.hide();
      $el.removeClass('edit_mode');
      this.set('is-dirty', this.get('initialValue') !== this.get('value'));
      $el.fadeIn();
    }
  });

});