define('bookalook/helpers/block-email', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return false;
    } else {
      if (Ember['default'].user) {
        //Corporate: grant access in all cases
        if (Ember['default'].user.get('isCorporate')) {
          return false;
        } else if (Ember['default'].user.get('isStarter')) {
          return true;
        } else {
          return !JSON.parse(localStorage.getItem('firstPaymentDone'));
        }
      } else {
        return false;
      }
    }
  });

});