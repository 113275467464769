define('bookalook/routes/profile/user-profile', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      collapseAreaAndScroll: function collapseAreaAndScroll(textSelector, textId, scrSel, scrId) {
        this.onCollapseAreaAndScroll(textSelector, textId, scrSel, scrId);
      },
      collapseArea: function collapseArea(selector, id) {
        this.onCollapseArea(selector, id);
      }
    },

    model: function model(params) {
      return this.store.findRecord('user', params.id);
    },
    activate: function activate() {
      Ember['default'].$('body').addClass('page-user-detail');
      Ember['default'].$('.search_suggestion_wrapper').removeClass('show');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-user-detail');
    },
    onCollapseArea: function onCollapseArea(selector, id) {
      var el = Ember['default'].$('#' + selector + id).find('a');
      var oldText = el.text();
      var newText = oldText.trim()[0] === 'S' ? oldText.replace('Show all', 'Hide') : oldText.replace('Hide', 'Show all');
      el.text(newText);
    },

    onCollapseAreaAndScroll: function onCollapseAreaAndScroll(textSelector, scrSel, id) {
      var el = Ember['default'].$('#' + textSelector + id).find('a');
      var scr = Ember['default'].$('#' + scrSel + id);
      var oldText = el.text();
      var newText = "";
      if (oldText.trim()[0] === 'S') {
        newText = oldText.replace('Show all (+', 'Hide (-');
      } else {
        newText = oldText.replace('Hide (-', 'Show all (+');
        $('html, body').animate({ scrollTop: scr.offset().top });
      }
      el.text(newText);
    }
  });

});