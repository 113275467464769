define('bookalook/helpers/fix-url-protocol', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return;
    }
    var url = params[0];
    var generalFormatUrl = url;
    if (url.indexOf('//') == -1) {
      generalFormatUrl = "http://" + url;
    }

    /*  let urlArray = url.split("//");
    
      if (urlArray[0]=="http:" || urlArray[0]=="https:"){
        urlArray.shift();
      }
      generalFormatUrl = "//"+urlArray.join();*/
    return generalFormatUrl;
  });

});