define('bookalook/templates/collections/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 8
            },
            "end": {
              "line": 15,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_circle archive_modal_ddt_btn");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_bal_circle samples_list_ddt_btn");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element7);
          morphs[1] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["element","action",["openSelectSamplesToArchiveModal"],[],["loc",[null,[13,77],[13,121]]]],
          ["element","action",["openDdtSamplesListModal"],[],["loc",[null,[14,76],[14,112]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/collections/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","right_col");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dropdown group_actions");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","dropdown-toggle btn_bal_primary");
          dom.setAttribute(el3,"data-toggle","dropdown");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","caret");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3,"class","dropdown-menu group_actions_menu");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("Active");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("Archived");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[1] = dom.createAttrMorph(element5, 'class');
          morphs[2] = dom.createElementMorph(element5);
          morphs[3] = dom.createAttrMorph(element6, 'class');
          morphs[4] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["content","collectionStatusFilter",["loc",[null,[26,81],[26,107]]]],
          ["attribute","class",["concat",[["subexpr","unless",[["get","showArchivedCollections",["loc",[null,[28,76],[28,99]]]],"active"],[],["loc",[null,[28,67],[28,110]]]]]]],
          ["element","action",["showArchivedCollections",false],[],["loc",[null,[28,16],[28,59]]]],
          ["attribute","class",["concat",[["subexpr","if",[["get","showArchivedCollections",["loc",[null,[29,71],[29,94]]]],"active"],[],["loc",[null,[29,66],[29,105]]]]]]],
          ["element","action",["showArchivedCollections",true],[],["loc",[null,[29,16],[29,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 16
                  },
                  "end": {
                    "line": 45,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/collections/index.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","partial",["collections/partials/collection-card"],[],["loc",[null,[44,18],[44,68]]]]
              ],
              locals: ["collection"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 14
                },
                "end": {
                  "line": 47,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/collections/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","cards-paginated-container");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","paginatedCollections",["loc",[null,[43,24],[43,44]]]]],[],0,null,["loc",[null,[43,16],[45,25]]]]
            ],
            locals: ["paginatedCollections"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 12
              },
              "end": {
                "line": 48,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/collections/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","simple-pagination",[],["itemsByPage",12,"items",["subexpr","@mut",[["get","collectionsList",["loc",[null,[41,56],[41,71]]]]],[],[]]],0,null,["loc",[null,[41,14],[47,36]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 12
              },
              "end": {
                "line": 50,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/collections/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","text-center");
            var el2 = dom.createTextNode("No collections found.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","collections-gallery clearfix");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","collectionsList.length",["loc",[null,[40,18],[40,40]]]]],[],0,1,["loc",[null,[40,12],[50,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 8
            },
            "end": {
              "line": 59,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/collections/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","empty-warn-in-big");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/new_bookings_black.svg");
          dom.setAttribute(el2,"title","Create a collection!");
          dom.setAttribute(el2,"data-toggle","modal");
          dom.setAttribute(el2,"data-target","#new-collection");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("You currently have no collections on Bookalook.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Start by creating a collection in just one click!.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","btn_bal_primary");
          dom.setAttribute(el2,"data-toggle","modal");
          dom.setAttribute(el2,"data-target","#new-collection");
          var el3 = dom.createTextNode("Create Collection");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [7]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [
          ["element","action",["openNewCollectionModal"],[],["loc",[null,[54,53],[54,88]]]],
          ["element","action",["openNewCollectionModal"],[],["loc",[null,[57,34],[57,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/collections/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 content-for-page");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("<div class=\"header-wrapper clearfix\">\n      <h1 class=\"section_title_uirefactor\">Collections</h1>\n    </div>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","menu-wrapper clearfix filters_top");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","left_col");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"class","btn_bal_circle add_btn");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"data-toggle","modal");
        dom.setAttribute(el5,"data-target","#new-collection");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","icon");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"class","btn_bal_circle report_btn");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","icon");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-group validated-input searcher");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","images/lupa.png");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"autocomplete","off");
        dom.setAttribute(el5,"id","no-autocomplete");
        dom.setAttribute(el5,"onsubmit","return false;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","bookings-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 no-padding");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0, 1]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element11, [3]);
        var morphs = new Array(13);
        morphs[0] = dom.createElementMorph(element12);
        morphs[1] = dom.createElementMorph(element13);
        morphs[2] = dom.createMorphAt(element11,5,5);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [3, 3]),1,1);
        morphs[4] = dom.createMorphAt(element10,5,5);
        morphs[5] = dom.createMorphAt(dom.childAt(element9, [5, 1]),1,1);
        morphs[6] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[7] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[8] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[9] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[10] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[12] = dom.createMorphAt(fragment,14,14,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["openNewCollectionModal"],[],["loc",[null,[10,61],[10,96]]]],
        ["element","action",["generateArchivedSamplesReport"],[],["loc",[null,[11,64],[11,106]]]],
        ["block","unless",[["get","userLogged.isStarter",["loc",[null,[12,18],[12,38]]]]],[],0,null,["loc",[null,[12,8],[15,19]]]],
        ["inline","input",[],["type","text","id","collections-search-filter","class","search-filter","value",["subexpr","@mut",[["get","searchInput",["loc",[null,[20,89],[20,100]]]]],[],[]],"placeholder","Search..."],["loc",[null,[20,10],[20,126]]]],
        ["block","unless",[["subexpr","if-or",[["get","userLogged.isStarter",["loc",[null,[23,23],[23,43]]]],["get","userLogged.isDesigner",["loc",[null,[23,44],[23,65]]]]],[],["loc",[null,[23,16],[23,66]]]]],[],1,null,["loc",[null,[23,6],[33,17]]]],
        ["block","if",[["get","model.length",["loc",[null,[38,14],[38,26]]]]],[],2,3,["loc",[null,[38,8],[59,15]]]],
        ["inline","partial",["collections/modals/new-album"],[],["loc",[null,[65,0],[65,43]]]],
        ["inline","partial",["collections/modals/new-collection"],[],["loc",[null,[66,0],[66,48]]]],
        ["inline","partial",["collections/modals/archive-collection"],[],["loc",[null,[67,0],[67,51]]]],
        ["inline","partial",["collections/modals/delete-collection"],[],["loc",[null,[68,0],[68,50]]]],
        ["inline","partial",["collections/modals/activate-collection"],[],["loc",[null,[69,0],[69,52]]]],
        ["inline","partial",["collections/modals/ddt-samples"],[],["loc",[null,[70,0],[70,44]]]],
        ["inline","partial",["collections/modals/ddt-samples-list"],[],["loc",[null,[71,0],[71,49]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});