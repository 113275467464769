define('bookalook/tests/routes/collections/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/collections/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/collections/index.js should pass jshint.\nroutes/collections/index.js: line 31, col 91, Missing semicolon.\nroutes/collections/index.js: line 32, col 75, Missing semicolon.\nroutes/collections/index.js: line 33, col 71, Missing semicolon.\nroutes/collections/index.js: line 34, col 119, Missing semicolon.\nroutes/collections/index.js: line 35, col 99, Missing semicolon.\nroutes/collections/index.js: line 37, col 48, Missing semicolon.\nroutes/collections/index.js: line 38, col 57, Missing semicolon.\nroutes/collections/index.js: line 346, col 40, [\'name\'] is better written in dot notation.\nroutes/collections/index.js: line 367, col 82, Unreachable \';\' after \'return\'.\nroutes/collections/index.js: line 367, col 82, Unnecessary semicolon.\nroutes/collections/index.js: line 507, col 31, Missing semicolon.\n\n11 errors');
  });

});