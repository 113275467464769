define('bookalook/helpers/get-array-element-property', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var array = params[0];
    var index = params[1];
    var property = params[2];
    if (array != null && index != null && property != null) {
      var element = array[index];
      if (element != null) {
        return element.get(property);
      }
    }
  });

});