define('bookalook/models/contact-address', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    street1: DS['default'].attr('string'),
    street2: DS['default'].attr('string'),
    street3: DS['default'].attr('string'),
    country: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    zipCode: DS['default'].attr('string'),
    telephone1: DS['default'].attr('string'),
    telephone2: DS['default'].attr('string'),
    region: DS['default'].attr('string'),
    contact: DS['default'].belongsTo('contact', { async: true, inverse: 'privateAddresses' }),
    'public': DS['default'].belongsTo('contact', { async: true, inverse: 'publicAddress' }),
    position: DS['default'].attr('string'),
    mpUser: DS['default'].belongsTo('user', { async: true }),
    main: DS['default'].attr('boolean'),

    isFormDataEmpty: function isFormDataEmpty() {
      if (Ember.isEmpty(this.get('street1')) && Ember.isEmpty(this.get('city')) && Ember.isEmpty(this.get('country')) && Ember.isEmpty(this.get('zipCode')) && Ember.isEmpty(this.get('telephone1')) && Ember.isEmpty(this.get('telephone2'))) {
        return true;
      } else {
        return false;
      }
    },

    isUSA: (function () {
      var r = false;
      if (this.get('country') === 'United States') {
        r = true;
      }
      return r;
    }).property('country'),

    getBillingAddress: function getBillingAddress(requester, vat) {
      return [requester, this.get('street1'), this.get('street2') + ' ' + this.get('street3'), this.get('city') + ' ' + this.get('region') + ' ' + this.get('zipCode'), this.get('country'), 'P.IVA ' + vat].join('\n').replace(/\bnull\b/g, '');
    },

    getOrderAddress: function getOrderAddress(attn, company) {
      return [company, this.get('street1'), this.get('street2') + ' ' + this.get('street3'), this.get('city') + ' ' + this.get('region') + ' ' + this.get('zipCode'), this.get('country'), 'Attn: ' + attn, this.get('telephone1')].join('\n').replace(/\bnull\b/g, '');
    },

    hasSomeField: (function () {
      var params = [];
      params.push(this.get('street1'));
      params.push(this.get('street2'));
      params.push(this.get('street3'));
      params.push(this.get('country'));
      params.push(this.get('city'));
      params.push(this.get('zipCode'));
      params.push(this.get('telephone1'));
      params.push(this.get('telephone2'));
      return params.some(function (p) {
        return !Ember.isBlank(p);
      });
    }).property('street1', 'street2', 'street3', 'country', 'city', 'zipCode', 'telephone1', 'telephone2'),

    hasMpUser: (function () {
      //TO EVADE contact-address.get('mpUser').get('content');
      return this._internalModel._relationships.get("mpUser").members.size !== 0;
    }).property('mpUser'),

    replaceData: (function () {
      var listValue = this.get('listSelector');
      if (!Ember.isNone(listValue)) {
        this.set('street1', listValue.street1);
        this.set('street2', listValue.street2);
        this.set('street3', listValue.street3);
        this.set('city', listValue.city);
        //this.set('region', listValue.region);
        this.set('zipCode', listValue.zipCode);
        this.set('country', listValue.country);
        this.set('telephone1', listValue.telephone1);
        this.set('telephone2', listValue.telephone2);

        this.set('listSelector', null);
      }
    }).observes('listSelector'),

    validations: {
      'street1': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('street1'));
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'street2': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return true;
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'street3': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('street3'));
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'telephone1': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('telephone1'));
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'telephone2': { custom: [{
          validation: function validation(key, value, model) {
            return model.get('hasSomeField');
          }
        }] },

      'country': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('country'));
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'city': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('city'));
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'zipCode': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('zipCode'));
            } else {
              return model.get('hasSomeField');
            }
          }
        }] },

      'position': { custom: [{
          validation: function validation(key, value, model) {
            if (model.get('hasMpUser')) {
              return !Ember.isBlank(model.get('position'));
            } else {
              return true;
            }
          }
        }] }

    }
  });
  /*'street1': {
    presence: true
  },
   'zipCode': {
    presence: true
  },
   'city': {
    presence: true
  },
   'country': {
    presence: true
  }*/

});