define('bookalook/tests/routes/bookings/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/bookings/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/bookings/index.js should pass jshint.\nroutes/bookings/index.js: line 44, col 80, Missing semicolon.\nroutes/bookings/index.js: line 50, col 80, Missing semicolon.\nroutes/bookings/index.js: line 52, col 105, Missing semicolon.\nroutes/bookings/index.js: line 61, col 86, Missing semicolon.\nroutes/bookings/index.js: line 105, col 73, Missing semicolon.\nroutes/bookings/index.js: line 106, col 65, Missing semicolon.\nroutes/bookings/index.js: line 107, col 93, Missing semicolon.\nroutes/bookings/index.js: line 108, col 87, Missing semicolon.\nroutes/bookings/index.js: line 109, col 87, Missing semicolon.\nroutes/bookings/index.js: line 118, col 60, Missing semicolon.\nroutes/bookings/index.js: line 119, col 89, Missing semicolon.\nroutes/bookings/index.js: line 970, col 65, Missing semicolon.\nroutes/bookings/index.js: line 1077, col 31, Missing semicolon.\nroutes/bookings/index.js: line 1099, col 31, Missing semicolon.\nroutes/bookings/index.js: line 1147, col 38, [\'all\'] is better written in dot notation.\nroutes/bookings/index.js: line 1161, col 59, Missing semicolon.\nroutes/bookings/index.js: line 1163, col 55, Missing semicolon.\nroutes/bookings/index.js: line 1167, col 38, [\'all\'] is better written in dot notation.\nroutes/bookings/index.js: line 1597, col 16, Duplicate key \'onCancelAddMp\'.\nroutes/bookings/index.js: line 1670, col 4, Missing semicolon.\n\n20 errors');
  });

});