define('bookalook/helpers/album-type', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return;
    }

    var album = params[0];
    var type = album.albumtype;
    if (type && type.toLowerCase() === 'other') {
      type = album.other;
    }
    return type;
  });

});