define('bookalook/mixins/user-validator-mixin', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'bookalook/route-access-data'], function (exports, Ember, AuthenticatedRouteMixin, route_access_data) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create(AuthenticatedRouteMixin['default'], {

    beforeModel: function beforeModel(transition, queryParams) {
      if (Ember['default'].user) {
        if (Ember['default'].user.get("isOnboarding")) {
          //TRANSITION TO ONBOARDING
          this.replaceWith('onboarding.index');
          return;
        }
      }
      var key = this.get('routeName');

      //CHECK PAYMENT FAILED OR TRIAL
      if (!Ember['default'].isNone(Ember['default'].user) && Ember['default'].user.get("type") == "PR") {
        var trialEndDate = localStorage.getItem('trialEndDate');
        if (trialEndDate) {
          var todayDate = new Date().getTime();
          var dateDif = new Date(trialEndDate - todayDate);
          var daysToEnd = Math.round(dateDif / 1000 / 60 / 60 / 24);
          if (daysToEnd <= 0) {
            if (JSON.parse(localStorage.getItem('firstPaymentDone'))) {
              if (JSON.parse(localStorage.getItem('managerSubscriptionExpired'))) {
                this.replaceWith('settings.payment-failed');
                return;
              } else {
                //Its Ok, he has active subscription
              }
            } else {
                this.replaceWith('settings.trial-ended');
                return;
              }
          } else {
            //Its Ok, he is trialing
          }
        }
      }

      var key_parts = key.split('.');
      //IF USER IS TRYING TO GO TO ONBOARDING BUT HAS DONE IT: BLOCK TRANSITION
      if (key_parts[0] == "onboarding") {
        this.replaceWith('session.login');
        return;
      }
      var roles = route_access_data.routeAccessData.routes;
      for (var i = 0; i < key_parts.length; i++) {
        if (roles[key_parts[i]] !== undefined) {
          roles = roles[key_parts[i]];
        }
      }
      if (!roles.includes("non-user")) {

        if (Ember['default'].isNone(Ember['default'].user)) {
          this.replaceWith('session.login');
          return;
        }

        this._super(transition, queryParams);

        var userType = Ember['default'].user.get("type");
        var superAdmin = Ember['default'].user.get("isSuperAdmin");
        if (!superAdmin && !roles.includes(userType)) {
          //TRANSITION TO INDEX OR FORBIDEN PAGE
          //console.log("FORBIDDEN!!!!");
          this.transitionTo('session.login');
        }
      }
    }
  });

});