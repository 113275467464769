define('bookalook/helpers/get-currency-amount', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      //starter, designer, pro, corporate
      return;
    }
    if (Ember['default'].isNone(params[1])) {
      //true = Monthly /&/ false = Yearly
      return;
    }
    if (!params[2]) {
      //gbp, usd, eur
      return;
    }
    var type = params[0].toUpperCase();
    var interval = params[1] ? 'monthly' : 'yearly';
    var currency = params[2].toUpperCase();

    var result = {
      'STARTER': {
        'monthly': {
          'GBP': "19",
          'EUR': "21",
          'USD': "25"
        },
        'yearly': {
          'GBP': "183",
          'EUR': "202",
          'USD': "240"
        }
      },
      'DESIGNER': {
        'monthly': {
          'GBP': "59",
          'EUR': "65",
          'USD': "77"
        },
        'yearly': {
          'GBP': "567",
          'EUR': "624",
          'USD': "740"
        }
      },
      'PRO': {
        'monthly': {
          'GBP': "129",
          'EUR': "159",
          'USD': "159"
        },
        'yearly': {
          'GBP': "1,239",
          'EUR': "1,429",
          'USD': "1,529"
        }
      }
    };
    var currencyIndex = null;
    if (currency === "GBP" || currency === "USD" || currency === "EUR") {
      currencyIndex = currency;
    } else {
      if (!Ember['default'].isNone(Ember['default'].user)) {
        var stripePlan = Ember['default'].user.get('registerPlan');
        if (!Ember['default'].isNone(stripePlan) && stripePlan != "") {
          stripePlan = stripePlan.toUpperCase();
          if (stripePlan.indexOf("GBP") !== -1) {
            currencyIndex = "GBP";
          } else if (stripePlan.indexOf("USD") !== -1) {
            currencyIndex = "USD";
          } else if (stripePlan.indexOf("EUR") !== -1) {
            currencyIndex = "EUR";
          }
        }
      }
    }

    if (currencyIndex !== null) {
      return result[type][interval][currencyIndex];
    }
    return "";
  });

});