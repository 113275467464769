define('bookalook/routes/jobs/shopping-list', ['exports', 'ember', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/jobs/functions'], function (exports, Ember, utils, UserValidatorMixin, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      openPrDetailsModal: function openPrDetailsModal(prUser) {
        this.controller.set('prUser', prUser);
      },
      requestSample: function requestSample(sample) {
        this.onRequestSample(sample);
      },
      cancelSample: function cancelSample(sample) {
        this.onCancelSample(sample);
      },
      removeSample: function removeSample(sample) {
        this.onRemoveSample(sample);
      },
      cancelJob: function cancelJob(job) {
        functions['default'].cancelJob(this, job);
      },
      deleteJob: function deleteJob(job) {
        functions['default'].cancelJob(this, job, 'delete');
      },
      requestAllSamples: function requestAllSamples() {
        this.onRequestAllSamples();
      },
      openTrackParcel: function openTrackParcel(jobStatus) {
        this.onOpenTrackParcel(jobStatus);
      },
      closeJob: function closeJob(job) {
        this.onCloseJob(job);
      },
      openLoanForms: function openLoanForms(jobId) {
        functions['default'].onOpenLoanForms(jobId, this.controller);
      },
      willTransition: function willTransition() {
        this.controller.get('looksArraySamples').setEach('visible', true);
      },
      //PROPOSAL
      acceptSampleProposal: function acceptSampleProposal(sample) {
        this.onAcceptSampleProposal(sample);
      },
      collapseAreaAndScroll: function collapseAreaAndScroll(textSelector, textId, scrSel, scrId) {
        this.onCollapseAreaAndScroll(textSelector, textId, scrSel, scrId);
      },
      declineSampleProposal: function declineSampleProposal(sample) {
        this.onDeclineSampleProposal(sample);
      },
      changeRequestNotifications: function changeRequestNotifications() {
        this.onChangeRequestNotifications();
      }
    },

    model: function model(params) {
      var _this = this;

      return utils['default'].getAPIJSON('jobs/fullJob/' + params.id).then(function (r) {
        return r;
      }, function () {
        _this.transitionTo('jobs.index');
      });
    },

    afterModel: function afterModel(model) {
      var data = model;

      if (model.job.closed === true && model.job.active === false) {
        this.transitionTo('jobs.index');
      } else {
        data.job.jobStatus.forEach(function (s) {
          if (s.shipping) {
            var shippingData = s.shipping.split('|');
            s.shipping = shippingData;
            s.shippingType = shippingData.length > 0 ? shippingData[0] : null;
            s.shippingCourier = shippingData.length > 1 ? shippingData[1] : null;
            s.shippingNumber = shippingData.length > 2 ? shippingData[2] : null;
          }
        });
        model.job = utils['default'].pushPayload(this.store, 'job', data.job);
        utils['default'].pushArrayPayload(this.store, 'collection', data.collections);
      }

      //utils.pushArrayPayload(this.store, 'brand', data.brands);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties(model);
      controller.set('isFormPage', false);
      controller.set('isLoading', false);
      controller.set('looksArraySamples', []);
      controller.set('userLogged', Ember['default'].user);

      var samplesToLoad = controller.get('job.samples');

      controller.set('samplesIds', samplesToLoad.map(function (s) {
        return parseInt(s.get('id'), 10);
      }));

      this._getSamplesInfo(samplesToLoad);
      this.controller.get('job').setSampleStatus();

      var trackParcelFound = samplesToLoad.some(function (sample) {
        return sample.get('jobStatus.status') === 'DISPATCHED';
      });

      controller.set('jobCanBeClosed', !trackParcelFound);

      utils['default'].addObserver(controller, ['searchInput'], function () {
        var searchInput = controller.get('searchInput').toLowerCase();
        controller.get('looksArraySamples').forEach(function (sample) {
          var search = Ember['default'].itemsLabels[sample.get('item')];
          if (search) {
            sample.set('visible', search.toLowerCase().indexOf(searchInput) >= 0);
          } else {
            sample.set('visible', true);
          }
        });
      });
    },

    _getSamplesInfo: function _getSamplesInfo(samples) {
      var _this2 = this;

      var promises = samples.map(function (s) {
        return s.get('look');
      });

      Ember['default'].RSVP.all(promises).then(function (looks) {
        var uniqLooks = looks.uniq();
        _this2.controller.get('job').set('looks', uniqLooks);
        _this2.controller.get('job').notifyPropertyChange('visibleLooks');
        _this2._getPRUsers(uniqLooks);
      });
    },

    _getPRUsers: function _getPRUsers(looks) {
      var _this3 = this;

      utils['default'].postAPIJSON('looks/prUsers', {
        country: this.controller.get('job.country'),
        looks: looks.mapBy('id')
      }).then(function (res) {
        looks.forEach(function (l) {
          var users = res.look[l.get('id')];
          users = users.map(function (u) {
            return utils['default'].pushPayload(_this3.get('store'), 'user', u);
          });

          l.set('prUsers', users);
          l.get('samples').setEach('visible', true);
          _this3.controller.get('looksArraySamples').addObjects(l.get('samples'));
        });
        _this3.notifyPropertyChange('job.looks');
        _this3.controller.set('searchInput', '');
      });
    },

    onCloseJob: function onCloseJob(job) {
      utils['default'].openDefaultConfirmationModal(this, 'Close Job', 'You are about to close this job.', 'Do you wish to proceed?', 'Yes, close this job', 'No').then(function () {
        job.set('closed', true);
        utils['default'].putAPIJSON('jobs/close/' + job.get('id'));
      });
    },

    onRequestSample: function onRequestSample(sample) {
      var _this4 = this;

      var job = this.controller.get('job');
      if (job.get('isOpen')) {
        utils['default'].openDefaultConfirmationModal(this, 'Request Sample', 'The status of this job will change to Active', 'Do you wish to proceed?', 'Yes, request this sample', 'No').then(function () {
          _this4._doSampleRequest(job, sample);
        });
      } else {
        this._doSampleRequest(job, sample);
      }
    },

    onRequestAllSamples: function onRequestAllSamples() {
      var _this5 = this;

      var job = this.controller.get('job');
      if (job.get('isOpen')) {
        utils['default'].openDefaultConfirmationModal(this, 'Request All samples', 'The status of this job will change to Active', 'Do you wish to proceed?', 'Yes, request all samples', 'No').then(function () {
          _this5._doRequestAllSamples(job);
        });
      } else {
        this._doRequestAllSamples(job);
      }
    },

    _doSampleRequest: function _doSampleRequest(job, sample) {
      var promise = this._requestArrayOfSamples(job, [sample]);
      this.send('openMessageModal', 'Requesting...');
      job.set('active', true);

      utils['default'].buttonLoading('#request-sample-' + sample.get('id'), promise);
    },

    _doRequestAllSamples: function _doRequestAllSamples(job) {
      var samplesArrObj = job.get('samples').filter(function (s) {
        return !s.get('isRequested');
      });
      this.send('openMessageModal', 'Requesting...');
      job.set('active', true);

      if (!Ember['default'].isEmpty(samplesArrObj)) {
        this._requestArrayOfSamples(job, samplesArrObj);
      } else {
        this.send('closeMessageModal');
        this.controller.set('message', 'There are no samples to request');
        this.controller.set('empty', true);
        Ember['default'].$('#requestedSamples').modal();
      }
    },

    _requestArrayOfSamples: function _requestArrayOfSamples(job, samplesArray) {
      var _this6 = this;

      var samplesArrayId = samplesArray.mapBy('id');
      var promises = [];

      return utils['default'].postAPIJSON('jobs/' + job.get('id') + '/request', { samples: samplesArrayId }).then(function (res) {
        job.get('samples').filter(function (s) {
          return !s.get('isRequested');
        });
        job.reload().then(function () {
          var samplesRequested = [];

          for (var i = 0; i < res.job.length; i++) {
            var sampleFound = _this6.get('store').peekRecord('sample', res.job[i]);

            sampleFound.set('jobStatus', samplesArray.objectAt(i).get('jobStatus'));
            sampleFound.set('visible', true);
            sampleFound.set('isRequested', true);

            samplesRequested.pushObject(sampleFound);
            promises.push(sampleFound.reload());
          }

          Ember['default'].RSVP.all(promises).then(function () {
            _this6.controller.get('job').notifyPropertyChange('visibleLooks');
            _this6.send('closeMessageModal');
            // this.send('openModal', 'Sample requested successfully');
            if (localStorage.getItem('notifyRequests') == 'true') {
              _this6.controller.set('message', 'Great! Your sample(s) has been requested!');
              _this6.controller.set('empty', false);
              Ember['default'].$('#requestedSamples').modal();
            }
          });
        });
      });
    },

    onCancelSample: function onCancelSample(sample) {
      var _this7 = this;

      var job = this.controller.get('job');
      utils['default'].openDefaultConfirmationModal(this, 'Cancel sample', 'You are about to cancel this sample from the job', 'Do you wish to proceed?', 'Cancel sample', 'No').then(function () {
        var promise = utils['default'].postAPIJSON('jobs/' + job.get('id') + '/cancelSample/' + sample.get('id'), {}).then(function () {
          sample.set('isRequested', true);
          sample.set('jobStatus.status', 'CANCELED');
          job.reload().then(function () {
            _this7.controller.get('job').notifyPropertyChange('visibleLooks');
          });
        });
        utils['default'].buttonLoading('#cancel-sample-' + sample.get('id'), promise);
      });
    },

    onRemoveSample: function onRemoveSample(sample) {
      var _this8 = this;

      var job = this.controller.get('job');
      var promise = utils['default'].putAPIJSON('jobs/' + job.get('id') + '/removeSample/' + sample.get('id'), {}).then(function () {
        sample.set('visible', false);
        _this8.controller.get('job').notifyPropertyChange('visibleLooks');
      });
      utils['default'].buttonLoading('#remove-sample-' + sample.get('id'), promise);
    },

    onOpenTrackParcel: function onOpenTrackParcel(jobStatus) {

      this.controller.set('trackParcel', jobStatus);
    },

    onAcceptSampleProposal: function onAcceptSampleProposal(sample) {
      var _this9 = this;

      var sampleId = sample.get('id');
      var jobId = this.controller.get('job.id');

      utils['default'].putAPIJSON('jobs/' + jobId + '/acceptSampleProposal/' + sampleId).then(function (res) {
        sample.set('jobStatus.status', res.job);
        //this.send('openTopBar', 'Sample proposal accepted.');
        _this9.send('openPositiveTopBar');
      });
    },

    onDeclineSampleProposal: function onDeclineSampleProposal(sample) {
      var _this10 = this;

      var sampleId = sample.get('id');
      var jobId = this.controller.get('job.id');

      utils['default'].putAPIJSON('jobs/' + jobId + '/declineSampleProposal/' + sampleId).then(function (res) {
        sample.set('jobStatus.status', res.job);
        _this10.send('openTopBar', 'Sample proposal declined.');
      });
    },

    onCollapseAreaAndScroll: function onCollapseAreaAndScroll(textSelector, scrSel, id) {
      var el = Ember['default'].$('#' + textSelector + id).find('a');
      var scr = Ember['default'].$('#' + scrSel + id);
      var oldText = el.text();
      var newText = "";
      if (oldText.trim()[0] === 'S') {
        newText = oldText.replace('Show all (+', 'Hide (-');
      } else {
        newText = oldText.replace('Hide (-', 'Show all (+');
        $('html, body').animate({ scrollTop: scr.offset().top });
      }
      el.text(newText);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-jobs page-jobs-shopping-list');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-jobs page-jobs-shopping-list');
    },
    onChangeRequestNotifications: function onChangeRequestNotifications() {
      //Send a request to stop showing sample request notifications
      if ($('#show-popups').is(':checked') === true) {
        utils['default'].postAPIJSON('modal_notification_type/user_settings/save', { 'type': 'requestSamplesModal', 'boolean': true });
        localStorage.setItem('notifyRequests', false);
      }
    }
  });

});