define('bookalook/routes/barcodes/scan-booking', ['exports', 'ember', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/barcodes/functions'], function (exports, Ember, utils, UserValidatorMixin, scanFunctions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      startScanning: function startScanning() {
        scanFunctions['default'].onStartScanning(this, this.controller.get('booking.id'));
      },
      confirmActions: function confirmActions(e) {
        scanFunctions['default'].onConfirmActions(this);
      },
      cancelSelection: function cancelSelection() {
        scanFunctions['default'].onCancelSelection(this);
      },
      goToDetails: function goToDetails() {
        this.onGoToDetails();
      }
    },

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        booking: this.store.findRecord('booking', params.id).then(function (b) {
          return b.reload();
        }),
        collections: utils['default'].getCollections(this)
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      var _this = this;
      this.controller.samplesToCheck = Ember['default'].computed('barcodes.@each', function () {
        var rows = _this.controller.get('barcodes');
        _this.controller.set('barcodes', []);
        return rows;
      });
    },

    onGoToDetails: function onGoToDetails() {
      this.transitionTo('bookings.booking-details', this.controller.get('booking.id'), {
        queryParams: {
          search: ""
        }
      });
    },

    deactivate: function deactivate() {
      // $(document).off();
      // $('#barcode-input').off();
      scanFunctions['default'].onCancelSelection(this);
      this.controller.set('checkinResults', '');
      this.controller.set('checkoutResults', '');
    }

  });

});