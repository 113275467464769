define('bookalook/routes/bookings/booking-details', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/bookings/functions', 'bookalook/routes/contacts-lists/functions', 'bookalook/utils', 'bookalook/config/environment', 'bookalook/filters', 'bookalook/routes/check-out/functions'], function (exports, Ember, UserValidatorMixin, functions, contactFunctions, utils, config, filters, checkFunctions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    poll: Ember['default'].inject.service(),

    queryParams: {
      search: {
        //assigning replace state as true
        replace: true
      }
    },

    actions: {
      deleteBooking: function deleteBooking(booking) {
        functions['default'].onDeleteBooking(this, booking);
      },
      cancelBooking: function cancelBooking(booking, textOption) {
        functions['default'].onCancelBooking(this, booking, textOption);
      },
      cancelBookingEdition: function cancelBookingEdition() {
        var booking = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        this.onCancelBookingEdition(booking);
      },
      cancelBookingEditionBrandRefactor: function cancelBookingEditionBrandRefactor(booking) {
        this.onCancelBookingEditionBrandRefactor(booking);
      },
      openEditBookingAddresses: function openEditBookingAddresses() {
        this.onOpenEditBookingAddresses();
      },
      joinBooking: function joinBooking(booking) {
        this.onJoinBooking(booking);
      },
      confirmNoteEdition: function confirmNoteEdition(parcel) {
        parcel.save();
      },
      cancelNoteEdition: function cancelNoteEdition(parcel) {
        parcel.rollbackAttributes();
      },

      //================SHOPPINGLIST ACTIONS
      openManageModal: function openManageModal() {
        this.onOpenManageModal();
      },
      saveManageBooking: function saveManageBooking() {
        this.onSaveManageBooking();
      },
      cancelCheck: function cancelCheck() {
        this.controller.set('disableStatusFilter', false);filters['default'].resetFilters(this);this.onResetSamples();
      },
      openCheckOut2Modal: function openCheckOut2Modal(sample) {
        checkFunctions['default'].onOpenCheckOut2Modal(this, sample);
      },
      checkoutSamples: function checkoutSamples(sample) {
        this.controller.set('disableStatusFilter', false);this.controller.set('noRefreshStatuses', true);filters['default'].resetFilters(this);checkFunctions['default'].onCheckoutSamples(this, sample);
      },

      openCheckOutModalBrandRefactor: function openCheckOutModalBrandRefactor(sample) {
        this.onOpenCheckOutModalBrandRefactor(sample);
      },
      openCheckInModalBrandRefactor: function openCheckInModalBrandRefactor(sample) {
        this.onOpenCheckInModalBrandRefactor(sample);
      },

      checkInSample: function checkInSample(sample) {
        this.controller.set('disableStatusFilter', false);this.controller.set('noRefreshStatuses', true);filters['default'].resetFilters(this);checkFunctions['default'].onCheckInSample(this, sample);
      },
      checkOutParcel: function checkOutParcel(parcel) {
        checkFunctions['default'].onCheckOutParcel(this, parcel);
      },
      acceptSample: function acceptSample(sample) {
        checkFunctions['default'].onAcceptSample(this, sample);
      },
      confirmSample: function confirmSample(sample) {
        checkFunctions['default'].onConfirmSample(this, sample);
      },
      declineSample: function declineSample(sample) {
        checkFunctions['default'].onDeclineSample(this, sample);
      },
      cancelAndRemoveSample: function cancelAndRemoveSample(sample) {
        checkFunctions['default'].onCancelAndRemoveSample(this, sample);
      },
      selectAllCheckSamples: function selectAllCheckSamples() {
        this.onSelectAllCheckSamples();
      },
      clearCheckSamples: function clearCheckSamples() {
        this.onClearCheckSamples();
      },
      openLoanFormTypeModal: function openLoanFormTypeModal(text) {
        functions['default'].openLoanFormTypeModal(this.controller, text);
      },
      openAddTeamMemberModal: function openAddTeamMemberModal() {
        functions['default'].openAddTeamMemberModal(this.controller);
      },
      cancelAddTeamMember: function cancelAddTeamMember() {
        functions['default'].onCancelAddTeamMember();
      },
      saveAddTeamMember: function saveAddTeamMember(booking, member) {
        functions['default'].onSaveAddTeamMember(this, booking, member);
      },
      //PROPOSAL
      openAddProposalModal: function openAddProposalModal(booking) {
        this.onOpenAddProposalModal(booking);
      },
      saveProposal: function saveProposal() {
        this.onSaveProposal();
      },
      acceptSampleProposal: function acceptSampleProposal(sample) {
        this.onAcceptSampleProposal(sample);
      },
      declineSampleProposal: function declineSampleProposal(sample) {
        this.onDeclineSampleProposal(sample);
      },
      cancelSampleProposal: function cancelSampleProposal(sample) {
        this.onCancelSampleProposal(sample);
      },
      cancelProposalEdition: function cancelProposalEdition(booking) {
        this.onCancelProposalEdition(booking);
      },

      _downloadFile: function _downloadFile(link) {
        var fixedLink = link.substr(link.lastIndexOf('/') + 1);
        window.open(config['default'].APIURL + 'filesdownload/bookings/' + fixedLink, '_blank' // <- This is what makes it open in a new window.
        );
      },

      checkLook: function checkLook(look) {
        this.onCheckLook(look);
      },
      checkLookVisibleSamples: function checkLookVisibleSamples(look) {
        this.onCheckLookVisibleSamples(look);
      },

      willTransition: function willTransition() {
        //clearInterval(this.controller.get('paginationInterval'));
        this.get('poll').clearAll();
        var controller = this.controller;
        filters['default'].removeObserversFromSetFilterComputeds(this);
        //allBrandsFromBookingObserver
        controller.removeObserver('collections', controller, 'allBrandsFromBookingObserver');
        controller.removeObserver('booking.samples', controller, 'allBrandsFromBookingObserver');
        //isBookingMemberObserver
        controller.removeObserver('booking.users', controller, 'isBookingMemberObserver');
        //returnAddressHasSomeFieldObserver
        controller.removeObserver('newReturnAddress.street', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.street2', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.street3', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.country', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.zipCode', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.city', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.telephone', controller, 'returnAddressHasSomeFieldObserver');
        controller.removeObserver('newReturnAddress.telephone2', controller, 'returnAddressHasSomeFieldObserver');
        this.controller.set('bookingDetailsPage', false);
        //Add contact observers
        controller.removeObserver('addContactSelectedLists.length', controller, 'addContactSelectedUserObserver');
        controller.removeObserver('addContactSelectedUser', controller, 'addContactSelectedUserObserver');
        controller.removeObserver('booking.requester', controller, 'addressesRequesterFunction');
        controller.removeObserver('addressesRequester', controller, 'addressesRequesterMoreThan1AddressesFunction');
        controller.removeObserver('booking.requester', controller, 'mainAddressRequesterFunction');
      },
      //didTransition()  { this.onReloadBooking(false); },
      clickSelectSample: function clickSelectSample(sample) {
        this.onClickSelectSample(sample);
      },

      checkCheckoutLook: function checkCheckoutLook(look) {
        checkFunctions['default'].onCheckCheckoutLook(look);
      },
      checkCheckoutLookVisibleSamples: function checkCheckoutLookVisibleSamples(look) {
        checkFunctions['default'].onCheckCheckoutLookVisibleSamples(look);
      },
      toggleChecksToCheckoutInBooking: function toggleChecksToCheckoutInBooking(booking) {
        checkFunctions['default'].onToggleChecksToCheckoutInBooking(booking);
      },
      checkCheckoutSample: function checkCheckoutSample(sample) {
        checkFunctions['default'].onCheckCheckoutSample(sample);
      },

      removeSample: function removeSample(sample) {
        checkFunctions['default'].onRemoveSample(this, sample);
        this.onResetSamples();
      },

      reloadBooking: function reloadBooking() {
        this.onReloadBooking();
      },

      swapReturnAddress: function swapReturnAddress(direction) {
        this.onSwapReturnAddress(direction, event);
      },
      swapCallInAddress: function swapCallInAddress(direction) {
        this.onSwapCallInAddress(direction, event);
      },
      goToScanning: function goToScanning() {
        this.onGoToScanning();
      },
      goToLoanFormPage: function goToLoanFormPage(booking, loanForm) {
        this.onGoToLoanFormPage(booking, loanForm);
      },
      newCallInAddress: function newCallInAddress() {
        this.onNewCallInAddress();
      },
      cancelNewCallIn: function cancelNewCallIn() {
        this.onCancelNewCallIn();
      },
      saveNewCallIn: function saveNewCallIn() {
        this.onSaveNewCallIn();
      },
      newReturnAddress: function newReturnAddress() {
        this.onNewReturnAddress();
      },
      cancelNewReturn: function cancelNewReturn() {
        this.onCancelNewReturn();
      },
      saveNewReturn: function saveNewReturn() {
        this.onSaveNewReturn();
      },
      toggleSetAsMainAddress: function toggleSetAsMainAddress(newCallInAddress) {
        this.onToggleSetAsMainAddress(newCallInAddress);
      },
      updateOverviewAndBooking: function updateOverviewAndBooking() {
        this.onUpdateOverviewAndBooking();
      },
      closeNewCallInAddressModal: function closeNewCallInAddressModal() {
        this.onCloseNewCallInAddressModal();
      },
      openReverseCheckOutModal: function openReverseCheckOutModal(sample) {
        this.onOpenReverseCheckOutModal(sample);
      },
      openReverseCheckInModal: function openReverseCheckInModal(sample) {
        this.onOpenReverseCheckInModal(sample);
      },

      setFeaturedSample: function setFeaturedSample(value, sample) {
        this.onSetFeaturedSample(value, sample);
      },
      addContactMpToLists: function addContactMpToLists(contact) {
        this.onAddContactMpToLists(contact);
      },
      addContactNotMpToLists: function addContactNotMpToLists(contact) {
        this.onAddContactNotMpToLists(contact);
      },
      saveContactNotMP: function saveContactNotMP() {
        this.onSaveContactNotMP();
      },
      cancelAddNotMp: function cancelAddNotMp() {
        this.onCancelAddNotMp();
      },
      cancelAddMp: function cancelAddMp() {
        this.onCancelAddMp();
      },
      saveContactFromUserRefactor: function saveContactFromUserRefactor() {
        this.onSaveContactFromUserRefactor(this);
      },
      addContactWhenEditingBooking: function addContactWhenEditingBooking() {
        this.onAddContactWhenEditingBooking();
      },
      saveContactRequesterEditingBooking: function saveContactRequesterEditingBooking() {
        this.onSaveContactRequesterEditingBooking(this);
      },
      openNewContactModal: function openNewContactModal() {
        this.onOpenNewContactModal();
      },
      saveNewContactRefactor: function saveNewContactRefactor() {
        this.onSaveNewContactRefactor();
      }

    },

    beforeModel: function beforeModel(transition) {
      var _t = this;
      var id = transition.params['bookings.booking-details'].id;
      if (id) {
        var promise = utils['default'].getAPIJSON('bookings/isAllowed/' + id + '/showroom/' + Ember['default'].showroom.get('id'));
        promise.then(function (success) {
          if (!success.booking) {
            _t.replaceWith('bookings.index');
          }
        }, function (fail) {
          _t.replaceWith('bookings.index');
        });
      } else {
        _t.replaceWith('bookings.index');
      }
    },

    model: function model(params) {
      /*let _t = this;
      //TODO: search collection directly by id why is loading all again?
      let collectionsFromCache = utils.getCollections(this, true); 
      let collections = [];
      if (!collectionsFromCache || Ember.isEmpty(collectionsFromCache)) {
        _t.set('fromCache', false);
        collections = utils.getCollections(this, false, params.id);
      } else {
        _t.set('fromCache', true);
        collections = collectionsFromCache;
      }*/

      return Ember['default'].RSVP.hash({
        booking: this.store.findRecord('booking', params.id, { reload: true }),
        collections: utils['default'].getCollectionByBooking(this, params.id), //collections,
        todayDate: utils['default'].getAPIJSON('statuses/getTodayDateTimezone')
        //collections : utils.getCollections(this, true)
        //collections: this.store.query('collection', {booking: params.id})
      });
    },

    setupController: function setupController(controller, model) {
      //Today Date
      var splittedDates = model.todayDate.split("-");
      var todayDate = new Date(splittedDates[0], splittedDates[1] - 1, splittedDates[2]);
      controller.set('todayDates', todayDate);

      //Disable observer for getStatuses
      controller.set('noRefreshStatuses', true);
      controller.set('userLogged', Ember['default'].user);
      this.controller.set('bookingDetailsPage', true);
      this.controller.set('disableShowroomFilter', true);
      //this.controller.set('disableBrandFilter', true);
      this.controller.set('bookingBrandsFilter', true);

      this._super(controller, model);
      controller.setProperties(model);
      controller.set('isLoading', true);
      controller.set('isManageLoading', true);

      var booking = controller.get('booking');
      controller.set('companyBooking', booking.get('company'));

      //This setting will modify Brand Filtering for agencies
      if (Ember['default'].showroom.companyTypeAndId.type && Ember['default'].showroom.companyTypeAndId.type == 'agency') {
        controller.set('isAgency', true);
      }

      if (controller.get('userLogged').get('type') == "PR") {
        utils['default'].getAPIJSON('plans/contactsAllowedForPlanUser').then(function (res) {
          controller.set('contactsAllowedForPlanUser', res);
          if (res == false) {
            controller.set('setbuttonfree', '1');
          }
        });
      }

      controller.allBrandsFromBookingObserver = function () {
        var collections = controller.get('collections');
        if (Ember['default'].isNone(collections)) {
          controller.set('allBrandsFromBooking', Ember['default'].A([{ id: null, name: 'All brands' }]));
          return;
        }
        var filteredCollections = collections.filter(function (c) {
          return c.get && c.get('hasCheckedSamples');
        });
        if (Ember['default'].isEmpty(filteredCollections)) {
          controller.set('allBrandsFromBooking', Ember['default'].A([{ id: null, name: 'All brands' }]));
          return;
        }
        var brands = filteredCollections.reduce(function (brandList, nextCollection, index, array) {
          var content = nextCollection.get('brand.content');
          if (content) {
            brandList.addObject(content);
          }
          //IF LAST ELEMENT: SORT THE ARRAY ALPHABETICALLY
          if (index === array.get('length') - 1) {
            brandList = brandList.sort(function (a, b) {
              if (a.get && b.get) {
                return a.get('name').toLowerCase().localeCompare(b.get('name').toLowerCase());
              } else {
                if (a.get) {
                  return 1;
                } else {
                  return -1;
                }
              }
            });
          }
          return brandList;
        }, Ember['default'].A([{ id: null, name: 'All brands' }]));
        controller.set('allBrandsFromBooking', brands);
      };
      utils['default'].addObserver(controller, ['collections', 'booking.samples'], 'allBrandsFromBookingObserver');

      //Loan Forms
      var loans = booking.get('loanForms');
      if (!Ember['default'].isNone(loans) && !loans.get('length') > 0) {
        loans = loans.sortBy('checkoutDate');
      }
      controller.set('allLoanForms', loans);

      //Get booking status
      utils['default'].getAPIJSON('bookings/status/' + model.booking.id).then(function (res) {
        var booking = model.booking;
        booking.set('status', res.booking);
      });

      this._setStatusFilter(controller, 'bookingDetailsStatusContent');
      this.resetController();
      this._resetNewCallInAddress();
      this._resetNewReturnAddress();

      controller.set('showBookingDetails', true);

      controller.set('route', this);

      this.resetBooking();
      //this.onResetSamples(); Already done in resetBooking
      //Get job notes && job files
      var job = model.booking.get('job');
      if (job && job.get('content')) {
        var idJob = job.get('content').get('id');
        if (idJob) {
          //utils.getAPIJSON('jobs/' + idJob).then();
          this.store.findRecord('job', idJob).then(function (job) {
            if (job.get('comments')) {
              controller.set('mpNotes', job.get('comments'));
            }
            controller.set('jobFiles', null);
            if (job.get('files')) {
              var jobFiles = [];
              var filesObjs = job.get('files');

              filesObjs.forEach(function (file, i) {
                jobFiles.push(file);
              });
              jobFiles.reverse();

              controller.set('jobFiles', jobFiles);
            }

            controller.set('booking.job', job);
          });
        }
      }

      controller.allCollectionsShoppingList = Ember['default'].computed('allCollections', 'samples', 'samples.@each.visible', 'samples.@each.checked', function () {
        return controller.get('allCollections').filter(function (c) {
          return c.get && c.get('hasCheckedSamples');
        }).sortBy('brand.name');
      });

      controller.someVisibleInShoppingList = Ember['default'].computed('allCollectionsShoppingList.@each.hasVisibleAndCheckedSamples', function () {
        return controller.get('allCollectionsShoppingList').some(function (c) {
          return c.get && c.get('hasVisibleAndCheckedSamples');
        });
      });

      functions['default'].showroomSelectorObserver(controller, 'showroom', 'booking');
      filters['default'].setFilterComputeds(this);

      this._initRequesterAddresses();

      //Enable observer for getStatuses
      //controller.set('noRefreshStatuses', false);
      if (this.get('fromCache')) {
        if (controller.get('allBrands.length') === 2) {
          //controller.set('brandFilter', controller.get('allBrands')[1].get('id'));
          controller.set('brandFilter', null);
        } else {
          controller.set('brandFilter', null);
        }
        controller.notifyPropertyChange('brandFilter');
        controller.set('isLoadingCollections', false);
        controller.set('noRefreshStatuses', false);
      } else {
        var collections = utils['default'].getCollections(this);
        //controller.set('addEnabled', false);
        controller.set('noRefreshStatuses', true);
        controller.set('isLoadingCollections', true);
        // PORQUE VUELVE A PEDIR LAS COLLECTIONS Y TODAS ?
        collections.then(function (newCollections) {
          controller.set('collections', newCollections);
          //controller.set('addEnabled', true);
          if (controller.get('allBrands.length') === 2) {
            controller.set('brandFilter', null);
            //controller.set('brandFilter', controller.get('allBrands')[1].get('id'));
          } else {
              controller.set('brandFilter', null);
            }
          controller.notifyPropertyChange('brandFilter');
          controller.set('isLoadingCollections', false);
          controller.set('noRefreshStatuses', false);
        });
      }

      this.controller.set('countriesAvailable', Ember['default'].countries);
      this.controller.set('searchInput', controller.get('search') || "");

      if (booking.get('isInternal')) {
        //Observer for addressesRequester changes
        controller.addressesRequesterFunction = function () {
          var b = controller.get('booking');
          if (!Ember['default'].isNone(b)) {
            var requester = b.get('requester');
            if (!Ember['default'].isNone(requester)) {
              Ember['default'].set(controller, 'addressesRequester', requester.get('allAddresses'));
            } else {
              Ember['default'].set(controller, 'addressesRequester', null);
            }
          }
        };
        utils['default'].addObserver(controller, ['booking.requester'], 'addressesRequesterFunction');
      }

      controller.addressesRequesterMoreThan1AddressesFunction = function () {
        var a = controller.get('addressesRequester');
        if (a != null) {
          if (a.get('length') != null && a.get('length') > 1) {
            Ember['default'].set(controller, 'moreThan1Addresses', true);
          } else {
            Ember['default'].set(controller, 'moreThan1Addresses', false);
          }
        } else {
          Ember['default'].set(controller, 'moreThan1Addresses', false);
        }
      };
      utils['default'].addObserver(controller, ['addressesRequester'], 'addressesRequesterMoreThan1AddressesFunction');

      //For adding contacts when Editing
      if (booking.get('isInternal')) {
        contactFunctions['default'].setComputedNewContact(controller);
        contactFunctions['default'].setListsFilteredBySelectedUserObserverBooking(controller);
      }
    },

    _initRequesterAddresses: function _initRequesterAddresses() {
      var controller = this.controller;

      var booking = controller.get('booking');
      var allAddresses = [];

      if (booking.get('isInternal')) {
        //Set addresses for requester
        var requester = controller.get('booking.requester');
        /*
              var mainAddress = requester.get('mainAddress');
              if (mainAddress && !(mainAddress.get('street1')) && !(mainAddress.get('street2')) && !(mainAddress.get('street1'))) {
                allAddresses.addObjects(requester.get('privateAddresses'));
              }
        
              if (requester.get('privateAddresses')) {
                allAddresses.addObject(requester.get('mainAddress'));
                allAddresses.addObjects(requester.get('privateAddresses'));
              }
              */

        allAddresses = requester.get('allAddresses');
      } else {
        var jobAddresses = booking.get('job').get('addresses');
        jobAddresses.forEach(function (jA) {
          allAddresses.pushObject(jA);
        });
      }
      var params = [];
      params.push(booking.get('callInStreet1'));
      params.push(booking.get('callInStreet2'));
      params.push(booking.get('callInStreet3'));
      params.push(booking.get('callInCity'));
      params.push(booking.get('callInCountry'));
      params.push(booking.get('callInZipCode'));
      params.push(booking.get('callInTelephone'));
      var hasSomeField = params.some(function (p) {
        return !Ember['default'].isBlank(p);
      });
      if (hasSomeField) {
        booking.set('callInId', 0);
        var current = null;
        if (booking.get('isInternal')) {
          current = Ember['default'].Object.create({
            'id': 0,
            'street1': booking.get('callInStreet1'),
            'street2': booking.get('callInStreet2'),
            'street3': booking.get('callInStreet3'),
            'city': booking.get('callInCity'),
            'country': booking.get('callInCountry'),
            'zipCode': booking.get('callInZipCode'),
            'telephone1': booking.get('callInTelephone')
          });
        } else {
          current = Ember['default'].Object.create({
            'id': 0,
            'street1': booking.get('callInStreet1'),
            'street2': booking.get('callInStreet2'),
            'street3': booking.get('callInStreet3'),
            'city': booking.get('callInCity'),
            'country': booking.get('callInCountry'),
            'zipCode': booking.get('callInZipCode'),
            'telephone': booking.get('callInTelephone')
          });
        }

        var found = false;
        var allAddressesCopy = allAddresses.slice();
        for (var i = 0; i < allAddressesCopy.length && !found; i++) {
          found = functions['default'].compareAddresses(current, allAddressesCopy[i]);
          if (found) {
            current = allAddressesCopy[i];
            allAddressesCopy.splice(i, 1);
            booking.set('callInId', current.get('id'));
            booking.set('callInMain', current.get('main'));
          }
        }
        var mixed = [current].concat(allAddressesCopy);
        controller.set('addressesRequester', mixed);
      } else {
        if (!Ember['default'].isNone(allAddresses[0])) {
          if (booking.get('isInternal')) {
            booking.setProperties({
              callInId: allAddresses[0].get('id'),
              callInStreet1: allAddresses[0].get('street1'),
              callInStreet2: allAddresses[0].get('street2'),
              callInStreet3: allAddresses[0].get('street3'),
              callInCountry: allAddresses[0].get('country'),
              callInCity: allAddresses[0].get('city'),
              callInZipCode: allAddresses[0].get('zipCode'),
              callInTelephone: allAddresses[0].get('telephone1'),
              callInTelephone2: allAddresses[0].get('telephone2')
            });
          } else {
            booking.setProperties({
              callInId: allAddresses[0].get('id'),
              callInStreet1: allAddresses[0].get('street1'),
              callInStreet2: allAddresses[0].get('street2'),
              callInStreet3: allAddresses[0].get('street3'),
              callInCountry: allAddresses[0].get('country'),
              callInCity: allAddresses[0].get('city'),
              callInZipCode: allAddresses[0].get('zipCode'),
              callInTelephone: allAddresses[0].get('telephone'),
              callInMain: allAddresses[0].get('main')
            });
          }
        }
        controller.set('addressesRequester', allAddresses);
      }

      var addressesRequester = controller.get('addressesRequester');
      if (!Ember['default'].isNone(addressesRequester)) {
        if (addressesRequester.get('length') != null && addressesRequester.get('length') > 1) {
          controller.set('moreThan1Addresses', true);
        } else {
          controller.set('moreThan1Addresses', false);
        }
      } else {
        controller.set('moreThan1Addresses', false);
      }

      controller.set('allShowrooms', []);
      var showrooms = Ember['default'].agency.get('showrooms').toArray();
      var paramsR = [];
      paramsR.push(booking.get('returnStreet1'));
      paramsR.push(booking.get('returnStreet2'));
      paramsR.push(booking.get('returnStreet3'));
      paramsR.push(booking.get('returnCity'));
      paramsR.push(booking.get('returnCountry'));
      paramsR.push(booking.get('returnZipCode'));
      paramsR.push(booking.get('returnTelephone'));
      paramsR.push(booking.get('returnTelephone2'));
      var hasSomeFieldR = paramsR.some(function (p) {
        return !Ember['default'].isBlank(p);
      });
      if (hasSomeFieldR) {
        booking.set('returnId', 0);
        var current = Ember['default'].Object.create({
          'id': 0,
          'street': booking.get('returnStreet1'),
          'street2': booking.get('returnStreet2'),
          'street3': booking.get('returnStreet3'),
          'city': booking.get('returnCity'),
          'country': booking.get('returnCountry'),
          'zipCode': booking.get('returnZipCode'),
          'telephone': booking.get('returnTelephone'),
          'telephone2': booking.get('returnTelephone2')
        });
        var found = false;
        var showroomsCopy = showrooms.slice();
        for (var i = 0; i < showroomsCopy.length && !found; i++) {
          found = functions['default'].compareAddresses(current, showroomsCopy[i]);
          if (found) {
            current = showroomsCopy[i];
            showroomsCopy.splice(i, 1);
            booking.set('returnId', current.get('id'));
          }
        }
        var mixed = [current].concat(showroomsCopy);
        controller.set('allShowrooms', mixed);
      } else {
        booking.setProperties({
          returnId: showrooms[0].get('id'),
          returnStreet1: showrooms[0].get('street'),
          returnStreet2: showrooms[0].get('street2'),
          returnStreet3: showrooms[0].get('street3'),
          returnCountry: showrooms[0].get('country'),
          returnCity: showrooms[0].get('city'),
          returnZipCode: showrooms[0].get('zipCode'),
          returnTelephone: showrooms[0].get('telephone'),
          returnTelephone2: showrooms[0].get('telephone2')
        });
        controller.set('allShowrooms', showrooms);
      }

      var a = controller.get('allShowrooms');
      if (a != null) {
        if (a.get('length') != null && a.get('length') > 1) {
          controller.set('moreThan1Showroom', true);
        } else {
          controller.set('moreThan1Showroom', false);
        }
      } else {
        controller.set('moreThan1Showroom', false);
      }

      if (requester) {
        var mainAddressFromRequester = requester.get('allAddresses').get('firstObject');
        //Set The Main address for mark it as main in the Edit Booking Address Form
        controller.set('mainAddressFromRequester', mainAddressFromRequester);
      }
    },

    onCheckLook: function onCheckLook(look) {
      var bookingSamples = this.controller.get('samples');
      var lookSamples = look.get('samples');
      //Remove samples already added in booking
      var filteredSamples = lookSamples.filter(function (s) {
        return !bookingSamples.some(function (bS) {
          return bS.get('id') === s.get('id');
        });
      });
      var allChecked = filteredSamples.isEvery('checked');
      filteredSamples.forEach(function (sample) {
        //if (sample.get('addSampleStatus') != 'unavailable'){
        sample.set('checked', !allChecked);
        //}
      });
    },

    onCheckLookVisibleSamples: function onCheckLookVisibleSamples(look) {
      var check = true;
      if (look.get('allVisibleSamplesAreChecked')) {
        check = false;
      } else {
        check = true;
      }
      var groupedSamples = look.get('groupedSamples');
      for (var i = 0; i < groupedSamples.length; i++) {
        var samples = groupedSamples[i]['all'];
        for (var x = 0; x < samples.length; x++) {
          //Dont check if sample is UNAVAILABLE
          //if (samples[x].addSampleStatus != 'unavailable') {
          if (samples[x].get('visible')) {
            samples[x].set('checked', check);
          }
          //}
        }
      }
    },

    onOpenCheckOutModalBrandRefactor: function onOpenCheckOutModalBrandRefactor(sample) {
      this.controller.set('disableStatusFilter', true);
      this.controller.set('selectAllCheck', false);
      checkFunctions['default'].onOpenCheckOutModalBrandRefactor(this, sample);
      filters['default'].resetFilters(this, '#checkout-step1-brand-refactor', '#checkout-step1-brand-refactor .modal-dialog');
    },

    onOpenCheckInModalBrandRefactor: function onOpenCheckInModalBrandRefactor(sample) {
      this.controller.set('disableStatusFilter', true);
      this.controller.set('selectAllCheck', false);
      checkFunctions['default'].onOpenCheckInModalBrandRefactor(this, sample);
      filters['default'].resetFilters(this, '#checkin-brand-refactor', '#checkin-brand-refactor .modal-dialog');
    },

    onReloadBooking: function onReloadBooking() {
      var _this2 = this;

      var fromButton = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      if (this.controller.get('isLoading') && fromButton) {
        return;
      }

      this.controller.set('isLoading', true);
      this.controller.get('booking').reload().then(function () {
        _this2.resetBooking();
      });
    },

    resetController: function resetController() {
      var _t = this;
      var controller = this.controller;
      controller.set('isShoppingList', true);
      controller.set('isBookingDetails', true);
      controller.set('isBookingMember', false);
      controller.get('booking.users').then(function () {
        controller.set('isBookingMember', functions['default'].isBookingMember(controller.get('booking')));
      });
      controller.isBookingMemberObserver = function () {
        controller.set('isBookingMember', functions['default'].isBookingMember(controller.get('booking')));
      };
      utils['default'].addObserver(controller, ['booking.users'], 'isBookingMemberObserver');

      var prov = this.controller.get('booking').serialize();
      prov.id = this.controller.get('booking.id');
      prov.addresses = this.controller.get('booking.addresses');
      Ember['default'].bookings.set('bookingsList', Ember['default'].A([prov]));
      Ember['default'].bookings.set('bookingsDate', new Date());

      //controller.get('booking').resetSampleStatusLabels();

      Ember['default'].bulk.clearSamples();

      controller.set('samples', controller.get('booking.samples'));
      controller.set('manageSamples', utils['default'].getSamples());

      this._setStatusFilter(controller, 'bookingDetailsStatusContent');
      this.onResetSamples();
    },

    onResetSamples: function onResetSamples() {
      var _this3 = this;

      Ember['default'].bulk.clearSamples();
      this.controller.get('samples').forEach(function (sample) {
        if (_this3.controller.get('booking.isForeign')) {
          sample.set('checked', sample.get('isForeignEditable') || sample.get('isProposalEditable'));
        } else {
          sample.set('checked', true);
        }

        sample.set('toCheckout', false);
      });
      this.notifyPropertyChange('samples');
      this.notifyPropertyChange('samples.@each.shoppingListStatus');

      this.controller.notifyPropertyChange('samples');
      this.controller.notifyPropertyChange('samples.@each.shoppingListStatus');
      this.controller.notifyPropertyChange('allCollectionsShoppingList');
      this.controller.notifyPropertyChange('allCollectionsShoppingList.@each.hasVisibleAndCheckedSamples');
      this.controller.notifyPropertyChange('allCollections');
      this.controller.notifyPropertyChange('brandFilter');
      this.controller.notifyPropertyChange('albumFilter');
      this.controller.notifyPropertyChange('statusFilter');
      this.controller.notifyPropertyChange('itemFilter');
    },

    onCancelBookingEdition: function onCancelBookingEdition() {
      var _this4 = this;

      var bo = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _t = this;
      if (bo) {
        var i;

        (function () {
          //If booking: We are in add samples
          _this4.controller.set('brandFilter', null);
          _this4.controller.set('statusFilter', '');
          _this4.controller.set('searchInput', '');
          _this4.controller.set('collectionFilter', null);
          _this4.controller.set('isManageLoading', true);

          for (i = 0; i < Ember['default'].bulk.allSamples.length; i++) {
            if (Ember['default'].bulk.allSamples[i].get('oldStatus') !== null) {
              Ember['default'].bulk.allSamples[i].set('checked', Ember['default'].bulk.allSamples[i].get('oldStatus'));
            }
          }
          _this4.controller.get('samples').forEach(function (sample) {
            return sample.set('visible', true);
          });
          _this4.controller.get('samples').forEach(function (sample) {
            sample.set('checked', true);
          });
          var booking = _this4.controller.get('booking');

          var status = booking.get('status');

          booking.rollbackAttributes();
          _t.send('openMessageModal', 'Reverting changes...');
          booking.reload().then(function (b) {
            _t.send('closeMessageModal');
            //b.set('callInId', _t.controller.get('oldCallInId'));
            //b.set('returnId', _t.controller.get('oldReturnId'));
            b.set('status', status);
          });
        })();
      } else {
        (function () {
          //If no booking: We are in edit booking
          var booking = _this4.controller.get('booking');

          var changedAttrWithoutStatus = Ember['default'].A();
          var changedAttributes = booking.changedAttributes();
          var usableHash = Object.keys(changedAttributes).map(function (attributeName) {
            var obj = {
              key: attributeName,
              earlierValue: changedAttributes[attributeName][0],
              currentValue: changedAttributes[attributeName][1]
            };
            if (attributeName != 'status') {
              changedAttrWithoutStatus.pushObject(obj);
            }
            return obj;
          });

          //IF SOME HAS CHANGED: REVERT
          if (changedAttrWithoutStatus.get('length') > 0) {
            (function () {
              var status = booking.get('status');

              booking.rollbackAttributes();
              _t.send('openMessageModal', 'Reverting changes...');
              booking.reload().then(function (b) {
                _t.send('closeMessageModal');
                //b.set('callInId', _t.controller.get('oldCallInId'));
                //b.set('returnId', _t.controller.get('oldReturnId'));
                b.set('status', status);
              });
            })();
          }

          //Restore Requester?
          if (booking.get('isInternal')) {
            if (!Ember['default'].isNone(_this4.controller.get('oldRequester')) && _this4.controller.get('oldRequester') != booking.get('requester')) {
              Ember['default'].set(booking, 'requester', _this4.controller.get('oldRequester'));
            }
          }
        })();
      }
    },

    onCancelBookingEditionBrandRefactor: function onCancelBookingEditionBrandRefactor() {
      this.controller.set('bookingDetailsPage', true);
      this.controller.set('isManageLoading', true);
      this._setStatusFilter(this.controller, 'bookingDetailsStatusContent');

      var bookingsamples = this.controller.get('booking.samples');
      var bSamplesIds = bookingsamples.map(function (s) {
        return s.get('id');
      });

      for (var i = 0; i < Ember['default'].bulk.allSamples.length; i++) {
        /*if(Ember.bulk.allSamples[i].get('oldStatus') !== null) {
          Ember.bulk.allSamples[i].set('checked', Ember.bulk.allSamples[i].get('oldStatus'));
        }*/
        if (bSamplesIds.includes(Ember['default'].bulk.allSamples[i].get('id'))) {
          Ember['default'].bulk.allSamples[i].set('checked', true);
        } else {
          Ember['default'].bulk.allSamples[i].set('checked', false);
        }
      }
      this.controller.get('samples').forEach(function (sample) {
        sample.set('visible', true);
        sample.set('checked', true);
      });
      filters['default'].setFilterComputeds(this);
      filters['default'].removeScrollListener(this);
      //this.controller.set('disableBrandFilter', true);
      this.controller.set('bookingBrandsFilter', true);

      this.notifyPropertyChange('samples');

      this.controller.set('brandFilter', null);

      this.controller.notifyPropertyChange('allCollections');
      this.controller.notifyPropertyChange('brandFilter');
      this.controller.notifyPropertyChange('albumFilter');
      this.controller.notifyPropertyChange('statusFilter');
      this.controller.notifyPropertyChange('itemFilter');
      this.controller.set('brandFilter', null);
    },

    onOpenEditBookingAddresses: function onOpenEditBookingAddresses() {
      var _this5 = this;

      //this._initRequesterAddresses();

      //For internal bookings we can change requester
      if (this.controller.get('booking.isInternal')) {
        Ember['default'].$('#edit-booking-requester').prop('disabled', 'disabled');
        //Saving oldRequester for restoring it (only for cancel cases)
        this.controller.set('oldRequester', this.controller.get('booking.requester'));

        //Initialize contacts lists!
        if (Ember['default'].isNone(this.controller.get('allContactsList')) || Ember['default'].isEmpty(this.controller.get('allContactsList'))) {
          //var lists = utils.getContactLists(route);
          //var lists = route.store.query('contacts-list', {from_booking: true});
          var lists = this.store.findAll('contacts-list');
          lists.then(function (newLists) {
            _this5.controller.set('allContacts', []);
            _this5.controller.set('lists', newLists);
            _this5.controller.get('lists').forEach(function (list) {
              return list.get('contacts').forEach(function (contact) {
                return _this5.controller.get('allContacts').addObject(contact);
              });
            });

            functions['default'].setRequesterComputedsForEditBooking(_this5.controller);
            _this5.setAddressRequesterChangesObserver(_this5.controller, _this5);
            Ember['default'].$('#edit-booking-requester').prop('disabled', false);
          });
        } else {
          this.controller.get('lists').forEach(function (list) {
            return list.get('contacts').forEach(function (contact) {
              return _this5.controller.get('allContacts').addObject(contact);
            });
          });

          functions['default'].setRequesterComputedsForEditBooking(this.controller);
          this.setAddressRequesterChangesObserver(this.controller, this);
          Ember['default'].$('#edit-booking-requester').prop('disabled', false);
        }
      }

      Ember['default'].$('#edit-booking-addresses').modal();
      Ember['default'].openModal();
    },

    onJoinBooking: function onJoinBooking(booking) {
      var bookingToSave = booking;
      var _t = this;
      this.send('openMessageModal', 'Joining booking...');
      utils['default'].putAPIJSON('bookings/' + bookingToSave.get('id') + '/joinUser').then(function () {
        bookingToSave.reload().then(function () {
          bookingToSave.resetSampleStatusLabels().then(function () {
            _t.onResetSamples();
            _t.send('closeMessageModal');
          });
        });
        //bookingToSave.get('users').addObject(Ember.user);
      });
    },

    onCancelProposalEdition: function onCancelProposalEdition() {
      this.controller.set('isManageLoading', true);
      this._setStatusFilter(this.controller, 'bookingDetailsStatusContent');

      var bookingsamples = this.controller.get('booking.samples');
      var bSamplesIds = bookingsamples.map(function (s) {
        return s.get('id');
      });
      for (var i = 0; i < Ember['default'].bulk.allSamples.length; i++) {
        /*if(Ember.bulk.allSamples[i].get('oldStatus') !== null) {
          Ember.bulk.allSamples[i].set('checked', Ember.bulk.allSamples[i].get('oldStatus'));
        }*/
        if (bSamplesIds.includes(Ember['default'].bulk.allSamples[i].get('id'))) {
          Ember['default'].bulk.allSamples[i].set('checked', true);
        } else {
          Ember['default'].bulk.allSamples[i].set('checked', false);
        }
      }
      this.controller.get('samples').forEach(function (sample) {
        sample.set('visible', true);
        sample.set('checked', true);
      });
      filters['default'].setFilterComputeds(this);
      filters['default'].removeScrollListener(this);
      //this.controller.set('disableBrandFilter', true);
      this.controller.set('bookingBrandsFilter', true);

      this.notifyPropertyChange('samples');

      this.controller.notifyPropertyChange('allCollections');
      this.controller.notifyPropertyChange('brandFilter');
      this.controller.notifyPropertyChange('albumFilter');
      this.controller.notifyPropertyChange('statusFilter');
      this.controller.notifyPropertyChange('itemFilter');
    },

    onOpenManageModal: function onOpenManageModal() {
      var _this6 = this;

      this.controller.set('bookingDetailsPage', false);
      this.controller.set('disableBrandFilter', false);
      this.controller.set('bookingBrandsFilter', false);
      this._setStatusFilter(this.controller, 'addSamples');

      this.controller.set('isLoading', true);
      //this.send('setPreviousModalForSampleDetails', '#manage-booking-samples-detail-brand-refactor');
      //Enable observer for getStatuses
      this.controller.set('noRefreshStatuses', false);

      this.controller.set('searchInput', '');
      var booking = this.controller.get('booking');
      if (Ember['default'].activeFilter !== '#addSamplesScrollTable') {
        filters['default'].setFilterComputeds(this, '#addSamplesScrollTable', '#addSamplesScrollGallery', true);
      }
      filters['default'].setScrollPaginationListener(this, '#addSamplesScrollTable', '#addSamplesScrollGallery');

      this.controller.get('samples').forEach(function (sample) {
        sample.set('checked', true);
      });

      var sampleList = Ember['default'].bulk.samples;
      Ember['default'].bulk.allSamples = sampleList.copy();
      Ember['default'].bulk.allSamples.forEach(function (sample) {
        sample.set('oldStatus', null);
      });

      var samples = utils['default'].getSamples();

      if (booking.get('isForeign')) {
        samples.forEach(function (sample) {
          sample.set('visible', sample.get('showroom.id') === Ember['default'].showroom.get('id'));
        });
      } else {
        samples.setEach('visible', true);
      }

      this.controller.get('samples').forEach(function (sample) {
        return sample.set('visible', false);
      });

      this.controller.notifyPropertyChange('allCollections');
      this.controller.notifyPropertyChange('brandFilter');
      this.controller.notifyPropertyChange('albumFilter');
      this.controller.notifyPropertyChange('statusFilter');
      this.controller.notifyPropertyChange('itemFilter');

      this.controller.set('isLoadingStatuses', false);

      Ember['default'].run.later(function () {
        _this6.controller.set('isManageLoading', false);

        _this6.controller.set('isLoading', true);

        utils['default'].lazyLoad(2000, '#addSamplesScrollTable');
        Ember['default'].openModal();
      }, 500);
    },

    onSaveManageBooking: function onSaveManageBooking() {
      var _this7 = this;

      this.controller.set('isManageLoading', true);
      this.controller.set('bookingDetailsPage', true);

      this.send('openMessageModal', 'Updating...');
      //this.controller.set('disableBrandFilter', true);
      this.controller.set('bookingBrandsFilter', true);

      var bookingToSave = this.controller.get('booking');
      var oldSamplesBooking = bookingToSave.get('samples');
      var promises = [];

      var diffArray = Ember['default'].bulk.samples.reject(function (item) {
        return oldSamplesBooking.contains(item);
      }, oldSamplesBooking);

      diffArray.forEach(function (sample) {
        var ss = _this7.store.createRecord('sampleStatus', { sample: sample, booking: bookingToSave, dateAccepted: new Date() });
        if (sample.get('showroom.id') === Ember['default'].showroom.get('id')) {
          ss.set('dateConfirmed', new Date());
        } else {
          promises.push(utils['default'].postAPIJSON('bookings/' + ss.get('booking.id') + '/sample/' + sample.get('id')));
        }
        var promise = ss.save().then(function (res) {
          bookingToSave.get('sampleStatus').addObject(res);
        });
        promises.push(promise);
      });

      Ember['default'].RSVP.all(promises).then(function () {
        _this7.controller.get('booking').reload().then(function () {
          bookingToSave.get('samples').addObjects(Ember['default'].bulk.samples);
          bookingToSave.save().then(function () {
            //this.resetBooking();
            _this7.controller.get('booking').resetSampleStatusLabels();
            //this.notifyPropertyChange('samples');

            _this7.resetController();

            _this7.controller.notifyPropertyChange('allCollections');
            _this7.controller.notifyPropertyChange('brandFilter');
            _this7.controller.notifyPropertyChange('albumFilter');
            _this7.controller.notifyPropertyChange('collectionFilter');
            _this7.controller.notifyPropertyChange('statusFilter');
            _this7.controller.notifyPropertyChange('itemFilter');
            filters['default'].setFilterComputeds(_this7);
            filters['default'].removeScrollListener(_this7);

            _this7.send('closeMessageModal');
          });
        });
      });
      //filters.setFilterComputeds(this);
    },

    onSaveProposal: function onSaveProposal() {
      var _this8 = this;

      this.controller.set('isManageLoading', true);
      this.send('openMessageModal', 'Doing proposals...');
      //this.controller.set('disableBrandFilter', true);
      this.controller.set('bookingBrandsFilter', true);

      var _t = this;
      var bookingToSave = this.controller.get('booking');
      var oldSamplesBooking = bookingToSave.get('samples');
      var myShowroomId = Ember['default'].showroom.get('id');
      var isForeign = bookingToSave.get('isForeign');
      var promise = undefined,
          postData = undefined;

      var samplesArray = [];
      var foreignSamplesArray = [];

      var newSamplesInArray = Ember['default'].bulk.samples.reject(function (item) {
        return oldSamplesBooking.contains(item);
      }, oldSamplesBooking);

      newSamplesInArray.forEach(function (sample) {
        if (sample.get('showroom.id') === myShowroomId && !isForeign) {
          samplesArray.addObject(sample.get('id'));
          sample.set('shoppingListStatus', 'PROPOSED BY YOU');
        } else if (sample.get('showroom.id') === myShowroomId && isForeign) {
          samplesArray.addObject(sample.get('id'));
          sample.set('shoppingListStatus', 'PENDING PROPOSAL');
        } else {
          foreignSamplesArray.addObject(sample.get('id'));
          sample.set('shoppingListStatus', 'PENDING PROPOSAL');
        }
        sample.set('warning', false);
        sample.set('checked', true);
        sample.set('isCanceled', false);
        _this8.controller.get('samples').addObject(sample);
      });

      if (isForeign) {
        postData = {
          booking: bookingToSave.get('id'),
          samples: samplesArray
        };
        promise = utils['default'].postAPIJSON('sampleStatus/proposal/createFromPROwner', postData);
      } else {
        postData = {
          booking: bookingToSave.get('id'),
          samples: samplesArray,
          foreignSamples: foreignSamplesArray
        };
        promise = utils['default'].postAPIJSON('sampleStatus/proposal/create', postData);
      }

      Ember['default'].RSVP.all([promise]).then(function () {
        //this.resetBooking();
        _t.resetController();
        _t.controller.get('booking').reload().then(function () {
          _t.notifyPropertyChange('samples');

          _t.controller.notifyPropertyChange('allCollections');
          _t.controller.notifyPropertyChange('brandFilter');
          _t.controller.notifyPropertyChange('albumFilter');
          _t.controller.notifyPropertyChange('collectionFilter');
          _t.controller.notifyPropertyChange('statusFilter');
          _t.controller.notifyPropertyChange('itemFilter');
          filters['default'].setFilterComputeds(_t);
          filters['default'].removeScrollListener(_t);

          _this8.send('closeMessageModal');
          //this.send('openTopBar','Sample/s proposed');
          _this8.send('openPositiveTopBar');
        });
      });
    },

    resetBooking: function resetBooking() {
      var _this9 = this;

      var prov = this.controller.get('booking').serialize();
      prov.id = this.controller.get('booking.id');
      prov.addresses = this.controller.get('booking.addresses');
      Ember['default'].bookings.set('bookingsList', Ember['default'].A([prov]));
      Ember['default'].bookings.set('bookingsDate', new Date());
      this.onResetSamples();

      this.controller.get('booking').resetSampleStatusLabels().then(function () {
        _this9.onResetSamples();
        _this9.controller.set('isLoading', false);
      });
    },

    onOpenAddProposalModal: function onOpenAddProposalModal(booking) {
      var _this10 = this;

      this.controller.set('disableBrandFilter', false);
      this.controller.set('bookingBrandsFilter', false);
      this._setStatusFilter(this.controller, 'addSamples');

      this.controller.set('isLoading', true);
      //this.send('setPreviousModalForSampleDetails', '#manage-proposal-samples-detail-brand-refactor');
      //Enable observer for getStatuses
      this.controller.set('noRefreshStatuses', false);
      this.controller.set('searchInput', '');

      var foreign = booking.get('isForeign');

      if (Ember['default'].activeFilter !== '#addProposalsScrollTable') {
        if (this.controller.get('booking.job') && this.controller.get('booking.isForeign')) {
          //filters.setFilterComputeds(this, '#manage-proposal-samples-detail-brand-refactor', '#manage-proposal-samples-detail-brand-refactor .modal-dialog', true, true);
          filters['default'].setFilterComputeds(this, '#addProposalsScrollTable', '#addProposalsScrollGallery', true, true);
        } else {
          //filters.setFilterComputeds(this, '#manage-proposal-samples-detail-brand-refactor', '#manage-proposal-samples-detail-brand-refactor .modal-dialog', true);
          filters['default'].setFilterComputeds(this, '#addProposalsScrollTable', '#addProposalsScrollGallery', true);
        }
      }
      filters['default'].setScrollPaginationListener(this, '#addProposalsScrollTable', '#addProposalsScrollGallery');

      this.controller.set('isManageLoading', true);

      //Setting samples in shopping list
      this.controller.get('samples').forEach(function (sample) {
        if (foreign) {
          sample.set('checked', sample.get('isProposalEditable'));
        } else {
          sample.set('checked', true);
        }
      });

      var sampleList = Ember['default'].bulk.samples;
      Ember['default'].bulk.allSamples = sampleList.copy();
      Ember['default'].bulk.allSamples.forEach(function (sample) {
        sample.set('oldStatus', null);
      });

      //Settings samples in add proposal modal
      if (foreign) {
        this.controller.get('samples').forEach(function (sample) {
          sample.setVisibleIfForeignCanPropose(_this10.controller.get('booking.owner.showroom'));
        });
        utils['default'].getSamples().forEach(function (sample) {
          sample.setVisibleIfForeignCanPropose(_this10.controller.get('booking.owner.showroom'));
        });
      } else {
        this.controller.get('samples').forEach(function (sample) {
          return sample.set('visible', false);
        });
      }

      this.controller.notifyPropertyChange('allCollections');
      this.controller.notifyPropertyChange('brandFilter');
      this.controller.notifyPropertyChange('albumFilter');
      this.controller.notifyPropertyChange('statusFilter');
      this.controller.notifyPropertyChange('itemFilter');

      Ember['default'].run.later(function () {
        _this10.controller.set('isManageLoading', false);
        utils['default'].lazyLoad(2000, '#addProposalsScrollTable');
        Ember['default'].openModal();
      }, 500);
    },

    onSelectAllCheckSamples: function onSelectAllCheckSamples() {
      Ember['default'].$('#checkout-step1 .blankCheckbox').each(function (i, cb) {
        if (!cb.checked) {
          cb.click();
        }
      });
    },

    onClearCheckSamples: function onClearCheckSamples() {
      Ember['default'].$('#checkout-step1 .blankCheckbox').each(function (i, cb) {
        if (cb.checked) {
          cb.click();
        }
      });
    },

    onAcceptSampleProposal: function onAcceptSampleProposal(sample) {
      var _this11 = this;

      var sampleStatusID = sample.get('sampleStatus.id');
      utils['default'].putAPIJSON('sampleStatus/' + sampleStatusID + '/acceptSampleProposal').then(function (res) {
        sample.set('shoppingListStatus', res.sampleStatus);
        //this.send('openTopBar', 'Sample proposal accepted.');
        _this11.send('openPositiveTopBar');
        checkFunctions['default'].updateBookingStatus(_this11);
      });
    },

    onDeclineSampleProposal: function onDeclineSampleProposal(sample) {
      var _this12 = this;

      var sampleStatusID = sample.get('sampleStatus.id');
      utils['default'].putAPIJSON('sampleStatus/' + sampleStatusID + '/declineSampleProposal').then(function (res) {
        sample.set('shoppingListStatus', res.sampleStatus);
        sample.set('toCheckout', false);
        sample.set('isCanceled', true);
        _this12.send('openTopBar', 'Sample proposal declined.');
        checkFunctions['default'].updateBookingStatus(_this12);
      });
    },

    onCancelSampleProposal: function onCancelSampleProposal(sample) {
      var _this13 = this;

      var _t = this;
      var bookingToSave = this.controller.get('booking');

      var btn = Ember['default'].$('#sample-cancel-' + sample.get('id'));
      btn.button('loading');

      var postData = {
        bookingId: bookingToSave.get('id'),
        sampleId: sample.get('id')
      };
      utils['default'].postAPIJSON('sampleStatus/cancelSampleProposal', postData).then(function () {
        sample.set('shoppingListStatus', 'CANCELED BY YOU');
        sample.set('toCheckout', false);
        sample.set('isCanceled', true);
        _t.send('openTopBar', 'Sample proposal cancelled.');
        checkFunctions['default'].updateBookingStatus(_this13);
        btn.button('reset');
      }, function (res) {
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }
        _t.controller.get('booking').resetSampleStatusLabels().then(function () {
          _t.onResetSamples();
          _t.send('openTopBar', 'Ooops! ' + errors);
          _t.controller.get('booking').reload().then(function () {
            btn.button('reset');
          });
        });
      });
    },
    onClickSelectSample: function onClickSelectSample(sample) {
      var currentStatus = sample.get('checked');
      sample.set('checked', !currentStatus);
    },

    onSwapReturnAddress: function onSwapReturnAddress(direction, event) {
      var _this14 = this;

      var addresses = this.controller.get('allShowrooms');
      if (addresses.length > 1) {
        (function () {
          var firstMove = "0%";
          var secondMove = "0%";
          if (direction === "next") {
            firstMove = "-100%";
            secondMove = "+200%";
          } else {
            firstMove = "+100%";
            secondMove = "-200%";
          }
          var booking = _this14.controller.get('booking');
          var _t = _this14;
          var changeTo = null;

          var sliders = null;
          //CLICK ARROW IMG
          if (Ember['default'].$(event.target).prop("tagName") === "IMG") {
            sliders = Ember['default'].$(event.target).parent().siblings('.address-card').get(0);
          }
          //CLICK SPAN (PARENT FROM ARROW IMG)
          else {
              sliders = Ember['default'].$(event.target).siblings('.address-card').get(0);
            }
          $(sliders).animate({ left: firstMove }, 250, function () {
            //CHANGE INFO
            for (var i = 0; i < addresses.length; i++) {
              if (addresses[i].get('id') === booking.get('returnId')) {
                if (direction === "next") {
                  //If last address => next will be first address
                  if (i === addresses.length - 1) {
                    changeTo = addresses[0];
                  } else {
                    changeTo = addresses[i + 1];
                  }
                } else {
                  //If first address => prev is the last address;
                  if (i === 0) {
                    changeTo = addresses[addresses.length - 1];
                  } else {
                    changeTo = addresses[i - 1];
                  }
                }
                break;
              }
            }
            _t.controller.get('booking').setProperties({
              returnId: changeTo.get('id'),
              returnStreet1: changeTo.get('street'),
              returnStreet2: changeTo.get('street2'),
              returnStreet3: changeTo.get('street3'),
              returnCountry: changeTo.get('country'),
              returnCity: changeTo.get('city'),
              returnZipCode: changeTo.get('zipCode'),
              returnTelephone: changeTo.get('telephone'),
              returnTelephone2: changeTo.get('telephone2')
            });
            //END CHANGE INFO
            $(sliders).animate({ left: secondMove }, 0, function () {
              $(sliders).animate({ left: "0" }, 250);
            });
          });
        })();
      }
    },

    onSwapCallInAddress: function onSwapCallInAddress(direction, event) {
      var _this15 = this;

      var addresses = this.controller.get('addressesRequester');
      if (addresses.length > 1) {
        (function () {
          var firstMove = "0%";
          var secondMove = "0%";
          if (direction === "next") {
            firstMove = "-100%";
            secondMove = "+200%";
          } else {
            firstMove = "+100%";
            secondMove = "-200%";
          }
          var booking = _this15.controller.get('booking');
          var changeTo = null;
          var _t = _this15;

          var sliders = null;
          //CLICK ARROW IMG
          if (Ember['default'].$(event.target).prop("tagName") === "IMG") {
            sliders = Ember['default'].$(event.target).parent().siblings('.address-card').get(0);
          }
          //CLICK SPAN (PARENT FROM ARROW IMG)
          else {
              sliders = Ember['default'].$(event.target).siblings('.address-card').get(0);
            }
          $(sliders).animate({ left: firstMove }, 250, function () {
            //CHANGE INFO
            for (var i = 0; i < addresses.length; i++) {
              if (addresses[i].get('id') === booking.get('callInId')) {
                if (direction === "next") {
                  //If last address => next will be first address
                  if (i === addresses.length - 1) {
                    changeTo = addresses[0];
                  } else {
                    changeTo = addresses[i + 1];
                  }
                } else {
                  //If first address => prev is the last address;
                  if (i === 0) {
                    changeTo = addresses[addresses.length - 1];
                  } else {
                    changeTo = addresses[i - 1];
                  }
                }
                break;
              }
            }
            if (booking.get('isInternal')) {
              booking.setProperties({
                callInId: changeTo.get('id'),
                callInStreet1: changeTo.get('street1'),
                callInStreet2: changeTo.get('street2'),
                callInStreet3: changeTo.get('street3'),
                callInCountry: changeTo.get('country'),
                callInCity: changeTo.get('city'),
                callInZipCode: changeTo.get('zipCode'),
                callInTelephone: changeTo.get('telephone1'),
                callInTelephone2: changeTo.get('telephone2')
              });
            } else {
              booking.setProperties({
                callInId: changeTo.get('id'),
                callInStreet1: changeTo.get('street1'),
                callInStreet2: changeTo.get('street2'),
                callInStreet3: changeTo.get('street3'),
                callInCountry: changeTo.get('country'),
                callInCity: changeTo.get('city'),
                callInZipCode: changeTo.get('zipCode'),
                callInTelephone: changeTo.get('telephone'),
                callInMain: changeTo.get('main')
              });
            }
            //END CHANGE INFO
            $(sliders).animate({ left: secondMove }, 0, function () {
              $(sliders).animate({ left: "0" }, 250);
            });
          });
        })();
      }
    },

    _resetNewCallInAddress: function _resetNewCallInAddress() {
      var newCallInAddress = this.store.createRecord('contact-address', {
        street1: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipcode: '',
        telephone1: '',
        telephone2: '',
        setAsMain: false
      });
      this.controller.set('newCallInAddress', newCallInAddress);
    },

    onNewCallInAddress: function onNewCallInAddress() {
      var newCallInAddress = this.store.createRecord('contact-address', {
        street1: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipcode: '',
        telephone1: '',
        telephone2: '',
        main: false,
        contact: this.controller.get('booking.requester')
      });
      newCallInAddress.set('setAsMain', false);
      this.controller.set('newCallInAddress', newCallInAddress);

      Ember['default'].$('#new-callin-address').modal('show');
    },

    onCancelNewCallIn: function onCancelNewCallIn() {
      this._resetNewCallInAddress();
      Ember['default'].$('#new-callin-address').modal('hide');
    },

    onSaveNewCallIn: function onSaveNewCallIn() {
      var btn = Ember['default'].$('#save-new-callin-address-btn');
      btn.button('loading');
      var _t = this;
      var addresses = this.controller.get('addressesRequester');
      var newObj = this.controller.get('newCallInAddress');
      var contact = this.controller.get('booking.requester');

      if (newObj.validate()) {

        if (newObj.get('setAsMain')) {
          newObj.set('contact', null);
          newObj.set('public', contact);
          //Set The Main address for mark it as main in the Edit Booking Address Form
          _t.controller.set('mainAddressFromRequester', newObj);
        } else {
          newObj.set('contact', contact);
          newObj.set('public', null);
        }

        if (addresses.length === 0) {
          addresses.addObject(newObj);
        } else if (addresses.get(addresses.length - 1).get('id') === 0) {
          addresses.set(addresses.length - 1, newObj);
        } else {
          addresses.addObject(newObj);
          this.controller.set('moreThan1Addresses', true);
        }

        newObj.save().then(function (changeTo) {
          _t.controller.get('booking').setProperties({
            callInId: changeTo.get('id'),
            callInStreet1: changeTo.get('street1'),
            callInStreet2: changeTo.get('street2'),
            callInStreet3: changeTo.get('street3'),
            callInCountry: changeTo.get('country'),
            callInCity: changeTo.get('city'),
            callInZipCode: changeTo.get('zipCode'),
            callInTelephone: changeTo.get('telephone1'),
            callInTelephone2: changeTo.get('telephone2')
          });
          Ember['default'].$('#new-callin-address').modal('hide');
          btn.button('reset');
        });
      } else {
        btn.button('reset');
        //console.log(newObj.get('country'));
      }
    },

    _resetNewReturnAddress: function _resetNewReturnAddress() {
      var controller = this.controller;
      var newReturnAddress = Ember['default'].Object.create({
        street: '',
        street2: '',
        street3: '',
        country: null,
        city: '',
        zipCode: '',
        telephone: '',
        telephone2: ''
      });
      this.controller.set('newReturnAddress', newReturnAddress);
      controller.returnAddressHasSomeFieldObserver = function () {
        var newReturnAddress = controller.get('newReturnAddress');
        var params = [];
        params.push(newReturnAddress.get('street'));
        params.push(newReturnAddress.get('street2'));
        params.push(newReturnAddress.get('street3'));
        params.push(newReturnAddress.get('country'));
        params.push(newReturnAddress.get('city'));
        params.push(newReturnAddress.get('zipCode'));
        params.push(newReturnAddress.get('telephone'));
        params.push(newReturnAddress.get('telephone2'));
        var hasSomeField = params.some(function (p) {
          return !Ember['default'].isBlank(p);
        });
        controller.set('newReturnHasSomeField', hasSomeField);
      };
      utils['default'].addObserver(controller, ['newReturnAddress.street', 'newReturnAddress.street2', 'newReturnAddress.street3', 'newReturnAddress.country', 'newReturnAddress.zipCode', 'newReturnAddress.city', 'newReturnAddress.telephone', 'newReturnAddress.telephone2'], 'returnAddressHasSomeFieldObserver');
    },

    onNewReturnAddress: function onNewReturnAddress() {
      this._resetNewReturnAddress();
      Ember['default'].$('#new-return-address').modal('show');
    },

    onCancelNewReturn: function onCancelNewReturn() {
      this._resetNewReturnAddress();
      Ember['default'].$('#new-return-address').modal('hide');
    },

    onSaveNewReturn: function onSaveNewReturn() {
      var btn = Ember['default'].$('#save-new-return-address-btn');
      btn.button('loading');
      var addresses = this.controller.get('allShowrooms');
      var newObj = this.controller.get('newReturnAddress');
      newObj.set('id', 0);
      if (addresses.get(addresses.length - 1).get('id') === 0) {
        addresses[addresses.length - 1] = newObj;
      } else {
        addresses.addObject(newObj);
        this.controller.set('moreThan1Showroom', true);
      }
      this.controller.get('booking').setProperties({
        returnId: newObj.get('id'),
        returnStreet1: newObj.get('street'),
        returnStreet2: newObj.get('street2'),
        returnStreet3: newObj.get('street3'),
        returnCountry: newObj.get('country'),
        returnCity: newObj.get('city'),
        returnZipCode: newObj.get('zipCode'),
        returnTelephone: newObj.get('telephone'),
        returnTelephone2: newObj.get('telephone2')
      });
      Ember['default'].$('#new-return-address').modal('hide');
      btn.button('reset');
    },

    onGoToScanning: function onGoToScanning() {
      var booking = this.controller.get('booking');
      this.transitionTo('barcodes.scan-booking', booking.get('id'));
    },

    onGoToLoanFormPage: function onGoToLoanFormPage(booking, loanForm) {
      var _this = this;
      if (loanForm.get('type') == "defaultParcel") {
        _this.transitionTo('bookings.loan-form', booking, loanForm.get('defaultParcel'));
      } else {
        _this.transitionTo('bookings.ddt', booking, loanForm.get('ddtParcel'));
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-bookings booking-details');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-bookings booking-details');
    },

    onToggleSetAsMainAddress: function onToggleSetAsMainAddress(newCallInAddress) {
      if (newCallInAddress.setAsMain) {
        Ember['default'].set(newCallInAddress, 'setAsMain', false);
      } else {
        Ember['default'].set(newCallInAddress, 'setAsMain', true);
      }
    },

    onUpdateOverviewAndBooking: function onUpdateOverviewAndBooking() {
      var _this16 = this;

      //Disable observer for getStatuses
      this.controller.set('noRefreshStatuses', true);

      var bookingToAdd = this.controller.get('booking');
      this.send('openMessageModal', 'Updating Booking, please wait.');

      if (bookingToAdd.get('checked') && !this.controller.get('fromAddressBook')) {
        var contactAddress = contactFunctions['default'].onAddAnotherAddress(this, this.controller.get('bookingToAdd.requester'));
        contactAddress.set('street1', bookingToAdd.get('callInStreet1'));
        contactAddress.set('street2', bookingToAdd.get('callInStreet2'));
        contactAddress.set('street3', bookingToAdd.get('callInStreet3'));
        contactAddress.set('country', bookingToAdd.get('callInCountry'));
        contactAddress.set('city', bookingToAdd.get('callInCity'));
        contactAddress.set('zipCode', bookingToAdd.get('callInZipCode'));
        contactAddress.set('telephone1', bookingToAdd.get('callInTelephone'));
        contactAddress.save();
      }

      //Create booking
      //Company modified
      this.controller.set('companyBookingToAdd', bookingToAdd.get('company'));

      this.send('openMessageModal', 'Updating Booking, please wait.');
      this.controller.set('collectionFilter', '');

      //Save new requester!
      this.controller.set('oldRequester', this.controller.get('booking.requester'));

      bookingToAdd.save().then(function (bookingUpdated) {
        _this16.controller.get('booking').resetSampleStatusLabels().then(function () {
          _this16.controller.notifyPropertyChange('samples');
          _this16.controller.notifyPropertyChange('samples.@each.shoppingListStatus');

          //Refreshing addresses
          _this16.controller.notifyPropertyChange('callInStreet1');
          _this16.controller.notifyPropertyChange('callInStreet2');
          _this16.controller.notifyPropertyChange('callInStreet3');
          _this16.controller.notifyPropertyChange('callInCountry');
          _this16.controller.notifyPropertyChange('callInZipCode');
          _this16.controller.notifyPropertyChange('callInCity');
          _this16.controller.notifyPropertyChange('callInTelephone');

          _this16.send('closeMessageModal');
          Ember['default'].$('.modal-backdrop').remove();
        });
      });
    },

    onCloseNewCallInAddressModal: function onCloseNewCallInAddressModal() {
      Ember['default'].$('#new-callin-address').modal('hide');
      this.controller.get('newCallInAddress').rollbackAttributes();
    },

    onOpenReverseCheckOutModal: function onOpenReverseCheckOutModal(sample) {
      var _this17 = this;

      if (sample.get('archived')) {
        this.send('openTopBar', "It is not possible to revert the check-out for archived samples!");
      } else {

        var booking = this.controller.get('booking');

        utils['default'].openDefaultConfirmationModal(this, 'Reverse Check Out', 'Check Out will be reversed', 'Do you wish to proceed?', 'Yes', 'No').then(function () {
          _this17._reverseCheckoutForSample(sample);
        });
      }
    },

    _reverseCheckoutForSample: function _reverseCheckoutForSample(sample) {
      var _this18 = this;

      var bookingId = this.controller.get('booking').get('id');
      var booking = this.controller.get('booking');

      var promise = utils['default'].putAPIJSON('sampleStatus/reverse_checkout/booking/' + bookingId + '/sample/' + sample.id);
      utils['default'].buttonLoading('#reverse-checkout-' + sample.id, promise);
      this.send('openMessageModal', 'Reversing Check Out...');

      promise.then(function () {
        //success
        _this18.refresh().then(function () {
          _this18.send('closeMessageModal');
          _this18.send('openTopBar', 'Check Out was reversed succesfully');
        });
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }

        _this18.controller.get('booking').resetSampleStatusLabels().then(function () {
          _this18.onResetSamples();
          _this18.controller.get('booking').reload().then(function () {
            _this18.send('closeMessageModal');
            _this18.send('openTopBar', 'Ooops! ' + errors);
          });
        });
      });
    },

    onOpenReverseCheckInModal: function onOpenReverseCheckInModal(sample) {
      var _this19 = this;

      if (sample.get('archived')) {
        this.send('openTopBar', "It is not possible to revert the check-in for archived samples!");
      } else {
        var booking = this.controller.get('booking');

        utils['default'].openDefaultConfirmationModal(this, 'Reverse Check In', 'Check In will be reversed', 'Do you wish to proceed?', 'Yes', 'No').then(function () {
          _this19._reverseCheckinForSample(sample);
        });
      }
    },

    _reverseCheckinForSample: function _reverseCheckinForSample(sample) {
      var _this20 = this;

      var bookingId = this.controller.get('booking').get('id');
      var booking = this.controller.get('booking');

      var promise = utils['default'].putAPIJSON('sampleStatus/reverse_checkin/booking/' + bookingId + '/sample/' + sample.id);
      utils['default'].buttonLoading('#reverse-checkout-' + sample.id, promise);
      this.send('openMessageModal', 'Reversing Check In...');

      promise.then(function () {
        //success
        var wasClosed = booking.get('closed');
        _this20.refresh().then(function () {
          _this20.send('closeMessageModal');
          if (wasClosed) {
            _this20.send('openTopBar', 'Check In was reversed and booking has been reactivated succesfully');
          } else {
            _this20.send('openTopBar', 'Check In was reversed succesfully');
          }
        });
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }

        _this20.controller.get('booking').resetSampleStatusLabels().then(function () {
          _this20.onResetSamples();
          _this20.controller.get('booking').reload().then(function () {
            _this20.send('closeMessageModal');
            _this20.send('openTopBar', 'Ooops! ' + errors);
          });
        });
      });
    },

    onSetFeaturedSample: function onSetFeaturedSample(value, sample) {
      Ember['default'].set(sample, 'loadingStatus', true);
      var _t = this;
      var controller = _t.controller;
      var bookingId = controller.get('booking.id');

      var actionLabel = "";
      if (value == "YES") {
        actionLabel = "Marking sample as Featured...";
      } else if (value == "NO") {
        actionLabel = "Marking sample as Not Featured...";
      } else {
        actionLabel = "Reversing Featured status...";
      }
      //this.send('openMessageModal', actionLabel);

      utils['default'].putAPIJSON('sampleStatus/sample/' + sample.id + '/booking/' + bookingId + '/featured', { value: value, current: sample.get('featured') }).then(function (success) {
        var label = "";
        if (value == "YES") {
          label = "FEATURED";
        } else if (value == "NO") {
          label = "NOT FEATURED";
        } else {
          label = "RETURNED";
        }
        Ember['default'].set(sample, 'featured', value);
        Ember['default'].set(sample, 'featuredLabel', label);

        //_t.send('closeMessageModal');
        if (value == "YES") {
          _t.send('openPositiveTopBar');
        } else if (value == "TBC") {
          _t.send('openTopBarWithTime', 'Sample action reversed succesfully.', 3500);
        }
        Ember['default'].set(sample, 'loadingStatus', false);
      }, function (fail) {
        var errors = '';
        if (fail && fail.responseText) {
          errors = JSON.parse(fail.responseText).errors;
        }
        controller.get('booking').resetSampleStatusLabels().then(function () {
          _t.onResetSamples();
          controller.get('booking').reload().then(function () {
            //_t.send('closeMessageModal');
            _t.send('openTopBar', 'Ooops! ' + errors);
            Ember['default'].set(sample, 'loadingStatus', false);
          });
        });
      });
    },

    _setStatusFilter: function _setStatusFilter(controller, list) {
      if (list == 'addSamples') {
        controller.set('statusContent', [{ id: 'All statuses' }, { id: 'AVAILABLE' }, { id: 'BOOKED' },
        //{id: 'IN TRANSFER'},
        { id: 'OVERLAP' }, { id: 'UNAVAILABLE' }]);
        controller.set('bookingDetailsStatuses', false);
      } else {
        controller.set('statusContent', [{ id: 'All statuses' }, { id: 'AVAILABLE' }, { id: 'BOOKED' }, { id: 'CANCELED' }, { id: 'CHECK IN TODAY' }, { id: 'CHECK OUT TODAY' }, { id: 'DECLINED' }, { id: 'LATE CHECK IN' }, { id: 'LATE CHECK OUT' }, { id: 'OUT' }, { id: 'OVERLAP' }, { id: 'PROPOSED' },
        //{id: 'IN TRANSFER'},
        { id: 'RETURNED' }, { id: 'UNAVAILABLE' }]);
        controller.set('bookingDetailsStatuses', true);
      }
    },

    onAddContactMpToLists: function onAddContactMpToLists(contact) {
      var controller = this.controller;
      Ember['default'].$('#view-contact-profile').modal('hide');

      var user = contact.get('user');
      if (user.get('content')) {
        user = user.get('content');
      }
      this.controller.set('addContactSelectedUser', user);
      this.controller.set('canAddContact', false);

      this.controller.set('addContactSelectedLists', []);

      Ember['default'].$('#add-mp').modal();
      Ember['default'].openModal();
      //contactFunctions.setCanAddContactObserver(this.controller);

      if (Ember['default'].isNone(controller.get('contactLists'))) {
        controller.set('cListsLoading', true);
        utils['default'].getContactLists(this).then(function (res) {
          controller.set('cListsLoading', false);
          controller.set('contactLists', res);
        });
      } else {
        controller.set('cListsLoading', false);
      }

      controller.addContactSelectedUserObserver = function () {
        var addContactSelectedUser = controller.get('addContactSelectedUser');
        var addContactSelectedLists = controller.get('addContactSelectedLists');
        controller.set('canAddContact', addContactSelectedUser && addContactSelectedLists.length);
      };
      utils['default'].addObserver(controller, ['addContactSelectedUser', 'addContactSelectedLists.length'], 'addContactSelectedUserObserver');
    },

    onAddContactNotMpToLists: function onAddContactNotMpToLists(contact) {
      var controller = this.controller;
      Ember['default'].$('#view-contact-profile').modal('hide');

      if (contact.get('content')) {
        contact = contact.get('content');
      }

      this.controller.set('addContactEntity', contact);
      this.controller.set('canAddNotMpEntity', false);

      this.controller.set('addNotMpLists', []);

      Ember['default'].$('#add-not-mp').modal();
      Ember['default'].openModal();
      //contactFunctions.setCanAddContactObserver(this.controller);

      if (Ember['default'].isNone(controller.get('contactLists'))) {
        controller.set('cListsLoading', true);
        utils['default'].getContactLists(this).then(function (res) {
          controller.set('cListsLoading', false);
          controller.set('contactLists', res);
        });
      } else {
        controller.set('cListsLoading', false);
      }

      controller.addContactEntityObserver = function () {
        var addContactEntity = controller.get('addContactEntity');
        var addNotMpLists = controller.get('addNotMpLists');
        controller.set('canAddNotMpEntity', addContactEntity && addNotMpLists.length);
      };
      utils['default'].addObserver(controller, ['addContactEntity', 'addNotMpLists.length'], 'addContactEntityObserver');
    },

    onCancelAddNotMp: function onCancelAddNotMp(contactToAdd) {
      Ember['default'].$('#add-not-mp').modal('hide');
      this.controller.set('addContactEntity', null);
      this.controller.set('addNotMpLists', []);
      Ember['default'].$('#view-contact-profile').modal();
      Ember['default'].openModal();
    },

    onCancelAddMp: function onCancelAddMp(contactToAdd) {
      Ember['default'].$('#add-mp').modal('hide');
      Ember['default'].$('#add-mp-found').modal('hide');
      this.controller.set('addContactSelectedUser', null);
      this.controller.set('addContactSelectedLists', []);
    },
    onSaveContactFromUserRefactor: function onSaveContactFromUserRefactor() {
      var route = this;
      var button = Ember['default'].$('#add-mp-contacts-lists');
      button.button('loading');
      //button2.button('loading');
      //Check limits
      var contactsAllowedForPlanUser = route.controller.get('contactsAllowedForPlanUser');
      if (contactsAllowedForPlanUser != true) {
        //Ember.$('#add-mp-found').modal('hide');
        Ember['default'].$('#add-mp').modal('hide');
        button.button('reset');
        //button2.button('reset');
      } else {
          (function () {
            var user = route.controller.get('addContactSelectedUser');
            route.send('openMessageModal', 'Adding contact...');
            Ember['default'].$('#add-mp').modal('hide');
            Ember['default'].$('#add-mp-found').modal('hide');
            route.store.find('contact', { user: user.id }).then(function (data) {
              var contact = data.objectAt(0);
              var selectedLists = route.controller.get('addContactSelectedLists');
              var promise = null;
              if (contact) {
                contact.get('contactsLists').addObjects(selectedLists);

                contact.save().then(function () {
                  route.controller.notifyPropertyChange('contactLists');
                  Ember['default'].set(user, 'checked', false);

                  route.send('closeMessageModal');
                  button.button('reset');
                  //button2.button('reset');
                  //route.send('openTopBar', 'Contact has been added succesfully...');
                  route.send('openPositiveTopBar');
                  Ember['default'].$('#view-contact-profile').modal();
                  Ember['default'].openModal();
                });
              } else {
                route.store.find('user', user.id).then(function (userDB) {
                  var createdContact = route.store.createRecord('contact', {
                    name: user.name,
                    surname: user.surname,
                    jobTitle: user.jobTitle,
                    email: user.email,
                    company: user.company,
                    user: userDB
                  });
                  createdContact.get('contactsLists').addObjects(selectedLists);

                  createdContact.save().then(function () {
                    route.controller.notifyPropertyChange('contactLists');
                    Ember['default'].set(user, 'checked', false);

                    route.send('closeMessageModal');
                    button.button('reset');
                    //button2.button('reset');
                    //route.send('openTopBar', 'Contact has been added succesfully...');
                    route.send('openPositiveTopBar');
                    Ember['default'].$('#view-contact-profile').modal();
                    Ember['default'].openModal();
                  });
                });
              }
            });
          })();
        }
    },
    onSaveContactNotMP: function onSaveContactNotMP() {
      var route = this;
      var button = Ember['default'].$('#add-not-mp-contacts-lists');
      button.button('loading');
      //Check limits
      var contactsAllowedForPlanUser = route.controller.get('contactsAllowedForPlanUser');
      if (contactsAllowedForPlanUser != true) {
        //Ember.$('#add-mp-found').modal('hide');
        Ember['default'].$('#add-not-mp').modal('hide');
        button.button('reset');
      } else {
        (function () {
          var contact = route.controller.get('addContactEntity');
          var lists = route.controller.get('addNotMpLists');

          route.send('openMessageModal', 'Adding contact...');
          Ember['default'].$('#add-not-mp').modal('hide');
          Ember['default'].$('#add-not-mp-found').modal('hide');

          var deferred = Ember['default'].$.Deferred();

          var contactsToDuplicate = [contact.id != null ? contact.id : contact.get('id')];

          var promises = [];
          lists.forEach(function (list) {
            var promise = utils['default'].putAPIJSON('contactsLists/' + list.id + '/duplicate', contactsToDuplicate);
            promises.push(promise);
          });

          Ember['default'].RSVP.all(promises).then(function () {
            deferred.resolve();
          });
          deferred.done(function () {
            route.store.findAll('contacts-list').then(function (lists) {
              route.controller.set('contactLists', lists);
              route.send('closeMessageModal');
              route.send('openPositiveTopBar');
              Ember['default'].$('#view-contact-profile').modal();
              Ember['default'].openModal();
            });
          });
        })();
      }
    },

    setAddressRequesterChangesObserver: function setAddressRequesterChangesObserver(controller, route) {

      controller.set('mainAddressRequester', Ember['default'].computed('booking.requester', function () {
        var requester = controller.get('booking.requester');
        //Comparison between oldRequester and requester must be through JSON.stringify
        if (JSON.stringify(requester) !== JSON.stringify(controller.get('oldRequester')) && requester.get('allAddresses')) {

          var addr = requester.get('allAddresses').get('firstObject');

          if (addr) {
            controller.get('booking').setProperties({
              callInId: addr.get('id'),
              callInStreet1: addr.get('street1'),
              callInStreet2: addr.get('street2'),
              callInStreet3: addr.get('street3'),
              callInCountry: addr.get('country'),
              callInCity: addr.get('city'),
              callInZipCode: addr.get('zipCode'),
              callInTelephone: addr.get('telephone1'),
              callInAttn: requester.get('fullName')
            });
          } else {
            controller.get('booking').setProperties({
              callInId: '',
              callInStreet1: '',
              callInStreet2: '',
              callInStreet3: '',
              callInCountry: '',
              callInCity: '',
              callInZipCode: '',
              callInTelephone: '',
              callInAttn: requester.get('fullName')
            });
            route.send('openTopBar', 'Warning: you have selected a requester without addresses!');
          }

          controller.get('booking').set('company', requester.get('company'));
          controller.get('booking').set('returnAttn', Ember['default'].user.get('fullName'));

          controller.set('fromAddressBook', true);
          controller.set('fromAddressBookInverse', false);

          //Set The Main address for mark it as main in the New Booking Address Form
          controller.set('mainAddressFromRequester', addr);

          return requester.get('allAddresses').get('firstObject');
        }
      }));
    },

    onAddContactWhenEditingBooking: function onAddContactWhenEditingBooking() {
      var route = this;

      Ember['default'].$('#edit-booking-addresses').modal('hide');

      route.controller.set('contactToAdd', route.store.createRecord('contact'));
      route.controller.set('addContactSelectedUser', 0);
      route.controller.set('canAddContact', false);

      route.controller.set('bookalookUsersLoading', false);
      var users = utils['default'].getAPIJSON('users/mp_contacts').then(function (res) {
        res.map(function (user) {
          //Add img src for show avatar in combo
          var safeString = user.safeString;
          var img = "<img src='" + user.avatar + "' class='avatar-select' />";
          var email = " <span class='display_none'>" + user.email + '</span>';
          user.safeString = new Ember['default'].Handlebars.SafeString(img + safeString + email);

          return user;
        });
        route.controller.set('bookalookUsers', res);
        route.controller.set('bookalookUsersLoading', "enabled");
      });

      route.controller.set('addContactSelectedLists', []);
      //Ember.$('#add-contact').modal();
      //Ember.openModal(1000, '#add-contact');
      Ember['default'].$('#new-contact-requester-editing-booking').modal();
      Ember['default'].openModal();
    },

    onSaveContactRequesterEditingBooking: function onSaveContactRequesterEditingBooking() {
      var route = this;
      var actionButton = Ember['default'].$('#add-mp-found-bookings-btn');
      var actionButton2 = Ember['default'].$('#new-contact-bookings-btn');
      actionButton.button('loading');
      actionButton2.button('loading');

      var user = route.controller.get('addContactSelectedUser');

      Ember['default'].$('#new-contact-requester-editing-booking').modal('hide');
      route.send('openMessageModal', 'Adding contact...');

      route.store.find('contact', { user: user.id }).then(function (data) {
        actionButton.button('reset');
        actionButton2.button('reset');
        var contact = data.objectAt(0);
        var selectedLists = route.controller.get('addContactSelectedLists');

        //Existing contact  (from MP)
        if (contact) {
          contact.get('contactsLists').addObjects(selectedLists);

          contact.save().then(function () {
            route.controller.notifyPropertyChange('contactLists');

            route.controller.get('booking').set('requester', contact);

            route.send('closeMessageModal');
            Ember['default'].$('#edit-booking-addresses').modal();
            Ember['default'].openModal();
            route.send('openPositiveTopBar');
          });
        } else {
          //Manual contact!
          route.store.find('user', user.id).then(function (userDB) {
            var createdContact = route.store.createRecord('contact', {
              name: user.name,
              surname: user.surname,
              jobTitle: user.jobTitle,
              email: user.email,
              company: user.company,
              user: userDB
            });
            createdContact.get('contactsLists').addObjects(selectedLists);

            createdContact.save().then(function () {
              route.controller.notifyPropertyChange('contactLists');

              route.controller.get('booking').set('requester', createdContact);

              route.send('closeMessageModal');
              Ember['default'].$('#edit-booking-addresses').modal();
              Ember['default'].openModal();
              route.send('openPositiveTopBar');
            });
          });
        }
      });
    },

    onOpenNewContactModal: function onOpenNewContactModal() {
      this.controller.set('addContactSelectedLists', []);
      contactFunctions['default'].openNewContactFromEditBookingModal(this);
    },

    onSaveNewContactRefactor: function onSaveNewContactRefactor() {
      var _this21 = this;

      var promise = contactFunctions['default'].onSaveNewContact(this);
      if (promise) {
        utils['default'].buttonLoading('#booking-create-date', promise);
        promise.then(function (c) {
          _this21.controller.get('booking').set('requester', c);
          _this21.controller.get('allContacts').addObject(c);
          _this21.controller.notifyPropertyChange('booking.requester');

          Ember['default'].$('#edit-booking-addresses').modal();
          Ember['default'].openModal();
        });
      }
    }

  });

});