define('bookalook/routes/onboarding/index', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/static-tables', 'bookalook/utils', 'bookalook/routes/session/functions'], function (exports, Ember, UserValidatorMixin, static_tables, utils, sessionFunctions) {

  'use strict';

  var status = undefined; //check status

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      goToStep1: function goToStep1() {
        this.changeStep(1);
      },
      startOnBoarding: function startOnBoarding() {
        this.onStartOnBoarding();
      },
      completeMpProfileStep: function completeMpProfileStep() {
        this.onCompleteMpProfileStep();
      },
      completeMpPositionStep: function completeMpPositionStep() {
        this.onCompleteMpPositionStep();
      },
      completeBrandStep: function completeBrandStep() {
        this.onCompleteBrandStep();
      },
      completeShowroomStep: function completeShowroomStep() {
        this.onCompleteShowroomStep();
      },
      completeProfileStep: function completeProfileStep() {
        this.onCompleteProfileStep();
      },
      completeUsersStep: function completeUsersStep(skip) {
        this.onCompleteUsersStep(skip);
      },
      addMoreUsers: function addMoreUsers() {
        this.onAddMoreUsers();
      },
      removeUser: function removeUser(user) {
        this.onRemoveUser(user);
      },
      checkUserEmail: function checkUserEmail() {
        this.onCheckUserEmail(event);
      }

    },

    model: function model() {
      static_tables.loadStaticTables(this);
      var _t = this;
      if (Ember['default'].isNone(Ember['default'].user) && !Ember['default'].isNone(Ember['default'].userOnBoard)) {
        Ember['default'].user = Ember['default'].userOnBoard;
      }
      if (Ember['default'].isNone(Ember['default'].user.get('isInvited'))) {
        return null;
      } else {
        return utils['default'].getAPIJSON('onboarding-status').then(function (res) {
          return res.registerWhiteList;
        });
      }
    },

    setupController: function setupController(controller, model) {
      controller.set('onboardingObj', model);
      var onboardingObj = model;
      var _t = this;
      if (Ember['default'].isNone(Ember['default'].user) && !Ember['default'].isNone(Ember['default'].userOnBoard)) {
        Ember['default'].user = Ember['default'].userOnBoard;
      }
      var user = Ember['default'].user;
      if (Ember['default'].isNone(user.get('isInvited'))) {
        var brand = null;
        if (user.get('isBrand')) {
          brand = user.get('company');
        } else {
          brand = user.get('company');
        }
        controller.set('brandName', brand);
        this.changeStep(1);
      } else {
        if (user.get('isMP')) {
          this.controller.set('vat', '');

          if (Ember['default'].isNone(user.get('statusOnBoarding')) || user.get('statusOnBoarding') <= 1) {
            this.changeStep(1);
          } else {
            this.changeStep(user.get('statusOnBoarding') + 1);
          }
        } else {
          if (Ember['default'].isNone(user.get('statusOnBoarding')) || user.get('statusOnBoarding') <= 2) {
            this.changeStep(1);
          } else {
            this.changeStep(user.get('statusOnBoarding') + 1);
          }
        }
      }
      if (onboardingObj) {
        if (onboardingObj.company_type === "Brand") {
          controller.set('isBrand', true);
          controller.set('company', _t.store.createRecord('brand', {
            'name': onboardingObj.company_name
          }));
          controller.set('sh', _t.store.createRecord('showroom'));
        } else if (onboardingObj.company_type === "Agency") {
          controller.set('isAgency', true);
          controller.set('company', _t.store.createRecord('agency', {
            'name': onboardingObj.company_name
          }));
          controller.set('sh', _t.store.createRecord('showroom'));
        } else {
          controller.set('isMp', true);
          controller.set('company', _t.store.createRecord('mp-company'));
          controller.checkMpType = function () {
            if (!controller.get('user.freelance') && !controller.get('user.influencer')) {
              controller.get('company').set('name', '');
              if (controller.get('user').get('jobTitle') == "Influencer") {
                controller.get('user').set('jobTitle', '');
              }
            }
          };
          controller.checkInfluencer = function () {
            if (controller.get('user.influencer')) {
              controller.get('company').set('name', 'Influencer');
              controller.get('user').set('freelance', false);
              controller.get('user').set('jobTitle', 'Influencer');
            }
          };
          controller.checkFreelance = function () {
            if (controller.get('user.freelance')) {
              controller.get('company').set('name', 'Freelance');
              if (controller.get('user').get('influencer')) {
                controller.get('user').set('jobTitle', '');
              }
              controller.get('user').set('influencer', false);
            }
          };
          utils['default'].addObserver(controller, ['user.influencer', 'user.freelance'], 'checkMpType');
          utils['default'].addObserver(controller, ['user.influencer'], 'checkInfluencer');
          utils['default'].addObserver(controller, ['user.freelance'], 'checkFreelance');
          controller.set('positionAddress', _t.store.createRecord('contact-address', {
            mpUser: controller.get('user.id'),
            position: user.get('jobTitle'),
            street3: user.get('mpcompany.name')
          }));
        }
      } else {
        //Mp invited!
        if (user.get('isMP')) {
          controller.set('isMp', true);
          controller.set('company', _t.store.createRecord('mp-company'));
          controller.checkMpType = function () {
            if (!controller.get('user.freelance') && !controller.get('user.influencer')) {
              controller.get('company').set('name', '');
              if (controller.get('user').get('jobTitle') == "Influencer") {
                controller.get('user').set('jobTitle', '');
              }
            }
          };
          controller.checkInfluencer = function () {
            if (controller.get('user.influencer')) {
              controller.get('company').set('name', 'Influencer');
              controller.get('user').set('freelance', false);
              controller.get('user').set('jobTitle', 'Influencer');
            }
          };
          controller.checkFreelance = function () {
            if (controller.get('user.freelance')) {
              controller.get('company').set('name', 'Freelance');
              if (controller.get('user').get('influencer')) {
                controller.get('user').set('jobTitle', '');
              }
              controller.get('user').set('influencer', false);
            }
          };
          utils['default'].addObserver(controller, ['user.influencer', 'user.freelance'], 'checkMpType');
          utils['default'].addObserver(controller, ['user.influencer'], 'checkInfluencer');
          utils['default'].addObserver(controller, ['user.freelance'], 'checkFreelance');
          controller.set('positionAddress', _t.store.createRecord('contact-address', {
            mpUser: controller.get('user.id'),
            position: user.get('jobTitle'),
            street3: user.get('mpcompany.name')
          }));
        }
      }

      if (user.get('isPR')) {
        //If have not done yet profile settings, remove temporary data
        if (user.get('statusOnBoarding') <= 3) {
          user.set('name', null);
          user.set('surname', null);
          user.set('jobTitle', null);
          user.set('avatar', null);
        } else {
          //Load remaining days
          this.setRemainingDays();
        }
      } else {
        //If have not done yet profile settings, remove temporary data
        if (user.get('statusOnBoarding') < 2) {
          user.set('name', null);
          user.set('surname', null);
          user.set('jobTitle', null);
          user.set('avatar', null);
        }
      }

      controller.set('user', user);

      var accountTypes = [{
        'id': '1',
        'name': 'Member'
      }, {
        'id': '2',
        'name': 'Manager'
      }];
      controller.set('accountTypes', accountTypes);
      var users = [Ember['default'].Object.create({ 'email': '', 'type': 'Member' }), Ember['default'].Object.create({ 'email': '', 'type': 'Member' })];
      controller.set('companyUsers', users);

      controller.set('canAddMoreUsers', Ember['default'].computed('companyUsers.length', function () {
        var usersLength = controller.get('companyUsers.length');
        if (Ember['default'].user.get('isDesigner') || Ember['default'].user.get('isStarter')) {
          return false;
        } else if (Ember['default'].user.get('isPro')) {
          return usersLength < 2;
        } else {
          return true;
        }
      }));

      this.send('setSkipSaveOnUploadImg', true);
    },

    changeStep: function changeStep(nextStep) {
      var controller = this.controller;
      for (var i = 1; i <= 6; i++) {
        if (i !== nextStep) {
          controller.set('step-' + i, false);
        }
      }
      controller.set('step-' + nextStep, true);
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-onboarding');
      this.controllerFor('application').set('hasSidebar', false);
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-onboarding');
      this.controllerFor('application').set('hasSidebar', false);
    },

    onStartOnBoarding: function onStartOnBoarding() {
      if (Ember['default'].isNone(Ember['default'].user) && !Ember['default'].isNone(Ember['default'].userOnBoard)) {
        Ember['default'].user = Ember['default'].userOnBoard;
      }

      if (Ember['default'].isNone(Ember['default'].user.get('isInvited'))) {
        this.changeStep(4);
      } else {
        this.changeStep(2);
      }
    },

    onCompleteMpProfileStep: function onCompleteMpProfileStep() {
      var buttonNext = Ember['default'].$('#next-button-steps');
      buttonNext.button('loading');
      var controller = this.controller;
      var user = controller.get('user');
      var company = controller.get('company');
      var vat = controller.get('vat');

      var _t = this;
      user.validate();
      company.validate();
      if (user.validate() && company.validate()) {
        controller.set('userAvatarError', false);
        utils['default'].postAPIJSON('users/' + user.get('id') + '/onBoarding/mpProfile', {
          "user": {
            "name": user.get('name'),
            "surname": user.get('surname'),
            "jobTitle": user.get('jobTitle'),
            "avatar": user.get('avatar'),
            "instagram": user.get('instagram'),
            "facebook": user.get('facebook'),
            "freelance": user.get('freelance'),
            "influencer": user.get('influencer'),
            "vat": vat
          },
          "company": {
            "name": company.get('name'),
            "website": company.get('website')
          }
        }).then(function (res) {
          _t.store.findRecord('user', user.get('id'), { reload: true }).then(function (user) {
            buttonNext.button('reset');
            Ember['default'].user = user;
            controller.set('user', user);
            var position = controller.get('positionAddress');
            position.set('position', user.get('jobTitle'));
            position.set('street3', company.get('name'));
            _t.changeStep(3);
          });
        }, function () {
          //console.log("fail");
          buttonNext.button('reset');
        });
      } else {
        if (user.get('isAvatarDefault')) {
          controller.set('userAvatarError', true);
        } else {
          controller.set('userAvatarError', false);
        }
        buttonNext.button('reset');
      }
    },

    onCompleteMpPositionStep: function onCompleteMpPositionStep() {
      var buttonNext = Ember['default'].$('#next-button-steps');
      buttonNext.button('loading');
      var _t = this;
      var controller = this.controller;
      var user = controller.get('user');
      var position = controller.get('positionAddress');
      if (position.validate()) {
        utils['default'].postAPIJSON('users/' + user.get('id') + '/onBoarding/createMPAddress', {
          "contact-address": {
            "street1": position.get('street1'),
            "street2": position.get('street2'),
            //"street3": position.get('street3'), //MP COMPANY
            //"position": position.get('position'), //MP COMPANY
            "country": position.get('country'),
            "city": position.get('city'),
            "zipCode": position.get('zipCode'),
            "telephone1": position.get('telephone1')
          }
        }).then(function (res) {
          _t.controllerFor('application').set('loadingBookalookPage', true);
          var promise = utils['default'].getUserProfile(true).then(Ember['default'].$.proxy(_t.onGetProfileSuccess, _t));
          _t.get('session').set('userPromise', promise);
        }, function () {
          //console.log("fail");
          buttonNext.button('reset');
        });
      } else {
        buttonNext.button('reset');
      }
    },

    onCompleteBrandStep: function onCompleteBrandStep() {
      var controller = this.controller;
      var company = controller.get('company');

      if (company.validate()) {
        controller.set('companyAvatarError', false);
        this.changeStep(3);
      } else {
        if (company.get('isAvatarDefault')) {
          controller.set('companyAvatarError', true);
        } else {
          controller.set('companyAvatarError', false);
        }
      }
    },

    onCompleteShowroomStep: function onCompleteShowroomStep() {
      var _this = this;

      var _t = this;
      var controller = this.controller;
      var user = Ember['default'].user;
      var showroom = controller.get('sh');
      var company = controller.get('company');
      if (showroom.validate()) {
        (function () {
          var buttonNext = Ember['default'].$('#next-button-steps');
          buttonNext.button('loading');

          var onboardingObj = _this.controller.get('onboardingObj');
          var brandOrAgency = onboardingObj.company_type.toLowerCase();

          utils['default'].postAPIJSON('users/' + user.get('id') + '/onBoarding/' + brandOrAgency + 'AndShowroom', {
            "company": {
              "website": company.get('website'),
              "instagram": company.get('instagram'),
              "facebook": company.get('facebook'),
              "avatar": company.get('avatar')
            },
            "showroom": {
              "address1": showroom.get('street'),
              "address2": showroom.get('street2'),
              "country": showroom.get('country'),
              "city": showroom.get('city'),
              "zipCode": showroom.get('zipCode'),
              "phone": showroom.get('telephone')
            }
          }).then(function (res) {
            _t.store.findRecord('user', user.get('id'), { reload: true }).then(function (user) {
              buttonNext.button('reset');
              //Remove temporary data from register
              user.set('name', null);
              user.set('surname', null);
              user.set('jobTitle', null);
              user.set('avatar', null);
              Ember['default'].user = user;
              controller.set('user', user);
              _t.changeStep(4);
            });
          }, function () {
            //console.log("fail");
            buttonNext.button('reset');
          });
        })();
      } else {
        //console.log("no valid");
      }
    },

    onCompleteProfileStep: function onCompleteProfileStep() {
      var _t = this;
      var controller = this.controller;
      var user = controller.get('user');
      var buttonNext = Ember['default'].$('#next-button-steps');
      Ember['default'].userId = Ember['default'].user.get('id');
      //buttonNext.button('loading');
      //buttonNext.button('reset');
      if (user.validate()) {
        controller.set('userAvatarError', false);
        buttonNext.button('loading');
        utils['default'].postAPIJSON('users/' + user.get('id') + '/onBoarding/profile', {
          "user": {
            "name": user.get('name'),
            "surname": user.get('surname'),
            "jobTitle": user.get('jobTitle'),
            "avatar": user.get('avatar')
          }
        }).then(function (res) {
          //_t.controllerFor('application').set('loadingBookalookPage', true);
          var promise = utils['default'].getUserProfile(true).then(Ember['default'].$.proxy(_t.onGetProfileSuccess, _t));
          _t.get('session').set('userPromise', promise);
        }, function () {
          //console.log("fail");
          buttonNext.button('reset');
        });
        /*if (controller.get('isAgency')){
         buttonNext.button('reset');
         user.set('onboardingStatus' , 4);
         user.save().then(() => {
         buttonNext.button('reset');
         this.changeStep(5);
         });
         } else {
         user.set('onboardingStatus' , 10);
         user.save().then(() => {
         buttonNext.button('reset');
         this.changeStep(10);
         sessionFunctions.getProfileAPI();
         });
         }*/
      } else {
          //console.log("no valid");
          if (user.get('isAvatarDefault')) {
            controller.set('userAvatarError', true);
          } else {
            controller.set('userAvatarError', false);
          }
        }
    },
    onAddMoreUsers: function onAddMoreUsers() {
      var _t = this;
      var controller = this.controller;
      var canAddMoreUsers = controller.get('canAddMoreUsers');
      if (canAddMoreUsers) {
        var users = controller.get('companyUsers');
        users.addObject(Ember['default'].Object.create({ 'email': '', 'type': "Member" }));
      }
    },
    onRemoveUser: function onRemoveUser(user) {
      var users = this.controller.get('companyUsers');
      users.removeObject(user);
    },

    onCheckUserEmail: function onCheckUserEmail(event) {
      var email = Ember['default'].$(event.target).val();
      var promise = utils['default'].postAPIJSON('users/pr_by_email', { 'email': email });
      promise.then(function (res) {
        if (Ember['default'].isNone(res.user)) {
          Ember['default'].$(event.target).parent().removeClass('error');
        } else {
          Ember['default'].$(event.target).parent().addClass('error');
        }
      }, function (fail) {
        Ember['default'].$(event.target).parent().addClass('error');
      });
    },

    onCompleteUsersStep: function onCompleteUsersStep(skip) {
      var _t = this;
      var buttonNext = Ember['default'].$('#next-button-steps');
      var user = Ember['default'].user;
      buttonNext.button('loading');
      if (skip) {
        user.set('statusOnBoarding', 10);
        _t.controllerFor('application').set('loadingBookalookPage', true);
        _t.controller.set('onboardingFinished', true);
        var rgstrPlan = (user.get('registerPlan') || '').toLowerCase();
        var prTransition = rgstrPlan.indexOf('starter') !== -1 ? 'collections.index' : 'inventory';
        _t.transitionTo(prTransition).then(function () {
          _t.controllerFor('application').set('personalLink', Ember['default'].userId);
          _t.controllerFor('application').set('loadingBookalookPage', false);
        });
        _t.send('openTopBar', 'Users has been invited.');
        buttonNext.button('reset');
        user.save().then();
      } else {
        var usersToInvite = this.controller.get('companyUsers');
        var promise = utils['default'].postAPIJSON('invitations/inviteUsers', usersToInvite);
        promise.then(function (res) {
          if (res.errors) {
            buttonNext.button('reset');
            _t.send('openTopBarWithTime', 'This email address already exists on Bookalook.', 3500);
          } else {
            buttonNext.button('reset');
            user.set('statusOnBoarding', 10);
            _t.controllerFor('application').set('loadingBookalookPage', true);
            _t.controller.set('onboardingFinished', true);
            var rgstrPlan = (user.get('registerPlan') || '').toLowerCase();
            var prTransition = rgstrPlan.indexOf('starter') !== -1 ? 'collections.index' : 'inventory';
            _t.transitionTo(prTransition).then(function () {
              _t.controllerFor('application').set('personalLink', Ember['default'].userId);
              _t.controllerFor('application').set('loadingBookalookPage', false);
            });
            _t.send('openTopBarWithTime', 'Users has been invited.', 3500);
            user.save();
          }
        }, function (fail) {
          buttonNext.button('reset');
          _t.send('openTopBarWithTime', 'This email address already exists on Bookalook.', 3500);
        });
      }
    },

    onGetProfileSuccess: function onGetProfileSuccess(res) {
      //IF YOU TOUCH THIS FUNCTION, EDIT THE SESSION/FUNCTIONS.JS TOO
      this.afterGetProfile(this, res);
      this.controllerFor('application').set('showroom', Ember['default'].showroom);
      this.controllerFor('application').set('user', Ember['default'].user);
      this.controllerFor('application').set('personalLink', res.user.id);
    },

    afterGetProfile: function afterGetProfile(route, res) {
      var _this2 = this;

      if (res.error) {
        console.log('User retrieve had an error.');
      } else {
        localStorage.setItem('userData', JSON.stringify(res));
        res = res.user;
        var user = utils['default'].pushPayload(route.store, 'user', res.user);

        if (user.get('isPR')) {
          var showroom = utils['default'].pushPayload(route.store, 'showroom', res.showroom);
          var agency, brand;
          if (showroom.get('isAgency')) {
            agency = utils['default'].pushPayload(route.store, 'agency', res.agency);
          } else {
            brand = utils['default'].pushPayload(route.store, 'brand', res.brand);
          }

          localStorage.setItem('showroomId', res.user.showroom);
          Ember['default'].showroomId = res.user.showroom;
          Ember['default'].showroom = showroom;
          Ember['default'].showroomLoaded = true;
          showroom.get("agency");
          //showroom.get("brands");
          Ember['default'].agency = showroom.get('isAgency') ? agency : brand;
        } else {
          var response = res.user.modalNotificationTypes.indexOf('requestSamplesModal') < 0 ? true : false;
          localStorage.setItem('notifyRequests', response);
          //console.log(localStorage.getItem('notifyRequests'));
        }
        localStorage.setItem('userId', res.user.id);
        Ember['default'].userId = res.user.id;

        var managerStatusRegister = res.statusRegister;
        localStorage.setItem("managerStatusRegister", managerStatusRegister);
        Ember['default'].managerStatusRegister = managerStatusRegister;

        var managerSubscriptionExpired = res.statusRegister == "6";
        localStorage.setItem("managerSubscriptionExpired", managerSubscriptionExpired);
        Ember['default'].managerSubscriptionExpired = managerSubscriptionExpired;

        var firstPaymentDone = res.firstPayment;
        localStorage.setItem("firstPaymentDone", firstPaymentDone);
        Ember['default'].firstPaymentDone = firstPaymentDone;

        if (Ember['default'].user) {
          //Ember.user.set(user);
        } else {
            Ember['default'].user = user;
          }

        //Variables for Intercom and Maqpie
        if (!Ember['default'].intercom) {
          Ember['default'].intercom = {};
        }
        Ember['default'].intercom.user_id = user.get('id');
        Ember['default'].intercom.email = user.get('email');
        Ember['default'].intercom.user_hash = res.intercomHash;

        if (!Ember['default'].maqpie) {
          Ember['default'].maqpie = {};
        }
        Ember['default'].maqpie.maqpieUserId = user.get('maqpieId');
        Ember['default'].maqpie.email = user.get('email');
        Ember['default'].maqpie.name = user.get('name');
        Ember['default'].maqpie.surname = user.get('surname');
        Ember['default'].maqpie.username = Ember['default'].maqpie.name + ' ' + Ember['default'].maqpie.surname;
        Ember['default'].maqpie.loaded = false;

        utils['default'].getCache(route);

        Appcues.identify(user.get('email'), { // Replace with unique identifier for current user
          name: user.get('fullName'), // Current user's name
          email: user.get('email'), // Current user's email
          created_at: user.get('date'), // Unix timestamp of user signup date

          // Additional user properties.
          // is_trial: false,
          // plan: "enterprise"
          type: user.get('type')
        });
        //route.transitionTo('index');
        var userPromise = this.get('session.userPromise');
        if (userPromise.then) {
          userPromise.then(function (resp) {
            _this2._loginSuccess();
          });
        }
      }
    },

    _loginSuccess: function _loginSuccess() {
      var _this3 = this;

      var route = this;
      //Emulate login-success.js
      //for show header and footer
      $('.beta_topbar ').show();
      $('.main_menu_wrapper').show();
      $('#test_footer').show();

      //If PR
      if (!Ember['default'].user.get('isMP')) {
        var promiseRemainingDays = null;
        var plan = Ember['default'].user.get('registerPlan');
        if (plan.includes("Designer")) {
          this.get('session').set('plan_key', 1);
        } else if (plan.includes("Pro")) {
          this.get('session').set('plan_key', 2);
        } else if (plan.includes("Starter")) {
          this.get('session').set('plan_key', 5);
        }

        if (Ember['default'].user.get('isSuperAdmin')) {
          if (plan.trim() == '') {
            this.get('session').set('admin_plan', '1');
          } else {
            this.get('session').set('admin_plan', '');
          }
        } else {
          promiseRemainingDays = this.setRemainingDays();
        }

        //Invitations: only for Pro Plans with no invitations! (First manager user)
        if (Ember['default'].isNone(plan) || Ember['default'].isBlank(plan) || Ember['default'].user.get('isDesigner') || Ember['default'].user.get('isStarter') || !Ember['default'].isNone(Ember['default'].user.get('invitedBy').get('content'))) {
          (function () {
            route.controllerFor('application').set('loadingBookalookPage', true);
            _this3.controller.set('onboardingFinished', true);
            var rgstrPlan = (Ember['default'].user.get('registerPlan') || '').toLowerCase();
            var prTransition = rgstrPlan.indexOf('starter') !== -1 ? 'collections.index' : 'inventory';
            if (promiseRemainingDays !== null) {
              promiseRemainingDays.then(function (getsub) {
                _this3.transitionTo(prTransition).then(function () {
                  _this3.controllerFor('application').set('personalLink', Ember['default'].userId);
                  route.controllerFor('application').set('loadingBookalookPage', false);
                });
              });
            } else {
              _this3.transitionTo(prTransition).then(function () {
                _this3.controllerFor('application').set('personalLink', Ember['default'].userId);
                route.controllerFor('application').set('loadingBookalookPage', false);
              });
            }
          })();
        } else {
          this.changeStep(5);
        }
      } else {
        //MP: No subscription
        route.controllerFor('application').set('loadingBookalookPage', true);
        Ember['default'].$('body').addClass('isMP');
        this.transitionTo('search.looks').then(function () {
          _this3.controllerFor('application').set('personalLink', Ember['default'].userId);
          route.controllerFor('application').set('loadingBookalookPage', false);
        });
      }
    },

    setRemainingDays: function setRemainingDays() {
      var _this4 = this;

      var sub_status;
      var sub_plan_id;
      var status_replace;
      var timestamp;
      var timestamp_seconds;
      var date;
      var current_date;
      var date_diffrence;
      var days;
      var count_days;

      return utils['default'].getAPIJSON('plans/getSubscriptionStatus').then(function (getsub) {
        sub_status = JSON.stringify(getsub.status);
        //If PR has any suscription plan on DB
        if (sub_status) {
          status_replace = sub_status.replace(/^"(.*)"$/, '$1');
          timestamp = JSON.stringify(getsub.trial_end);
          timestamp_seconds = timestamp * 1000;
          date = new Date();
          current_date = date.getTime();
          date_diffrence = new Date(timestamp_seconds - current_date);
          days = date_diffrence / 1000 / 60 / 60 / 24;
          count_days = Math.round(Math.abs(days));
          localStorage.setItem('trialEndDate', timestamp_seconds);
        } else {
          status_replace = '';
        }

        if (status_replace.trim() == 'trialing') {
          if (_this4.get('session.isAuthenticated')) {
            _this4.get('session').set('remaining_days', count_days);
            //Set number for plan
            sub_plan_id = JSON.stringify(getsub.planId);
            //sub_plan_id: StarterYearlyEUR | ProYearlyEUR | NewDesignerYearlyEUR ...
            if (sub_plan_id.includes("Designer")) {
              _this4.get('session').set('plan_key', 1);
            } else if (sub_plan_id.includes("Pro")) {
              _this4.get('session').set('plan_key', 2);
            } else if (sub_plan_id.includes("Starter")) {
              _this4.get('session').set('plan_key', 5);
            }
          }
        }
      });
    }
  });

});