define('bookalook/templates/jobs/modals/job-addresses', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 10
            },
            "end": {
              "line": 19,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","bluebox");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("YOU CAN ADD & EDIT THESE DETAILS LATER ON");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 12
            },
            "end": {
              "line": 24,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","section-title-btn-img pointer");
          dom.setAttribute(el1,"src","images/new_address.svg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element11);
          return morphs;
        },
        statements: [
          ["element","action",["openNewJobAddressModal",["get","jobToCreate",["loc",[null,[23,53],[23,64]]]],"#job-addresses-modal"],[],["loc",[null,[23,19],[23,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 34
                },
                "end": {
                  "line": 46,
                  "column": 34
                }
              },
              "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Set as main address");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [
              ["element","action",["setJobAddressAsMain",["get","address",["loc",[null,[43,72],[43,79]]]]],[],["loc",[null,[43,41],[43,81]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 34
                },
                "end": {
                  "line": 54,
                  "column": 34
                }
              },
              "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Delete address");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["deleteJobAddress",["get","jobToCreate",["loc",[null,[51,69],[51,80]]]],["get","address",["loc",[null,[51,81],[51,88]]]]],[],["loc",[null,[51,41],[51,90]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 86
                },
                "end": {
                  "line": 65,
                  "column": 130
                }
              },
              "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(", ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","address.street2",["loc",[null,[65,111],[65,130]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 137
                },
                "end": {
                  "line": 65,
                  "column": 181
                }
              },
              "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(", ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","address.street3",["loc",[null,[65,162],[65,181]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 18
              },
              "end": {
                "line": 88,
                "column": 18
              }
            },
            "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","container-callin");
            dom.setAttribute(el1,"class","address-container");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","address-content");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","address-card expandible");
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","flat-dropdown dd-job-address-container has-pophover-ui-refactor bottom hover-disabled");
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5,"type","button");
            dom.setAttribute(el5,"class","dots_horizontal_btn");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","icon");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","pophover-wrapper-ui-refactor");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","pophover");
            var el7 = dom.createTextNode("\n                                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("div");
            dom.setAttribute(el7,"class","flat-dropdown-list samples-list-dropdown");
            var el8 = dom.createTextNode("\n");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("                                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("div");
            var el9 = dom.createTextNode("\n                                    ");
            dom.appendChild(el8, el9);
            var el9 = dom.createElement("span");
            var el10 = dom.createTextNode("Edit address");
            dom.appendChild(el9, el10);
            dom.appendChild(el8, el9);
            var el9 = dom.createTextNode("\n                                  ");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("                                ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","ac-title");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","ac-content");
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","ac-row");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-left");
            var el7 = dom.createTextNode("Address");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-right");
            var el7 = dom.createTextNode("\n                                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("input");
            dom.setAttribute(el7,"type","hidden");
            dom.setAttribute(el7,"name","");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("span");
            dom.setAttribute(el7,"class","ac-row-right-address");
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","ac-row");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-left");
            var el7 = dom.createTextNode("Country");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-right");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","ac-row");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-left");
            var el7 = dom.createTextNode("City");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-right");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","ac-row");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-left");
            var el7 = dom.createTextNode("Zip Code");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-right");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","ac-row");
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-left");
            var el7 = dom.createTextNode("Telephone(s)");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","ac-row-right");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1, 1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element3, [3, 1, 1]);
            var element5 = dom.childAt(element4, [3]);
            var element6 = dom.childAt(element2, [3]);
            var element7 = dom.childAt(element2, [5]);
            var element8 = dom.childAt(element7, [1, 3]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(15);
            morphs[0] = dom.createAttrMorph(element3, 'id');
            morphs[1] = dom.createElementMorph(element3);
            morphs[2] = dom.createMorphAt(element4,1,1);
            morphs[3] = dom.createElementMorph(element5);
            morphs[4] = dom.createMorphAt(element4,5,5);
            morphs[5] = dom.createMorphAt(element6,0,0);
            morphs[6] = dom.createMorphAt(element6,1,1);
            morphs[7] = dom.createAttrMorph(element9, 'value');
            morphs[8] = dom.createMorphAt(element10,0,0);
            morphs[9] = dom.createMorphAt(element10,1,1);
            morphs[10] = dom.createMorphAt(element10,2,2);
            morphs[11] = dom.createMorphAt(dom.childAt(element7, [3, 3]),0,0);
            morphs[12] = dom.createMorphAt(dom.childAt(element7, [5, 3]),0,0);
            morphs[13] = dom.createMorphAt(dom.childAt(element7, [7, 3]),0,0);
            morphs[14] = dom.createMorphAt(dom.childAt(element7, [9, 3]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["dd-job-address-",["get","jobToCreate.id",["loc",[null,[34,52],[34,66]]]]]]],
            ["element","action",["toggleActiveClass",["subexpr","concat",["#dd-job-address-",["get","jobToCreate.id",["loc",[null,[35,84],[35,98]]]]],[],["loc",[null,[35,57],[35,99]]]]],[],["loc",[null,[35,28],[35,101]]]],
            ["block","unless",[["get","address.main",["loc",[null,[42,44],[42,56]]]]],[],0,null,["loc",[null,[42,34],[46,45]]]],
            ["element","action",["openEditJobAddressModal",["get","jobToCreate",["loc",[null,[47,74],[47,85]]]],["get","address",["loc",[null,[47,86],[47,93]]]],"#job-addresses-modal"],[],["loc",[null,[47,39],[47,118]]]],
            ["block","unless",[["get","address.main",["loc",[null,[50,44],[50,56]]]]],[],1,null,["loc",[null,[50,34],[54,45]]]],
            ["content","address.city",["loc",[null,[59,49],[59,65]]]],
            ["inline","if",[["get","address.main",["loc",[null,[59,70],[59,82]]]]," (main)"],[],["loc",[null,[59,65],[59,94]]]],
            ["attribute","value",["concat",[["get","mainAddressRequester",["loc",[null,[64,70],[64,90]]]]]]],
            ["content","address.street1",["loc",[null,[65,67],[65,86]]]],
            ["block","if",[["get","address.street2",["loc",[null,[65,92],[65,107]]]]],[],2,null,["loc",[null,[65,86],[65,137]]]],
            ["block","if",[["get","address.street3",["loc",[null,[65,143],[65,158]]]]],[],3,null,["loc",[null,[65,137],[65,188]]]],
            ["content","address.country",["loc",[null,[70,57],[70,76]]]],
            ["content","address.city",["loc",[null,[74,57],[74,73]]]],
            ["content","address.zipCode",["loc",[null,[78,57],[78,76]]]],
            ["content","address.telephone",["loc",[null,[82,57],[82,78]]]]
          ],
          locals: ["address"],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 16
            },
            "end": {
              "line": 89,
              "column": 16
            }
          },
          "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","paginatedAddresses",["loc",[null,[30,26],[30,44]]]]],[],0,null,["loc",[null,[30,18],[88,27]]]]
        ],
        locals: ["paginatedAddresses"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 101,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/jobs/modals/job-addresses.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New job ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade bal-modal-mini with-columns");
        dom.setAttribute(el1,"id","job-addresses-modal");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","backimage");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","backcontainer");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","backicon spaceship");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-header");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","modal-title");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Job");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("strong");
        var el8 = dom.createTextNode(" Addresses");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("hr");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-body");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","address-actions");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","input_label");
        var el8 = dom.createTextNode("CALL-IN ADDRESS(ES): SET A MAIN CALL-IN ADDRESS AND ADD UP TO 3");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","addresses-container view-contact-addresses");
        dom.setAttribute(el7,"style","width: 100%; margin-top: 8px");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-footer");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"id","job-addresses-btn");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn_bal_primary pull-right");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [2, 1, 1, 3]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element12, [5, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element13,1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element13, [3]),3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element13, [5, 2]),1,1);
        morphs[3] = dom.createElementMorph(element14);
        morphs[4] = dom.createMorphAt(element14,0,0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","fromJobListPage",["loc",[null,[15,16],[15,31]]]]],[],0,null,["loc",[null,[15,10],[19,17]]]],
        ["block","unless",[["subexpr","if-higher",[["get","jobToCreate.addresses.length",["loc",[null,[22,33],[22,61]]]],2],[],["loc",[null,[22,22],[22,64]]]]],[],1,null,["loc",[null,[22,12],[24,23]]]],
        ["block","slider-pagination",[],["itemsByPage",1,"items",["subexpr","@mut",[["get","jobToCreate.addresses",["loc",[null,[29,57],[29,78]]]]],[],[]]],2,null,["loc",[null,[29,16],[89,38]]]],
        ["element","action",["saveJobAddressesModal"],[],["loc",[null,[95,55],[95,91]]]],
        ["inline","if",[["get","fromJobListPage",["loc",[null,[95,132],[95,147]]]],"Finish","Save"],[],["loc",[null,[95,127],[95,165]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});