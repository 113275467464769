define('bookalook/tests/serializers/job-status.jshint', function () {

  'use strict';

  QUnit.module('JSHint - serializers/job-status.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'serializers/job-status.js should pass jshint.');
  });

});