define('bookalook/helpers/free-block-email', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (Ember['default'].user) {
      return Ember['default'].user.get('isFree') && params[0];
    } else {
      return false;
    }
  });

});