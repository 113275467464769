define('bookalook/templates/components/input-file', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/components/input-file.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","input-file-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2,"type","file");
        dom.setAttribute(el2,"class","input-file");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn_bal_primary_light");
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Upload a file");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  document.getElementById('");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("').addEventListener( 'change', function( e )\n  {\n    let input = e.target || e.srcElement;\n    let label	 = input.nextElementSibling;\n    let labelVal = label.innerHTML;\n    let fileName = '';\n    if( this.files && this.files.length > 1 )\n      fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );\n    else\n      fileName = e.target.value.split( '\\\\' ).pop();\n\n    if( fileName ){\n      label.querySelector( 'span' ).innerHTML = fileName;\n    }\n    else {\n      //label.innerHTML = labelVal;\n      label.querySelector( 'span' ).innerHTML = \"Upload a file\";\n    }\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element1, 'id');
        morphs[1] = dom.createAttrMorph(element2, 'for');
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","id",["concat",[["get","inputFileId",["loc",[null,[2,46],[2,57]]]]]]],
        ["attribute","for",["concat",[["get","inputFileId",["loc",[null,[3,16],[3,27]]]]]]],
        ["content","inputFileId",["loc",[null,[8,27],[8,42]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});