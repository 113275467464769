define('bookalook/routes/jobs/index', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      saveNewJob: function saveNewJob() {
        this.onSaveNewJob();
      },
      sortJobs: function sortJobs(field) {
        this.onSortJobs(field);
      },
      setJobsToShow: function setJobsToShow(statusFilter) {
        this.onSetJobsToShow(statusFilter);
      }

    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        allJobs: this.store.findAll('job')
      });
    },

    setupController: function setupController(controller, model) {
      /*//Hide TOP BAR MENU modals
      Ember.$('#bags-menu').removeClass('showToggle');
      Ember.$('#clothing-menu').removeClass('showToggle');
      Ember.$('#accesories-menu').removeClass('showToggle');
      Ember.$('#shoes-menu').removeClass('showToggle');
      Ember.$('#lingerie-menu').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-bags').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-clothing').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-shoes').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-accesories').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-lingerie').removeClass('showToggle');*/

      this._super(controller, model);
      controller.setProperties(model);
      controller.set('userLogged', Ember['default'].user);

      //this._setupJobFilterStatus();
      //this.controller.set('fromJobListPage', true);

      controller.set('jobSearchInput', '');
      controller.set('jobStatusFilterSelected', "All");
      controller.set('jobsSorting', 'id-des');

      //controller.jobs = Ember.computed.filterBy('allJobs', 'isNew', false);
      controller.jobs = Ember['default'].computed('allJobs.@each.isNew', 'allJobs.@each.deleted', function () {
        var allJobs = controller.get('allJobs');
        return allJobs.filter(function (j) {
          return !j.get('isNew') && !j.get('deleted');
        });
      });

      controller.filterAndSortJobs = function () {
        var jobs = controller.get('jobs');
        var jobStatusFilter = (controller.get('jobStatusFilterSelected') || '').toLowerCase();
        var searchInput = (controller.get('jobSearchInput') || '').toLowerCase();
        controller.set('jobsFiltered', jobs);

        var jobsFiltered = jobs.filter(function (j) {
          var jobName = (j.get('name') || '').toLowerCase();
          if (jobName.indexOf(searchInput) >= 0) {
            if (Ember['default'].isNone(jobStatusFilter) || jobStatusFilter == "all") {
              return true;
            } else {
              var _status = (j.get('status') || '').toLowerCase();
              return _status == jobStatusFilter;
            }
          } else {
            return false;
          }
        });

        // Setting a criteria
        var criteria = controller.get('jobsSorting') || 'id-des';

        // Getting criteria and direction respectively
        var criteriaKey = criteria.slice(0, criteria.length - 4);
        var order = criteria.slice(criteria.length - 4);

        // Applying the sorting
        var jobsSorted = jobsFiltered.sort(getSortFunction(order, criteriaKey));

        // Saving the criteria for next sorting comparison
        controller.set('jobsSorting', criteria);

        controller.set('jobsFiltered', jobsSorted);
      };
      controller.filterAndSortJobs();

      utils['default'].addObserver(controller, ['allJobs', 'jobSearchInput', 'jobStatusFilterSelected', 'jobsSorting'], 'filterAndSortJobs');
    },

    onSortJobs: function onSortJobs(field) {
      var key = 'jobsSorting';
      var selector = '#jobs_table';

      $(selector).find('th').removeClass('asc des');
      var previousField = this.controller.get(key);
      if (field == previousField.slice(0, previousField.length - 4)) {
        if (previousField.endsWith('-des')) {
          $(selector).find('.' + field + '_column').addClass('asc');
          field = field + '-asc';
        } else {
          $(selector).find('.' + field + '_column').addClass('des');
          field = field + '-des';
        }
      } else {
        $(selector).find('.' + field + '_column').addClass('asc');
        field = field + '-asc';
      }

      this.controller.set(key, field);
    },

    onSaveNewJob: function onSaveNewJob() {
      var _this = this;

      var newJob = this.controller.get('newJob');

      this.send('openMessageModal', 'Creating job ' + newJob.get('name') + '. Please Wait.');
      newJob.save().then(function (newJobSaved) {
        _this.send('closeMessageModal');
        _this.transitionTo('jobs.form-edit', newJobSaved.get('id'));
      });
    },

    /*_setupJobFilterStatus() {
      this.controller.set('jobStatusFilter', []);
      this.controller.set('statusSelected', '-1');
      this.controller.set('activeFilterActive', true);
      this.controller.set('openFilterActive', true);
      this.controller.set('closedFilterActive', true);
       this.controller.get('jobStatusFilter').addObjects([
        {'id' : "-1", 'status' : 'All Statuses'},
        {'id' : "0", 'status' : 'Active'},
        {'id' : "1", 'status' : 'Open'},
        {'id' : "2", 'status' : 'Closed'}
      ]);
      this._setupJobFilterObserver();
    },
     _setupJobFilterObserver() {
      let _this = this;
      let controller = _this.controller;
      controller.statusSelectedObserver = function () {
        let statusSelected = controller.get('statusSelected');
        controller.set('activeFilterActive', false);
        controller.set('openFilterActive', false);
        controller.set('closedFilterActive', false);
         switch (statusSelected) {
          case "-1":
            controller.set('activeFilterActive', true);
            controller.set('openFilterActive', true);
            controller.set('closedFilterActive', true);
            break;
           case "0":
            controller.set('activeFilterActive', true);
            break;
           case "1":
            controller.set('openFilterActive', true);
            break;
           case "2":
            controller.set('closedFilterActive', true);
            break;
        }
      };
      utils.addObserver(controller, ['statusSelected'], 'statusSelectedObserver');
    },
      });
    },*/

    activate: function activate() {
      Ember['default'].$('body').addClass('page-jobs');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-jobs');
    },

    onSetJobsToShow: function onSetJobsToShow(statusFilter) {
      this.controller.set('jobStatusFilterSelected', statusFilter);
    }
  });

  function getSortFunction(order, criteria) {
    return function (a, b) {
      var elemA = undefined,
          elemB = undefined;
      if (criteria.indexOf('_') != -1) {
        // For two levels properties
        var criterias = criteria.split('_');
        if (criterias.length === 2) {
          elemA = a.get(criterias[0] + '.' + criterias[1]) || '';
          elemB = b.get(criterias[0] + '.' + criterias[1]) || '';
        }
      } else {
        // For one level properties
        elemA = a.get(criteria) || '';
        elemB = b.get(criteria) || '';
      }
      if (typeof elemA === 'string') {
        if (parseInt(elemA) && parseInt(elemB)) {
          elemA = parseInt(elemA) || 0;
          elemB = parseInt(elemB) || 0;
        }
      }
      if (typeof elemA === 'string') {
        if (order === '-asc') {
          return elemA.localeCompare(elemB);
        } else {
          return -1 * elemA.localeCompare(elemB);
        }
      } else if (typeof elemA === 'number') {
        elemA = elemA != '' ? elemA : 0;
        elemB = elemB != '' ? elemB : 0;
        if (order === '-asc') {
          return elemA - elemB;
        } else {
          return elemB - elemA;
        }
      } else if (typeof elemA === 'object' && criteria.search(/date/i)) {
        //search in-case insensitive the word Date in Criteria
        if (order === '-asc') {
          return elemA - elemB;
        } else {
          return elemB - elemA;
        }
      }
    };
  }

});