define('bookalook/routes/session/functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.onAuthenticate = onAuthenticate;
  exports.onAuthenticationSuccess = onAuthenticationSuccess;
  exports.onAuthenticationError = onAuthenticationError;
  exports.onGetProfileSuccess = onGetProfileSuccess;
  exports.afterGetProfile = afterGetProfile;

  var LOGIN_BUTTON_ID = "#login-button";
  var LOGIN_MODAL_ID = "#login-modal";

  function onAuthenticate(route) {
    var credentials = route.controller.getProperties('identification', 'password');
    var authenticator = route.get('authenticator');
    var $ = Ember['default'].$;

    Ember['default'].$(LOGIN_BUTTON_ID).button('loading');

    route.get('session').authenticate(authenticator, credentials).then($.proxy(onAuthenticationSuccess, route), $.proxy(onAuthenticationError, route));
  }

  function onAuthenticationSuccess() {
    Ember['default'].$(LOGIN_MODAL_ID).modal('hide');
    utils['default'].getCache(this);
    if (!Ember['default'].isNone(this.controllerFor('application'))) {
      this.controllerFor('application').set('loadingBookalookPage', true);
    }
    var promise = utils['default'].getUserProfile(true).then(Ember['default'].$.proxy(onGetProfileSuccess, this));
    this.get('session').set('userPromise', promise);
  }

  function onAuthenticationError() {
    Ember['default'].$(LOGIN_BUTTON_ID).button('reset');
    this.controller.set('loginError', 'Wrong email/password.');
  }

  function onGetProfileSuccess(res) {
    //IF YOU TOUCH THIS FUNCTION, EDIT THE ONBOARDING/INDEX.JS TOO
    afterGetProfile(this, res);

    Ember['default'].$(LOGIN_BUTTON_ID).button('reset');

    this.controllerFor('application').set('showroom', Ember['default'].showroom);
    this.controllerFor('application').set('user', Ember['default'].user);
    this.controllerFor('application').set('personalLink', res.user.id);
  }

  function afterGetProfile(route, res) {
    if (res.error) {
      console.log('User retrieve had an error.');
    } else {
      localStorage.setItem('userData', JSON.stringify(res));
      res = res.user;
      var user = utils['default'].pushPayload(route.store, 'user', res.user);

      if (user.get('isPR') && user.get('isOnboarding') && user.get('statusOnBoarding') < 4 || user.get('isMP') && user.get('isOnboarding') && user.get('statusOnBoarding') < 2) {
        Ember['default'].user = user;
        Ember['default'].userOnBoard = user;
        localStorage.setItem('userId', res.user.id);
      } else {
        if (user.get('isPR')) {
          var showroom = utils['default'].pushPayload(route.store, 'showroom', res.showroom);
          var agency, brand;
          if (showroom.get('isAgency')) {
            agency = utils['default'].pushPayload(route.store, 'agency', res.agency);
          } else {
            brand = utils['default'].pushPayload(route.store, 'brand', res.brand);
          }

          localStorage.setItem('showroomId', res.user.showroom);
          Ember['default'].showroomId = res.user.showroom;
          Ember['default'].showroom = showroom;
          Ember['default'].showroomLoaded = true;
          showroom.get("agency");
          Ember['default'].agency = showroom.get('isAgency') ? agency : brand;
        } else {
          var response = res.user.modalNotificationTypes.indexOf('requestSamplesModal') < 0 ? true : false;
          localStorage.setItem('notifyRequests', response);
        }

        localStorage.setItem('userId', res.user.id);
        Ember['default'].userId = res.user.id;

        var managerStatusRegister = res.statusRegister;
        localStorage.setItem("managerStatusRegister", managerStatusRegister);
        Ember['default'].managerStatusRegister = managerStatusRegister;

        var managerSubscriptionExpired = res.statusRegister == "6";
        localStorage.setItem("managerSubscriptionExpired", managerSubscriptionExpired);
        Ember['default'].managerSubscriptionExpired = managerSubscriptionExpired;

        var firstPaymentDone = res.firstPayment;
        localStorage.setItem("firstPaymentDone", firstPaymentDone);
        Ember['default'].firstPaymentDone = firstPaymentDone;

        if (!Ember['default'].isNone(user)) {
          Ember['default'].user = user;
          route.set('userLogged', user);
        }

        //Variables for Intercom and Maqpie
        if (!Ember['default'].intercom) {
          Ember['default'].intercom = {};
        }
        Ember['default'].intercom.user_id = user.get('id');
        Ember['default'].intercom.email = user.get('email');
        Ember['default'].intercom.user_hash = res.intercomHash;

        if (!Ember['default'].maqpie) {
          Ember['default'].maqpie = {};
        }
        Ember['default'].maqpie.maqpieUserId = user.get('maqpieId');
        Ember['default'].maqpie.email = user.get('email');
        Ember['default'].maqpie.name = user.get('name');
        Ember['default'].maqpie.surname = user.get('surname');
        Ember['default'].maqpie.username = Ember['default'].maqpie.name + ' ' + Ember['default'].maqpie.surname;
        Ember['default'].maqpie.loaded = false;

        utils['default'].getCache(route);
      }

      Appcues.identify(user.get('email'), { // Replace with unique identifier for current user
        name: user.get('fullName'), // Current user's name
        email: user.get('email'), // Current user's email
        created_at: user.get('date'), // Unix timestamp of user signup date
        type: user.get('type')
      });
    }
  }

});