define('bookalook/tests/helpers/find-first-avatar.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/find-first-avatar.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'helpers/find-first-avatar.js should pass jshint.\nhelpers/find-first-avatar.js: line 6, col 20, [\'position\'] is better written in dot notation.\nhelpers/find-first-avatar.js: line 11, col 34, [\'thumbnail\'] is better written in dot notation.\n\n2 errors');
  });

});