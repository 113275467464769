define('bookalook/filters_inventory', ['exports', 'ember', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, utils, config) {

  'use strict';

  exports.resetFilters = resetFilters;
  exports.removeObserversFromSetFilterComputeds = removeObserversFromSetFilterComputeds;
  exports.undoResetFilters = undoResetFilters;
  exports.removeScrollPaginationListener = removeScrollPaginationListener;
  exports.setScrollPaginationListener = setScrollPaginationListener;
  exports.clearSamples = clearSamples;
  exports.clearFilters = clearFilters;
  exports.filterByWord = filterByWord;
  exports.initializeFilters = initializeFilters;
  exports.setFilterComputeds = setFilterComputeds;

  var containerGlobal = null;
  var containerGlobalDocument = null;
  var controllerGlobal = null;

  function resetFilters(route, container) {
    var controller = route.controller;

    //For inventory: save current filter values
    if (route.routeName == 'inventory.index') {
      controller.set('undoBrandFilteri', controller.get('brandFilteri'));
      controller.set('undoCollectionFilteri', controller.get('collectionFilteri'));
      controller.set('undoShowroomFilteri', controller.get('showroomFilteri'));
      controller.set('undoSearchInputi', controller.get('searchInputi'));
      controller.set('undoColourFilteri', controller.get('colourFilteri'));
      if (Ember['default'].isNone(controller.get('albumFilteri'))) {
        controller.set('undoAlbumFilteri', null);
      } else {
        controller.set('undoAlbumFilteri', controller.get('albumFilteri'));
      }
      controller.set('undoItemFilteri', controller.get('itemFilteri'));
      controller.set('undoLookFilteri', controller.get('lookFilteri'));
      controller.set('undoSizeFilteri', controller.get('sizeFilteri'));
      controller.set('undoStatusFilteri', controller.get('statusFilteri'));
    }

    if (controller.get('allBrandsi.length') !== 2) {
      controller.set('brandFilteri', null);
    }
    controller.set('collectionPage', 1);
    controller.set('collectionFilteri', null);
    controller.set('showroomFilteri', null);
    controller.set('searchInputi', null);
    controller.set('colourFilteri', null);

    //Don't reset status filter for inventory page
    if (route.routeName != 'inventory.index') {
      controller.set('statusFilteri', '');
    }

    controller.set('albumFilteri', null);
    controller.set('lookFilteri', null);
    controller.set('itemFilteri', null);
    controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
    controller.set('sizeFilteri', null);

    container = container || window;
    Ember['default'].activeFilter = container;
  }

  function removeObserversFromSetFilterComputeds(route) {
    var controller = route.controller;

    //searchAndFiltering
    controller.removeObserver('collectionFilteri', controller, 'searchAndFiltering');
    controller.removeObserver('brandFilteri', controller, 'searchAndFiltering');
    controller.removeObserver('albumFilteri', controller, 'searchAndFiltering');
    controller.removeObserver('colourFilteri', controller, 'searchAndFiltering');
    controller.removeObserver('statusFilteri', controller, 'searchAndFiltering');
    controller.removeObserver('sizeFilteri', controller, 'searchAndFiltering');
    controller.removeObserver('itemFilteri', controller, 'searchAndFiltering');

    controller.removeObserver('collectionFilteri', controller, 'updateAlbums');
    controller.removeObserver('brandFilteri', controller, 'updateCollections');
    controller.removeObserver('collectionPage', controller, 'loadMoreCollections');
  }

  //Restore filter values before the reset

  function undoResetFilters(route, container) {
    var loadingScreen = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var controller = route.controller;

    //For inventory: restore old filter values
    if (route.routeName == 'inventory.index') {
      if (loadingScreen) {
        route.send('openMessageModal', '');
      }
      if (!Ember['default'].isNone(controller.get('undoBrandFilteri'))) {
        controller.set('brandFilteri', controller.get('undoBrandFilteri'));
      }
      if (Ember['default'].isNone(controller.get('undoCollectionFilteri'))) {
        controller.set('collectionFilteri', null);
      } else {
        controller.set('collectionFilteri', controller.get('undoCollectionFilteri'));
      }

      controller.set('searchInputi', controller.get('undoSearchInputi'));
      controller.set('colourFilteri', controller.get('undoColourFilteri'));

      controller.set('itemFilteri', controller.get('undoItemFilteri'));
      controller.set('lookFilteri', controller.get('undoLookFilteri'));

      controller.set('sizeFilteri', controller.get('undoSizeFilteri'));

      controller.set('statusFilteri', controller.get('undoStatusFilteri'));

      if (loadingScreen) {
        route.send('closeMessageModal');
      }
    }

    container = container || window;
    Ember['default'].activeFilter = container;

    if (route.routeName == 'inventory.index') {
      Ember['default'].run.later(function () {
        if (!Ember['default'].isNone(controller.get('undoAlbumFilteri'))) {
          controller.set('albumFilteri', controller.get('undoAlbumFilteri'));
        }
        if (loadingScreen) {
          route.send('closeMessageModal');
        }
      }, 1000);
    }
  }

  function _scrollCalculationPage() {
    if ($(containerGlobal).scrollTop() + $(containerGlobal).height() > $(containerGlobalDocument).height() - 300) {

      if (!controllerGlobal.get('allSamplesUnvisiblei') && controllerGlobal.get('loadMorei') && !controllerGlobal.get('loadingNextAlbumsi') && !controllerGlobal.get('isLoading')
      /*&& !controllerGlobal.get('loadMoreStatuses')*/) {
          controllerGlobal.incrementProperty('collectionPage');
        }
    }
  }

  function removeScrollPaginationListener() {

    $(window).unbind('scroll');
  }

  function setScrollPaginationListener() {

    $(window).bind('scroll', _scrollCalculationPage);
  }

  function clearSamples(route) {
    var controller = route.controller;
    controller.set('itemFilteri', '');
  }

  ;

  function clearFilters(route) {
    var controller = route.controller;
    controller.set('resetFilter', true);
    initializeFilters(controller);
    controller.set('resetFilter', false);
    controller.set('searchAnyWay', true);
    controller.searchAndFiltering();
  }

  function filterByWord(route) {
    var controller = route.controller;
    controller.searchAndFiltering();
  }

  ;

  function initializeFilters(controller) {
    if (controller.get('allBrandsi.length') !== 2) {
      controller.set('brandFilteri', null);
    }
    controller.set('collectionPage', 1);
    controller.set('loadingNextAlbumsi', false);

    controller.set('collectionFilteri', null);
    controller.set('showroomFilteri', null);
    controller.set('searchInputi', null);
    controller.set('colourFilteri', null);
    controller.set('statusFilteri', null);
    controller.set('albumFilteri', null);
    controller.set('lookFilteri', null);
    controller.set('itemFilteri', null);
    controller.set('sizeFilteri', null);
    controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
    controller.set('resetFilter', false);
    controller.set('searchAnyWay', false);
  }

  function setFilterComputeds(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;
    controllerGlobal = controller;
    containerGlobal = container;
    containerGlobalDocument = containerDocument;

    var $ = Ember['default'].$;
    var lazyContainer = container;
    containerDocument = containerDocument || document;
    container = container || window;

    _setVisibleSamplesi(onlySamplesSameShowroom, controller);

    initializeFilters(controller);

    controller.loadMoreCollections = function () {
      var pagination = controller.get('pagination');
      var collectionPage = controller.get('collectionPage');
      var filters = controller.get('filters');
      var pageLoad = collectionPage;
      var filtering = controller.get('loadFiltering');
      if (filtering) {
        pageLoad = 1;
        controller.set('loadFiltering', false);
        controller.set('collectionPage', 1); //me llama a esto nuevamente pero no lo corre por las condiciones del if
      }

      if (collectionPage > pagination.actualPage && collectionPage <= pagination.lastPage || filtering) {
        controller.set('loadMorei', false); // evitar rellamar a aumentar collectionPage
        controllerGlobal.set('loadingNextAlbumsi', true);
        var page = parseInt(pageLoad);
        utils['default'].getCollectionsPaginated(controller, page, filters).then(function (response) {

          var results = controller.get('collectionsPaginated');
          if (results) {
            results.get('content').pushObjects(response.content);
          } else {
            results = response;
          }
          // actualizo la data de collections y debo re-renderizar
          controller.set('collectionsPaginated', results);
          controller.set('pagination', response.meta);

          controllerGlobal.set('loadingNextAlbumsi', false);
          controller.searchFilterFunctioni(); // chequea si hay algun sample para mostrar

          var date = controller.get('dateFilter');
          var data = { date: utils['default'].dateToDayFormat(date), samples: utils['default'].getSamples('paginated').mapBy('id') };
          controller.getStatus(data);
        }, function () {
          controller.set('loadingNextAlbumsi', false);
        });
      } else {
        controller.set('loadMorei', false);
      }
    };
    // cuando cambia collectionPage agrego collections
    utils['default'].addObserver(controller, ['collectionPage'], 'loadMoreCollections');

    controller.getStatus = function (data) {

      var promise = Ember['default'].$.ajax({
        url: config['default'].APIURL + 'statuses/inventory',
        data: JSON.stringify(data),
        type: 'POST',
        contentType: 'application/json',
        success: function success(res) {
          var statuses = res.data;
          statuses.forEach(function (status) {
            var sample = controller.store.peekRecord('sample', status.sample);
            if (sample) {
              sample.set('inventoryStatus', status.status);
              sample.set('bookingCompany', status.bookingCompany);
              sample.set('bookingInventoryId', status.booking);
              sample.set('warning', status.warning);
              if (status.status === 'in transfer') {
                sample.set('recipientShowroomId', status.recipientShowroomId);
                sample.set('transferId', status.transferId);
              }
            }
          });

          utils['default'].lazyLoad(2000);
        }
      });

      controller.set('getDataPromise', promise);
    };

    controller.searchAndFiltering = function () {
      var filters = {};

      //sino me lo llama en el seteo
      if (controller.get('brandFilteri') == null && controller.get('collectionFilteri') == null && controller.get('albumFilteri') == null && controller.get('searchInputi') == null && controller.get('sizeFilteri') == null && controller.get('itemFilteri') == null && controller.get('colourFilteri') == null && controller.get('statusFilteri') == null && !controller.get('searchAnyWay') //obligo a buscar si o si
       || controller.get('resetFilter') //mientras voy reseteando el filtro que no vaya haciendo busquedas
      ) {
          return;
        }

      if (controller.get('brandFilteri')) {
        filters.brand = controller.get('brandFilteri');
      }

      if (controller.get('collectionFilteri')) {
        filters.collection = controller.get('collectionFilteri');
      }

      if (controller.get('albumFilteri')) {
        filters.album = controller.get('albumFilteri');
      }

      if (controller.get('searchInputi')) {
        filters.search = controller.get('searchInputi').toString().toLowerCase();
      }
      if (controller.get('colourFilteri')) {
        filters.color = controller.get('colourFilteri').toLowerCase();
      }
      if (controller.get('sizeFilteri')) {
        filters.size = controller.get('sizeFilteri').toLowerCase();
      }
      if (controller.get('itemFilteri')) {
        filters.name = controller.get('itemFilteri').id;
      }
      if (controller.get('statusFilteri') && controller.get('statusFilteri').toLowerCase() !== 'all statuses') {
        var date = utils['default'].dateToDayFormat(controller.get('dateFilter'));
        filters.date = date;
        filters.status = controller.get('statusFilteri');
      }

      controller.set('collectionsPaginated', null); //limpiar collections
      controller.set('filters', filters);
      controller.set('loadFiltering', true);
      controller.set('resetFilter', false);
      controller.set('searchAnyWay', false);
      controller.loadMoreCollections();
    };
    utils['default'].addObserver(controller, ['collectionFilteri', 'albumFilteri', 'colourFilteri', 'statusFilteri', 'sizeFilteri', 'itemFilteri'], 'searchAndFiltering');

    controller.searchByBrand = function () {
      // si son dos brands significa que hay una sola no uso el brandFilteri
      // para buscar
      if (controller.get('allBrandsi').length > 2) {
        controller.searchAndFiltering();
      }
    };

    utils['default'].addObserver(controller, ['brandFilteri'], 'searchByBrand');

    // CARGANDO LISTADO DE BRANDS
    controller.allBrandsi = Ember['default'].computed('brands', function () {
      var brands = Ember['default'].A([{ id: 0, name: 'All brands' }]);
      if (controller.get('brands')) {
        brands = brands.concat(this.get('brands'));
      }
      if (brands.length == 2) {
        controller.set('brandFilteri', brands[1].id);
      }
      return brands;
    });

    controller.allSizes = Ember['default'].computed('allSizesContent', function () {
      var sizes = Ember['default'].A([{ id: 0, name: 'All sizes' }]);
      if (controller.get('allSizesContent')) {
        sizes = sizes.concat(this.get('allSizesContent'));
      }
      return sizes;
    });

    controller.updateCollections = function () {
      //limpiar collection y album
      var brand = controller.get('brandFilteri');
      controller.set('collectionFilteri', null);
      if (brand) {
        utils['default'].getCollectionsByBrands(brand).then(function (response) {
          controller.set('collectionBrand', response.collection);
        });
      } else {
        controller.set('collectionBrand', null);
      }
    };

    utils['default'].addObserver(controller, ['brandFilteri'], 'updateCollections');

    // COMPUTED PROPERTIES 
    controller.collectionsFilteredByBrandi = Ember['default'].computed('collectionBrand', function () {

      var collections = Ember['default'].A([{ id: 0, name: 'All Collections' }]);
      if (controller.get('collectionBrand')) {
        collections = collections.concat(controller.get('collectionBrand'));
      }
      return collections;
    });

    controller.updateAlbums = function () {
      //limpiar album
      controller.set('albumFilteri', null);
      if (controller.get('collectionFilteri')) {
        utils['default'].getAlbumsByCollection(controller.get('collectionFilteri')).then(function (response) {

          controller.set('albumsCollection', response.album);
        });
      }
    };
    utils['default'].addObserver(controller, ['collectionFilteri'], 'updateAlbums');

    controller.albumsFilteredByCollectioni = Ember['default'].computed('albumsCollection', function () {
      var albums = Ember['default'].A([{ id: 0, name: 'All Albums' }]);
      if (this.get('albumsCollection')) {
        albums = albums.concat(this.get('albumsCollection'));
      }
      return albums;
    });

    //Cargar todas las collections
    controller.allCollectionsi = Ember['default'].computed('collectionsPaginated.length', function () {
      return controller.get('collectionsPaginated');
    });

    //FILTRANDO POR ALBUMS
    controller.allAlbumsi = Ember['default'].computed('allCollectionsi', function () {
      var albums = undefined;
      var collections = this.get('allCollectionsi');
      var albumsToShowFiltered = [];

      if (collections) {
        albums = collections.reduce(function (a, c) {
          if (c.get) {
            a = a.concat(c.get('albums').toArray());
          }
          return a;
        }, []);

        // AQUI HACE UN EFECTO VISUAL
        utils['default'].lazyLoad(2000, lazyContainer);
        //AQUI OCULTA LOS SAMPLES CUANDO RETORNA MUESTRA TODOS OTRA VEZ
        // _hideControllerSamplesi(hideControllerSamples, controller);

        //Dont show unpublished for Booking creation AND INVENTORY!

        albums.forEach(function (al) {
          if (al.get('published')) {
            albumsToShowFiltered.push(al);
          }
        });

        // AQUI verifico si hay mas datos que cargar
        var pagination = controller.get('pagination');
        var loadMore = pagination.actualPage < pagination.lastPage;
        controller.set('loadMorei', loadMore);

        //CARGAR MAS COLLECTIONS
        if (!loadMore) {
          controller.set('collectionPage', pagination.lastPage);
        }
      }
      return albumsToShowFiltered;
    });

    //VER ESTO QUE SERIA
    controller.showroomsFilteredByBrandi = Ember['default'].computed(function () {
      var showrooms = Ember['default'].user.get('showroom').get('company').get('showrooms');
      //Por si ya se ha desactivado en el controller
      if (showrooms.length > 1 && !controller.get('disableShowroomFilter')) {
        controller.set('disableShowroomFilter', false);
      } else {
        controller.set('disableShowroomFilter', true);
      }
      return utils['default'].addDefaultOption(showrooms, 'All Showrooms', 'label', null);
    });

    controller.searchFilterFunctioni = function () {

      var samples = utils['default'].getSamples('paginated');
      var allSamplesUnvisible = true;

      samples.forEach(function (s) {
        var checked = true; //s.get('look.album.published'); ya tiene que venir del backend filtrado albums no publicados
        if (onlySamplesSameShowroom && checked) {
          checked = s.setVisibleIfForeignCanPropose(controller.get('booking.owner.showroom'));
        }

        s.set('visible', checked);
        allSamplesUnvisible = allSamplesUnvisible && !checked;
      });
      controller.set('allSamplesUnvisiblei', allSamplesUnvisible);

      _hideControllerSamplesi(hideControllerSamples, controller);

      utils['default'].lazyLoad(2000, lazyContainer);
    };

    controller._checkStatusShoppingListi = function (status, statusFilter, warning) {
      status = status.toLowerCase();
      statusFilter = statusFilter.toLowerCase();

      var arraycontains = undefined;

      switch (statusFilter) {
        //INTERNAL
        case "booked":
          arraycontains = ['booked', 'in'].contains(status);
          break;
        case "out":
          arraycontains = ['check in today', 'out'].contains(status);
          break;
        case "check out today":
          arraycontains = status === 'check out today';
          break;
        case "late check out":
          arraycontains = status === 'in' && warning;
          break;
        case "check in today":
          arraycontains = status === 'check in today';
          break;
        case "late check in":
          arraycontains = status === 'out' && warning;
          break;
        case "canceled":
          arraycontains = status.indexOf("canceled") !== -1;
          break;
        //REQUESTED
        case "proposed":
          arraycontains = status.indexOf("propos") !== -1;
          break;
        case "declined":
          arraycontains = status.indexOf("declined") !== -1;
          break;
        default:
          arraycontains = status == statusFilter;
          break;
      }
      return arraycontains;
    };

    controller._checkStatusi = function (status, statusFilter, warning) {
      status = status.toLowerCase();
      statusFilter = statusFilter.toLowerCase();

      var options = [];
      var arraycontains = undefined;

      if (statusFilter === 'in') {
        options = ['check out today', 'in'];
        arraycontains = options.contains(status);
      } else if (statusFilter === 'out') {
        options = ['check in today', 'out'];
        arraycontains = options.contains(status);
      } else if (statusFilter === 'late check-out') {
        arraycontains = status === 'in' && warning;
      } else if (statusFilter === 'late check-in') {
        arraycontains = status === 'out' && warning;
      } else {
        arraycontains = status === statusFilter;
      }

      return arraycontains;
    };

    controller.looksFilteredByAlbum = Ember['default'].computed('albumFilteri', function () {
      controller.set('lookFilteri', null);
      controller.set('colourFilteri', null);
      controller.set('statusFilteri', null);
      var album = controller.get('albumFilteri');
      if (!album || !album.get) {
        return [];
      }
      var albumId = album.get('id');
      var looks = album.get('looks');
      var looksFiltered = looks;
      if (albumId !== '0') {
        looksFiltered = looks.filter(function (look) {
          return look.get('album.id') === albumId && look.get('archived') === false && look.get('hasActiveSamples');
        });
      }
      return utils['default'].addDefaultOption(looksFiltered, 'All Looks', 'position', '');
    });

    controller.oneBrandi = Ember['default'].computed('allBrandsi.length', function () {
      if (controller.get('allBrandsi.length') === 2) {
        return true;
      }
      return false;
    });

    Ember['default'].activeFilter = container;
  }

  function _hideControllerSamplesi(hideControllerSamples, controller) {
    if (hideControllerSamples && controller.get('samples')) {
      controller.get('samples').forEach(function (sample) {
        return sample.set('visible', false);
      });
    }
  }

  function _setVisibleSamplesi(onlySamplesSameShowroom, controller) {
    var samples = controller.get('albumFilteri.samples') || utils['default'].getSamples('paginated');

    if (onlySamplesSameShowroom) {
      samples.forEach(function (sample) {
        sample.setVisibleIfForeignCanPropose(controller.get('booking.owner.showroom'));
      });
    } else {
      samples.setEach('visible', true);
    }
    if (controller.searchFilterFunctioni) {
      controller.searchFilterFunctioni();
    }
  }

});