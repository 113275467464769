define('bookalook/routes/contacts-lists/index', ['exports', 'ember', 'bookalook/routes/contacts-lists/functions', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, functions, config, utils, UserValidatorMixin) {

  'use strict';


  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      setEditionList: function setEditionList(list) {
        functions['default'].onSetEditionList(this, list);
      },
      setEditionListRefactor: function setEditionListRefactor(list) {
        this.onSetEditionListRefactor(list);
      },
      saveList: function saveList(list) {
        functions['default'].onSaveList(this, list);
      },
      deleteLists: function deleteLists() {
        functions['default'].onDeleteLists(this);
      },
      addAnotherAddress: function addAnotherAddress() {
        functions['default'].onAddAnotherAddress(this);
      },
      uploadContactsExcel: function uploadContactsExcel() {
        functions['default'].onUploadExcel(this);
      },
      sendInvitation: function sendInvitation(contact) {
        functions['default'].onSendInvitation(this, contact);
      },
      sendInvitationToLists: function sendInvitationToLists(lists) {
        functions['default'].onSendInvitationToLists(this, lists);
      },
      saveContactFromUser: function saveContactFromUser() {
        this.onSaveContactFromUser(this);
      },
      saveContactFromUserRefactor: function saveContactFromUserRefactor() {
        this.onSaveContactFromUserRefactor(this);
      },
      saveNewContact: function saveNewContact() {
        functions['default'].onSaveNewContact(this);
      },
      openNewContactModal: function openNewContactModal() {
        functions['default'].openNewContactModal(this);
      },
      addContact: function addContact() {
        functions['default'].onAddContact(this);
      },
      downloadList: function downloadList(list) {
        functions['default'].onDownloadContacts(list.get('id'));
      },

      addDeleteList: function addDeleteList(list) {
        this.onAddDeleteList(list);
      },
      bulkDeleteList: function bulkDeleteList() {
        this.onBulkDeleteList();
      },
      cancelDelete: function cancelDelete() {
        this.controller.set('listsToDelete', []);this.controller.set('contactsToDelete', []);
      },
      duplicateOwnedList: function duplicateOwnedList(list) {
        this.onDuplicateOwnedList(list);
      },
      duplicateBulkList: function duplicateBulkList() {
        this.onDuplicateLists();
      },
      cancelEdition: function cancelEdition(list) {
        list.rollbackAttributes();
      },

      cancelEditionNewContact: function cancelEditionNewContact(contactToAdd) {
        this.onCancelEditionNewContact(contactToAdd);
      },

      openInviteContactModal: function openInviteContactModal(contact) {
        functions['default'].onOpenInviteContactModal(this, contact);
      },
      openInviteAllContactsModal: function openInviteAllContactsModal() {
        functions['default'].onOpenInviteAllContactsModal(this);
      },
      addListToInvite: function addListToInvite(list) {
        functions['default'].onAddListToInvite(this, list);
      },
      cancelNoAllowedContactsModal: function cancelNoAllowedContactsModal() {
        Ember['default'].$('#no-allowed-contacts').modal('hide');
      },
      changeSelectedList: function changeSelectedList(list) {
        this.onChangeSelectedList(list);
      },
      selectAllContacts: function selectAllContacts() {
        functions['default'].onCheckAllContacts(this);
      },
      selectAllVisibleContacts: function selectAllVisibleContacts() {
        this.onSelectAllVisibleContacts();
      },
      selectContact: function selectContact(contact) {
        this.onSelectContact(contact);
      },
      addSelectedContact: function addSelectedContact(contact) {
        this.onAddSelectedContact(contact);
      },
      viewCheckeds: function viewCheckeds() {
        console.log(this.controller.get('checkedContacts'));
      },
      openDeleteListModal: function openDeleteListModal(list) {
        this.onOpenDeleteListModal(list);
      },
      openImportModal: function openImportModal(list) {
        this.onOpenImportModal(list);
      },
      openContactsView: function openContactsView(contact) {
        this.onOpenContactsView(contact);
      },
      addContactToDeleteList: function addContactToDeleteList(contact) {
        this.onAddContactToDeleteList(contact);
      },
      deleteContacts: function deleteContacts(contact) {
        this.onDeleteContacts(contact);
      },
      bulkDeleteContacts: function bulkDeleteContacts() {
        this.onBulkDeleteContacts();
      },
      addContactToDuplicateListCL: function addContactToDuplicateListCL(contact) {
        this.onAddContactToDuplicateListCL(contact);
      },
      bulkDuplicateContacts: function bulkDuplicateContacts() {
        this.onBulkDuplicateContacts();
      },
      duplicateContacts: function duplicateContacts() {
        this.onDuplicateBulkList();
      },
      cancelDuplicate: function cancelDuplicate() {
        this.onCancelDuplicate();
      },
      addContactToLists: function addContactToLists(contact) {
        this.onAddContactToLists(contact);
      },
      addContactMpToLists: function addContactMpToLists(contact) {
        this.onAddContactMpToLists(contact);
      },
      cancelAddMp: function cancelAddMp() {
        this.onCancelAddMp();
      },
      goToLastMps: function goToLastMps() {
        this.transitionTo('members');
      },
      toggleImportHelp: function toggleImportHelp() {
        utils['default'].toggleHelp('import');
      },
      sortContacts: function sortContacts(field) {
        this.onSortContacts(field);
      },
      toggleSetAsMainAddress: function toggleSetAsMainAddress(contact, address) {
        this.onToggleSetAsMainAddress(contact, address);
      },
      removeAddress: function removeAddress(address) {
        this.onRemoveAddress(address);
      }

    },

    model: function model() {
      //return utils.getContactLists(this);
      return Ember['default'].RSVP.hash({
        contactLists: utils['default'].getContactLists(this),
        mpUsers: utils['default'].getAPIJSON('users/last_mps?elements=19')
      });
    },

    setupController: function setupController(controller, model) {
      var ispr = Ember['default'].user.get('type');
      controller.setProperties(model);
      controller.set('userLogged', Ember['default'].user);
      var contactLists = model.contactLists;
      var mpUsers = model.mpUsers;
      if (mpUsers.length > 0) {
        mpUsers[mpUsers.length - 1].last = true;
      }

      controller.set('countryList', Ember['default'].countries);

      controller.set('selectedList', contactLists.find(function (c) {
        return c.id != null;
      }));
      this.initializeFilters();
      this._super(controller, model);
      functions['default'].setupController(controller, contactLists);

      controller.set('listsToDelete', []);
      controller.set('isListsIndex', true);
      controller.set('importExampleURL', config['default'].APIURL + 'excels');
      controller.set('setbuttonfree', '');

      controller.ownedLists = Ember['default'].computed.filterBy('contactLists', 'isOwned', true);
      controller.invitedLists = Ember['default'].computed.filterBy('contactLists', 'isOwned', false);
      controller.checkedLists = Ember['default'].computed.filterBy('contactLists', 'checked', true);
      controller.bulkDisabled = Ember['default'].computed.empty('checkedLists');

      //contacts sum
      controller.contactsUnique = functions['default'].contactsUnique();

      functions['default'].setComputedNewContact(controller);

      //Get user plan configuration for contacts
      if (ispr == "PR") {
        utils['default'].getAPIJSON('plans/contactsAllowedForPlanUser').then(function (res) {
          controller.set('contactsAllowedForPlanUser', res);
          if (res == false) {
            controller.set('setbuttonfree', '1');
            $('#create_new_list').prop('disabled', true);
          }
        });
      }
      //controller.set('allVisibleContactsSelected', false);

      controller.visibleContacts = Ember['default'].computed('selectedList', 'selectedList.contacts', 'searchInput', 'contactsSorting', function () {
        var visible = true;

        var searchInput = controller.get('searchInput').toLowerCase();
        var selectedList = this.get('selectedList');
        var contacts = selectedList.get('contacts');
        var allSelected = true;
        if (searchInput) {
          contacts = contacts.filter(function (c) {
            //Filter by search input
            var search = c.get('fullName') + ' ' + c.get('jobTitle') + ' ' + c.get('company') + ' ' + c.get('email');
            visible = search.toLowerCase().indexOf(searchInput) >= 0;
            // if (visible && !c.get('checked')){
            //   allSelected = false;
            // }
            return visible;
          });
        }
        // let selectedListFiltered = selectedList;
        // selectedListFiltered.set('contacts', contacts);
        //controller.set('allVisibleContactsSelected', allSelected);

        // Setting a criteria
        var criteria = controller.get('contactsSorting') || 'fullName-asc';

        // Getting criteria and direction respectively
        var criteriaKey = criteria.slice(0, criteria.length - 4);
        var order = criteria.slice(criteria.length - 4);

        // Applying the sorting
        var content = contacts.toArray();
        var contactsSorted = content.sort(getSortFunction(order, criteriaKey));

        // Saving the criteria for next sorting comparison
        controller.set('contactsSorting', criteria);

        return contactsSorted;
      });

      controller.checkedContacts = Ember['default'].computed.filterBy('selectedList.contacts', 'checked', true);
      controller.bulkDisabledRefactor = Ember['default'].computed.empty('checkedContacts');

      controller.set('contactsToDelete', []);
      controller.set('duplicateContactSelectedLists', []);

      controller.set('invite-join', true);
      functions['default'].setListsFilteredBySelectedUserObserver(controller);
    },

    initializeFilters: function initializeFilters() {
      this.controller.set('searchInput', '');
      this.controller.set('contactsSorting', null);
    },

    onSortContacts: function onSortContacts(field) {
      var key = 'contactsSorting';
      var selector = '.contacts_table';

      $(selector).find('th').removeClass('asc des');
      var previousField = this.controller.get(key);
      if (field == previousField.slice(0, previousField.length - 4)) {
        if (previousField.endsWith('-des')) {
          $(selector).find('.' + field).addClass('asc');
          field = field + '-asc';
        } else {
          $(selector).find('.' + field).addClass('des');
          field = field + '-des';
        }
      } else {
        $(selector).find('.' + field).addClass('asc');
        field = field + '-asc';
      }

      this.controller.set(key, field);
    },

    onAddContact: function onAddContact() {
      var _this2 = this;

      this.controller.set('contactToAdd', this.store.createRecord('contact'));
      this.store.findAll('user').then(function (res) {
        _this2.controller.set('bookalookUsers', res.filterBy('isPR', true));
      });
      this.controller.set('addContactSelectedLists', []);
    },

    onDuplicateOwnedList: function onDuplicateOwnedList(list) {
      Ember['default'].set(list, 'checked', true);
      this.onDuplicateLists();
    },

    onDuplicateLists: function onDuplicateLists() {
      var _this = this;
      var lists = this.controller.get('checkedLists');

      lists.forEach(function (list) {
        _this.duplicateList(list);
      });
    },

    onAddAnotherAddress: function onAddAnotherAddress() {
      var contact = this.controller.get('contactToAdd');
      contact.get('contactAddresses').addObject(this.store.createRecord('contact-address', { contact: contact }));
    },

    duplicateList: function duplicateList(list) {
      var _this3 = this;

      this.send('openMessageModal', 'Duplicating list... Please Wait.');

      utils['default'].putAPIJSON('contactsLists/' + list.get('id') + '/duplicateContactsList').then(function (newList) {
        _this3.refresh();

        _this3.controller.notifyPropertyChange('contactLists');
        _this3.send('closeMessageModal');
        //this.send('openTopBar', 'List duplicated succesfully');
        _this3.send('openPositiveTopBar');
      });
    },

    onAddDeleteList: function onAddDeleteList(list) {
      var listsToDelete = this.controller.get('listsToDelete');
      listsToDelete.addObject(list);

      this.controller.set('deleteMultiple', listsToDelete.length > 1);
    },

    onBulkDeleteList: function onBulkDeleteList() {
      var lists = this.controller.get('contactLists');
      var listsToDelete = this.controller.get('listsToDelete');

      listsToDelete.addObjects(lists.filterBy('checked'));

      this.controller.set('deleteMultiple', listsToDelete.length > 1);
    },

    onCancelEditionNewContact: function onCancelEditionNewContact(contactToAdd) {
      Ember['default'].$('#new-refactor-contact').modal('hide');
      this.send('cancelEdition', contactToAdd);
    },

    onSaveContactFromUser: function onSaveContactFromUser(route) {
      Ember['default'].$('#new-refactor-contact').modal('hide');

      //Check limits
      var contactsAllowedForPlanUser = route.controller.get('contactsAllowedForPlanUser');
      if (contactsAllowedForPlanUser != true) {
        functions['default'].onOpenNoAllowedContactsModal();
      } else {
        functions['default'].onSaveContactFromUser(route);
      }
    },

    onSaveContactFromUserRefactor: function onSaveContactFromUserRefactor() {
      var route = this;
      var button = Ember['default'].$('#add-mp-contacts-lists');
      var button2 = Ember['default'].$('#add-mp-found-contacts-list');
      button.button('loading');
      button2.button('loading');
      //Check limits
      var contactsAllowedForPlanUser = route.controller.get('contactsAllowedForPlanUser');
      if (contactsAllowedForPlanUser != true) {
        Ember['default'].$('#add-mp-found').modal('hide');
        Ember['default'].$('#add-mp').modal('hide');
        button.button('reset');
        button2.button('reset');
      } else {
        (function () {
          var user = route.controller.get('addContactSelectedUser');
          route.send('openMessageModal', 'Adding contact...');
          Ember['default'].$('#add-mp').modal('hide');
          Ember['default'].$('#add-mp-found').modal('hide');
          route.store.find('contact', { user: user.id }).then(function (data) {
            var contact = data.objectAt(0);
            var selectedLists = route.controller.get('addContactSelectedLists');
            var promise = null;
            if (contact) {
              contact.get('contactsLists').addObjects(selectedLists);

              contact.save().then(function () {
                route.controller.notifyPropertyChange('contactLists');
                Ember['default'].set(user, 'checked', false);

                utils['default'].getAPIJSON('users/last_mps?elements=19').then(function (resultLastMps) {
                  route.controller.set('mpUsers', resultLastMps);
                  route.controller.notifyPropertyChange('mpUsers');
                });

                route.send('closeMessageModal');
                button.button('reset');
                button2.button('reset');
                //route.send('openTopBar', 'Contact has been added succesfully...');
                route.send('openPositiveTopBar');
              });
            } else {
              route.store.find('user', user.id).then(function (userDB) {
                var createdContact = route.store.createRecord('contact', {
                  name: user.name,
                  surname: user.surname,
                  jobTitle: user.jobTitle,
                  email: user.email,
                  company: user.company,
                  user: userDB
                });
                createdContact.get('contactsLists').addObjects(selectedLists);

                createdContact.save().then(function () {
                  route.controller.notifyPropertyChange('contactLists');
                  Ember['default'].set(user, 'checked', false);

                  utils['default'].getAPIJSON('users/last_mps?elements=19').then(function (resultLastMps) {
                    route.controller.set('mpUsers', resultLastMps);
                    route.controller.notifyPropertyChange('mpUsers');
                  });

                  route.send('closeMessageModal');
                  button.button('reset');
                  button2.button('reset');
                  //route.send('openTopBar', 'Contact has been added succesfully...');
                  route.send('openPositiveTopBar');
                });
              });
            }
          });
        })();
      }
    },

    onChangeSelectedList: function onChangeSelectedList(list) {
      functions['default'].onChangeSelectedList(this, list);
    },

    onSelectAllVisibleContacts: function onSelectAllVisibleContacts() {
      var contacts = this.controller.get('visibleContacts');
      var changeCheckTo = this.controller.get('allVisibleContactsSelected');
      contacts.map(function (c) {
        return c.set('checked', !changeCheckTo);
      });
      this.controller.notifyPropertyChange('visibleContacts');
    },

    onSelectContact: function onSelectContact(contact) {
      contact.set('checked', !contact.get('checked'));
      //this.controller.notifyPropertyChange('visibleContacts');
    },

    onOpenDeleteListModal: function onOpenDeleteListModal(list) {
      Ember['default'].$('#delete-lists').modal();
      Ember['default'].openModal();
      this.controller.get('listsToDelete').addObject(list);
    },

    onOpenImportModal: function onOpenImportModal(list) {
      Ember['default'].$('#import-contacts').modal();
      Ember['default'].openModal();
      this.controller.set('listToImport', list);
    },

    onOpenContactsView: function onOpenContactsView(contact) {
      this.controller.set('contactToView', contact);
      Ember['default'].$('#view-contact').modal();
      //Ember.$('#view-manual-contact').modal();
      Ember['default'].openModal();
    },

    onAddContactToDeleteList: function onAddContactToDeleteList(contact) {
      var contacts = this.controller.get('contactsToDelete');
      contacts.addObject(contact);
      this.controller.set('deleteMultiple', contacts.length > 1);
      Ember['default'].$('#delete-contacts').modal();
      Ember['default'].openModal();
    },

    onDeleteContacts: function onDeleteContacts() {
      var _this = this;
      var contacts = this.controller.get('contactsToDelete');
      var selectedList = this.controller.get('selectedList');
      contacts.forEach(function (contact) {
        setTimeout(function () {
          contact.get('contactsLists').removeObject(selectedList);
          contact.save();
        }, 2000);
      });
      //this.controller.notifyPropertyChange('visibleContacts');
      this.controller.set('contactsToDelete', []);
      this.controller.set('deleteMultiple', false);
      Ember['default'].$('#delete-contacts').modal('hide');
    },

    onBulkDeleteContacts: function onBulkDeleteContacts() {
      this.controller.set('contactsToDelete', this.controller.get("checkedContacts"));
      this.controller.set('deleteMultiple', this.controller.get('contactsToDelete').length > 1);
      Ember['default'].$('#delete-contacts').modal();
      Ember['default'].openModal();
    },

    onAddContactToDuplicateListCL: function onAddContactToDuplicateListCL(contact) {
      this.controller.set('contactsToDelete', []);
      var contacts = this.controller.get('contactsToDelete');
      contacts.addObject(contact);
      this.controller.set('deleteMultiple', contacts.length > 1);

      functions['default'].setListsFilteredByContactToDuplicate(this.controller);
      Ember['default'].$('#duplicate-contacts').modal();
      Ember['default'].openModal();
    },

    onBulkDuplicateContacts: function onBulkDuplicateContacts() {
      this.controller.set('contactsToDelete', this.controller.get("checkedContacts"));
      this.controller.set('deleteMultiple', this.controller.get('contactsToDelete').length > 1);
      this.controller.set("duplicateContactSelectedLists", []);

      var contactListsController = this.controller.get('contactLists');
      this.controller.set('contactListsFiltered', contactListsController);

      Ember['default'].$('#duplicate-contacts').modal();
      Ember['default'].openModal();
    },

    onDuplicateBulkList: function onDuplicateBulkList() {
      var _this4 = this;

      var _this = this;
      var lists = this.controller.get("duplicateContactSelectedLists");
      var contacts = this.controller.get('contactsToDelete');
      var deferred = Ember['default'].$.Deferred();
      Ember['default'].$("#duplicate-contacts").modal('hide');
      _this.send('openMessageModal', 'Duplicating contact...');

      var contactsToDuplicate = [];
      contacts.forEach(function (contact) {
        contactsToDuplicate.push(contact.id);
      });

      var promises = [];
      lists.forEach(function (list) {
        var promise = utils['default'].putAPIJSON('contactsLists/' + list.id + '/duplicate', contactsToDuplicate);
        promises.push(promise);

        //functions.saveContactInList(_this, contact, list);
      });

      Ember['default'].RSVP.all(promises).then(function () {
        deferred.resolve();
      });
      deferred.done(function () {
        //_this.send('closeMessageModal');
        _this.store.findAll('contacts-list').then(function (lists) {
          _this.controller.set('contactLists', lists);
          _this.controller.set('duplicateContactSelectedLists', []);
          _this.send('closeMessageModal');
          //_this.send('openTopBar', 'Contacts successfully duplicated.');
          _this.send('openPositiveTopBar');
          functions['default'].onCheckAllContacts(_this4, false);
        });
      });
    },

    onCancelDuplicate: function onCancelDuplicate() {
      this.controller.set('duplicateContactSelectedLists', []);
      this.controller.set('contactsToDelete', []);
    },

    onAddContactToLists: function onAddContactToLists(contact) {
      this.controller.set('addContactSelectedUser', contact);
      this.controller.set('canAddContact', false);

      this.controller.set('addContactSelectedLists', []);
      Ember['default'].$('#add-mp').modal();
      Ember['default'].openModal();
      functions['default'].setCanAddContactObserver(this.controller);
    },

    onAddContactMpToLists: function onAddContactMpToLists(contact) {
      Ember['default'].$('#view-contact-profile').modal('hide');

      var user = contact.get('user');
      if (user.get('content')) {
        user = user.get('content');
      }
      this.controller.set('addContactSelectedUser', user);
      this.controller.set('canAddContact', false);

      this.controller.set('addContactSelectedLists', []);
      Ember['default'].$('#add-mp').modal();
      Ember['default'].openModal();
      functions['default'].setCanAddContactObserver(this.controller);
    },
    onCancelAddMp: function onCancelAddMp(contactToAdd) {
      Ember['default'].$('#add-mp').modal('hide');
      Ember['default'].$('#add-mp-found').modal('hide');
      this.controller.set('addContactSelectedUser', null);
      this.controller.set('addContactSelectedLists', []);
    },

    onSetEditionListRefactor: function onSetEditionListRefactor(list) {
      list = list || this.store.createRecord('contacts-list', { owner: Ember['default'].user, privacy: "Only me", users: [Ember['default'].user] });
      this.controller.set('listToEdit', list);
      Ember['default'].$('#new-list').modal();
      Ember['default'].openModal();
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-contact-brand-refactor');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-contact-brand-refactor');
    },

    onToggleSetAsMainAddress: function onToggleSetAsMainAddress(newCallInAddress) {
      if (newCallInAddress.setAsMain) {
        Ember['default'].set(newCallInAddress, 'setAsMain', false);
      } else {
        Ember['default'].set(newCallInAddress, 'setAsMain', true);
      }
    },

    onRemoveAddress: function onRemoveAddress(address) {
      address.destroyRecord();
    }

  });

  function getSortFunction(order, criteria) {
    return function (a, b) {
      var elemA = undefined,
          elemB = undefined,
          elemA2 = undefined,
          elemB2 = undefined;
      if (criteria.indexOf('_') != -1) {
        // For two levels properties
        var criterias = criteria.split('_');
        if (criterias.length === 2) {
          elemA = a.get(criterias[0]) && a.get(criterias[0]).get(criterias[1]) || '';
          elemB = b.get(criterias[0]) && b.get(criterias[0]).get(criterias[1]) || '';
        }
      } else {
        // For one level properties
        elemA = a.get(criteria) || '';
        elemB = b.get(criteria) || '';
      }

      if (typeof elemA === 'string') {
        if (order === '-asc') {
          return elemA.localeCompare(elemB);
        } else {
          return -1 * elemA.localeCompare(elemB);
        }
      } else if (typeof elemA === 'number') {
        elemA = elemA != '' ? elemA : 0;
        elemB = elemB != '' ? elemB : 0;
        if (order === '-asc') {
          return elemA - elemB;
        } else {
          return elemB - elemA;
        }
      }
    };
  }

  function format(option) {
    //console.log(option);
    if (!option.id) {
      return option.text;
    }
    var ob = option.text + '<img src="https://api.bookalook.com/uploads/documents/images/user-default.png" />'; // replace image source with option.img (available in JSON)
    return ob;
  };

});