define('bookalook/routes/messages/index', ['exports', 'ember', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, utils, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      newMessage: function newMessage(conversation, text) {
        this.onNewMessage(conversation, text);
      },
      getMessages: function getMessages(conversation) {
        this.onGetMessages(conversation);
      },
      newConversation: function newConversation(receiver, text) {
        this.onNewConversation(receiver.get('id'), text);
      },
      toggleNewConversation: function toggleNewConversation(activate) {
        this.onToggleNewConversation(activate);
      },
      cancelNewConversation: function cancelNewConversation() {
        this.onCancelNewConversation();
      },
      removeConversation: function removeConversation(conversation) {
        this.onRemoveConversation(conversation);
      },
      acceptConversation: function acceptConversation(conversation) {
        this.onAcceptConversation(conversation);
      },
      newMessageEnter: function newMessageEnter() {
        this.onNewMessageEnter();
      },
      ignoreConversation: function ignoreConversation(conversation) {
        this.onIgnoreConversation(conversation);
      },
      willTransition: function willTransition() {
        clearInterval(Ember['default'].messageInterval);
      },
      selectConversationById: function selectConversationById(id) {
        var justGetConversation = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        this._selectConversationById(id, justGetConversation);
      }
    },

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        bookalookUsers: this.store.findAll('user'),
        conversations: utils['default'].getAPIJSON('conversation/user/' + Ember['default'].userId).then(function (conversations) {
          var conversationList = conversations;

          conversationList.forEach(function (conversation) {
            conversation.lastModified = new Date(conversation.lastModified.date).getTime() / 1000;
          });

          return conversationList;
        }),
        idConversationParam: parseInt(params.idConversation)
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      controller.setProperties({
        conversationMessages: [],
        selectedConversation: null,
        addSelectedUsersToConversation: [],
        userNewConversation: '',
        isNewConversation: false,
        newMessageText: '',
        conversationsObj: controller.get('conversations'),
        sorting: ['lastModified:desc'],
        todayDate: Ember['default'].formatDate(new Date(), '-'),
        conversationSearchInput: '',
        notUsersFoundMessage: '',
        timeInterval: 10000,
        auxConversationNotificationComp: null
      });

      // remove current user and @bookalook accounts
      var chatUsers = controller.get('bookalookUsers').filter(function (user) {
        return user.get('id') !== Ember['default'].userId.toString();
      });
      chatUsers = chatUsers.filter(function (user) {
        return user.get('searchable');
      }).sortBy('name');
      controller.set('chatUsers', chatUsers);

      if (!controller.get('conversationList')) {
        controller.conversationList = Ember['default'].computed.sort('conversationsObj', 'sorting');
      }

      controller.canCreateNewConversation = Ember['default'].computed('newMessageText', function () {
        return controller.get('newMessageText.length') > 0;
      });

      this._setupObservers();

      if (controller.get('idConversationParam')) {
        this._selectConversationById(controller.get('idConversationParam'));
      } else {
        this._selectConversationByPosition(0);
      }
    },

    _selectConversationByPosition: function _selectConversationByPosition(position) {
      if (this.controller.get('conversationList.length') > 0) {
        var conversation = this.controller.get('conversationList').objectAt(position);
        this.controller.set('selectedConversation', conversation);
        this.onGetMessages(conversation);
      }
    },

    _selectConversationById: function _selectConversationById(idConversation) {
      var justGetConversation = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var conversationList = this.controller.get('conversationsObj'),
          conversationFound;

      conversationFound = conversationList.find(function (conversation) {
        return conversation.id === idConversation;
      });
      if (conversationFound) {
        if (justGetConversation) {
          this.controller.set('auxConversationNotificationComp', conversationFound);
        } else {
          this.controller.set('selectedConversation', conversationFound);
          this.onGetMessages(conversationFound);
        }
      }
    },

    _updateMessagesInterval: function _updateMessagesInterval() {
      if (this.controller.get('conversationsObj.length') > 0) {
        this.onGetMessages(this.controller.get('selectedConversation'), true);
      }
    },

    _setupObservers: function _setupObservers() {
      var _this = this;

      var controller = this.controller;

      utils['default'].addObserver(controller, ['userNewConversation'], function () {
        var conversationList = _this.controller.get('conversationList');
        var selectedUser = controller.get('userNewConversation');
        var existingConversation = conversationList.find(function (conversation) {
          return conversation.users[0].id + "" === selectedUser.id;
        });

        if (existingConversation) {
          controller.setProperties({
            isNewConversation: false
          });
          _this.onGetMessages(existingConversation);
        } else {
          if (controller.get('userNewConversation') !== '') {
            controller.setProperties({
              isNewConversation: true,
              conversationMessages: [],
              selectedConversation: { users: [{ friend: true }] }
            });
          }
        }
      });

      utils['default'].addObserver(controller, ['conversationSearchInput'], function () {
        var searchConversationInput = controller.get('conversationSearchInput');
        var conversationList = controller.get('conversationList');
        var resultsFound = false;

        conversationList.forEach(function (conversation) {
          var conversationUser = conversation.users[0].name;
          var isInvisible = conversationUser.toLowerCase().indexOf(searchConversationInput) === -1;
          Ember['default'].set(conversation, "invisible", isInvisible);
          if (!isInvisible) {
            resultsFound = true;
          }
        });
        if (!resultsFound) {
          controller.set('notUsersFoundMessage', 'Not users found.');
        } else {
          controller.set('notUsersFoundMessage', '');
        }
      });
    },

    onNewMessageEnter: function onNewMessageEnter() {
      if (!this.controller.get('isNewConversation')) {
        this.onNewMessage(this.controller.get('selectedConversation'), this.controller.get('newMessageText'));
      }
    },

    onNewMessage: function onNewMessage(conversation, text) {
      var _this2 = this;

      var conversationMessages = this.controller.get('conversationMessages');
      var lastMessage = conversationMessages.objectAt(conversationMessages.get('length') - 1).id;
      if (text.trim().length === 0) {
        this.controller.set('newMessageText', '');
        return;
      }
      var data = {
        textMessage: text,
        conversation: conversation.id,
        lastMessageId: lastMessage
      };

      utils['default'].postAPIJSON('message', data).then(function (message) {
        var newMessageDate = new Date(message.message.date.date).getTime() / 1000;
        var messages = message.newMessages;
        Ember['default'].set(_this2.controller.selectedConversation, 'lastMessage', messages[messages.length - 1].text);
        Ember['default'].set(_this2.controller.selectedConversation, 'lastModified', newMessageDate);
        conversationMessages.addObjects(messages.reverse());
        _this2.controller.set('newMessageText', '');
        //scroll down
        Ember['default'].$('.msg-wrap').animate({ scrollTop: Ember['default'].$('.msg-wrap').prop("scrollHeight") }, 500);
      });
    },

    onAcceptConversation: function onAcceptConversation(conversation) {
      var _this3 = this;

      utils['default'].postAPIJSON('users/friendship/' + Ember['default'].userId + '/' + conversation.users[0].id).then(function () {
        Ember['default'].set(conversation, 'users.0.friend', true);
        _this3.controller.set('selectedConversation', conversation);
        _this3.onGetMessages(conversation);
      });
    },

    onIgnoreConversation: function onIgnoreConversation(conversation) {
      var _this4 = this;

      var conversationList = this.controller.get('conversationList');
      utils['default'].postAPIJSON('users/friendship/ignore/' + Ember['default'].userId + '/' + conversation.users[0].id).then(function () {
        utils['default'].putAPIJSON('conversation/' + conversation.id + '/archive').then(function () {
          conversationList.removeObject(conversation);
          //go to first conversation
          _this4.controller.setProperties({
            selectedConversation: conversationList.objectAt(0),
            conversationSearchInput: ''
          });

          if (conversationList.objectAt(0)) {
            _this4.onGetMessages(conversationList.objectAt(0));
          }
          _this4.send('openTopBar', 'Conversation removed');
        });
      });
    },

    onNewConversation: function onNewConversation(idReceiver, text) {
      var _this5 = this;

      var data = {
        'receivers': [idReceiver],
        'textMessage': text
      };

      //Must be rewriten when multiuser conversation is supported
      return utils['default'].postAPIJSON('conversation', data).then(function (res) {
        var conversationEmberObject = Ember['default'].Object.create(res);
        var conversationList = _this5.controller.get('conversationsObj');

        Ember['default'].set(conversationEmberObject, 'lastModified', new Date(conversationEmberObject.lastModified.date).getTime() / 1000);
        _this5.controller.set('selectedConversation', conversationEmberObject);
        conversationList.unshiftObject(conversationEmberObject);

        //this.onGetMessages(conversationEmberObject);
        _this5.controller.setProperties({
          newMessageText: '',
          isNewConversation: false,
          userNewConversation: '',
          newConversationButtonActive: false
        });
      });
    },

    onGetMessages: function onGetMessages(conversation) {
      var _this6 = this;

      var isUpdate = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      //production code
      var conversationId = conversation.id,
          friendship = conversation.users[0].friend,
          timeInterval = this.controller.get('timeInterval'),
          promise = null;

      if (!isUpdate) {
        this.controller.setProperties({
          selectedConversation: conversation,
          isNewConversation: false,
          userNewConversation: '',
          newMessageText: '',
          newConversationButtonActive: false
        });

        clearInterval(Ember['default'].messageInterval);
      }

      if (friendship) {
        promise = utils['default'].getAPIJSON('conversation/' + conversationId).then(function (messages) {
          var lastMessageDate = new Date(messages[0].date.date).getTime() / 1000;
          var lastMessageText = messages[0].text;

          // Check message for turning links on
          var linksRegExp = /(http.+\/){0,1}[a-zA-Z]+\.[a-zA-Z]{2,5}[\/,\#,\?]*.*([\n,\s,]|$)/;
          messages.forEach(function (message) {
            var match = message.text.match(linksRegExp);
            if (match) {
              var url = match[0].split(" ")[0];
              var formedLink;
              if (!url.includes('http')) {
                formedLink = message.text.replace(url, '<a href="http://' + url + '" target="_blank" >' + url + '</a>');
              } else {
                formedLink = message.text.replace(url, '<a href="' + url + '" target="_blank" >' + url + '</a>');
              }
              message.text = formedLink;
            };
          });
          messages = messages.reverse();
          _this6.controller.set('conversationMessages', messages);
          Ember['default'].set(conversation, 'lastModified', lastMessageDate);
          Ember['default'].set(conversation, 'lastMessage', lastMessageText);
          Ember['default'].run.later(function () {
            Ember['default'].$('.msg-wrap').animate({ scrollTop: Ember['default'].$('.msg-wrap').prop("scrollHeight") }, 500);
          }, 500);
        });
      }
      if (!isUpdate) {
        if (promise) {
          promise.then(function () {
            return Ember['default'].messageInterval = setInterval(function () {
              return _this6._updateMessagesInterval();
            }, timeInterval);
          });
        } else {
          Ember['default'].messageInterval = setInterval(function () {
            return _this6._updateMessagesInterval();
          }, timeInterval);
        }
      }

      Ember['default'].set(conversation, 'somethingNew', false);
    },

    onToggleNewConversation: function onToggleNewConversation(activate) {
      var _this7 = this;

      var $el = Ember['default'].$('.top_conversation_button');
      var $el2 = Ember['default'].$('.new-conversation-btn');
      $el.fadeOut(function () {
        _this7.controller.set('newConversationButtonActive', activate);
        $el.fadeIn();
      });
      $el2.fadeOut();
    },

    onCancelNewConversation: function onCancelNewConversation() {
      var _this8 = this;

      var $el = Ember['default'].$('.top_conversation_button');
      var firstConversation = this.controller.get('conversationList').objectAt(0);
      var $el2 = Ember['default'].$('.new-conversation-btn');
      $el.fadeOut(function () {
        _this8.controller.setProperties({
          isNewConversation: false,
          newConversationButtonActive: false,
          userNewConversation: '',
          selectedConversation: firstConversation
        });
        $el2.fadeIn();
        if (firstConversation) {
          _this8.onGetMessages(firstConversation);
        }
        $el.fadeIn();
      });
    },

    onRemoveConversation: function onRemoveConversation(conversation) {
      var _this9 = this;

      var conversationId = conversation.id;
      var conversationList = this.controller.get('conversationsObj');
      var orderedConversationList = this.controller.get('conversationList');

      utils['default'].openDefaultConfirmationModal(this, 'Conversation', 'You are about to remove this conversation.', 'Do you wish to proceed?', 'Yes, remove this conversation', 'No').then(function () {
        utils['default'].putAPIJSON('conversation/' + conversationId + '/archive').then(function () {
          conversationList.removeObject(conversation);
          //go to first conversation
          _this9.controller.setProperties({
            conversationSearchInput: ''
          });

          if (conversationList.objectAt(0)) {
            _this9.controller.get('selectedConversation', orderedConversationList.objectAt(0));
            _this9.onGetMessages(orderedConversationList.objectAt(0));
          } else {
            _this9.controller.set('selectedConversation', null);
          }
          _this9.send('openTopBar', 'Conversation removed');
          _this9.controller.notifyPropertyChange('conversationObj');
          _this9.controller.notifyPropertyChange('conversationList');
        });
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-inventory');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-inventory');
    }
  });

});