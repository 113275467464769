define('bookalook/templates/bookings/partials/booking-look-card-brand-refactor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 28,
                        "column": 22
                      },
                      "end": {
                        "line": 30,
                        "column": 22
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
                  },
                  arity: 2,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","partial",["bookings/partials/booking-sample-row-brand-refactor"],[],["loc",[null,[29,24],[29,89]]]]
                  ],
                  locals: ["sample","sampleIndex"],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 27,
                      "column": 20
                    },
                    "end": {
                      "line": 31,
                      "column": 20
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","sampleGroup.all",["loc",[null,[28,30],[28,45]]]]],[],0,null,["loc",[null,[28,22],[30,31]]]]
                ],
                locals: ["sampleGroup"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 12
                  },
                  "end": {
                    "line": 36,
                    "column": 12
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","look-table-wrapper col-xs-12");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table-column no-padding");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("table");
                dom.setAttribute(el3,"class","table bal-table table-hover table-center table-height tr_min_height");
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("thead");
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("tr");
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_sample");
                var el7 = dom.createTextNode("Sample");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_count");
                var el7 = dom.createTextNode("Count");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_colour");
                var el7 = dom.createTextNode("Colours");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_size");
                var el7 = dom.createTextNode("Size");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_status");
                var el7 = dom.createTextNode("Status");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_actions");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_calendar");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                    ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("tbody");
                var el5 = dom.createTextNode("\n");
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("                    ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element0,1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 3]),1,1);
                return morphs;
              },
              statements: [
                ["inline","partial",["components/looks-gallery"],[],["loc",[null,[11,16],[11,54]]]],
                ["block","each",[["get","look.groupedSamplesWithArchived",["loc",[null,[27,28],[27,59]]]]],[],0,null,["loc",[null,[27,20],[31,29]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 10
                },
                "end": {
                  "line": 37,
                  "column": 10
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","look.hasVisibleAndCheckedSamples",["loc",[null,[8,18],[8,50]]]]],[],0,null,["loc",[null,[8,12],[36,19]]]]
            ],
            locals: ["look"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","album.looks",["loc",[null,[7,18],[7,29]]]]],[],0,null,["loc",[null,[7,10],[37,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 39,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","album.hasVisibleAndCheckedSamples",["loc",[null,[6,14],[6,47]]]]],[],0,null,["loc",[null,[6,8],[38,15]]]]
        ],
        locals: ["album"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/partials/booking-look-card-brand-refactor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","look-card-wrapper clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","look_wrapper clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element1,1,1);
        morphs[1] = dom.createMorphAt(element1,3,3);
        return morphs;
      },
      statements: [
        ["inline","partial",["global/album-booking-header"],[],["loc",[null,[4,6],[4,47]]]],
        ["block","each",[["get","collection.albums",["loc",[null,[5,14],[5,31]]]]],[],0,null,["loc",[null,[5,6],[39,15]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});