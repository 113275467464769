define('bookalook/components/inline-textarea-edition', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['inline_edition', 'multiline_edition'],
    classNameBindings: ['is-dirty'],

    'is-dirty': false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', '-');
      }
      this.set('initialValue', this.get('value'));
    },

    click: function click() {
      if (Ember['default'].user.get('isPR')) {
        var $el = Ember['default'].$(this.element);
        if (!$el.hasClass('edit_mode')) {
          $el.fadeOut(function () {
            return $el.addClass('edit_mode');
          });
          $el.fadeIn(function () {
            return $el.find('.inline_input').get(0).select();
          });
        }
      }
    },

    focusOut: function focusOut() {
      var $el = Ember['default'].$(this.element);
      $el.fadeOut(function () {
        return $el.removeClass('edit_mode');
      });
      this.set('is-dirty', this.get('initialValue') !== this.get('value'));
      $el.fadeIn();
    },

    rowsLimiter: (function () {
      var returnAddress = this.get('value');
      if (!Ember['default'].isNone(returnAddress)) {
        var newLines = returnAddress.split("\n").length;
        var newText;

        if (this.get('maxrows') && newLines > this.get('maxrows')) {
          var splitText = returnAddress.split("\n");
          var popText = splitText.pop();

          newText = splitText.join("\n") + popText;
          this.set('value', newText);
        }
      }
    }).observes('value')
  });

});