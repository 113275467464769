define('bookalook/templates/settings/image-avatar-cropper', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 16
              },
              "end": {
                "line": 44,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","model.avatar",["loc",[null,[43,30],[43,42]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 16
              },
              "end": {
                "line": 47,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element5, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","model.company.avatar",["loc",[null,[46,30],[46,50]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 16
              },
              "end": {
                "line": 50,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element4, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","model.showroom1.company.avatar",["loc",[null,[49,30],[49,60]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 10
            },
            "end": {
              "line": 58,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","crop_wrapper");
          dom.setAttribute(el1,"style","display:none;");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","show text-uppercase");
          var el3 = dom.createTextNode("Select area");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","cropper-container ");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","preview_wrapper");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","show text-uppercase");
          var el3 = dom.createTextNode("Image preview");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1, 3]);
          var element8 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element7,1,1);
          morphs[1] = dom.createMorphAt(element7,2,2);
          morphs[2] = dom.createMorphAt(element7,3,3);
          morphs[3] = dom.createAttrMorph(element8, 'class');
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.avatar",["loc",[null,[42,22],[42,34]]]]],[],0,null,["loc",[null,[42,16],[44,23]]]],
          ["block","if",[["get","model.company.avatar",["loc",[null,[45,22],[45,42]]]]],[],1,null,["loc",[null,[45,16],[47,23]]]],
          ["block","if",[["get","model.showroom1.company.avatar",["loc",[null,[48,22],[48,52]]]]],[],2,null,["loc",[null,[48,16],[50,23]]]],
          ["attribute","class",["concat",["preview ",["subexpr","if",[["get","imageCropperModalIsActive",["loc",[null,[55,39],[55,64]]]],"isPotatoe","noPotatoe"],[],["loc",[null,[55,34],[55,90]]]]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 10
            },
            "end": {
              "line": 68,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn_primary");
          dom.setAttribute(el1,"data-dismiss","modal");
          var el2 = dom.createTextNode("Save picture");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["element","action",["newUploadPicture","user-avatar","user",["get","model",["loc",[null,[67,89],[67,94]]]]],[],["loc",[null,[67,40],[67,96]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 10
              },
              "end": {
                "line": 70,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn_primary");
            dom.setAttribute(el1,"data-dismiss","modal");
            var el2 = dom.createTextNode("Save picture");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [
            ["element","action",["newUploadPicture","user-avatar","user",["get","model",["loc",[null,[69,89],[69,94]]]]],[],["loc",[null,[69,40],[69,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 10
                },
                "end": {
                  "line": 72,
                  "column": 10
                }
              },
              "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","btn_primary");
              dom.setAttribute(el1,"data-dismiss","modal");
              var el2 = dom.createTextNode("Save picture");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [
              ["element","action",["newUploadPicture","user-avatar","user",["get","model.company",["loc",[null,[71,89],[71,102]]]]],[],["loc",[null,[71,40],[71,104]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 10
                },
                "end": {
                  "line": 74,
                  "column": 10
                }
              },
              "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","btn_primary");
              dom.setAttribute(el1,"data-dismiss","modal");
              var el2 = dom.createTextNode("Save picture");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["newUploadPicture","user-avatar","user",["get","model",["loc",[null,[73,89],[73,94]]]]],[],["loc",[null,[73,40],[73,96]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 10
              },
              "end": {
                "line": 74,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","if-equal",[["get","activeSettingsTab",["loc",[null,[70,30],[70,47]]]],"showroom"],[],["loc",[null,[70,20],[70,59]]]]],[],0,1,["loc",[null,[70,10],[74,10]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 10
            },
            "end": {
              "line": 74,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","if-equal",[["get","activeSettingsTab",["loc",[null,[68,30],[68,47]]]],"agency"],[],["loc",[null,[68,20],[68,57]]]]],[],0,1,["loc",[null,[68,10],[74,10]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/settings/image-avatar-cropper.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Image avatar crooper ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade image_cropper");
        dom.setAttribute(el1,"id","image_avatar_cropper");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header clearfix");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title");
        var el6 = dom.createTextNode("Add/edit avatar picture");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","import-alert");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","color_primary");
        var el7 = dom.createTextNode("To ensure good picture quality, please upload images with high resolution.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Select image file (Only images with jpg or png format will be accepted.)");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","edit-link select_image");
        dom.setAttribute(el7,"id","user-avatar");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("input");
        dom.setAttribute(el8,"type","file");
        dom.setAttribute(el8,"class","upload");
        dom.setAttribute(el8,"accept","image/png, image/jpeg");
        dom.setAttribute(el8,"id","user-avatar-input");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n           ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Use the square to select the image area desired, you can use mouse wheel to increase or decrease the image size.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Once you have decided the image size click on \"Save picture\"");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 no-padding");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","btn_cancel");
        dom.setAttribute(el6,"data-dismiss","modal");
        var el7 = dom.createTextNode("Cancel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [2, 1, 1]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [3, 1, 4]);
        var element12 = dom.childAt(element9, [5, 1]);
        var element13 = dom.childAt(element12, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element11);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [5]),1,1);
        morphs[2] = dom.createElementMorph(element13);
        morphs[3] = dom.createMorphAt(element12,3,3);
        return morphs;
      },
      statements: [
        ["element","action",["setInputEvents","user-avatar",["get","model",["loc",[null,[18,57],[18,62]]]]],["preventDefault",false],["loc",[null,[18,17],[18,85]]]],
        ["block","image-cropper",[],["minContainerWidth",400,"minContainerHeight",500,"autoCrop",true,"aspectRatio",true,"minCropBoxWidth",140,"minCropBoxHeight",140,"checkCrossOrigin",false,"dragMode","move","previewClass",".preview","cropperContainer",".cropper-container > img"],0,null,["loc",[null,[27,10],[58,28]]]],
        ["element","action",["cancelUploadPicture","user-avatar",["get","model",["loc",[null,[65,82],[65,87]]]]],[],["loc",[null,[65,37],[65,89]]]],
        ["block","if",[["subexpr","if-equal",[["get","activeSettingsTab",["loc",[null,[66,26],[66,43]]]],"brand"],[],["loc",[null,[66,16],[66,52]]]]],[],1,2,["loc",[null,[66,10],[74,17]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});