define('bookalook/templates/bookings/modals/new-manual-contact-editing-booking', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 10
            },
            "end": {
              "line": 171,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/modals/new-manual-contact-editing-booking.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","gray-font form-fields-set gray-fieldset");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","gray-font");
          dom.setAttribute(el2,"data-toggle","collapse");
          dom.setAttribute(el2,"data-parent","#new-refactor-manual-contact");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","images/forward.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label main_address_label");
          var el5 = dom.createTextNode("Additional address ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","remove-address");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"type","button");
          dom.setAttribute(el5,"class","btn_bal_circle_form close_btn");
          var el6 = dom.createTextNode("\n                      ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","icon");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","collapse");
          dom.setAttribute(el2,"style","width: 100%;");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-group validated-select col-xs-12 no-padding multiple-select-contact");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Search member");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("form");
          dom.setAttribute(el4,"autocomplete","off");
          dom.setAttribute(el4,"id","no-autocomplete-copy-from-existing");
          dom.setAttribute(el4,"onsubmit","return false;");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","address-contact-fieldset");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Address 1");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Address 2");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Address 3");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Country");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("City");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Post code");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Phone number 1");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Phone number 2");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element3, [5, 1]);
          var element5 = dom.childAt(element1, [3]);
          var element6 = dom.childAt(element5, [3]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element6, [3]);
          var morphs = new Array(13);
          morphs[0] = dom.createAttrMorph(element2, 'data-target');
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          morphs[2] = dom.createElementMorph(element4);
          morphs[3] = dom.createAttrMorph(element5, 'id');
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [1, 3]),1,1);
          morphs[5] = dom.createMorphAt(element7,3,3);
          morphs[6] = dom.createMorphAt(element7,7,7);
          morphs[7] = dom.createMorphAt(element7,11,11);
          morphs[8] = dom.createMorphAt(element7,15,15);
          morphs[9] = dom.createMorphAt(element8,3,3);
          morphs[10] = dom.createMorphAt(element8,7,7);
          morphs[11] = dom.createMorphAt(element8,11,11);
          morphs[12] = dom.createMorphAt(element8,15,15);
          return morphs;
        },
        statements: [
          ["attribute","data-target",["concat",["#address_container_",["subexpr","plus-one",[["get","index",["loc",[null,[110,103],[110,108]]]]],[],["loc",[null,[110,92],[110,110]]]]]]],
          ["inline","plus-one",[["get","index",["loc",[null,[113,93],[113,98]]]]],[],["loc",[null,[113,82],[113,100]]]],
          ["element","action",["removeAddress",["get","address",["loc",[null,[115,68],[115,75]]]]],[],["loc",[null,[115,42],[115,77]]]],
          ["attribute","id",["concat",["address_container_",["subexpr","plus-one",[["get","index",["loc",[null,[121,52],[121,57]]]]],[],["loc",[null,[121,41],[121,59]]]]]]],
          ["inline","select-2",[],["placeholder","Copy address from existing contact (by Name, Company or Address field)","value",["subexpr","@mut",[["get","address.listSelector",["loc",[null,[127,28],[127,48]]]]],[],[]],"content",["subexpr","@mut",[["get","contactsAddresses",["loc",[null,[128,30],[128,47]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","address","enabled",["subexpr","if-true-and-false",[["get","cAddressesListNotLoading",["loc",[null,[131,50],[131,74]]]],["get","cAddressesListHide",["loc",[null,[131,75],[131,93]]]]],[],["loc",[null,[131,31],[131,94]]]]],["loc",[null,[125,20],[132,22]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[138,44],[138,51]]]]],[],[]],"property","street1","class","no-padding-left hint-left col-xs-12"],["loc",[null,[138,20],[138,116]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[140,44],[140,51]]]]],[],[]],"property","street2","class","no-padding-left col-xs-12"],["loc",[null,[140,20],[140,106]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[142,44],[142,51]]]]],[],[]],"property","street3","class","no-padding-left col-xs-12"],["loc",[null,[142,20],[142,106]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","countriesAvailable",["loc",[null,[145,42],[145,60]]]]],[],[]],"model",["subexpr","@mut",[["get","address",["loc",[null,[146,42],[146,49]]]]],[],[]],"property","country","class","col-xs-12 hint-right no-padding-right","optionValuePath","name","optionLabelPath","name"],["loc",[null,[144,20],[151,22]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[159,44],[159,51]]]]],[],[]],"property","city","class","no-padding-left hint-right col-xs-12"],["loc",[null,[159,20],[159,114]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[161,44],[161,51]]]]],[],[]],"property","zipCode","class","no-padding-left hint-right col-xs-12"],["loc",[null,[161,20],[161,117]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[163,44],[163,51]]]]],[],[]],"property","telephone1","class","no-padding-left col-xs-12"],["loc",[null,[163,20],[163,109]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[165,44],[165,51]]]]],[],[]],"property","telephone2","class","no-padding-left col-xs-12"],["loc",[null,[165,20],[165,109]]]]
        ],
        locals: ["address","index"],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 201,
              "column": 10
            },
            "end": {
              "line": 203,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/modals/new-manual-contact-editing-booking.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          dom.setAttribute(el1,"disabled","");
          dom.setAttribute(el1,"class","btn_bal_primary pull-right");
          var el2 = dom.createTextNode("Create contact");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 203,
              "column": 10
            },
            "end": {
              "line": 205,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/modals/new-manual-contact-editing-booking.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          dom.setAttribute(el1,"class","btn_bal_primary pull-right");
          dom.setAttribute(el1,"data-dismiss","modal");
          var el2 = dom.createTextNode("Create contact");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["saveNewContactRefactor","#edit-booking-addresses"],[],["loc",[null,[204,34],[204,96]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 217,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/modals/new-manual-contact-editing-booking.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New MANUAL contact for booking ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade bal-modal-mini with-columns");
        dom.setAttribute(el1,"id","new-manual-contact-editing-booking");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-header");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","modal-title");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("NEW");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("strong");
        var el8 = dom.createTextNode("CONTACT");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("hr");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-body");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("Email address*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("Add to list(s)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 form-group validated-select last-input multiple-select-contact-list-new-contact");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font form-fields-set");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("First name*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label input_label_margin_0");
        var el9 = dom.createTextNode("Job title*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("Last name*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label input_label_margin_0");
        var el9 = dom.createTextNode("Company*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","icon_new_address");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","add-address-btn-img pointer");
        dom.setAttribute(el7,"src","images/new_address.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font form-fields-set gray-fieldset");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","gray-font");
        dom.setAttribute(el7,"data-toggle","collapse");
        dom.setAttribute(el7,"data-target","#address_container");
        dom.setAttribute(el7,"data-parent","#new-refactor-manual-contact");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("img");
        dom.setAttribute(el9,"src","images/forward.svg");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","input_label main_address_label");
        var el10 = dom.createTextNode("Main address");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","address_container");
        dom.setAttribute(el7,"class","collapse in");
        dom.setAttribute(el7,"style","width: 100%;");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-group validated-select col-xs-12 no-padding multiple-select-contact");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label");
        var el11 = dom.createTextNode("Search member");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("form");
        dom.setAttribute(el9,"autocomplete","off");
        dom.setAttribute(el9,"id","no-autocomplete-copy-address");
        dom.setAttribute(el9,"onsubmit","return false;");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","address-contact-fieldset");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label");
        var el11 = dom.createTextNode("Address 1");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Address 2");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Address 3");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Country");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label");
        var el11 = dom.createTextNode("City");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Post code");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Phone number 1");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Phone number 2");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n        <div class=\"gray-font\">\n          <div>\n            <span class=\"input_label\">Website</span>\n            {{validated-input    model=contactToAdd property='email' class='col-xs-12 hint-left no-padding-left '}}\n          </div>\n          <div>\n            <span class=\"input_label\">Instagram</span>\n            {{validated-input    model=contactToAdd property='email' class='col-xs-12 hint-left no-padding-left '}}\n            <span class=\"input_label input_label_margin_0\">Facebook</span>\n            {{validated-input    model=contactToAdd property='email' class='col-xs-12 hint-left no-padding-left '}}\n          </div>\n        </div>\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("VAT (OPTIONAL)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-invite-join");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Invite contact to join Bookalook");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-footer");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn_bal_cancel pull-left");
        dom.setAttribute(el6,"data-dismiss","modal");
        dom.setAttribute(el6,"data-target","#edit-booking-addresses");
        dom.setAttribute(el6,"data-toggle","modal");
        var el7 = dom.createTextNode("Cancel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $myGroup = $('#new-refactor-manual-contact');\n  $myGroup.on('show.bs.collapse','.collapse', function() {\n    $('#new-refactor-manual-contact').find('.collapse.in').collapse('hide');\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [2, 1, 1, 1]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element10, [5, 1]);
        var element16 = dom.childAt(element10, [7, 3]);
        var element17 = dom.childAt(element16, [3]);
        var element18 = dom.childAt(element17, [1]);
        var element19 = dom.childAt(element17, [3]);
        var element20 = dom.childAt(element10, [13]);
        var morphs = new Array(20);
        morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(element11, [3, 3]),1,1);
        morphs[2] = dom.createMorphAt(element13,3,3);
        morphs[3] = dom.createMorphAt(element13,7,7);
        morphs[4] = dom.createMorphAt(element14,3,3);
        morphs[5] = dom.createMorphAt(element14,7,7);
        morphs[6] = dom.createElementMorph(element15);
        morphs[7] = dom.createMorphAt(dom.childAt(element16, [1, 3]),1,1);
        morphs[8] = dom.createMorphAt(element18,3,3);
        morphs[9] = dom.createMorphAt(element18,7,7);
        morphs[10] = dom.createMorphAt(element18,11,11);
        morphs[11] = dom.createMorphAt(element18,15,15);
        morphs[12] = dom.createMorphAt(element19,3,3);
        morphs[13] = dom.createMorphAt(element19,7,7);
        morphs[14] = dom.createMorphAt(element19,11,11);
        morphs[15] = dom.createMorphAt(element19,15,15);
        morphs[16] = dom.createMorphAt(element10,9,9);
        morphs[17] = dom.createMorphAt(dom.childAt(element20, [1]),3,3);
        morphs[18] = dom.createMorphAt(dom.childAt(element20, [3, 1]),0,0);
        morphs[19] = dom.createMorphAt(dom.childAt(element9, [5]),3,3);
        return morphs;
      },
      statements: [
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[18,39],[18,51]]]]],[],[]],"property","email","class","email-contact col-xs-12 hint-left no-padding-left "],["loc",[null,[18,14],[18,129]]]],
        ["inline","multiple-select",[],["list",["subexpr","@mut",[["get","addContactSelectedLists",["loc",[null,[24,23],[24,46]]]]],[],[]],"content",["subexpr","@mut",[["get","lists",["loc",[null,[25,26],[25,31]]]]],[],[]],"placeholder","Select Lists","optionLabelPath","name"],["loc",[null,[23,16],[28,18]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[36,41],[36,53]]]]],[],[]],"property","name","class","col-xs-12 hint-left no-padding-left "],["loc",[null,[36,14],[36,116]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[38,39],[38,51]]]]],[],[]],"property","jobTitle","class","col-xs-12 hint-left no-padding-left"],["loc",[null,[38,14],[38,117]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[42,39],[42,51]]]]],[],[]],"property","surname","class","col-xs-12 hint-right no-padding-right"],["loc",[null,[42,14],[42,118]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[44,39],[44,51]]]]],[],[]],"property","company","class","col-xs-12 hint-right no-padding-right"],["loc",[null,[44,14],[44,118]]]],
        ["element","action",["addAnotherAddress"],[],["loc",[null,[49,17],[49,47]]]],
        ["inline","select-2",[],["placeholder","Copy address from existing contact (by Name, Company or Address field)","value",["subexpr","@mut",[["get","contactToAddAddress.listSelector",["loc",[null,[65,26],[65,58]]]]],[],[]],"content",["subexpr","@mut",[["get","contactsAddresses",["loc",[null,[66,28],[66,45]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","address","enabled",["subexpr","if-true-and-false",[["get","cAddressesListNotLoading",["loc",[null,[69,48],[69,72]]]],["get","cAddressesListHide",["loc",[null,[69,73],[69,91]]]]],[],["loc",[null,[69,29],[69,92]]]]],["loc",[null,[63,18],[70,20]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[76,42],[76,61]]]]],[],[]],"property","street1","class","no-padding-left hint-left col-xs-12"],["loc",[null,[76,18],[76,126]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[78,42],[78,61]]]]],[],[]],"property","street2","class","no-padding-left col-xs-12"],["loc",[null,[78,18],[78,116]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[80,42],[80,61]]]]],[],[]],"property","street3","class","no-padding-left col-xs-12"],["loc",[null,[80,18],[80,116]]]],
        ["inline","validated-select",[],["content",["subexpr","@mut",[["get","countriesAvailable",["loc",[null,[83,40],[83,58]]]]],[],[]],"model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[84,40],[84,59]]]]],[],[]],"property","country","class","col-xs-12 hint-right no-padding-right","optionValuePath","name","optionLabelPath","name"],["loc",[null,[82,18],[89,20]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[93,42],[93,61]]]]],[],[]],"property","city","class","no-padding-left hint-right col-xs-12"],["loc",[null,[93,18],[93,124]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[95,42],[95,61]]]]],[],[]],"property","zipCode","class","no-padding-left hint-right col-xs-12"],["loc",[null,[95,18],[95,127]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[97,42],[97,61]]]]],[],[]],"property","telephone1","class","no-padding-left col-xs-12"],["loc",[null,[97,18],[97,119]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[99,42],[99,61]]]]],[],[]],"property","telephone2","class","no-padding-left col-xs-12"],["loc",[null,[99,18],[99,119]]]],
        ["block","each",[["get","contactToAdd.privateAddresses",["loc",[null,[108,18],[108,47]]]]],[],0,null,["loc",[null,[108,10],[171,19]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[191,38],[191,50]]]]],[],[]],"property","vat","class","col-xs-12 hint-left no-padding-left"],["loc",[null,[191,14],[191,111]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","invite-join",["loc",[null,[194,77],[194,88]]]]],[],[]]],["loc",[null,[194,45],[194,90]]]],
        ["block","if",[["get","createNewContactDisabled",["loc",[null,[201,16],[201,40]]]]],[],1,2,["loc",[null,[201,10],[205,17]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});