define('bookalook/models/areas-influence', ['exports', 'ember-data', 'bookalook/utils', 'bookalook/mixins/model-validator'], function (exports, DS, utils, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    countries: DS['default'].hasMany('country', { async: true, inverse: null }),
    brand: DS['default'].belongsTo('brand', { async: true }),
    user: DS['default'].belongsTo('user', { async: true }),

    isBrand: function isBrand(brand) {
      return this.get("brand.id") === brand.get("id");
    },

    countriesCollapse: (function () {
      return utils['default'].buildCountriesAccordion(this.get('countries'));
    }).property('countries.@each'),

    hasCountry: function hasCountry(country) {
      return this.get('countries').some(function (c) {
        return c.get('name') === country.get('name');
      });
    },

    countriesString: (function () {
      return this.get('countries').mapBy('name').join(' - ');
    }).property('countries.@each', 'user.restOfTheWorld.[]'),

    firstFiveCountries: (function () {
      return this.get('countries').slice(0, 5);
    }).property('countries.@each'),

    restOfCountries: (function () {
      var countries = this.get('countries');
      return countries.slice(5, countries.get('length') - 1);
    }).property('countries.@each')
  });

});