define('bookalook/tests/routes/members/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/members/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/members/index.js should pass jshint.\nroutes/members/index.js: line 254, col 75, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (route, user)\n\n1 error');
  });

});