define('bookalook/tests/routes/barcodes/functions.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/barcodes/functions.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'routes/barcodes/functions.js should pass jshint.');
  });

});