define('bookalook/helpers/ddt-example', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var ddtNumber = params[0];
    if (ddtNumber) {
      ddtNumber = ddtNumber.replace(/\%i/g, '23');
      ddtNumber = ddtNumber.replace(/\%y/g, new Date().getFullYear());
    }
    return ddtNumber || '23';
  });

});