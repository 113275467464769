define('bookalook/tests/routes/application.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/application.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/application.js should pass jshint.\nroutes/application.js: line 431, col 52, Missing semicolon.\nroutes/application.js: line 468, col 54, Missing semicolon.\nroutes/application.js: line 590, col 7, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\nroutes/application.js: line 922, col 13, \'files\' is already defined.\nroutes/application.js: line 957, col 11, \'input\' is already defined.\nroutes/application.js: line 976, col 50, Missing semicolon.\nroutes/application.js: line 1004, col 52, Missing semicolon.\nroutes/application.js: line 962, col 8, \'input\' used out of scope.\nroutes/application.js: line 1402, col 11, \'input\' is already defined.\nroutes/application.js: line 1412, col 26, \'input\' used out of scope.\nroutes/application.js: line 1558, col 6, Unnecessary semicolon.\nroutes/application.js: line 1593, col 54, Regular parameters should not come after default parameters.\nroutes/application.js: line 1608, col 66, Regular parameters should not come after default parameters.\nroutes/application.js: line 1769, col 74, Missing semicolon.\nroutes/application.js: line 2643, col 5, Unreachable \'let\' after \'return\'.\nroutes/application.js: line 2677, col 87, Missing semicolon.\nroutes/application.js: line 2681, col 88, Missing semicolon.\n\n17 errors');
  });

});