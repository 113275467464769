define('bookalook/tests/components/add-samples-loan-form.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components/add-samples-loan-form.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'components/add-samples-loan-form.js should pass jshint.\ncomponents/add-samples-loan-form.js: line 53, col 40, [\'all\'] is better written in dot notation.\ncomponents/add-samples-loan-form.js: line 132, col 58, Missing semicolon.\n\n2 errors');
  });

});