define('bookalook/controllers/search/looks', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    queryParams: ['departments', 'brands', 'collectionTypes'],
    departments: null,
    brands: null,
    collectionTypes: null
  });
  /*actions: {
    changeUrlQuery: function() {
       //assigning value to the 'departments' i.e. query parameter
      this.set('departments', "asdasdasd");
    }
  }*/

});