define('bookalook/tests/models/look.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/look.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'models/look.js should pass jshint.\nmodels/look.js: line 185, col 38, [\'all\'] is better written in dot notation.\nmodels/look.js: line 199, col 38, [\'all\'] is better written in dot notation.\nmodels/look.js: line 213, col 38, [\'all\'] is better written in dot notation.\nmodels/look.js: line 234, col 38, [\'all\'] is better written in dot notation.\nmodels/look.js: line 248, col 38, [\'all\'] is better written in dot notation.\nmodels/look.js: line 264, col 38, [\'all\'] is better written in dot notation.\nmodels/look.js: line 390, col 7, Unreachable \'return\' after \'return\'.\nmodels/look.js: line 391, col 50, Missing semicolon.\n\n8 errors');
  });

});