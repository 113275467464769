define('bookalook/routes/barcodes/scan', ['exports', 'ember', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/barcodes/functions'], function (exports, Ember, utils, UserValidatorMixin, scanFunctions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    actions: {
      startScanning: function startScanning() {
        scanFunctions['default'].onStartScanning(this);
      },
      confirmActions: function confirmActions(e) {
        scanFunctions['default'].onConfirmActions(this);
      },
      cancelSelection: function cancelSelection() {
        scanFunctions['default'].onCancelSelection(this);
      },
      processResponse: function processResponse(response) {
        scanFunctions['default'].onProcessResponse(this, response);
      },
      showResponse: function showResponse(checkin, checkout) {
        scanFunctions['default'].onShowResponse(this, checkin, checkout);
      },
      backToInventory: function backToInventory() {
        this.onBackToInventory();
      },
      refreshCounter: function refreshCounter() {
        var confirmed = Ember['default'].$('.table').find('.chosenSamples:checked');
        this.controller.set('confirmed', confirmed.length);
      }
    },
    model: function model() {},
    setupController: function setupController() {
      this.controller.set('confirmed', '0');
      var _this = this;
      this.controller.samplesToCheck = Ember['default'].computed('barcodes.@each', function () {
        var rows = _this.controller.get('barcodes');
        _this.controller.set('barcodes', []);
        return rows;
      });
    },
    deactivate: function deactivate() {
      // $(document).off();
      // $('#barcode-input').off();
      scanFunctions['default'].onCancelSelection(this);
      this.controller.set('checkinResults', '');
      this.controller.set('checkoutResults', '');
    }

  });

});