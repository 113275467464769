define('bookalook/tests/static-tables-data.jshint', function () {

  'use strict';

  QUnit.module('JSHint - static-tables-data.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'static-tables-data.js should pass jshint.');
  });

});