define('bookalook/components/notifications-button', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    /*  - Attributes
     *   · IMAGESRC
     *   ·
     */
    tagName: 'ul',
    classNames: ['list-inline', 'pull-right'],
    notifications: [],
    messageNotifications: [],

    notificationsTransitions: {
      'Job': { route: 'jobs.shopping-list' },
      'Collection': { route: 'collections.collection' },
      'Album': { route: 'collections.album' },
      //'BookingShoppingList': {route: 'bookings.shopping-list'},
      'BookingShoppingList': { route: 'bookings.booking-details' },
      //'BookingDetails'     : {route: 'bookings.booking-details'},
      'BookingDetails': { route: 'bookings.booking-details' },
      //'BookingOverview'    : {route: 'bookings.booking-overview'},
      'BookingOverview': { route: 'bookings.booking-details' },
      //'ContactsList'       : {route: 'contacts-lists.contacts-lists'},
      'ContactsList': { route: 'contacts-lists.index' },
      'Conversation': { route: 'messages.index' },
      //'MyShowroom'	     : {route: 'settings.showroom'},
      'MyShowroom': { route: 'settings.account-settings' },
      'MessageInbox': { route: 'messages.index' },
      'Friend': { route: 'messages.index' },
      'AlbumDirectory': { route: 'profile.album-profile' },
      'CollectionDirectory': { route: 'profile.collection-profile' },
      'JobLoanForm': { route: 'jobs.form-view' }
    },

    //<ACTIONS>
    actions: {
      markAsRead: function markAsRead(notification) {
        Ember['default'].set(notification, 'isRead', true);
        utils['default'].putAPIJSON('notifications/read/' + notification.id);
        this._refreshNewNotificationsCounter();
        this.doNotificationAction(notification);
      },
      markAllMessagesAsRead: function markAllMessagesAsRead(notifications) {
        notifications.setEach('isRead', true);
        utils['default'].putAPIJSON('notifications/read/messages');
        this._refreshNewNotificationsCounter();
      },
      markAllNotificationsAsRead: function markAllNotificationsAsRead(notifications) {
        notifications.setEach('isRead', true);
        utils['default'].putAPIJSON('notifications/read/notifications');
        this._refreshNewNotificationsCounter();
      }
    },
    //</ACTIONS>

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._pullNotifications();

      setInterval(function () {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) _this._pullNotifications();
      }, 60000);
    },

    doNotificationAction: function doNotificationAction(notification) {
      var mainType = notification.notificationType.split(' - ')[0];
      var transition = this.get('notificationsTransitions')[mainType];
      var currentRoute = this.get('router.url');

      if (transition) {
        this.get('router').transitionTo(transition.route, notification.entityId).then(function () {
          checkTransition(currentRoute);
        });
      } else {
        if (mainType === 'AlbumSearch') {
          this.get('router').transitionTo('search.looks', notification.entityId).then(function () {
            checkTransition(currentRoute);
          });
        } else if (mainType === 'BookingLoanForm') {
          var Ids = notification.entityId.split(' ');
          var bookingId = Ids[0];
          var loanId = Ids[1];
          this.get('router').transitionTo('bookings.loan-form', bookingId, loanId).then(function () {
            checkTransition(currentRoute);
          });
        }
      }
    },
    checkTransition: function checkTransition(currentRoute) {
      if (currentRoute === this.get('router.url')) {
        window.location.reload(true);
      }
    },

    // This is dirty code.
    _pullNotifications: function _pullNotifications() {
      var _this2 = this;

      var waitForUserIdInterval = setInterval(function () {
        if (Ember['default'].userId) {
          utils['default'].getAPIJSON('notifications/user/' + Ember['default'].userId).then(function (res) {
            if (!Ember['default'].isNone(res) && !Ember['default'].isEmpty(res)) {
              _this2._addNewNotifications(res);
              _this2._refreshNewNotificationsCounter();
            }
          });
          clearInterval(waitForUserIdInterval);
        }
      }, 500);
    },

    _addNewNotifications: function _addNewNotifications(notifications) {
      var _this3 = this;

      var currentPath = this.get('router').get('currentPath');

      notifications.forEach(function (newNotification) {
        var existingNotification = _this3._findNotification(newNotification);

        //remove seconds
        newNotification.dateTime = newNotification.date.date.slice(0, -3);

        if (existingNotification) {
          Ember['default'].setProperties(existingNotification, newNotification);
        } else {
          var notificationType = newNotification.notificationType.split(' - ');
          var notificationTransitionName = notificationType[0];
          notificationType = notificationType[notificationType.length - 1];
          if (notificationTransitionName === 'Conversation' || notificationTransitionName === 'Friend' || notificationTransitionName === 'MessageInbox') {
            if (currentPath === 'messages.index') {
              _this3._messagesPathActions(newNotification);
            } else {
              _this3.get('messageNotifications').unshiftObject(newNotification);
            }
          } else {
            newNotification.hideDate = notificationType === 'InventoryLogin';
            if (!Ember['default'].isNone(_this3.get('notifications')) && Ember['default'].isArray(_this3.get('notifications'))) _this3.get('notifications').unshiftObject(newNotification);
          }
        }
      });

      if (!Ember['default'].isNone(this.get('notifications')) && Ember['default'].isArray(this.get('notifications'))) {
        var inOrderNotifications = this._orderArrayNotifications(this.get('notifications'));
        this.set('notifications', inOrderNotifications);
      }

      if (!Ember['default'].isNone(this.get('messageNotifications')) && Ember['default'].isArray(this.get('messageNotifications'))) {
        var inOrderMessages = this._orderArrayNotifications(this.get('messageNotifications'));
        this.set('messageNotifications', inOrderMessages);
      }
    },

    _orderArrayNotifications: function _orderArrayNotifications(array) {
      return array.sort(function (n1, n2) {
        if (n1.dateTime === n2.dateTime) {
          return parseInt(n2.entityId, 10) - parseInt(n1.entityId, 10);
        } else {
          return n1.dateTime < n2.dateTime ? 1 : -1;
        }
      });
    },

    _messagesPathActions: function _messagesPathActions(notification) {
      var _this4 = this;

      var messagesController = this.get('route').controllerFor('messages.index');
      var selectedConversation = messagesController.get('selectedConversation');
      var notificationTypeArray = notification.notificationType.split(' - ');
      var notificationType = notificationTypeArray[0];
      var notificationAction = notificationTypeArray[1];
      var conversationList = messagesController.get('conversationList');
      var conversationsObj = messagesController.get('conversationsObj');

      if (notificationType === 'Conversation') {
        if (notificationAction === 'New Conversation') {
          var existingConversation = conversationsObj.find(function (conversation) {
            return parseInt(conversation.id) === parseInt(notification.entityId);
          });
          if (existingConversation) {
            Ember['default'].set(existingConversation, 'lastModified', new Date(notification.date.date).getTime() / 1000);
            Ember['default'].set(existingConversation, 'lastMessage', utils['default'].limitString(notification.extraInfo, 20));
            Ember['default'].set(notification, 'isRead', true);
            utils['default'].putAPIJSON('notifications/read/' + notification.id);
            this._refreshNewNotificationsCounter();
          } else {
            utils['default'].getAPIJSON('conversation/conversation/' + notification.entityId).then(function (conversation) {
              Ember['default'].set(conversation, 'somethingNew', true);
              conversationsObj.pushObject(conversation);
              Ember['default'].set(notification, 'isRead', true);
              utils['default'].putAPIJSON('notifications/read/' + notification.id);
              _this4._refreshNewNotificationsCounter();
            });
          }
        } else {
          if (selectedConversation && selectedConversation.id === parseInt(notification.entityId)) {
            Ember['default'].set(notification, 'isRead', true);
            utils['default'].putAPIJSON('notifications/read/' + notification.id);
            this._refreshNewNotificationsCounter();
          } else {
            messagesController.get('target').send('selectConversationById', parseInt(notification.entityId), true);
            var conversationFound = messagesController.get('auxConversationNotificationComp');
            if (conversationFound) {
              Ember['default'].set(conversationFound, 'lastModified', new Date(notification.date.date).getTime() / 1000);
              Ember['default'].set(conversationFound, 'lastMessage', utils['default'].limitString(notification.extraInfo, 20));
              Ember['default'].set(notification, 'isRead', true);
              utils['default'].putAPIJSON('notifications/read/' + notification.id);
              this._refreshNewNotificationsCounter();
              Ember['default'].set(conversationFound, 'somethingNew', true);
              messagesController.notifyPropertyChange('conversationObj');
            } else {
              utils['default'].getAPIJSON('conversation/conversation/' + notification.entityId).then(function (conversation) {
                Ember['default'].set(conversation, 'somethingNew', true);
                conversationsObj.unshiftObject(conversation);
                Ember['default'].set(notification, 'isRead', true);
                utils['default'].putAPIJSON('notifications/read/' + notification.id);
                _this4._refreshNewNotificationsCounter();
              });
            }
          }
        }
      } else {
        (function () {
          // Friend notification. Must update conversation with friendship false
          var idUser = parseInt(notification.entityId);
          var conversationToUpdate = conversationList.find(function (conversation) {
            return conversation.users[0].friend === false && parseInt(conversation.users[0].id) === parseInt(idUser);
          });

          Ember['default'].set(conversationToUpdate, 'users.0.friend', true);
          Ember['default'].set(notification, 'isRead', true);
          utils['default'].putAPIJSON('notifications/read/' + notification.id);

          if (conversationToUpdate.id === selectedConversation.id) {
            messagesController.get('target').send('getMessages', conversationToUpdate);
          } else {
            Ember['default'].set(conversationToUpdate, 'somethingNew', true);
          }
        })();
      }
    },

    _findNotification: function _findNotification(notification) {
      var notificationType = notification.notificationType.split(' - ')[0];

      if (notificationType === 'Conversation' || notificationType === 'Friend' || notificationType === 'MessageInbox') {
        if (!Ember['default'].isNone(this.get('messageNotifications'))) {
          return this.get('messageNotifications').find(function (n) {
            return n.id === notification.id;
          });
        } else {
          return null;
        }
      } else {
        if (!Ember['default'].isNone(this.get('notifications'))) {
          return this.get('notifications').find(function (n) {
            return n.id === notification.id;
          });
        } else {
          return null;
        }
      }
    },

    _refreshNewNotificationsCounter: function _refreshNewNotificationsCounter() {
      if (this && !(this.get('isDestroyed') || this.get('isDestroying'))) {
        if (Ember['default'].isNone(this.get('notifications'))) {
          this.set('newNotificationsCounter', 0);
        } else {
          var counter = this.get('notifications').filterBy('isRead', false).length;
          this.set('newNotificationsCounter', counter);
        }
        if (Ember['default'].isNone(this.get('messageNotifications'))) {
          this.set('newMessagesCounter', 0);
        } else {
          var messageCounter = this.get('messageNotifications').filterBy('isRead', false).length;
          this.set('newMessagesCounter', messageCounter);
        }
      }
    }
  });

});