define('bookalook/components/inline-text-edition', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['inline_edition'],
    classNameBindings: ['is-dirty'],

    'is-dirty': false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', '-');
      }
      this.set('initialValue', this.get('value'));
    },

    click: function click() {
      if (Ember['default'].user.get('isPR')) {
        var $el = Ember['default'].$(this.element);
        if (!$el.hasClass('edit_mode')) {
          $el.fadeOut(function () {
            return $el.addClass('edit_mode');
          });
          $el.fadeIn(function () {
            return $el.find('.inline_input').get(0).select();
          });
        }
      }
    },

    focusOut: function focusOut() {
      var _this = this;

      var $el = Ember['default'].$(this.element);
      $el.fadeOut(function () {
        $el.removeClass('edit_mode');
        _this.set('is-dirty', _this.get('initialValue') !== _this.get('value'));
        $el.fadeIn();
      });
    }

  });

});