define('bookalook/tests/helpers/test-helpers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports._then = _then;
  exports._given = _given;
  exports._when = _when;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var thenRegExps = [];

  function _then(regExp, callback) {
    thenRegExps.push({ regExp: regExp, callback: callback });
  }

  Ember['default'].Test.registerHelper('_thenHelper', function (app, str) {
    var assert = Ember['default'].assertHelper;
    var assertStr = 'Then ' + str;
    var hasRegExp = false;
    thenRegExps.forEach(function (re) {
      if (re.regExp.test(str)) {
        var args = str.match(re.regExp);
        args.shift();
        re.callback.apply(re, _toConsumableArray(args).concat([assert, assertStr]));
        hasRegExp = true;
      }
    });

    if (!hasRegExp) {
      assert.ok(false, 'Rule undefined:' + assertStr);
    }
  });

  var givenRegExps = [];

  function _given(regExp, callback) {
    givenRegExps.push({ regExp: regExp, callback: callback });
  }

  Ember['default'].Test.registerHelper('_givenHelper', function (app, str) {
    var assert = Ember['default'].assertHelper;
    var assertStr = 'Given ' + str;
    var hasRegExp = false;
    givenRegExps.forEach(function (re) {
      if (re.regExp.test(str)) {
        var args = str.match(re.regExp);
        args.shift();
        re.callback.apply(re, _toConsumableArray(args).concat([assert, assertStr]));
        hasRegExp = true;
      }
    });

    if (!hasRegExp) {
      assert.ok(false, 'Rule undefined:' + assertStr);
    }
  });

  var whenRegExps = [];

  function _when(regExp, callback) {
    whenRegExps.push({ regExp: regExp, callback: callback });
  }

  Ember['default'].Test.registerHelper('_whenHelper', function (app, str) {
    var assert = Ember['default'].assertHelper;
    var assertStr = 'When ' + str;
    var hasRegExp = false;
    whenRegExps.forEach(function (re) {
      if (re.regExp.test(str)) {
        var args = str.match(re.regExp);
        args.shift();
        re.callback.apply(re, _toConsumableArray(args).concat([assert, assertStr]));
        hasRegExp = true;
      }
    });

    if (!hasRegExp) {
      assert.ok(false, 'Rule undefined:' + assertStr);
    }
  });

});