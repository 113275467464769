define('bookalook/tests/helpers/collection-search-string.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/collection-search-string.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'helpers/collection-search-string.js should pass jshint.\nhelpers/collection-search-string.js: line 55, col 17, Missing semicolon.\n\n1 error');
  });

});