define('bookalook/templates/global/modals/look-overview', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 10
            },
            "end": {
              "line": 11,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"data-dismiss","modal");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","lookToView.collection.brand.name",["loc",[null,[10,39],[10,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 16
                  },
                  "end": {
                    "line": 27,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","look-position");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element23 = dom.childAt(fragment, [1]);
                var element24 = dom.childAt(element23, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element23, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
                morphs[2] = dom.createAttrMorph(element24, 'src');
                morphs[3] = dom.createElementMorph(element24);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["album ",["subexpr","if",[["subexpr","if-equal",[["get","look.id",["loc",[null,[23,51],[23,58]]]],["get","lookToView.id",["loc",[null,[23,59],[23,72]]]]],[],["loc",[null,[23,41],[23,73]]]],"selected"],[],["loc",[null,[23,36],[23,86]]]]]]],
                ["content","look.position",["loc",[null,[24,48],[24,65]]]],
                ["attribute","src",["concat",[["get","look.avatars.0.thumbnail",["loc",[null,[25,32],[25,56]]]]]]],
                ["element","action",["setLookToView",["get","look",["loc",[null,[25,85],[25,89]]]]],[],["loc",[null,[25,60],[25,91]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 14
                },
                "end": {
                  "line": 28,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","look.hasActiveSamples",["loc",[null,[22,22],[22,43]]]]],[],0,null,["loc",[null,[22,16],[27,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 29,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","look.isAvatarDefault",["loc",[null,[21,24],[21,44]]]]],[],0,null,["loc",[null,[21,14],[28,25]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 10
            },
            "end": {
              "line": 30,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 3,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","look.archived",["loc",[null,[20,22],[20,35]]]]],[],0,null,["loc",[null,[20,12],[29,23]]]]
        ],
        locals: ["look","as","lookIndex"],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 12
              },
              "end": {
                "line": 46,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"type","button");
            dom.setAttribute(el1,"class","look-avatar-overlay btn_bal_circle_no_border no_color zoom_btn");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 10
            },
            "end": {
              "line": 47,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","light-box",[],["href",["subexpr","@mut",[["get","lookToView.currentAvatarToView.avatar",["loc",[null,[36,21],[36,58]]]]],[],[]],"lazyload-src","images/default-image.png","lazyloadActive",true,"data-lightbox","overview-look-avatars","data-title",["subexpr","@mut",[["get","lookToView.publicDescription",["loc",[null,[40,31],[40,59]]]]],[],[]],"data-class","image_zoom lazyload"],0,null,["loc",[null,[35,12],[46,26]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 14
            },
            "end": {
              "line": 55,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          dom.setAttribute(el1,"class","btn_bal_circle_no_border no_color download_circle_btn");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element22);
          return morphs;
        },
        statements: [
          ["element","action",["downloadOverviewLookAvatar"],[],["loc",[null,[52,100],[52,139]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 14
            },
            "end": {
              "line": 59,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          dom.setAttribute(el1,"class","btn_bal_circle_no_border no_color download_circle_btn");
          dom.setAttribute(el1,"disabled","");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 12
            },
            "end": {
              "line": 66,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","arrows");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","arrow_left");
          dom.setAttribute(el2,"src","images/arrow-back-thin.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","arrow_right");
          dom.setAttribute(el2,"src","images/arrow-back-thin.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element19, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element20);
          morphs[1] = dom.createElementMorph(element21);
          return morphs;
        },
        statements: [
          ["element","action",["slideCurrentAvatarToView","prev"],[],["loc",[null,[63,73],[63,117]]]],
          ["element","action",["slideCurrentAvatarToView","next"],[],["loc",[null,[64,74],[64,118]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 14
              },
              "end": {
                "line": 74,
                "column": 14
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element18, 'class');
            morphs[1] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["subexpr","if-equal",[["get","avatarIndex",["loc",[null,[73,43],[73,54]]]],["get","lookToView.currentAvatarToView.position",["loc",[null,[73,55],[73,94]]]]],[],["loc",[null,[73,33],[73,95]]]],"active"],[],["loc",[null,[73,28],[73,106]]]]]]],
            ["element","action",["setCurrentAvatarToView",["get","avatar",["loc",[null,[73,142],[73,148]]]],["get","avatarIndex",["loc",[null,[73,149],[73,160]]]]],[],["loc",[null,[73,108],[73,162]]]]
          ],
          locals: ["avatar","avatarIndex"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 10
            },
            "end": {
              "line": 76,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","carousel-buttons");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","lookToView.avatars",["loc",[null,[72,22],[72,40]]]]],[],0,null,["loc",[null,[72,14],[74,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 96,
                "column": 28
              },
              "end": {
                "line": 98,
                "column": 28
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","sample.colour1URL",["loc",[null,[97,42],[97,59]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 99,
                "column": 28
              },
              "end": {
                "line": 101,
                "column": 28
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element5, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","sample.colour2URL",["loc",[null,[100,42],[100,59]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 28
              },
              "end": {
                "line": 104,
                "column": 28
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element4, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","sample.colour3URL",["loc",[null,[103,42],[103,59]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 40
                  },
                  "end": {
                    "line": 133,
                    "column": 40
                  }
                },
                "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","job");
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","images/tick-green-circle.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1, 3]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element3);
                morphs[1] = dom.createMorphAt(element3,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["goToLink","jobs.view",["get","job.id",["loc",[null,[131,82],[131,88]]]],"#look-overview"],[],["loc",[null,[131,50],[131,107]]]],
                ["content","job.name",["loc",[null,[131,108],[131,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 133,
                    "column": 40
                  },
                  "end": {
                    "line": 140,
                    "column": 40
                  }
                },
                "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","job");
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
                dom.setAttribute(el2,"type","button");
                dom.setAttribute(el2,"class","btn_bal_circle_form add_btn_2 css-for-loading");
                var el3 = dom.createTextNode("\n                                              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","icon");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1]);
                var element2 = dom.childAt(element0, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element1, 'id');
                morphs[1] = dom.createElementMorph(element1);
                morphs[2] = dom.createElementMorph(element2);
                morphs[3] = dom.createMorphAt(element2,0,0);
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["look-overview-job-",["get","sample.id",["loc",[null,[135,76],[135,85]]]],"-",["get","job.id",["loc",[null,[135,90],[135,96]]]]]]],
                ["element","action",["addSampleToJobRefactor",["get","sample",["loc",[null,[135,134],[135,140]]]],["get","job",["loc",[null,[135,141],[135,144]]]]],[],["loc",[null,[135,100],[135,146]]]],
                ["element","action",["goToLink","jobs.view",["get","job.id",["loc",[null,[138,82],[138,88]]]],"#look-overview"],[],["loc",[null,[138,50],[138,107]]]],
                ["content","job.name",["loc",[null,[138,108],[138,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 126,
                  "column": 36
                },
                "end": {
                  "line": 142,
                  "column": 36
                }
              },
              "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
            },
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","job-element");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","contains-id",[["get","job.jobSamplesIds",["loc",[null,[128,59],[128,76]]]],["get","sample.id",["loc",[null,[128,77],[128,86]]]]],[],["loc",[null,[128,46],[128,87]]]]],[],0,1,["loc",[null,[128,40],[140,47]]]]
            ],
            locals: ["job","jobIndex"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 124,
                "column": 34
              },
              "end": {
                "line": 144,
                "column": 34
              }
            },
            "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","title");
            var el2 = dom.createTextNode("Add to Job");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["block","each",[["get","availableJobs",["loc",[null,[126,44],[126,57]]]]],[],0,null,["loc",[null,[126,36],[142,45]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 16
            },
            "end": {
              "line": 168,
              "column": 16
            }
          },
          "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sample");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","sample-collapsing-row");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"data-toggle","collapse");
          dom.setAttribute(el3,"data-parent","#overview-look-samples-container");
          dom.setAttribute(el3,"unselectable","on");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","arrow-div");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5,"class","collapse-arrow");
          dom.setAttribute(el5,"src","images/forward.svg");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","item");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","colors");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","fabrics");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dropdowner");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"type","button");
          dom.setAttribute(el5,"class","btn_bal_circle_no_border no_color add_btn");
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","icon");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","pophover-wrapper-ui-refactor");
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","pophover");
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","jobs-container");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("                                ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("button");
          dom.setAttribute(el7,"type","button");
          dom.setAttribute(el7,"class","btn_bal_primary_opposite");
          var el8 = dom.createTextNode("New Job");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","sample-collapsed-content collapse");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","first-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Size");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label_value");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Retail price");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label_value");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","second-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Care & Additional infos");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element9, [5]);
          var element11 = dom.childAt(element9, [7, 1]);
          var element12 = dom.childAt(element8, [3, 1]);
          var element13 = dom.childAt(element12, [3, 1]);
          var element14 = dom.childAt(element13, [3]);
          var element15 = dom.childAt(element7, [3]);
          var element16 = dom.childAt(element15, [1]);
          var element17 = dom.childAt(element16, [1]);
          var morphs = new Array(18);
          morphs[0] = dom.createAttrMorph(element9, 'class');
          morphs[1] = dom.createAttrMorph(element9, 'data-target');
          morphs[2] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[3] = dom.createMorphAt(element10,1,1);
          morphs[4] = dom.createMorphAt(element10,2,2);
          morphs[5] = dom.createMorphAt(element10,3,3);
          morphs[6] = dom.createMorphAt(element11,1,1);
          morphs[7] = dom.createMorphAt(element11,3,3);
          morphs[8] = dom.createMorphAt(element11,5,5);
          morphs[9] = dom.createAttrMorph(element12, 'id');
          morphs[10] = dom.createAttrMorph(element12, 'class');
          morphs[11] = dom.createElementMorph(element12);
          morphs[12] = dom.createMorphAt(dom.childAt(element13, [1]),1,1);
          morphs[13] = dom.createElementMorph(element14);
          morphs[14] = dom.createAttrMorph(element15, 'id');
          morphs[15] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
          morphs[16] = dom.createMorphAt(dom.childAt(element17, [7]),0,0);
          morphs[17] = dom.createMorphAt(dom.childAt(element16, [3]),3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["collapser ",["subexpr","if",[["subexpr","if-equal",[["get","sampleToView",["loc",[null,[88,61],[88,73]]]],["get","sample.id",["loc",[null,[88,74],[88,83]]]]],[],["loc",[null,[88,51],[88,84]]]],"","collapsed"],[],["loc",[null,[88,46],[88,101]]]]," pointer"]]],
          ["attribute","data-target",["concat",["#overview-look-sample-",["get","sample.id",["loc",[null,[89,89],[89,98]]]]]]],
          ["content","sample.itemLabel",["loc",[null,[94,45],[94,65]]]],
          ["block","if",[["get","sample.colour1",["loc",[null,[96,34],[96,48]]]]],[],0,null,["loc",[null,[96,28],[98,35]]]],
          ["block","if",[["get","sample.colour2",["loc",[null,[99,34],[99,48]]]]],[],1,null,["loc",[null,[99,28],[101,35]]]],
          ["block","if",[["get","sample.colour3",["loc",[null,[102,34],[102,48]]]]],[],2,null,["loc",[null,[102,28],[104,35]]]],
          ["content","sample.fabric1",["loc",[null,[108,30],[108,48]]]],
          ["inline","if",[["get","sample.fabric2",["loc",[null,[109,35],[109,49]]]],["subexpr","concat",[", ",["get","sample.fabric2",["loc",[null,[109,63],[109,77]]]]],[],["loc",[null,[109,50],[109,78]]]]],[],["loc",[null,[109,30],[109,80]]]],
          ["inline","if",[["get","sample.fabric3",["loc",[null,[110,35],[110,49]]]],["subexpr","concat",[", ",["get","sample.fabric3",["loc",[null,[110,63],[110,77]]]]],[],["loc",[null,[110,50],[110,78]]]]],[],["loc",[null,[110,30],[110,80]]]],
          ["attribute","id",["concat",["dd-overview-sample-",["get","sample.id",["loc",[null,[115,56],[115,65]]]]]]],
          ["attribute","class",["concat",["dd-overview-sample has-pophover-ui-refactor flat-dropdown hover-disabled ",["subexpr","if",[["subexpr","check-position-pophover",[8,["get","selectableSamples.length",["loc",[null,[116,143],[116,167]]]],["get","sampleIndex",["loc",[null,[116,168],[116,179]]]]],[],["loc",[null,[116,116],[116,180]]]],"top","bottom"],[],["loc",[null,[116,111],[116,197]]]]]]],
          ["element","action",["toggleActiveClassClickOutside",["subexpr","concat",["#dd-overview-sample-",["get","sample.id",["loc",[null,[117,103],[117,112]]]]],[],["loc",[null,[117,72],[117,113]]]]],[],["loc",[null,[117,31],[117,115]]]],
          ["block","if",[["get","availableJobs",["loc",[null,[124,40],[124,53]]]]],[],3,null,["loc",[null,[124,34],[144,41]]]],
          ["element","action",["openCreateJobModal",false,["get","sample",["loc",[null,[146,76],[146,82]]]]],[],["loc",[null,[146,40],[146,84]]]],
          ["attribute","id",["concat",["overview-look-sample-",["get","sample.id",["loc",[null,[152,54],[152,63]]]]]]],
          ["content","sample.size",["loc",[null,[156,60],[156,75]]]],
          ["content","sample.retailPrice",["loc",[null,[158,60],[158,82]]]],
          ["inline","multiline-text",[],["text",["subexpr","@mut",[["get","sample.description",["loc",[null,[162,50],[162,68]]]]],[],[]],"class","input_label_value"],["loc",[null,[162,28],[162,96]]]]
        ],
        locals: ["sample","sampleIndex"],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 187,
            "column": 33
          }
        },
        "moduleName": "bookalook/templates/global/modals/look-overview.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Look Overview ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade bal-modal");
        dom.setAttribute(el1,"id","look-overview");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"data-dismiss","modal");
        dom.setAttribute(el4,"class","close_button");
        dom.setAttribute(el4,"src","images/modal_x.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title link-style");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-subtitle");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","link-style");
        dom.setAttribute(el6,"data-dismiss","modal");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" - Look ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body gradient-horizontal-background-toleft-60");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","album-slider-container");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","overview-look-avatars");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"id","look-overview-current-avatar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","look-avatar-overlay container");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","overview-look-info");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","content");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","samples");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h2");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Available samples");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"id","overview-look-samples-container");
        dom.setAttribute(el8,"class","samples-container");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("div class=\"keywords\">\n\n            </div");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("script");
        var el6 = dom.createTextNode("\n          $overviewlookGroup = $('#overview-look-samples-container');\n          $overviewlookGroup.on('show.bs.collapse','.collapse', function() {\n            $overviewlookGroup.find('.collapse.in').collapse('hide');\n          });\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [2, 1, 1]);
        var element26 = dom.childAt(element25, [4]);
        var element27 = dom.childAt(element26, [3, 1]);
        var element28 = dom.childAt(element25, [6]);
        var element29 = dom.childAt(element28, [3]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element29, [5]);
        var morphs = new Array(15);
        morphs[0] = dom.createMorphAt(dom.childAt(element26, [1]),1,1);
        morphs[1] = dom.createElementMorph(element27);
        morphs[2] = dom.createMorphAt(element27,0,0);
        morphs[3] = dom.createMorphAt(element27,2,2);
        morphs[4] = dom.createMorphAt(element27,4,4);
        morphs[5] = dom.createMorphAt(element27,6,6);
        morphs[6] = dom.createMorphAt(dom.childAt(element28, [1]),1,1);
        morphs[7] = dom.createAttrMorph(element30, 'data-avatar-src');
        morphs[8] = dom.createAttrMorph(element30, 'style');
        morphs[9] = dom.createMorphAt(element29,3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(element31, [1]),1,1);
        morphs[11] = dom.createMorphAt(element31,3,3);
        morphs[12] = dom.createMorphAt(element29,7,7);
        morphs[13] = dom.createMorphAt(dom.childAt(element28, [5, 1, 1, 3]),1,1);
        morphs[14] = dom.createMorphAt(fragment,4,4,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","link-to",["profile.brand-profile",["get","lookToView.collection.brand.id",["loc",[null,[9,45],[9,75]]]]],["class","link-style"],0,null,["loc",[null,[9,10],[11,22]]]],
        ["element","action",["clearFilters",["get","departmentLookOverview",["loc",[null,[14,59],[14,81]]]],["get","lookToView.collection.brand.name",["loc",[null,[14,82],[14,114]]]],["get","lookToView.collectionSearchString",["loc",[null,[14,115],[14,148]]]]],[],["loc",[null,[14,35],[14,150]]]],
        ["content","lookToView.collection.collectionType",["loc",[null,[14,172],[14,212]]]],
        ["content","lookToView.collection.season",["loc",[null,[14,213],[14,245]]]],
        ["content","lookToView.collection.year",["loc",[null,[14,246],[14,276]]]],
        ["content","lookToView.position",["loc",[null,[14,284],[14,307]]]],
        ["block","each",[["get","albumToView.orderedLooks",["loc",[null,[19,18],[19,42]]]]],[],1,null,["loc",[null,[19,10],[30,19]]]],
        ["attribute","data-avatar-src",["concat",[["get","lookToView.currentAvatarToView.avatar",["loc",[null,[33,68],[33,105]]]]]]],
        ["attribute","style",["concat",["background-image: url('",["get","lookToView.currentAvatarToView.thumbnail",["loc",[null,[33,141],[33,181]]]],"');"]]],
        ["block","if",[["get","lookToView.avatars",["loc",[null,[34,16],[34,34]]]]],[],2,null,["loc",[null,[34,10],[47,17]]]],
        ["block","unless",[["get","downloadingOverviewImg",["loc",[null,[51,24],[51,46]]]]],[],3,4,["loc",[null,[51,14],[59,25]]]],
        ["block","if",[["subexpr","if-higher",[["get","lookToView.avatars.length",["loc",[null,[61,29],[61,54]]]],1],[],["loc",[null,[61,18],[61,57]]]]],[],5,null,["loc",[null,[61,12],[66,19]]]],
        ["block","if",[["subexpr","if-higher",[["get","lookToView.avatars.length",["loc",[null,[70,27],[70,52]]]],1],[],["loc",[null,[70,16],[70,55]]]]],[],6,null,["loc",[null,[70,10],[76,17]]]],
        ["block","each",[["get","selectableSamples",["loc",[null,[84,24],[84,41]]]]],[],7,null,["loc",[null,[84,16],[168,25]]]],
        ["inline","partial",["jobs/modals/new-job"],[],["loc",[null,[187,0],[187,33]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});