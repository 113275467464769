define('bookalook/serializers/look', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].RESTSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      samples: { serialize: 'ids', deserialize: 'records' }
    }
  });

});