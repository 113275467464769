define('bookalook/routes/collections/index', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/routes/check-out/functions'], function (exports, Ember, UserValidatorMixin, config, utils, checkFunctions) {

  'use strict';

  exports.filterByCollection = filterByCollection;
  exports._scrollCalculation = _scrollCalculation;
  exports.removeScrollListener = removeScrollListener;
  exports.setScrollPaginationListener = setScrollPaginationListener;
  exports._hidingControllerSamples = _hidingControllerSamples;
  exports.removeListenersAndObservers = removeListenersAndObservers;

  var controllerGlobal = null;

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    poll: Ember['default'].inject.service(),

    actions: {
      willTransition: function willTransition() {
        //Remove listeners on transition
        removeListenersAndObservers(this);
      },
      openNewCollectionModal: function openNewCollectionModal() {
        this.onOpenNewCollectionModal();
      },
      saveCollection: function saveCollection(collection) {
        this.onSaveCollection(collection);
      },
      showArchivedCollections: function showArchivedCollections(visibility) {
        this.onShowArchivedCollections(visibility);
      },
      generateArchivedSamplesReport: function generateArchivedSamplesReport() {
        this.onGenerateArchivedSamplesReport();
      },
      selectCollection: function selectCollection(c) {
        this.controller.set('choosenCollection', c);
      },
      deleteCollection: function deleteCollection() {
        this.onDeleteCollection();
      },
      openEditCollectionModal: function openEditCollectionModal(c) {
        this.onOpenEditCollectionModal(c);
      },
      openActivateCollectionModal: function openActivateCollectionModal(c) {
        this.onOpenActivateCollectionModal(c);
      },
      openDeleteCollectionModal: function openDeleteCollectionModal(c) {
        this.onOpenDeleteCollectionModal(c);
      },
      activateCollection: function activateCollection() {
        this.onActivateCollection();
      },
      rollbackCollection: function rollbackCollection(collection) {
        this.controller.set('editingCollection', false);collection.rollbackAttributes();
      },
      openSelectSamplesToArchiveModal: function openSelectSamplesToArchiveModal() {
        this.onOpenSelectSamplesToArchiveModal();
      },
      openDdtSamplesListModal: function openDdtSamplesListModal() {
        this.onOpenDdtSamplesListModal();
      },
      cancelDdtSamplesModal: function cancelDdtSamplesModal() {
        this.onCancelDdtSamplesModal();
      },
      checkCheckoutLookVisibleSamples: function checkCheckoutLookVisibleSamples(look) {
        checkFunctions['default'].onCheckCheckoutLookVisibleSamples(look);
      },
      checkCheckoutSample: function checkCheckoutSample(sample) {
        checkFunctions['default'].onCheckCheckoutSample(sample);
      },
      sortDdtList: function sortDdtList(field) {
        this.onSortDdtList(field);
      },
      searchDDT: function searchDDT() {
        this.onSearchDDT();
      },
      clearFilterDDT: function clearFilterDDT() {
        this.onClearFilterDDT();
      },
      downloadDDT: function downloadDDT(id) {
        this.onDownloadDDT(id);
      },
      toggleActivePophover: function toggleActivePophover(id) {
        this.onToggleActivePophover(id);
      },
      callToArchiveModal: function callToArchiveModal(ddtSamplesToArchive) {
        this.onCallToArchiveModal(this, ddtSamplesToArchive);
      }
    },

    model: function model() {
      return this.store.query('collection', { collection_list: true });
    },

    setupController: function setupController(controller, model) {
      var _t = this;
      this._super(controller, model);

      controller.set('userLogged', Ember['default'].user);

      controller.set('showArchivedCollections', false);
      controller.set('searchInput', '');
      controller.set('collectionToAdd', null);
      controller.set('disableShowroomFilter', true);

      controller.createCollectionDisabledObserver = function () {
        var collection = controller.get('collectionToAdd');
        var disabled = null;
        if (collection != null) {
          var brand = collection.get('brand');
          var season = collection.get('season');
          var type = collection.get('collectionType');
          var year = collection.get('year');
          if (!brand || !season || !type || !year) {
            disabled = true;
          } else {
            disabled = false;
          }
        } else {
          disabled = true;
        }
        controller.set('createCollectionDisabled', disabled);
      };

      utils['default'].addObserver(controller, ['collectionToAdd.brand', 'collectionToAdd.season', 'collectionToAdd.collectionType', 'collectionToAdd.year'], 'createCollectionDisabledObserver');

      controller.set('collectionFilteredByStatus', Ember['default'].computed('model.@each.archived', 'showArchivedCollections', function () {
        var showArchived = controller.get('showArchivedCollections');
        var collectionsFiltered = controller.get('model').filter(function (collection) {
          return collection.get('archived') === showArchived;
        });
        return collectionsFiltered;
      }));

      controller.set('collectionsList', Ember['default'].computed('collectionFilteredByStatus', 'searchInput', 'brandFilter', function () {
        var searchInput = controller.get('searchInput').toLowerCase();
        var collectionsFiltered = controller.get('collectionFilteredByStatus');
        var brandFilter = controller.get('brandFilter');

        var collectionsFilteredBySearch = collectionsFiltered.filter(function (collection) {
          var title = (collection.get('title') || '').toLowerCase();
          var season = collection.get('season').toLowerCase();
          var collectionType = collection.get('collectionType').toLowerCase();
          var year = collection.get('year');
          var brandName = collection.get('brand').get('name').toLowerCase();
          return title.indexOf(searchInput) >= 0 || season.indexOf(searchInput) >= 0 || collectionType.indexOf(searchInput) >= 0 || year.indexOf(searchInput) >= 0 || brandName.indexOf(searchInput) >= 0;
        });

        if (brandFilter) {
          collectionsFilteredBySearch = collectionsFilteredBySearch.filter(function (collection) {
            return collection.get('brand').get('id') === brandFilter;
          });
        }
        return collectionsFilteredBySearch;
      }));

      controller.set('allBrands', Ember['default'].computed('model.@each', function () {

        var brands = this.store.query('brand', { collection: true });

        brands.then(function (theBrands) {
          return theBrands;
        }, Ember['default'].A([{ id: null, name: 'All brands' }]));

        return brands;
      }));

      controller.set('oneBrand', Ember['default'].computed.equal('allBrands.length', 2));

      controller.set('collectionStatusFilter', Ember['default'].computed('showArchivedCollections', function () {
        return controller.get('showArchivedCollections') ? 'Archived' : 'Active';
      }));

      _t.controller.set('collections', []);
    },

    onOpenNewCollectionModal: function onOpenNewCollectionModal() {
      var collection = this.controller.get('collectionToAdd');
      if (collection) {
        collection.rollbackAttributes();
      }

      collection = this.store.createRecord('collection');
      this.controller.set('collectionToAdd', collection);
      if (!Ember['default'].showroom.get('isAgency')) {
        // Ember.agency is a brand here
        collection.set('brand', Ember['default'].agency);
      }
    },

    onSaveCollection: function onSaveCollection(collection) {
      var _this2 = this;

      var _this = this;
      if (collection.validate()) {
        var promise = collection.save();
        if (promise) {
          utils['default'].removeCollectionsCache();
          utils['default'].buttonLoading('#create-collection-btn', promise);
          promise.then(function (newCollection) {
            Ember['default'].$('#new-collection').modal('hide');
            //_this.controller.notifyPropertyChange('model');
            if (!_this2.controller.get('editingCollection')) {
              _this2.transitionTo('collections.collection', newCollection.get('id'));
            } else {
              _this.controller.set('editingCollection', false);
            }
          });
        }
      }
    },

    onShowArchivedCollections: function onShowArchivedCollections(visibility) {
      this.controller.set('showArchivedCollections', visibility);
      this.controller.set('searchInput', '');
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-collections');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-collections');
    },

    onGenerateArchivedSamplesReport: function onGenerateArchivedSamplesReport() {
      if (Ember['default'].user.get('isDesigner')) {
        this.send('openLimitationReachedModal', 'Report');
      } else {
        window.open(config['default'].APIURL + 'collection_archived_samples_report?userId=' + Ember['default'].user.id, '_blank' // <- This is what makes it open in a new window.
        );
      }
    },

    onDeleteCollection: function onDeleteCollection() {
      var c = this.controller.get('choosenCollection');
      if (c != null) {
        c.destroyRecord().then(function () {
          Ember['default'].$('#delete-collection').modal('hide');
        });
      }
    },

    onOpenEditCollectionModal: function onOpenEditCollectionModal(c) {
      this.controller.set('editingCollection', true);
      this.controller.set('collectionToAdd', c);
      Ember['default'].$('#new-collection').modal();
      Ember['default'].openModal();
    },

    onOpenActivateCollectionModal: function onOpenActivateCollectionModal(c) {
      this.controller.set('choosenCollection', c);
      Ember['default'].$('#activate-collection').modal();
      Ember['default'].openModal();
    },

    onOpenDeleteCollectionModal: function onOpenDeleteCollectionModal(c) {
      this.controller.set('choosenCollection', c);
      Ember['default'].$('#delete-collection').modal();
      Ember['default'].openModal();
    },

    onActivateCollection: function onActivateCollection() {
      var t = this;
      var collection = this.controller.get('choosenCollection');
      utils['default'].putAPIJSON('collections/' + collection.get('id') + '/reactivate').then(function () {
        collection.reload().then(function () {
          t.send('openPositiveTopBar');
        });
        collection.set('archived', false);
      });
    },

    onOpenSelectSamplesToArchiveModal: function onOpenSelectSamplesToArchiveModal() {
      var _this3 = this;

      var _t = this;

      //_t.send('openMessageModal', 'Loading samples');
      Ember['default'].$('#select-samples-to-archive-modal').modal();
      Ember['default'].openModal();
      _t.controller.set('ddtCollectionsLoading', true);

      //checkFunctions.onOpenCheckOutModalBrandRefactor(this, sample, 'Inventory');

      utils['default'].getAPIJSON('collections/archivable').then(function (data) {
        var collections = utils['default'].pushArrayPayload(_this3.store, 'collection', data.collection);
        var samplesToBeArchived = Ember['default'].A();
        collections.map(function (c) {
          var cSamples = c.get('samples');
          samplesToBeArchived.pushObjects(cSamples);
          cSamples.map(function (s) {
            s.set('checked', true);
            s.set('visible', true);
            s.set('toCheckout', false);
            return s;
          });
          return c;
        });
        _t.controller.set('ddtSamples', samplesToBeArchived);
        if (Ember['default'].isNone(_t.controller.get('ddtSamplesToArchive'))) {
          _t.controller.set('ddtSamplesToArchive', Ember['default'].computed.filterBy('ddtSamples', 'toCheckout', true));
        }
        _t.controller.set('collectionsToArchive', collections);

        //Filters are included into this class (not using filters.js)
        _t.initializeFilters();
        var controller = _t.controller;

        //All Albums: for pagination
        _t.controller.allAlbums = Ember['default'].computed('allCollections', 'albumPage', 'albumFilter', function () {
          var albums;
          if (controller.get('albumFilter') && controller.get('albumFilter.id')) {
            albums = [controller.get('albumFilter')];
          } else {
            controller.set('albumFilter', null);
            controller.set('lookFilter', null);
            albums = this.get('allCollections').reduce(function (a, c) {
              if (c.get) {
                a = a.concat(c.get('albums').toArray());
              }
              return a;
            }, []);
          }
          utils['default'].lazyLoad(2000, '#selectArchiveSamplesScrollTable');
          _hidingControllerSamples(controller);

          var albumsToShow = albums.slice(0, controller.get('albumPage'));

          var albumsToShowFiltered = albumsToShow.slice(0);

          var loadMore = !controller.get('allSamplesUnvisible') && controller.get('albumPage') < albums.length;
          controller.set('loadMore', loadMore);
          if (!loadMore) {
            controller.set('albumPage', albums.length);
          }

          return albumsToShowFiltered;
        });

        //Initialize search Filter function
        controller.searchFilterFunction = function () {
          var lookFilter = controller.get('lookFilter');
          var brandSearchFilter = controller.get('brandSearchFilter');
          var collectionFilter = controller.get('collectionFilter');
          var albumFilter = controller.get('albumFilter');
          var searchInput = this.get('searchInput').toLowerCase();
          var colourFilter = (this.get('colourFilter') || '').toLowerCase();
          var showroomFilter = this.get('showroomFilter');
          var itemFilter = (this.get('itemFilter') || '').toLowerCase();
          var samples = controller.get('ddtSamples');
          var allSamplesUnvisible = true;
          var routeName = controller.route ? controller.route.routeName : '';
          samples.forEach(function (s) {
            var checked = true;

            if (brandSearchFilter && checked) {
              checked = s.get('look.album.collection.brand.id') === brandSearchFilter;
            }

            if (collectionFilter && checked) {
              checked = s.get('look.album.collection.id') === collectionFilter;
            }

            if (albumFilter && albumFilter.get && checked) {
              checked = s.get('look.album.id') === albumFilter.get('id');
            }

            if (lookFilter && checked) {
              checked = s.get('look.id') === lookFilter;
            }

            if (searchInput !== '' && checked) {
              checked = s.get('searchString').toLowerCase().indexOf(searchInput) > -1;
            }

            if (colourFilter && colourFilter !== '' && checked) {
              checked = s.get('colourSearchString').toLowerCase().indexOf(colourFilter) > -1;
            }

            if (itemFilter && itemFilter !== '' && itemFilter !== 'all samples' && checked) {
              checked = s.get('itemObject')['name'].toLowerCase() === itemFilter;
            }

            s.set('visible', checked);
            allSamplesUnvisible = allSamplesUnvisible && !checked;
          });
          controller.set('allSamplesUnvisible', allSamplesUnvisible);
        };
        controller.searchFilterFunction();

        //Observer for collectionsFilteredByBrand (no computeds!)
        controller.collectionsFilteredByBrandObserver = function () {

          controller.set('collectionFilter', null);
          controller.set('colourFilter', '');

          controller.set('sizeFilter', '');
          if (Ember['default'].isNone(controller.get('collectionsToArchive')) || controller.get('collectionsToArchive').length == 0) {
            return utils['default'].addDefaultOption([], 'All Collections', 'filterName', null);;
          }

          var collections = controller.get('collectionsToArchive');

          var collectionsFiltered = collections;

          if (controller.get('brandSearchFilter') !== '0' && controller.get('brandSearchFilter') !== null) {
            collectionsFiltered = collections.filter(function (collection) {
              return collection.get('brand.id') === controller.get('brandSearchFilter') && !collection.get('archived');
            });
          }

          collectionsFiltered = collectionsFiltered.sort(function (a, b) {
            if (a.get && b.get) {
              return b.get('filterName').toLowerCase().localeCompare(a.get('filterName').toLowerCase());
            } else {
              //FIRST ITEM (All Collections)
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          controller.set('collectionsFilteredByBrand', utils['default'].addDefaultOption(collectionsFiltered, 'All Collections', 'filterName', null));
        };
        utils['default'].addObserver(controller, ['brandSearchFilter', 'collections', 'collectionsToArchive'], 'collectionsFilteredByBrandObserver');
        controller.notifyPropertyChange('collectionsToArchive');

        //Observer for albumsFilteredByCollection (no computeds!)
        controller.albumsFilteredByCollectionObserver = function () {
          var collection = controller.get('allCollections')[0];
          controller.set('albumFilter', null);
          controller.set('lookFilter', null);
          controller.set('colourFilter', '');
          controller.set('statusFilter', '');

          if (!collection || !collection.get) {
            return [];
          }
          var albums = collection.get('albums');
          var albumsFiltered = albums;

          if (controller.get('collectionFilter') !== '0') {
            albumsFiltered = albums.filter(function (album) {
              return album.get('collection.id') === controller.get('collectionFilter') && !album.get('archived');
            });
          }

          albumsFiltered = albumsFiltered.sort(function (a, b) {
            if (a.get && b.get) {
              return a.get('albumTypeLabel').toLowerCase().localeCompare(b.get('albumTypeLabel').toLowerCase());
            } else {
              if (a.get) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          controller.set('albumsFilteredByCollection', utils['default'].addDefaultOption(albumsFiltered, 'All Albums', 'albumTypeLabel', null));
        };
        utils['default'].addObserver(controller, ['collectionFilter'], 'albumsFilteredByCollectionObserver');

        controller.allCollections = Ember['default'].computed('collectionsFilteredByBrand', 'collectionsToArchive', 'collectionFilter', function () {
          var collectionFilter = controller.get('collectionFilter');
          var collections = controller.get('collectionsToArchive');

          var collectionsFiltered = filterByCollection(collections, collectionFilter, controller);

          return collectionsFiltered;
        });

        utils['default'].addObserver(_t.controller, ['brandSearchFilter', 'collectionFilter', 'albumFilter', 'searchInput', 'itemFilter', 'colourFilter'], 'searchFilterFunction');
        _t.controller.set('ddtCollectionsLoading', false);
        //filters.setFilterComputeds(this, '#selectArchiveSamplesScrollTable','#selectArchiveSamplesScrollGallery');

        setScrollPaginationListener(_t, '#selectArchiveSamplesScrollTable', '#selectArchiveSamplesScrollGallery');
      });
    },

    onCancelDdtSamplesModal: function onCancelDdtSamplesModal() {
      //filters.removeScrollListener(this);
      //filters.removeObserversFromSetFilterComputeds(this);

      Ember['default'].bulk.clearSamples();
      this.controller.get('ddtSamples').map(function (s) {
        s.set('checked', false);
        s.set('toCheckout', false);
      });
    },

    onOpenDdtSamplesListModal: function onOpenDdtSamplesListModal() {
      Ember['default'].$('#ddt-samples-list-modal').modal();
      Ember['default'].openModal();
      this.loadDDTList();
    },

    loadDDTList: function loadDDTList() {
      var to = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];
      var ddtnumber = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

      var _t = this;
      _t.controller.set('ddtSamplesListLoading', true);
      utils['default'].getDDT(to, ddtnumber).then(function (data) {
        _t.controller.set('ddtSamplesList', data.ddtSample);
        _t.controller.set('ddtSamplesListLoading', false);
      });
    },

    onSearchDDT: function onSearchDDT() {
      var to = $("#ddt-to").val();
      var ddtnumber = $("#ddt-number").val();
      this.loadDDTList(to, ddtnumber);
    },

    onClearFilterDDT: function onClearFilterDDT() {
      $("#ddt-to").val('');
      $("#ddt-number").val('');
      this.loadDDTList();
    },

    onSortDdtList: function onSortDdtList(field) {
      var key = 'ddtSamplesListSorting';
      var selector = '#ddt-samples-list-modal';

      $(selector).find('th').removeClass('asc des');
      var previousField = this.controller.get(key) || 'date-des';
      if (field == previousField.slice(0, previousField.length - 4)) {
        if (previousField.endsWith('-des')) {
          $(selector).find('.' + field).addClass('asc');
          field = field + '-asc';
        } else {
          $(selector).find('.' + field).addClass('des');
          field = field + '-des';
        }
      } else {
        $(selector).find('.' + field).addClass('asc');
        field = field + '-asc';
      }
      this.controller.set(key, field);

      // Setting a criteria
      var criteria = field;

      // Getting criteria and direction respectively
      var criteriaKey = criteria.slice(0, criteria.length - 4);
      var order = criteria.slice(field.length - 4);

      var ddtSamplesList = this.controller.get('ddtSamplesList');

      var sortedDDTs = [];
      if (order == "-asc") {
        sortedDDTs = ddtSamplesList.sortBy(criteriaKey);
      } else {
        sortedDDTs = ddtSamplesList.sortBy(criteriaKey).reverse();
      }
      this.controller.set('ddtSamplesList', sortedDDTs);
    },

    onDownloadDDT: function onDownloadDDT(id) {
      window.open(config['default'].APIURL + 'pdfs/ddtsamples/' + id, '_blank' // <- This is what makes it open in a new window.
      );
    },

    initializeFilters: function initializeFilters() {
      var controller = this.controller;

      controllerGlobal = controller;

      controller.set('albumPage', 1);
      controller.set('brandSearchFilter', null);
      controller.set('collectionFilter', null);
      controller.set('searchInput', '');
      controller.set('itemFilter', '');
      controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
      controller.set('colourFilter', '');

      controller.resetAlbumPageObserver = function () {
        controller.set('albumPage', 1);
      };

      utils['default'].addObserver(controller, ['brandFilter', 'collectionFilter', 'albumFilter', 'searchInput', 'colourFilter', 'sizeFilter', 'itemFilter'], 'resetAlbumPageObserver');

      //controller.set('allSearchBrands', function () {
      if (!controller.get('collectionsToArchive')) {
        return [];
      } else {
        var brands = controller.get('collectionsToArchive').reduce(function (brandList, nextCollection) {
          var content = nextCollection.get('brand.content');
          if (content) {
            brandList.addObject(content);
          }
          return brandList;
        }, Ember['default'].A([{ id: null, name: 'All brands' }]));
        controller.set('allSearchBrands', brands);
      }
    },
    onToggleActivePophover: function onToggleActivePophover(id) {
      var element = $(id);
      var wasActive = element.hasClass('active');
      element.toggleClass('active');
      $(".has-pophover-ui-refactor.active").removeClass('active');
      if (!wasActive) {
        element.addClass('active');
      }
      Ember['default'].$('body').one('click', function closeMenu(e) {
        if ($(e.target).parents('.has-pophover-ui-refactor').length === 0) {
          element.removeClass('active');
        }
      });
    },
    onCallToArchiveModal: function onCallToArchiveModal(route, ddtSamplesToArchive) {
      removeListenersAndObservers(route);
      route.send('openArchiveModal', 'ddt-samples', ddtSamplesToArchive);
    }

  });

  function filterByCollection(collectionList, collectionFilter) {
    var collectionsFiltered = collectionList;

    if (collectionFilter !== null && collectionFilter !== '') {
      collectionsFiltered = collectionList.filter(function (collection) {
        return collection.id === collectionFilter && collection.get('archived') === false;
      });
    }
    return collectionsFiltered;
  }

  //Scroll calculation functions

  function _scrollCalculation() {

    var container = '#selectArchiveSamplesScrollTable';
    var containerDocument = '#selectArchiveSamplesScrollGallery';

    if ($(container).scrollTop() + $(container).height() > $(containerDocument).height() - 300) {
      if (!controllerGlobal.get('bookingDatesBlock') && !controllerGlobal.get('allSamplesUnvisible') && controllerGlobal.get('loadMore') && !controllerGlobal.get('isLoading')) {
        controllerGlobal.incrementProperty('albumPage');
      }
    }
  }

  function removeScrollListener(route) {
    var intervals = route.get('poll');
    intervals.clearAll();
  }

  function setScrollPaginationListener(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;
    var intervals = route.get('poll');
    intervals.clearAll();
    intervals.addPoll({
      interval: 50, // 0.05s
      callback: _scrollCalculation
    });
  }

  function _hidingControllerSamples(controller) {
    if (controller.get('samples')) {
      controller.get('samples').forEach(function (sample) {
        return sample.set('visible', false);
      });
    }
  }

  function removeListenersAndObservers(route) {
    route.get('poll').clearAll();
    var _t = route;
    var controller = _t.controller;
    controller.removeObserver('collectionFilter', controller, 'albumsFilteredByCollectionObserver');
    controller.removeObserver('brandSearchFilter', controller, 'collectionsFilteredByBrandObserver');
    controller.removeObserver('collections', controller, 'collectionsFilteredByBrandObserver');
    controller.removeObserver('collectionsToArchive', controller, 'collectionsFilteredByBrandObserver');
    controller.removeObserver('brandFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('collectionFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('albumFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('searchInput', controller, 'resetAlbumPageObserver');
    controller.removeObserver('colourFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('sizeFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('itemFilter', controller, 'resetAlbumPageObserver');
    controller.removeObserver('collectionToAdd.brand', controller, 'createCollectionDisabledObserver');
    controller.removeObserver('collectionToAdd.season', controller, 'createCollectionDisabledObserver');
    controller.removeObserver('collectionToAdd.collectionType', controller, 'createCollectionDisabledObserver');
    controller.removeObserver('collectionToAdd.year', controller, 'createCollectionDisabledObserver');
  }

});