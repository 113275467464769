define('bookalook/routes/register/complete', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    activate: function activate() {
      Ember['default'].$('body').addClass('page-complete');
      this.controllerFor('application').set('hasSidebar', false);
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-complete');
      this.controllerFor('application').set('hasSidebar', false);
    }
  });

});