define('bookalook/models/plan', ['exports', 'ember-data', 'ember', 'bookalook/mixins/model-validator', 'ember-cli-copyable'], function (exports, DS, Ember, Validator, Copyable) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], Copyable['default'], {
    name: DS['default'].attr('string'),
    idStripe: DS['default'].attr('string'),
    maxUsers: DS['default'].attr('string'),
    maxShowrooms: DS['default'].attr('string'),
    maxBrands: DS['default'].attr('string'),
    maxSamples: DS['default'].attr('string'),
    maxBookings: DS['default'].attr('string'),
    currency: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    weight: DS['default'].attr('string'),

    active: DS['default'].attr('boolean'),
    contacts: DS['default'].attr('boolean'),

    dateCreated: DS['default'].attr('day'),

    type: (function () {
      var name = this.get('idStripe');
      if (name) {
        name = name.toLowerCase();
        if (name.indexOf('starter') !== -1) {
          return "Starter";
        } else if (name.indexOf('designer') !== -1) {
          return "Designer";
        } else if (name.indexOf('corporate') !== -1) {
          return "Corporate";
        } else {
          return "Pro";
        }
      }
      return null;
    }).property('idStripe'),

    interval: (function () {
      var name = this.get('idStripe');
      if (name) {
        name = name.toLowerCase();
        if (name.indexOf('monthly') !== -1) {
          return "Monthly";
        } else {
          return "Yearly";
        }
      }
      return null;
    }).property('idStripe'),

    validations: {
      'name': {
        presence: true
      },
      'maxUsers': {
        presence: true
      },
      'maxShowrooms': {
        presence: true
      },
      'maxBrands': {
        presence: true
      },
      'maxSamples': {
        presence: true
      },
      'maxBookings': {
        presence: true
      },
      'weight': {
        presence: true
      }
    }
  });

});