define('bookalook/routes/barcodes/functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.onStartScanning = onStartScanning;
  exports.onSearchBarcode = onSearchBarcode;
  exports.onCancelSelection = onCancelSelection;
  exports.onConfirmActions = onConfirmActions;
  exports.onSendSamplesToCheck = onSendSamplesToCheck;
  exports.onProcessResponse = onProcessResponse;
  exports.onShowResponse = onShowResponse;

  var idsMapping = {};
  var barcodes = [];

  function onStartScanning(route) {
    var bookingId = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    var input = '#barcode-input';
    Ember['default'].$('.scan-searcher').show();
    Ember['default'].$(input).off().focus();

    // This is observing clicks to focus the input text every time
    Ember['default'].$(window).off().mouseup(function (e) {
      // "Recursive" calling to focus the input text for each click, wherever it is performed
      onStartScanning(route, bookingId);
    });

    Ember['default'].$(input).on('paste', function (e) {
      // Paste event triggers before the real pasting, then we prevent the default event to miss the pasting.
      // And next, we obtain the text that user was going to paste
      e.preventDefault();
      onSearchBarcode(route, e.originalEvent.clipboardData.getData('text'), bookingId);
      Ember['default'].$(input).val('');
    });

    Ember['default'].$(input).on('keyup', function (e) {
      var content = Ember['default'].$(input).val();
      if (e.keyCode == 13) {
        onSearchBarcode(route, content, bookingId);
        Ember['default'].$(input).val('');
      }
    });
  }

  function onSearchBarcode(route, barcode) {
    var bookingId = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    var url = "barcode/scan/" + barcode;
    //if (bookingId != null){
    //  url = url + "/booking/"+bookingId;
    //}
    var controller = route.controller;
    utils['default'].getAPIJSON(url).then(function (response) {
      var newBarcodesList = barcodes;
      //Check if error or success
      if (response.barcode.id) {
        // Then check existence inside the list
        if (!idsMapping[response.barcode.id]) {
          idsMapping[response.barcode.id] = barcode;
          newBarcodesList.push(response.barcode);
          //count checked samples, add the actual one and set the counter
          var confirmed = Ember['default'].$('.table').find('.chosenSamples:checked');
          var confirmedcorrected = confirmed.length + 1;
          controller.set('confirmed', confirmedcorrected);
        }
      } else {
        // Errors always will be shown
        newBarcodesList.push(response.barcode);
      }
      controller.set('barcodes', newBarcodesList);
      Ember['default'].$('#confirm-buttons').show();
    });
  }

  function onCancelSelection(route) {
    var controller = route.controller;
    idsMapping = [];
    barcodes = [];
    controller.set('barcodes', []);
    Ember['default'].$('#confirm-buttons').hide();
  }

  function onConfirmActions(route) {
    // Find the checked samples
    var confirmed = Ember['default'].$('.table').find('.chosenSamples:checked');
    if (confirmed.length) {
      (function () {
        var checkin = [],
            checkout = [],
            transfer = [];
        route.controller.set('isLoading', true);
        // Divide the samples to send them as two different arrays
        Ember['default'].$.each(confirmed, function (key, value) {
          var status = Ember['default'].$(value).parents('tr').find('.status').text();
          var sampleId = Ember['default'].$(value).context.id;
          sampleId = sampleId.substr(10, sampleId.length);
          switch (status) {
            case 'CHECK-IN':
              checkin.push(sampleId);
              break;
            case 'TRANSFERRING':
              transfer.push(sampleId);
              break;
            case 'CHECK-OUT':
              checkout.push(sampleId);
              break;
          }
        });
        Ember['default'].$('.barcode-search').hide();
        onSendSamplesToCheck(route, checkin, checkout, transfer);
      })();
    } else {
      // No samples checked case
      route.send('openErrorsModal', [], "There are no samples selected, please select samples for check-out, check-in or to transfer. Click on cancel to return to your inventory.");
    }
  }

  function onSendSamplesToCheck(route, checkin, checkout, transfer) {
    utils['default'].postAPIJSON('barcode/check', { 'checkin': checkin, 'checkout': checkout, 'transfer': transfer }).then(function (response) {
      onProcessResponse(route, response);
      route.controller.set('isLoading', false);
    });
  }

  function onProcessResponse(route, response) {
    var controller = route.controller;
    var mapping = idsMapping;
    response = response.barcode;
    //console.log("response", response);
    var checkInReport = [],
        checkOutReport = [],
        transferReport = [];
    Ember['default'].$.each(mapping, function (id, barcode) {
      if (response.checkin) {
        Ember['default'].$.each(response.checkin, function (key, value) {
          if (typeof value !== 'undefined' && value.id == id) {
            //Set his barcode saved previously
            value.barcode = barcode;
            value.date = new Date(value.date);

            //Inserting into object and deleting from response
            checkInReport.push(value);
            response.checkin.splice(key, 1);
          }
        });
      }
      if (response.checkout) {
        Ember['default'].$.each(response.checkout, function (key, value) {
          if (typeof value !== 'undefined' && value.id == id) {
            //Set his barcode saved previously
            value.barcode = barcode;
            value.date = new Date(value.date);

            //Inserting into object and deleting from response
            checkOutReport.push(value);
            response.checkout.splice(key, 1);
          }
        });
      }
      if (response.transfer) {
        Ember['default'].$.each(response.transfer, function (key, value) {
          if (typeof value !== 'undefined' && value.id == id) {
            //Set his barcode saved previously
            value.barcode = barcode;
            value.date = new Date(value.date);

            //Inserting into object and deleting from response
            transferReport.push(value);
            response.transfer.splice(key, 1);
          }
        });
      }
    });
    onShowResponse(route, checkInReport, checkOutReport, transferReport);
  }

  function onShowResponse(route, checkin, checkout, transfer) {
    // this.controller.set('checkinResults',checkin);
    // this.controller.set('checkoutResults',checkout);
    // this.controller.set('transferResults',transfer);
    var controller = route.controller;
    if (controller.get('booking')) {
      route.transitionTo('bookings.booking-details', controller.get('booking').get('id'), {
        queryParams: {
          search: ""
        }
      });
    } else {
      route.transitionTo('inventory');
    }
    //route.send('openTopBar','Scanning processed correctly.');
    route.send('openPositiveTopBar');
  }

});