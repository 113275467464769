define('bookalook/models/sample-status', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    dateDeclined: DS['default'].attr('day'),
    dateAccepted: DS['default'].attr('day'),
    dateConfirmed: DS['default'].attr('day'),

    checkout: DS['default'].attr('day'),
    checkin: DS['default'].attr('day'),

    userCheckout: DS['default'].belongsTo('user'),
    userCheckin: DS['default'].belongsTo('user'),
    userCancel: DS['default'].belongsTo('user'),

    sample: DS['default'].belongsTo('sample', { async: true }), // avoid issue when no sample is loaded associated to sampleStatus
    booking: DS['default'].belongsTo('booking'),

    featured: DS['default'].attr('string'),

    isOut: (function () {
      if (this.get('sample.isFromLoggedUserShowroom')) {
        return Ember['default'].isNone(this.get('dateDeclined')) && !Ember['default'].isNone(this.get('checkout')) && Ember['default'].isNone(this.get('checkin'));
      }
    }).property('checkout', 'checkin', 'dateDeclined'),

    isOutInventory: (function () {
      return Ember['default'].isNone(this.get('dateDeclined')) && !Ember['default'].isNone(this.get('checkout')) && Ember['default'].isNone(this.get('checkin'));
    }).property('checkout', 'checkin', 'dateDeclined'),

    isConfirmed: (function () {
      if (this.get('sample.isFromLoggedUserShowroom')) {
        return Ember['default'].isNone(this.get('dateDeclined')) && !Ember['default'].isNone(this.get('dateConfirmed')) && Ember['default'].isNone(this.get('checkout'));
      }
    }).property('dateConfirmed', 'checkout', 'dateDeclined'),

    isConfirmedInventory: (function () {
      if (this.get('sample.isFromLoggedUserShowroom')) {
        return Ember['default'].isNone(this.get('dateDeclined')) && !Ember['default'].isNone(this.get('dateAccepted')) && Ember['default'].isNone(this.get('checkout'));
      }
    }).property('dateConfirmed', 'checkout', 'dateDeclined')
  });

});