define('bookalook/tests/routes/bookings/new-booking.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/bookings/new-booking.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/bookings/new-booking.js should pass jshint.\nroutes/bookings/new-booking.js: line 79, col 41, Missing semicolon.\nroutes/bookings/new-booking.js: line 101, col 5, Misleading line break before \'||\'; readers may interpret this as an expression boundary.\nroutes/bookings/new-booking.js: line 102, col 5, Misleading line break before \'||\'; readers may interpret this as an expression boundary.\n\n3 errors');
  });

});