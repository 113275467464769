define('bookalook/templates/collections/partials/new-sample-card', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 10
            },
            "end": {
              "line": 29,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/collections/partials/new-sample-card.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","pointer");
          var el2 = dom.createTextNode("Activate sample");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","pointer");
          var el2 = dom.createTextNode("Delete sample");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[27,63],[27,72]]]],["get","sample",["loc",[null,[27,73],[27,79]]]],"archive"],[],["loc",[null,[27,32],[27,91]]]],
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[28,63],[28,72]]]],["get","sample",["loc",[null,[28,73],[28,79]]]],"delete"],[],["loc",[null,[28,32],[28,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 12
              },
              "end": {
                "line": 35,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/collections/partials/new-sample-card.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","pointer");
            var el2 = dom.createTextNode("Delete sample");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[34,65],[34,74]]]],["get","sample",["loc",[null,[34,75],[34,81]]]],"delete"],[],["loc",[null,[34,34],[34,92]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 14
                },
                "end": {
                  "line": 38,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/collections/partials/new-sample-card.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","pointer");
              var el2 = dom.createTextNode("Archive sample");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["openArchiveModal","samples",["get","sample",["loc",[null,[37,74],[37,80]]]],"#new-look",["get","lookToAdd",["loc",[null,[37,93],[37,102]]]]],[],["loc",[null,[37,36],[37,104]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 12
              },
              "end": {
                "line": 39,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/collections/partials/new-sample-card.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","userLogged.isStarter",["loc",[null,[36,24],[36,44]]]]],[],0,null,["loc",[null,[36,14],[38,25]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 10
            },
            "end": {
              "line": 40,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/collections/partials/new-sample-card.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","pointer");
          var el2 = dom.createTextNode("Duplicate sample");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","pointer");
          var el2 = dom.createTextNode("Clear all fields");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[30,63],[30,72]]]],["get","sample",["loc",[null,[30,73],[30,79]]]],"duplicate"],[],["loc",[null,[30,32],[30,93]]]],
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[31,63],[31,72]]]],["get","sample",["loc",[null,[31,73],[31,79]]]],"clear"],[],["loc",[null,[31,32],[31,89]]]],
          ["block","if",[["get","sample.isNew",["loc",[null,[33,18],[33,30]]]]],[],0,1,["loc",[null,[33,12],[39,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 183,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/collections/partials/new-sample-card.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","sample-wrapper clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","collapse-header clearfix collapsed");
        dom.setAttribute(el2,"data-toggle","collapse");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-6");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("strong");
        dom.setAttribute(el5,"class","text-uppercase");
        var el6 = dom.createTextNode("Sample ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" - BARCODE: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-uppercase");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-4");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","text-uppercase");
        var el6 = dom.createTextNode("SHOWROOM: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","color_primary text-uppercase");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","collapse-inner clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-3 no-padding-left");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-9 no-padding-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","sample-detail row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Colour 1*:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Colour 2:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Colour 3:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Pattern 1:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Pattern 2:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Pattern 3:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Material 1*:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Material 2:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-2");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Material 3:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-3");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Size:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-3");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Manufacturer:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n           ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","dashed-line pull-left");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-4");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Retail price:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-4");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Wholesale:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-4");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Sale price:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","dashed-line pull-left");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-6");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Style #:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n           ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","validated-select col-xs-6");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Reference:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","dashed-line pull-left");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-6");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Sample description - Public(300 characters max.):");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-6");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","color_primary show");
        var el8 = dom.createTextNode("Sample description - Private:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element6, [3]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3, 1]);
        var morphs = new Array(28);
        morphs[0] = dom.createAttrMorph(element6, 'id');
        morphs[1] = dom.createAttrMorph(element7, 'data-target');
        morphs[2] = dom.createMorphAt(element10,1,1);
        morphs[3] = dom.createMorphAt(element10,3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element8, [3, 1, 1]),0,0);
        morphs[6] = dom.createAttrMorph(element11, 'class');
        morphs[7] = dom.createAttrMorph(element11, 'id');
        morphs[8] = dom.createMorphAt(element13,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element14, [1]),3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(element14, [3]),3,3);
        morphs[12] = dom.createMorphAt(dom.childAt(element14, [5]),3,3);
        morphs[13] = dom.createMorphAt(dom.childAt(element14, [7]),3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element14, [9]),3,3);
        morphs[15] = dom.createMorphAt(dom.childAt(element14, [11]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element14, [13]),3,3);
        morphs[17] = dom.createMorphAt(dom.childAt(element14, [15]),3,3);
        morphs[18] = dom.createMorphAt(dom.childAt(element14, [17]),3,3);
        morphs[19] = dom.createMorphAt(dom.childAt(element14, [19]),3,3);
        morphs[20] = dom.createMorphAt(dom.childAt(element14, [21]),3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element14, [25]),3,3);
        morphs[22] = dom.createMorphAt(dom.childAt(element14, [27]),3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element14, [29]),3,3);
        morphs[24] = dom.createMorphAt(dom.childAt(element14, [33]),3,3);
        morphs[25] = dom.createMorphAt(dom.childAt(element14, [35]),3,3);
        morphs[26] = dom.createMorphAt(dom.childAt(element14, [39]),3,3);
        morphs[27] = dom.createMorphAt(dom.childAt(element14, [41]),3,3);
        return morphs;
      },
      statements: [
        ["attribute","id",["concat",["sample-card-",["get","sample.id",["loc",[null,[1,55],[1,64]]]]]]],
        ["attribute","data-target",["concat",["#",["get","groupIndex",["loc",[null,[2,89],[2,99]]]],["get","sample.position",["loc",[null,[2,103],[2,118]]]]]]],
        ["content","sample.globalPosition",["loc",[null,[5,46],[5,71]]]],
        ["content","sample.barcode",["loc",[null,[5,83],[5,101]]]],
        ["content","sample.itemLabel",["loc",[null,[6,37],[6,57]]]],
        ["content","sample.showroom.city",["loc",[null,[9,90],[9,114]]]],
        ["attribute","class",["concat",["collapse ",["subexpr","if",[["get","sample.isNew",["loc",[null,[13,28],[13,40]]]],"in"],[],["loc",[null,[13,23],[13,47]]]]]]],
        ["attribute","id",["concat",[["get","groupIndex",["loc",[null,[13,55],[13,65]]]],["get","sample.position",["loc",[null,[13,69],[13,84]]]]]]],
        ["inline","validated-select",[],["placeholder","Search items...","content",["subexpr","@mut",[["get","filteredItems",["loc",[null,[18,30],[18,43]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[19,30],[19,36]]]]],[],[]],"property","item","optionLabelPath","name","optionValuePath","id","class","col-xs-12 no-padding-left hint-left"],["loc",[null,[16,10],[24,12]]]],
        ["block","if",[["get","sample.archived",["loc",[null,[26,16],[26,31]]]]],[],0,1,["loc",[null,[26,10],[40,17]]]],
        ["inline","validated-select",[],["placeholder","---","content",["subexpr","@mut",[["get","colours",["loc",[null,[49,38],[49,45]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[50,38],[50,44]]]]],[],[]],"property","colour1","optionLabelPath","safeString","optionValuePath","name","class","hint-left"],["loc",[null,[47,12],[55,14]]]],
        ["inline","select-2",[],["placeholder","---","content",["subexpr","@mut",[["get","colours",["loc",[null,[61,38],[61,45]]]]],[],[]],"value",["subexpr","@mut",[["get","sample.colour2",["loc",[null,[62,38],[62,52]]]]],[],[]],"searchEnabled",true,"optionLabelPath","safeString","optionValuePath","name"],["loc",[null,[59,12],[66,14]]]],
        ["inline","select-2",[],["placeholder","---","content",["subexpr","@mut",[["get","colours",["loc",[null,[72,38],[72,45]]]]],[],[]],"value",["subexpr","@mut",[["get","sample.colour3",["loc",[null,[73,38],[73,52]]]]],[],[]],"searchEnabled",true,"optionLabelPath","safeString","optionValuePath","name"],["loc",[null,[70,12],[77,14]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[83,36],[83,42]]]]],[],[]],"property","pattern1","type","text","label","Pattern","class","col-xs-12 no-padding"],["loc",[null,[83,12],[83,121]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[87,36],[87,42]]]]],[],[]],"property","pattern2","type","text","label","Pattern","class","col-xs-12 no-padding"],["loc",[null,[87,12],[87,121]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[91,36],[91,42]]]]],[],[]],"property","pattern3","type","text","label","Pattern","class","col-xs-12 no-padding"],["loc",[null,[91,12],[91,121]]]],
        ["inline","validated-select",[],["placeholder","---","content",["subexpr","@mut",[["get","fabrics",["loc",[null,[100,38],[100,45]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[101,38],[101,44]]]]],[],[]],"property","fabric1","optionLabelPath","name","optionValuePath","name","class","hint-left"],["loc",[null,[98,12],[106,14]]]],
        ["inline","select-2",[],["placeholder","---","content",["subexpr","@mut",[["get","fabrics",["loc",[null,[112,32],[112,39]]]]],[],[]],"value",["subexpr","@mut",[["get","sample.fabric2",["loc",[null,[113,32],[113,46]]]]],[],[]],"optionLabelPath","name","optionValuePath","name","searchEnabled",true],["loc",[null,[110,12],[117,14]]]],
        ["inline","select-2",[],["placeholder","---","content",["subexpr","@mut",[["get","fabrics",["loc",[null,[123,32],[123,39]]]]],[],[]],"value",["subexpr","@mut",[["get","sample.fabric3",["loc",[null,[124,32],[124,46]]]]],[],[]],"optionLabelPath","name","optionValuePath","name","searchEnabled",true],["loc",[null,[121,12],[128,14]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[134,36],[134,42]]]]],[],[]],"property","size","type","text","label","Size","class","col-xs-12 no-padding"],["loc",[null,[134,12],[134,114]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[138,35],[138,41]]]]],[],[]],"property","manufacturer","type","text","label","Manufacturer","class","col-xs-12 no-padding"],["loc",[null,[138,11],[138,129]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[145,36],[145,42]]]]],[],[]],"property","retailPrice","type","text","label","Retail price","class","col-xs-12 no-padding"],["loc",[null,[145,12],[145,129]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[149,36],[149,42]]]]],[],[]],"property","wholesale","type","text","label","Wholesale","class","col-xs-12 no-padding"],["loc",[null,[149,12],[149,124]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[153,36],[153,42]]]]],[],[]],"property","salePrice","type","text","label","Sale price","class","col-xs-12 no-padding"],["loc",[null,[153,12],[153,125]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","sample",["loc",[null,[160,35],[160,41]]]]],[],[]],"property","styleNumber","type","text","label","Style #","class","col-xs-12 no-padding"],["loc",[null,[160,11],[160,123]]]],
        ["inline","validated-input",[],["type","text","model",["subexpr","@mut",[["get","sample",["loc",[null,[164,48],[164,54]]]]],[],[]],"property","reference","label","Reference","class","col-xs-12 no-padding"],["loc",[null,[164,12],[164,124]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","sample.description",["loc",[null,[171,29],[171,47]]]]],[],[]],"placeholder","Optional but highly recommended. (300 characters allowed)","maxlength","300","cols","80","rows","2","class","col-xs-12"],["loc",[null,[171,12],[171,174]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","sample.note",["loc",[null,[176,29],[176,40]]]]],[],[]],"placeholder","Optional","cols","80","rows","2","class","col-xs-12"],["loc",[null,[176,12],[176,102]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});