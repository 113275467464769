define('bookalook/helpers/if-or', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    return params.some(function (p) {
      return p;
    });
  });

});