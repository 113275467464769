define('bookalook/tests/routes/inventory/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/inventory/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/inventory/index.js should pass jshint.\nroutes/inventory/index.js: line 426, col 6, Missing semicolon.\nroutes/inventory/index.js: line 453, col 6, Missing semicolon.\nroutes/inventory/index.js: line 479, col 6, Missing semicolon.\nroutes/inventory/index.js: line 506, col 6, Missing semicolon.\nroutes/inventory/index.js: line 688, col 31, [\'zpl\'] is better written in dot notation.\nroutes/inventory/index.js: line 712, col 31, [\'zpl\'] is better written in dot notation.\n\n6 errors');
  });

});