define('bookalook/tests/helpers/_then', ['bookalook/tests/helpers/test-helpers'], function (test_helpers) {

  'use strict';

  test_helpers._then(/^I should be on (.*) page$/, function (page, assert, assertStr) {
    andThen(function () {
      return assert.equal(currentURL(), page, assertStr);
    });
  });

  test_helpers._then(/^I should see (.*) in (.*)$/, function (msg, div, assert, assertStr) {
    andThen(function () {
      return assert.equal(find(div).text().trim(), msg, assertStr);
    });
  });

  test_helpers._then(/^I should see modal "(.*)"$/, function (id, assert, assertStr) {
    waitForModalOpen(id);
    andThen(function () {
      return assert.ok(find(id).hasClass('in'), assertStr);
    });
  });

});