define('bookalook/helpers/if-equal-two-parameters', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var o2 = params[1];
    var o3 = params[2];

    var result = false;

    if (o1 === o2 || o1 === o3) {
      result = true;
    }

    return result;
  });

});