define('bookalook/tests/models/booking.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/booking.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'models/booking.js should pass jshint.\nmodels/booking.js: line 281, col 85, Missing semicolon.\nmodels/booking.js: line 292, col 84, Missing semicolon.\nmodels/booking.js: line 583, col 17, Missing semicolon.\n\n3 errors');
  });

});