define('bookalook/helpers/sanitize-string', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var string = params[0];
    var outString = undefined;
    string = params[0].replace(/ /g, '');
    outString = string.replace(/[`~!@$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    return outString;
  });

});