define('bookalook/components/page-footer', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    isActive: {},
    actions: {
      changeClass: function changeClass(network) {
        this.toggleProperty('isActive.' + network);
      },
      requestMyInvite: function requestMyInvite() {
        utils['default'].requestMyInvite(this);
      }
    }
  });

});