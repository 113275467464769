define('bookalook/components/pagination-element', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    /*  - Attributes -
     *   · route -> route
     *   · nPages -> num of pages
     */

    currentPage: 1,
    pagesToShow: [],
    enabledNextButtons: true,
    enabledPrevButtons: true,

    //<ACTIONS>
    actions: {
      nextPage: function nextPage() {
        this.onNextPage();
      },
      previousPage: function previousPage() {
        this.onPreviousPage();
      },
      firstPage: function firstPage() {
        this.onFirstPage();
      },
      lastPage: function lastPage() {
        this.onLastPage();
      },
      targetPage: function targetPage(page) {
        this.onTargetPage(page);
      },
      changePageWithoutSearch: function changePageWithoutSearch(page) {
        this.set('currentPage', page);
      }
    },
    //</ACTIONS>

    didInsertElement: function didInsertElement() {
      this.pagesToShowObserver();
    },

    pagesToShowObserver: (function () {
      // Clear code, but it needs a refactor.
      var currentPage = this.get('currentPage');
      var firstPagePaginator = 0;
      var lastPagePaginator = 0;

      if (currentPage > 5) {
        firstPagePaginator = currentPage - 4;
        lastPagePaginator = 5 + currentPage;
      } else {
        firstPagePaginator = 1;
        lastPagePaginator = 10;
      }

      if (lastPagePaginator > this.get('nPages')) {
        lastPagePaginator = this.get('nPages');
      }

      if (currentPage > this.get('nPages') - 5) {
        // decrease firstpagepaginator in order to show 10 page buttons when you
        // are in the last 5 pages.
        firstPagePaginator = firstPagePaginator - (currentPage - (this.get('nPages') - 5));
      }

      if (firstPagePaginator < 1) {
        // First page can't be negative
        firstPagePaginator = 1;
      }

      this.set('enabledPrevButtons', currentPage > 1);
      this.set('enabledNextButtons', currentPage < this.get('nPages'));
      this.set('enabledNumPage', this.get('nPages') > 1);

      this.fulfillPagesToShow(firstPagePaginator, lastPagePaginator);
    }).observes('currentPage', 'nPages'),

    fulfillPagesToShow: function fulfillPagesToShow(first, last) {
      var pagesToShow = this.get('pagesToShow');
      var isCurrentPage = false;
      pagesToShow.clear();
      for (var i = first; i <= last; i++) {
        isCurrentPage = i === this.get('currentPage');
        pagesToShow.addObject({ 'page': i, 'current': isCurrentPage });
      }
    },

    //<ON ACTIONS>
    onNextPage: function onNextPage() {
      this.incrementProperty('currentPage');
      this.get('route')._searchTargetPage(this.get('currentPage'));
    },

    onPreviousPage: function onPreviousPage() {
      this.decrementProperty('currentPage');
      this.get('route')._searchTargetPage(this.get('currentPage'));
    },

    onFirstPage: function onFirstPage() {
      this.set('currentPage', 1);
      this.get('route')._searchTargetPage(1);
    },

    onTargetPage: function onTargetPage(page) {
      this.set('currentPage', page);
      this.get('route')._searchTargetPage(page);
    },

    onLastPage: function onLastPage() {
      this.set('currentPage', this.get('nPages'));
      this.get('route')._searchTargetPage(this.get('currentPage'));
    }
  });
  //</ON ACTIONS>

});