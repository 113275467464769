define('bookalook/templates/global/partials/sample-edit-card-toggle', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 16
              },
              "end": {
                "line": 36,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Activate sample");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [
            ["element","action",["activateSample",["get","sample",["loc",[null,[33,49],[33,55]]]],"#new-look"],[],["loc",[null,[33,23],[33,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 18
                },
                "end": {
                  "line": 41,
                  "column": 18
                }
              },
              "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Archive sample");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element3);
              return morphs;
            },
            statements: [
              ["element","action",["openArchiveModal","samples",["get","sample",["loc",[null,[38,63],[38,69]]]],"#new-look",["get","lookToAdd",["loc",[null,[38,82],[38,91]]]]],[],["loc",[null,[38,25],[38,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 18
                },
                "end": {
                  "line": 46,
                  "column": 18
                }
              },
              "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Duplicate sample");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[43,56],[43,65]]]],["get","sample",["loc",[null,[43,66],[43,72]]]],"duplicate"],[],["loc",[null,[43,25],[43,86]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 16
              },
              "end": {
                "line": 47,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","userLogged.isStarter",["loc",[null,[37,28],[37,48]]]]],[],0,null,["loc",[null,[37,18],[41,29]]]],
            ["block","if",[["get","sample.isMain",["loc",[null,[42,24],[42,37]]]]],[],1,null,["loc",[null,[42,18],[46,25]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 14
            },
            "end": {
              "line": 51,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Delete sample");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [2]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element5);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sample.archived",["loc",[null,[32,22],[32,37]]]]],[],0,1,["loc",[null,[32,16],[47,23]]]],
          ["element","action",["openDeleteModal","samples",["get","sample",["loc",[null,[48,58],[48,64]]]],"#new-look",["get","lookToAdd",["loc",[null,[48,77],[48,86]]]]],[],["loc",[null,[48,21],[48,88]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 16
              },
              "end": {
                "line": 56,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Duplicate sample");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[53,54],[53,63]]]],["get","sample",["loc",[null,[53,64],[53,70]]]],"duplicate"],[],["loc",[null,[53,23],[53,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 14
            },
            "end": {
              "line": 60,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Delete sample");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [2]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sample.isMain",["loc",[null,[52,22],[52,35]]]]],[],0,null,["loc",[null,[52,16],[56,23]]]],
          ["element","action",["manageSampleNewLook",["get","lookToAdd",["loc",[null,[57,52],[57,61]]]],["get","sample",["loc",[null,[57,62],[57,68]]]],"delete"],[],["loc",[null,[57,21],[57,79]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 6
          }
        },
        "moduleName": "bookalook/templates/global/partials/sample-edit-card-toggle.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Sample Card Toggle  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"data-toggle","collapse");
        dom.setAttribute(el2,"data-parent","#edit-look-samples-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","info-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","images/forward.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","info-labels");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","input_label_value using-inline-combo-edition");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","input_label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","count-container");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("/");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","dots-container no-collapsable");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flat-dropdown dd-look-list has-pophover-ui-refactor bottom hover-disabled");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","dots_horizontal_btn");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","icon");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pophover-wrapper-ui-refactor");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","pophover");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","flat-dropdown-list look-list-dropdown");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","footer-color");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [2]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [1, 3]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element7, [3]);
        var element11 = dom.childAt(element7, [5, 1]);
        var element12 = dom.childAt(element6, [3]);
        var morphs = new Array(14);
        morphs[0] = dom.createAttrMorph(element6, 'class');
        morphs[1] = dom.createAttrMorph(element7, 'class');
        morphs[2] = dom.createAttrMorph(element7, 'data-target');
        morphs[3] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
        morphs[4] = dom.createMorphAt(element9,0,0);
        morphs[5] = dom.createMorphAt(element9,1,1);
        morphs[6] = dom.createMorphAt(element10,0,0);
        morphs[7] = dom.createMorphAt(element10,2,2);
        morphs[8] = dom.createAttrMorph(element11, 'id');
        morphs[9] = dom.createElementMorph(element11);
        morphs[10] = dom.createMorphAt(dom.childAt(element11, [3, 1, 1]),1,1);
        morphs[11] = dom.createAttrMorph(element12, 'id');
        morphs[12] = dom.createAttrMorph(element12, 'class');
        morphs[13] = dom.createMorphAt(dom.childAt(element12, [1]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["sample-container card-gradient-border ",["subexpr","if",[["get","sample.archived",["loc",[null,[2,55],[2,70]]]],"archived"],[],["loc",[null,[2,50],[2,83]]]]]]],
        ["attribute","class",["concat",["sample-toggle ",["subexpr","unless",[["get","sample.isNew",["loc",[null,[3,37],[3,49]]]],"collapsed"],[],["loc",[null,[3,28],[3,63]]]]," pointer"]]],
        ["attribute","data-target",["concat",["#toggle_sample_edit_",["get","groupIndex",["loc",[null,[3,131],[3,141]]]],["get","sample.position",["loc",[null,[3,145],[3,160]]]]]]],
        ["inline","inline-combo-edition",[],["content",["subexpr","@mut",[["get","filteredItems",["loc",[null,[9,30],[9,43]]]]],[],[]],"model",["subexpr","@mut",[["get","sample",["loc",[null,[10,30],[10,36]]]]],[],[]],"property","item","optionLabelPath","name","optionValuePath","id","value",["subexpr","@mut",[["get","sample.itemLabel",["loc",[null,[14,30],[14,46]]]]],[],[]]],["loc",[null,[8,10],[15,12]]]],
        ["content","sample.barcode",["loc",[null,[17,34],[17,52]]]],
        ["inline","if",[["get","sample.archived",["loc",[null,[17,57],[17,72]]]]," | ARCHIVED",""],[],["loc",[null,[17,52],[17,91]]]],
        ["inline","plus-one",[["get","sampleIndex",["loc",[null,[20,44],[20,55]]]]],[],["loc",[null,[20,33],[20,57]]]],
        ["content","sampleGroup.all.length",["loc",[null,[20,58],[20,84]]]],
        ["attribute","id",["concat",["dd-editlook-sample-",["get","groupIndex",["loc",[null,[22,36],[22,46]]]],["get","sample.position",["loc",[null,[22,50],[22,65]]]]]]],
        ["element","action",["toggleActiveClass",["subexpr","concat",["#dd-editlook-sample-",["get","groupIndex",["loc",[null,[24,59],[24,69]]]],["get","sample.position",["loc",[null,[24,70],[24,85]]]]],[],["loc",[null,[24,28],[24,86]]]]],[],["loc",[null,[23,93],[24,88]]]],
        ["block","unless",[["get","sample.isNew",["loc",[null,[31,24],[31,36]]]]],[],0,1,["loc",[null,[31,14],[60,25]]]],
        ["attribute","id",["concat",["toggle_sample_edit_",["get","groupIndex",["loc",[null,[67,32],[67,42]]]],["get","sample.position",["loc",[null,[67,46],[67,61]]]]]]],
        ["attribute","class",["concat",["collapse sample-toggle-content ",["subexpr","if",[["get","sample.isNew",["loc",[null,[67,108],[67,120]]]],"in"],[],["loc",[null,[67,103],[67,127]]]]]]],
        ["inline","partial",["global/partials/sample-edit-card-content"],[],["loc",[null,[69,6],[69,60]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});