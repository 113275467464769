define('bookalook/templates/bookings/partials/booking-header', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 14
            },
            "end": {
              "line": 9,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  Foreign Booking\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 14
            },
            "end": {
              "line": 11,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","if",[["get","booking.job.id",["loc",[null,[10,21],[10,35]]]],"Requested Booking","Manual Booking"],[],["loc",[null,[10,16],[10,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 14
            },
            "end": {
              "line": 23,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element16, 'src');
          morphs[1] = dom.createAttrMorph(element16, 'title');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","booking.owner.avatar",["loc",[null,[22,30],[22,50]]]]]]],
          ["attribute","title",["concat",[["get","booking.owner.fullName",["loc",[null,[22,63],[22,85]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 10
            },
            "end": {
              "line": 30,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","booking-members has-pophover left");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","color_primary");
          var el3 = dom.createTextNode("+");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
          morphs[1] = dom.createMorphAt(element15,3,3);
          return morphs;
        },
        statements: [
          ["content","booking.membersNumber",["loc",[null,[27,47],[27,72]]]],
          ["inline","partial",["bookings/pophovers/booking-members"],[],["loc",[null,[28,16],[28,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 8
            },
            "end": {
              "line": 55,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","clearboth");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","booking-date-call pull-left");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","color_primary line-title");
          var el4 = dom.createTextNode("CALL-IN START - CALL IN END");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","line-date");
          var el4 = dom.createTextNode("| ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","booking-date-job-return pull-right");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","color_primary line-title");
          var el4 = dom.createTextNode("JOB RETURN START - JOB RETURN END");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","line-date");
          var el4 = dom.createTextNode("| ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var element13 = dom.childAt(element12, [1, 3]);
          var element14 = dom.childAt(element12, [3, 3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element13,1,1);
          morphs[1] = dom.createMorphAt(element13,3,3);
          morphs[2] = dom.createMorphAt(element14,1,1);
          morphs[3] = dom.createMorphAt(element14,3,3);
          return morphs;
        },
        statements: [
          ["inline","date-format",[["get","booking.job.callInDateStart",["loc",[null,[48,54],[48,81]]]]],[],["loc",[null,[48,40],[48,83]]]],
          ["inline","date-format",[["get","booking.job.callInDateEnd",["loc",[null,[48,100],[48,125]]]]],[],["loc",[null,[48,86],[48,127]]]],
          ["inline","date-format",[["get","booking.job.returnDateStart",["loc",[null,[52,54],[52,81]]]]],[],["loc",[null,[52,40],[52,83]]]],
          ["inline","date-format",[["get","booking.job.returnDateEnd",["loc",[null,[52,100],[52,125]]]]],[],["loc",[null,[52,86],[52,127]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 70
              },
              "end": {
                "line": 64,
                "column": 37
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                      BOOKING DETAILS");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 68
              },
              "end": {
                "line": 66,
                "column": 106
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("SHOPPING LIST");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 69
              },
              "end": {
                "line": 67,
                "column": 129
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("LOAN FORMS");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 10
            },
            "end": {
              "line": 70,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(fragment, [3]);
          var element11 = dom.childAt(fragment, [5]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element9, 'class');
          morphs[1] = dom.createMorphAt(element9,0,0);
          morphs[2] = dom.createAttrMorph(element10, 'class');
          morphs[3] = dom.createMorphAt(element10,0,0);
          morphs[4] = dom.createAttrMorph(element11, 'class');
          morphs[5] = dom.createMorphAt(element11,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["menu_tab ",["subexpr","if",[["get","isBookingDetails",["loc",[null,[63,41],[63,57]]]],"active"],[],["loc",[null,[63,36],[63,68]]]]]]],
          ["block","link-to",["bookings.booking-details",["get","booking.id",["loc",[null,[63,108],[63,118]]]],["subexpr","query-params",[],["search",""],["loc",[null,[63,119],[63,143]]]]],[],0,null,["loc",[null,[63,70],[64,49]]]],
          ["attribute","class",["concat",["menu_tab ",["subexpr","if",[["get","isShoppingList",["loc",[null,[65,41],[65,55]]]],"active"],[],["loc",[null,[65,36],[65,66]]]]]]],
          ["block","link-to",["bookings.shopping-list",["get","booking.id",["loc",[null,[65,104],[65,114]]]]],["id","shopping-list-open"],1,null,["loc",[null,[65,68],[66,118]]]],
          ["attribute","class",["concat",["menu_tab ",["subexpr","if",[["get","isLoanFormsList",["loc",[null,[67,41],[67,56]]]],"active"],[],["loc",[null,[67,36],[67,67]]]]]]],
          ["block","link-to",["bookings.loan-forms-list",["get","booking.id",["loc",[null,[67,107],[67,117]]]]],[],2,null,["loc",[null,[67,69],[67,141]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 18
                    },
                    "end": {
                      "line": 86,
                      "column": 18
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"data-toggle","modal");
                  dom.setAttribute(el1,"data-target","#manage-booking-samples-detail");
                  dom.setAttribute(el1,"class","btn_primary_plus");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" Add samples\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element7);
                  morphs[1] = dom.createMorphAt(element7,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["openManageModal"],[],["loc",[null,[82,44],[82,72]]]],
                  ["inline","fa-icon",["plus"],[],["loc",[null,[84,24],[84,42]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 86,
                      "column": 18
                    },
                    "end": {
                      "line": 92,
                      "column": 18
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"data-toggle","modal");
                  dom.setAttribute(el1,"data-target","#manage-proposal-samples-detail");
                  dom.setAttribute(el1,"class","btn_primary_plus");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" Add proposal\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element6);
                  morphs[1] = dom.createMorphAt(element6,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["openAddProposalModal",["get","booking",["loc",[null,[87,76],[87,83]]]]],[],["loc",[null,[87,44],[87,85]]]],
                  ["inline","fa-icon",["plus"],[],["loc",[null,[89,24],[89,42]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 94,
                      "column": 18
                    },
                    "end": {
                      "line": 96,
                      "column": 18
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"class","btn_cancel");
                  dom.setAttribute(el1,"data-toggle","modal");
                  dom.setAttribute(el1,"data-target","#delete-booking");
                  var el2 = dom.createTextNode("Cancel Booking");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 98,
                      "column": 18
                    },
                    "end": {
                      "line": 100,
                      "column": 18
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","button");
                  dom.setAttribute(el1,"class","btn_cancel");
                  var el2 = dom.createTextNode("Close Booking");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element5);
                  return morphs;
                },
                statements: [
                  ["element","action",["deleteBooking",["get","booking",["loc",[null,[99,84],[99,91]]]]],[],["loc",[null,[99,59],[99,93]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 16
                  },
                  "end": {
                    "line": 101,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"class","btn-plus raised");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createElementMorph(element8);
                morphs[1] = dom.createMorphAt(element8,1,1);
                morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
                morphs[4] = dom.createMorphAt(fragment,7,7,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["element","action",["reloadBooking"],[],["loc",[null,[78,64],[78,90]]]],
                ["inline","fa-icon",["refresh"],[],["loc",[null,[79,20],[79,41]]]],
                ["block","unless",[["get","booking.job",["loc",[null,[81,28],[81,39]]]]],[],0,1,["loc",[null,[81,18],[92,29]]]],
                ["block","if",[["get","booking.canBeCancelled",["loc",[null,[94,24],[94,46]]]]],[],2,null,["loc",[null,[94,18],[96,25]]]],
                ["block","if",[["get","booking.canBeClosed",["loc",[null,[98,24],[98,43]]]]],[],3,null,["loc",[null,[98,18],[100,25]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 16
                  },
                  "end": {
                    "line": 107,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"class","btn_primary_plus");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" New loan form\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element4);
                morphs[1] = dom.createMorphAt(element4,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["openLoanFormTypeModal",""],[],["loc",[null,[104,40],[104,77]]]],
                ["inline","fa-icon",["plus"],[],["loc",[null,[105,20],[105,38]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 111,
                        "column": 18
                      },
                      "end": {
                        "line": 115,
                        "column": 18
                      }
                    },
                    "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1,"type","button");
                    dom.setAttribute(el1,"class","btn_primary_plus");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" Edit details\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","fa-icon",["plus"],[],["loc",[null,[113,22],[113,40]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 16
                    },
                    "end": {
                      "line": 116,
                      "column": 16
                    }
                  },
                  "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["bookings.change-dates",["get","booking.id",["loc",[null,[111,53],[111,63]]]]],[],0,null,["loc",[null,[111,18],[115,30]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 109,
                    "column": 16
                  },
                  "end": {
                    "line": 117,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","isBookingDetails",["loc",[null,[110,22],[110,38]]]]],[],0,null,["loc",[null,[110,16],[116,23]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 14
                },
                "end": {
                  "line": 118,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","isShoppingList",["loc",[null,[77,22],[77,36]]]]],[],0,null,["loc",[null,[77,16],[101,23]]]],
              ["block","if",[["get","isLoanFormsList",["loc",[null,[103,22],[103,37]]]]],[],1,null,["loc",[null,[103,16],[107,23]]]],
              ["block","if",[["get","booking.canBeCancelled",["loc",[null,[109,22],[109,44]]]]],[],2,null,["loc",[null,[109,16],[117,23]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 14
                },
                "end": {
                  "line": 120,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","btn_primary margin-bottom");
              dom.setAttribute(el1,"data-toggle","modal");
              dom.setAttribute(el1,"data-target","#join-booking");
              var el2 = dom.createTextNode("Join Booking");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 12
              },
              "end": {
                "line": 121,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","isBookingMember",["loc",[null,[76,20],[76,35]]]]],[],0,1,["loc",[null,[76,14],[120,21]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 122,
                  "column": 14
                },
                "end": {
                  "line": 124,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","btn_cancel");
              var el2 = dom.createTextNode("Delete Booking");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element3);
              return morphs;
            },
            statements: [
              ["element","action",["cancelBooking",["get","booking",["loc",[null,[123,63],[123,70]]]],"delete"],[],["loc",[null,[123,38],[123,81]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 14
                },
                "end": {
                  "line": 127,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","btn_primary margin-bottom");
              dom.setAttribute(el1,"data-toggle","modal");
              dom.setAttribute(el1,"data-target","#join-booking");
              var el2 = dom.createTextNode("Join Booking");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 121,
                "column": 12
              },
              "end": {
                "line": 128,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","isShoppingList",["loc",[null,[122,20],[122,34]]]]],[],0,null,["loc",[null,[122,14],[124,21]]]],
            ["block","unless",[["get","isBookingMember",["loc",[null,[125,24],[125,39]]]]],[],1,null,["loc",[null,[125,14],[127,25]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 10
            },
            "end": {
              "line": 129,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","booking.closed",["loc",[null,[75,22],[75,36]]]]],[],0,1,["loc",[null,[75,12],[128,23]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 16
                  },
                  "end": {
                    "line": 140,
                    "column": 16
                  }
                },
                "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"type","button");
                dom.setAttribute(el1,"data-toggle","modal");
                dom.setAttribute(el1,"data-target","#manage-proposal-samples-detail");
                dom.setAttribute(el1,"class","btn_primary_plus");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" Add proposal\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element1);
                morphs[1] = dom.createMorphAt(element1,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["openAddProposalModal",["get","booking",["loc",[null,[136,74],[136,81]]]]],[],["loc",[null,[136,42],[136,83]]]],
                ["inline","fa-icon",["plus"],[],["loc",[null,[138,22],[138,40]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 131,
                  "column": 14
                },
                "end": {
                  "line": 141,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","btn-plus raised");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(element2,1,1);
              morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["element","action",["reloadBooking"],[],["loc",[null,[132,62],[132,88]]]],
              ["inline","fa-icon",["refresh"],[],["loc",[null,[133,18],[133,39]]]],
              ["block","if",[["get","booking.job",["loc",[null,[135,22],[135,33]]]]],[],0,null,["loc",[null,[135,16],[140,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 142,
                  "column": 14
                },
                "end": {
                  "line": 146,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","btn-plus");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","images/plus-header.png");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("New loan form\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["openLoanFormTypeModal",""],[],["loc",[null,[143,57],[143,94]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 130,
                "column": 12
              },
              "end": {
                "line": 147,
                "column": 12
              }
            },
            "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","isShoppingList",["loc",[null,[131,20],[131,34]]]]],[],0,null,["loc",[null,[131,14],[141,21]]]],
            ["block","if",[["get","isLoanFormsList",["loc",[null,[142,20],[142,35]]]]],[],1,null,["loc",[null,[142,14],[146,21]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 129,
              "column": 10
            },
            "end": {
              "line": 148,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","isBookingMember",["loc",[null,[130,18],[130,33]]]]],[],0,null,["loc",[null,[130,12],[147,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 158,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/partials/booking-header.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","header-wrapper clearfix");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-6 no-padding-left");
        dom.setAttribute(el2,"id","test_div_header_booking");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3,"class","pull-left section_title");
        dom.setAttribute(el3,"id","test_booking_number");
        var el4 = dom.createTextNode("Booking #");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-info pull-left margin-top-x2-important");
        var el4 = dom.createTextNode("\n           ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strong");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n           ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strong");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","booking-involved col-xs-6 no-padding-right text-right");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","involved_colleagues");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("Involved colleagues");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","involved-images clearfix pull-right");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","booking-author");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","booking-date");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clearboth");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","booking-date-check pull-left");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","color_primary line-title");
        var el6 = dom.createTextNode("CHECK-OUT - CHECK-IN");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","line-date");
        var el6 = dom.createTextNode("| ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" - ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","booking-date-job pull-right");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","color_primary line-title");
        var el6 = dom.createTextNode("JOB START - JOB END");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","line-date");
        var el6 = dom.createTextNode("| ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" - ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","menu_tab_wrapper clearfix");
        dom.setAttribute(el1,"id","test_div_menu_booking");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 btn-group");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 text-right pull-right");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element17 = dom.childAt(fragment, [0]);
        var element18 = dom.childAt(element17, [1]);
        var element19 = dom.childAt(element18, [3]);
        var element20 = dom.childAt(element17, [3, 3]);
        var element21 = dom.childAt(element17, [5]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element22, [1, 3]);
        var element24 = dom.childAt(element22, [3, 3]);
        var element25 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(17);
        morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element19, [4]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
        morphs[4] = dom.createMorphAt(element20,3,3);
        morphs[5] = dom.createMorphAt(element23,1,1);
        morphs[6] = dom.createMorphAt(element23,3,3);
        morphs[7] = dom.createMorphAt(element24,1,1);
        morphs[8] = dom.createMorphAt(element24,3,3);
        morphs[9] = dom.createMorphAt(element21,3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(element25, [1]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element25, [3]),1,1);
        morphs[12] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[13] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[14] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,12,12,contextualElement);
        return morphs;
      },
      statements: [
        ["content","booking.showroomCustomId",["loc",[null,[3,78],[3,106]]]],
        ["content","booking.company",["loc",[null,[5,28],[5,47]]]],
        ["block","if",[["get","booking.isForeign",["loc",[null,[7,20],[7,37]]]]],[],0,1,["loc",[null,[7,14],[11,21]]]],
        ["block","if",[["get","booking.owner",["loc",[null,[21,20],[21,33]]]]],[],2,null,["loc",[null,[21,14],[23,21]]]],
        ["block","if",[["get","booking.hasMembers",["loc",[null,[25,16],[25,34]]]]],[],3,null,["loc",[null,[25,10],[30,17]]]],
        ["inline","date-format",[["get","booking.checkout",["loc",[null,[37,52],[37,68]]]]],[],["loc",[null,[37,38],[37,70]]]],
        ["inline","date-format",[["get","booking.checkin",["loc",[null,[37,87],[37,102]]]]],[],["loc",[null,[37,73],[37,104]]]],
        ["inline","date-format",[["get","booking.jobDateStart",["loc",[null,[41,52],[41,72]]]]],[],["loc",[null,[41,38],[41,74]]]],
        ["inline","date-format",[["get","booking.jobDateEnd",["loc",[null,[41,91],[41,109]]]]],[],["loc",[null,[41,77],[41,111]]]],
        ["block","if",[["get","booking.job.id",["loc",[null,[44,14],[44,28]]]]],[],4,null,["loc",[null,[44,8],[55,15]]]],
        ["block","if",[["get","isBookingMember",["loc",[null,[62,16],[62,31]]]]],[],5,null,["loc",[null,[62,10],[70,17]]]],
        ["block","unless",[["get","booking.isForeign",["loc",[null,[74,20],[74,37]]]]],[],6,7,["loc",[null,[74,10],[148,21]]]],
        ["inline","partial",["bookings/modals/join-booking"],[],["loc",[null,[153,0],[153,42]]]],
        ["inline","partial",["bookings/modals/delete-booking"],[],["loc",[null,[154,0],[154,44]]]],
        ["inline","partial",["bookings/modals/manage-booking-samples-detail"],[],["loc",[null,[155,0],[155,59]]]],
        ["inline","partial",["bookings/modals/select-new-loan-form-type"],[],["loc",[null,[156,0],[156,55]]]],
        ["inline","partial",["bookings/modals/manage-proposal-samples-detail"],[],["loc",[null,[157,0],[157,60]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});