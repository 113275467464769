define('bookalook/helpers/get-array-object-parameter', ['exports'], function (exports) {

    'use strict';

    exports['default'] = Ember.Helper.helper(function (params) {
        var array = params[0];
        var index = params[1];
        var param = params[2];
        if (array !== undefined && index !== undefined && param !== undefined) {
            return array.get(index)[param];
        }
    });

});