define('bookalook/models/album', ['exports', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment', 'ember'], function (exports, DS, Validator, config, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    albumType: DS['default'].attr('string'),
    other: DS['default'].attr('string'),
    archived: DS['default'].attr('boolean'),
    archivedDate: DS['default'].attr('day'),
    published: DS['default'].attr('boolean'),
    avatar: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_IMAGE }),
    privacy: DS['default'].attr('string'),
    thumbnail: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_IMAGE }),

    lastPublished: DS['default'].attr('day'),
    lastSaved: DS['default'].attr('day'),

    collection: DS['default'].belongsTo('collection', { async: true }),
    looks: DS['default'].hasMany('look', { async: true }),

    orderedLooks: (function () {
      return this.get('looks').sortBy('position');
    }).property('looks.@each.position'),

    activeLooks: (function () {
      // If album is archived show all looks
      var isArchived = this.get('archived');
      return this.get('looks').filter(function (l) {
        return isArchived || !l.get('archived');
      }).sortBy('position');
    }).property('looks.@each.position', 'looks.@each.archived'),

    activeAvatarLooks: (function () {
      // If album is archived show all looks
      var isArchived = this.get('archived');
      return this.get('looks').filter(function (l) {
        return isArchived || !l.get('archived');
      }).sortBy('position');
    }).property('looks.@each.position', 'looks.@each.archived'),

    firstAvatar: (function () {
      return this.get('visibleLooks').get(0).get('getAvatars').get(0);
    }).property('looks'),

    firstVisibleAvatarImg: (function () {
      //Search a non-default image in album's looks
      var defaultAvatar = config['default'].DEFAULT_IMAGE;
      if (this.get('avatar') == config['default'].DEFAULT_IMAGE) {
        var looks = this.get('visibleLooks');

        var look = looks.content.find(function (look) {
          return look.get('getAvatars').any(function (avatar) {
            return avatar && avatar != defaultAvatar;
          });
        });

        if (look != null) {
          var avatar = look.get('getAvatars').find(function (avatar) {
            return avatar && avatar != defaultAvatar;
          });
          if (avatar != null) {
            return avatar.avatar;
          }
        }
        return defaultAvatar;
      } else {
        return this.get('avatar');
      }
    }).property('looks'),

    firstAvatarImg: (function () {
      //Search a non-default image in album's looks
      var defaultAvatar = config['default'].DEFAULT_IMAGE;
      if (this.get('avatar') == config['default'].DEFAULT_IMAGE) {
        var looks = this.get('looks');

        var look = looks.content.find(function (look) {
          return look.get('getAvatars').any(function (avatar) {
            return avatar && avatar != defaultAvatar;
          });
        });

        if (look != null) {
          var avatar = look.get('getAvatars').find(function (avatar) {
            return avatar && avatar != defaultAvatar;
          });
          if (avatar != null) {
            return avatar.avatar;
          }
        }
        return defaultAvatar;
      } else {
        return this.get('avatar');
      }
    }).property('looks'),

    visibleLooks: (function () {
      return this.get('activeLooks').filter(function (l) {
        return l.get('activeSamples.length');
      });
    }).property('activeLooks'),

    firstFiveLooks: (function () {
      return this.get('activeLooks').slice(0, 5);
    }).property('activeLooks'),

    firstFiveActiveAvatarLooks: (function () {
      return this.get('activeAvatarLooks').slice(0, 5);
    }).property('activeAvatarLooks'),

    albumTypeLabel: (function () {
      var type = this.get('albumType');
      if (type && type.toLowerCase() === 'other') {
        type = this.get('other');
      }
      return type;
    }).property('albumType', 'other'),

    noLooks: (function () {
      return this.get('looks.length') === 0;
    }).property('looks'),

    carouselId: (function () {
      return '#album-slider-' + this.get('id');
    }).property('id'),

    samples: (function () {
      var looks = this.get('looks');
      var samples = [];
      looks.forEach(function (look) {
        samples.addObjects(look.get('samples'));
      });
      return samples;
    }).property('looks.@each.samples.@each'),

    hasArchivedSamples: (function () {
      var looks = this.get('looks');
      var r = false;
      looks.forEach(function (look) {
        if (look.get('hasArchivedSamples')) {
          r = true;
        }
      });
      return r;
    }).property('looks.@each.hasArchivedSamples'),

    hasActiveSamples: (function () {
      var looks = this.get('looks');
      var r = false;
      looks.forEach(function (look) {
        if (look.get('hasActiveSamples')) {
          r = true;
        }
      });
      return r;
    }).property('looks.@each.hasActiveSamples'),

    hasCheckedSamples: (function () {
      var looks = this.get('looks');
      var r = false;
      looks.forEach(function (look) {
        if (look.get('hasCheckedSamples')) {
          r = true;
        }
      });
      return r;
    }).property('looks.@each.hasCheckedSamples'),

    hasVisibleSamples: (function () {
      var looks = this.get('looks');
      var r = false;
      looks.forEach(function (look) {
        r = r || look.get('hasVisibleSamples');
      });
      return r;
    }).property('looks.@each.hasVisibleSamples'),

    hasVisibleAndNotArchivedSamples: (function () {
      var looks = this.get('looks');
      var r = false;
      looks.forEach(function (look) {
        r = r || look.get('hasVisibleAndNotArchivedSamples');
      });
      return r;
    }).property('looks.@each.hasVisibleAndNotArchivedSamples'),

    hasVisibleAndNotRemovedSamples: (function () {
      var looks = this.get('looks');
      var r = false;
      looks.forEach(function (look) {
        r = r || look.get('hasVisibleAndNotRemovedSamples');
      });
      return r;
    }).property('looks.@each.hasVisibleAndNotRemovedSamples'),

    hasVisibleAndCheckedSamples: (function () {
      return this.get('looks').some(function (look) {
        return look.get('hasVisibleAndCheckedSamples');
      });
    }).property('looks.@each.hasVisibleAndCheckedSamples'),

    hasActiveLooks: (function () {
      var looks = this.get('looks');
      var hasActiveLooks = false;
      looks.some(function (look) {
        if (!look.get('archived')) {
          hasActiveLooks = true;
          return;
        }
      });
      return hasActiveLooks;
    }).property('looks'),

    isPrivate: (function () {
      var privacy = this.get('privacy');
      return privacy === "All my contacts";
    }).property('privacy'),

    isPublic: (function () {
      var privacy = this.get('privacy');
      return privacy === "Public";
    }).property('privacy'),

    isPrivateOption: (function () {
      var privacy = this.get('privacy');
      return privacy === "Private";
    }).property('privacy'),

    //Redo functions to better syntaxis (we should remove the other ones if we have time)
    hasPublicPrivacy: (function () {
      var privacy = this.get('privacy');
      return privacy === "Public";
    }).property('privacy'),
    hasPrivatePrivacy: (function () {
      var privacy = this.get('privacy');
      return privacy === "Private";
    }).property('privacy'),
    hasContactsPrivacy: (function () {
      var privacy = this.get('privacy');
      return privacy === "All my contacts";
    }).property('privacy'),

    getImage: (function () {
      var avatar = this.get('avatar');
      var thumbnail = this.get('thumbnail');
      var image = thumbnail;
      if (Ember['default'].isEmpty(thumbnail)) {
        image = avatar;
        if (Ember['default'].isEmpty(image)) {
          image = config['default'].DEFAULT_IMAGE;
        }
      }
      return image;
    }).property('avatar', 'thumbnail'),

    lookWithAvatars: (function () {
      var looks = this.get('looks');
      var result = [];
      looks.forEach(function (look) {
        if (look.get('avatars').length > 0) {
          result.push(look);
        }
      });
      return result;
    }).property('looks.@each.avatars.@each'),

    activedLooks: Ember['default'].computed.filterBy('looks', 'archived', false),
    archivedLooks: Ember['default'].computed.filterBy('looks', 'archived', true),

    allLooksCount: (function () {
      return this.get('looks').get('length');
    }).property("looks"),

    activedLooksCount: (function () {
      return this.get('activedLooks').get('length');
    }).property("activedLooks"),

    archivedLooksCount: (function () {
      return this.get('archivedLooks').get('length');
    }).property("archivedLooks"),

    albumTypeObserver: (function () {
      var type = this.get('albumType');
      if (type !== "Other") {
        this.set('other', '');
      }
    }).observes('albumType'),

    hasSamplesVisiblesAndAvailablesForLoan: (function () {
      var looks = this.get('looks');
      var r = false;
      if (!Ember['default'].isNone('looks')) {
        looks.forEach(function (look) {
          r = r || look.get('hasSamplesVisiblesAndAvailablesForLoan');
        });
      }
      return r;
    }).property('looks.@each.hasSamplesVisiblesAndAvailablesForLoan')
  });

});