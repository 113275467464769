define('bookalook/helpers/messages-dateformat', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    Number.prototype.padZero = function (len) {
        var s = String(this),
            c = '0';
        len = len || 2;
        while (s.length < len) {
            s = c + s;
        }
        return s;
    };

    exports['default'] = Ember['default'].Helper.helper(function (params) {
        var d = params[0].date || params[0];
        var day, hour, minutes, ampm;

        d = new Date(d);
        hour = d.getHours().padZero();
        minutes = d.getMinutes().padZero();
        ampm = hour >= 12 ? "PM" : "AM";

        if (d.toDateString() === new Date().toDateString()) {
            day = 'TODAY';
        } else if (new Date().getMonth() + " " + new Date().getDate() === new Date().getMonth() + " " + (new Date().getDate() - 1)) {
            day = 'YESTERDAY';
        } else {

            day = d.getDate() + "/" + ("0" + (d.getMonth() + 1)).slice(-2);
        }

        return day + " - " + hour + ":" + minutes;
    });

});