define('bookalook/tests/routes/bookings/ddt.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/bookings/ddt.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/bookings/ddt.js should pass jshint.\nroutes/bookings/ddt.js: line 67, col 77, Missing semicolon.\nroutes/bookings/ddt.js: line 68, col 11, Missing semicolon.\nroutes/bookings/ddt.js: line 95, col 11, \'samplesId\' is already defined.\nroutes/bookings/ddt.js: line 232, col 5, Unreachable \'var\' after \'return\'.\nroutes/bookings/ddt.js: line 247, col 13, \'cssText\' is already defined.\nroutes/bookings/ddt.js: line 250, col 14, \'cssText\' used out of scope.\n\n6 errors');
  });

});