define('bookalook/tests/unit/mixins/user-validator-mixin-test', ['ember', 'bookalook/mixins/user-validator-mixin', 'qunit'], function (Ember, UserValidatorMixinMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | user validator mixin');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var UserValidatorMixinObject = Ember['default'].Object.extend(UserValidatorMixinMixin['default']);
    var subject = UserValidatorMixinObject.create();
    assert.ok(subject);
  });

});