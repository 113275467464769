define('bookalook/tests/helpers/_given', ['bookalook/tests/helpers/test-helpers'], function (test_helpers) {

  'use strict';

  test_helpers._given(/^I am an anonimous user$/, function (assert, assertStr) {
    visit('/session/logout');
    andThen(function () {
      return assert.equal(currentURL(), '/', assertStr);
    });
  });

  test_helpers._given(/^I am logged as (.*) with password (.*)$/, function (user, password, assert, assertStr) {
    visit('/session/logout');
    visit('/session/login');

    fillIn('#username', user);
    fillIn('#password', password);
    click('#login');

    andThen(function () {
      return assert.notEqual(currentURL(), 'session/login', assertStr);
    });
  });

  test_helpers._given(/^I am on the (.*) page$/, function (page, assert, assertStr) {
    visit(page);
    andThen(function () {
      return assert.equal(currentURL(), page, assertStr);
    });
  });

});