define('bookalook/helpers/length-is-higher-than', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var o2 = params[1];
    if (Ember['default'].isNone(o1) || Ember['default'].isNone(o2)) {
      return false;
    }
    return o1.length > o2;
  });

});