define('bookalook/templates/barcodes/scanned-row', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 4
                },
                "end": {
                  "line": 9,
                  "column": 4
                }
              },
              "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1,"class","error");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"colspan","7");
              dom.setAttribute(el2,"class","error-message");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","status");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [5, 0]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
              morphs[2] = dom.createAttrMorph(element9, 'class');
              morphs[3] = dom.createMorphAt(element9,0,0);
              return morphs;
            },
            statements: [
              ["content","sample.barcode",["loc",[null,[5,12],[5,30]]]],
              ["content","sample.errorMessage",["loc",[null,[6,46],[6,69]]]],
              ["attribute","class",["concat",[["subexpr","lowercase",[["get","sample.error",["loc",[null,[7,51],[7,63]]]]],[],["loc",[null,[7,39],[7,65]]]]]]],
              ["inline","uppercase",[["get","sample.error",["loc",[null,[7,79],[7,91]]]]],[],["loc",[null,[7,67],[7,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 12
                  },
                  "end": {
                    "line": 17,
                    "column": 67
                  }
                },
                "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("img");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [0]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element1, 'src');
                return morphs;
              },
              statements: [
                ["attribute","src",["concat",[["get","color",["loc",[null,[17,58],[17,63]]]]]]]
              ],
              locals: ["color"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 4
                },
                "end": {
                  "line": 25,
                  "column": 4
                }
              },
              "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("br");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","status");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("input");
              dom.setAttribute(el3,"type","checkbox");
              dom.setAttribute(el3,"checked","checked");
              dom.setAttribute(el3,"class","bal-checkbox-input chosenSamples");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3,"class","bal-checkbox-label");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [7]);
              var element4 = dom.childAt(element2, [17, 0]);
              var element5 = dom.childAt(element2, [19]);
              var element6 = dom.childAt(element5, [0]);
              if (this.cachedFragment) { dom.repairClonedNode(element6,[],true); }
              var element7 = dom.childAt(element5, [2]);
              var morphs = new Array(15);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
              morphs[3] = dom.createMorphAt(element3,0,0);
              morphs[4] = dom.createMorphAt(element3,2,2);
              morphs[5] = dom.createMorphAt(element3,4,4);
              morphs[6] = dom.createMorphAt(dom.childAt(element2, [9]),0,0);
              morphs[7] = dom.createMorphAt(dom.childAt(element2, [11]),0,0);
              morphs[8] = dom.createMorphAt(dom.childAt(element2, [13]),0,0);
              morphs[9] = dom.createMorphAt(dom.childAt(element2, [15]),0,0);
              morphs[10] = dom.createAttrMorph(element4, 'class');
              morphs[11] = dom.createMorphAt(element4,0,0);
              morphs[12] = dom.createAttrMorph(element6, 'id');
              morphs[13] = dom.createElementMorph(element6);
              morphs[14] = dom.createAttrMorph(element7, 'for');
              return morphs;
            },
            statements: [
              ["content","sample.barcode",["loc",[null,[11,12],[11,30]]]],
              ["content","sample.sample",["loc",[null,[13,12],[13,29]]]],
              ["content","sample.brand",["loc",[null,[14,12],[14,28]]]],
              ["content","sample.collection.type",["loc",[null,[15,12],[15,38]]]],
              ["content","sample.collection.season",["loc",[null,[15,42],[15,70]]]],
              ["content","sample.collection.year",["loc",[null,[15,71],[15,97]]]],
              ["content","sample.count",["loc",[null,[16,12],[16,28]]]],
              ["block","each",[["get","sample.colors",["loc",[null,[17,20],[17,33]]]]],[],0,null,["loc",[null,[17,12],[17,76]]]],
              ["inline","uppercase",[["get","sample.showroom",["loc",[null,[18,24],[18,39]]]]],[],["loc",[null,[18,12],[18,41]]]],
              ["inline","uppercase",[["get","sample.company",["loc",[null,[19,24],[19,38]]]]],[],["loc",[null,[19,12],[19,40]]]],
              ["attribute","class",["concat",[["subexpr","lowercase",[["get","sample.action",["loc",[null,[20,51],[20,64]]]]],[],["loc",[null,[20,39],[20,66]]]]]]],
              ["inline","uppercase",[["get","sample.action",["loc",[null,[20,80],[20,93]]]]],[],["loc",[null,[20,68],[20,95]]]],
              ["attribute","id",["concat",["sample-id-",["get","sample.id",["loc",[null,[21,94],[21,103]]]]]]],
              ["element","action",["refreshCounter"],["on","change"],["loc",[null,[21,35],[21,74]]]],
              ["attribute","for",["concat",["sample-id-",["get","sample.id",["loc",[null,[22,36],[22,45]]]]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 26,
                "column": 2
              }
            },
            "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","sample.error",["loc",[null,[3,10],[3,22]]]]],[],0,1,["loc",[null,[3,4],[25,11]]]]
          ],
          locals: ["sample"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","samplesToCheck",["loc",[null,[2,10],[2,24]]]]],[],0,null,["loc",[null,[2,2],[26,11]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"colspan","9");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","not-scanned-yet");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","icon");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("hr");
          dom.setAttribute(el4,"class","horizontal-bar");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          var el5 = dom.createTextNode("Use your scanning gun to check-out & check-in samples.\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("You can always cancel or remove samples from your scanning list.");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","btn_bal_primary start-scan");
          var el5 = dom.createTextNode("Start Scanning");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1, 1, 7]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["startScanning"],[],["loc",[null,[35,16],[35,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/barcodes/scanned-row.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","samplesToCheck",["loc",[null,[1,6],[1,20]]]]],[],0,1,["loc",[null,[1,0],[39,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});