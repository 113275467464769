define('bookalook/tests/models/album.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/album.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'models/album.js should pass jshint.\nmodels/album.js: line 50, col 87, Missing semicolon.\nmodels/album.js: line 72, col 87, Missing semicolon.\n\n2 errors');
  });

});