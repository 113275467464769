define('bookalook/templates/components/inline-textarea-edition', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/components/inline-textarea-edition.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["inline","multiline-text",[],["text",["subexpr","@mut",[["get","value",["loc",[null,[1,22],[1,27]]]]],[],[]],"class","inline_value multiline_value"],["loc",[null,[1,0],[1,66]]]],
        ["inline","textarea",[],["class","inline_input","value",["subexpr","@mut",[["get","value",["loc",[null,[2,38],[2,43]]]]],[],[]],"rows",6],["loc",[null,[2,0],[2,52]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});