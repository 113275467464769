define('bookalook/tests/routes/profile/album-profile.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/profile/album-profile.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/profile/album-profile.js should pass jshint.\nroutes/profile/album-profile.js: line 79, col 84, Missing semicolon.\nroutes/profile/album-profile.js: line 85, col 3, Comma warnings can be turned off with \'laxcomma\'.\nroutes/profile/album-profile.js: line 84, col 3, Misleading line break before \',\'; readers may interpret this as an expression boundary.\nroutes/profile/album-profile.js: line 114, col 20, [\'content\'] is better written in dot notation.\nroutes/profile/album-profile.js: line 125, col 24, [\'content\'] is better written in dot notation.\n\n5 errors');
  });

});