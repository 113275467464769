define('bookalook/routes/settings/personal-mp', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      addPosition: function addPosition() {
        this.onAddPosition(this.store.createRecord('contact-address'));
      },
      edition: function edition(id, index) {
        this.onEdition(id, index);
      },
      deletePosition: function deletePosition(model, contactAddresses, index) {
        this.onDeletePosition(model, contactAddresses, index);
      },
      confirmAddressEdition: function confirmAddressEdition(model, id, index) {
        this.onConfirmAddressEdition(model, id, index);
      },
      modCancelEdition: function modCancelEdition(model, id, index) {
        this.onCancelEdition(model, id, index);
      },
      setEmailSetting: function setEmailSetting(activate) {
        this.onSetEmailSetting(activate);
      }
    },

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      if (!Ember['default'].user.get('isSuperAdmin') && Ember['default'].user.get('id') !== transition.params['settings.personal-mp'].id) {
        this.transitionTo('session.login');
      }
      if (Ember['default'].user.get('firstLogin')) {
        this.send('openWelcomeMessageModal');
        Ember['default'].user.set('firstLogin', false);
        Ember['default'].user.save();
      }
    },

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        user: this.store.findRecord('user', params.id)
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.set('userLogged', Ember['default'].user);
      controller.set('selectContent', '');
      controller.set('contactAddresses', controller.user.get('addresses'));
      controller.set('model', controller.get('user'));
      controller.set('addressCount', controller.user.get('addresses.length'));
      if (controller.get('addressCount') < 3) {
        controller.set('addressSlot', true);
      } else {
        controller.set('addressSlot', false);
      }
    },

    onEdition: function onEdition(id, index) {
      this.send('activateEdition', id, index);
    },

    onConfirmAddressEdition: function onConfirmAddressEdition(model, id, index) {
      var _this = this;

      if (model.get('main')) {
        this.controller.get('contactAddresses').forEach(function (address) {
          if (model !== address) {
            address.set('main', false);
          } else {
            _this.controller.get('model').set('company', address.get('street3'));
            _this.controller.get('model').set('jobTitle', address.get('position'));
            _this.controller.get('model').save();
          }
        });
        this.controller.get('model').notifyPropertyChange('mainAddress');
      }
      this.send('confirmEdition', model, id, index);
    },

    onCancelEdition: function onCancelEdition(model, id, index) {
      if (model.get('isNew')) {
        this.controller.set('addressCount', this.controller.get('addressCount') - 1);
        this.controller.set('addressSlot', true);
      }
      this.send('cancelEdition', model, id, index);
    },

    onAddPosition: function onAddPosition(newContactAddress) {
      var _this2 = this;

      var user = this.controller.get('user');
      if (this.controller.get('addressCount') < 3) {
        newContactAddress.set('mpUser', user);
        Ember['default'].run.later(function () {
          _this2.send('activateEdition', 'user-position', _this2.controller.get('addressCount'));
          _this2.controller.set('addressCount', _this2.controller.get('addressCount') + 1);
          if (_this2.controller.get('addressCount') === 3) {
            _this2.controller.set('addressSlot', false);
          }
        }, 500);
      }
    },

    onDeletePosition: function onDeletePosition(model, contactAddresses, index) {
      if (model.get('main')) {
        if (contactAddresses) {
          if (contactAddresses.objectAt(index + 1)) {
            contactAddresses.objectAt(index + 1).set('main', true);
            contactAddresses.objectAt(index + 1).save();
            model.destroyRecord();
            this.controller.set('addressCount', this.controller.get('addressCount') - 1);
            this.controller.set('addressSlot', true);
          } else if (contactAddresses.objectAt(index - 1)) {
            contactAddresses.objectAt(index - 1).set('main', true);
            contactAddresses.objectAt(index - 1).save();
            model.destroyRecord();
            this.controller.set('addressCount', this.controller.get('addressCount') - 1);
            this.controller.set('addressSlot', true);
          }
        }
      } else {
        model.destroyRecord();
        this.controller.set('addressCount', this.controller.get('addressCount') - 1);
        this.controller.set('addressSlot', true);
      }
    },

    onSetEmailSetting: function onSetEmailSetting(activate) {
      var _this3 = this;

      var model = this.controller.get('user');
      if (model.get('emailNotifications') !== activate) {
        utils['default'].putAPIJSON('users/' + model.get('id') + '/changeEmailNotifications', { 'user': { 'emailNotifications': activate } }).then(function () {
          if (activate) {
            _this3.send('openTopBar', 'Email notifications turned ON');
          } else {
            _this3.send('openTopBar', 'Email notifications turned OFF');
          }
          model.set('emailNotifications', activate);
        });
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-user-detail');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-user-detail');
      var form = $('.col-xs-6.edit-field.user-jobTitle');
      if (form.length > 0) {
        form.find('.btn_cancel').click();
      }
    }
  });

});