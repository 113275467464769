define('bookalook/routes/jobs/form-edit', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      addAddress: function addAddress() {
        this.onAddAddress();
      },
      addFile: function addFile() {
        this.onAddFile();
      },
      cancelEdition: function cancelEdition() {
        this.onCancelEdition();
      },
      removeFile: function removeFile(file) {
        this.onRemoveFile(file);
      },
      saveJob: function saveJob() {
        this.onSaveJob();
      }
    },

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        job: this.store.findRecord('job', params.id)
      });
    },

    afterModel: function afterModel(model) {
      var _this = this;

      var job = model.job;
      var addresses = job.get('addresses');

      addresses.then(function () {
        if (!addresses.get('length')) {
          addresses.addObject(_this.store.createRecord('job-address', { main: true }));
        }
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);
      controller.set('userLogged', Ember['default'].user);
      controller.set('isFormPage', true);
      controller.set('isFormEditPage', true);
    },

    onAddAddress: function onAddAddress() {
      var job = this.controller.get('job');
      var addresses = job.get('addresses');

      addresses.addObject(this.store.createRecord('job-address', { main: false }));
    },

    onAddFile: function onAddFile() {
      var _this2 = this;

      var input = Ember['default'].$('#add-job-file');
      input.off("change").on("change", function () {
        var file = input[0].files[0];
        if (file.size > 3145728) {
          _this2.send('openErrorsModal', [], 'File size cannot exceed 3 MB');
        } else {
          _this2.store.createRecord('file', {
            name: file.name,
            job: _this2.controller.get('job'),
            file: file
          });
        }
      });
    },

    onCancelEdition: function onCancelEdition() {
      var job = this.controller.get('job');
      job.completeReset();

      this.transitionTo('jobs.view', job.get('id'));
    },

    onSaveJob: function onSaveJob() {
      var _this3 = this;

      var job = this.controller.get('job');
      this.send('openMessageModal', 'Saving job ' + job.get('name') + '. Please Wait.');

      this._checkNewMainAddress();
      Ember['default'].RSVP.all([this._saveFiles(), this._saveAdresses()]).then(function () {
        job.save().then(function (jobSaved) {
          _this3.send('closeMessageModal');
          _this3.transitionTo('jobs.view', jobSaved.get('id')).then(function () {
            return jobSaved.reload();
          });
        });
      });
    },

    _checkNewMainAddress: function _checkNewMainAddress() {
      var job = this.controller.get('job');
      var addresses = job.get('addresses');
      var newMain = addresses.findBy('willMain', true);

      if (newMain) {
        job.get('mainAddress').set('main', false);
        newMain.set('main', true);
      }
    },

    _saveFiles: function _saveFiles() {
      var job = this.controller.get('job');
      var promises = [];

      job.get('files').forEach(function (f) {
        if (f.get("isNew")) {
          promises.push(f.save().then(function (res) {
            f.setProperties(res.file);
          }));
        } else if (f.get("toDelete")) {
          promises.push(f.destroyRecord());
        }
      });

      return Ember['default'].RSVP.all(promises);
    },

    _saveAdresses: function _saveAdresses() {
      var job = this.controller.get('job');
      var promises = [];

      job.get('addresses').forEach(function (a) {
        if (a.get("hasDirtyAttributes")) {
          promises.push(a.save());
        } else {
          a.rollbackAttributes();
        }
      });

      return Ember['default'].RSVP.all(promises);
    },

    onRemoveFile: function onRemoveFile(file) {
      if (file.get("isNew")) {
        file.rollbackAttributes();
      } else {
        file.set('toDelete', true);
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-jobs page-jobs-form-edit');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-jobs page-jobs-form-edit');
    }
  });

});