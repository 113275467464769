define('bookalook/helpers/datetime-format', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var d = params[0];
    var s = params[1] || '.';
    var canBeEmpty = params[2];
    var showToday = true;
    var time = d.split(' ')[1];

    time = time.split('.')[0].split(':');
    time = time[0] + ':' + time[1];

    if (!Ember['default'].isNone(params[3])) {
      showToday = params[3];
    }

    if (Ember['default'].isNone(d)) {
      if (!canBeEmpty) {
        d = new Date();
        showToday = false;
      } else {
        return '-';
      }
    }

    if (typeof d === 'string') {
      d = utils['default'].parseDate(d);
    }

    return [d.getDate(), d.getMonth() + 1, d.getFullYear() % 100].join(s) + (' ' + time);
  });

});