define('bookalook/routes/session/login-success', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, UserValidatorMixin, utils, config) {

  'use strict';

  var status; //check status
  var timestamp; //for timestamp
  var MyTimestamp = {};
  var globel_plan;
  var timestamp_seconds;
  var plan; //for plan
  var currency;
  var stripeCustomerId;
  var status_replace;
  var plan_replace;
  var date;
  var current_date; // current date
  var date_diffrence; //date diffrence
  var days;
  var length_of_card;
  var flag_card;
  var count_days;
  var api_url;
  var sub_plan_id;
  var sub_status;
  var admin_status_obj = {};
  var trial_end_admin;
  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    model: function model() {
      if (Ember['default'].showroom) {
        return Ember['default'].showroom.get('brands');
      }
    },
    /**
     * Determinate if user is PR or MP for requesting stripe subscription status
     */
    afterModel: function afterModel() {
      var _this = this;

      var userPromise = this.get('session.userPromise');

      if (userPromise.then) {
        userPromise.then(function (resp) {
          if (!Ember['default'].user.get('isMP')) {
            //utils.getAPIJSON('plans/getSubscriptionStatus').then(response => {
            _this.onMakeTransitionToUserCompany();
            //});
          } else {
              _this.onMakeTransitionToUserCompany(resp);
            }
        });
      }
      //else {
      //  this.onMakeTransitionToUserCompany();
      //}
    },
    actions: {
      makeTransitionToUserCompany: function makeTransitionToUserCompany() {
        this.onMakeTransitionToUserCompany();
      }

    },

    onMakeTransitionToUserCompany: function onMakeTransitionToUserCompany() {
      var _this2 = this;

      var resp = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var route = this;
      //IF YOU TOUCH THIS, REMEMBER TO MAKE CHANGES IN ONBOARDING/INDEX.JS _login-success function
      //for show header and footer
      $('.beta_topbar ').show();
      $('.main_menu_wrapper').show();
      $('#test_footer').show();

      //set session for page redirect
      this.get('session').set('check_login_user', "1");
      var stripeCustomerId = "";

      //Subscription is expired?
      /*  let isExpired = Ember.user.get('hasExpiredSubscription');
        localStorage.setItem('managerSubscriptionExpired', isExpired);*/
      if (!Ember['default'].isNone(resp)) {
        var managerStatusRegister = resp.statusRegister;
        localStorage.setItem("managerStatusRegister", managerStatusRegister);
        Ember['default'].managerStatusRegister = managerStatusRegister;

        var isExpired = resp.statusRegister == "6";
        localStorage.setItem("managerSubscriptionExpired", isExpired);
        Ember['default'].managerSubscriptionExpired = isExpired;

        var firstPaymentDone = resp.firstPayment;
        localStorage.setItem("firstPaymentDone", firstPaymentDone);
        Ember['default'].firstPaymentDone = firstPaymentDone;

        if (isExpired) {
          //this.transitionTo('settings.blocked-account');
          this.transitionTo('settings.payment-failed').then(function () {
            route.controllerFor('application').set('loadingBookalookPage', false);
          });
          return;
        }
      }
      if (Ember['default'].user.get('isOnboarding')) {
        this.transitionTo('onboarding.index').then(function () {
          _this2.controllerFor('application').set('personalLink', Ember['default'].userId);
          /*if (Ember.user.get('firstLogin')) {
            this.send('openWelcomeMessageModal');
            Ember.user.set('firstLogin', false);
            Ember.user.save();
          }*/
          route.controllerFor('application').set('loadingBookalookPage', false);
        });
      } else {
        //If PR
        if (!Ember['default'].user.get('isMP')) {
          //if (stripeCustomerId.trim() == "" || Ember.user.get('isSuperAdmin')) {
          utils['default'].getAPIJSON('plans/getSubscriptionStatus').then(function (getsub) {
            sub_status = JSON.stringify(getsub.status);
            //If PR has any suscription plan on DB
            if (sub_status) {
              status_replace = sub_status.replace(/^"(.*)"$/, '$1');
              timestamp = JSON.stringify(getsub.trial_end);
              timestamp_seconds = timestamp * 1000;
              date = new Date();
              current_date = date.getTime();
              date_diffrence = new Date(timestamp_seconds - current_date);
              days = date_diffrence / 1000 / 60 / 60 / 24;
              //count_days = Math.round(Math.abs(days));
              count_days = Math.round(days);
              localStorage.setItem('trialEndDate', timestamp_seconds);
            } else {
              status_replace = '';
            }

            if (status_replace.trim() == 'trialing') {
              if (_this2.get('session.isAuthenticated')) {
                _this2.get('session').set('remaining_days', count_days);
                //Set number for plan
                sub_plan_id = JSON.stringify(getsub.planId);
                //sub_plan_id: StarterYearlyEUR | ProYearlyEUR | NewDesignerYearlyEUR ...
                if (sub_plan_id.includes("Designer")) {
                  _this2.get('session').set('plan_key', 1);
                } else if (sub_plan_id.includes("Pro")) {
                  _this2.get('session').set('plan_key', 2);
                } else if (sub_plan_id.includes("Starter")) {
                  _this2.get('session').set('plan_key', 5);
                }
              }
            }

            if (status_replace.trim() !== "active" && (count_days == 0 || count_days < 0)) {
              //this.transitionTo('settings.trial-ended');
              _this2.get('session').set('check_login_user', "");
              _this2.get('session').set('set_header_link', "1");
              _this2.get('session').set('set_end_of_trial', "1");
              //CHECK PAYMENT FAILED OR TRIAL
              var trialEndDate = localStorage.getItem('trialEndDate');
              if (trialEndDate) {
                var todayDate = new Date().getTime();
                var dateDif = new Date(trialEndDate - todayDate);
                var daysToEnd = Math.round(dateDif / 1000 / 60 / 60 / 24);
                if (daysToEnd <= 0) {
                  if (JSON.parse(localStorage.getItem('firstPaymentDone'))) {
                    if (JSON.parse(localStorage.getItem('managerSubscriptionExpired'))) {
                      _this2.replaceWith('settings.payment-failed').then(function () {
                        route.controllerFor('application').set('loadingBookalookPage', false);
                      });
                      return;
                    } else {
                      //Its Ok, he has active subscription
                    }
                  } else {
                      _this2.replaceWith('settings.trial-ended').then(function () {
                        route.controllerFor('application').set('loadingBookalookPage', false);
                      });
                      return;
                    }
                } else {
                  //Its Ok, he is trialing
                }
              } else {}
            }

            var rgstrPlan = (Ember['default'].user.get('registerPlan') || '').toLowerCase();
            var prTransition = rgstrPlan.indexOf('starter') !== -1 ? 'collections.index' : 'inventory';
            _this2.transitionTo(prTransition).then(function () {
              if (Ember['default'].user.get('isSuperAdmin')) {
                var admin_plan = Ember['default'].user.get('registerPlan');

                if (admin_plan.trim() == '') {
                  _this2.get('session').set('admin_plan', '1');
                } else {
                  _this2.get('session').set('admin_plan', '');
                }
              }
              _this2.controllerFor('application').set('personalLink', Ember['default'].userId);
              if (Ember['default'].user.get('firstLogin')) {
                _this2.send('openWelcomeMessageModal');
                Ember['default'].user.set('firstLogin', false);
                Ember['default'].user.save();
              }
              route.controllerFor('application').set('loadingBookalookPage', false);
            });
          });
        } else {
          //MP: No subscription
          Ember['default'].$('body').addClass('isMP');
          this.transitionTo('search.looks').then(function () {
            _this2.controllerFor('application').set('personalLink', Ember['default'].userId);
            route.controllerFor('application').set('loadingBookalookPage', false);
          });
        }
      }
    }
  });

});