define('bookalook/routes/directory/functions', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports.sortBrandsByLetter = sortBrandsByLetter;

  function sortBrandsByLetter(brands) {
    var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var brandSorted = [];
    var brandSortedIndexed = [];
    //brandSortedIndexed[0] = [];
    //brandSortedIndexed[1] = [];
    //brandSortedIndexed[2] = [];
    //brandSortedIndexed[3] = [];
    var numberOfBrands = 0;
    var numberOfLetters = 0;

    for (var i = 0; i < brands.length; i++) {
      var brand = brands[i];
      var name = brand.name;
      var initial = name.charAt(0).toUpperCase();
      var index = letters.indexOf(initial);

      if (typeof brandSorted[index] == 'undefined') {
        brandSorted[index] = [];
        brandSorted[index].push(brand);
        numberOfLetters++;
      } else {
        brandSorted[index].push(brand);
      }
      numberOfBrands++;
    }

    var count = 0;
    var partialBrandsCount = 0;
    var currentColumn = 0;
    var divInteger = 0;

    if (numberOfLetters < 4) {
      divInteger = 0;
    } else {
      divInteger = Math.floor(numberOfLetters / 3);
    }
    brandSorted.forEach(function (entry) {

      if (typeof brandSortedIndexed[currentColumn] == 'undefined') {
        brandSortedIndexed[currentColumn] = [];
      }

      brandSortedIndexed[currentColumn][count] = [];
      for (var j = 0; j < entry.length; j++) {
        brandSortedIndexed[currentColumn][count].push(entry[j]);
        partialBrandsCount++;
      }

      count++;

      if (count >= divInteger && currentColumn != 2) {
        partialBrandsCount = 0;
        count = 0;
        currentColumn++;
      }
    });

    return brandSortedIndexed;
  }

});