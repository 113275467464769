define('bookalook/tests/models/contact-address.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/contact-address.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'models/contact-address.js should pass jshint.\nmodels/contact-address.js: line 22, col 8, Misleading line break before \'&&\'; readers may interpret this as an expression boundary.\n\n1 error');
  });

});