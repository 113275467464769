define('bookalook/templates/contacts-lists/modals/add-contact', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/contacts-lists/modals/add-contact.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Add contact ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","add-contact");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        dom.setAttribute(el3,"id","test_add_contact_modal");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title");
        var el6 = dom.createTextNode("Add contact");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h5");
        var el7 = dom.createTextNode("Search member within the Bookalook data base.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" Buscar un miembro de bookalook y añadirlo como contacto ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","form-group select-contact validated-select col-xs-12 no-padding");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" Añadir dicho contacto a una o varias listas ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h5");
        var el7 = dom.createTextNode("Add New Contact to a list or multiple lists.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","form-group validated-select col-xs-12 no-padding");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" Crear un nuevo contacto manualmente e invitarlo a bookalook ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","col-xs-12 no-padding");
        var el8 = dom.createTextNode("Can't find who you are looking for?.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#");
        dom.setAttribute(el7,"class","col-xs-12 no-padding");
        dom.setAttribute(el7,"data-dismiss","modal");
        dom.setAttribute(el7,"data-toggle","modal");
        dom.setAttribute(el7,"data-target","#new-contact");
        var el8 = dom.createTextNode("Add new contact manually.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_cancel");
        dom.setAttribute(el5,"data-dismiss","modal");
        var el6 = dom.createTextNode("Cancel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_primary");
        dom.setAttribute(el5,"data-dismiss","modal");
        var el6 = dom.createTextNode("Add contact");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 1]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [15, 3]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [11]),1,1);
        morphs[2] = dom.createElementMorph(element2);
        morphs[3] = dom.createElementMorph(element4);
        morphs[4] = dom.createAttrMorph(element5, 'disabled');
        morphs[5] = dom.createElementMorph(element5);
        return morphs;
      },
      statements: [
        ["inline","select-2",[],["placeholder","Search member by name, company or email address","value",["subexpr","@mut",[["get","addContactSelectedUser",["loc",[null,[15,20],[15,42]]]]],[],[]],"content",["subexpr","@mut",[["get","bookalookUsers",["loc",[null,[16,22],[16,36]]]]],[],[]],"optionLabelPath","safeString","enabled",["subexpr","@mut",[["get","bookalookUsersLoading",["loc",[null,[18,22],[18,43]]]]],[],[]],"cssClass",["subexpr","@mut",[["get","select-contact",["loc",[null,[19,23],[19,37]]]]],[],[]]],["loc",[null,[13,12],[20,14]]]],
        ["inline","multiple-select",[],["list",["subexpr","@mut",[["get","addContactSelectedLists",["loc",[null,[27,19],[27,42]]]]],[],[]],"content",["subexpr","@mut",[["get","contactLists",["loc",[null,[28,22],[28,34]]]]],[],[]],"placeholder","Select Lists","optionLabelPath","name"],["loc",[null,[26,12],[31,14]]]],
        ["element","action",["openNewContactModal"],[],["loc",[null,[37,24],[37,58]]]],
        ["element","action",["cancelEditionNewContact",["get","contactToAdd",["loc",[null,[42,66],[42,78]]]]],[],["loc",[null,[42,30],[42,81]]]],
        ["attribute","disabled",["subexpr","not",[["get","canAddContact",["loc",[null,[43,93],[43,106]]]]],[],["loc",[null,[43,87],[43,108]]]]],
        ["element","action",["saveContactFromUser",["get","contactToAdd",["loc",[null,[43,62],[43,74]]]]],[],["loc",[null,[43,30],[43,77]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});