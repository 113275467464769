define('bookalook/filters_newbooking', ['exports', 'ember', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, utils, config) {

  'use strict';

  exports.resetFilters = resetFilters;
  exports.removeObserversFromSetFilterComputeds = removeObserversFromSetFilterComputeds;
  exports.undoResetFilters = undoResetFilters;
  exports.removeScrollPaginationListener = removeScrollPaginationListener;
  exports.setScrollPaginationListener = setScrollPaginationListener;
  exports.clearSamples = clearSamples;
  exports.clearFilters = clearFilters;
  exports.filterByWord = filterByWord;
  exports.initializeFilters = initializeFilters;
  exports.setFilterComputeds = setFilterComputeds;

  var containerGlobal = null;
  var containerGlobalDocument = null;
  var controllerGlobal = null;

  function resetFilters(route, container) {
    var controller = route.controller;

    if (controller.get('allBrands.length') !== 2) {
      controller.set('brandFilter', null);
    }
    controller.set('collectionPage', 1);
    controller.set('collectionFilter', null);
    controller.set('showroomFilter', null);

    controller.set('colourFilter', null);

    controller.set('statusFilter', '');

    controller.set('albumFilter', null);
    controller.set('lookFilter', null);
    controller.set('itemFilter', null);
    controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
    controller.set('sizeFilter', null);

    container = container || window;
    Ember['default'].activeFilter = container;
  }

  function removeObserversFromSetFilterComputeds(route) {
    var controller = route.controller;

    //searchAndFiltering
    controller.removeObserver('collectionFilter', controller, 'searchAndFiltering');
    controller.removeObserver('brandFilter', controller, 'searchAndFiltering');
    controller.removeObserver('albumFilter', controller, 'searchAndFiltering');
    controller.removeObserver('colourFilter', controller, 'searchAndFiltering');
    controller.removeObserver('statusFilter', controller, 'searchAndFiltering');
    controller.removeObserver('sizeFilter', controller, 'searchAndFiltering');
    controller.removeObserver('itemFilter', controller, 'searchAndFiltering');

    controller.removeObserver('collectionFilter', controller, 'updateAlbums');
    controller.removeObserver('brandFilter', controller, 'updateCollections');
    controller.removeObserver('collectionPage', controller, 'loadMoreCollections');
  }

  //Restore filter values before the reset

  function undoResetFilters(route, container) {
    var loadingScreen = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var controller = route.controller;

    container = container || window;
    Ember['default'].activeFilter = container;
  }

  function _scrollCalculationPage() {
    if ($(containerGlobal).scrollTop() + $(containerGlobal).height() > $(containerGlobalDocument).height() - 300) {

      if (!controllerGlobal.get('allSamplesUnvisible') && controllerGlobal.get('loadMore') && !controllerGlobal.get('loadingNextAlbums') && !controllerGlobal.get('isLoading')) {
        controllerGlobal.incrementProperty('collectionPage');
      }
    }
  }

  function removeScrollPaginationListener(route) {

    var intervals = route.get('poll');
    intervals.clearAll();
  }

  function setScrollPaginationListener(route) {

    /* No pude setearlo en el evento on scroll  entonces tuve que 
    ponerlo como antes cada x tiempo verifica el scroll */
    var intervals = route.get('poll');
    intervals.clearAll();
    intervals.addPoll({
      interval: 50, // 0.05s
      callback: _scrollCalculationPage
    });
  }

  function clearSamples(route) {
    var controller = route.controller;
    controller.set('itemFilter', '');
  }

  ;

  function clearFilters(route) {
    var controller = route.controller;
    controller.set('resetFilter', true);
    initializeFilters(controller);
    controller.set('resetFilter', false);
    controller.set('searchAnyWay', true);
    controller.searchAndFiltering();
  }

  function filterByWord(route) {
    var controller = route.controller;
    controller.searchAndFiltering();
  }

  ;

  function initializeFilters(controller) {
    if (controller.get('allBrands.length') !== 2) {
      controller.set('brandFilter', null);
    }
    controller.set('collectionPage', 1);
    controller.set('loadingNextAlbums', false);

    controller.set('collectionFilter', null);
    controller.set('showroomFilter', null);

    controller.set('colourFilter', null);
    controller.set('statusFilter', null);
    controller.set('albumFilter', null);
    controller.set('lookFilter', null);
    controller.set('itemFilter', null);
    controller.set('sizeFilter', null);
    if (controller.get('bookingToAdd')) {
      controller.set('bookingToAdd.checkout', null);
      controller.set('bookingToAdd.checkin', null);
    }

    controller.set('allColoursContent', utils['default'].addDefaultOption(Ember['default'].colours, 'All colours', 'safeString'));
    controller.set('resetFilter', false);
    controller.set('searchAnyWay', false);
  }

  function setFilterComputeds(route, container, containerDocument, hideControllerSamples) {
    var onlySamplesSameShowroom = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    var controller = route.controller;
    controllerGlobal = controller;
    containerGlobal = container;
    containerGlobalDocument = containerDocument;

    var $ = Ember['default'].$;
    var lazyContainer = container;
    containerDocument = containerDocument || document;
    container = container || window;

    _setVisibleSamples(onlySamplesSameShowroom, controller);

    initializeFilters(controller);

    controller.loadMoreCollections = function () {

      var pagination = controller.get('pagination');
      var collectionPage = controller.get('collectionPage');
      var filters = controller.get('filters');
      var pageLoad = collectionPage;
      var filtering = controller.get('loadFiltering');
      //carga collections solo si selecciona días en el calendario
      // y un brand
      if (!filters.checkinDate || !filters.checkoutDate || !filters.brandId) {
        return false;
      }
      if (filtering) {
        pageLoad = 1;
        controller.set('loadFiltering', false);
        controller.set('collectionPage', 1); //me llama a esto nuevamente pero no lo corre por las condiciones del if
      }

      if (collectionPage > pagination.actualPage && collectionPage <= pagination.lastPage || filtering) {
        controller.set('loadMore', false); // evitar rellamar a aumentar collectionPage
        controllerGlobal.set('loadingNextAlbums', true);
        var page = parseInt(pageLoad);
        utils['default'].getCollectionsBookinPaginated(controller, page, filters).then(function (response) {

          var results = controller.get('collections');
          if (results) {
            results.get('content').pushObjects(response.content);
          } else {
            results = response;
          }
          // actualizo la data de collections y debo re-renderizar
          controller.set('collections', results);
          controller.set('pagination', response.meta);

          controllerGlobal.set('loadingNextAlbums', false);
          controller.searchFilterFunction(); // chequea si hay algun sample para mostrar

          var samples = utils['default'].getSamples('paginated');
          controller.getStatus(samples);
        }, function () {
          controller.set('loadingNextAlbums', false);
        });
      } else {
        controller.set('loadMore', false);
      }
    };
    // cuando cambia collectionPage agrego collections
    utils['default'].addObserver(controller, ['collectionPage'], 'loadMoreCollections');

    controller.getStatus = function (samples) {

      if (controller.get('isLoadingCollections') || controller.get('isLoadingStatuses') /*|| 
                                                                                        controller.get('noRefreshStatuses') == true*/) {
          controller.set('isLoading', false);
          return;
        }

      controller.set('isLoadingStatuses', true);
      controller.set('isLoading', true);
      controller.set('loadMoreStatuses', true);
      var booking = controller.get('booking') || controller.get('bookingToAdd');
      if (booking) {

        var checkout = null;
        var checkin = null;
        if (controller.get('bookingDetailsPage')) {
          checkout = new Date();
          checkin = new Date();
        } else {
          checkout = booking.get('checkout');
          checkin = booking.get('checkin');
        }
        if (checkout && checkin) {

          if (!controller.get('isAgency')) {
            //BRAND: TRADITIONAL BEHAVIOUR
            utils['default'].getSampleStatusByBookingDates(route, samples, utils['default'].parseDate(checkout), utils['default'].parseDate(checkin)).then(function () {
              controller.set('bookingDatesBlock', false);
              controller.set('loadMoreStatuses', false);
              controller.set('isLoading', false);
              //Disable observer for getStatuses
              controller.set('noRefreshStatuses', true);
              controller.set('isLoadingStatuses', false);
            });
          } else {
            //AGENCY: Calculating sample status for Agencies

            if (controller.get('brandFilter') != null) {
              utils['default'].getSampleStatusForBrandByBookingDates(route, samples, controller.get('brandFilter'), utils['default'].parseDate(checkout), utils['default'].parseDate(checkin)).then(function () {
                controller.set('bookingDatesBlock', false);
                controller.set('loadMoreStatuses', false);
                controller.set('isLoading', false);
                //Disable observer for getStatuses
                controller.set('isLoadingStatuses', false);
              });
            } else if (controller.get('bookingDetailsPage')) {
              controller.set('bookingDatesBlock', false);
              controller.set('loadMoreStatuses', false);
              controller.set('isLoading', false);
              //Disable observer for getStatuses
              controller.set('isLoadingStatuses', false);
            } else {
              controller.set('isLoadingStatuses', false);
              if (controller.get('brandFilter') != null) {
                controller.set('isLoading', false);
              }
            }
          }
        } else {
          controller.set('bookingDatesBlock', true);
          controller.set('loadMoreStatuses', false);
          controller.set('isLoading', false);
          controller.set('isLoadingStatuses', false);
        }
      } else {
        controller.set('loadMoreStatuses', false);
        controller.set('isLoading', false);
        controller.set('isLoadingStatuses', false);
      }
    };

    controller.searchAndFiltering = function () {
      console.log('searchandFiltering filternewbooking');
      var filters = {};
      var checkout = controller.get('bookingToAdd.checkout');
      var checkin = controller.get('bookingToAdd.checkin');

      //sino me lo llama en el seteo
      if (controller.get('brandFilter') == null && controller.get('collectionFilter') == null && controller.get('albumFilter') == null && controller.get('searchInput') == null && checkin == null && checkout == null && controller.get('sizeFilter') == null && controller.get('itemFilter') == null && controller.get('colourFilter') == null && controller.get('statusFilter') == null && !controller.get('searchAnyWay') //obligo a buscar si o si
       || controller.get('resetFilter') //mientras voy reseteando el filtro que no vaya haciendo busquedas
      ) {
          return;
        }

      if (controller.get('brandFilter')) {
        filters.brandId = controller.get('brandFilter');
      }

      if (controller.get('searchInput')) {
        filters.search = controller.get('searchInput').toString().toLowerCase();
      }

      if (checkin) {
        filters.checkinDate = utils['default'].dateToDayFormat(checkin);
      }
      if (checkout) {
        filters.checkoutDate = utils['default'].dateToDayFormat(checkout);
      }

      if (controller.get('collectionFilter')) {
        filters.collection = controller.get('collectionFilter');
      }

      if (controller.get('albumFilter')) {
        filters.album = controller.get('albumFilter');
      }

      if (controller.get('colourFilter')) {
        filters.color = controller.get('colourFilter').toLowerCase();
      }
      if (controller.get('sizeFilter')) {
        filters.size = controller.get('sizeFilter').toLowerCase();
      }
      if (controller.get('itemFilter')) {
        filters.name = controller.get('itemFilter').id;
      }
      if (controller.get('statusFilter') && controller.get('statusFilter').toLowerCase() !== 'all statuses') {

        filters.statusBooking = controller.get('statusFilter');
      }

      controller.set('collections', null); //limpiar collections
      controller.set('filters', filters);
      controller.set('loadFiltering', true);
      controller.set('resetFilter', false);
      controller.set('searchAnyWay', false);
      controller.loadMoreCollections();
    };
    utils['default'].addObserver(controller, ['collectionFilter', 'albumFilter', 'colourFilter', 'statusFilter', 'sizeFilter', 'itemFilter', 'bookingToAdd.checkout', 'bookingToAdd.checkin'], 'searchAndFiltering');

    controller.searchByBrand = function () {
      // si son dos brands significa que hay una sola no uso el brandFilter
      // para buscar
      if (controller.get('allBrands').length > 2) {
        controller.searchAndFiltering();
      }
    };

    utils['default'].addObserver(controller, ['brandFilter'], 'searchByBrand');

    // CARGANDO LISTADO DE BRANDS
    controller.allBrands = Ember['default'].computed('brands', function () {
      var brands = Ember['default'].A([]); //en new booking no tengo que mostrar All Brands
      if (controller.get('brands')) {
        brands = brands.concat(this.get('brands'));
      }
      if (brands.length == 1) {
        controller.set('brandFilter', brands[0].id);
      }
      return brands;
    });

    controller.allSizes = Ember['default'].computed('allSizesContent', function () {
      var sizes = Ember['default'].A([{ id: 0, name: 'All sizes' }]);
      if (controller.get('allSizesContent')) {
        sizes = sizes.concat(this.get('allSizesContent'));
      }
      return sizes;
    });

    controller.updateCollections = function () {
      //limpiar collection y album
      var brand = controller.get('brandFilter');
      controller.set('collectionFilter', null);
      if (brand) {
        utils['default'].getCollectionsByBrands(brand).then(function (response) {
          controller.set('collectionBrand', response.collection);
        });
      } else {
        controller.set('collectionBrand', null);
      }
    };

    utils['default'].addObserver(controller, ['brandFilter'], 'updateCollections');

    // COMPUTED PROPERTIES 
    controller.collectionsFilteredByBrand = Ember['default'].computed('collectionBrand', function () {

      var collections = Ember['default'].A([{ id: 0, name: 'All Collections' }]);
      if (controller.get('collectionBrand')) {
        collections = collections.concat(controller.get('collectionBrand'));
      }
      return collections;
    });

    controller.updateAlbums = function () {
      //limpiar album
      controller.set('albumFilter', null);
      if (controller.get('collectionFilter')) {
        utils['default'].getAlbumsByCollection(controller.get('collectionFilter')).then(function (response) {

          controller.set('albumsCollection', response.album);
        });
      }
    };
    utils['default'].addObserver(controller, ['collectionFilter'], 'updateAlbums');

    controller.albumsFilteredByCollection = Ember['default'].computed('albumsCollection', function () {
      var albums = Ember['default'].A([{ id: 0, name: 'All Albums' }]);
      if (this.get('albumsCollection')) {
        albums = albums.concat(this.get('albumsCollection'));
      }
      return albums;
    });

    //Cargar todas las collections
    controller.allCollections = Ember['default'].computed('collections.length', function () {
      return controller.get('collections');
    });

    //FILTRANDO POR ALBUMS
    controller.allAlbums = Ember['default'].computed('allCollections', function () {
      var albums = undefined;
      var collections = this.get('allCollections');
      var albumsToShowFiltered = [];

      if (collections) {
        albums = collections.reduce(function (a, c) {
          if (c.get) {
            a = a.concat(c.get('albums').toArray());
          }
          return a;
        }, []);

        // AQUI HACE UN EFECTO VISUAL
        utils['default'].lazyLoad(2000, lazyContainer);
        //AQUI OCULTA LOS SAMPLES CUANDO RETORNA MUESTRA TODOS OTRA VEZ
        // _hideControllerSamples(hideControllerSamples, controller);

        //Dont show unpublished for Booking creation AND INVENTORY!

        albums.forEach(function (al) {
          if (al.get('published')) {
            albumsToShowFiltered.push(al);
          }
        });

        // AQUI verifico si hay mas datos que cargar
        var pagination = controller.get('pagination');
        var loadMore = pagination.actualPage < pagination.lastPage;
        controller.set('loadMore', loadMore);

        //CARGAR MAS COLLECTIONS
        if (!loadMore) {
          controller.set('collectionPage', pagination.lastPage);
        }
      }
      return albumsToShowFiltered;
    });

    //VER ESTO QUE SERIA
    controller.showroomsFilteredByBrand = Ember['default'].computed(function () {
      var showrooms = Ember['default'].user.get('showroom').get('company').get('showrooms');
      //Por si ya se ha desactivado en el controller
      if (showrooms.length > 1 && !controller.get('disableShowroomFilter')) {
        controller.set('disableShowroomFilter', false);
      } else {
        controller.set('disableShowroomFilter', true);
      }
      return utils['default'].addDefaultOption(showrooms, 'All Showrooms', 'label', null);
    });

    controller.searchFilterFunction = function () {

      var samples = utils['default'].getSamples('paginated');
      var allSamplesUnvisible = true;

      samples.forEach(function (s) {
        var checked = true; //s.get('look.album.published'); ya tiene que venir del backend filtrado albums no publicados
        if (onlySamplesSameShowroom && checked) {
          checked = s.setVisibleIfForeignCanPropose(controller.get('booking.owner.showroom'));
        }

        s.set('visible', checked);
        allSamplesUnvisible = allSamplesUnvisible && !checked;
      });
      controller.set('allSamplesUnvisible', allSamplesUnvisible);

      _hideControllerSamples(hideControllerSamples, controller);

      utils['default'].lazyLoad(2000, lazyContainer);
    };

    controller._checkStatusShoppingList = function (status, statusFilter, warning) {
      status = status.toLowerCase();
      statusFilter = statusFilter.toLowerCase();

      var arraycontains = undefined;

      switch (statusFilter) {
        //INTERNAL
        case "booked":
          arraycontains = ['booked', 'in'].contains(status);
          break;
        case "out":
          arraycontains = ['check in today', 'out'].contains(status);
          break;
        case "check out today":
          arraycontains = status === 'check out today';
          break;
        case "late check out":
          arraycontains = status === 'in' && warning;
          break;
        case "check in today":
          arraycontains = status === 'check in today';
          break;
        case "late check in":
          arraycontains = status === 'out' && warning;
          break;
        case "canceled":
          arraycontains = status.indexOf("canceled") !== -1;
          break;
        //REQUESTED
        case "proposed":
          arraycontains = status.indexOf("propos") !== -1;
          break;
        case "declined":
          arraycontains = status.indexOf("declined") !== -1;
          break;
        default:
          arraycontains = status == statusFilter;
          break;
      }
      return arraycontains;
    };

    controller._checkStatus = function (status, statusFilter, warning) {
      status = status.toLowerCase();
      statusFilter = statusFilter.toLowerCase();

      var options = [];
      var arraycontains = undefined;

      if (statusFilter === 'in') {
        options = ['check out today', 'in'];
        arraycontains = options.contains(status);
      } else if (statusFilter === 'out') {
        options = ['check in today', 'out'];
        arraycontains = options.contains(status);
      } else if (statusFilter === 'late check-out') {
        arraycontains = status === 'in' && warning;
      } else if (statusFilter === 'late check-in') {
        arraycontains = status === 'out' && warning;
      } else {
        arraycontains = status === statusFilter;
      }

      return arraycontains;
    };

    controller.looksFilteredByAlbum = Ember['default'].computed('albumFilter', function () {
      controller.set('lookFilter', null);
      controller.set('colourFilter', null);
      controller.set('statusFilter', null);
      var album = controller.get('albumFilter');
      if (!album || !album.get) {
        return [];
      }
      var albumId = album.get('id');
      var looks = album.get('looks');
      var looksFiltered = looks;
      if (albumId !== '0') {
        looksFiltered = looks.filter(function (look) {
          return look.get('album.id') === albumId && look.get('archived') === false && look.get('hasActiveSamples');
        });
      }
      return utils['default'].addDefaultOption(looksFiltered, 'All Looks', 'position', '');
    });

    controller.oneBrand = Ember['default'].computed('allBrands.length', function () {
      if (controller.get('allBrands.length') === 2) {
        return true;
      }
      return false;
    });

    Ember['default'].activeFilter = container;
  }

  function _hideControllerSamples(hideControllerSamples, controller) {
    if (hideControllerSamples && controller.get('samples')) {
      controller.get('samples').forEach(function (sample) {
        return sample.set('visible', false);
      });
    }
  }

  function _setVisibleSamples(onlySamplesSameShowroom, controller) {
    var samples = controller.get('albumFilter.samples') || utils['default'].getSamples('paginated');

    if (onlySamplesSameShowroom) {
      samples.forEach(function (sample) {
        sample.setVisibleIfForeignCanPropose(controller.get('booking.owner.showroom'));
      });
    } else {
      samples.setEach('visible', true);
    }
    if (controller.searchFilterFunction) {
      controller.searchFilterFunction();
    }
  }

});