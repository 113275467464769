define('bookalook/tests/routes/check-out/functions.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/check-out/functions.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/check-out/functions.js should pass jshint.\nroutes/check-out/functions.js: line 185, col 125, Missing semicolon.\nroutes/check-out/functions.js: line 439, col 36, [\'all\'] is better written in dot notation.\nroutes/check-out/functions.js: line 457, col 36, [\'all\'] is better written in dot notation.\n\n3 errors');
  });

});