define('bookalook/tests/static-tables.jshint', function () {

  'use strict';

  QUnit.module('JSHint - static-tables.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'static-tables.js should pass jshint.\nstatic-tables.js: line 65, col 40, [\'items\'] is better written in dot notation.\nstatic-tables.js: line 66, col 23, [\'name\'] is better written in dot notation.\nstatic-tables.js: line 72, col 12, [\'itemsUnique\'] is better written in dot notation.\n\n3 errors');
  });

});