define('bookalook/routes/terms-and-conditions/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    activate: function activate() {
      Ember['default'].$('body').addClass('page-privacy-and-terms');
      Ember['default'].$('.back_to_top').hide();
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-privacy-and-terms');
    }
  });

});