define('bookalook/routes/profile/brand-profile', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils'], function (exports, Ember, UserValidatorMixin, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    model: function model(params) {
      return utils['default'].getAPIJSON('brands/' + params.id + '/profile');
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('loadingDiscoverMore', true);
      controller.set('discoverBrands', null);
      utils['default'].getAPIJSON('discover/brands/' + model.id).then(function (brands) {
        controller.set('discoverBrands', brands);
        controller.set('loadingDiscoverMore', false);
      }, function () {
        controller.set('loadingDiscoverMore', false);
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-settings page-settings-brand');
      Ember['default'].$('.search_suggestion_wrapper').removeClass('show');
    },
    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-settings page-settings-brand');
    }
  });

});