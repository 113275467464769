define('bookalook/tests/acceptance/2-booking/booking-list-page-feature', ['exports'], function (exports) {

  'use strict';

  exports['default'] = "\n\n";

  /*Feature: Bookings list page
   As a PR user
   I want to see bookings list
    Scenario: Personal booking view
      Dado que soy un usuario registrado
       Y estoy en /bookings
     Cuando hago click en #personal-booking-view-link
     Entonces deberia ver (should see) el listado de bookings personales
    Scenario: Showroom booking view
      Dado que soy un usuario registrado
       Y estoy en /bookings
     Cuando hago click en #showroom-booking-view-link
     Entonces deberia ver (should see) el listado de bookings del showroom
    Scenario: Closed booking view
      Dado que soy un usuario registrado
       Y estoy en /bookings
     Cuando hago click en #closed-booking-view-link
     Entonces deberia ver (should see) el listado de bookings cerrados*/

});