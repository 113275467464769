define('bookalook/templates/search/modals/sample-detail-old', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 10
            },
            "end": {
              "line": 9,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","lookOverview.album.collection.brand.name",["loc",[null,[8,16],[8,60]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 10
            },
            "end": {
              "line": 11,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","lookOverview.collectionBrandName",["loc",[null,[10,16],[10,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 8
            },
            "end": {
              "line": 18,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pull-left");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","../images/lock-closed.png");
          dom.setAttribute(el2,"class","image_album_closed");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 8
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row height10");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","previous-finger");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","images/60758.svg");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","next-finger");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","images/60758.svg");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var element26 = dom.childAt(element25, [1]);
          var element27 = dom.childAt(element25, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element26);
          morphs[1] = dom.createElementMorph(element27);
          return morphs;
        },
        statements: [
          ["element","action",["showPreviousLook",["get","lookOverview",["loc",[null,[33,69],[33,81]]]]],[],["loc",[null,[33,41],[33,83]]]],
          ["element","action",["showNextLook",["get","lookOverview",["loc",[null,[34,61],[34,73]]]]],[],["loc",[null,[34,37],[34,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 8
            },
            "end": {
              "line": 41,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row height10");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","previous-finger");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","next-finger");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 15
            },
            "end": {
              "line": 50,
              "column": 15
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","gallery left arrow");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/arrow-left.png");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","gallery right arrow");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/arrow-right.png");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [1]);
          var element24 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element23);
          morphs[1] = dom.createElementMorph(element24);
          return morphs;
        },
        statements: [
          ["element","action",["changeLookSlide",["get","look.id",["loc",[null,[48,50],[48,57]]]],"left"],[],["loc",[null,[48,23],[48,66]]]],
          ["element","action",["changeLookSlide",["get","look.id",["loc",[null,[49,50],[49,57]]]],"right"],[],["loc",[null,[49,23],[49,67]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 15
            },
            "end": {
              "line": 53,
              "column": 15
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","gallery gallery-cover");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element22, 'id');
          morphs[1] = dom.createAttrMorph(element22, 'name');
          morphs[2] = dom.createAttrMorph(element22, 'src');
          return morphs;
        },
        statements: [
          ["attribute","id",["get","look.id",["loc",[null,[52,57],[52,64]]]]],
          ["attribute","name",["subexpr","get-array-object-parameter",[["get","look.avatars",["loc",[null,[52,101],[52,113]]]],0,"position"],[],["loc",[null,[52,72],[52,128]]]]],
          ["attribute","src",["subexpr","get-array-object-parameter",[["get","look.avatars",["loc",[null,[52,162],[52,174]]]],0,"avatar"],[],["loc",[null,[52,133],[52,187]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 15
            },
            "end": {
              "line": 55,
              "column": 15
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","gallery gallery-cover");
          dom.setAttribute(el1,"id","0");
          dom.setAttribute(el1,"name","default-image");
          dom.setAttribute(el1,"src","images/default-image.png");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 19
            },
            "end": {
              "line": 60,
              "column": 19
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","content text-left");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","look.publicDescription",["loc",[null,[59,50],[59,76]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 19
            },
            "end": {
              "line": 72,
              "column": 19
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","image_zoom_wrapper");
          var el2 = dom.createTextNode("\n                       ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/eye-icon.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                       ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                     ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","light-box",[],["href",["subexpr","@mut",[["get","look.avatars.0.avatar",["loc",[null,[65,42],[65,63]]]]],[],[]],"data-lightbox","look-group","data-title",["subexpr","@mut",[["get","look.publicDescription",["loc",[null,[67,42],[67,64]]]]],[],[]],"data-lightbox","name","data-class","image_zoom"],["loc",[null,[64,23],[70,25]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 19
            },
            "end": {
              "line": 83,
              "column": 19
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","image_zoom_wrapper");
          var el2 = dom.createTextNode("\n                       ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","images/eye-icon.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                       ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                     ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","light-box",[],["href","images/default-image.png","data-lightbox","look-group","data-title",["subexpr","@mut",[["get","look.publicDescription",["loc",[null,[78,42],[78,64]]]]],[],[]],"data-lightbox","name","data-class","image_zoom"],["loc",[null,[75,23],[81,25]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 17
              },
              "end": {
                "line": 92,
                "column": 17
              }
            },
            "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                   ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","gallery gallery-slide");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element21, 'id');
            morphs[1] = dom.createAttrMorph(element21, 'src');
            return morphs;
          },
          statements: [
            ["attribute","id",["get","avatar.position",["loc",[null,[91,59],[91,74]]]]],
            ["attribute","src",["get","avatar.avatar",["loc",[null,[91,83],[91,96]]]]]
          ],
          locals: ["avatar"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 89,
              "column": 15
            },
            "end": {
              "line": 93,
              "column": 15
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","look.avatars",["loc",[null,[90,35],[90,47]]]]],[],0,null,["loc",[null,[90,17],[92,26]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 15
            },
            "end": {
              "line": 95,
              "column": 15
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                 ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","gallery gallery-slide");
          dom.setAttribute(el1,"id","");
          dom.setAttribute(el1,"src","images/default-image.png");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 115,
                        "column": 36
                      },
                      "end": {
                        "line": 117,
                        "column": 36
                      }
                    },
                    "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1,"class","fa fa-circle");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element11 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element11);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["setSampleSelectedForJob",["get","lookSample",["loc",[null,[116,99],[116,109]]]],false],[],["loc",[null,[116,64],[116,117]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 117,
                        "column": 36
                      },
                      "end": {
                        "line": 119,
                        "column": 36
                      }
                    },
                    "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1,"class","fa fa-circle-o");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element10 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element10);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["setSampleSelectedForJob",["get","lookSample",["loc",[null,[118,101],[118,111]]]],true],[],["loc",[null,[118,66],[118,118]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 113,
                      "column": 28
                    },
                    "end": {
                      "line": 122,
                      "column": 28
                    }
                  },
                  "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element12 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element12,1,1);
                  morphs[1] = dom.createMorphAt(element12,3,3);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","lookSample.isSelectedForJob",["loc",[null,[115,42],[115,69]]]]],[],0,1,["loc",[null,[115,36],[119,43]]]],
                  ["content","lookSample.itemLabel",["loc",[null,[120,36],[120,60]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 24
                  },
                  "end": {
                    "line": 123,
                    "column": 24
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","lookSample.isSelectable",["loc",[null,[113,34],[113,57]]]]],[],0,null,["loc",[null,[113,28],[122,35]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 20
                },
                "end": {
                  "line": 124,
                  "column": 20
                }
              },
              "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","lookSample.archived",["loc",[null,[112,34],[112,53]]]]],[],0,null,["loc",[null,[112,24],[123,35]]]]
            ],
            locals: ["lookSample"],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 136,
                      "column": 24
                    },
                    "end": {
                      "line": 145,
                      "column": 24
                    }
                  },
                  "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","row");
                  var el3 = dom.createTextNode("\n                              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3,"class","col-xs-12");
                  var el4 = dom.createTextNode("\n                                ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("button");
                  dom.setAttribute(el4,"class","btn_secondary");
                  var el5 = dom.createTextNode("ADD");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1, 1, 1]);
                  var element9 = dom.childAt(element8, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element9, 'disabled');
                  morphs[1] = dom.createElementMorph(element9);
                  morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["attribute","disabled",["subexpr","not",[["get","samplesSelectedForJob",["loc",[null,[140,55],[140,76]]]]],[],["loc",[null,[140,49],[140,78]]]]],
                  ["element","action",["addSampleToJob",["get","lookOverview",["loc",[null,[140,105],[140,117]]]],["get","job",["loc",[null,[140,118],[140,121]]]]],[],["loc",[null,[140,79],[140,123]]]],
                  ["content","job.name",["loc",[null,[141,38],[141,50]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 22
                  },
                  "end": {
                    "line": 146,
                    "column": 22
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","unless",[["get","job.isClosed",["loc",[null,[136,34],[136,46]]]]],[],0,null,["loc",[null,[136,24],[145,35]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 134,
                  "column": 20
                },
                "end": {
                  "line": 147,
                  "column": 20
                }
              },
              "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","job.isNew",["loc",[null,[135,32],[135,41]]]]],[],0,null,["loc",[null,[135,22],[146,33]]]]
            ],
            locals: ["job"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 14
              },
              "end": {
                "line": 159,
                "column": 14
              }
            },
            "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","add-sample-to-job");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            var el4 = dom.createTextNode("SELECT SAMPLES");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","items another_samples");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3,"class","fa-ul");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","items select_job");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            dom.setAttribute(el3,"class","text-uppercase");
            var el4 = dom.createTextNode("ADD TO JOB");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-group validated-input searcher clearfix");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","images/lupa.png");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3,"class","job_list");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"type","button");
            dom.setAttribute(el3,"class","btn_primary_plus margin-top");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("Open new job");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","add-sample-to-job-edit");
            dom.setAttribute(el1,"class","edit-field");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","text-right");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"type","button");
            dom.setAttribute(el3,"class","btn_cancel");
            var el4 = dom.createTextNode("Cancel");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"type","button");
            dom.setAttribute(el3,"class","btn_secondary");
            var el4 = dom.createTextNode("Create new job");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var element14 = dom.childAt(element13, [3]);
            var element15 = dom.childAt(element13, [5]);
            var element16 = dom.childAt(element15, [7]);
            var element17 = dom.childAt(fragment, [3]);
            var element18 = dom.childAt(element17, [3]);
            var element19 = dom.childAt(element18, [1]);
            var element20 = dom.childAt(element18, [3]);
            var morphs = new Array(11);
            morphs[0] = dom.createAttrMorph(element14, 'id');
            morphs[1] = dom.createMorphAt(dom.childAt(element14, [1]),1,1);
            morphs[2] = dom.createAttrMorph(element15, 'id');
            morphs[3] = dom.createMorphAt(dom.childAt(element15, [3]),3,3);
            morphs[4] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
            morphs[5] = dom.createElementMorph(element16);
            morphs[6] = dom.createMorphAt(element16,0,0);
            morphs[7] = dom.createMorphAt(element17,1,1);
            morphs[8] = dom.createElementMorph(element19);
            morphs[9] = dom.createAttrMorph(element20, 'disabled');
            morphs[10] = dom.createElementMorph(element20);
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["available-items-",["get","lookOverview.id",["loc",[null,[109,43],[109,58]]]]]]],
            ["block","each",[["get","lookOverview.samples",["loc",[null,[111,28],[111,48]]]]],[],0,null,["loc",[null,[111,20],[124,29]]]],
            ["attribute","id",["concat",["in-shoppings-lists-",["get","id",["loc",[null,[127,46],[127,48]]]]]]],
            ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","jobSearchInput",["loc",[null,[131,46],[131,60]]]]],[],[]],"placeholder","Search job","class","col-xs-12"],["loc",[null,[131,20],[131,105]]]],
            ["block","each",[["get","filteredJobs",["loc",[null,[134,28],[134,40]]]]],[],1,null,["loc",[null,[134,20],[147,29]]]],
            ["element","action",["activateEdition","add-sample-to-job"],[],["loc",[null,[149,76],[149,124]]]],
            ["inline","fa-icon",["plus"],[],["loc",[null,[149,125],[149,143]]]],
            ["inline","partial",["jobs/partials/new-job-form"],[],["loc",[null,[153,16],[153,56]]]],
            ["element","action",["cancelEdition",null,"add-sample-to-job"],[],["loc",[null,[155,59],[155,110]]]],
            ["attribute","disabled",["subexpr","not",[["get","newJob.hasMinimunAttrsSet",["loc",[null,[156,125],[156,150]]]]],[],["loc",[null,[156,119],[156,152]]]]],
            ["element","action",["addSampleToJob",["get","lookOverview",["loc",[null,[156,88],[156,100]]]],["get","newJob",["loc",[null,[156,101],[156,107]]]]],[],["loc",[null,[156,62],[156,109]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 159,
                "column": 14
              },
              "end": {
                "line": 164,
                "column": 14
              }
            },
            "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","text-uppercase");
            var el3 = dom.createTextNode("No available items");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("There's no items in this look");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 12
            },
            "end": {
              "line": 165,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","lookOverview.samples.length",["loc",[null,[103,20],[103,47]]]]],[],0,1,["loc",[null,[103,14],[164,21]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 165,
              "column": 12
            },
            "end": {
              "line": 173,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","text-uppercase");
          var el3 = dom.createTextNode("No available items");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("You are not allowed to request items in this look");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","margin-top");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-external-link");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","pointer");
          var el4 = dom.createTextNode("Request permission");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1, 6, 2]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["element","action",["requestAlbumPermission",["get","lookOverview.album.id",["loc",[null,[170,106],[170,127]]]]],[],["loc",[null,[170,72],[170,129]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 184,
              "column": 10
            },
            "end": {
              "line": 188,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-12");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Select a sample to view details");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 209,
                    "column": 28
                  },
                  "end": {
                    "line": 211,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("img");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1, 0]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element1, 'src');
                return morphs;
              },
              statements: [
                ["attribute","src",["concat",[["subexpr","colour-url",[["get","sample.colour2",["loc",[null,[210,57],[210,71]]]]],[],["loc",[null,[210,44],[210,73]]]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 211,
                    "column": 28
                  },
                  "end": {
                    "line": 213,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 214,
                    "column": 28
                  },
                  "end": {
                    "line": 216,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("img");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 0]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element0, 'src');
                return morphs;
              },
              statements: [
                ["attribute","src",["concat",[["subexpr","colour-url",[["get","sample.colour3",["loc",[null,[215,57],[215,71]]]]],[],["loc",[null,[215,44],[215,73]]]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 216,
                    "column": 28
                  },
                  "end": {
                    "line": 218,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 223,
                    "column": 28
                  },
                  "end": {
                    "line": 225,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","sample.pattern1",["loc",[null,[224,34],[224,53]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 225,
                    "column": 28
                  },
                  "end": {
                    "line": 227,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 228,
                    "column": 28
                  },
                  "end": {
                    "line": 230,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","sample.pattern2",["loc",[null,[229,34],[229,53]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 230,
                    "column": 28
                  },
                  "end": {
                    "line": 232,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child8 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 233,
                    "column": 28
                  },
                  "end": {
                    "line": 235,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","sample.pattern3",["loc",[null,[234,34],[234,53]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child9 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 235,
                    "column": 28
                  },
                  "end": {
                    "line": 237,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child10 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 243,
                    "column": 28
                  },
                  "end": {
                    "line": 245,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","sample.fabric2",["loc",[null,[244,34],[244,52]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child11 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 245,
                    "column": 28
                  },
                  "end": {
                    "line": 247,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child12 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 248,
                    "column": 28
                  },
                  "end": {
                    "line": 250,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","sample.fabric3",["loc",[null,[249,34],[249,52]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child13 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 250,
                    "column": 28
                  },
                  "end": {
                    "line": 252,
                    "column": 28
                  }
                },
                "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 191,
                  "column": 16
                },
                "end": {
                  "line": 261,
                  "column": 16
                }
              },
              "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","margin-top-x3 sample-header");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h5");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1,"class","table table-hover table-center tr_min_height");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("COLORS");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("PATTERNS");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("FABRICS");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("SAMPLE DESCRIPTION");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("ul");
              dom.setAttribute(el5,"class","list-block");
              var el6 = dom.createTextNode("\n                            ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("li");
              var el7 = dom.createElement("img");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("ul");
              dom.setAttribute(el5,"class","list-block");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              dom.setAttribute(el4,"class","text-uppercase");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("ul");
              dom.setAttribute(el5,"class","list-block");
              var el6 = dom.createTextNode("\n                            ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("li");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                          ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [3, 3, 1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element3, [1, 0]);
              var element5 = dom.childAt(element2, [3, 1]);
              var element6 = dom.childAt(element2, [5, 1]);
              var morphs = new Array(11);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
              morphs[1] = dom.createAttrMorph(element4, 'src');
              morphs[2] = dom.createMorphAt(element3,3,3);
              morphs[3] = dom.createMorphAt(element3,4,4);
              morphs[4] = dom.createMorphAt(element5,1,1);
              morphs[5] = dom.createMorphAt(element5,2,2);
              morphs[6] = dom.createMorphAt(element5,3,3);
              morphs[7] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
              morphs[8] = dom.createMorphAt(element6,3,3);
              morphs[9] = dom.createMorphAt(element6,4,4);
              morphs[10] = dom.createMorphAt(dom.childAt(element2, [7, 1]),0,0);
              return morphs;
            },
            statements: [
              ["content","sample.item.name",["loc",[null,[193,24],[193,44]]]],
              ["attribute","src",["concat",[["subexpr","colour-url",[["get","sample.colour1",["loc",[null,[208,55],[208,69]]]]],[],["loc",[null,[208,42],[208,71]]]]]]],
              ["block","if",[["get","sample.colour2",["loc",[null,[209,34],[209,48]]]]],[],0,1,["loc",[null,[209,28],[213,35]]]],
              ["block","if",[["get","sample.colour3",["loc",[null,[214,34],[214,48]]]]],[],2,3,["loc",[null,[214,28],[218,35]]]],
              ["block","if",[["get","sample.pattern1",["loc",[null,[223,34],[223,49]]]]],[],4,5,["loc",[null,[223,28],[227,35]]]],
              ["block","if",[["get","sample.pattern2",["loc",[null,[228,34],[228,49]]]]],[],6,7,["loc",[null,[228,28],[232,35]]]],
              ["block","if",[["get","sample.pattern3",["loc",[null,[233,34],[233,49]]]]],[],8,9,["loc",[null,[233,28],[237,35]]]],
              ["content","sample.fabric1",["loc",[null,[242,32],[242,50]]]],
              ["block","if",[["get","sample.fabric2",["loc",[null,[243,34],[243,48]]]]],[],10,11,["loc",[null,[243,28],[247,35]]]],
              ["block","if",[["get","sample.fabric3",["loc",[null,[248,34],[248,48]]]]],[],12,13,["loc",[null,[248,28],[252,35]]]],
              ["content","sample.description",["loc",[null,[256,32],[256,54]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 190,
                "column": 14
              },
              "end": {
                "line": 262,
                "column": 14
              }
            },
            "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","sample.isSelectedForJob",["loc",[null,[191,22],[191,45]]]]],[],0,null,["loc",[null,[191,16],[261,23]]]]
          ],
          locals: ["sample"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 188,
              "column": 10
            },
            "end": {
              "line": 264,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-12");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","lookOverview.samples",["loc",[null,[190,22],[190,42]]]]],[],0,null,["loc",[null,[190,14],[262,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 272,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/search/modals/sample-detail-old.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Sample modal ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","sample-detail-searched");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header clearfix");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pull-left");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h4");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pull-left sample_album_info");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" - ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pull-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","close pull-right");
        dom.setAttribute(el6,"data-dismiss","modal");
        dom.setAttribute(el6,"aria-label","Close");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"aria-hidden","true");
        var el8 = dom.createTextNode("×");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","look_number_label");
        var el5 = dom.createTextNode("\n        Look ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row look-card-wrapper");
        var el6 = dom.createTextNode("\n           ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","look-table-wrapper col-xs-6");
        var el7 = dom.createTextNode("\n             ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","gallery gallery-container col-xs-12 look-image no-padding no-top-margin");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("               ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","image-description");
        var el9 = dom.createTextNode("\n                 ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","description");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                 ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n               ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("             ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n             ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","margin-top");
        var el8 = dom.createTextNode("\n               ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"id","download_image_btn");
        dom.setAttribute(el8,"href","");
        dom.setAttribute(el8,"download","");
        dom.setAttribute(el8,"class","btn_link");
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","fa fa-download");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Download image");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n             ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-6");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","job-sample-info");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","margin-top-plus");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h5");
        dom.setAttribute(el9,"class","text-uppercase");
        var el10 = dom.createTextNode("SAMPLE DETAILS");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element28 = dom.childAt(fragment, [2, 1, 1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element29, [5]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element31, [3]);
        var element34 = dom.childAt(element28, [5]);
        var element35 = dom.childAt(element34, [3]);
        var element36 = dom.childAt(element35, [1]);
        var element37 = dom.childAt(element36, [1]);
        var element38 = dom.childAt(element37, [4, 1]);
        var element39 = dom.childAt(element36, [3, 1]);
        var morphs = new Array(17);
        morphs[0] = dom.createMorphAt(element30,1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element30, [3]),0,0);
        morphs[2] = dom.createMorphAt(element29,3,3);
        morphs[3] = dom.createMorphAt(element32,0,0);
        morphs[4] = dom.createMorphAt(element32,2,2);
        morphs[5] = dom.createMorphAt(element33,0,0);
        morphs[6] = dom.createMorphAt(element33,2,2);
        morphs[7] = dom.createMorphAt(dom.childAt(element28, [3]),1,1);
        morphs[8] = dom.createMorphAt(element34,1,1);
        morphs[9] = dom.createMorphAt(element37,1,1);
        morphs[10] = dom.createMorphAt(element37,2,2);
        morphs[11] = dom.createMorphAt(element38,1,1);
        morphs[12] = dom.createMorphAt(element38,2,2);
        morphs[13] = dom.createMorphAt(element37,6,6);
        morphs[14] = dom.createElementMorph(element39);
        morphs[15] = dom.createMorphAt(dom.childAt(element35, [3]),1,1);
        morphs[16] = dom.createMorphAt(element35,7,7);
        return morphs;
      },
      statements: [
        ["block","if",[["get","lookOverview.album.collection.brand.name",["loc",[null,[7,16],[7,56]]]]],[],0,1,["loc",[null,[7,10],[11,17]]]],
        ["content","lookOverview.collectionBrandName",["loc",[null,[12,14],[12,50]]]],
        ["block","if",[["get","lookOverview.isPrivate",["loc",[null,[14,14],[14,36]]]]],[],2,null,["loc",[null,[14,8],[18,15]]]],
        ["content","lookOverview.album.collection.collectionType",["loc",[null,[20,15],[20,63]]]],
        ["inline","album-type",[["get","lookOverview.album",["loc",[null,[20,78],[20,96]]]]],[],["loc",[null,[20,65],[20,98]]]],
        ["content","lookOverview.album.collection.season",["loc",[null,[21,15],[21,55]]]],
        ["content","lookOverview.album.collection.year",["loc",[null,[21,58],[21,96]]]],
        ["content","lookOverview.position",["loc",[null,[28,13],[28,38]]]],
        ["block","if",[["subexpr","if-equal",[["get","showPreviousNext",["loc",[null,[31,24],[31,40]]]],1],[],["loc",[null,[31,14],[31,43]]]]],[],3,4,["loc",[null,[31,8],[41,15]]]],
        ["block","if",[["get","look.avatars.1.avatar",["loc",[null,[47,21],[47,42]]]]],[],5,null,["loc",[null,[47,15],[50,22]]]],
        ["block","if",[["get","look.avatars",["loc",[null,[51,21],[51,33]]]]],[],6,7,["loc",[null,[51,15],[55,22]]]],
        ["block","if",[["get","look.publicDescription",["loc",[null,[58,25],[58,47]]]]],[],8,null,["loc",[null,[58,19],[60,26]]]],
        ["block","if",[["get","look.avatars",["loc",[null,[61,25],[61,37]]]]],[],9,10,["loc",[null,[61,19],[83,26]]]],
        ["block","if",[["get","look.avatars",["loc",[null,[89,21],[89,33]]]]],[],11,12,["loc",[null,[89,15],[95,23]]]],
        ["element","action",["lookDownloadImage"],[],["loc",[null,[98,50],[98,80]]]],
        ["block","unless",[["get","lookOverview.isPrivate",["loc",[null,[102,22],[102,44]]]]],[],13,14,["loc",[null,[102,12],[173,23]]]],
        ["block","unless",[["get","samplesSelectedForJob",["loc",[null,[184,20],[184,41]]]]],[],15,16,["loc",[null,[184,10],[264,21]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16]
    };
  }()));

});