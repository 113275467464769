define('bookalook/routes/directory/index', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/utils', 'bookalook/routes/directory/functions', 'bookalook/mixins/user-validator-mixin'], function (exports, Ember, config, utils, directoryFunctions, UserValidatorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    model: function model() {
      return Ember['default'].RSVP.hash({
        brands: utils['default'].getAPIJSON('brands/list')
      });
    },

    actions: {},

    setupController: function setupController(controller, model) {
      /*//Hide TOP BAR MENU modals
      Ember.$('#bags-menu').removeClass('showToggle');
      Ember.$('#clothing-menu').removeClass('showToggle');
      Ember.$('#accesories-menu').removeClass('showToggle');
      Ember.$('#shoes-menu').removeClass('showToggle');
      Ember.$('#lingerie-menu').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-bags').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-clothing').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-shoes').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-accesories').removeClass('showToggle');
      Ember.$('#contenedor-absoluto-lingerie').removeClass('showToggle');*/

      this._super(controller, model);
      controller.setProperties(model);

      var brands = controller.get('brands');
      var sortedBrands = directoryFunctions['default'].sortBrandsByLetter(brands);

      this.controller.set('sortedBrands', sortedBrands);

      //Layout for brands directory
      var colmd = 'col-md-4';
      //if (sortedBrands.length == 4) {
      //  colmd = 'col-md-3';
      //}

      this.controller.set('col-md', colmd);
    }
  });

});