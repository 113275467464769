define('bookalook/templates/contacts-lists/modals/new-refactor-manual-contact', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 8
            },
            "end": {
              "line": 167,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/contacts-lists/modals/new-refactor-manual-contact.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","gray-font form-fields-set gray-fieldset");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","gray-font");
          dom.setAttribute(el2,"data-toggle","collapse");
          dom.setAttribute(el2,"data-parent","#new-refactor-manual-contact");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","images/forward.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","input_label main_address_label");
          var el5 = dom.createTextNode("Additional address ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","remove-address");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"type","button");
          dom.setAttribute(el5,"class","btn_bal_circle_form close_btn");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","icon");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                  ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","collapse");
          dom.setAttribute(el2,"style","width: 100%;");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-group validated-select col-xs-12 no-padding multiple-select-contact");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Search member");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","address-contact-fieldset");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("Address 1");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Address 2");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Address 3");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Country");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","set-as-main-checkbox");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("input");
          dom.setAttribute(el6,"type","checkbox");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Set as main address");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                  ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label");
          var el6 = dom.createTextNode("City");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Post code");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Phone number 1");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","input_label input_label_margin_0");
          var el6 = dom.createTextNode("Phone number 2");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [5, 1]);
          var element4 = dom.childAt(element0, [3]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [17, 1]);
          if (this.cachedFragment) { dom.repairClonedNode(element7,[],true); }
          var element8 = dom.childAt(element5, [3]);
          var morphs = new Array(15);
          morphs[0] = dom.createAttrMorph(element1, 'data-target');
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createAttrMorph(element4, 'id');
          morphs[4] = dom.createMorphAt(dom.childAt(element4, [1]),3,3);
          morphs[5] = dom.createMorphAt(element6,3,3);
          morphs[6] = dom.createMorphAt(element6,7,7);
          morphs[7] = dom.createMorphAt(element6,11,11);
          morphs[8] = dom.createMorphAt(element6,15,15);
          morphs[9] = dom.createAttrMorph(element7, 'checked');
          morphs[10] = dom.createElementMorph(element7);
          morphs[11] = dom.createMorphAt(element8,3,3);
          morphs[12] = dom.createMorphAt(element8,7,7);
          morphs[13] = dom.createMorphAt(element8,11,11);
          morphs[14] = dom.createMorphAt(element8,15,15);
          return morphs;
        },
        statements: [
          ["attribute","data-target",["concat",["#address_container_",["subexpr","plus-one",[["get","index",["loc",[null,[108,101],[108,106]]]]],[],["loc",[null,[108,90],[108,108]]]]]]],
          ["inline","plus-one",[["get","index",["loc",[null,[111,91],[111,96]]]]],[],["loc",[null,[111,80],[111,98]]]],
          ["element","action",["removeAddress",["get","address",["loc",[null,[113,66],[113,73]]]]],[],["loc",[null,[113,40],[113,75]]]],
          ["attribute","id",["concat",["address_container_",["subexpr","plus-one",[["get","index",["loc",[null,[119,50],[119,55]]]]],[],["loc",[null,[119,39],[119,57]]]]]]],
          ["inline","select-2",[],["placeholder","Copy address from existing contact (by Name, Company or Address field)","value",["subexpr","@mut",[["get","address.listSelector",["loc",[null,[124,24],[124,44]]]]],[],[]],"content",["subexpr","@mut",[["get","contactsAddresses",["loc",[null,[125,26],[125,43]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","address","enabled",["subexpr","if-true-and-false",[["get","cAddressesListNotLoading",["loc",[null,[128,46],[128,70]]]],["get","cAddressesListHide",["loc",[null,[128,71],[128,89]]]]],[],["loc",[null,[128,27],[128,90]]]]],["loc",[null,[122,16],[129,18]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[134,42],[134,49]]]]],[],[]],"property","street1","class","no-padding-left hint-left col-xs-12"],["loc",[null,[134,18],[134,114]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[136,42],[136,49]]]]],[],[]],"property","street2","class","no-padding-left col-xs-12"],["loc",[null,[136,18],[136,104]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[138,42],[138,49]]]]],[],[]],"property","street3","class","no-padding-left col-xs-12"],["loc",[null,[138,18],[138,104]]]],
          ["inline","validated-select",[],["content",["subexpr","@mut",[["get","countryList",["loc",[null,[141,40],[141,51]]]]],[],[]],"model",["subexpr","@mut",[["get","address",["loc",[null,[142,40],[142,47]]]]],[],[]],"property","country","class","col-xs-12 hint-right no-padding-right","optionValuePath","name","optionLabelPath","name"],["loc",[null,[140,18],[147,20]]]],
          ["attribute","checked",["get","contactToAddAddress.main",["loc",[null,[149,107],[149,131]]]]],
          ["element","action",["toggleSetAsMainAddress",["get","contactToAdd",["loc",[null,[149,61],[149,73]]]],["get","address",["loc",[null,[149,74],[149,81]]]]],["on","change"],["loc",[null,[149,27],[149,96]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[155,42],[155,49]]]]],[],[]],"property","city","class","no-padding-left hint-right col-xs-12"],["loc",[null,[155,18],[155,112]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[157,42],[157,49]]]]],[],[]],"property","zipCode","class","no-padding-left hint-right col-xs-12"],["loc",[null,[157,18],[157,115]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[159,42],[159,49]]]]],[],[]],"property","telephone1","class","no-padding-left col-xs-12"],["loc",[null,[159,18],[159,107]]]],
          ["inline","validated-input",[],["model",["subexpr","@mut",[["get","address",["loc",[null,[161,42],[161,49]]]]],[],[]],"property","telephone2","class","no-padding-left col-xs-12"],["loc",[null,[161,18],[161,107]]]]
        ],
        locals: ["address","index"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 209,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/contacts-lists/modals/new-refactor-manual-contact.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New MANUAL contact ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade bal-modal-mini with-columns");
        dom.setAttribute(el1,"id","new-refactor-manual-contact");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-header");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","modal-title");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("NEW");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("strong");
        var el8 = dom.createTextNode("CONTACT");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("hr");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-body");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("Email address*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("Add to list(s)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col-xs-12 form-group validated-select last-input multiple-select-contact-list-new-contact");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font form-fields-set");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("First name*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label input_label_margin_0");
        var el9 = dom.createTextNode("Job title*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("Last name*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label input_label_margin_0");
        var el9 = dom.createTextNode("Company*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","icon_new_address");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","add-address-btn-img pointer");
        dom.setAttribute(el7,"src","images/new_address.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font form-fields-set gray-fieldset");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","gray-font");
        dom.setAttribute(el7,"data-toggle","collapse");
        dom.setAttribute(el7,"data-target","#address_container");
        dom.setAttribute(el7,"data-parent","#new-refactor-manual-contact");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("img");
        dom.setAttribute(el9,"src","images/forward.svg");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","input_label main_address_label");
        var el10 = dom.createTextNode("Main address");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","address_container");
        dom.setAttribute(el7,"class","collapse in");
        dom.setAttribute(el7,"style","width: 100%;");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-group validated-select col-xs-12 no-padding multiple-select-contact");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label");
        var el11 = dom.createTextNode("Search member");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","address-contact-fieldset");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label");
        var el11 = dom.createTextNode("Address 1");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Address 2");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Address 3");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Country");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label");
        var el11 = dom.createTextNode("City");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Post code");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Phone number 1");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","input_label input_label_margin_0");
        var el11 = dom.createTextNode("Phone number 2");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n        <div class=\"gray-font\">\n          <div>\n            <span class=\"input_label\">Website</span>\n            {{validated-input    model=contactToAdd property='email' class='col-xs-12 hint-left no-padding-left '}}\n          </div>\n          <div>\n            <span class=\"input_label\">Instagram</span>\n            {{validated-input    model=contactToAdd property='email' class='col-xs-12 hint-left no-padding-left '}}\n            <span class=\"input_label input_label_margin_0\">Facebook</span>\n            {{validated-input    model=contactToAdd property='email' class='col-xs-12 hint-left no-padding-left '}}\n          </div>\n        </div>\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gray-font");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","input_label");
        var el9 = dom.createTextNode("VAT (OPTIONAL)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-invite-join");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Invite contact to join Bookalook");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","modal-footer");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn_bal_cancel pull-left");
        dom.setAttribute(el6,"data-dismiss","modal");
        var el7 = dom.createTextNode("Cancel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn_bal_primary pull-right");
        dom.setAttribute(el6,"data-dismiss","modal");
        var el7 = dom.createTextNode("Create contact");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $myGroup = $('#new-refactor-manual-contact');\n  $myGroup.on('show.bs.collapse','.collapse', function() {\n    $('#new-refactor-manual-contact').find('.collapse.in').collapse('hide');\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [2, 1, 1, 1]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element10, [5, 1]);
        var element16 = dom.childAt(element10, [7, 3]);
        var element17 = dom.childAt(element16, [3]);
        var element18 = dom.childAt(element17, [1]);
        var element19 = dom.childAt(element17, [3]);
        var element20 = dom.childAt(element10, [13]);
        var element21 = dom.childAt(element9, [5]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element21, [3]);
        var morphs = new Array(22);
        morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(element11, [3, 3]),1,1);
        morphs[2] = dom.createMorphAt(element13,3,3);
        morphs[3] = dom.createMorphAt(element13,7,7);
        morphs[4] = dom.createMorphAt(element14,3,3);
        morphs[5] = dom.createMorphAt(element14,7,7);
        morphs[6] = dom.createElementMorph(element15);
        morphs[7] = dom.createMorphAt(dom.childAt(element16, [1]),3,3);
        morphs[8] = dom.createMorphAt(element18,3,3);
        morphs[9] = dom.createMorphAt(element18,7,7);
        morphs[10] = dom.createMorphAt(element18,11,11);
        morphs[11] = dom.createMorphAt(element18,15,15);
        morphs[12] = dom.createMorphAt(element19,3,3);
        morphs[13] = dom.createMorphAt(element19,7,7);
        morphs[14] = dom.createMorphAt(element19,11,11);
        morphs[15] = dom.createMorphAt(element19,15,15);
        morphs[16] = dom.createMorphAt(element10,9,9);
        morphs[17] = dom.createMorphAt(dom.childAt(element20, [1]),3,3);
        morphs[18] = dom.createMorphAt(dom.childAt(element20, [3, 1]),0,0);
        morphs[19] = dom.createElementMorph(element22);
        morphs[20] = dom.createAttrMorph(element23, 'disabled');
        morphs[21] = dom.createElementMorph(element23);
        return morphs;
      },
      statements: [
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[18,39],[18,51]]]]],[],[]],"property","email","class","email-contact col-xs-12 hint-left no-padding-left "],["loc",[null,[18,14],[18,129]]]],
        ["inline","multiple-select",[],["list",["subexpr","@mut",[["get","addContactSelectedLists",["loc",[null,[24,25],[24,48]]]]],[],[]],"content",["subexpr","@mut",[["get","contactLists",["loc",[null,[25,28],[25,40]]]]],[],[]],"placeholder","Select Lists","optionLabelPath","name"],["loc",[null,[23,16],[28,18]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[36,41],[36,53]]]]],[],[]],"property","name","class","col-xs-12 hint-left no-padding-left "],["loc",[null,[36,14],[36,116]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[38,39],[38,51]]]]],[],[]],"property","jobTitle","class","col-xs-12 hint-left no-padding-left"],["loc",[null,[38,14],[38,117]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[42,39],[42,51]]]]],[],[]],"property","surname","class","col-xs-12 hint-right no-padding-right"],["loc",[null,[42,14],[42,118]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[44,39],[44,51]]]]],[],[]],"property","company","class","col-xs-12 hint-right no-padding-right"],["loc",[null,[44,14],[44,118]]]],
        ["element","action",["addAnotherAddress"],[],["loc",[null,[49,17],[49,47]]]],
        ["inline","select-2",[],["placeholder","Copy address from existing contact (by Name, Company or Address field)","value",["subexpr","@mut",[["get","contactToAddAddress.listSelector",["loc",[null,[64,24],[64,56]]]]],[],[]],"content",["subexpr","@mut",[["get","contactsAddresses",["loc",[null,[65,26],[65,43]]]]],[],[]],"optionLabelPath","safeString","optionValuePath","address","enabled",["subexpr","if-true-and-false",[["get","cAddressesListNotLoading",["loc",[null,[68,46],[68,70]]]],["get","cAddressesListHide",["loc",[null,[68,71],[68,89]]]]],[],["loc",[null,[68,27],[68,90]]]]],["loc",[null,[62,16],[69,18]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[74,44],[74,63]]]]],[],[]],"property","street1","class","no-padding-left hint-left col-xs-12"],["loc",[null,[74,20],[74,128]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[76,44],[76,63]]]]],[],[]],"property","street2","class","no-padding-left col-xs-12"],["loc",[null,[76,20],[76,118]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[78,44],[78,63]]]]],[],[]],"property","street3","class","no-padding-left col-xs-12"],["loc",[null,[78,20],[78,118]]]],
        ["inline","validated-select",[],["content",["subexpr","@mut",[["get","countryList",["loc",[null,[81,42],[81,53]]]]],[],[]],"model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[82,42],[82,61]]]]],[],[]],"property","country","class","col-xs-12 hint-right no-padding-right","optionValuePath","name","optionLabelPath","name"],["loc",[null,[80,20],[87,22]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[91,44],[91,63]]]]],[],[]],"property","city","class","no-padding-left hint-right col-xs-12"],["loc",[null,[91,20],[91,126]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[93,44],[93,63]]]]],[],[]],"property","zipCode","class","no-padding-left hint-right col-xs-12"],["loc",[null,[93,20],[93,129]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[95,44],[95,63]]]]],[],[]],"property","telephone1","class","no-padding-left col-xs-12"],["loc",[null,[95,20],[95,121]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAddAddress",["loc",[null,[97,44],[97,63]]]]],[],[]],"property","telephone2","class","no-padding-left col-xs-12"],["loc",[null,[97,20],[97,121]]]],
        ["block","each",[["get","contactToAdd.privateAddresses",["loc",[null,[106,16],[106,45]]]]],[],0,null,["loc",[null,[106,8],[167,17]]]],
        ["inline","validated-input",[],["model",["subexpr","@mut",[["get","contactToAdd",["loc",[null,[187,36],[187,48]]]]],[],[]],"property","vat","class","col-xs-12 hint-left no-padding-left"],["loc",[null,[187,12],[187,109]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","invite-join",["loc",[null,[190,75],[190,86]]]]],[],[]]],["loc",[null,[190,43],[190,88]]]],
        ["element","action",["cancelEdition",["get","contactToAdd",["loc",[null,[196,56],[196,68]]]]],[],["loc",[null,[196,30],[196,71]]]],
        ["attribute","disabled",["get","createNewContactDisabled",["loc",[null,[197,70],[197,94]]]]],
        ["element","action",["saveNewContact"],[],["loc",[null,[197,30],[197,58]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});