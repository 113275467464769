define('bookalook/models/contacts-list', ['exports', 'ember', 'ember-data', 'bookalook/config/environment', 'bookalook/mixins/model-validator'], function (exports, Ember, DS, config, Validator) {

  'use strict';

  var privacyLabels = ['Only me', 'All users of my showroom'];

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    lastEdited: DS['default'].attr('string'),
    privacy: DS['default'].attr('string'),

    owner: DS['default'].belongsTo('user', { async: true, inverse: null }),
    users: DS['default'].hasMany('user', { async: true, inverse: null }),
    contacts: DS['default'].hasMany('contact', { async: true }),

    privacyLabel: (function () {
      return this.get('privacy');
    }).property('privacy'),

    isOwned: (function () {
      return this.get('owner.id') === Ember['default'].user.get('id');
    }).property('owner'),

    contactsNumber: (function () {
      var number = this._internalModel._relationships.get("contacts").canonicalState.length;
      return number;
    }).property('contacts.@each'),

    nonMembers: (function () {
      return this.get('contacts').filter(function (c) {
        return !c.get('user.id');
      });
    }).property('contacts.@each'),

    contactsIds: (function () {
      var ids = this._internalModel._relationships.get("contacts").canonicalState.map(function (c) {
        return c.id;
      });
      return ids;
    }).property('contacts.@each.user'),

    usersIds: (function () {
      var contactsFiltered = this.get('contacts').filter(function (c) {
        return c.get('hasUser');
      });
      return contactsFiltered.map(function (c) {
        return parseInt(c.get('user.id'));
      });
    }).property('contacts.@each.hasUser'),

    downloadLink: (function () {
      var contacts = this._internalModel._relationships.get("contacts").members.list;
      contacts = contacts.map(function (c) {
        return c.id;
      });
      return config['default'].APIURL + 'excels?ids=' + contacts.join(',');
    }).property('contacts.@each'),

    checkedObserver: (function () {
      if (this.get('checked')) {
        Ember['default'].bulk.lists.addObject(this);
      } else {
        Ember['default'].bulk.lists.removeObject(this);
      }
    }).observes('checked'),

    ownerNameLabel: (function () {
      if (this.get('isOwned')) {
        return "ME";
      } else {
        return this.get('owner.fullName');
      }
    }).property('isOwned', 'owner'),

    allContactsChecked: (function () {
      var contacts = this.get('contacts');
      var number = contacts.get('length');
      contacts = contacts.filter(function (c) {
        return c.get('checked');
      });
      return number === contacts.length;
    }).property('contacts.@each.checked'),

    visibleContacts: (function () {
      var contacts = this.get('contacts');
      return contacts.filter(function (c) {
        return c.get('visible');
      });
    }).property('contacts.@each.visible'),

    ownershipOptions: [{ id: 1, text: privacyLabels[0] }, { id: 2, text: privacyLabels[1] }],

    isPrivateList: (function () {
      return this.get('privacy') == "Only me";
    }).property('privacy'),

    validations: {
      'name': {
        presence: true
      }
    }
  });

});