define('bookalook/tests/helpers/start-app', ['exports', 'ember', 'bookalook/app', 'bookalook/config/environment', 'bookalook/tests/helpers/_given', 'bookalook/tests/helpers/_when', 'bookalook/tests/helpers/_then', 'bookalook/tests/helpers/wait-for-modal-open', 'bookalook/tests/helpers/get-controller', 'bookalook/tests/helpers/get-store'], function (exports, Ember, Application, config) {

  'use strict';



  exports['default'] = startApp;
  function startApp(attrs) {
    var application;

    var attributes = Ember['default'].merge({}, config['default'].APP);
    attributes = Ember['default'].merge(attributes, attrs); // use defaults, but you can override;

    Ember['default'].run(function () {

      Ember['default'].openModal = function () {};

      application = Application['default'].create(attributes);
      application.setupForTesting();
      application.injectTestHelpers();

      visit('/session/logout');
    });

    return application;
  }

});