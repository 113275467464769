define('bookalook/templates/check-out/partials/checkout-aditional-brand-refactor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 19,
                      "column": 18
                    },
                    "end": {
                      "line": 19,
                      "column": 117
                    }
                  },
                  "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("th");
                  dom.setAttribute(el1,"class","table_col_showroom table_col_hide_tablet");
                  var el2 = dom.createTextNode("Showroom");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 22,
                      "column": 20
                    },
                    "end": {
                      "line": 24,
                      "column": 20
                    }
                  },
                  "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("input");
                  dom.setAttribute(el1,"checked","");
                  dom.setAttribute(el1,"type","checkbox");
                  dom.setAttribute(el1,"class","bal-checkbox-input samples-id samples-group");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element1, 'id');
                  morphs[1] = dom.createElementMorph(element1);
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["checkout-a-look-id-",["get","look.id",["loc",[null,[23,193],[23,200]]]]]]],
                  ["element","action",["checkCheckoutLookVisibleSamples",["get","look",["loc",[null,[23,80],[23,84]]]]],["on","change"],["loc",[null,[23,37],[23,99]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 24,
                      "column": 20
                    },
                    "end": {
                      "line": 26,
                      "column": 20
                    }
                  },
                  "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("input");
                  dom.setAttribute(el1,"type","checkbox");
                  dom.setAttribute(el1,"class","bal-checkbox-input samples-id samples-group");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element0, 'id');
                  morphs[1] = dom.createElementMorph(element0);
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["checkout-a-look-id-",["get","look.id",["loc",[null,[25,185],[25,192]]]]]]],
                  ["element","action",["checkCheckoutLookVisibleSamples",["get","look",["loc",[null,[25,72],[25,76]]]]],["on","change"],["loc",[null,[25,29],[25,91]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 35,
                            "column": 20
                          },
                          "end": {
                            "line": 37,
                            "column": 20
                          }
                        },
                        "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","partial",["check-out/partials/checkout-aditional-sample-brand-refactor"],[],["loc",[null,[36,22],[36,95]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 34,
                          "column": 20
                        },
                        "end": {
                          "line": 38,
                          "column": 20
                        }
                      },
                      "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","sample.checked",["loc",[null,[35,26],[35,40]]]]],[],0,null,["loc",[null,[35,20],[37,27]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 18
                      },
                      "end": {
                        "line": 39,
                        "column": 18
                      }
                    },
                    "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                  },
                  arity: 2,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","sample.visible",["loc",[null,[34,26],[34,40]]]]],[],0,null,["loc",[null,[34,20],[38,27]]]]
                  ],
                  locals: ["sample","sampleIndex"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 32,
                      "column": 16
                    },
                    "end": {
                      "line": 40,
                      "column": 16
                    }
                  },
                  "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","sampleGroup.all",["loc",[null,[33,26],[33,41]]]]],[],0,null,["loc",[null,[33,18],[39,27]]]]
                ],
                locals: ["sampleGroup"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 8
                  },
                  "end": {
                    "line": 45,
                    "column": 8
                  }
                },
                "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","look-table-wrapper col-xs-12");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table-column no-padding");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("table");
                dom.setAttribute(el3,"class","table bal-table table-hover table-center table-height tr_min_height");
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("thead");
                var el5 = dom.createTextNode("\n                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("tr");
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_sample");
                var el7 = dom.createTextNode("Sample");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_count");
                var el7 = dom.createTextNode("Count");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_colour");
                var el7 = dom.createTextNode("Colours");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_barcode");
                var el7 = dom.createTextNode("Barcode");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_calendar");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                  ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("th");
                dom.setAttribute(el6,"class","table_col_check");
                var el7 = dom.createTextNode("\n");
                dom.appendChild(el6, el7);
                var el7 = dom.createComment("");
                dom.appendChild(el6, el7);
                var el7 = dom.createTextNode("                    ");
                dom.appendChild(el6, el7);
                var el7 = dom.createElement("label");
                dom.setAttribute(el7,"class","bal-checkbox-label");
                dom.appendChild(el6, el7);
                var el7 = dom.createTextNode("\n                  ");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("tbody");
                var el5 = dom.createTextNode("\n");
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n              ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [3, 1]);
                var element4 = dom.childAt(element3, [1, 1]);
                var element5 = dom.childAt(element4, [13]);
                var element6 = dom.childAt(element5, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(element2,1,1);
                morphs[1] = dom.createMorphAt(element4,9,9);
                morphs[2] = dom.createMorphAt(element5,1,1);
                morphs[3] = dom.createAttrMorph(element6, 'for');
                morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
                return morphs;
              },
              statements: [
                ["inline","partial",["components/looks-gallery"],[],["loc",[null,[10,12],[10,50]]]],
                ["block","unless",[["get","disableShowroomFilter",["loc",[null,[19,28],[19,49]]]]],[],0,null,["loc",[null,[19,18],[19,128]]]],
                ["block","if",[["get","look.allVisibleSamplesAreGoingToCheckout",["loc",[null,[22,26],[22,66]]]]],[],1,2,["loc",[null,[22,20],[26,27]]]],
                ["attribute","for",["concat",["checkout-a-look-id-",["get","look.id",["loc",[null,[27,53],[27,60]]]]]]],
                ["block","each",[["get","look.groupedSamples",["loc",[null,[32,24],[32,43]]]]],[],3,null,["loc",[null,[32,16],[40,25]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 6
                },
                "end": {
                  "line": 46,
                  "column": 6
                }
              },
              "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","look.hasVisibleAndCheckedSamples",["loc",[null,[8,14],[8,46]]]]],[],0,null,["loc",[null,[8,8],[45,15]]]]
            ],
            locals: ["look"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 6
              },
              "end": {
                "line": 48,
                "column": 0
              }
            },
            "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","clearfix");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element7,1,1);
            morphs[1] = dom.createMorphAt(element7,3,3);
            return morphs;
          },
          statements: [
            ["inline","partial",["global/album-header"],[],["loc",[null,[6,6],[6,39]]]],
            ["block","each",[["get","album.looks",["loc",[null,[7,14],[7,25]]]]],[],0,null,["loc",[null,[7,6],[46,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","album.hasVisibleAndCheckedSamples",["loc",[null,[4,12],[4,45]]]]],[],0,null,["loc",[null,[4,6],[48,7]]]]
        ],
        locals: ["album"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/check-out/partials/checkout-aditional-brand-refactor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","look-card-wrapper clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),1,1);
        return morphs;
      },
      statements: [
        ["block","each",[["get","collection.albums",["loc",[null,[3,12],[3,29]]]]],[],0,null,["loc",[null,[3,4],[49,9]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});