define('bookalook/templates/components/add-samples-loan-form-look', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 20
                },
                "end": {
                  "line": 22,
                  "column": 20
                }
              },
              "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"checked","");
              dom.setAttribute(el1,"type","checkbox");
              dom.setAttribute(el1,"class","bal-checkbox-input samples-id samples-group");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              if (this.cachedFragment) { dom.repairClonedNode(element13,[],true); }
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element13, 'id');
              morphs[1] = dom.createElementMorph(element13);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["add-samples-look-id-",["get","look.id",["loc",[null,[21,172],[21,179]]]]]]],
              ["element","action",["checkLook",["get","look",["loc",[null,[21,58],[21,62]]]]],["on","change"],["loc",[null,[21,37],[21,77]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 20
                },
                "end": {
                  "line": 24,
                  "column": 20
                }
              },
              "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"type","checkbox");
              dom.setAttribute(el1,"class","bal-checkbox-input samples-id samples-group");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element12, 'id');
              morphs[1] = dom.createElementMorph(element12);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["add-samples-look-id-",["get","look.id",["loc",[null,[23,164],[23,171]]]]]]],
              ["element","action",["checkLook",["get","look",["loc",[null,[23,50],[23,54]]]]],["on","change"],["loc",[null,[23,29],[23,69]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 43,
                          "column": 28
                        },
                        "end": {
                          "line": 45,
                          "column": 28
                        }
                      },
                      "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("li");
                      var el2 = dom.createElement("img");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element4 = dom.childAt(fragment, [1, 0]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createAttrMorph(element4, 'src');
                      return morphs;
                    },
                    statements: [
                      ["attribute","src",["concat",[["get","sample.colour2URL",["loc",[null,[44,46],[44,63]]]]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 46,
                          "column": 28
                        },
                        "end": {
                          "line": 48,
                          "column": 28
                        }
                      },
                      "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("li");
                      var el2 = dom.createElement("img");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element3 = dom.childAt(fragment, [1, 0]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createAttrMorph(element3, 'src');
                      return morphs;
                    },
                    statements: [
                      ["attribute","src",["concat",[["get","sample.colour3URL",["loc",[null,[47,46],[47,63]]]]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 60,
                          "column": 26
                        },
                        "end": {
                          "line": 62,
                          "column": 26
                        }
                      },
                      "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","fa fa-circle-o-notch fa-spin");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 63,
                            "column": 28
                          },
                          "end": {
                            "line": 63,
                            "column": 80
                          }
                        },
                        "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("img");
                        dom.setAttribute(el1,"src","images/warning.svg");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.7",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 67,
                                "column": 37
                              },
                              "end": {
                                "line": 67,
                                "column": 241
                              }
                            },
                            "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createElement("span");
                            dom.setAttribute(el1,"class","color_primary");
                            dom.setAttribute(el1,"data-dismiss","modal");
                            var el2 = dom.createTextNode("Booking #");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createComment("");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                            return morphs;
                          },
                          statements: [
                            ["content","sample.shoppingListInBookingShowroomCustom",["loc",[null,[67,188],[67,234]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.7",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 66,
                              "column": 30
                            },
                            "end": {
                              "line": 68,
                              "column": 30
                            }
                          },
                          "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("br");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["block","link-to",["bookings.booking-details",["get","sample.shoppingListInBooking",["loc",[null,[67,75],[67,103]]]],["subexpr","query-params",[],["search",""],["loc",[null,[67,104],[67,128]]]]],[],0,null,["loc",[null,[67,37],[67,253]]]]
                        ],
                        locals: [],
                        templates: [child0]
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.7",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 68,
                              "column": 30
                            },
                            "end": {
                              "line": 70,
                              "column": 30
                            }
                          },
                          "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("br");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("Booking #");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["content","sample.shoppingListInBookingShowroomCustom",["loc",[null,[69,46],[69,92]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.7",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 65,
                            "column": 28
                          },
                          "end": {
                            "line": 71,
                            "column": 28
                          }
                        },
                        "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","sample.bookingPermission",["loc",[null,[66,36],[66,60]]]]],[],0,1,["loc",[null,[66,30],[70,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 62,
                          "column": 26
                        },
                        "end": {
                          "line": 72,
                          "column": 26
                        }
                      },
                      "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element2 = dom.childAt(fragment, [3]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createAttrMorph(element2, 'class');
                      morphs[2] = dom.createMorphAt(element2,0,0);
                      morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","sample.warning",["loc",[null,[63,34],[63,48]]]]],[],0,null,["loc",[null,[63,28],[63,87]]]],
                      ["attribute","class",["concat",["sample_",["get","sample.shoppingListStatus",["loc",[null,[64,50],[64,75]]]]]]],
                      ["content","sample.shoppingListStatus",["loc",[null,[64,79],[64,108]]]],
                      ["block","if",[["get","sample.shoppingListInBooking",["loc",[null,[65,34],[65,62]]]]],[],1,null,["loc",[null,[65,28],[71,35]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child4 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 75,
                          "column": 26
                        },
                        "end": {
                          "line": 78,
                          "column": 26
                        }
                      },
                      "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("input");
                      dom.setAttribute(el1,"type","checkbox");
                      dom.setAttribute(el1,"checked","");
                      dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
                      dom.setAttribute(el1,"aria-label","");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [1]);
                      if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element1, 'id');
                      morphs[1] = dom.createElementMorph(element1);
                      return morphs;
                    },
                    statements: [
                      ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[76,41],[76,55]]]]]]],
                      ["element","action",["checkSample",["get","sample",["loc",[null,[76,98],[76,104]]]]],["on","click"],["loc",[null,[76,75],[76,118]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child5 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.7",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 78,
                          "column": 26
                        },
                        "end": {
                          "line": 81,
                          "column": 26
                        }
                      },
                      "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("input");
                      dom.setAttribute(el1,"type","checkbox");
                      dom.setAttribute(el1,"class","bal-checkbox-input blankCheckbox");
                      dom.setAttribute(el1,"aria-label","");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element0, 'id');
                      morphs[1] = dom.createElementMorph(element0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","id",["concat",[["get","sample.checkId",["loc",[null,[79,41],[79,55]]]]]]],
                      ["element","action",["checkSample",["get","sample",["loc",[null,[79,98],[79,104]]]]],["on","click"],["loc",[null,[79,75],[79,118]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.7",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 32,
                        "column": 42
                      },
                      "end": {
                        "line": 85,
                        "column": 20
                      }
                    },
                    "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("tr");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_sample");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_count");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3,"class","gray-light-span");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("/");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_colour");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("ul");
                    dom.setAttribute(el3,"class","list-block");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("li");
                    var el5 = dom.createElement("img");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_material");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3,"class","show");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3,"class","show");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3,"class","show");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_style");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_status");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2,"class","table_col_check");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("label");
                    dom.setAttribute(el3,"class","bal-checkbox-label");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1]);
                    var element6 = dom.childAt(element5, [3, 1]);
                    var element7 = dom.childAt(element5, [5, 1]);
                    var element8 = dom.childAt(element7, [1, 0]);
                    var element9 = dom.childAt(element5, [7]);
                    var element10 = dom.childAt(element5, [13]);
                    var element11 = dom.childAt(element10, [3]);
                    var morphs = new Array(14);
                    morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
                    morphs[1] = dom.createMorphAt(element6,0,0);
                    morphs[2] = dom.createMorphAt(element6,2,2);
                    morphs[3] = dom.createAttrMorph(element8, 'src');
                    morphs[4] = dom.createMorphAt(element7,3,3);
                    morphs[5] = dom.createMorphAt(element7,4,4);
                    morphs[6] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                    morphs[7] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
                    morphs[8] = dom.createMorphAt(dom.childAt(element9, [5]),0,0);
                    morphs[9] = dom.createMorphAt(dom.childAt(element5, [9, 1]),0,0);
                    morphs[10] = dom.createMorphAt(dom.childAt(element5, [11]),1,1);
                    morphs[11] = dom.createAttrMorph(element10, 'id');
                    morphs[12] = dom.createMorphAt(element10,1,1);
                    morphs[13] = dom.createAttrMorph(element11, 'for');
                    return morphs;
                  },
                  statements: [
                    ["content","sample.itemLabel",["loc",[null,[35,32],[35,52]]]],
                    ["inline","plus-one",[["get","sampleIndex",["loc",[null,[38,67],[38,78]]]]],[],["loc",[null,[38,56],[38,80]]]],
                    ["content","sampleGroup.all.length",["loc",[null,[38,81],[38,107]]]],
                    ["attribute","src",["concat",[["get","sample.colour1URL",["loc",[null,[42,44],[42,61]]]]]]],
                    ["block","if",[["get","sample.colour2URL",["loc",[null,[43,34],[43,51]]]]],[],0,null,["loc",[null,[43,28],[45,35]]]],
                    ["block","if",[["get","sample.colour3URL",["loc",[null,[46,34],[46,51]]]]],[],1,null,["loc",[null,[46,28],[48,35]]]],
                    ["content","sample.fabric1Label",["loc",[null,[52,45],[52,68]]]],
                    ["content","sample.fabric2Label",["loc",[null,[53,45],[53,68]]]],
                    ["content","sample.fabric3Label",["loc",[null,[54,45],[54,68]]]],
                    ["content","sample.styleNumber",["loc",[null,[57,32],[57,54]]]],
                    ["block","if",[["subexpr","is-string-null-or-empty",[["get","sample.shoppingListStatus",["loc",[null,[60,57],[60,82]]]]],[],["loc",[null,[60,32],[60,83]]]]],[],2,3,["loc",[null,[60,26],[72,33]]]],
                    ["attribute","id",["concat",[["get","sample.barcode",["loc",[null,[74,34],[74,48]]]]]]],
                    ["block","if",[["get","sample.addToSelectedSamples",["loc",[null,[75,32],[75,59]]]]],[],4,5,["loc",[null,[75,26],[81,33]]]],
                    ["attribute","for",["concat",[["get","sample.checkId",["loc",[null,[82,40],[82,54]]]]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4, child5]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.7",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 32,
                      "column": 20
                    },
                    "end": {
                      "line": 85,
                      "column": 27
                    }
                  },
                  "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","sample.isAvailableForLoan",["loc",[null,[32,48],[32,73]]]]],[],0,null,["loc",[null,[32,42],[85,27]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 18
                  },
                  "end": {
                    "line": 86,
                    "column": 18
                  }
                },
                "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["block","if",[["get","sample.visible",["loc",[null,[32,26],[32,40]]]]],[],0,null,["loc",[null,[32,20],[85,34]]]]
              ],
              locals: ["sample","sampleIndex"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 16
                },
                "end": {
                  "line": 87,
                  "column": 16
                }
              },
              "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","sampleGroup.all",["loc",[null,[31,26],[31,41]]]]],[],0,null,["loc",[null,[31,18],[86,27]]]]
            ],
            locals: ["sampleGroup"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 92,
                "column": 8
              }
            },
            "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","look-table-wrapper col-xs-12");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table-column no-padding");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3,"class","table bal-table table-hover table-center table-height tr_min_height");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("thead");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_sample");
            var el7 = dom.createTextNode("Sample");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_count");
            var el7 = dom.createTextNode("Count");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_colour");
            var el7 = dom.createTextNode("Colours");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_material");
            var el7 = dom.createTextNode("Materials");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_style");
            var el7 = dom.createTextNode("Style number");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_status");
            var el7 = dom.createTextNode("Status");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6,"class","table_col_check");
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("                    ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("label");
            dom.setAttribute(el7,"class","bal-checkbox-label");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tbody");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var element15 = dom.childAt(element14, [3, 1]);
            var element16 = dom.childAt(element15, [1, 1, 13]);
            var element17 = dom.childAt(element16, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element14,1,1);
            morphs[1] = dom.createMorphAt(element16,1,1);
            morphs[2] = dom.createAttrMorph(element17, 'for');
            morphs[3] = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","partial",["components/looks-gallery"],[],["loc",[null,[8,12],[8,50]]]],
            ["block","if",[["get","look.allSamplesAreGoingToBeAdded",["loc",[null,[20,26],[20,58]]]]],[],0,1,["loc",[null,[20,20],[24,27]]]],
            ["attribute","for",["concat",["add-samples-look-id-",["get","look.id",["loc",[null,[25,54],[25,61]]]]]]],
            ["block","each",[["get","look.groupedSamples",["loc",[null,[30,24],[30,43]]]]],[],2,null,["loc",[null,[30,16],[87,25]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 93,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","look.hasSamplesVisiblesAndAvailablesForLoan",["loc",[null,[6,14],[6,57]]]]],[],0,null,["loc",[null,[6,8],[92,15]]]]
        ],
        locals: ["look"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 97,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/components/add-samples-loan-form-look.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","look-card-wrapper clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","look_wrapper clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element18 = dom.childAt(fragment, [0, 1, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element18,1,1);
        morphs[1] = dom.createMorphAt(element18,3,3);
        return morphs;
      },
      statements: [
        ["inline","partial",["global/album-header"],[],["loc",[null,[4,6],[4,39]]]],
        ["block","each",[["get","album.visibleLooks",["loc",[null,[5,14],[5,32]]]]],[],0,null,["loc",[null,[5,6],[93,15]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});