define('bookalook/transforms/static-id', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        if (serialized.id) {
          serialized = serialized.id;
        }
        return serialized;
      }
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

});