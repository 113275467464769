define('bookalook/routes/bookings/new-booking', ['exports', 'ember', 'bookalook/utils', 'bookalook/filters_newbooking'], function (exports, Ember, utils, booking_filters) {

  'use strict';

  exports.onOpenNewBookingModal = onOpenNewBookingModal;
  exports.filterByWord = filterByWord;
  exports.resetController = resetController;
  exports.onUpdateBookingDates = onUpdateBookingDates;
  exports.setContactsComputed = setContactsComputed;

  function onOpenNewBookingModal(route) {

    var controller = route.controller;
    //Enable observer for getStatuses
    controller.set('noRefreshStatuses', false);
    controller.set('searchInput', '');
    controller.set('bookingToAdd', route.store.createRecord('booking', { active: true }));
    controller.set('allShowrooms', []);

    controller.set('collections', []);
    controller.set('pagination', { actualPage: 0, lastPage: 0 });

    if (Ember['default'].agency.get('showrooms')) {
      var showrooms = Ember['default'].agency.get('showrooms').toArray();
      var booking = controller.get('bookingToAdd');
      booking.setProperties({
        returnId: showrooms[0].get('id'),
        returnStreet1: showrooms[0].get('street'),
        returnStreet2: showrooms[0].get('street2'),
        returnStreet3: showrooms[0].get('street3'),
        returnCountry: showrooms[0].get('country'),
        returnCity: showrooms[0].get('city'),
        returnZipCode: showrooms[0].get('zipCode'),
        returnTelephone: showrooms[0].get('telephone'),
        returnTelephone2: showrooms[0].get('telephone2')
      });
      controller.set('allShowrooms', showrooms);
    }

    //cargar las brands
    utils['default'].getBrands().then(function (response) {
      controller.set('brands', response.brand);
    });

    /* inicializar filtros */

    booking_filters['default'].setFilterComputeds(route, '#newBookingScrollTable', '#newBookingScrollGallery');
    booking_filters['default'].setScrollPaginationListener(route);
    controller.set('undoBrandFilter', null);
    booking_filters['default'].resetFilters(route);
    /* --- */
    resetController(route);
    if (Ember['default'].isNone(controller.get('allContactsList')) || Ember['default'].isEmpty(controller.get('allContactsList'))) {
      var lists = route.store.findAll('contacts-list');
      lists.then(function (newLists) {
        controller.set('lists', newLists);
        controller.get('lists').forEach(function (list) {
          return list.get('contacts').forEach(function (contact) {
            return controller.get('allContacts').addObject(contact);
          });
        });
      });
    } else {
      controller.get('lists').forEach(function (list) {
        return list.get('contacts').forEach(function (contact) {
          return controller.get('allContacts').addObject(contact);
        });
      });
    }
  }

  function filterByWord(route) {
    console.log('filter by word');
    var controller = route.controller;
    var searchInput = controller.get('searchInput').toLowerCase();
    // si se pone un barcode en el searchInput se busca ese
    var regexBarcode = /^\d{4}[\-\']\d{6}$/gm;

    if (searchInput !== '' && regexBarcode.test(searchInput)) {
      console.log('serach by barcode');
      console.log('searchInput', searchInput);
      searchInput = searchInput.replace("'", "-"); //change ' to -
      utils['default'].getCollectionsBookinPaginated(controller, 1, { search: searchInput }).then(function (response) {
        var samples = utils['default'].getSamples('paginated');
        samples.forEach(function (element) {
          console.log(element.get('barcode'));
          if (element.get('barcode') == searchInput) {
            element.set('checked', true);
          }
        });
      });

      $('#new-booking-searching-filter').val('');
      controller.send('openPositiveTopBar');
      controller.set('brandFilter', null);
    } else {
      console.log('search general');
      controller.searchAndFiltering();
    }
  }

  function resetController(route) {

    var controller = route.controller;

    controller.datesNotSelected = Ember['default'].computed('bookingToAdd.checkout', 'bookingToAdd.checkin', function () {
      return !this.get('bookingToAdd.checkout') || !this.get('bookingToAdd.checkin') || this.get('bookingToAdd.checkout') > this.get('bookingToAdd.checkin');
    });

    controller.set('allContacts', []);
    controller.set('addContactSelectedLists', []);
    controller.set('allAvailabilityStatus', []);
    controller.set('statusSelected', '-1');

    controller.set('statusContent', [{ id: 'All statuses' }, { id: 'BOOKED' }, { id: 'OVERLAP' }, { id: 'AVAILABLE' }, { id: 'UNAVAILABLE' }]);

    setSizeFilter(controller);

    controller.get('allAvailabilityStatus').addObject({ 'id': "-1", 'status': 'All' });
    controller.get('allAvailabilityStatus').addObject({ 'id': "0", 'status': 'Available' });
    controller.get('allAvailabilityStatus').addObject({ 'id': "1", 'status': 'Unavailable' });
    controller.get('allAvailabilityStatus').addObject({ 'id': "2", 'status': 'Booked' });

    controller.set('showAvailableSamples', true);
    controller.set('showUnavailableSamples', true);
    controller.set('showBookedSamples', true);

    setContactsComputed(controller, route);
  }

  function onUpdateBookingDates(route) {

    route.resetBookingDates();

    Ember['default'].run.later(function () {
      route.controller.set('isLoading', false);
      Ember['default'].openModal();
      utils['default'].lazyLoad(5000, '#new-booking-2');
      route.controller.notifyPropertyChange('allAlbums');
    }, 1000);
  }

  function setSizeFilter(controller) {

    //Find any sample size and gather all into an array
    var allSizesContent = [];

    utils['default'].getAPIJSON('collections/filter/size').then(function (response) {
      response.filterSize.forEach(function (value) {
        if (value) {
          var sizeArray = { id: value, name: value, safeString: value };
          allSizesContent.push(sizeArray);
        }
      });

      allSizesContent = allSizesContent.sort(function (a, b) {
        return a.safeString.toLowerCase().localeCompare(b.safeString.toLowerCase());
      });

      controller.set('allSizesContent', allSizesContent);
    });
  }

  function setContactsComputed(controller, route) {
    controller.set('mainAddressRequester', Ember['default'].computed('bookingToAdd.requester', function () {
      var requester = controller.get('bookingToAdd.requester');
      if (requester && requester.get('allAddresses')) {

        var addr = requester.get('allAddresses').get('firstObject');

        if (addr) {
          controller.get('bookingToAdd').setProperties({
            callInId: addr.get('id'),
            callInStreet1: addr.get('street1'),
            callInStreet2: addr.get('street2'),
            callInStreet3: addr.get('street3'),
            callInCountry: addr.get('country'),
            callInCity: addr.get('city'),
            callInZipCode: addr.get('zipCode'),
            callInTelephone: addr.get('telephone1'),
            callInAttn: requester.get('fullName')
          });
        } else {
          controller.get('bookingToAdd').setProperties({
            callInId: '',
            callInStreet1: '',
            callInStreet2: '',
            callInStreet3: '',
            callInCountry: '',
            callInCity: '',
            callInZipCode: '',
            callInTelephone: '',
            callInAttn: requester.get('fullName')
          });
          route.send('openTopBar', 'Warning: you have selected a requester without addresses!');
        }

        //Set The Main address for mark it as main in the New Booking Address Form
        controller.set('mainAddressFromRequester', addr);

        controller.get('bookingToAdd').set('company', requester.get('company'));
        controller.get('bookingToAdd').set('returnAttn', Ember['default'].user.get('fullName'));

        controller.set('fromAddressBook', true);
        controller.set('fromAddressBookInverse', false);

        return requester.get('allAddresses').get('firstObject');
      }
    }));
  }

});