define('bookalook/tests/models/job.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/job.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'models/job.js should pass jshint.\nmodels/job.js: line 73, col 69, Missing semicolon.\nmodels/job.js: line 157, col 58, Missing semicolon.\nmodels/job.js: line 158, col 13, Missing semicolon.\nmodels/job.js: line 186, col 54, Missing semicolon.\n\n4 errors');
  });

});