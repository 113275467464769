define('bookalook/tests/acceptance/load-test', ['ember', 'qunit', 'bookalook/tests/helpers/start-app'], function (Ember, qunit, startApp) {

  'use strict';

  /* globals requirejs, require */

  var shouldLoadModule = function shouldLoadModule(moduleName) {
    return moduleName.match(/[-_]feature$/);
  };

  var isFeature = function isFeature(line) {
    return line.split(':')[0].trim() === 'Feature';
  };

  var isScenario = function isScenario(line) {
    return line.split(':')[0].trim() === 'Scenario';
  };

  var buildModule = function buildModule(line) {
    qunit.module(line.split(':')[1].trim(), {
      beforeEach: function beforeEach(assert) {
        this.application = startApp['default']();
        Ember['default'].assertHelper = assert;
      },

      afterEach: function afterEach() {
        Ember['default'].run(this.application, 'destroy');
        Ember['default'].$('.modal').modal('hide');
        Ember['default'].$('.modal-backdrop').remove();
      }
    });
  };

  var runTest = function runTest(scenario, assert) {
    var prevOrder = '';
    scenario.forEach(function (line) {
      line = line.trim();
      if (!line) {
        return;
      }

      var order = line.split(' ')[0];
      var criteria = line.replace(order, '').trim();

      if (order === 'And') {
        order = prevOrder;
      }

      switch (order) {
        case 'Given':
          andThen(function () {
            return _givenHelper(criteria);
          });
          prevOrder = 'Given';
          break;
        case 'When':
          andThen(function () {
            return _whenHelper(criteria);
          });
          prevOrder = 'When';
          break;
        case 'Then':
          andThen(function () {
            return _thenHelper(criteria);
          });
          prevOrder = 'Then';
          break;
        default:
          assert.ok(false, 'bad syntax.');
      }
    });
  };

  var buildTest = function buildTest(scenario) {
    var scenarioLine = scenario.shift();
    qunit.test(scenarioLine.split(':')[1].trim(), function (assert) {
      runTest(scenario, assert);
    });
  };

  var moduleName;
  for (moduleName in requirejs.entries) {
    if (shouldLoadModule(moduleName)) {
      var feature = require(moduleName)['default'];
      feature = feature.split('\n');

      var scenario;
      feature.forEach(function (line) {
        if (isFeature(line)) {
          buildModule(line);
        }

        if (isScenario(line)) {
          if (!Ember['default'].isNone(scenario)) {
            buildTest(scenario);
          }
          scenario = [line];
        } else {
          if (!Ember['default'].isNone(scenario)) {
            scenario.push(line);
          }
        }
      });

      if (!Ember['default'].isNone(scenario)) {
        buildTest(scenario);
        scenario = null;
      }
    }
  }

});