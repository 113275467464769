define('bookalook/routes/register/mp', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/utils', 'bookalook/routes/session/functions'], function (exports, Ember, UserValidatorMixin, utils, sessionFunctions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    authenticator: 'simple-auth-authenticator:oauth2-password-grant',

    actions: {
      register: function register() {
        this.onRegister();
      }

    },

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);
      if (Ember['default'].user) {
        this.transitionTo('index');
      }
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.set('identification', '');
      controller.set('password', '');

      var _t = this;
      var uuid = Ember['default'].getUrlParameter('uuid');
      controller.set('uuid', uuid);
      if (!Ember['default'].isNone(uuid)) {
        utils['default'].getAPIJSON('invitations/uuid/' + uuid).then(function (res) {
          if (!Ember['default'].isNone(res.invitation)) {
            controller.set('brandName', res.invitation);
          }
        });
      }
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-register');
      this.controllerFor('application').set('hasSidebar', false);
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-register');
      this.controllerFor('application').set('hasSidebar', false);
    },

    onRegister: function onRegister() {
      var _this = this;

      var _t = this;
      var controller = _t.controller;
      var email = controller.get('identification');
      var pass = controller.get('password');
      var uuid = controller.get('uuid');

      var error = false;
      if (Ember['default'].isNone(email) || Ember['default'].isBlank(email)) {
        controller.set('emailError', true);
        error = true;
      } else {
        controller.set('emailError', false);
      }
      if (Ember['default'].isNone(pass) || Ember['default'].isBlank(pass)) {
        controller.set('passError', true);
        error = true;
      } else {
        controller.set('passError', false);
      }

      if (error) {} else {
        var requestData;

        (function () {
          var button = Ember['default'].$('#login-button');
          button.button('loading');

          requestData = {
            'email': email,
            'plainPassword': pass,
            'uuid': uuid
          };

          utils['default'].postAPIJSON('users/register-white-list', requestData).then(function (response) {
            sessionFunctions['default'].onAuthenticate(_this);
            //button.button('reset');
          }, function (fail) {
            if (fail.responseJSON.errors.errorCode === "no-white-listed") {
              _t._openNonInvitedUserModal();
              button.button('reset');
            } else if (fail.responseJSON.errors.errorCode === "already-registered") {
              _t._openAlreadyExistModal();
              button.button('reset');
            } else if (fail.responseJSON.errors.errorCode === "register-url-not-valid") {
              _t._openRegisterUrlNotValidModal();
              button.button('reset');
            }
          });
        })();
      }
    },

    _openNonInvitedUserModal: function _openNonInvitedUserModal() {
      Ember['default'].$('#non-invited-user-modal').modal();
      Ember['default'].openModal();
    },

    _openAlreadyExistModal: function _openAlreadyExistModal() {
      Ember['default'].$('#user-already-exist').modal();
      Ember['default'].openModal();
    },

    _openRegisterUrlNotValidModal: function _openRegisterUrlNotValidModal() {
      Ember['default'].$('#register-url-not-valid').modal();
      Ember['default'].openModal();
    }

  });

});