define('bookalook/helpers/if-equal-four-parameters', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var o2 = params[1];
    var o3 = params[2];
    var o4 = params[3];
    var o5 = params[4];

    var result = false;

    if (o1 === o2 || o1 === o3 || o1 === o4 || o1 === o5) {
      result = true;
    }

    return result;
  });

});