define('bookalook/models/loan-form', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {

    type: DS['default'].attr('string'),
    canceled: DS['default'].attr('boolean'),
    sender: DS['default'].attr('string'),
    items: DS['default'].attr('number'),
    checkoutDate: DS['default'].attr('day'),

    booking: DS['default'].belongsTo('booking', { async: true }),

    defaultParcel: DS['default'].attr('number'),
    ddtParcel: DS['default'].attr('number'),

    requester: DS['default'].attr('string'),
    mpdownloaded: DS['default'].attr('boolean'),

    typeName: (function () {
      var type = this.get('type');
      var typeName = "DDT";
      if (type === "defaultParcel") {
        typeName = "BASIC";
      }

      return typeName;
    }).property('type'),

    shortRequester: (function () {
      var requester = this.get('requester') || '';
      var stringLength = 20;

      return requester.substr(0, stringLength) + '...';
    }).property('requester')
  });

});