define('bookalook/helpers/collection-search-string', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    if (!params[0]) {
      return '';
    }
    var collection = params[0];
    if (Ember['default'].isNone(collection)) {
      return "";
    }

    var year = "";
    var season = "";
    var collectionType = "";

    if (collection.get) {
      year = collection.get('year');
      season = collection.get('season');
      collectionType = collection.get('collectionType');
    } else {
      year = collection.year;
      season = collection.season;
      collectionType = collection.collectionType;
    }

    if (typeof year === 'number') {
      year = year.toString();
    }
    var y = year.charAt(2) + year.charAt(3);

    var t = collectionType.charAt(0);
    if (t === 'H') t = "HC";

    var s = null;
    switch (season) {
      case "Spring/Summer":
        s = "S/S";
        break;
      case "Pre-fall":
        s = season;
        break;
      case "Fall/Winter":
        s = "F/W";
        break;
      case "Cruise":
        s = season;
        break;
      case "Capsule":
        s = season;
        break;
      case "Year-round":
        s = season;
        break;
      default:
        s = season;
    }
    return y + " - " + t + " - " + s;
  });

});