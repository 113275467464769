define('bookalook/helpers/are-false', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    //(!params[0] && !params[1]) => true
    //(params[0] || params[1]) => false;
    var p1 = params[0];
    var p2 = params[1];
    return !p1 && !p2;
  });

});