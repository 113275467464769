define('bookalook/templates/settings/modals/showroom-modal-user-edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 10
            },
            "end": {
              "line": 9,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            Add member\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 10
            },
            "end": {
              "line": 11,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            Edit member's details\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 12
            },
            "end": {
              "line": 23,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","validated-input",[],["label","Name","model",["subexpr","@mut",[["get","userToEdit",["loc",[null,[21,51],[21,61]]]]],[],[]],"property","name","class","col-xs-6 hint-left no-padding-left"],["loc",[null,[21,14],[21,122]]]],
          ["inline","validated-input",[],["label","Surname","model",["subexpr","@mut",[["get","userToEdit",["loc",[null,[22,54],[22,64]]]]],[],[]],"property","surname","class","col-xs-6 hint-right no-padding-right"],["loc",[null,[22,14],[22,130]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 12
            },
            "end": {
              "line": 34,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","showroom-member-info clearfix");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","member-image");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","member-edit-info");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createElement("strong");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createElement("strong");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1, 1]);
          var element7 = dom.childAt(element5, [3]);
          var element8 = dom.childAt(element7, [1, 0]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element6, 'src');
          morphs[1] = dom.createMorphAt(element8,0,0);
          morphs[2] = dom.createMorphAt(element8,2,2);
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [4, 0]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element7, [7]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","userToEdit.avatar",["loc",[null,[26,28],[26,45]]]]]]],
          ["content","userToEdit.name",["loc",[null,[29,30],[29,49]]]],
          ["content","userToEdit.surname",["loc",[null,[29,50],[29,72]]]],
          ["content","userJob",["loc",[null,[30,30],[30,41]]]],
          ["content","userToEdit.roleLabel",["loc",[null,[31,22],[31,46]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 12
            },
            "end": {
              "line": 38,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","validated-input",[],["label","Email","model",["subexpr","@mut",[["get","userToEdit",["loc",[null,[37,52],[37,62]]]]],[],[]],"property","email","class","col-xs-6 no-padding-right hint-right"],["loc",[null,[37,14],[37,126]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 12
            },
            "end": {
              "line": 49,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","import-alert");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","color_primary");
          var el3 = dom.createTextNode("\n                Please make sure to have at least one Area of Influence assigned to this user.\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 20
                },
                "end": {
                  "line": 58,
                  "column": 20
                }
              },
              "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element2, 'src');
              morphs[1] = dom.createAttrMorph(element2, 'alt');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",[["get","area.brand.avatar",["loc",[null,[57,39],[57,56]]]]]]],
              ["attribute","alt",["concat",[["get","area.brand.name",["loc",[null,[57,67],[57,82]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 20
                  },
                  "end": {
                    "line": 66,
                    "column": 20
                  }
                },
                "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1,"class","list-inline");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","strong");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","images/edit.png");
                dom.setAttribute(el1,"class","pointer edit-user-pencil");
                dom.setAttribute(el1,"data-toggle","tooltip");
                dom.setAttribute(el1,"data-placement","right");
                dom.setAttribute(el1,"title","Edit areas of influence");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(fragment, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
                morphs[1] = dom.createMorphAt(element0,2,2);
                morphs[2] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [
                ["inline","if",[["subexpr","if-function",[["get","userToEdit",["loc",[null,[63,63],[63,73]]]],"isRestOfTheWorld",["get","area.brand",["loc",[null,[63,93],[63,103]]]]],[],["loc",[null,[63,50],[63,104]]]],"Rest of the World    "],[],["loc",[null,[63,45],[63,130]]]],
                ["content","userArea.countriesString",["loc",[null,[63,137],[63,165]]]],
                ["element","action",["activateUserAreasEdition",["get","index",["loc",[null,[65,85],[65,90]]]],["get","userArea",["loc",[null,[65,91],[65,99]]]],["get","area",["loc",[null,[65,100],[65,104]]]]],[],["loc",[null,[65,49],[65,106]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 20
                },
                "end": {
                  "line": 67,
                  "column": 20
                }
              },
              "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
            },
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","if-function",[["get","userArea",["loc",[null,[61,39],[61,47]]]],"isBrand",["get","area.brand",["loc",[null,[61,58],[61,68]]]]],[],["loc",[null,[61,26],[61,69]]]]],[],0,null,["loc",[null,[61,20],[66,27]]]]
            ],
            locals: ["userArea","index"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 16
              },
              "end": {
                "line": 70,
                "column": 16
              }
            },
            "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element3,1,1);
            morphs[1] = dom.createAttrMorph(element4, 'class');
            morphs[2] = dom.createMorphAt(element4,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.isAgency",["loc",[null,[56,26],[56,40]]]]],[],0,null,["loc",[null,[56,20],[58,27]]]],
            ["attribute","class",["concat",["influence-areas ",["subexpr","unless",[["get","model.isAgency",["loc",[null,[59,57],[59,71]]]],"brand-areas"],[],["loc",[null,[59,48],[59,87]]]]]]],
            ["block","each",[["get","userToEdit.areasInfluence",["loc",[null,[60,28],[60,53]]]]],[],1,null,["loc",[null,[60,20],[67,29]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 14
            },
            "end": {
              "line": 71,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","area.brand",["loc",[null,[54,22],[54,32]]]]],[],0,null,["loc",[null,[54,16],[70,23]]]]
        ],
        locals: ["area","index"],
        templates: [child0]
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 14
            },
            "end": {
              "line": 80,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Select the level of responsabilities this new member will hold on Bookalook.\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 14
            },
            "end": {
              "line": 82,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Level of responsabilities this member hold on Bookalook.\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 12
            },
            "end": {
              "line": 88,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","import-alert clearfix col-xs-12");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","color_primary");
          var el3 = dom.createTextNode("You need to have at least one manager in your showroom.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 10
            },
            "end": {
              "line": 110,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("An email will be sent to the new member requesting an activation of their account.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 122,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/settings/modals/showroom-modal-user-edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" New member modal ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","modal-member");
        dom.setAttribute(el1,"tabindex","-1");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"data-backdrop","static");
        dom.setAttribute(el1,"data-keyboard","false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        dom.setAttribute(el2,"role","document");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","modal-title");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" Member account details\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix member-areas-influence");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Areas of influence");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","clearfix");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        var el8 = dom.createTextNode("Bookalook member account type");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-group validated-select col-xs-6 no-padding-left");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("<div class=\"pull-left has-pophover right\">\n              <i class=\"glyphicon glyphicon-question-sign\"></i>\n              {{partial 'settings/pophovers/member-responsability'}}\n            </div>");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-footer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_cancel");
        dom.setAttribute(el5,"data-dismiss","modal");
        var el6 = dom.createTextNode("\n        Cancel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        dom.setAttribute(el5,"class","btn_primary");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [2, 1, 1]);
        var element10 = dom.childAt(element9, [3, 1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element10, [5]);
        var element14 = dom.childAt(element9, [5]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [3]);
        var morphs = new Array(15);
        morphs[0] = dom.createMorphAt(dom.childAt(element9, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
        morphs[2] = dom.createMorphAt(element11,3,3);
        morphs[3] = dom.createMorphAt(element11,5,5);
        morphs[4] = dom.createMorphAt(element11,7,7);
        morphs[5] = dom.createMorphAt(element12,3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element12, [5]),1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
        morphs[8] = dom.createMorphAt(element13,5,5);
        morphs[9] = dom.createMorphAt(dom.childAt(element13, [7]),1,1);
        morphs[10] = dom.createMorphAt(element10,7,7);
        morphs[11] = dom.createElementMorph(element15);
        morphs[12] = dom.createAttrMorph(element16, 'disabled');
        morphs[13] = dom.createElementMorph(element16);
        morphs[14] = dom.createMorphAt(element16,1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","userToEdit.isNew",["loc",[null,[7,16],[7,32]]]]],[],0,1,["loc",[null,[7,10],[11,17]]]],
        ["inline","if",[["get","userToEdit.isNew",["loc",[null,[18,19],[18,35]]]],"New"],[],["loc",[null,[18,14],[18,43]]]],
        ["block","if",[["get","userToEdit.isNew",["loc",[null,[20,18],[20,34]]]]],[],2,3,["loc",[null,[20,12],[34,19]]]],
        ["inline","validated-input",[],["label","Job Title","model",["subexpr","@mut",[["get","userToEdit",["loc",[null,[35,54],[35,64]]]]],[],[]],"property","jobTitle","class","col-xs-6 no-padding-left hint-left"],["loc",[null,[35,12],[35,129]]]],
        ["block","if",[["get","userToEdit.isNew",["loc",[null,[36,18],[36,34]]]]],[],4,null,["loc",[null,[36,12],[38,19]]]],
        ["block","if",[["get","userToEdit.isNew",["loc",[null,[43,18],[43,34]]]]],[],5,null,["loc",[null,[43,12],[49,19]]]],
        ["block","each",[["get","model.areasAvailables",["loc",[null,[53,22],[53,43]]]]],[],6,null,["loc",[null,[53,14],[71,23]]]],
        ["block","if",[["get","userToEdit.isNew",["loc",[null,[78,20],[78,36]]]]],[],7,8,["loc",[null,[78,14],[82,21]]]],
        ["block","if",[["get","hasOneManager",["loc",[null,[84,18],[84,31]]]]],[],9,null,["loc",[null,[84,12],[88,19]]]],
        ["inline","select-2",[],["value",["subexpr","@mut",[["get","userToEdit.roles",["loc",[null,[91,42],[91,58]]]]],[],[]],"content",["subexpr","@mut",[["get","userToEdit.rolesContent",["loc",[null,[92,42],[92,65]]]]],[],[]],"optionLabelPath","label","optionValuePath","value","searchEnabled",false,"enabled",["subexpr","@mut",[["get","hasManagers",["loc",[null,[96,42],[96,53]]]]],[],[]]],["loc",[null,[90,14],[97,16]]]],
        ["block","if",[["get","userToEdit.isNew",["loc",[null,[108,16],[108,32]]]]],[],10,null,["loc",[null,[108,10],[110,17]]]],
        ["element","action",["rollbackUser",["get","userToEdit",["loc",[null,[114,94],[114,104]]]]],[],["loc",[null,[114,70],[114,106]]]],
        ["attribute","disabled",["subexpr","not",[["get","userToEdit.hasAreasOfInfluence",["loc",[null,[116,65],[116,95]]]]],[],["loc",[null,[116,59],[116,97]]]]],
        ["element","action",["saveUser",["get","userToEdit",["loc",[null,[116,118],[116,128]]]]],[],["loc",[null,[116,98],[116,130]]]],
        ["inline","if",[["get","userToEdit.isNew",["loc",[null,[117,13],[117,29]]]],"Create","Save"],[],["loc",[null,[117,8],[117,47]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
    };
  }()));

});