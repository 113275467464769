define('bookalook/templates/check-out/partials/checkin-aditional-sample-brand-refactor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/check-out/partials/checkin-aditional-sample-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element2, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","sample.colour2URL",["loc",[null,[13,24],[13,41]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 17,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/check-out/partials/checkin-aditional-sample-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","sample.colour3URL",["loc",[null,[16,24],[16,41]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 2
            },
            "end": {
              "line": 25,
              "column": 7
            }
          },
          "moduleName": "bookalook/templates/check-out/partials/checkin-aditional-sample-brand-refactor.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("td");
          dom.setAttribute(el1,"class","table_col_showroom table_col_hide_tablet pointer");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[23,130],[23,136]]]],"#checkin-brand-refactor"],[],["loc",[null,[23,96],[23,164]]]],
          ["inline","my-showroom",[["get","sample.showroom",["loc",[null,[24,24],[24,39]]]],["get","route",["loc",[null,[24,40],[24,45]]]]],[],["loc",[null,[24,10],[24,47]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/check-out/partials/checkin-aditional-sample-brand-refactor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Sample row | Checkout modal ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("tr");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","table_col_sample pointer");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","table_col_count pointer");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","gray-light-span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("/");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","table_col_colour pointer");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","list-block");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createElement("img");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","table_col_barcode pointer");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","table_col_calendar");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"class","image-calendar_uirefactor");
        dom.setAttribute(el4,"src","images/calendar.svg");
        dom.setAttribute(el4,"data-target","#sample-historial");
        dom.setAttribute(el4,"data-toggle","modal");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("td");
        dom.setAttribute(el2,"class","table_col_check");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","bal-checkbox-label");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element3, [5]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [1, 0]);
        var element10 = dom.childAt(element3, [7]);
        var element11 = dom.childAt(element3, [11, 1, 1]);
        var element12 = dom.childAt(element3, [13]);
        var element13 = dom.childAt(element12, [3]);
        var morphs = new Array(15);
        morphs[0] = dom.createElementMorph(element4);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[2] = dom.createElementMorph(element5);
        morphs[3] = dom.createMorphAt(element6,0,0);
        morphs[4] = dom.createMorphAt(element6,2,2);
        morphs[5] = dom.createElementMorph(element7);
        morphs[6] = dom.createAttrMorph(element9, 'src');
        morphs[7] = dom.createMorphAt(element8,3,3);
        morphs[8] = dom.createMorphAt(element8,4,4);
        morphs[9] = dom.createElementMorph(element10);
        morphs[10] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
        morphs[11] = dom.createMorphAt(element3,9,9);
        morphs[12] = dom.createElementMorph(element11);
        morphs[13] = dom.createMorphAt(element12,1,1);
        morphs[14] = dom.createAttrMorph(element13, 'for');
        return morphs;
      },
      statements: [
        ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[3,73],[3,79]]]],"#checkin-brand-refactor"],[],["loc",[null,[3,39],[3,107]]]],
        ["content","sample.itemLabel",["loc",[null,[4,12],[4,32]]]],
        ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[6,72],[6,78]]]],"#checkin-brand-refactor"],[],["loc",[null,[6,38],[6,106]]]],
        ["inline","plus-one",[["get","sampleIndex",["loc",[null,[7,45],[7,56]]]]],[],["loc",[null,[7,34],[7,58]]]],
        ["content","sampleGroup.all.length",["loc",[null,[7,59],[7,85]]]],
        ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[9,73],[9,79]]]],"#checkin-brand-refactor"],[],["loc",[null,[9,39],[9,107]]]],
        ["attribute","src",["concat",[["get","sample.colour1URL",["loc",[null,[11,22],[11,39]]]]]]],
        ["block","if",[["get","sample.colour2URL",["loc",[null,[12,12],[12,29]]]]],[],0,null,["loc",[null,[12,6],[14,13]]]],
        ["block","if",[["get","sample.colour3URL",["loc",[null,[15,12],[15,29]]]]],[],1,null,["loc",[null,[15,6],[17,13]]]],
        ["element","action",["openSampleDetailsModal",["get","sample",["loc",[null,[20,74],[20,80]]]],"#checkin-brand-refactor"],[],["loc",[null,[20,40],[20,108]]]],
        ["content","sample.barcode",["loc",[null,[21,10],[21,28]]]],
        ["block","unless",[["get","disableShowroomFilter",["loc",[null,[23,12],[23,33]]]]],[],2,null,["loc",[null,[23,2],[25,18]]]],
        ["element","action",["openMinicalendarModal",["get","sample",["loc",[null,[28,46],[28,52]]]],"#checkin-brand-refactor"],[],["loc",[null,[28,13],[28,80]]]],
        ["inline","input",[],["id",["subexpr","concat",["checkin-cb-",["get","sample.id",["loc",[null,[33,40],[33,49]]]]],[],["loc",[null,[33,18],[33,50]]]],"type","checkbox","class","bal-checkbox-input blankCheckbox","checked",["subexpr","@mut",[["get","sample.toCheckout",["loc",[null,[36,18],[36,35]]]]],[],[]],"aria-label",""],["loc",[null,[32,4],[38,6]]]],
        ["attribute","for",["concat",["checkin-cb-",["get","sample.id",["loc",[null,[39,29],[39,38]]]]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});