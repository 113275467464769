define('bookalook/tests/acceptance/load-test.jshint', function () {

  'use strict';

  QUnit.module('JSHint - acceptance/load-test.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'acceptance/load-test.js should pass jshint.\nacceptance/load-test.js: line 85, col 21, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (isFeature, buildModule, isScenario, Ember, scenario, buildTest)\n\n1 error');
  });

});