define('bookalook/components/svg-images', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    /** Atributos a pasarle:
     *  svgSrc => svg que se quiere pintar
     */
    svgSrc: '',
    svgsPath: 'svgs/',
    fullPath: Ember['default'].computed('svgSrc', function () {
      return this.get('svgsPath') + this.get('svgSrc');
    })
    // svgsPath: '/images/',
    // fullPath: Ember.computed('svgSrc', function(){
    //   let jQuery = Ember.$;
    //   let _t = this;
    //   let result = _t.get('svgsPath')+_t.get('svgSrc')+'.svg';
    //   return Ember.$.get(_t.get('svgsPath')+_t.get('svgSrc')+'.svg', function(data) {
    //     result = "entra";
    //     // Get the SVG tag, ignore the rest
    //     let svg = Ember.$(data).find('svg');
    //     // Remove any invalid XML tags as per http://validator.w3.org
    //     svg = Ember.$(svg).removeAttr('xmlns:a');
    //
    //     // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
    //     if(!Ember.$(svg).attr('viewBox') && Ember.$(svg).attr('height') && Ember.$(svg).attr('width')) {
    //       Ember.$(svg).attr('viewBox', '0 0 ' + Ember.$(svg).attr('height') + ' ' + Ember.$(svg).attr('width'))
    //     }
    //     console.log(Ember.$(svg));
    //     console.log(Ember.$(svg)[0]);
    //     return Ember.$(svg)[0];
    //
    //   }, 'xml');
    // }),
  });

});