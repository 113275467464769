define('bookalook/models/country', ['exports', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    area: DS['default'].attr('string'),
    continent: DS['default'].attr('string')
  });

});