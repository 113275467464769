define('bookalook/utils', ['exports', 'ember', 'bookalook/config/environment', 'bookalook/static-tables'], function (exports, Ember, config, static_tables) {

	'use strict';

	exports.getConfirmationPromise = getConfirmationPromise;
	exports.getCache = getCache;
	exports.getJobs = getJobs;
	exports.getUserProfile = getUserProfile;
	exports.getCollections = getCollections;
	exports.getCollectionsForInventory = getCollectionsForInventory;
	exports.getCollectionByBooking = getCollectionByBooking;
	exports.getAllCollectionsForModal = getAllCollectionsForModal;
	exports.getBookingCollectionsForModal = getBookingCollectionsForModal;
	exports.getContactLists = getContactLists;
	exports.getContactListsCache = getContactListsCache;
	exports.getContactListsArray = getContactListsArray;
	exports.getBookings = getBookings;
	exports.getBookingsList = getBookingsList;
	exports.searchSamples = searchSamples;
	exports.getSeasons = getSeasons;
	exports.getBrands = getBrands;
	exports.getCollectionsByBooking = getCollectionsByBooking;
	exports.getCollectionsByBrands = getCollectionsByBrands;
	exports.getAlbumsByCollection = getAlbumsByCollection;
	exports.getDDT = getDDT;
	exports.getCollectionsPaginated = getCollectionsPaginated;
	exports.getCollectionsBookinPaginated = getCollectionsBookinPaginated;
	exports.getSamples = getSamples;
	exports.removeCache = removeCache;
	exports.removeCollectionsCache = removeCollectionsCache;
	exports.getItemsFromShowroom = getItemsFromShowroom;
	exports.refreshCollections = refreshCollections;
	exports.refreshInventoryCollections = refreshInventoryCollections;
	exports.sendJSON = sendJSON;
	exports.getJSON = getJSON;
	exports.postJSON = postJSON;
	exports.deleteJSON = deleteJSON;
	exports.putJSON = putJSON;
	exports.getAPIJSON = getAPIJSON;
	exports.postAPIJSON = postAPIJSON;
	exports.deleteAPIJSON = deleteAPIJSON;
	exports.putAPIJSON = putAPIJSON;
	exports.getInstagramImages = getInstagramImages;
	exports.openConfirmationModal = openConfirmationModal;
	exports.openDefaultConfirmationModal = openDefaultConfirmationModal;
	exports.monthName = monthName;
	exports.parseDate = parseDate;
	exports.dateToDayFormat = dateToDayFormat;
	exports.peekBooking = peekBooking;
	exports.formatBookingDates = formatBookingDates;
	exports.getSampleStatusByBookingDates = getSampleStatusByBookingDates;
	exports.getSampleStatusForBrandByBookingDates = getSampleStatusForBrandByBookingDates;
	exports.addDefaultOption = addDefaultOption;
	exports.pushPayload = pushPayload;
	exports.pushArrayPayload = pushArrayPayload;
	exports.createComputedFilter = createComputedFilter;
	exports.addObserver = addObserver;
	exports.buildCountriesAccordion = buildCountriesAccordion;
	exports.isSampleInBooking = isSampleInBooking;
	exports.lazyLoad = lazyLoad;
	exports.buttonLoading = buttonLoading;
	exports.requestMyInvite = requestMyInvite;
	exports.goToInventoryWithFilterStatus = goToInventoryWithFilterStatus;
	exports.limitString = limitString;
	exports.toggleHelp = toggleHelp;

	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	var confirmationPromise;

	function getConfirmationPromise() {
		return confirmationPromise;
	}

	//change: globals variables to use like cache
	var collections;
	var contactLists;
	var bookings;
	var jobs;
	var samples = [];
	var isCacheLoaded = false;
	var userProfile;
	var paginatedSamples = [];

	function getCache(route) {
		if (!isCacheLoaded) {
			static_tables.loadStaticTables(route);
		}
		isCacheLoaded = true;
	}

	function getJobs(route) {
		jobs = route.store.findAll('job');
		return jobs;
	}

	// change: outside is always waiting a promise

	function getUserProfile() {
		var reload = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

		userProfile = JSON.parse(localStorage.getItem('userData'));
		if (!userProfile || reload) {
			return this.getAPIJSON('users/profile');
		} else {
			return Promise.resolve(userProfile);
		}
	}

	function getCollections(route, fromCache, bookingId) {

		if (Ember['default'].user.get('isPR')) {

			if (!fromCache || !collections) {
				if (bookingId) {
					refreshCollections(route, bookingId);
				} else {
					refreshCollections(route);
				}
			}
			return collections;
		}
	}

	function getCollectionsForInventory(route) {
		//always reload collection to show last updates
		refreshInventoryCollections(route);
		return collections;
	}

	function getCollectionByBooking(route, bookingId) {
		samples.clear();
		collections = route.store.query('collection', {
			filters: { bookingid: bookingId },
			inventoryByBooking: true,
			pagination: {
				page: 1,
				size: 'ALL'
			}
		});

		collections.then(function (cols) {
			cols.forEach(function (c) {
				return samples = samples.concat(c.get('samples'));
			});
		});

		return collections;
	}

	function getAllCollectionsForModal(route) {
		samples.clear();
		collections = route.store.query('collection', {
			inventory: true,
			pagination: {
				page: 1,
				size: 'ALL'
			}
		});

		collections.then(function (cols) {
			cols.forEach(function (c) {
				return samples = samples.concat(c.get('samples'));
			});
		});

		return collections;
	}

	function getBookingCollectionsForModal(route, bookingId) {

		samples.clear();

		var promise = getCollectionsByBooking(bookingId).then(function (response) {
			var storedCollections = route.store.peekAll('collection');
			storedCollections.forEach(function (c) {
				response.collection = response.collection.filter(function (x) {
					return x.id != c.get('id');
				});
			});
			pushArrayPayload(route.store, 'collection', response.collection);
			collections = route.store.peekAll('collection');
			collections.forEach(function (c) {
				return samples = samples.concat(c.get('samples'));
			});
			return collections;
		});

		return promise;
	}

	function getContactLists(route) {
		contactLists = route.store.findAll('contacts-list');
		return contactLists;
	}

	function getContactListsCache(route) {
		if (!contactLists) {
			contactLists = route.store.findAll('contacts-list');
		}

		return contactLists;
	}

	function getContactListsArray(route) {
		return route.store.query('contacts-list', { arrayContacts: true });
	}

	function getBookings(route) {
		bookings = route.store.findAll('booking');

		return bookings;
	}

	function getBookingsList() {
		return getAPIJSON('bookingsList');
	}

	function searchSamples(term) {
		return getAPIJSON('samples?filter=true&filters[archived]=0&filters[published]=1&filters[name]=' + term);
	}

	function getSeasons() {
		return getAPIJSON('collections?seasons=true');
	}

	function getBrands() {
		return getAPIJSON('brands?filter=true&filters[archived]=0&filters[published]=1');
	}

	function getCollectionsByBooking(bookingId) {
		return getAPIJSON('collections?booking=' + bookingId);
	}

	function getCollectionsByBrands(brandsId) {
		return getAPIJSON('collections?filter=true&filters[brand]=' + brandsId + '&expanded=true&filters[archived]=0&filters[published]=1');
	}

	function getAlbumsByCollection(collectionId) {
		return getAPIJSON('albums?filter=true&filters[archived]=0&filters[published]=1&filters[collection]=' + collectionId);
	}

	function getDDT() {
		var to = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];
		var ddtnumber = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

		var url = 'ddtSamples/getFromLoggedUser?order[date]=desc';
		var filters = [];
		if (to) {
			filters.push('filters[to]=' + to);
		}
		if (ddtnumber) {
			filters.push('filters[number]=' + ddtnumber);
		}
		if (filters.length > 0) {
			url += '&' + filters.join('&');
		}
		return getAPIJSON(url);
	}

	function getCollectionsPaginated(route, page) {
		var filters = arguments.length <= 2 || arguments[2] === undefined ? '' : arguments[2];

		var collections = route.store.query('collection', { inventory: true, pagination: { page: page, size: 1 }, filters: _extends({}, filters) });
		//aqui carga los samples para pedir en inventory
		// si no los carga luego no muestra los datos
		if (page == 1) {
			paginatedSamples.clear();
		}
		collections.then(function (cols) {
			cols.forEach(function (c) {
				return paginatedSamples = paginatedSamples.concat(c.get('samples'));
			});
		});
		return collections;
	}

	function getCollectionsBookinPaginated(route, page) {
		var filters = arguments.length <= 2 || arguments[2] === undefined ? '' : arguments[2];

		var collections = route.store.query('collection', {
			bookingList: true, pagination: { page: page, size: 1 }, filters: _extends({}, filters) });

		if (page == 1) {
			paginatedSamples.clear();
		}
		collections.then(function (cols) {
			cols.forEach(function (c) {
				return paginatedSamples = paginatedSamples.concat(c.get('samples'));
			});
		});
		return collections;
	}

	function getSamples() {
		var type = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

		if (type == 'paginated') {
			return paginatedSamples;
		} else {
			return samples;
		}
	}

	function removeCache() {
		collections = null;
		samples = [];
		contactLists = null;
		bookings = null;
		userProfile = null;
		paginatedSamples = [];
	}

	function removeCollectionsCache() {
		collections = null;
	}

	function getItemsFromShowroom(route) {
		var _t = route;
		var controller = _t.controller;
		utils.getAPIJSON('endpoint?showroom=true').then(function (items) {
			controller.set('allItemsContent', items);
			console.log(items);
		});
	}

	function refreshCollections(route, bookingId) {
		samples.clear();

		if (bookingId) {
			collections = route.store.query('collection', { booking: bookingId });
		} else {
			collections = route.store.findAll('collection');
		}

		collections.then(function (cols) {
			cols.forEach(function (c) {
				return samples = samples.concat(c.get('samples'));
			});
		});
	}

	function refreshInventoryCollections(route) {
		samples.clear();
		collections = route.store.query('collection', { inventory: true });

		collections.then(function (cols) {
			cols.forEach(function (c) {
				return samples = samples.concat(c.get('samples'));
			});
		});
	}

	function sendJSON(url, method, data) {

		var promise = new Ember['default'].RSVP.Promise(function (resolve, reject) {
			Ember['default'].$.ajax({
				url: url,
				type: method,
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json',

				success: resolve,
				error: function error(res) {
					reject(res);

					/*if(res.responseJSON.errors && res.responseJSON.errors.showMessage) {
	    	alert(res.responseJSON.errors.showMessage);
	    }*/
				}
			});
		});
		return promise;
	}

	function getJSON(url, data) {
		return sendJSON(url, 'GET', data);
	}

	function postJSON(url, data) {
		return sendJSON(url, 'POST', data);
	}

	function deleteJSON(url, data) {
		return sendJSON(url, 'DELETE', data);
	}

	function putJSON(url, data) {
		return sendJSON(url, 'PUT', data);
	}

	function getAPIJSON(url, data) {
		return getJSON(config['default'].APIURL + url, data);
	}

	function postAPIJSON(url, data) {
		return postJSON(config['default'].APIURL + url, data);
	}

	function deleteAPIJSON(url, data) {
		return deleteJSON(config['default'].APIURL + url, data);
	}

	function putAPIJSON(url, data) {
		return putJSON(config['default'].APIURL + url, data);
	}

	function getInstagramImages() {
		// return Ember.$.ajax({url: config.APIHost + '/uploads/documents/images/instagram.json', disableLog: true});
		// this is just a patch for CORS, following is the response from above ajax call
		return { "images": ["https:\/\/scontent.cdninstagram.com\/t51.2885-15\/s640x640\/sh0.08\/e35\/13183259_647871022032106_977764546_n.jpg?ig_cache_key=MTI1MTQzODQyODM2MTM3ODc2NA%3D%3D.2", "https:\/\/scontent.cdninstagram.com\/t51.2885-15\/s640x640\/sh0.08\/e35\/13167302_1552132421749674_1226816616_n.jpg?ig_cache_key=MTI0Njk1ODgwMTMwMzY4NTMzMg%3D%3D.2.l", "https:\/\/scontent.cdninstagram.com\/t51.2885-15\/e35\/13129280_1759197514321923_1079098200_n.jpg?ig_cache_key=MTI0MTMyMDY3MDkwNjg2MjEzMg%3D%3D.2", "https:\/\/scontent.cdninstagram.com\/t51.2885-15\/s640x640\/sh0.08\/e35\/13129852_952490738205171_1608976718_n.jpg?ig_cache_key=MTIzOTgwMjg3NjQ5MDk5MTg3MA%3D%3D.2", "https:\/\/scontent.cdninstagram.com\/t51.2885-15\/s640x640\/sh0.08\/e35\/13116828_475922562604246_632631776_n.jpg?ig_cache_key=MTIzOTMyNzE1NjAzOTg5NTc1NQ%3D%3D.2", "https:\/\/scontent.cdninstagram.com\/t51.2885-15\/s640x640\/sh0.08\/e35\/12976115_1297149536966748_1284817996_n.jpg?ig_cache_key=MTIzNzYwMTg2OTM3MTU5MTI3Ng%3D%3D.2"] };
	}

	function openConfirmationModal(route, msg) {
		var confirm = arguments.length <= 2 || arguments[2] === undefined ? 'confirm' : arguments[2];
		var cancel = arguments.length <= 3 || arguments[3] === undefined ? 'cancel' : arguments[3];

		route.controllerFor('application').set('message', msg);
		route.controllerFor('application').set('confirm', confirm);
		route.controllerFor('application').set('cancel', cancel);
		Ember['default'].$('#app-confirmation').modal();
		confirmationPromise = Ember['default'].$.Deferred();
		return confirmationPromise;
	}

	function openDefaultConfirmationModal(route, title, subtitle, text) {
		var confirm = arguments.length <= 4 || arguments[4] === undefined ? 'Confirm' : arguments[4];
		var cancel = arguments.length <= 5 || arguments[5] === undefined ? 'Cancel' : arguments[5];
		var modal = arguments.length <= 6 || arguments[6] === undefined ? false : arguments[6];

		route.controllerFor('application').set('titleDefault', title);
		route.controllerFor('application').set('subtitleDefault', subtitle);
		route.controllerFor('application').set('textDefault', text);
		route.controllerFor('application').set('confirm', confirm);
		route.controllerFor('application').set('cancel', cancel);
		Ember['default'].$('#' + modal).modal('hide');
		Ember['default'].$('#default-confirmation').modal();
		Ember['default'].openModal();
		confirmationPromise = Ember['default'].$.Deferred();
		return confirmationPromise;
	}

	var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	function monthName(monthNumber) {
		return months[monthNumber];
	}

	function parseDate(stringDate) {
		if (stringDate == null) {
			return null;
		}
		if (Ember['default'].typeOf(stringDate) !== 'string') {
			stringDate.setHours(0, 0, 0, 0);
			return stringDate;
		}
		// example 2015-11-24T23:00:00+0000
		var year = stringDate.substring(0, 4);
		var month = stringDate.substring(5, 7);
		var day = stringDate.substring(8, 10);
		var hour = stringDate.substring(11, 13);
		var date = new Date(year, month - 1, day, hour);
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
		date.setHours(0, 0, 0, 0);
		return date;
	}

	function dateToDayFormat(date) {
		if (date == null) {
			return null;
		}
		var day = ('0' + date.getDate()).slice(-2);
		var month = ('0' + (date.getMonth() + 1)).slice(-2);
		var dateString = [date.getFullYear(), month, day].join('-');
		return dateString;
	}

	function peekBooking(route, booking) {
		var peekedBooking = route.store.peekRecord('booking', booking.id);
		if (!peekedBooking) {
			//formatBookingDates(booking);
			peekedBooking = pushPayload(route.store, 'booking', booking);
		}
		return peekedBooking;
	}

	function formatBookingDates(booking) {
		booking.checkout = parseDate(booking.checkout);
		booking.checkin = parseDate(booking.checkin);
		booking.dateCreated = parseDate(booking.dateCreated);
	}

	function getSampleStatusByBookingDates(route, samples, checkout, checkin, excludedBookingId) {
		if (samples.get('length') == 0) {
			return Promise.resolve();
		}
		var promise = postAPIJSON('statuses/addSamples', {
			samples: samples.mapBy('id'),
			checkout: dateToDayFormat(checkout),
			checkin: dateToDayFormat(checkin),
			excludedBookingId: excludedBookingId
		});

		promise.then(function (res) {
			var statuses = res.data;
			var statusV = '';
			var statusLabel = '';
			statuses.forEach(function (status) {
				var sample = route.store.peekRecord('sample', status.sample);
				if (sample) {
					statusV = status.status;
					statusLabel = statusV.charAt(0).toUpperCase() + statusV.slice(1);
					sample.set('addSampleStatus', statusV);
					sample.set('addSampleStatusLabel', statusLabel);
					sample.set('disabled', status.status === 'in transfer');
				}
			});
		});

		return promise;
	}

	function getSampleStatusForBrandByBookingDates(route, samples, brandId, checkout, checkin, excludedBookingId) {
		if (samples.get('length') == 0) {
			return Promise.resolve();
		}
		var promise = postAPIJSON('statuses/addSamplesByBrand', {
			brand: brandId,
			checkout: dateToDayFormat(checkout),
			checkin: dateToDayFormat(checkin),
			excludedBookingId: excludedBookingId
		});

		promise.then(function (res) {
			var statuses = res.data;
			var statusV = '';
			var statusLabel = '';
			statuses.forEach(function (status) {
				var sample = route.store.peekRecord('sample', status.sample);
				if (sample) {
					statusV = status.status;
					statusLabel = statusV.charAt(0).toUpperCase() + statusV.slice(1);
					sample.set('addSampleStatus', statusV);
					sample.set('addSampleStatusLabel', statusLabel);
					sample.set('disabled', status.status === 'in transfer');
				}
			});
		});

		return promise;
	}

	function addDefaultOption(list, label) {
		var key = arguments.length <= 2 || arguments[2] === undefined ? 'name' : arguments[2];
		var id = arguments.length <= 3 || arguments[3] === undefined ? '0' : arguments[3];

		var copy = [].addObjects(list);
		var defaultValue = { id: id };
		defaultValue[key] = label;
		copy.unshift(defaultValue);
		return copy;
	}

	function pushPayload(store, modelName, payload) {
		var payloadData = {};
		payloadData[modelName] = [payload];
		store.pushPayload(modelName, payloadData);

		var record = store.peekRecord(modelName, payload.id);
		return record;
	}

	function pushArrayPayload(store, modelName, payload) {
		var payloadData = {};
		payloadData[modelName] = payload;
		store.pushPayload(modelName, payloadData);

		return payload.map(function (object) {
			return store.peekRecord(modelName, object.id);
		});
	}

	function createComputedFilter(keyToObserve, arrayToFilter, keyToFilter, partial) {
		var defaultValue = arguments.length <= 4 || arguments[4] === undefined ? '0' : arguments[4];

		return Ember['default'].computed(keyToObserve, arrayToFilter, function () {
			var array = this.get(arrayToFilter);
			var filterValue = this.get(keyToObserve);
			var arrayFiltered = array;

			if (filterValue !== '') {
				if (partial) {
					var lowerKeyFilter = filterValue.toLowerCase();
					arrayFiltered = array.filter(function (v) {
						return v.get(keyToFilter).toLowerCase().indexOf(lowerKeyFilter) > -1;
					});
				} else if (filterValue !== defaultValue) {
					arrayFiltered = array.filter(function (v) {
						return v.get(keyToFilter) === filterValue;
					});
				}
			}
			return arrayFiltered;
		});
	}

	function addObserver(controller, keys, observer) {
		keys.forEach(function (key) {
			controller.removeObserver(key, controller, observer);
			controller.addObserver(key, controller, observer);
		});
	}

	function buildCountriesAccordion(countries) {
		var areasAvailable = [];
		countries.forEach(function (c) {

			var continent = areasAvailable.filter(function (a) {
				return a.name === c.get('continent');
			})[0];
			if (!continent) {
				continent = { name: c.get('continent'), nameId: c.get('continent').replace(/\(|\)| /g, ''), areas: [] };
				areasAvailable.push(continent);
			}

			var area = continent.areas.filter(function (a) {
				return a.name === c.get('area');
			})[0];
			if (!area) {
				area = { name: c.get('area'), nameId: '_' + c.get('area').replace(/\(|\)| /g, ''), countries: [] };
				continent.areas.push(area);
			}

			area.countries.push(c);
		});
		return areasAvailable;
	}

	function isSampleInBooking(booking, sample) {
		var samples = booking.get('samples');
		return Boolean(samples.findBy('id', sample.get('id')));
	}

	function lazyLoad(timeout, container) {
		Ember['default'].run.later(function () {
			var images;
			var options = { effect: 'fadeIn' };

			if (container) {
				if (container === window) {
					container = 'body';
				}
				options.container = Ember['default'].$(container);
				images = Ember['default'].$(container + ' .lazy-image');
			} else {
				images = Ember['default'].$('.lazy-image');
			}

			images.off().lazyload(options);
		}, timeout);
	}

	function buttonLoading(buttonRef, promise) {
		var $ = Ember['default'].$;
		var button = $(buttonRef);
		button.button('loading');
		promise.then(function () {
			return button.button('reset');
		}, function () {
			return button.button('reset');
		});
	}

	function requestMyInvite(route) {
		var isIndex = route.container.lookup('router:main').get('currentRouteName') === 'index';
		if (isIndex) {
			Ember['default'].$('body, html').animate({
				scrollTop: Ember['default'].$('body, html').offset().top
			}, 500);
			Ember['default'].$('#request-invite-email input').get(0).focus();
			location.hash = 'request-my-invite';
		} else {
			route.get('router').transitionTo('index').then(function () {
				Ember['default'].run.later(function () {
					return Ember['default'].$('#request-invite-email input').get(0).focus();
				}, 200);
				location.hash = 'request-my-invite';
			});
		}
	}

	function goToInventoryWithFilterStatus(route, filter, extra) {

		if (!route.routeName) {
			route = route.route;
		}

		route.transitionTo('inventory').then(function () {
			var timeout = 1000;
			var inventoryController = route.controllerFor('inventory.index');
			if (extra) {
				extra();
			}

			var settingFilters = Ember['default'].run.later(function () {
				if (inventoryController.get('getDataPromise')) {
					inventoryController.get('getDataPromise').then(function () {
						inventoryController.set('statusFilter', filter.toUpperCase());
						inventoryController.notifyPropertyChange('statusFilter');
					});
				} else {
					settingFilters();
				}
			}, timeout);
		});
	}

	function limitString(string) {
		var limit = arguments.length <= 1 || arguments[1] === undefined ? 10 : arguments[1];

		var points = string.length > limit ? '...' : '';
		return string.substring(0, limit) + points;
	}

	function toggleHelp(section) {
		if (Ember['default'].$('.' + section + '-help').first().is(":visible")) {
			Ember['default'].$('.' + section + '-help').hide(400, function () {
				if (Ember['default'].$('.' + section + '-no-help').length != 0) {
					Ember['default'].$('.' + section + '-no-help').show(400);
				}
			});
		} else {
			if (Ember['default'].$('.' + section + '-no-help').length != 0) {
				Ember['default'].$('.' + section + '-no-help').hide(400, function () {
					Ember['default'].$('.' + section + '-help').show(400);
				});
			} else {
				Ember['default'].$('.' + section + '-help').show(400);
			}
		}
	}

});