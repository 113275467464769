define('bookalook/routes/jobs/view', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/routes/jobs/functions', 'bookalook/utils', 'bookalook/config/environment'], function (exports, Ember, UserValidatorMixin, functions, utils, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    actions: {
      cancelJob: function cancelJob(job) {
        functions['default'].cancelJob(this, job);
      },
      deleteJob: function deleteJob(job) {
        functions['default'].deleteJob(this, job, 'delete');
      },
      openLoanForms: function openLoanForms(jobId) {
        functions['default'].onOpenLoanForms(jobId, this.controller);
      },
      closeJob: function closeJob(job) {
        this.onCloseJob(job);
      },
      downloadLoanForm: function downloadLoanForm(loanFormId, bookingId) {
        this.onDownloadLoanForm(loanFormId, bookingId);
      },
      downloadLoanFormDDT: function downloadLoanFormDDT(bookingId, ddtParcelId) {
        this.onDownloadLoanFormDDT(bookingId, ddtParcelId);
      },
      openEditDates: function openEditDates() {
        this.onOpenEditDates();
      },

      //SHOPPINGLIST
      openPrDetailsModal: function openPrDetailsModal(prUser) {
        this.controller.set('prUser', prUser);
      },
      requestSample: function requestSample(sample) {
        this.onRequestSample(sample);
      },
      cancelSample: function cancelSample(sample) {
        this.onCancelSample(sample);
      },
      removeSample: function removeSample(sample) {
        this.onRemoveSample(sample);
      },
      requestAllSamples: function requestAllSamples() {
        this.onRequestAllSamples();
      },
      openTrackParcel: function openTrackParcel(jobStatus) {
        this.onOpenTrackParcel(jobStatus);
      },
      willTransition: function willTransition() {
        this.controller.get('looksArraySamples').setEach('visible', true);
      },
      //PROPOSAL
      acceptSampleProposal: function acceptSampleProposal(sample) {
        this.onAcceptSampleProposal(sample);
      },
      collapseAreaAndScroll: function collapseAreaAndScroll(textSelector, textId, scrSel, scrId) {
        this.onCollapseAreaAndScroll(textSelector, textId, scrSel, scrId);
      },
      declineSampleProposal: function declineSampleProposal(sample) {
        this.onDeclineSampleProposal(sample);
      },
      changeRequestNotifications: function changeRequestNotifications() {
        this.onChangeRequestNotifications();
      },

      //FORM-EDIT JS
      removeFile: function removeFile(file) {
        this.onRemoveFile(file);
      },
      addFile: function addFile() {
        this.onAddFile();
      },
      goToLoanFormPage: function goToLoanFormPage(type, bookingId, parcelId) {
        this.onGoToLoanFormPage(type, bookingId, parcelId);
      },
      saveJobDetails: function saveJobDetails() {
        this.onSaveJobDetails();
      },
      saveJobFromAPI: function saveJobFromAPI() {
        this.onSaveJobFromAPI();
      },
      cancelEditJob: function cancelEditJob() {
        this.onCancelEditJob();
      },
      setMainAddress: function setMainAddress(address) {
        this.onSetMainAddress(address);
      },
      addAnotherAddress: function addAnotherAddress() {
        this.onAddAnotherAddress();
      },
      removeAddress: function removeAddress(address) {
        this.onRemoveAddress(address);
      },
      toggleSetAsMainAddress: function toggleSetAsMainAddress(address) {
        this.onToggleSetAsMainAddress(address);
      },
      openModalJobOverviewSample: function openModalJobOverviewSample(sample) {
        this.onOpenModalJobOverviewSample(sample);
      },
      setJobLookToView: function setJobLookToView(look) {
        this.onSetJobLookToView(look);
      },
      setCurrentJobAvatarToView: function setCurrentJobAvatarToView(avatar, position) {
        this.onSetCurrentJobAvatarToView(avatar, position);
      },
      slideCurrentJobAvatarToView: function slideCurrentJobAvatarToView(currentPosition, direction) {
        this.onSlideCurrentJobAvatarToView(currentPosition, direction);
      },
      downloadJobOverviewLookAvatar: function downloadJobOverviewLookAvatar(avatar, position) {
        this.onDownloadJobOverviewLookAvatar();
      },
      _downloadFile: function _downloadFile(link) {
        var fixedLink = link.substr(link.lastIndexOf('/') + 1);
        window.open(config['default'].APIURL + 'filesdownload/bookings/' + fixedLink, '_blank' // <- This is what makes it open in a new window.
        );
      }
    },

    model: function model(params) {
      var _this2 = this;

      return Ember['default'].RSVP.hash({
        job: this.store.findRecord('job', params.id, { reload: true }),
        fullJob: utils['default'].getAPIJSON('jobs/fullJob/' + params.id).then(function (r) {
          delete r.job.job;
          return r.job;
        })
      }, function () {
        _this2.transitionTo('jobs.index');
      });
    },

    afterModel: function afterModel(model) {
      var _this3 = this;

      //.job due to RAW=>false in backend
      var data = model;

      if (data.job.get('deleted')) {
        this.transitionTo('jobs.index');
      } else {
        var jobstatuses = data.job.get('jobStatus');

        jobstatuses.forEach(function (s) {
          if (s.get('shipping')) {
            var shippingData = s.get('shipping').split('|');
            Ember['default'].set(s, 'shipping', shippingData);
            Ember['default'].set(s, 'shippingType', shippingData.length > 0 ? shippingData[0] : null);
            Ember['default'].set(s, 'shippingCourier', shippingData.length > 1 ? shippingData[1] : null);
            Ember['default'].set(s, 'shippingNumber', shippingData.length > 2 ? shippingData[2] : null);
          }
        });
        //data.job = utils.pushPayload(this.store, 'job', data.job);
        //utils.pushArrayPayload(this.store, 'collection', data.collections);

        //Collections in JSON
        //console.log(data.collections);
        var collections = data.fullJob.collections;
        var storeCollections = Ember['default'].A();
        for (var i = 0; i < collections.length; i++) {
          storeCollections.push(utils['default'].pushPayload(this.store, 'collection', collections[i]));
        }
        //MAGIC: COLLECTIONS in Model
        //console.log(storeCollections);
        data.collections = storeCollections;

        //Collections in JSON
        var brands = data.fullJob.brands;
        var storeBrands = Ember['default'].A();

        var _loop = function (i) {
          var storeBrand = utils['default'].pushPayload(_this3.store, 'brand', brands[i]);
          storeBrand.set('collections', storeCollections.filter(function (c) {
            return c.get('brand.id') === storeBrand.get('id');
          }));
          var users = brands[i]['users'];
          var storeUsers = Ember['default'].A();
          for (var y = 0; y < users.length; y++) {
            storeUsers.push(utils['default'].pushPayload(_this3.store, 'user', users[y]));
          }
          storeBrand.set('users', storeUsers);
          storeBrands.push(storeBrand);
        };

        for (var i = 0; i < brands.length; i++) {
          _loop(i);
        }
        data.brands = storeBrands;
        data.loanForms = data.fullJob.loanForms;
        delete data.fullJob;
      }

      //utils.pushArrayPayload(this.store, 'brand', data.brands);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);
      //controller.setProperties(model.fullJob);
      var _t = this;
      controller.set('userLogged', Ember['default'].user);

      //FORM-VIEW JS
      var id = model.job.id;
      if (model.loanForms[0]) {
        var booking = [];
        utils['default'].getAPIJSON('bookings/' + model.loanForms[0].booking.id).then(function (res) {
          controller.set('booking', res);
          //console.log(res);
        });
      }

      controller.set('jobCanBeClosed', controller.get('job.canBeClosed'));

      _t.controllerFor('application').set('jobToCreate', controller.get('job'));

      //SHOPPING-LIST JS
      controller.set('isLoading', false);
      controller.set('looksArraySamples', []);

      var samplesToLoad = controller.get('job.samples');

      controller.set('samplesIds', samplesToLoad.map(function (s) {
        return parseInt(s.get('id'), 10);
      }));

      //controller.set('loadingJobLooks', true);
      this._getSamplesInfo(samplesToLoad);
      this.controller.get('job').setSampleStatus();

      var trackParcelFound = samplesToLoad.some(function (sample) {
        return sample.get('jobStatus.status') === 'DISPATCHED';
      });

      controller.set('jobCanBeClosed', !trackParcelFound);

      utils['default'].addObserver(controller, ['searchInput'], function () {
        var searchInput = controller.get('searchInput').toLowerCase();
        controller.get('looksArraySamples').forEach(function (sample) {
          var search = Ember['default'].itemsLabels[sample.get('item')];
          if (search) {
            sample.set('visible', search.toLowerCase().indexOf(searchInput) >= 0);
          } else {
            sample.set('visible', true);
          }
        });
      });

      //To separate in brands. TO BE CONTINUED IN NEXT VERSION
      var storeBrands = controller.get('brands');
      var filtered = storeBrands.filter(function (b) {
        return b.get('collections').some(function (c) {
          return c.get('hasVisibleAndNotRemovedSamples');
        });
      });
      controller.set('brandsFiltered', filtered);

      utils['default'].addObserver(controller, ['collections.@each.hasVisibleAndNotRemovedSamples'], function () {
        var storeBrands = controller.get('brands');
        var filtered = storeBrands.filter(function (b) {
          return b.get('collections').some(function (c) {
            return c.get('hasVisibleAndNotRemovedSamples');
          });
        });
        controller.set('brandsFiltered', filtered);
      });

      //Loan Forms
      var loans = controller.get('loanForms');
      if (!Ember['default'].isNone(loans) && !loans.get('length') > 0) {
        loans = loans.sortBy('checkoutDate');
      }
      controller.set('allLoanForms', loans);

      controller.set('countryList', Ember['default'].countries);
      controller.set('todayDate', new Date());

      //Files
      if (model.job.get('files')) {
        var jobFiles = [];
        var filesObjs = model.job.get('files');

        filesObjs.forEach(function (file, i) {
          jobFiles.push(file);
        });
        jobFiles.reverse();

        controller.set('jobFiles', jobFiles);
      }
    },

    onCloseJob: function onCloseJob(job) {
      var _t = this;
      utils['default'].openDefaultConfirmationModal(this, 'Close <strong>Job</strong>', 'You are about to close this job.', 'Do you wish to proceed?', 'Yes, close this job', 'No').then(function () {
        _t.send('openMessageModal', "Closing job. Please Wait.");
        job.set('closed', true);

        utils['default'].putAPIJSON('jobs/close/' + job.get('id')).then(function () {
          _t.refresh().then(function () {
            _t.send('closeMessageModal');
          });
        }, function (failed) {
          _t.refresh().then(function () {
            _t.send('closeMessageModal');
          });
        });
      });
    },
    onDownloadLoanForm: function onDownloadLoanForm(loanFormId, bookingId) {
      var _this4 = this;

      this.controller.get('booking').booking.addresses.forEach(function (address) {
        if (address.loanForm == loanFormId) {
          _this4.transitionTo('bookings.loan-form', bookingId, address.id);
        }
      });
    },

    onDownloadLoanFormDDT: function onDownloadLoanFormDDT(bookingId, parcelDDTId) {
      this.transitionTo('bookings.ddt', bookingId, parcelDDTId);
    },

    _getSamplesInfo: function _getSamplesInfo(samples) {
      var _this5 = this;

      var promises = samples.map(function (s) {
        return s.get('look');
      });

      Ember['default'].RSVP.all(promises).then(function (looks) {
        var uniqLooks = looks.uniq();
        _this5.controller.get('job').set('looks', uniqLooks);
        _this5.controller.get('job').notifyPropertyChange('visibleLooks');
        //this.controller.set('loadingJobLooks', false);
        _this5._getPRUsers(uniqLooks);
      });
    },

    _getPRUsers: function _getPRUsers(looks) {
      var _this6 = this;

      utils['default'].postAPIJSON('looks/prUsers', {
        country: this.controller.get('job.country'),
        looks: looks.mapBy('id')
      }).then(function (res) {
        looks.forEach(function (l) {
          var users = res.look[l.get('id')];
          users = users.map(function (u) {
            return utils['default'].pushPayload(_this6.get('store'), 'user', u);
          });

          l.set('prUsers', users);
          l.get('samples').setEach('visible', true);
          _this6.controller.get('looksArraySamples').addObjects(l.get('samples'));
        });
        _this6.notifyPropertyChange('job.looks');
        _this6.controller.set('searchInput', '');
      });
    },

    onRequestSample: function onRequestSample(sample) {
      var _this7 = this;

      var job = this.controller.get('job');
      if (job.get('isOpen')) {
        utils['default'].openDefaultConfirmationModal(this, 'Request <strong>Sample<strong></strong>', 'The status of this job will change to Active', 'Do you wish to proceed?', 'Yes, request this sample', 'No').then(function () {
          _this7._doSampleRequest(job, sample);
        });
      } else {
        this._doSampleRequest(job, sample);
      }
    },

    onRequestAllSamples: function onRequestAllSamples() {
      var _this8 = this;

      var job = this.controller.get('job');
      if (job.get('isOpen')) {
        utils['default'].openDefaultConfirmationModal(this, 'Request All samples', 'The status of this job will change to Active', 'Do you wish to proceed?', 'Yes, request all samples', 'No').then(function () {
          _this8._doRequestAllSamples(job);
        });
      } else {
        this._doRequestAllSamples(job);
      }
    },

    _doSampleRequest: function _doSampleRequest(job, sample) {
      var promise = this._requestArrayOfSamples(job, [sample]);
      this.send('openMessageModal', 'Requesting...');
      job.set('active', true);

      utils['default'].buttonLoading('#request-sample-' + sample.get('id'), promise);
    },

    _doRequestAllSamples: function _doRequestAllSamples(job) {
      var samplesArrObj = job.get('samples').filter(function (s) {
        return !s.get('isRequested');
      });
      this.send('openMessageModal', 'Requesting...');
      job.set('active', true);

      if (!Ember['default'].isEmpty(samplesArrObj)) {
        this._requestArrayOfSamples(job, samplesArrObj);
      } else {
        this.send('closeMessageModal');
        this.controller.set('message', 'There are no samples to request');
        this.controller.set('empty', true);
        Ember['default'].$('#requestedSamples').modal();
      }
    },

    _requestArrayOfSamples: function _requestArrayOfSamples(job, samplesArray) {
      var _this9 = this;

      var samplesArrayId = samplesArray.mapBy('id');
      var promises = [];
      var route = this;
      return utils['default'].postAPIJSON('jobs/' + job.get('id') + '/request', { samples: samplesArrayId }).then(function (res) {
        job.get('samples').filter(function (s) {
          return !s.get('isRequested');
        });
        job.reload().then(function () {
          var samplesRequested = [];

          for (var i = 0; i < res.job.length; i++) {
            var sampleFound = _this9.get('store').peekRecord('sample', res.job[i]);

            sampleFound.set('jobStatus', samplesArray.objectAt(i).get('jobStatus'));
            sampleFound.set('visible', true);
            sampleFound.set('isRequested', true);

            samplesRequested.pushObject(sampleFound);
            promises.push(sampleFound.reload());
          }

          Ember['default'].RSVP.all(promises).then(function () {
            _this9.controller.get('job').notifyPropertyChange('visibleLooks');
            _this9.send('closeMessageModal');
            // this.send('openModal', 'Sample requested successfully');
            if (localStorage.getItem('notifyRequests') == 'true') {
              _this9.controller.set('message', 'Great! Your sample(s) has been requested!');
              _this9.controller.set('empty', false);
              Ember['default'].$('#requestedSamples').modal();
            }
          });
        });
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }
        job.reload().then(function () {
          route.send('openTopBar', 'Ooops! ' + errors);
          route.controller.get('job').notifyPropertyChange('visibleLooks');
          route.send('closeMessageModal');
        });
      });
    },

    onCancelSample: function onCancelSample(sample) {
      var _this10 = this;

      var btn = Ember['default'].$('#cancel-sample-' + sample.get('id'));
      btn.button('loading');
      var route = this;
      var job = this.controller.get('job');
      var title = "";
      if (sample.get('isRequested')) {
        title = "Cancel <strong>Requested Sample</strong>";
      } else {
        title = "Cancel <strong>Sample</strong>";
      }
      utils['default'].openDefaultConfirmationModal(this, title, 'You are about to cancel this sample from the job', 'Do you wish to proceed?', 'Cancel sample', 'No').then(function () {
        utils['default'].postAPIJSON('jobs/' + job.get('id') + '/cancelSample/' + sample.get('id'), {}).then(function () {
          sample.set('isRequested', true);
          sample.set('jobStatus.status', 'CANCELED');
          job.reload().then(function () {
            _this10.controller.get('job').notifyPropertyChange('visibleLooks');
            route.send('openPositiveTopBar');
            btn.button('reset');
          });
        }, function (res) {
          //fail
          var errors = '';
          if (res && res.responseText) {
            errors = JSON.parse(res.responseText).errors;
          }
          job.reload().then(function () {
            route.send('openTopBar', 'Ooops! ' + errors);
            btn.button('reset');
          });
        });
      }, function () {
        btn.button('reset');
      });
    },

    onRemoveSample: function onRemoveSample(sample) {
      var _this11 = this;

      var btn = Ember['default'].$('#remove-sample-' + sample.get('id'));
      btn.button('loading');
      var route = this;
      var job = this.controller.get('job');
      utils['default'].putAPIJSON('jobs/' + job.get('id') + '/removeSample/' + sample.get('id'), {}).then(function () {
        sample.set('visible', false);
        _this11.controller.get('job').notifyPropertyChange('visibleLooks');
        btn.button('reset');
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }
        job.reload().then(function () {
          route.send('openTopBar', 'Ooops! ' + errors);
          btn.button('reset');
        });
      });
    },

    onOpenTrackParcel: function onOpenTrackParcel(jobStatus) {

      this.controller.set('trackParcel', jobStatus);
    },

    onAcceptSampleProposal: function onAcceptSampleProposal(sample) {
      var sampleId = sample.get('id');
      var btn = Ember['default'].$('#accept-sample-' + sampleId);
      btn.button('loading');
      var route = this;
      var jobId = this.controller.get('job.id');

      utils['default'].putAPIJSON('jobs/' + jobId + '/acceptSampleProposal/' + sampleId).then(function (res) {
        sample.set('jobStatus.status', res.job);
        route.send('openPositiveTopBar');
        btn.button('reset');
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }
        route.store.findRecord('job', jobId, { reload: true }).then(function () {
          route.send('openTopBar', 'Ooops! ' + errors);
          btn.button('reset');
        });
      });
    },

    onDeclineSampleProposal: function onDeclineSampleProposal(sample) {
      var sampleId = sample.get('id');
      var btn = Ember['default'].$('#decline-sample-' + sampleId);
      btn.button('loading');
      var route = this;
      var jobId = this.controller.get('job.id');

      utils['default'].putAPIJSON('jobs/' + jobId + '/declineSampleProposal/' + sampleId).then(function (res) {
        sample.set('jobStatus.status', res.job);
        route.send('openPositiveTopBar');
      }, function (res) {
        //fail
        var errors = '';
        if (res && res.responseText) {
          errors = JSON.parse(res.responseText).errors;
        }
        route.store.findRecord('job', jobId, { reload: true }).then(function () {
          route.send('openTopBar', 'Ooops! ' + errors);
          btn.button('reset');
        });
      });
    },

    onCollapseAreaAndScroll: function onCollapseAreaAndScroll(textSelector, scrSel, id) {
      var el = Ember['default'].$('#' + textSelector + id).find('a');
      var scr = Ember['default'].$('#' + scrSel + id);
      var oldText = el.text();
      var newText = "";
      if (oldText.trim()[0] === 'S') {
        newText = oldText.replace('Show all (+', 'Hide (-');
      } else {
        newText = oldText.replace('Hide (-', 'Show all (+');
        $('html, body').animate({ scrollTop: scr.offset().top });
      }
      el.text(newText);
    },

    //FORM-EDIT JS

    onAddFile: function onAddFile() {
      var _this12 = this;

      var _t = this;
      var input = Ember['default'].$('#add-job-file');

      input.off("change").on("change", function () {
        var file = input[0].files[0];
        if (file.size > 3145728) {
          _t.send('openErrorsModal', [], 'File size cannot exceed 3 MB');
        } else {
          _t.send('openMessageModal', 'Uploading File');
          _t.controller.set('loadingAddFile', true);
          //loadingAdd file is 'an easy way' to reset input value
          var f = _this12.store.createRecord('file', {
            name: file.name,
            job: _t.controller.get('job'),
            file: file
          });
          f.save().then(function (res) {
            //f.setProperties(res.file);
            _t.controller.set('loadingAddFile', false);
            f = _this12.store.findRecord('file', res.file.id, { reload: true }).then(function () {
              if (_t.controller.get('job').get('files')) {
                var jobFiles = [];

                var filesObjs = _t.controller.get('job').get('files');

                filesObjs.forEach(function (file, i) {
                  if (file.get('id')) {
                    jobFiles.push(file);
                  }
                });
                jobFiles.reverse();

                _t.controller.set('jobFiles', jobFiles);
              }
              _t.send('closeMessageModal');
            });
          });
        }
      });
    },

    onRemoveFile: function onRemoveFile(file) {
      var _t = this;
      file.set('toDelete', true);
      file.destroyRecord().then(function () {
        _t.send('openTopBarWithTime', 'File deleted', 1500);
      });
    },

    onGoToLoanFormPage: function onGoToLoanFormPage(type, bookingId, parcelId) {
      var _this = this;
      if (type == "defaultParcel") {
        _this.transitionTo('bookings.loan-form', bookingId, parcelId);
      } else {
        _this.transitionTo('bookings.ddt', bookingId, parcelId);
      }
    },

    //NOT IN USE
    onSaveJobDetails: function onSaveJobDetails() {
      var btn = Ember['default'].$('#save-job-btn');
      btn.button('loading');
      var _t = this;
      Ember['default'].$('#edit-job-modal').modal('hide');
      _t.send('openMessageModal', 'Saving Job Details...');
      var job = this.controller.get('job');
      var addresses = job.get('addresses');
      var addressWillBeMain = addresses.findBy('setAsMain', true);
      if (!Ember['default'].isNone(addressWillBeMain)) {
        var changeMainPromise = _t._changeJobMainAddress(addressWillBeMain);
        if (!Ember['default'].isNone(changeMainPromise)) {
          changeMainPromise.then(function () {
            _t._finishSavingJob(_t, job, btn);
          });
        } else {
          _t._finishSavingJob(_t, job, btn);
        }
      } else {
        _t._finishSavingJob(_t, job, btn);
      }
    },

    //NOT IN USE
    _finishSavingJob: function _finishSavingJob(route, job, btn) {
      var _t = route;
      var addresses = job.get('addresses');

      addresses.forEach(function (a) {});
      job.save().then(function () {
        _t.send('closeMessageModal');
        btn.button('reset');
      }, function () {
        _t.send('openTopBar', 'Something has failed saving the job');
        _t.send('closeMessageModal');
        btn.button('reset');
        Ember['default'].$('#edit-job-modal').modal();
        Ember['default'].openModal();
      });
    },

    //NOT IN USE
    onSetMainAddress: function onSetMainAddress(newMainAddress) {
      var _this13 = this;

      var promise = this._changeJobMainAddress(newMainAddress);
      if (promise) {
        promise.then(function () {
          //this.send('openTopBarWithTime', "Job main address has changed.", 3000);
          _this13.send('openPositiveTopBar');
        });
      } else {
        //_t.onOpenTopBarWithTime("Main address change failed.", 3000);
      }
    },

    onToggleSetAsMainAddress: function onToggleSetAsMainAddress(newMainAddress) {
      var job = this.controller.get('job');
      var addresses = job.get('addresses');
      addresses.forEach(function (a) {
        a.set('setAsMain', false);
      });
      newMainAddress.set('setAsMain', true);
    },

    //NOT IN USE
    _changeJobMainAddress: function _changeJobMainAddress(address) {
      var promise = null;
      var _t = this;
      var job = _t.controller.get('job');

      var currentMain = job.get('mainAddress');
      promise = null;
      if (!Ember['default'].isNone(currentMain) && currentMain.get('id') !== address.get('id')) {
        promise = _t.get('store').findRecord('job-address', currentMain.get('id')).then(function (main) {
          main.set('main', false);
          return main.save().then(function () {
            address.set('main', true);
            return address.save();
          });
        });
      } else {
        address.set('main', true);
        promise = address.save();
      }
      return promise;
    },

    onAddAnotherAddress: function onAddAnotherAddress() {
      this.store.createRecord('job-address', { job: this.controller.get('job'), main: false });
    },

    onRemoveAddress: function onRemoveAddress(address) {
      address.destroyRecord();
    },

    onSaveJobFromAPI: function onSaveJobFromAPI() {
      var _t = this;
      var btn = Ember['default'].$('#save-job-btn');
      btn.button('loading');
      var job = _t.controller.get('job');

      if (job.validate()) {
        (function () {
          Ember['default'].$('#edit-job-modal').modal('hide');
          var addresses = job.get('addresses');
          _t.send('openMessageModal', 'Saving Job Details...');

          var addressesJSON = Ember['default'].A();
          addresses.forEach(function (a) {
            addressesJSON.pushObject(a.toJSON());
          });

          utils['default'].putAPIJSON('jobs/' + job.get('id') + '/updateWithAddresses', { 'jobEntity': job, 'addresses': addressesJSON }).then(function () {
            _t.store.findRecord('job', job.get('id'), { reload: true }).then(function (j) {
              var ad1 = j.get('addresses').find(function (a) {
                return Ember['default'].isNone(a.get('id'));
              });
              if (ad1) {
                ad1.deleteRecord();
              }
              var ad2 = j.get('addresses').find(function (a) {
                return Ember['default'].isNone(a.get('id'));
              });
              if (ad2) {
                ad2.deleteRecord();
              }var ad3 = j.get('addresses').find(function (a) {
                return Ember['default'].isNone(a.get('id'));
              });
              if (ad3) {
                ad3.deleteRecord();
              }
              //console.log(j.get('addresses')); //3
              //console.log(j.get('addresses').get('content').get('canonicalState')); //5
              btn.button('reset');
              _t.send('closeMessageModal');
            }, function () {
              //FAIL
              btn.button('reset');
              _t.send('closeMessageModal');
            });
          }, function () {
            //FAIL
            btn.button('reset');
            _t.send('closeMessageModal');
          });
        })();
      } else {
        btn.button('reset');
      }
    },

    onCancelEditJob: function onCancelEditJob() {
      var _t = this;
      var job = _t.controller.get('job');
      job.rollbackAttributes();
      var addresses = job.get('addresses');
      addresses.forEach(function (a) {
        if (!a.get('isNew')) {
          a.rollbackAttributes();
        }
      });
      addresses.forEach(function (a) {
        if (a.get('isNew')) {
          a.rollbackAttributes();
        }
      });
    },

    onOpenModalJobOverviewSample: function onOpenModalJobOverviewSample(sample) {
      var _t = this;
      _t.controller.set('sampleToView', sample);

      _t.send('openMessageModal', "Loading album data");
      _t.store.findRecord('look', sample.get('look.id')).then(function (l) {
        _t.send('closeMessageModal');
        _t.onSetJobLookToView(l);

        Ember['default'].$('#shopping-list-overview-modal').modal();
        Ember['default'].openModal();
      });
    },

    onSetJobLookToView: function onSetJobLookToView(l) {
      var _t = this;
      _t.controller.set('lookToView', l);
      if (!Ember['default'].isNone(l)) {
        if (!Ember['default'].isNone(l.get('avatars.0'))) {
          _t.onSetCurrentJobAvatarToView(l.get('avatars.0'), 0);
        } else {
          var avatar = {
            avatar: "images/default-image.png",
            thumbnail: "images/default-image.png"
          };
          _t.onSetCurrentJobAvatarToView(avatar, 0);
        }
      } else {
        var avatar = {
          avatar: "images/default-image.png",
          thumbnail: "images/default-image.png"
        };
        _t.onSetCurrentJobAvatarToView(avatar, 0);
      }
      var currentGroupId = null;
      var lookSamples = l.get('activeSamples');
      lookSamples.setEach('isSelectable', true);
      //Code for show only one sample of duplicates samples
      lookSamples.forEach(function (sample) {
        sample.set('isSelectedForJob', false);
        if (sample.get('groupid')) {
          if (sample.get('groupid') === currentGroupId) {
            sample.set('isSelectable', false);
          } else {
            currentGroupId = sample.get('groupid');
          }
        }
      });
      _t.controller.set('selectableSamples', lookSamples.filter(function (s) {
        return s.get('isSelectable');
      }));
    },

    onSetCurrentJobAvatarToView: function onSetCurrentJobAvatarToView(avatar, avatarPosition) {
      var _t = this;
      var l = _t.controller.get('lookToView');
      Ember['default'].set(avatar, 'position', avatarPosition);
      //avatar.position = avatarPosition;
      if (!Ember['default'].isNone(l)) {
        l.set('currentAvatarToView', avatar);
      }
    },

    onSlideCurrentJobAvatarToView: function onSlideCurrentJobAvatarToView(direction) {
      var _t = this;
      var l = _t.controller.get('lookToView');
      var avatars = l.get('avatars');
      var current = l.get('currentAvatarToView');
      var currentPosition = current.position;
      if (direction === 'next') {
        if (avatars.get('length') === currentPosition + 1) {
          _t.onSetCurrentJobAvatarToView(avatars.get(0), 0);
        } else {
          _t.onSetCurrentJobAvatarToView(avatars.get(currentPosition + 1), currentPosition + 1);
        }
      } else {
        if (0 === currentPosition) {
          _t.onSetCurrentJobAvatarToView(avatars.get(avatars.get('length') - 1), avatars.get('length') - 1);
        } else {
          _t.onSetCurrentJobAvatarToView(avatars.get(currentPosition - 1), currentPosition - 1);
        }
      }
    },

    onDownloadJobOverviewLookAvatar: function onDownloadJobOverviewLookAvatar() {
      var srcImg = Ember['default'].$('#shopping-list-overview-current-avatar').data('avatarSrc');
      //Same code as old download (onLookDownloadImage)
      var splittedSrc = srcImg.split('/');
      var img = splittedSrc[splittedSrc.length - 1];
      utils['default'].getAPIJSON('looks/' + img + '/image').then(function (res) {
        if (res.look != null) {
          var element = document.createElement('a');
          element.setAttribute('href', res.look);
          element.setAttribute('download', img);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-job-view');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-job-view');
    }
  });

});