define('bookalook/templates/bookings/ddt', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","showroomInfo.companyName",["loc",[null,[12,14],[12,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 16
              },
              "end": {
                "line": 21,
                "column": 89
              }
            },
            "moduleName": "bookalook/templates/bookings/ddt.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","pointer");
            var el2 = dom.createTextNode("Back to job");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 10
            },
            "end": {
              "line": 22,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["jobs.view",["get","booking.job.id",["loc",[null,[21,39],[21,53]]]]],[],0,null,["loc",[null,[21,16],[21,101]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 16
              },
              "end": {
                "line": 23,
                "column": 129
              }
            },
            "moduleName": "bookalook/templates/bookings/ddt.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","pointer");
            var el2 = dom.createTextNode("Back to booking");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 10
            },
            "end": {
              "line": 24,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["bookings.booking-details",["get","booking.id",["loc",[null,[23,54],[23,64]]]],["subexpr","query-params",[],["search",""],["loc",[null,[23,65],[23,89]]]]],[],0,null,["loc",[null,[23,16],[23,141]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 6
            },
            "end": {
              "line": 32,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-6 no-padding-right text-right");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","btn_primary");
          var el3 = dom.createTextNode("Generate document");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["element","action",["download"],[],["loc",[null,[30,32],[30,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 6
            },
            "end": {
              "line": 37,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-6 no-padding-right text-right");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","btn_primary");
          var el3 = dom.createTextNode("Save & Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"id","saveLoanFormButton");
          dom.setAttribute(el2,"data-loading-text","<i class='fa fa-circle-o-notch fa-spin'></i>");
          dom.setAttribute(el2,"class","btn_primary");
          var el3 = dom.createTextNode("Save");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["element","action",["download"],[],["loc",[null,[34,18],[34,39]]]],
          ["element","action",["saveLoanForm"],[],["loc",[null,[35,121],[35,146]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 4
            },
            "end": {
              "line": 47,
              "column": 4
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          dom.setAttribute(el1,"class","hidden-print");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","loan_form_download_button col-xs-12 hidden-print");
          var el3 = dom.createTextNode("\n          Click here if generated pdf file did not open in a new window\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element2, 'href');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",[["get","PDFURL",["loc",[null,[42,17],[42,23]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 87
            },
            "end": {
              "line": 81,
              "column": 156
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1,"class","fa fa-circle-o-notch fa-spin");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 14
            },
            "end": {
              "line": 103,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("strong");
          var el2 = dom.createTextNode("Data reso:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","date-format",[["get","parcel.checkinDate",["loc",[null,[102,57],[102,75]]]],".",false,false],[],["loc",[null,[102,43],[102,93]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 103,
              "column": 14
            },
            "end": {
              "line": 114,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("strong");
          var el2 = dom.createTextNode("Data reso: ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
          return morphs;
        },
        statements: [
          ["inline","bootstrap-datepicker",[],["class","inline_date_value","autoclose",true,"placeholder","DD/MM/YY","orientation","top right","value",["subexpr","@mut",[["get","parcel.checkinDate",["loc",[null,[109,30],[109,48]]]]],[],[]],"format","dd.mm.yy","weekStart",1,"name","dateday"],["loc",[null,[104,50],[113,16]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 14
            },
            "end": {
              "line": 122,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","inline-textarea-edition",[],["value",["subexpr","@mut",[["get","parcel.billingAddress",["loc",[null,[121,48],[121,69]]]]],[],[]]],["loc",[null,[121,16],[121,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 122,
              "column": 14
            },
            "end": {
              "line": 125,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","line-space select_contact_address_button hidden-print");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","btn_edit");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" Select contact address");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3, 0]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1,0,0);
          return morphs;
        },
        statements: [
          ["inline","inline-textarea-edition",[],["value",["subexpr","@mut",[["get","parcel.billingAddress",["loc",[null,[123,48],[123,69]]]]],[],[]]],["loc",[null,[123,16],[123,71]]]],
          ["element","action",["openContactAddressSelector"],[],["loc",[null,[124,91],[124,130]]]],
          ["inline","fa-icon",["user"],[],["loc",[null,[124,148],[124,166]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 154,
              "column": 14
            },
            "end": {
              "line": 156,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","parcel.shippingCompanyAddress",["loc",[null,[155,16],[155,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 156,
              "column": 14
            },
            "end": {
              "line": 159,
              "column": 14
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","hidden-print");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Save as default");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","inline-textarea-edition",[],["value",["subexpr","@mut",[["get","parcel.shippingCompanyAddress",["loc",[null,[157,48],[157,77]]]]],[],[]]],["loc",[null,[157,16],[157,79]]]],
          ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","saveVettori",["loc",[null,[158,74],[158,85]]]]],[],[]]],["loc",[null,[158,42],[158,87]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 166,
              "column": 12
            },
            "end": {
              "line": 168,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("strong");
          var el2 = dom.createTextNode("Data trasporto:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","date-format",[["get","parcel.shippingDate",["loc",[null,[167,60],[167,79]]]],".",false,false],[],["loc",[null,[167,46],[167,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 168,
              "column": 12
            },
            "end": {
              "line": 180,
              "column": 12
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("strong");
          var el2 = dom.createTextNode("Data trasporto: ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
          return morphs;
        },
        statements: [
          ["inline","bootstrap-datepicker",[],["class","inline_date_value","autoclose",true,"placeholder","DD/MM/YY","orientation","top right","value",["subexpr","@mut",[["get","parcel.shippingDate",["loc",[null,[175,28],[175,47]]]]],[],[]],"format","dd.mm.yy","weekStart",1,"name","dateday"],["loc",[null,[170,53],[179,14]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 190,
              "column": 8
            },
            "end": {
              "line": 192,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          dom.setAttribute(el1,"id","add_samples_button");
          dom.setAttribute(el1,"class","btn_primary pull-right");
          var el2 = dom.createTextNode("Add samples");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["openAddSamplesModal"],[],["loc",[null,[191,54],[191,86]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 194,
                "column": 10
              },
              "end": {
                "line": 204,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/bookings/ddt.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-xs-9 loader-container search-container");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("center");
            var el3 = dom.createElement("h3");
            var el4 = dom.createTextNode("Loading samples...");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","sk-folding-cube");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","sk-cube1 sk-cube");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","sk-cube2 sk-cube");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","sk-cube4 sk-cube");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","sk-cube3 sk-cube");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 193,
              "column": 8
            },
            "end": {
              "line": 205,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","isLoading",["loc",[null,[194,16],[194,25]]]]],[],0,null,["loc",[null,[194,10],[204,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 205,
              "column": 8
            },
            "end": {
              "line": 207,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","pull-right");
          var el2 = dom.createTextNode("No samples were added to this DDT. ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 212,
              "column": 10
            },
            "end": {
              "line": 214,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/bookings/ddt.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","partial",["bookings/partials/look-card-loan-old"],[],["loc",[null,[213,10],[213,60]]]]
        ],
        locals: ["collection"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 229,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/bookings/ddt.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 relative");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-wrapper clearfix");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-6 no-padding-left text-left text-uppercase");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("DDT");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-6 no-padding-right text-right company-name");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","breadcrumbs col-xs-12 no-padding clearfix margin-bottom");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-6 no-padding-left");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","list-inline");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","new-loan-form-wrapper no-padding");
        dom.setAttribute(el3,"id","ddt_header");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n   ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","pdf_container");
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 no-padding");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","row logo_loan no-padding");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"height","90px");
        dom.setAttribute(el7,"width","90px");
        dom.setAttribute(el7,"style","width: 90px; height: 90px");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 no-padding new-loan-form-header");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","row");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-4");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Reso");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col-xs-8");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","row");
        var el9 = dom.createTextNode("\n\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-7");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("strong");
        var el12 = dom.createTextNode("Documento Di Transporto (D.d.T)");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        var el12 = dom.createTextNode("D.P.R 465 del 14.08.1996");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        var el12 = dom.createTextNode("D.P.R 765 del 21.02.1996");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-5");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("strong");
        var el12 = dom.createTextNode("N.");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode(" ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("strong");
        var el12 = dom.createTextNode("Data.");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","new-loan-form-wrapper no-padding clearfix");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("table");
        dom.setAttribute(el6,"class","col-xs-12");
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-4 border-gray no-border-bottom");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Aspetto esteriore beni: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Causale: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Totale Pezzi: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-8 pink-bg");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-6");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","text-uppercase");
        var el11 = dom.createElement("strong");
        var el12 = dom.createTextNode("Destinatario");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-6");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","text-uppercase");
        var el11 = dom.createElement("strong");
        var el12 = dom.createTextNode("Destinazione");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("table");
        dom.setAttribute(el6,"class","col-xs-12 margin-bottom");
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-4 border-gray");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Transporto a mezzo: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-2 border-gray");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"id","test_input_loan_form_ddt");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Peso Kg: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-6 border-gray");
        dom.setAttribute(el8,"rowspan","2");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-3");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Vettori: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-xs-9");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-4 border-gray");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("           ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","col-xs-2 border-gray");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("N. Colli: ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 no-padding hidden-print add_samples_wrapper");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 look_content");
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","checkout_body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-12 legal_address hidden-print");
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","images/official-bw-pin.svg");
        dom.setAttribute(el7,"height","50px");
        dom.setAttribute(el7,"width","50px");
        dom.setAttribute(el7,"style","width: 50px; height: 50px");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" VAT Number ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("end pdf_container");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0, 1]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element7, [5]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element10, [1, 1, 1]);
        var element12 = dom.childAt(element10, [3, 1]);
        var element13 = dom.childAt(element12, [3, 1, 3]);
        var element14 = dom.childAt(element13, [1, 2]);
        var element15 = dom.childAt(element10, [5]);
        var element16 = dom.childAt(element15, [1, 1]);
        var element17 = dom.childAt(element16, [1]);
        var element18 = dom.childAt(element16, [3]);
        var element19 = dom.childAt(element15, [3]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element19, [3]);
        var element22 = dom.childAt(element15, [5]);
        var element23 = dom.childAt(element15, [9, 3]);
        var morphs = new Array(27);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1, 3]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element8, [1, 1]),1,1);
        morphs[2] = dom.createMorphAt(element8,3,3);
        morphs[3] = dom.createMorphAt(element9,1,1);
        morphs[4] = dom.createAttrMorph(element11, 'src');
        morphs[5] = dom.createMorphAt(dom.childAt(element12, [1]),3,3);
        morphs[6] = dom.createMorphAt(element14,0,0);
        morphs[7] = dom.createMorphAt(element14,2,2);
        morphs[8] = dom.createMorphAt(dom.childAt(element13, [3]),2,2);
        morphs[9] = dom.createMorphAt(dom.childAt(element17, [1, 1]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element17, [3, 1]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element17, [5, 1]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element17, [7]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element18, [1]),3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element18, [3]),3,3);
        morphs[15] = dom.createMorphAt(dom.childAt(element20, [1, 1]),1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element20, [3, 1, 1]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element20, [5, 3]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element21, [1, 1]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element21, [3, 1, 1]),0,0);
        morphs[20] = dom.createMorphAt(element22,1,1);
        morphs[21] = dom.createMorphAt(element22,2,2);
        morphs[22] = dom.createMorphAt(dom.childAt(element15, [7, 1]),1,1);
        morphs[23] = dom.createMorphAt(element23,0,0);
        morphs[24] = dom.createMorphAt(element23,2,2);
        morphs[25] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["block","unless",[["get","userLogged.isMP",["loc",[null,[11,18],[11,33]]]]],[],0,null,["loc",[null,[11,8],[13,19]]]],
        ["block","if",[["get","userLogged.isMP",["loc",[null,[20,16],[20,31]]]]],[],1,2,["loc",[null,[20,10],[24,17]]]],
        ["block","if",[["get","userLogged.isMP",["loc",[null,[28,12],[28,27]]]]],[],3,4,["loc",[null,[28,6],[37,13]]]],
        ["block","if",[["get","PDFURL",["loc",[null,[41,10],[41,16]]]]],[],5,null,["loc",[null,[41,4],[47,11]]]],
        ["attribute","src",["concat",[["get","showroomInfo.avatar",["loc",[null,[53,22],[53,41]]]]]]],
        ["inline","inline-textarea-edition",[],["value",["subexpr","@mut",[["get","parcel.returnAddress",["loc",[null,[62,44],[62,64]]]]],[],[]],"maxrows",6],["loc",[null,[62,12],[62,76]]]],
        ["inline","inline-text-edition",[],["value",["subexpr","@mut",[["get","parcel.number",["loc",[null,[81,71],[81,84]]]]],[],[]]],["loc",[null,[81,43],[81,86]]]],
        ["block","unless",[["get","parcel.number",["loc",[null,[81,97],[81,110]]]]],[],6,null,["loc",[null,[81,87],[81,167]]]],
        ["inline","date-format",[["get","parcel.loanForm.checkoutDate",["loc",[null,[84,56],[84,84]]]],".",false,false],[],["loc",[null,[84,42],[84,102]]]],
        ["inline","inline-text-edition",[],["value",["subexpr","@mut",[["get","parcel.appearance",["loc",[null,[97,92],[97,109]]]]],[],[]]],["loc",[null,[97,64],[97,111]]]],
        ["inline","inline-text-edition",[],["value",["subexpr","@mut",[["get","parcel.reason",["loc",[null,[98,77],[98,90]]]]],[],[]]],["loc",[null,[98,49],[98,92]]]],
        ["content","parcel.samples.length",["loc",[null,[99,54],[99,79]]]],
        ["block","if",[["get","userLogged.isMP",["loc",[null,[101,20],[101,35]]]]],[],7,8,["loc",[null,[101,14],[114,21]]]],
        ["block","if",[["get","userLogged.isMP",["loc",[null,[120,20],[120,35]]]]],[],9,10,["loc",[null,[120,14],[125,21]]]],
        ["inline","inline-textarea-edition",[],["value",["subexpr","@mut",[["get","parcel.orderAddress",["loc",[null,[129,48],[129,67]]]]],[],[]]],["loc",[null,[129,16],[129,69]]]],
        ["inline","inline-combo-edition",[],["placeholder","Transporto","content",["subexpr","@mut",[["get","Ember.shippingTypes",["loc",[null,[139,32],[139,51]]]]],[],[]],"model",["subexpr","@mut",[["get","parcel",["loc",[null,[140,32],[140,38]]]]],[],[]],"property","shippingType","optionLabelPath","name","optionValuePath","name","value",["subexpr","@mut",[["get","parcel.shippingType",["loc",[null,[144,32],[144,51]]]]],[],[]],"elid","shipping-label"],["loc",[null,[137,54],[146,14]]]],
        ["inline","inline-text-edition",[],["value",["subexpr","@mut",[["get","parcel.weight",["loc",[null,[149,107],[149,120]]]]],[],[]]],["loc",[null,[149,79],[149,122]]]],
        ["block","if",[["get","userLogged.isMP",["loc",[null,[154,20],[154,35]]]]],[],11,12,["loc",[null,[154,14],[159,21]]]],
        ["block","if",[["get","userLogged.isMP",["loc",[null,[166,18],[166,33]]]]],[],13,14,["loc",[null,[166,12],[180,19]]]],
        ["inline","inline-text-edition",[],["value",["subexpr","@mut",[["get","parcel.boxes",["loc",[null,[184,78],[184,90]]]]],[],[]]],["loc",[null,[184,50],[184,92]]]],
        ["block","unless",[["get","userLogged.isMP",["loc",[null,[190,18],[190,33]]]]],[],15,null,["loc",[null,[190,8],[192,19]]]],
        ["block","if",[["get","parcel.samples.length",["loc",[null,[193,14],[193,35]]]]],[],16,17,["loc",[null,[193,8],[207,15]]]],
        ["block","each",[["get","collections",["loc",[null,[212,18],[212,29]]]]],[],18,null,["loc",[null,[212,10],[214,19]]]],
        ["content","showroomInfo.legalAddress",["loc",[null,[220,13],[220,42]]]],
        ["content","showroomInfo.vat",["loc",[null,[220,54],[220,74]]]],
        ["inline","add-samples-loan-form",[],["booking",["subexpr","@mut",[["get","booking",["loc",[null,[227,32],[227,39]]]]],[],[]],"ddtFormPage",["subexpr","@mut",[["get","ddtFormPage",["loc",[null,[227,52],[227,63]]]]],[],[]],"samplesList",["subexpr","@mut",[["get","booking.samples",["loc",[null,[227,76],[227,91]]]]],[],[]],"selectedSamples",["subexpr","@mut",[["get","parcel.samples",["loc",[null,[227,108],[227,122]]]]],[],[]],"collections",["subexpr","@mut",[["get","collections",["loc",[null,[227,135],[227,146]]]]],[],[]],"store",["subexpr","@mut",[["get","store",["loc",[null,[227,153],[227,158]]]]],[],[]]],["loc",[null,[227,0],[227,160]]]],
        ["inline","contact-address-selector",[],["allContacts",["subexpr","@mut",[["get","allContacts",["loc",[null,[228,39],[228,50]]]]],[],[]],"parcel",["subexpr","@mut",[["get","parcel",["loc",[null,[228,58],[228,64]]]]],[],[]],"isDDT",true],["loc",[null,[228,0],[228,77]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18]
    };
  }()));

});