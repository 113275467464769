define('bookalook/route-access-data', ['exports'], function (exports) {

  'use strict';

  var routeAccessData = {
    "routes": {
      "index": ["non-user"],
      "barcodes": {
        "scan": ["PR"],
        "scan-booking": ["PR"]
      },
      "admin": {
        "index": [],
        "agencies": [],
        "brands": [],
        "mp-users": [],
        "plans": [],
        "pr-users": [],
        "white-list": []
      },
      "agencies": {
        "index": ["PR"],
        "agency": ["PR"]
      },
      "bookings": {
        "index": ["PR"],
        "booking-details": ["PR"],
        "booking-join": ["PR"],
        "booking-overview": ["PR"],
        "change-dates": ["PR"],
        "check-out": ["PR"],
        "ddt": ["PR", "MP"],
        "loan-form": ["PR", "MP"],
        "loan-forms-list": ["PR"],
        "overview": ["PR"],
        "shopping-list": ["PR"]
      },
      "check-out": {
        "index": ["PR"],
        "check-out": ["PR"]
      },
      "collections": {
        "index": ["PR"],
        "album": ["PR"],
        "collection": ["PR"]
      },
      "contacts-lists": {
        "index": ["PR"],
        "contacts": ["PR"],
        "contacts-lists": ["PR"]
      },
      "directory": {
        "index": ["MP"],
        "collections": ["MP"],
        "collections-directory": ["MP"]
      },
      "inventory": {
        "index": ["PR"],
        "check-out": ["PR"]
      },
      "work-in-progress": {
        "index": ["non-user"]
      },
      "jobs": {
        "index": ["MP"],
        "view": ["MP"],
        "form-view": ["MP"],
        "form-edit": ["MP"],
        "shopping-list": ["MP"],
        "loan-form": ["MP"]
      },
      "members": {
        "index": ["PR"]
      },
      "messages": {
        "index": ["MP", "PR"]
      },
      "onboarding": {
        "index": ["non-user", "MP", "PR"]
      },
      "privacy-and-cookie-policy": ["non-user"],
      "profile": {
        "index": ["MP"],
        "profile": ["MP"],
        "agency-profile": ["MP"],
        "brand-profile": ["MP"],
        "collection-profile": ["MP"],
        "album-profile": ["MP"],
        "user-profile": ["MP"]
      },
      "register": {
        "index": ["non-user"],
        "complete": ["non-user"],
        "mp": ["non-user"],
        "pr": ["non-user"]
      },
      "search": {
        "index": ["MP"],
        "looks": ["MP"],
        "user-profile": ["MP"],
        "brand-profile": ["MP"],
        "profile-profile": ["MP"]
      },
      "session": {
        "index": ["non-user"],
        "login": ["non-user"],
        "login-success": ["non-user"],
        "logout": ["MP", "PR"],
        "password-recovery": ["MP", "PR"],
        "new-password": ["MP", "PR"]
      },

      "settings": {
        "index": ["MP", "PR"],
        "personal-mp": ["MP"],
        "select-plan": ["PR"],
        "trial-ended": ["PR"],
        "payment-failed": ["PR"],
        "account-settings": ["PR"],
        "payment-method": ["PR"]
      },
      "terms-and-conditions": ["non-user"],
      "user-generated-content-policy": ["non-user"]
    }
  };

  exports.routeAccessData = routeAccessData;

});