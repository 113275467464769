define('bookalook/routes/admin/brands', ['exports', 'ember', 'bookalook/utils', 'bookalook/routes/admin/functions'], function (exports, Ember, utils, functions) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    actions: {
      toggleSearchable: function toggleSearchable(brand) {
        var check = confirm("Are You Sure?");
        if (check == true) {
          Ember['default'].set(brand, 'searchable', !brand.searchable);
          utils['default'].putAPIJSON('brands/' + brand.id + '/toggleSearchable');
        }
      },
      getBookingsData: function getBookingsData(brand) {
        utils['default'].getAPIJSON('brands/' + brand.id + '/bookings').then(function (res) {
          Ember['default'].set(brand, 'bookingsNumber', res.total);
          var showrooms = brand.showrooms;
          showrooms.forEach(function (showroom) {
            Ember['default'].set(showroom, 'bookingsNumber', res.showrooms[showroom.id]);
          });
        });
      }
    },

    model: function model() {
      return utils['default'].getAPIJSON('admin/brands');
    },

    setupController: function setupController(controller, model) {
      model.forEach(function (brand) {
        //brand.usersNumber = functions.getShowroomsAttrSum(brand.showrooms, 'usersNumber');
        //brand.samplesNumber = functions.getShowroomsAttrSum(brand.showrooms, 'samplesNumber');

        /*var sumBookingNumber = 0;
         brand.showrooms.forEach(showroom => {
            showroom.bookingNumber = functions.getSamplesAttrSum(showroom.samples, 'bookings');
            sumBookingNumber += showroom.bookingNumber;
        });
         brand.bookingNumber = sumBookingNumber;*/

      });
      controller.setProperties({
        brands: model,
        isBrandsPage: true
      });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-admin');
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-admin');
    }
  });

});