define('bookalook/templates/terms-and-conditions/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 48
            },
            "end": {
              "line": 16,
              "column": 85
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("www.bookalook.com");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 62
            },
            "end": {
              "line": 29,
              "column": 118
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Platform Terms of Use");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 68
            },
            "end": {
              "line": 30,
              "column": 122
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Privacy Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 145
            },
            "end": {
              "line": 30,
              "column": 198
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Cookie Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 110
            },
            "end": {
              "line": 31,
              "column": 183
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("User Generated Content Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 122
            },
            "end": {
              "line": 39,
              "column": 154
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Apply now");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 12
            },
            "end": {
              "line": 55,
              "column": 70
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Our Privacy Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 12
            },
            "end": {
              "line": 56,
              "column": 69
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Our Cookie Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 12
            },
            "end": {
              "line": 57,
              "column": 89
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Our User Generated Content Policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 301,
              "column": 38
            },
            "end": {
              "line": 301,
              "column": 77
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(www.bookalook.com)");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 360,
              "column": 41
            },
            "end": {
              "line": 360,
              "column": 78
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("www.bookalook.com");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 427,
              "column": 86
            },
            "end": {
              "line": 427,
              "column": 169
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("www.bookalook.com/privacy-and-cookie-policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 705,
              "column": 41
            },
            "end": {
              "line": 705,
              "column": 78
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("www.bookalook.com");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 750,
              "column": 86
            },
            "end": {
              "line": 750,
              "column": 169
            }
          },
          "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("www.bookalook.com/privacy-and-cookie-policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 935,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/terms-and-conditions/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row static-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 relative");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-wrapper clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 no-padding-left text-left text-uppercase");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Terms & Conditions");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createElement("u");
        var el6 = dom.createTextNode("General");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Please read these Terms and Policies carefully before you start to use our Platform. We recommend that you print a copy of these for future reference.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        By using our Platform, you confirm that you accept these Terms and Policies and that you agree to comply with them regardless of whether you choose to register with us.\n        If you do not agree to these Terms and Policies, you must not use our Platform in any way.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n      Welcome to our membership based platform, ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" (\"");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Platform");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\"). The Platform is provided by Bookalook Ltd, a company registered\n      in England under company number 08437376 and whose registered office is at 4 Lanesborough Court, 1 Fanshaw Street, London N1 6AU, with VAT registration number 177276081.\n      (\"");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Bookalook");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\", \"us\", \"we\" or \"our\" for short). \"You\" and \"your\" means you as the user of our Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n      Bookalook connects registered fashion brands, group companies and PR agencies (together, the “");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Brands");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("”), who establish showroom profiles, with fashion professionals such as curated media, journalists, editors,\n      bloggers and stylists (“");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Fashion Media Professionals");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("”). Brands use the Platform to promote collections and manage the availability of fashion samples (“");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Samples");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("”) by listing specific looks. Fashion\n      Media Professionals can then search for, request and book Samples.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n      We have set out the terms under which we are providing you with access to our Platform and any services we offer from our Platform. These include the terms and conditions that govern:\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Your rights to use and link to our Platform (our \"");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\");");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("How we will use and protect information about you (our \"");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\" and our “");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("”);");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Your obligations when uploading comments or other contributions and content to our Platform (our “");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("”);");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Our terms and conditions in relation to the services we offer to Brands, which are provided to you upon registration with us as a Brand (our \"");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Brand Terms of Business");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\"); and ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          Our terms and conditions in relation to the services we offer to Fashion Media Professionals, which are provided to you upon registration with us as a Fashion Media Professional (our “");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Fashion Media Professional Terms of Business");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("”),\n          (together or individually these may be referred to as our \"");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Terms and Policies");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\").\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n      If you are not yet registered with us as a Brand or Fashion Media Professional but would like to apply, please see \"");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\". Please note that Bookalook reserves\n      the right to accept or decline any such application.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n      We may change our Terms and Policies from time to time, in which case up to date versions of such Terms and Policies will be available via the Platform.\n      You should check these Terms and Policies regularly to ensure that you are happy with any changes. You will be deemed to have accepted any changes to the Terms and Policies after you\n      have been notified of the changes on our Platform and/ or if you continue to access or use the Platform, where the updated Terms and Policies will be available for you to view.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Platform terms of use");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Please read these Platform Terms of Use carefully before you start to use our Platform, as they apply to your use of our Platform. We recommend that you print a copy of\n        these for future reference.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("These Platform Terms of Use refer to the following additional terms which also apply to your use of our Platform:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        By using our Platform, you confirm that you accept these Platform Terms of Use and that you agree to comply with them. If you do not agree to these Platform Terms of Use,\n        you must not use our Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Please note: these Platform Terms of Use only cover your use of our Platform, they DO NOT apply to any services which we may offer to you if you register with us as a Brand or a Fashion Media Professional.\n        Please refer to our Brand Terms of Business or our Fashion Media Professional Terms of Business (as applicable), which are provided to you upon registration as either a Brand or a Fashion Media Professional.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Within these Platform Terms of Use, the phrase “Terms and Policies” refers to any or all of the following policies: our Privacy Policy, our Cookie Policy, our User Generated Content Policy,\n        these Platform Terms and Conditions, our Brand Terms of Business and our Fashion Media Professional Terms of Business.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("1 Use of the Platform");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        1.1 These Platform Terms of Use set out how you may use our Platform. By accessing the Platform, you agree to these Platform Terms of Use. These Platform Terms of Use apply to whatever\n        method you have used to access the Platform, including but not limited to the internet, digital television services and mobile phone.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        1.2 If you do not agree to these Platform Terms of Use, you should not use the Platform. You should read all of the Platform Terms of Use prior to using the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("2. Accessing our Platform");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.1 Access to our Platform is permitted on a temporary basis. We reserve the right to withdraw or amend our Platform (and any services offered on them) without notice.\n        We will not be liable if for any reason our Platform or any part of it is unavailable at any time or for any period.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.2 We update our Platform from time to time and so may change the content at any time without notice to you. We reserve the right to withdraw, vary or suspend the Platform\n        (or any part of it) at any time without notice.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.3 Materials and information posted on our Platform are not intended as advice and should not be relied upon as such. We therefore disclaim all liability and responsibility arising\n        from any reliance placed on such information to the fullest extent permissible by all applicable laws.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.4 You are responsible for making all arrangements necessary to access and view this Platform and should ensure you have up to date anti-virus software on any device\n        from which your access our Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.5 You are responsible for ensuring that all persons accessing our Platform through your internet connection are aware of these Platform Terms of Use.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("3. Misuse of our Platform");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("3.1 You must not misuse our Platform by:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          knowingly introducing viruses, trojans, worms, logic bombs, time bombs, keystroke loggers, spyware, adware or other material, programme or code which adversely\n          affects the operation of any computer software or hardware (or is designed to do so); and/or\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          gaining or attempting to gain unauthorised access to the server on which our Platform is stored or any server, computer or database connected to our Platform; and/or\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          attacking our Platform via a denial-of-service attack or a distributed denial-of service attack.\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.2 Breach of this clause might constitute a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities\n        and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Platform will cease immediately.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("4. Users");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.1 You will need to set up an account and create a user profile associated to a particular Brand or as a Fashion Media Professional in order to access most of the content on the Platform.\n        If your profile is associated to a PR agency rather than a fashion brand or Fashion Media Professional, then you will also be associated to one or more fashion brands as part of your profile.\n        By successfully creating a user profile you will become a “User”.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.2 Registration may require provision of personal and payment details (such as full name and email) and access to a third party account (such as Facebook or LinkedIn).\n        You consent to the use of this information to create your account and acknowledge that we rely on the information provided by you during the registration process\n        being true, accurate, current and complete. You must notify us of any change to such information by updating your account details.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.3 Any personal information that you provide to us in the course of becoming a User or after registration will be held and used in accordance with any consent obtained\n        from you and the terms of our Privacy and Cookies Policy.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.4 We reserve the right to suspend or cancel membership to the Platform and/or withdraw any or all User's rights at any time. We shall not be liable for any loss or damages\n        whatsoever arising from a User’s inability to access any pages on the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.5 You must use your email address and password to access the Platform. It is your responsibility to keep your email address and password secure and you indemnify us for any\n        loss or damage we suffer as a result of unauthorised access to your account. You are solely responsible for the use of your account, irrespective of who is utilising it,\n        whether with or without your permission.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.6 We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed\n        to comply with any of the provisions of any of our Terms and Policies.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.7 If you know or suspect that anyone other than you knows your Platform username or password, you must promptly notify us at info@bookalook.com.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("5. Posting User Generated Content");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.1 If and where the functionality of the Platform allows, Users may post User Generated Content (“UGC”) to the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.2 Any UGC posted by you will be attributed to the username you provide or the username of the social media account you log in with but we will not publish your email address on the Platform unless you have consented to this via\n        your privacy settings.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.3 We reserve the right to refuse to publish any UGC (or remove without notice any previously published UGC) if it does not adhere to our User Generated Content Policy.\n        We shall not be liable for any loss or damages whatsoever arising from such decision to refuse to publish (or to remove previously published) UGC.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.4 We also reserve the right to close your account and/or ban you from being able to post UGC to the Platform if you persistently and/or seriously breach the terms of the User\n        Generated Content Policy.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.5 Views and opinions expressed in UGC submitted by Users or other members of the public are those of the individual submitting the UGC, not those of Bookalook and we accept\n        no responsibility for the content of such UGC.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.6 However, if you find any UGC on the Platform to be in any way offensive, obscene, defamatory, racist, harmful, inaccurate, unlawful, illegal or deceptive in any way,\n        please notify us by emailing us at ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"href","mailto:info@bookalook.com");
        var el6 = dom.createTextNode("info@bookalook.com");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" with the subject heading \"Objectionable Content\". On receipt of your complaint we may remove or block access\n        to the UGC complained of.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("6. Intellectual property");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.1 We expressly reserve all intellectual property rights in and to the Platform and all information, images, and other content displayed on the Platform (“Materials”).\n        Our intellectual property in the Platform includes without limitation any source code, product recipes, usage data, ideas, enhancements, feature requests, suggestions\n        or other information provided by you. You hereby assign all current and future rights to such intellectual property, which we can use for any purpose relating to the Platform or our business.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.2 Unless we expressly agree otherwise, you shall not copy all or any part of the Platform for your own commercial purposes.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.3 Your use of the Platform and the Materials is subject to the following restrictions. You must not:\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("remove any copyright or other proprietary notices contained in the Materials; and/or");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("use any Materials from the Platform in any manner that may infringe any copyright, intellectual property right or proprietary right of us or any third parties; and/or");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            use, or cause others to use, any automated system or software to extract content or data from the Platform (\"screen scraping\"), except in cases where you or any applicable\n            third party has entered into a written licence agreement directly with us that expressly permits such activity; and/or\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            reproduce, modify, display, perform, publish, distribute, disseminate, broadcast, frame (or use any other browser or border environment), communicate to the public or\n            circulate to any third party or exploit the Platform and/or the Materials for any commercial purpose.\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("7. Trade marks and other rights");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.1 We expressly reserve all rights in and to the www.bookalook.com domain name and all related domains and sub-domains, the name \"Bookalook\", our logo, the look and feel of\n        the Platform, service marks, trading names and/or trade marks. Other trade marks, products and company names mentioned on the Platform may be trademarks of their respective\n        owners or licensors and the rights in such marks are reserved to their respective owners or licensors.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("8. Linking to our Platform");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.1 You may link to any page of the Platform, for non-commercial purposes provided that you do so in a way that is fair and legal and which does not damage our reputation\n        or take unfair advantage of it. For the avoidance of doubt, the linking site must not contain any adult or illegal material or any material that is offensive, harassing\n        or otherwise objectionable.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.2 You must not link to our Platform in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not remove or obscure\n        by framing or otherwise, advertisements, any copyright notice, or other information published on the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.3 Our Platform must not be framed or be subjected to any other browser or border environment on any other site.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.4 If you would like to link to our Platform for commercial purposes or any purpose not included above, or if you would like to become a Fashion Media Professional or a Brand, please contact info@bookalook.com.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.5 We reserve the right to withdraw linking permission at any time and without notice.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("9. Privacy, your personal data and cookies");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.1 The privacy of your personal data is important to us. Please see our Privacy Policy and our Cookies Policy for details of how we will process your personal data, where it is provided to us,\n        and how we use cookies.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("10. Third party content and third party websites");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.1 Our Platform may contain advertising submitted by third parties. Such third parties are solely responsible for the content of such advertising and for ensuring that it complies with all\n        relevant legislation and regulations. We do not accept any responsibility for the content of any third party advertising.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.2 Our Platform and/or the Materials may contain links to third party websites (including those of Fashion Media Professional and Brands). If you decide to visit any third party site, you do so at your\n        own risk. We are not responsible or liable directly or indirectly for the content, accuracy or opinions expressed in such websites or the standard of goods or services\n        available through or on such websites. Unless expressly stated otherwise, links do not imply that we are, or our Platform is, affiliated to or associated with such sites.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.3 Our communications with you may contain information sourced from third party websites. Material from a third party site will be marked as such and a link to the source website\n        may be provided. We accept no responsibility or liability for any material supplied by or contained on any third party website which is linked from our communications with you,\n        or any use of personal data by such a third party.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.4 The inclusion of any link in our communications with you does not imply endorsement by us of the linked site. If you decide to access linked third party websites, you do so at your own risk.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.5 Please remember that when you use a link to go from our Platform to another website, our Terms and Polices (including our Privacy Policy and our Cookies Policy) will no longer be applicable.\n        Your browsing and interaction on any other website, including websites which are linked to our Platform, is subject to that website’s own terms and policies. Please read those terms\n        and policies before proceeding.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("11. Our liability");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        11.1 To the fullest extent permissible by law, we exclude and disclaim all warranties, terms, conditions and representations that might otherwise be implied by law in relation to\n        this Platform. In particular, we do not represent or warrant that the Platform will be error-free, free of viruses or other harmful components, or that defects will\n        be corrected. You must take your own precautions in this respect. In any event, we will not be liable for any loss or damage caused by a distributed denial-of-service attack,\n        viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("11.2 We do not accept liability for any failure to maintain the Platform.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        11.3 We do not accept any liability for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty or otherwise (even if foreseeable)\n        arising under or in connection with: (a) use of, or inability to use, the Platform; or (b) use of, or reliance on, any content displayed on the Platform, including the Materials.\n        In particular we will not be liable for loss of profits, sales, business or revenue, business interruption, loss of anticipated savings, loss of business opportunity,\n        goodwill or reputation, or any indirect or consequential loss or damage.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("12. Changes to our Platform Terms of Use");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        We may change these Platform Terms of Use from time to time, in which case an up to date version will be available via the Platform. You should check these Platform Terms\n        of Use regularly to ensure that you are happy with any changes. You will be deemed to have accepted any changes to these Platform Terms of Use after you have been notified\n        of the changes on our Platform and/ or if you continue to access or use the Platform, where the updated Platform Terms of Use will be available for you to view.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("13. Serviced Countries");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        We operate the Platform from the United Kingdom and make no representation that the Platform is appropriate or available in other locations. If you access the Platform\n        from other locations, you are solely responsible for compliance with local laws.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("14. Legal compliance and applicable law");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        The English Courts will have exclusive jurisdiction over any claim or dispute arising from or related to a visit to the Platform (including non-contractual disputes or claims).\n        English Law will apply to these Platform Terms of Use.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","text-uppercase");
        var el5 = dom.createTextNode("15. Contact us");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        If you have any concerns or queries about material which appears on the Platform or if you have questions about your use of the Platform or these Platform Terms of Use please\n        email us at info@bookalook.com. Our postal address for correspondence is Bookalook, 4 Lanesborough Court, 1 Fanshaw Street, London N1 6AU.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createElement("u");
        var el6 = dom.createTextNode("Brands Terms of Business");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Bookalook provides a platform ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" on which you (a fashion brand, group, company or associated PR agency, and defined in these Terms of Business as the “Brand”)\n        can promote collections and manage the availability of fashion samples (“Samples”) by listing specific looks. Fashion-related media professionals can then search for, request and\n        book Samples. The Platform gives you the ability to advertise and communicate your business and services to a selected audience of worldwide fashion-related media professionals.\n        Users of the platform may message, post images and other content, upload files, contacts, professional profiles, text, comments, private messages, information, data, graphics,\n        blog articles, photographs or illustrations.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Please read these Brand Terms of Business (as well as your Membership Contract) carefully before you begin using Bookalook Services as these will apply to your relationship\n        with Bookalook. We recommend that you print a copy of these Brand Terms of Business for future reference. If you do not agree with these Brand Terms of Business, you must not\n        use the Bookalook Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("1. Definitions");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Agreement” means these Brand Terms of Business and the Membership Contract, which together set out the terms and conditions upon which Bookalook shall provide the Bookalook\n        Services to the Brand and which comes into effect on the Effective Date;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Bookalook” means Bookalook Ltd, a company registered in England under company number 08437376 and whose registered office is at 4 Lanesborough Court, 1 Fanshaw Street,\n        London N1 6AU, with VAT registration number 177276081;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Bookalook Services” means the benefits and services a Brand may receive from Bookalook, as set out in the Membership Contract and as otherwise agreed between the parties from time to time;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Booking” means any booking by a Fashion Media Professional for any of the Brand Services, made via the Platform;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Brand Content” means any information, documentation, equipment, software, photographs or other material (which may include the Brand name, logo and any other brand features\n        and Intellectual Property Rights) which may be published on the Page(s) pursuant to this Agreement;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Brand Services” means any information, documentation, equipment, samples, images or any other online or physical goods or services which the Brand advertises, promotes, provides\n        or supplies to Fashion Media Professionals through the use of the Platform;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Charges” means the charges, as set out in the Membership Contract, payable by the Brand in order to receive Bookalook Services, including but not limited to set up fees and membership\n        subscription fees;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Effective Date” means the earlier of (a) the Brand beginning to receive the Bookalook Services; or (b) the date when the last signatory signs the Membership Contract;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Fashion Media Professional” means any fashion professional, including curated media, journalists, editors, bloggers and stylists, who is a registered user of the Platform;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Intellectual Property Rights” means all intellectual property rights on a world-wide basis whether currently in existence or otherwise and whether vested or contingent including\n        (without limitation) copyright (including foreign language translation rights), design rights, database rights, rights in any domain names, registered designs, patents, trade marks,\n        trade names, signs and other designations provided the foregoing are of a proprietary nature and all similar rights whether registered or otherwise (including, without limitation,\n        all extensions, reversions, revivals and renewals thereof). The above shall include, in relation to registerable rights, any applications made or rights to make applications in respect\n        of any such rights;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Membership Contract” means the agreement setting out the key commercial terms of agreement between Bookalook and the Brand;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Page(s)” means the internet page or pages and contents of the Brand’s designated section of the Platform along with any applicable page or pages and contents of the Brand’s own website(s);\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Platform” means the platform at ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(";\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Profile” means any User’s profile on the Platform;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Sample” means any fashion sample made available by the Brand;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Showroom” means any room used by the Brand to display Samples, receive Fashion Media Professionals or organise events; and “User” means any fashion professional (including any stylist,\n        curated media, journalist, editor or blogger), fashion brand, fashion brand group company or PR agency who is a registered user of the Platform, whether as an individual user or as a\n        permitted user via such user’s organisation. For the avoidance of doubt, “User” may include the Brand.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("2. Bookalook Services");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.1 In consideration of payment by the Brand of the Charges and the Brand performing all its other obligations herein and subject to this Agreement, Bookalook shall provide the applicable\n        Bookalook Services to the Brand.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.2 The Bookalook Services will be set out in the Membership Contract and will depend on the number of Showrooms and users of the Brand who will be using the Bookalook Services.\n        The services available to the Brand may include: (a) access to tools for creating and managing inventories of Samples and specific looks across Showrooms; (b) access to a communication\n        platform and networking tool enabling the Brand to connect with Fashion Media Professionals, as well as peers in other fashion brands and PR agencies, and develop their professional network;\n        (c) the ability to create ‘Showroom profiles’; (d) full editing and publishing rights; (e) the ability to manage Bookings; (f) the ability to manage contacts, contact lists and connections;\n        (g) statistical and analytical services; and (h) (for PR agencies only) the ability to create additional accounts for related brands and Showrooms.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.3 If the Brand wishes to cease receiving one or more of the Bookalook Services, the Brand must give Bookalook at least 30 days’ notice in writing and will remain liable to pay any applicable\n        Charges in relation to the notice period. Bookalook will not refund any Charges already paid (for example, any set-up fee or annual memberships paid in advance).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.4 Bookalook reserves the right to make changes to, add or withdraw Bookalook Services and related Charges by giving the Brand at least 30 days’ notice in writing.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.5 Bookalook may, in its sole discretion, change, add or withdraw Bookalook Services and/or the Charges at any time on 30 days’ written notice to the Brand (“Change Notice”).\n        If the Brand, as a result of a Change Notice wishes to change the Bookalook Services it receives, it must provide written notice to Bookalook to this effect within 14 days of the\n        Change Notice in order to prevent the Change Notice coming into effect.  In all other circumstances, if the Brand wishes to make any changes to the Bookalook Services it receives,\n        notice must be given to Bookalook in accordance with Clause 2.3. Bookalook will not refund any Charges already paid (for example, any set-up fee or annual memberships paid in advance).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("3. Brand Obligations");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.1. In consideration of receiving the Bookalook Services, the Brand agrees to pay all applicable Charges to Bookalook and to supply the Brand Services to Fashion Media Professionals\n        to the highest industry standards and in line with any specific terms and conditions set out in this Agreement generally, and particularly in this Clause 3.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.2. The Brand is responsible for ensuring that all Brand Content that it publishes or provides to Bookalook to publish on the Page(s) is accurate, correct and not misleading. The\n        Brand shall ensure that it has the right to use any Brand Content which it uploads or provides to Bookalook to upload to its Page(s).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.3 The Brand is responsible for informing all Fashion Media Professionals with which it communicates of its terms of business (including without limitation the Brand’s privacy policy,\n        delivery policy and returns policy) which will apply to Bookings.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.4. If Bookalook has reasonable grounds to suspect that the Brand has made or makes any direct or indirect attempt to circumvent the Platform, this shall be a material breach of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.5. The Brand shall be directly responsible to a Fashion Media Professional for any failure to fulfil that Fashion Media Professional’s expectations or for any other legal liability which arises\n        in respect of the Brand Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.6. Bookalook shall refer to the Brand any Fashion Media Professional complaint it receives in connection with the Brand, unless such complaint relates directly to Bookalook’s operation\n        of the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("4. Personal information");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.1. Bookalook only uses personal data in accordance with its Privacy Policy (");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("5. Brand Warranties and Indemnity");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.1. The Brand shall provide Bookalook with any Brand Content it reasonably requires to be provided with in order to supply the Bookalook Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.2. The Brand warrants, represents and undertakes that all Brand Content it supplies to Bookalook in connection with this Agreement and/or publishes (or provides to Bookalook\n        for publication) on the Platform will be accurate in all material respects and shall not infringe any other person’s rights (including Intellectual Property Rights) or be defamatory,\n        unlawful, offensive, threatening, or pornographic or otherwise falling below general standards of taste and decency.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.3. The Brand hereby grants Bookalook the right:\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        a. to use and publish the Brand Content in connection with the provision of Bookalook Services; and\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        b. to remove, edit, cut-down or otherwise amend Brand Content published on any Pages, including without limitation where such Brand Content does not, in Bookalook’s opinion\n        comply with the warranties at Clause 5.2, or is otherwise in breach of the terms of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.4. The Brand warrants, represents and undertakes that it will not solicit a User to join another competing platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.5. The Brand warrants, represents and undertakes that it shall comply with all applicable laws and advertising regulations in the marketing, sale and provision of the Brand Services\n        and shall obtain all licences, consents, authorities and insurance it is either necessary or reasonably prudent for the Brand to obtain in respect of all its business activities and\n        personnel (but especially in connection with the provision of Brand Services).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.6. The Brand hereby agrees to indemnify, keep indemnified and hold harmless Bookalook and its officers, directors and employees, from and against any and all claims, demands,\n        obligations, actual or alleged causes of action and lawsuits and all damages, liabilities, fines, judgments, costs (including settlement costs), expenses associated therewith (including the\n        payment of reasonable legal charges and disbursements) and losses (including but not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all\n        interest, penalties and legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses arising out of or in connection with any breach\n        by the Brand of any term of this Agreement or arising out of any action brought by any third party relating to the Brand Services provided (or not provided), or actions (or failure to act),\n        of the Brand or any person (other than Bookalook) acting on its behalf, including, without limitation any action brought in connection with any Brand Content.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.7. This Clause 5 shall survive the termination or expiry of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("6. Payment Terms");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.1. The Brand shall pay all Charges (including without limitation set-up fees and membership fees) payable under this Agreement in accordance with the terms set out herein.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.2. Unless otherwise specified in the Membership Contract: (a) all registration set-up fees shall be payable by the Brand upon registration as a User; (b) all membership fees shall be\n        payable monthly or annually in advance, depending on whether the Brand has opted for a monthly or annual subscription; and (c) any other fees specified in the Membership Contract\n        or agreed between the parties from time to time shall be payable within 14 days of issue by Bookalook of a supporting invoice.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.3. Unless otherwise specified in the Membership Contract, Bookalook shall not be required to provide any of the Bookalook Services to the Brand, including access to the Platform,\n        unless and until Bookalook has received payment in full for all relevant set-up fees and initial membership fees.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.4. Unless otherwise agreed by the parties in writing, payments must be made by electronic bank transfer, direct debit or debit or credit card, using the account details provided to the\n        Brand upon request. Surcharges may apply for certain payment methods. The Brand warrants that there are sufficient funds on the relevant payment account, credit or debit\n        card to pay for all Charges on their due date, including any renewal Charges.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.5. If the Brand fails to pay any Charges due under this Agreement within 7 days of the due date for payment for any reason howsoever arising: (a) all Charges shall immediately\n        become due and payable; and (b) Bookalook may, without prejudice to any other right or remedy that it may have, suspend its provision of the Bookalook Services or any part thereof\n        and/or suspend the Brand’s membership of the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.6. Bookalook reserves the right to charge interest on all amounts payable to Bookalook from the Brand which are not paid by the relevant due date at the annual rate of 2% above\n        the official base rate from time to time of the Bank of England.  Such interest will accrue on a daily basis from the date on which payment became due up to the date on which Bookalook\n        receives the full outstanding amount together with all accrued interest.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.7. Charges are not refundable under any circumstances, including without limitation if Bookalook modifies or withdraws any of the Bookalook Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.8. All Charges are exclusive of value added tax and any other sales tax (unless otherwise specified).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("7. Term of the Agreement");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.1. Subject to earlier termination in accordance with this Agreement, the Brand’s subscription to the Bookalook Services shall renew automatically (“Subscription Renewal”)\n        commencing the day after the end of the subscription term specified in the Membership Contract (“Subscription Term”).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.2. Charges for Subscription Renewal will be billed automatically for the full amount of the subscription fee specified in the Membership Contract on or around the date on which the\n        Subscription Term expires. If the Subscription Payment fails, Bookalook may suspend its provision of the Bookalook Services or any part thereof and/or suspend the Brand’s\n        membership of the Platform. If the Brand does not pay its Subscription Renewal within 30 days of the Subscription Term expiration, the Brand’s membership of the Platform may be\n        terminated without notice.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.3 Either party shall be entitled to terminate this Agreement at any time by giving the other party no less than 30 days’ notice in writing.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.4. Either party shall be entitled to terminate this Agreement with immediate effect by written notice to the other if:\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          The other party commits a material breach of any of the provisions of this Agreement and either that breach is not capable or, in the case of a breach capable of remedy,\n          that party fails to remedy the same within 7 days after receipt of a written notice giving full particulars of the breach and requiring it to be remedied; or\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          The other party is in persistent non-material breach (whether remediable or not) of any of the provisions of this Agreement;\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          An encumbrancer takes possession or a receiver is appointed over any of the property or assets of that other party;\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          That other party makes any voluntary arrangement with its creditors or becomes subject to an administration order;\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          That other party goes into liquidation (except for the purposes of amalgamation or reconstruction and in such manner that the company resulting there from effectively\n          agrees to be bound by or assume the obligations imposed on that other party under this Agreement);\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          Anything analogous to any of the foregoing under the law of any jurisdiction occurs in relation to that other party; or\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("The other party ceases, or threatens to cease, to carry on business.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("8. Confidentiality");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.1. Each party acknowledges that, whether by virtue of and in the course of this Agreement or otherwise, it may receive or otherwise become aware of information relating to the other\n        party, their marketing plans, their clients, customers, businesses, business plans, finances, technology or affairs, which information is proprietary and confidential to the other party\n        (“Confidential Information”).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.2. Each party undertakes to maintain and procure the maintenance of the confidentiality of Confidential Information at all times and to keep and procure the keeping of all Confidential\n        Information secure and protected against theft, damage, loss or unauthorised access, and not at any time, whether during the term of this Agreement or at any time thereafter, without\n        the prior written consent of the owner of the Confidential Information, directly or indirectly, use, disclose, exploit, copy or modify any Confidential Information, or authorise or permit\n        any third party to do the same, other than for the sole purpose of the performance of its rights and obligations hereunder.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.3. The terms of and obligations imposed by this Clause 8 shall not apply to any Confidential Information which:\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("At the time of receipt by the recipient is in the public domain;");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Subsequently comes into the public domain through no fault of the recipient, its officers, employees or agents;");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Is lawfully received by the recipient from a third party on an unrestricted basis; or");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Is already known to the recipient before receipt hereunder.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.4. The recipient may disclose Confidential Information in confidence to a professional adviser of the recipient or if it is required to do so by law, regulation or order of a competent\n        authority.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.5. This Clause 8 shall survive the termination or expiry of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("9. Liability");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.1. Bookalook has no liability whatsoever for any act or omission of a User, arising from or in connection with the use of the Platform, including the fulfilment of any Bookings, or\n        related transactions or agreements.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.2. Bookalook makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to any\n        Samples, specific looks or Bookings, or the related pictures, photographs, statements or other information provided via the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.3. The Brand acknowledges that Bookalook is not required to verify or undertake any due diligence on any Profiles, Samples, specific looks or Bookings. Whilst Bookalook may review\n        Profiles, Samples, specific looks and Bookings, the Brand acknowledges and accepts that Bookalook is not responsible for monitoring or verifying any related information or communications.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.4. Bookalook shall not be responsible for and accepts no liability whatsoever in relation to: (i) the delivery or return of any Samples booked on the Platform, or (ii) any loss or damage\n        to any Samples booked on the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.5. Subject to Clause 9.6, Bookalook’s maximum aggregate liability under or in connection with this Agreement, or any collateral contract, whether in contract, tort (including negligence)\n        or otherwise, shall in no circumstances exceed the Charges payable to Bookalook hereunder. Bookalook shall not be liable for any loss of profits, sales, business or revenue,\n        business interruption, loss of anticipated savings, loss of business opportunity, goodwill or reputation, or any indirect or consequential loss or damage (even if Bookalook\n        has been advised by the Brand of the possibility of such loss or damage).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.6. Nothing in this Agreement shall exclude or in any way limit Bookalook’s liability for fraud or for death or personal injury caused by its negligence or for its wilful default or any\n        other liability to the extent the same may not be excluded or limited as a matter of law.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.7. This Clause 9 shall survive the termination or expiry of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("10. Miscellaneous");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.1. All rights to the Platform and the content on it (save for Brand Content), and all other Intellectual Property Rights belonging to or licensed to Bookalook, remain vested in\n        Bookalook at all times.  Nothing in this Agreement shall give the Brand any rights in respect of any such Intellectual Property Rights or of the goodwill associated therewith.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.2. In the event of a change of control or senior management of the Brand, the Brand must bring the existence and terms of this Agreement to the new owner or manager’s attention\n        and inform Bookalook of the relevant new personnel’s contact details.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.3. Any notice, consent or other communication sent by the Brand to Bookalook shall be sent to info@bookalook.com or to 4 Lanesborough Court, 1 Fanshaw Street, London N1 6AU.\n        Bookalook will notify the Brand of any change of details by publishing new details on the Platform. Any notice, invoice, consent or other communication sent by Bookalook to the\n        Brand shall be sent to the email or postal address that the Brand submitted to the Platform upon registration, or as notified to Bookalook from time to time. Notices sent by registered\n        post or recorded delivery shall be deemed to be served three working days following the day of posting.  In all other cases, notices are deemed to be served on the day when they are\n        actually received. A consent, notice, invoice or other communication under this Agreement is effective if it is sent as an electronic communication (having the meaning given to that term\n        in the Electronic Communications Act 2000 and the Electronic Signatures Regulations 2002) unless required to be physically delivered under law.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.4. The relationship of the parties is that of independent contractors dealing at arm’s length.  Except as otherwise stated in this Agreement, nothing in this Agreement shall\n        constitute the parties as partners, joint venturers or co-owners.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.5. The Brand may not assign, transfer, charge, sub-contract or otherwise deal with any part or all of this Agreement without the prior written consent of Bookalook (not to be\n        unreasonably withheld, conditioned or delayed). Bookalook may assign, transfer, charge, sub-contract or otherwise deal with any part or all of this Agreement by giving the Brand notice in writing.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.6. A person who is not a party to this Agreement has no right to enforce any term of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.7. The failure of either party to enforce or to exercise at any time or for any period of time any term of or any right pursuant to this Agreement does not constitute, and shall not be\n        construed as, a waiver of such term or right and shall in no way affect that party’s right later to enforce or to exercise it.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.8. If any term of this Agreement is found to be illegal, invalid or unenforceable under any applicable law, such term shall, insofar as it is severable from the remaining terms, be\n        deemed omitted from this Agreement and shall in no way affect the legality, validity or enforceability of the remaining terms.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.9. This Agreement contains all the terms agreed between the parties regarding its subject matter and supersedes any prior agreement, understanding or arrangement between the\n        parties, whether oral or in writing.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.10. Bookalook operates the Platform from the United Kingdom and makes no representation that the Platform is appropriate or available in other locations. If the Brand\n        accesses the Platform from other locations, it is solely responsible for compliance with local laws.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        10.11. This Agreement shall be governed and interpreted in accordance with the laws of England and Wales.  The parties submit to the exclusive jurisdiction of the English courts to\n        settle any dispute arising out of or in connection with this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createElement("u");
        var el6 = dom.createTextNode("Stylists Terms of Business");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Bookalook provides a platform (www.bookalook.com) on which you (a fashion professional, including curated media, journalist, editor, blogger or stylist, and defined in these Terms of\n        Business as the “Fashion Media Professional”) can view and make bookings for fashion samples. Users of the platform may message, post images and other content, upload files, contacts,\n        professional profiles, text, comments, private messages, information, data, graphics, blog articles, photographs, images or illustrations.\n        Please read these Fashion Media Professional Terms of Business carefully before you begin using the Bookalook Services as these will apply to your relationship with Bookalook.\n        We recommend that you print a copy of these Fashion Media Professional Terms of Business for future reference. If you do not agree with these Fashion Media Professional Terms of Business,\n        you must not use the Bookalook Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        Please read these Fashion Media Professional Terms of Business carefully before you begin using the Bookalook Services as these will apply to your relationship with Bookalook.\n        We recommend that you print a copy of these Fashion Media Professional Terms of Business for future reference. If you do not agree with these Fashion Media Professional Terms of Business,\n        you must not use the Bookalook Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("1. Definitions");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Agreement” means these Fashion Media Professional Terms of Business which set out the terms and conditions upon which Bookalook shall provide the Bookalook Services to the\n        Fashion Media Professional, and which comes into effect on the date on which the Fashion Media Professional registers as a User on the Platform and ticks the box to confirm its\n        acceptance of this Agreement;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Bookalook” means Bookalook Ltd, a company registered in England under company number 08437376 and whose registered office is at 4 Lanesborough Court, 1 Fanshaw\n        Street, London N1 6AU, with VAT registration number 177276081;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Bookalook Services” means the benefits and services a Fashion Media Professional may receive from Bookalook, as set out in more detail in Clause 2 and as otherwise provided by\n        Bookalook via the Platform from time to time;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Booking” means any booking by the Fashion Media Professional of any Sample;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Brand” means any fashion brand or associated PR agency who is a registered user of the Platform;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Fashion Media Professional Content” means any information, documentation, equipment, software, photographs or other material (which may include the Fashion Media Professional\n        name, logo and any other brand features and Intellectual Property Rights) which may be published on the Page(s) pursuant to this Agreement;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Intellectual Property Rights” means all intellectual property rights on a world-wide basis whether currently in existence or otherwise and whether vested or contingent including\n        (without limitation) copyright (including foreign language translation rights), design rights, database rights, rights in any domain names, registered designs, patents, trade marks, trade\n        names, signs and other designations provided the foregoing are of a proprietary nature and all similar rights whether registered or otherwise (including, without limitation, all\n        extensions, reversions, revivals and renewals thereof). The above shall include, in relation to registerable rights, any applications made or rights to make applications in respect of any\n        such rights;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Page(s)” means the internet page or pages and contents of the Fashion Media Professional’s designated section of the Platform along with any applicable page or pages\n        and contents of the Fashion Media Professional’s own website(s);\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Platform” means the platform at ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(";\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Profile” means any User’s profile on the Platform;\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “Sample” means any fashion sample made available by a Brand via the Platform; and\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        “User” means any fashion professional (including any stylist, curated media, journalist, editor or blogger), fashion brand, fashion brand group company or PR agency who is a\n        registered user of the Platform, whether as an individual user or as a permitted user via such user’s organisation. For the avoidance of doubt, “User” may include  the Fashion Media\n        Professional.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("2. Bookalook Services");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.1. As a registered user of the Platform, the Fashion Media Professional will have access to a number of services including without limitation: (a) access to a communication platform and\n        networking tool enabling the Fashion Media Professional to connect with other stylists, fashion journalists, bloggers, curated media, fashion brands and PR agencies, and develop\n        their professional network; (b) the ability to search for and view Samples and specific looks and to place Bookings across the Platform rather than on a per-Brand basis; (c) the ability to\n        locate favourite fashion brands and discover new brands and designers; and (d) access to a straightforward process for requesting, booking and returning Samples.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        2.2. Bookalook reserves the right to make changes to, add or withdraw the Bookalook Services at any time.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("3. Fashion Media Professional Obligations");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.1. The Fashion Media Professional is responsible for ensuring that all Fashion Media Professional Content that it publishes or provides to Bookalook to publish on the Page(s) is accurate,\n        correct and not misleading. The Fashion Media Professional shall ensure that it has the right to use any photographs which it uploads or provides to Bookalook to upload to its Page(s).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.2. If Bookalook has reasonable grounds to suspect that the Fashion Media Professional has made or makes any direct or indirect attempt to circumvent the Platform, for example\n        without limitation, by asking Brands to provide Samples otherwise than through the Platform, this shall be a material breach of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.3. The Fashion Media Professional shall comply with any terms of business notified to it by a Brand in relation to a Booking and shall be directly responsible to the relevant Brand for\n        any failure to comply with such terms of business, including without limitation in relation to loss of or damage to Samples.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        3.4. Bookalook shall refer to the Fashion Media Professional any Brand complaints it receives in connection with the Fashion Media Professional, unless such complaint relates\n        directly to Bookalook’s operation of the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("4. Personal information");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        4.1. Bookalook only uses personal data in accordance with its Privacy Policy (");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("5. Fashion Media Professional Warranties and Indemnity");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.1. The Fashion Media Professional shall provide Bookalook with any Fashion Media Professional Content it reasonably requires to be provided with in order to supply the\n        Bookalook Services.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.2. The Fashion Media Professional warrants, represents and undertakes that all Fashion Media Professional Content it supplies to Bookalook in connection with this Agreement\n        and/or publishes (or provides to Bookalook for publication) on the Platform will be accurate in all material respects and shall not infringe any other person’s rights (including Intellectual\n        Property Rights) or be defamatory, unlawful, offensive, threatening, or pornographic or otherwise falling below general standards of taste and decency.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.3. The Fashion Media Professional hereby grants Bookalook the right:\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("a. to use and publish the Fashion Media Professional Content in connection with the provision of Bookalook Services; and");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          b. to remove, edit, cut-down or otherwise amend Fashion Media Professional Content published on any Pages, including without limitation where such Fashion Media Professional\n          Content does not, in Bookalook’s opinion comply with the warranties at Clause 5.2, or is otherwise in breach of the terms of this Agreement.\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.4. The Fashion Media Professional warrants, represents and undertakes that it will not solicit a User to join another competing platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.5. The Fashion Media Professional warrants, represents and undertakes that it shall comply with all applicable laws and advertising regulations, and shall obtain all licences,\n        consents, authorities and insurance it is either necessary or reasonably prudent for the Fashion Media Professional to obtain, in respect of all its business activities and personnel.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.6. The Fashion Media Professional hereby agrees to indemnify, keep indemnified and hold harmless Bookalook and its officers, directors and employees, from and against any and all\n        claims, demands, obligations, actual or alleged causes of action and lawsuits and all damages, liabilities, fines, judgments, costs (including settlement costs), expenses associated\n        therewith (including the payment of reasonable legal charges and disbursements) and losses (including but not limited to any direct, indirect or consequential losses, loss of profit, loss of\n        reputation and all interest, penalties and legal costs (calculated on a full indemnity basis)) and all other reasonable professional costs and expenses arising out of or in connection with\n        any breach by the Fashion Media Professional of any term of this Agreement or arising out of any action brought by any third party relating to the actions (or failure to act), of the Fashion\n        Media Professional or any person (other than Bookalook) acting on its behalf, including, without limitation any action brought in connection with any Fashion Media Professional Content.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        5.7. This Clause 5 shall survive the termination or expiry of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("6. Termination of the Agreement and suspension of services");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.1. Bookalook may terminate this Agreement at any time immediately upon giving written notice to the Fashion Media Professional. Bookalook may also suspend the Fashion Media\n        Professional’s Profile and use of the Platform at any time on a temporary or permanent basis.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        6.2. The Fashion Media Professional may terminate its Platform account and this Agreement at any time by sending an email to info@bookalook.com, requesting permanent deletion of its Profile.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("7. Confidentiality");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.1. Each party acknowledges that, whether by virtue of and in the course of this Agreement or otherwise, it may receive or otherwise become aware of information relating to the other\n        party, their marketing plans, their clients, customers, businesses, business plans, finances, technology or affairs, which information is proprietary and confidential to the other party\n        (“");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("strong");
        var el6 = dom.createTextNode("Confidential Information");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("”).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.2. Each party undertakes to maintain and procure the maintenance of the confidentiality of Confidential Information at all times and to keep and procure the keeping of all Confidential\n        Information secure and protected against theft, damage, loss or unauthorised access, and not at any time, whether during the term of this Agreement or at any time thereafter, without\n        the prior written consent of the owner of the Confidential Information, directly or indirectly, use, disclose, exploit, copy or modify any Confidential Information, or authorise or permit\n        any third party to do the same, other than for the sole purpose of the performance of its rights and obligations hereunder.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.3. The terms of and obligations imposed by this Clause 7 shall not apply to any Confidential Information which:\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("At the time of receipt by the recipient is in the public domain;");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Subsequently comes into the public domain through no fault of the recipient, its officers, employees or agents;");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Is lawfully received by the recipient from a third party on an unrestricted basis; or");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Is already known to the recipient before receipt hereunder.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.4. The recipient may disclose Confidential Information in confidence to a professional adviser of the recipient or if it is required to do so by law, regulation or order of a competent authority.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        7.5. This Clause 7 shall survive the termination or expiry of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("8. Liability");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.1. Bookalook has no liability whatsoever for any act or omission of a User, arising from or in connection with the use of the Platform, including the fulfilment of any Bookings, or\n        related transactions or agreements.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.2. Bookalook makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to any\n        Samples, specific looks or Bookings, or the related pictures, photographs, statements or other information provided via the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.3. The Fashion Media Professional acknowledges that Bookalook is not required to verify or undertake any due diligence on any Profiles, Samples, specific looks or Bookings. Whilst\n        Bookalook may review Profiles, Samples, specific looks and Bookings, the Fashion Media Professional acknowledges and accepts that Bookalook is not responsible for monitoring or\n        verifying any related information or communications.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.4. Bookalook shall not be responsible for and accepts no liability whatsoever in relation to: (i) the delivery or return of any Samples booked on the Platform, (ii) the quality of any\n        Samples booked on the Platform, or (iii) any loss or damage to any Samples booked on the Platform.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.5. Subject to Clause 8.6, Bookalook’s maximum aggregate liability under or in connection with this Agreement, or any collateral contract, whether in contract, tort (including\n        negligence) or otherwise, shall in no circumstances exceed £100. Bookalook shall not be liable for any loss of profits, sales, business or revenue, business interruption, loss of\n        anticipated savings, loss of business opportunity, goodwill or reputation, or any indirect or consequential loss or damage (even if Bookalook has been advised by the Fashion Media\n        Professional of the possibility of such loss or damage).\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.6. Nothing in this Agreement shall exclude or in any way limit Bookalook’s liability for fraud or for death or personal injury caused by its negligence or for its wilful default or any\n        other liability to the extent the same may not be excluded or limited as a matter of law.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        8.7. This Clause 8 shall survive the termination or expiry of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("9. Miscellaneous");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.1. All rights to the Platform and the content on it (save for Fashion Media Professional Content), and all other Intellectual Property Rights belonging to or licensed to Bookalook,\n        remain vested in Bookalook at all times.  Nothing in this Agreement shall give the Fashion Media Professional any rights in respect of any such Intellectual Property Rights or of the\n        goodwill associated therewith.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.2. In the event of a change of control or senior management of the Fashion Media Professional, the Fashion Media Professional must bring the existence and terms of this Agreement\n        to the new owner or manager’s attention and inform Bookalook of the relevant new personnel’s contact details.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.3. Any notice, consent, or other communication sent by the Fashion Media Professional to Bookalook shall be sent to ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"href","mailto:info@bookalook.com");
        var el6 = dom.createTextNode("info@bookalook.com");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" or to 4 Lanesborough Court, 1 Fanshaw\n        Street, London N1 6AU. Bookalook will notify the Fashion Media Professional of any change of details by publishing new details on the Platform. Any notices sent by Bookalook to the\n        Fashion Media Professional shall be sent to the email or postal address that the Fashion Media Professional submitted to the Platform upon registration, or as notified to Bookalook\n        from time to time. Notices sent by registered post or recorded delivery shall be deemed to be served three working days following the day of posting.  In all other cases, notices are\n        deemed to be served on the day when they are actually received. A consent, notice, invoice or other communication under this Agreement is effective if it is sent as an electronic\n        communication (having the meaning given to that term in the Electronic Communications Act 2000 and the Electronic Signatures Regulations 2002) unless required to be physically\n        delivered under law.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.4. The relationship of the parties is that of independent contractors dealing at arm’s length.  Except as otherwise stated in this Agreement, nothing in this Agreement shall\n        constitute the parties as partners, joint venturers or co-owners.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.5. The Fashion Media Professional may not assign, transfer, charge, sub-contract or otherwise deal with any part or all of this Agreement. Bookalook may assign, transfer,\n        charge, sub-contract or otherwise deal with any part or all of this Agreement by giving the Fashion Media Professional notice in writing.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.6. A person who is not a party to this Agreement has no right to enforce any term of this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.7. The failure of either party to enforce or to exercise at any time or for any period of time any term of or any right pursuant to this Agreement does not constitute, and shall not be\n        construed as, a waiver of such term or right and shall in no way affect that party’s right later to enforce or to exercise it.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.8. If any term of this Agreement is found to be illegal, invalid or unenforceable under any applicable law, such term shall, insofar as it is severable from the remaining terms, be\n        deemed omitted from this Agreement and shall in no way affect the legality, validity or enforceability of the remaining terms.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.9. This Agreement contains all the terms agreed between the parties regarding its subject matter and supersedes any prior agreement, understanding or arrangement between the\n        parties, whether oral or in writing.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.10. Bookalook operates the Platform from the United Kingdom and makes no representation that the Platform is appropriate or available in other locations. If the Fashion\n        Media Professional accesses the Platform from other locations, it is solely responsible for compliance with local laws.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        9.11. This Agreement shall be governed and interpreted in accordance with the laws of England and Wales.  The parties submit to the exclusive jurisdiction of the English courts to\n        settle any dispute arising out of or in connection with this Agreement.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 3]);
        var element1 = dom.childAt(element0, [13]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element0, [25]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createMorphAt(element2,3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element0, [15]),1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element3, [5]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element0, [155]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element0, [185]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element0, [221]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element0, [351]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element0, [377]),1,1);
        return morphs;
      },
      statements: [
        ["block","link-to",["index"],[],0,null,["loc",[null,[16,48],[16,97]]]],
        ["block","link-to",["terms-and-conditions"],[],1,null,["loc",[null,[29,62],[29,130]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],2,null,["loc",[null,[30,68],[30,134]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],3,null,["loc",[null,[30,145],[30,210]]]],
        ["block","link-to",["user-generated-content-policy"],[],4,null,["loc",[null,[31,110],[31,195]]]],
        ["block","link-to",["register"],[],5,null,["loc",[null,[39,122],[39,166]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],6,null,["loc",[null,[55,12],[55,82]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],7,null,["loc",[null,[56,12],[56,81]]]],
        ["block","link-to",["user-generated-content-policy"],[],8,null,["loc",[null,[57,12],[57,101]]]],
        ["block","link-to",["index"],[],9,null,["loc",[null,[301,38],[301,89]]]],
        ["block","link-to",["index"],[],10,null,["loc",[null,[360,41],[360,90]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],11,null,["loc",[null,[427,86],[427,181]]]],
        ["block","link-to",["index"],[],12,null,["loc",[null,[705,41],[705,90]]]],
        ["block","link-to",["privacy-and-cookie-policy"],[],13,null,["loc",[null,[750,86],[750,181]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
    };
  }()));

});