define('bookalook/templates/inventory/partials/barcode-sample-row', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 6
              },
              "end": {
                "line": 19,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/inventory/partials/barcode-sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","unbound",[["get","sample.colour2URL",["loc",[null,[18,32],[18,49]]]]],[],["loc",[null,[18,22],[18,51]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 6
              },
              "end": {
                "line": 22,
                "column": 6
              }
            },
            "moduleName": "bookalook/templates/inventory/partials/barcode-sample-row.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","unbound",[["get","sample.colour3URL",["loc",[null,[21,32],[21,49]]]]],[],["loc",[null,[21,22],[21,51]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "bookalook/templates/inventory/partials/barcode-sample-row.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","gray-light-span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3,"class","list-block");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("img");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","show");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","show");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","show");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","td_small");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0]);
          var element3 = dom.childAt(element2, [3, 1]);
          var element4 = dom.childAt(element2, [5, 1]);
          var element5 = dom.childAt(element4, [1, 0]);
          var element6 = dom.childAt(element2, [7]);
          var morphs = new Array(12);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1, 1]),0,0);
          morphs[1] = dom.createMorphAt(element3,0,0);
          morphs[2] = dom.createMorphAt(element3,2,2);
          morphs[3] = dom.createAttrMorph(element5, 'src');
          morphs[4] = dom.createMorphAt(element4,3,3);
          morphs[5] = dom.createMorphAt(element4,4,4);
          morphs[6] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element2, [9, 1]),0,0);
          morphs[10] = dom.createMorphAt(dom.childAt(element2, [11, 1]),0,0);
          morphs[11] = dom.createMorphAt(dom.childAt(element2, [13]),1,1);
          return morphs;
        },
        statements: [
          ["inline","unbound",[["get","sample.itemLabel",["loc",[null,[7,24],[7,40]]]]],[],["loc",[null,[7,14],[7,42]]]],
          ["inline","plus-one",[["get","sampleIndex",["loc",[null,[12,45],[12,56]]]]],[],["loc",[null,[12,34],[12,58]]]],
          ["content","sampleGroup.all.length",["loc",[null,[12,59],[12,85]]]],
          ["attribute","src",["concat",[["subexpr","unbound",[["get","sample.colour1URL",["loc",[null,[16,30],[16,47]]]]],[],["loc",[null,[16,20],[16,49]]]]]]],
          ["block","if",[["get","sample.colour2URL",["loc",[null,[17,12],[17,29]]]]],[],0,null,["loc",[null,[17,6],[19,13]]]],
          ["block","if",[["get","sample.colour3URL",["loc",[null,[20,12],[20,29]]]]],[],1,null,["loc",[null,[20,6],[22,13]]]],
          ["inline","unbound",[["get","sample.fabric1Label",["loc",[null,[26,33],[26,52]]]]],[],["loc",[null,[26,23],[26,54]]]],
          ["inline","unbound",[["get","sample.fabric2Label",["loc",[null,[27,33],[27,52]]]]],[],["loc",[null,[27,23],[27,54]]]],
          ["inline","unbound",[["get","sample.fabric3Label",["loc",[null,[28,33],[28,52]]]]],[],["loc",[null,[28,23],[28,54]]]],
          ["inline","unbound",[["get","sample.barcode",["loc",[null,[31,20],[31,34]]]]],[],["loc",[null,[31,10],[31,36]]]],
          ["inline","my-showroom",[["get","sample.showroom",["loc",[null,[34,24],[34,39]]]],["get","route",["loc",[null,[34,40],[34,45]]]]],[],["loc",[null,[34,10],[34,47]]]],
          ["inline","input",[],["id",["subexpr","@mut",[["get","sample.checkId",["loc",[null,[38,20],[38,34]]]]],[],[]],"type","checkbox","class","blankCheckbox","checked",["subexpr","@mut",[["get","sample.checked",["loc",[null,[41,20],[41,34]]]]],[],[]],"aria-label","","disabled",["subexpr","@mut",[["get","sample.disabled",["loc",[null,[43,20],[43,35]]]]],[],[]]],["loc",[null,[37,4],[44,6]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/inventory/partials/barcode-sample-row.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Sample row | New booking step 2 ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","sample.visible",["loc",[null,[2,6],[2,20]]]]],[],0,null,["loc",[null,[2,0],[47,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});