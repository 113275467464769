define('bookalook/tests/routes/settings/payment-failed.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/settings/payment-failed.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/settings/payment-failed.js should pass jshint.\nroutes/settings/payment-failed.js: line 4, col 41, Missing semicolon.\n\n1 error');
  });

});