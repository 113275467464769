define('bookalook/routes/session/password-recovery', ['exports', 'ember', 'bookalook/utils'], function (exports, Ember, utils) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    RECOVERY_WARNING_ID: '.session-warning',
    CONTAINER_ID: '.recovery-form',
    SUCCESS_ID: '.session-action-complete',
    BUTTON_ID: '#recovery-button',

    actions: {
      recovery: function recovery(email) {
        this.onRecovery(email);
      }
    },
    setupController: function setupController(controller, model) {
      $('.beta_topbar ').hide();
      $('.main_menu_wrapper').hide();
      $('#test_footer').hide();
    },

    onRecovery: function onRecovery(email) {
      Ember['default'].$(this.RECOVERY_WARNING_ID).animate({ opacity: 0 });
      if (email) {
        var $ = Ember['default'].$;

        $(this.BUTTON_ID).button('loading');

        this.controller.set('resetEmail', email);
        utils['default'].postAPIJSON('users/resetting/send-email', { username: email }).then($.proxy(this.onSendMailSuccess, this), $.proxy(this.onSendMailError, this));
      } else {
        this._showWarning();
      }
    },

    onSendMailSuccess: function onSendMailSuccess() {
      var _this = this;

      Ember['default'].$(this.CONTAINER_ID).fadeOut(function () {
        return Ember['default'].$(_this.SUCCESS_ID).fadeIn();
      });
    },

    onSendMailError: function onSendMailError() {
      Ember['default'].$(this.BUTTON_ID).button('reset');
      this._showWarning();
    },

    _showWarning: function _showWarning() {
      Ember['default'].$(this.RECOVERY_WARNING_ID).animate({ opacity: 1 });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-recovery');
      $('.beta_topbar ').hide();
      $('.main_menu_wrapper').hide();
      $('#test_footer').hide();
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-recovery');
    }
  });

});