define('bookalook/tests/routes/jobs/form-view.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/jobs/form-view.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/jobs/form-view.js should pass jshint.\nroutes/jobs/form-view.js: line 13, col 92, Missing semicolon.\nroutes/jobs/form-view.js: line 14, col 100, Missing semicolon.\nroutes/jobs/form-view.js: line 19, col 74, Missing semicolon.\n\n3 errors');
  });

});