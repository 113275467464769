define('bookalook/routes/chat/index', ['exports', 'ember', 'bookalook/utils', 'bookalook/mixins/user-validator-mixin', 'bookalook/config/environment'], function (exports, Ember, utils, UserValidatorMixin, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {

    setupController: function setupController(controller) {

      window.MP = {
        data: {
          appId: config['default'].MaqpieAppId, // your app id. it's same for each user. required
          user: {
            username: Ember['default'].maqpie.username,
            vendorUserId: Ember['default'].maqpie.email
          },
          userHash: "any-hash" // required
        },
        settings: {
          styles: {
            headerColor: "#4545ef", // a primary chat color
            scrollColor: "#6284b5", // a color of the chat scroll
            fontFamily: "futura-pt"
          },
          position: {
            left: "15px"
          },
          mode: 'embedded',
          demoMode: 0
        }
      };

      (function () {
        var loadWidget = function loadWidget() {
          // load Maqpie widget - inserts <script> tag before the first <script> tag in <body>
          var d = document;
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.maqpie.com/widget/v1.0';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        var w = window;
        w.MP._queue = w.MP._queue || [];
        var m = ['renderLargeView', 'showLargeView', 'destroyLargeView', 'subscribe', 'unsubscribe'];
        var f = function f(method) {
          return function () {
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            w.MP._queue.push(args);
          };
        };
        for (var i = 0; i < m.length; i += 1) {
          w.MP[m[i]] = f(m[i]);
        }
        if (w.attachEvent) {
          // if page has already been loaded just call loadWidget function
          w.attachEvent('onload', loadWidget);
        } else {
          w.addEventListener('load', loadWidget, false);
        }

        var re = new RegExp("^(\/settings\/payment-failed|\/settings\/trial-ended)$");
        if (!re.test(location.pathname)) {
          loadWidget();
          Ember['default'].maqpie.loaded = true;
        }
      })();

      this.send('loadMaximizedChat');
    }

  });

});