define('bookalook/initializers/component-router-injector', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    // Injects all Ember components with a router object:
    application.inject('component', 'router', 'router:main');

    /* Needed to serializa arrays */
    DS['default'].ArrayTransform = DS['default'].Transform.extend({
      deserialize: function deserialize(serialized) {
        return Ember['default'].typeOf(serialized) === "array" ? serialized : [];
      },

      serialize: function serialize(deserialized) {
        var type = Ember['default'].typeOf(deserialized);
        if (type === 'array') {
          return deserialized;
        } else if (type === 'string') {
          return deserialized.split(',').map(function (item) {
            return Ember['default'].$.trim(item);
          });
        } else {
          return [];
        }
      }
    });

    var inflector = Ember['default'].Inflector.inflector;
    inflector.irregular('sampleStatus', 'sampleStatus');

    application.register("transform:array", DS['default'].ArrayTransform);
  }

  exports['default'] = {
    name: 'component-router-injector',
    initialize: initialize
  };

});