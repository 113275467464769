define('bookalook/models/showroom', ['exports', 'ember', 'ember-data', 'bookalook/mixins/model-validator'], function (exports, Ember, DS, Validator) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    street: DS['default'].attr('string'),
    street2: DS['default'].attr('string'),
    country: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    zipCode: DS['default'].attr('string'),
    region: DS['default'].attr('string'),
    telephone: DS['default'].attr('string'),
    peopleNumber: DS['default'].attr('number'),
    isAgency: DS['default'].attr('boolean'),
    ddtFormat: DS['default'].attr('string'),
    shippingCompanyAddress: DS['default'].attr('string'),
    legalAddress: DS['default'].attr('string'),
    vat: DS['default'].attr('string'),
    ddtNumber: DS['default'].attr('string'),

    users: DS['default'].hasMany('user', { async: true, inverse: 'showroom' }),
    brands: DS['default'].hasMany('brand', { async: true, inverse: 'showrooms' }),
    brandsHQ: DS['default'].hasMany('brand', { async: true, inverse: 'HQ' }),
    areasAvailables: DS['default'].hasMany('areasAvailable', { async: true }),
    agency: DS['default'].belongsTo('agency', { async: true }),

    timezone: DS['default'].attr('number'),
    //timezone       : DS.belongsTo('timezone', { async: true }),

    companyObserver: (function () {
      var name = this.get('agency.name'),
          id = this.get('agency.id'),
          type = 'agency';

      this.set('company', this.get('agency'));
      if (!this.get('isAgency')) {
        var brand = this.get('brands').objectAt(0);
        if (brand) {
          this.set('company', brand);
          name = brand.get('name');
          id = brand.get('id');
          type = 'brand';
        }
      }

      if (name) {
        this.set('companyName', name);
      }
      this.set('companyTypeAndId', { type: type, id: id });
    }).observes('agency.name', 'brands.@each.name'),

    isHQ: function isHQ(brand) {
      if (!Ember['default'].isNone(brand)) {
        return brand.get('HQ.id') === this.get('id');
      }
    },

    isEqual: function isEqual(showroom) {
      return this.get("id") === showroom.get("id");
    },

    /*
    * Devuelve los paises que quedarian sin asignar en el caso de borrar el user.
    */
    orphanCountriesIfUserIsDeleted: function orphanCountriesIfUserIsDeleted(user) {
      var orphanCountries = [];
      var userAreas = user.get('areasInfluence');

      var showroomUserAreas = [];
      this.get('users').forEach(function (showroomUser) {
        if (user.get('id') !== showroomUser.get('id')) {
          showroomUserAreas = showroomUserAreas.concat(showroomUser.get('areasInfluence').toArray());
        }
      });

      userAreas.forEach(function (userArea) {
        var userAreaCountries = userArea.get('countries');
        var userAreaBrand = userArea.get('brand');

        userAreaCountries.forEach(function (country) {
          var isOrphan = true;

          showroomUserAreas.forEach(function (showroomUserArea) {
            if (userAreaBrand.get('id') === showroomUserArea.get('brand.id')) {

              showroomUserArea.get('countries').forEach(function (showroomUserAreaCountry) {
                if (country.get('id') === showroomUserAreaCountry.get('id')) {
                  isOrphan = false;
                }
              });
            }
          });

          if (isOrphan) {
            orphanCountries.push(country);
          }
        });
      });

      return orphanCountries;
    },

    firstUserShowroom: (function () {
      var users = this.get('users');
      return users.objectAt(0);
    }).observes('users.@each'),

    label: (function () {
      var city = this.get('city');
      return this.get('id') === Ember['default'].showroom.get('id') ? 'MINE (' + city + ')' : city;
    }).property('city'),

    usaCountryObserver: (function () {
      var c = this.get('country');
      if (Ember['default'].isNone(c) || c === "USA" || c === "United States") {
        this.set('region', null);
      }
    }).observes('country'),

    validations: {
      'street': {
        presence: true
      },

      'country': {
        presence: true
      },

      'city': {
        presence: true
      },

      'zipCode': {
        presence: true
      },

      'telephone': {
        presence: true
      }

    }
  });
  /*  'peopleNumber': {
      presence: true
    }*/

});