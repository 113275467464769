define('bookalook/tests/routes/messages/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/messages/index.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/messages/index.js should pass jshint.\nroutes/messages/index.js: line 277, col 12, Unnecessary semicolon.\n\n1 error');
  });

});