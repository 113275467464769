define('bookalook/models/agency', ['exports', 'ember-data', 'bookalook/mixins/model-validator', 'bookalook/config/environment', 'bookalook/utils'], function (exports, DS, Validator, config, utils) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(Validator['default'], {
    name: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    avatar: DS['default'].attr('string', { defaultValue: config['default'].DEFAULT_COMPANY_IMAGE }),

    website: DS['default'].attr('string'),
    twitter: DS['default'].attr('string'),
    facebook: DS['default'].attr('string'),
    instagram: DS['default'].attr('string'),
    pinterest: DS['default'].attr('string'),

    showrooms: DS['default'].hasMany('showrooms', { async: true }),

    isAvatarDefault: (function () {
      var avatar = this.get('avatar');
      if (Ember.isNone(avatar)) return true;
      if (avatar.indexOf('default') !== -1) {
        return true;
      } else {
        return false;
      }
    }).property('avatar'),

    myShowroomId: (function () {
      return localStorage.getItem('showroomId');
    }).property(),

    brandResumeObserver: (function () {
      var _this = this;

      utils['default'].getAPIJSON('agencies/areas/' + this.get('id')).then(function (data) {
        var resume = data.agency;
        resume.forEach(function (area) {
          area.firstFiveCountries = area.countries.slice(0, 5);
          area.restOfCountries = area.countries.slice(5, area.countries.length - 1);
        });
        _this.set('brandResume', resume);
      });
    }).property(),

    validations: {
      'name': {
        presence: true,
        length: { minimum: 2 }
      },

      'website': {
        presence: true,
        length: { minimum: 3 }
      },

      'instagram': {
        presence: true,
        length: { minimum: 3 }
      },

      'avatar': {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('isAvatarDefault');
          },
          message: 'You must set an avatar.'
        }]
      }
    }
  });

});