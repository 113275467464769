define('bookalook/tests/routes/jobs/view.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/jobs/view.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'routes/jobs/view.js should pass jshint.\nroutes/jobs/view.js: line 15, col 92, Missing semicolon.\nroutes/jobs/view.js: line 16, col 100, Missing semicolon.\nroutes/jobs/view.js: line 35, col 77, Missing semicolon.\nroutes/jobs/view.js: line 40, col 102, Missing semicolon.\nroutes/jobs/view.js: line 111, col 65, Functions declared within loops referencing an outer scoped variable may lead to confusing semantics. (storeBrand)\nroutes/jobs/view.js: line 112, col 30, [\'users\'] is better written in dot notation.\nroutes/jobs/view.js: line 194, col 33, Confusing use of \'!\'.\nroutes/jobs/view.js: line 503, col 58, Missing semicolon.\n\n8 errors');
  });

});