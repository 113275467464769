define('bookalook/helpers/check-plan-disabled-select-plan', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var o1 = params[0];
    var o2 = params[1];
    var o3 = params[2];
    var o4 = params[3];
    var o5 = params[4];
    var remainingDays = params[5];

    var result = false;

    if (remainingDays) {
      result = false;
    } else if (o1 && (o2 === o3 || o4 === o5)) {
      result = true;
    }

    return result;
  });

});