define('bookalook/components/multiline-text', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    textLines: (function () {
      if (this.get('text')) {
        return this.get('text').split('\n');
      }
    }).property('text')
  });

});