define('bookalook/routes/register/index', ['exports', 'ember', 'bookalook/mixins/user-validator-mixin', 'bookalook/static-tables'], function (exports, Ember, UserValidatorMixin, static_tables) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(UserValidatorMixin['default'], {
    model: function model() {
      static_tables.loadStaticTables(this);
      return Ember['default'].RSVP.hash({
        user: this.store.createRecord('user'),
        agency: this.store.createRecord('agency'),
        brand: this.store.createRecord('brand'),
        showroom: this.store.createRecord('showroom')
      });
    },

    beforeModel: function beforeModel(transition, queryParams) {
      this.replaceWith('register.pr');
      this._super(transition, queryParams);
      if (Ember['default'].user) {
        this.transitionTo('index');
      }
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      var name = Ember['default'].getUrlParameter('name');
      var website = Ember['default'].getUrlParameter('website');
      var agencyId = Ember['default'].getUrlParameter('agency');
      var brandId = Ember['default'].getUrlParameter('brand');

      //New! Plan to suscribe
      var plan = Ember['default'].getUrlParameter('plan');
      //controller.set('plan', plan);
      var newUser = this.controller.get('user');
      newUser.set('registerPlan', plan);
      newUser.set('name', name);

      if (brandId) {
        this.storeBrand(controller, model, name, website, brandId, plan);
      } else if (agencyId) {
        this.storeAgency(controller, model, name, website, agencyId, plan);
      }
    },

    storeBrand: function storeBrand(controller, model, name, website, brandId, plan) {
      controller.set('invited', true);
      controller.set('brandId', brandId);
      model.agency.set('type', 1);

      model.brand.set('id', brandId);
      model.brand.set('name', name);
      model.brand.set('website', website);
      this.get('store').push('brand', { id: brandId, name: name, website: website });
    },

    storeAgency: function storeAgency(controller, model, name, website, agencyId, plan) {
      controller.set('invited', true);
      controller.set('agencyId', agencyId);
      model.agency.set('type', 2);

      model.agency.set('id', agencyId);
      model.agency.set('name', name);
      model.agency.set('website', website);
      this.get('store').push('agency', { id: agencyId, name: name, website: website });
    },

    activate: function activate() {
      Ember['default'].$('body').addClass('page-register');
      this.controllerFor('application').set('hasSidebar', false);
    },

    deactivate: function deactivate() {
      Ember['default'].$('body').removeClass('page-register');
      this.controllerFor('application').set('hasSidebar', false);
    }
  });

});