define('bookalook/components/lazy-image', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    onInsert: (function () {
      var img = Ember['default'].$(this.element).find('img');
      img[0].onload = function () {
        return img.removeClass('lazy-image');
      };
    }).on('didInsertElement'),

    imageObserver: (function () {
      var img = Ember['default'].$(this.element).find('img');
      Ember['default'].$(img[0]).attr('src', this.get('url'));
    }).observes('url')
  });

});