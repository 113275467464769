define('bookalook/templates/messages/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 10
            },
            "end": {
              "line": 27,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/messages/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","validated-select col-xs-11 no-padding");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1,"class","fa fa-times col-xs-1 close-new-conversation");
          dom.setAttribute(el1,"aria-hidden","true");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          morphs[1] = dom.createElementMorph(element17);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["placeholder","Search member by name, company or email address","value",["subexpr","@mut",[["get","userNewConversation",["loc",[null,[20,24],[20,43]]]]],[],[]],"content",["subexpr","@mut",[["get","chatUsers",["loc",[null,[21,26],[21,35]]]]],[],[]],"class","no-margin","optionLabelPath","userSafeString"],["loc",[null,[18,16],[24,18]]]],
          ["element","action",["cancelNewConversation"],[],["loc",[null,[26,15],[26,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 10
            },
            "end": {
              "line": 29,
              "column": 10
            }
          },
          "moduleName": "bookalook/templates/messages/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","btn col-xs-12 new-conversation-btn pull-right");
          dom.setAttribute(el1,"role","button");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","fa fa-plus");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" NEW MESSAGE");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element16);
          return morphs;
        },
        statements: [
          ["element","action",["toggleNewConversation",true],[],["loc",[null,[28,20],[28,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 12
                },
                "end": {
                  "line": 48,
                  "column": 12
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-xs-3");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"style","width: 50px; height: 50px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-xs-8 preview-conversation");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h4");
              dom.setAttribute(el2,"class","row col-xs-12 no-padding conversation-heading");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("small");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1, 1]);
              var element13 = dom.childAt(fragment, [3]);
              var element14 = dom.childAt(element13, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element12, 'class');
              morphs[1] = dom.createAttrMorph(element12, 'src');
              morphs[2] = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
              morphs[3] = dom.createAttrMorph(element14, 'class');
              morphs[4] = dom.createMorphAt(element14,0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["user-avatar ",["subexpr","if",[["subexpr","if-equal",[["get","user.type",["loc",[null,[42,55],[42,64]]]],"PR"],[],["loc",[null,[42,45],[42,70]]]],"pr-user","mp-user"],[],["loc",[null,[42,40],[42,92]]]]]]],
              ["attribute","src",["concat",[["get","user.avatar",["loc",[null,[42,136],[42,147]]]]]]],
              ["content","user.name",["loc",[null,[45,74],[45,87]]]],
              ["attribute","class",["concat",["row col-xs-12 no-padding ",["subexpr","if",[["get","conversation.somethingNew",["loc",[null,[46,60],[46,85]]]],"something-new"],[],["loc",[null,[46,55],[46,103]]]]]]],
              ["inline","limit-string",[["get","conversation.lastMessage",["loc",[null,[46,120],[46,144]]]],20],[],["loc",[null,[46,105],[46,149]]]]
            ],
            locals: ["user"],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 14
                },
                "end": {
                  "line": 52,
                  "column": 14
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-times close-new-conversation");
              dom.setAttribute(el1,"aria-hidden","true");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [
              ["element","action",["removeConversation",["get","conversation",["loc",[null,[51,49],[51,61]]]]],["bubbles",false],["loc",[null,[51,19],[51,77]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 8
              },
              "end": {
                "line": 55,
                "column": 8
              }
            },
            "moduleName": "bookalook/templates/messages/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-1 remove-conversation no-padding-left");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element15, 'class');
            morphs[1] = dom.createElementMorph(element15);
            morphs[2] = dom.createMorphAt(element15,1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["media conversation pointer row ",["subexpr","if",[["subexpr","if-equal",[["get","conversation.id",["loc",[null,[38,68],[38,83]]]],["get","selectedConversation.id",["loc",[null,[38,84],[38,107]]]]],[],["loc",[null,[38,58],[38,108]]]],"selected"],[],["loc",[null,[38,53],[38,121]]]]]]],
            ["element","action",["getMessages",["get","conversation",["loc",[null,[38,146],[38,158]]]]],[],["loc",[null,[38,123],[38,160]]]],
            ["block","each",[["get","conversation.users",["loc",[null,[40,20],[40,38]]]]],[],0,null,["loc",[null,[40,12],[48,21]]]],
            ["block","if",[["subexpr","if-equal",[["get","conversation.id",["loc",[null,[50,30],[50,45]]]],["get","selectedConversation.id",["loc",[null,[50,46],[50,69]]]]],[],["loc",[null,[50,20],[50,70]]]]],[],1,null,["loc",[null,[50,14],[52,21]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 6
            },
            "end": {
              "line": 56,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/messages/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","conversation.invisible",["loc",[null,[37,18],[37,40]]]]],[],0,null,["loc",[null,[37,8],[55,19]]]]
        ],
        locals: ["conversation"],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 14
                  },
                  "end": {
                    "line": 69,
                    "column": 14
                  }
                },
                "moduleName": "bookalook/templates/messages/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","alert alert-info msg-date col-xs-12");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode("Today");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 12
                },
                "end": {
                  "line": 80,
                  "column": 12
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","media msg col-xs-12");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","pull-left");
              dom.setAttribute(el2,"href","#");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("img");
              dom.setAttribute(el3,"style","width: 50px; height: 50px;");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","media-body");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("small");
              dom.setAttribute(el3,"class","pull-right time");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h4");
              dom.setAttribute(el3,"class","media-heading");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("small");
              dom.setAttribute(el3,"class","col-xs-12 no-padding");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [2]);
              var element9 = dom.childAt(element8, [1, 1]);
              var element10 = dom.childAt(element8, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createAttrMorph(element9, 'class');
              morphs[2] = dom.createAttrMorph(element9, 'src');
              morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
              morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
              morphs[5] = dom.createUnsafeMorphAt(dom.childAt(element10, [5]),0,0);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","if-equal",[["get","message.date",["loc",[null,[65,30],[65,42]]]],["get","todayDate",["loc",[null,[65,43],[65,52]]]]],[],["loc",[null,[65,20],[65,53]]]]],[],0,null,["loc",[null,[65,14],[69,21]]]],
              ["attribute","class",["concat",["user-avatar ",["subexpr","if",[["subexpr","if-equal",[["get","message.user.type",["loc",[null,[72,57],[72,74]]]],"PR"],[],["loc",[null,[72,47],[72,80]]]],"pr-user","mp-user"],[],["loc",[null,[72,42],[72,102]]]]]]],
              ["attribute","src",["concat",[["get","message.user.avatar",["loc",[null,[72,146],[72,165]]]]]]],
              ["inline","messages-dateformat",[["get","message.date",["loc",[null,[75,71],[75,83]]]]],[],["loc",[null,[75,49],[75,85]]]],
              ["content","message.user.name",["loc",[null,[76,44],[76,65]]]],
              ["content","message.text",["loc",[null,[77,54],[77,72]]]]
            ],
            locals: ["message"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 10
              },
              "end": {
                "line": 81,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/messages/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","conversationMessages",["loc",[null,[64,20],[64,40]]]]],[],0,null,["loc",[null,[64,12],[80,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 12
                },
                "end": {
                  "line": 87,
                  "column": 12
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-xs-12 text-center");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-xs-12 padding-top padding-bottom");
              var el3 = dom.createElement("h3");
              var el4 = dom.createTextNode("Your request to message with ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" has been sent!");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-xs-12 padding-top-25");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","excerpt-request");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element7, [1, 0]),1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element7, [3, 0]),0,0);
              return morphs;
            },
            statements: [
              ["content","selectedConversation.users.0.name",["loc",[null,[84,99],[84,136]]]],
              ["inline","limit-string",[["get","selectedConversation.lastMessage",["loc",[null,[85,99],[85,131]]]],30],[],["loc",[null,[85,84],[85,136]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 12
                },
                "end": {
                  "line": 94,
                  "column": 12
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-xs-12 text-center");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-xs-12 padding-top padding-bottom");
              var el3 = dom.createElement("h3");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" wants to chat with you");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-xs-12 padding-top-25");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","excerpt-request");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-xs-6 padding-top padding-top-25");
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","new-conversation-btn pull-right");
              dom.setAttribute(el3,"role","button");
              var el4 = dom.createTextNode("ACCEPT");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-xs-6 padding-top padding-top-25");
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","ignore-btn pull-left");
              dom.setAttribute(el3,"role","button");
              var el4 = dom.createTextNode("IGNORE");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [5, 0]);
              var element6 = dom.childAt(element4, [7, 0]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 0]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 0]),0,0);
              morphs[2] = dom.createElementMorph(element5);
              morphs[3] = dom.createElementMorph(element6);
              return morphs;
            },
            statements: [
              ["content","selectedConversation.users.0.name",["loc",[null,[89,70],[89,107]]]],
              ["inline","limit-string",[["get","selectedConversation.lastMessage",["loc",[null,[90,99],[90,131]]]],30],[],["loc",[null,[90,84],[90,136]]]],
              ["element","action",["acceptConversation",["get","selectedConversation",["loc",[null,[91,103],[91,123]]]]],[],["loc",[null,[91,73],[91,125]]]],
              ["element","action",["ignoreConversation",["get","selectedConversation",["loc",[null,[92,103],[92,123]]]]],[],["loc",[null,[92,73],[92,125]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 10
              },
              "end": {
                "line": 95,
                "column": 10
              }
            },
            "moduleName": "bookalook/templates/messages/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","if-equal",[["get","selectedConversation.creatorId",["loc",[null,[82,28],[82,58]]]],["get","Ember.userId",["loc",[null,[82,59],[82,71]]]]],[],["loc",[null,[82,18],[82,72]]]]],[],0,1,["loc",[null,[82,12],[94,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 8
            },
            "end": {
              "line": 96,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/messages/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","selectedConversation.users.0.friend",["loc",[null,[63,16],[63,51]]]]],[],0,1,["loc",[null,[63,10],[95,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 8
            },
            "end": {
              "line": 102,
              "column": 8
            }
          },
          "moduleName": "bookalook/templates/messages/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-xs-12 text-center");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 padding-top");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"class","mail-icon");
          dom.setAttribute(el3,"src","images/mail-icon.png");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 padding-top");
          var el3 = dom.createTextNode("This is the very beginning of your messaging history.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 padding-top");
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","new-conversation-btn");
          dom.setAttribute(el3,"role","button");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","fa fa-plus");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" START CHATTING");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 5, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["element","action",["toggleNewConversation",true],[],["loc",[null,[100,55],[100,94]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 113,
                    "column": 20
                  },
                  "end": {
                    "line": 115,
                    "column": 20
                  }
                },
                "moduleName": "bookalook/templates/messages/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"class","btn new-conversation-btn pull-right col-xs-3");
                dom.setAttribute(el1,"role","button");
                var el2 = dom.createTextNode("Create conversation");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [
                ["element","action",["newConversation",["get","userNewConversation",["loc",[null,[114,57],[114,76]]]],["get","newMessageText",["loc",[null,[114,77],[114,91]]]]],[],["loc",[null,[114,30],[114,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.7",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 115,
                    "column": 20
                  },
                  "end": {
                    "line": 117,
                    "column": 20
                  }
                },
                "moduleName": "bookalook/templates/messages/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"class","btn new-conversation-btn pull-right col-xs-3");
                dom.setAttribute(el1,"role","button");
                dom.setAttribute(el1,"disabled","");
                var el2 = dom.createTextNode("Create conversation");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 112,
                  "column": 18
                },
                "end": {
                  "line": 118,
                  "column": 18
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","canCreateNewConversation",["loc",[null,[113,26],[113,50]]]]],[],0,1,["loc",[null,[113,20],[117,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 18
                },
                "end": {
                  "line": 120,
                  "column": 18
                }
              },
              "moduleName": "bookalook/templates/messages/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","btn send-message-btn pull-right col-xs-2");
              dom.setAttribute(el1,"role","button");
              var el2 = dom.createTextNode("Send");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["newMessage",["get","selectedConversation",["loc",[null,[119,50],[119,70]]]],["get","newMessageText",["loc",[null,[119,71],[119,85]]]]],[],["loc",[null,[119,28],[119,87]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 8
              },
              "end": {
                "line": 125,
                "column": 8
              }
            },
            "moduleName": "bookalook/templates/messages/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","send-wrap row");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","inner-div-send");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","message-buttons-layer row no-margin");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","buttons-layer col-xs-12");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2,1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","textarea",[],["value",["subexpr","@mut",[["get","newMessageText",["loc",[null,[109,31],[109,45]]]]],[],[]],"enter","newMessageEnter","placeholder","Write a reply...","rows","5","class","form-control send-message"],["loc",[null,[109,14],[109,145]]]],
            ["block","if",[["get","isNewConversation",["loc",[null,[112,24],[112,41]]]]],[],0,1,["loc",[null,[112,18],[120,25]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 6
            },
            "end": {
              "line": 126,
              "column": 6
            }
          },
          "moduleName": "bookalook/templates/messages/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","selectedConversation.users.0.friend",["loc",[null,[106,14],[106,49]]]]],[],0,null,["loc",[null,[106,8],[125,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 132,
            "column": 0
          }
        },
        "moduleName": "bookalook/templates/messages/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row page-messages");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 no-padding");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","pull-left padding-bottom section_title");
        var el5 = dom.createTextNode("Messages ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","fa fa-envelope-o");
        dom.setAttribute(el5,"aria-hidden","true");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-4 btn-pane-top");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","validated-input col-xs-12 searcher no-padding-left");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","images/lupa.png");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-8 title-top no-padding");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","top_conversation_button");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row conversationlist-chat");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","conversation-wrap col-xs-4");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","message-wrap col-xs-8 no-padding");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","msg-wrap row");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element18 = dom.childAt(fragment, [0]);
        var element19 = dom.childAt(element18, [3]);
        var element20 = dom.childAt(element18, [5]);
        var element21 = dom.childAt(element20, [1]);
        var element22 = dom.childAt(element20, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element19, [1, 1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(element19, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(element21,1,1);
        morphs[3] = dom.createMorphAt(element21,3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element22, [1]),1,1);
        morphs[5] = dom.createMorphAt(element22,3,3);
        morphs[6] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","conversationSearchInput",["loc",[null,[11,40],[11,63]]]]],[],[]],"placeholder","Search...","class","col-xs-12 search-messages"],["loc",[null,[11,14],[11,123]]]],
        ["block","if",[["get","newConversationButtonActive",["loc",[null,[16,16],[16,43]]]]],[],0,1,["loc",[null,[16,10],[29,17]]]],
        ["block","each",[["get","conversationList",["loc",[null,[36,14],[36,30]]]]],[],2,null,["loc",[null,[36,6],[56,15]]]],
        ["content","notUsersFoundMessage",["loc",[null,[57,6],[57,30]]]],
        ["block","if",[["get","selectedConversation",["loc",[null,[62,14],[62,34]]]]],[],3,4,["loc",[null,[62,8],[102,15]]]],
        ["block","if",[["get","selectedConversation",["loc",[null,[105,12],[105,32]]]]],[],5,null,["loc",[null,[105,6],[126,13]]]],
        ["inline","partial",["messages/modals/new-conversation"],[],["loc",[null,[131,0],[131,46]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }()));

});